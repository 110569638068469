import {
  AiState,
  ListItem,
  ListItemSupported,
  MainProductListItem,
  PopoverControlData,
  ProductListItem,
} from "src/types/components";
import {
  ConfiguredProductGroup,
  Configuration,
  Relationship,
  RelationshipType,
  ConfiguredProductGroupDefinition,
  GroupIdObj,
  ProductIdObj,
  ConfigurationState,
  MainProductIdObj,
  ListItemIdKey,
} from "src/types/configuration";
import { Product, MainProduct } from "src/types/products";
import * as JsSearch from "js-search";
import { deepCopy } from "src/helpers/index";
import {
  ApiResult,
  GetGroupApiResult,
  PostGroupsApiParams,
  PostPutConfigurationsApiResult,
  PostPutGroupApiResult,
  PutConfigurationsApiParams,
  RelationshipsApiResult,
} from "src/types/api";
import groupsMiddleware from "src/api/groupsMiddleware";
import relationshipsMiddleware from "src/api/relationshipsMiddleware";
import configurationsMiddleware from "src/api/configurationsMiddleware";
import config from "src/config";
import { toUtc } from "../dates";
import {
  CalendarEmpty,
  Communication,
  CommunicationCrossed,
} from "src/components/linearicons";
import { deepEqual } from "src/helpers";
import i18n from "src/i18n";
import { Surface } from "src/types/surfaces";

const searches: {
  [key: string]: {
    instance: any;
    documents: any;
  };
} = {};

export const getStarterGroup = (id: string): PostGroupsApiParams => {
  let groupDefinition: ConfiguredProductGroupDefinition = [];

  return {
    group_type: "USER_DEFINED_MANUAL_GROUP",
    name: id,
    group_definition: groupDefinition,
  };
};

export const getListItemType = (
  item: MainProduct | Product | ConfiguredProductGroup | Configuration
): ListItemSupported => {
  if (typeof (item as MainProduct).main_product_id !== "undefined") {
    return "main_product";
  }
  if (typeof (item as Product).product_id !== "undefined") {
    return "product";
  }

  if (typeof (item as ConfiguredProductGroup).group_type !== "undefined") {
    return "group";
  }

  return "configuration";
};

export const getListItemIdKeyFromIdObj = (
  item: MainProductIdObj | ProductIdObj | GroupIdObj
): ListItemIdKey => {
  if (typeof (item as MainProductIdObj).main_product_id !== "undefined") {
    return config.configurationListItemTypeToId["main_product"];
  }
  if (typeof (item as ProductIdObj).product_id !== "undefined") {
    return config.configurationListItemTypeToId["product"];
  }

  if (typeof (item as GroupIdObj).group_id !== "undefined") {
    return config.configurationListItemTypeToId["group"];
  }

  return "id";
};

export const getProductIdFromMainOrRegularProduct = (
  item: MainProductIdObj | ProductIdObj
): string | null => {
  const mainProductKey = config.configurationListItemTypeToId["main_product"];
  if (typeof (item as any)[mainProductKey] !== "undefined") {
    return (item as any)[mainProductKey];
  }
  const productKey = config.configurationListItemTypeToId["product"];
  if (typeof (item as any)[productKey] !== "undefined") {
    return (item as any)[productKey];
  }

  return null;
};

export const getGroupIdFromObj = (item: GroupIdObj): string | null => {
  const groupKey = config.configurationListItemTypeToId["group"];
  if (typeof (item as any)[groupKey] !== "undefined") {
    return (item as any)[groupKey];
  }

  return null;
};

export const fromListItemToIdObj = (
  item: ListItem
): MainProductIdObj | ProductIdObj | GroupIdObj => {
  switch (item.type) {
    case "main_product":
      return { main_product_id: item.id.toString() };
    case "product":
      return { product_id: item.id.toString() };
    case "group":
      return { group_id: item.id.toString() };
  }
  throw Error("Unexpected list item type");
};

export const getConfigurationStateColor = (
  state: ConfigurationState,
  startTimestamp: number | null,
  endTimestamp: number | null
): string => {
  switch (state) {
    case "inactive":
      return config.plotColors[5];
    case "indefinite":
      return config.plotColors[0];
    case "scheduled":
      const currentTimestamp = Math.floor(+toUtc(new Date()) / 1000);
      const st = startTimestamp || 0;
      const et = endTimestamp || 2147483647; //max unix value
      const isActive = currentTimestamp >= st && currentTimestamp <= et;
      if (isActive) {
        return config.plotColors[0];
      } else if (currentTimestamp < st) {
        return config.plotColors[0];
      } else if (currentTimestamp > et) {
        return config.plotColors[5];
      }
      return config.plotColors[0];
    default:
      return config.plotColors[0];
  }
};

export const getConfigurationStateLabel = (
  state: ConfigurationState,
  startTimestamp: number | null,
  endTimestamp: number | null
): string => {
  switch (state) {
    case "scheduled":
      const currentTimestamp = Math.floor(+toUtc(new Date()) / 1000);
      const st = startTimestamp || 0;
      const et = endTimestamp || 2147483647; //max unix value
      const isActive = currentTimestamp >= st && currentTimestamp <= et;
      if (isActive) {
        return i18n.t(`configuration.state.label.indefinite`);
      } else if (currentTimestamp < st) {
        return i18n.t(`configuration.state.label.scheduled`);
      } else if (currentTimestamp > et) {
        return i18n.t(`configuration.state.label.inactive`);
      }
      return i18n.t(`configuration.state.label.${state}`);
    default:
      return i18n.t(`configuration.state.label.${state}`);
  }
};

export const getConfigurationStateIcon = (
  state: ConfigurationState,
  startTimestamp: number | null,
  endTimestamp: number | null
): JSX.Element => {
  switch (state) {
    case "inactive":
      return (
        <CommunicationCrossed
          style={{ color: config.plotColors[5] }}
          size="12"
        />
      );
    case "indefinite":
      return (
        <Communication style={{ color: config.plotColors[0] }} size="12" />
      );
    case "scheduled":
      const currentTimestamp = Math.floor(+toUtc(new Date()) / 1000);
      const st = startTimestamp || 0;
      const et = endTimestamp || 2147483647; //max unix value
      const isActive = currentTimestamp >= st && currentTimestamp <= et;
      if (isActive) {
        return (
          <Communication style={{ color: config.plotColors[0] }} size="12" />
        );
      } else if (currentTimestamp < st) {
        return (
          <CalendarEmpty style={{ color: config.plotColors[0] }} size="12" />
        );
      } else if (currentTimestamp > et) {
        return (
          <CommunicationCrossed
            style={{ color: config.plotColors[5] }}
            size="12"
          />
        );
      }
      return (
        <CommunicationCrossed
          style={{ color: config.plotColors[5] }}
          size="12"
        />
      );
    default:
      return <></>;
  }
};

export const refactorForList = (
  items: Array<
    MainProduct | Product | ConfiguredProductGroup | Configuration
  > | null
): Array<ListItem> | null => {
  if (!items) {
    return null;
  }

  return items.map(
    (
      item: MainProduct | Product | ConfiguredProductGroup | Configuration
    ): ListItem => {
      let type = getListItemType(item);

      let obj: ListItem;
      switch (type) {
        case "main_product":
          const asMainProduct = item as MainProduct;
          const a: MainProductListItem = {
            //@ts-ignore
            id: asMainProduct[config.configurationListItemTypeToId[type]],
            title: asMainProduct.title,
            type,
            inStock: asMainProduct.in_stock,
            deleted: asMainProduct.deleted,
            imageUrl:
              asMainProduct.image_urls && asMainProduct.image_urls[0]
                ? asMainProduct.image_urls[0]
                : undefined,
          };
          return a;
        case "product":
          const asProduct = item as Product;
          const b: ProductListItem = {
            //@ts-ignore
            id: asProduct[config.configurationListItemTypeToId[type]],
            title: asProduct.title,
            type,
            inStock: asProduct.in_stock,
            deleted: asProduct.deleted,
            imageUrl:
              asProduct.image_urls && asProduct.image_urls[0]
                ? asProduct.image_urls[0]
                : undefined,
          };
          return b;
        case "configuration":
          const asConfiguration = item as Configuration;
          obj = {
            //@ts-ignore
            id: asConfiguration[config.configurationListItemTypeToId[type]],
            title: asConfiguration.display_name,
            type,
            rightIcon: asConfiguration.is_empty ? (
              <></>
            ) : (
              getConfigurationStateIcon(
                asConfiguration.state,
                asConfiguration.validity_start_timestamp || null,
                asConfiguration.validity_end_timestamp || null
              )
            ),
          };
          break;
        case "group":
          const asGroup = item as ConfiguredProductGroup;
          obj = {
            //@ts-ignore
            id: asGroup[config.configurationListItemTypeToId[type]],
            title: asGroup.name || asGroup.group_id,
            type,
          };
          break;
        default:
          obj = {
            id: Math.random(),
            title: "Not identified",
            type: "unknown",
          };
          break;
      }

      return obj;
    }
  );
};

export const updateSelectedItems = (
  selected: ConfiguredProductGroupDefinition,
  params: {
    item?: ListItem;
    action: "toggle" | "reset";
  }
): ConfiguredProductGroupDefinition => {
  if (params.action === "reset") {
    return [];
  }

  var proxy: ConfiguredProductGroupDefinition = deepCopy(selected);

  if (!params.item) {
    return proxy;
  }

  let type = params.item.type as Extract<
    ListItemSupported,
    "main_product" | "product" | "group"
  >;

  const idKey: ListItemIdKey | "id" =
    config.configurationListItemTypeToId[type];
  if (!["main_product", "product", "group"].includes(type) || idKey === "id") {
    return proxy;
  }

  let index = proxy.findIndex(
    (obj: MainProductIdObj | ProductIdObj | GroupIdObj) => {
      // Ignoring TS here since we just checked that the type is in the list we expect,
      // if you know how to do this nicer without having to ignore TS then please change it
      // @ts-ignore
      const id = obj[idKey as ListItemIdKey];
      return id === params?.item?.id;
    }
  );

  if (index > -1) {
    proxy?.splice(index, 1);
  } else {
    proxy.push({
      [idKey]: params?.item?.id + "",
    } as MainProductIdObj | ProductIdObj | GroupIdObj);
  }

  return proxy;
};

export const updateSelectedSurfaces = (
  surface: string,
  selected: string[] | undefined
): string[] => {
  if (!surface) {
    return selected ? deepCopy(selected) : [];
  }

  let proxy = selected ? deepCopy(selected) : [];

  let position = proxy.indexOf(surface + "");
  if (position > -1) {
    proxy.splice(position, 1);
  } else {
    proxy.push(surface + "");
  }

  return proxy;
};

export const getSearchFooterString = (
  groupDefinition: ConfiguredProductGroupDefinition
): string => {
  let nOfProducts = 0;
  let nOfConfiguredProductGroup = 0;

  groupDefinition.forEach(
    (obj: MainProductIdObj | ProductIdObj | GroupIdObj) => {
      if (
        (obj as ProductIdObj).product_id ||
        (obj as MainProductIdObj).main_product_id
      ) {
        nOfProducts++;
      }
      if ((obj as GroupIdObj).group_id) {
        nOfConfiguredProductGroup++;
      }
    }
  );

  let s = "";
  if (nOfProducts) {
    s += `${nOfProducts} product${nOfProducts > 1 ? "s" : ""}`;
  }

  if (s && nOfConfiguredProductGroup) {
    s += " & ";
  }

  if (nOfConfiguredProductGroup) {
    s += `${nOfConfiguredProductGroup} group${
      nOfConfiguredProductGroup > 1 ? "s" : ""
    }`;
  }

  if (s) {
    s += " selected";
  }

  if (s === "") {
    s = "Nothing selected";
  }

  return s;
};

export const getSearchHits = (
  searchName: string,
  uidFields: string[] | string,
  fields: string[] | string,
  documents: Object[],
  keyword: string
) => {
  if (
    !searches[searchName] ||
    !deepEqual(documents, searches[searchName].documents)
  ) {
    console.log(
      "%c[JS SEARCH] creating a new search instance for " + searchName,
      "color: limegreen"
    );
    searches[searchName] = {
      instance: new JsSearch.Search(uidFields),
      documents: deepCopy(documents),
    };
    searches[searchName].instance.indexStrategy =
      new JsSearch.AllSubstringsIndexStrategy();
    if (typeof fields === "string") {
      searches[searchName].instance.addIndex(fields);
    } else {
      fields.forEach((field) => {
        searches[searchName].instance.addIndex(field);
      });
    }
    searches[searchName].instance.addDocuments(documents);
  }

  return searches[searchName].instance.search(keyword);
};

export const getGroupItems = (
  selected: ConfiguredProductGroupDefinition,
  items: Array<MainProduct | Product | ConfiguredProductGroup>
): Array<MainProduct | Product | ConfiguredProductGroup> => {
  let selectedItems: Array<MainProduct | Product | ConfiguredProductGroup> = [];

  selected.forEach((obj: MainProductIdObj | ProductIdObj | GroupIdObj) => {
    const key = getListItemIdKeyFromIdObj(obj);

    const element = items.find(
      (item: MainProduct | Product | ConfiguredProductGroup) => {
        // @ts-ignore
        return typeof item[key] !== "undefined" && obj[key] === item[key];
      }
    );

    if (element) {
      selectedItems.push(element);
    } else {
      const deletedMainProduct: MainProduct = {
        // @ts-ignore
        main_product_id: obj[key],
        // @ts-ignore
        title: obj[key],
        image_urls: [],
        in_stock: false,
        deleted: true,
      };
      selectedItems.push(deletedMainProduct);
    }
  });

  return selectedItems;
};

export const getSet = (
  groupDefinition: ConfiguredProductGroupDefinition,
  products: Array<MainProduct | Product> | null,
  groups: ConfiguredProductGroup[] | null
): Array<MainProduct | Product | ConfiguredProductGroup> | null => {
  if (!groupDefinition || groupDefinition.length === 0) {
    return null;
  }

  if (!products) {
    products = [];
  }

  if (!groups) {
    groups = [];
  }

  let items = getGroupItems(
    groupDefinition,
    ([] as Array<MainProduct | Product | ConfiguredProductGroup>)
      .concat(products || [])
      .concat(groups || [])
  );

  if (!items.length) {
    return null;
  }

  return items;
};

export const getUpdatedGroupDefinition = (
  currentDef: ConfiguredProductGroupDefinition | null,
  data: ConfiguredProductGroupDefinition,
  operation: "append" | "remove"
): ConfiguredProductGroupDefinition => {
  if (operation === "append") {
    return currentDef ? currentDef.concat(data) : data;
  }

  if (operation === "remove") {
    return currentDef
      ? currentDef.filter(
          (obj: MainProductIdObj | ProductIdObj | GroupIdObj) => {
            const key = getListItemIdKeyFromIdObj(obj);
            // @ts-ignore
            const objVal = obj[key];
            return !data.find(
              (obj2: MainProductIdObj | ProductIdObj | GroupIdObj) => {
                // @ts-ignore
                const obj2Val = obj2[key];
                return typeof obj2Val !== "undefined" && objVal === obj2Val;
              }
            );
          }
        )
      : [];
  }

  return currentDef ? currentDef : [];
};

export const getUpdatedConfigurations = (
  currentConfigurations: Configuration[],
  configuration: Configuration,
  operation: "append" | "remove" | "update"
): Configuration[] => {
  let proxy = deepCopy(currentConfigurations);

  switch (operation) {
    case "append":
      proxy.push(configuration);
      break;
    case "update":
      const currentConfigurationPosition = proxy?.findIndex((c) => {
        return c.id === configuration.id;
      });

      if (currentConfigurationPosition === -1) {
        return proxy;
      }

      proxy[currentConfigurationPosition] = getUpdatedConfiguration(
        proxy[currentConfigurationPosition],
        configuration
      );
      break;
    case "remove":
      const configurationPosition = proxy?.findIndex((c) => {
        return c.id === configuration.id;
      });

      if (configurationPosition === -1) {
        return proxy;
      }

      proxy.splice(configurationPosition, 1);
      break;
    default:
      break;
  }

  return proxy;
};

export const getUpdatedConfiguration = (
  currentConfiguration: Configuration,
  configuration: PutConfigurationsApiParams
): Configuration => {
  return Object.assign(currentConfiguration, configuration);
};

export const getRelationshipByType = (
  configuration: Configuration,
  type: RelationshipType
): Relationship | null => {
  if (!configuration.relationships) {
    return null;
  }

  let relationships = configuration.relationships;

  let relationship = relationships.find((rel) => {
    return rel.relationship_type === type;
  });

  if (!relationship) {
    return null;
  }

  return relationship;
};

export const createGroupsSet = async (
  merchant: string,
  market: string,
  groups: {
    from: PostGroupsApiParams;
    includeTo: PostGroupsApiParams;
    excludeTo: PostGroupsApiParams;
  },
  idToken: string,
  userId: string | undefined
): Promise<
  ApiResult<{
    from: ConfiguredProductGroup;
    includeTo: ConfiguredProductGroup;
    excludeTo: ConfiguredProductGroup;
  }>
> => {
  let data = await Promise.all([
    groupsMiddleware.create(idToken, merchant, market, userId, groups.from),
    groupsMiddleware.create(
      idToken,
      merchant,
      market,
      userId,
      groups.includeTo
    ),
    groupsMiddleware.create(
      idToken,
      merchant,
      market,
      userId,
      groups.excludeTo
    ),
  ]);

  const [fromGroup, includeGroup, excludeGroup] = data as [
    PostPutGroupApiResult,
    PostPutGroupApiResult,
    PostPutGroupApiResult
  ];

  if (!fromGroup.success) {
    return fromGroup;
  }

  if (!includeGroup.success) {
    return includeGroup;
  }

  if (!excludeGroup.success) {
    return excludeGroup;
  }

  return {
    success: true,
    data: {
      from: fromGroup.data,
      includeTo: includeGroup.data,
      excludeTo: excludeGroup.data,
    },
  };
};

export const createBasicRelationships = async (
  merchant: string,
  configuration_id: number,
  aiState: boolean,
  groups: {
    from: ConfiguredProductGroup;
    includeTo: ConfiguredProductGroup;
    excludeTo: ConfiguredProductGroup;
  },
  idToken: string,
  userId: string | undefined
): Promise<
  ApiResult<{
    include: Relationship;
    exclude: Relationship;
  }>
> => {
  let data = await Promise.all([
    relationshipsMiddleware.create(
      idToken,
      merchant,
      configuration_id,
      userId,
      {
        display_name: "include",
        from_group: groups.from.group_id,
        to_group: groups.includeTo.group_id,
        relationship_type: aiState ? "only_include" : "must_include",
      }
    ),
    relationshipsMiddleware.create(
      idToken,
      merchant,
      configuration_id,
      userId,
      {
        display_name: "exclude",
        from_group: groups.from.group_id,
        to_group: groups.excludeTo.group_id,
        relationship_type: "do_not_include",
      }
    ),
  ]);

  const [includeRelationship, excludeRelationship] = data as [
    RelationshipsApiResult,
    RelationshipsApiResult
  ];

  if (!includeRelationship.success) {
    return includeRelationship;
  }

  if (!excludeRelationship.success) {
    return excludeRelationship;
  }

  return {
    success: true,
    data: {
      include: includeRelationship.data,
      exclude: excludeRelationship.data,
    },
  };
};

export const getConfigurationGroupIds = (
  relationships: Relationship[]
): string[] => {
  let configurationGroups: string[] = [];

  for (let i = 0; i < relationships.length; i++) {
    let relationship: Relationship = relationships[i];
    let from = relationship.from_group;
    let to = relationship.to_group;

    if (configurationGroups.indexOf(from) === -1) {
      configurationGroups.push(from);
    }

    if (to && configurationGroups.indexOf(to) === -1) {
      configurationGroups.push(to);
    }
  }

  return configurationGroups;
};

export const getRelationshipGroup = (
  type: RelationshipType,
  side: "from_group" | "to_group",
  relationships: Relationship[],
  groups: ConfiguredProductGroup[]
): ConfiguredProductGroup | null => {
  let firstRelationshipOfType = relationships.find(
    (rel: Relationship) => rel.relationship_type === type
  );

  if (!firstRelationshipOfType) {
    return null;
  }

  if (!firstRelationshipOfType[side]) {
    return null;
  }

  let foundGroup = groups.find(
    (el: ConfiguredProductGroup) =>
      el.group_id === firstRelationshipOfType?.[side]
  );

  if (!foundGroup) {
    return null;
  }

  return foundGroup;
};

export const getUpdatedGroup = (
  target: string,
  data: ConfiguredProductGroupDefinition,
  groups: ConfiguredProductGroup[],
  operation: "append" | "remove" | "replace"
): ConfiguredProductGroup | null => {
  let groupTarget = groups?.find(
    (el: ConfiguredProductGroup) => el.group_id === target
  );

  if (!groupTarget) {
    return null;
  }

  if (operation === "replace") {
    let updatedGroup = deepCopy(groupTarget);
    updatedGroup.group_definition = data;
    return updatedGroup;
  }

  let updatedGroupDefinition = getUpdatedGroupDefinition(
    groupTarget.group_definition,
    data,
    operation
  );

  let updatedGroup = deepCopy(groupTarget);
  updatedGroup.group_definition = updatedGroupDefinition;
  return updatedGroup;
};

export const getNullAiState = () => {
  return { view: null, currentConf: null };
};

const updateAiStatusOnServer = async (
  aiControl: AiState,
  configuration: Configuration,
  merchant: string,
  idToken: string,
  userId: string | undefined
): Promise<[string, RelationshipsApiResult | null]> => {
  const toApply = aiControl.view;
  let result = null;

  let existingType: RelationshipType = "must_include";
  let toType: RelationshipType = "only_include";
  if (!toApply) {
    existingType = "only_include";
    toType = "must_include";
  }

  let debug = `Toggling aiControl, relationship type will be set to ${toType}`;

  const existingRelationship = getRelationshipByType(
    configuration,
    existingType
  );

  if (!existingRelationship) {
    return [debug, result];
  }

  let response = await relationshipsMiddleware.edit(
    idToken,
    merchant,
    configuration.id,
    `${existingRelationship.id}`,
    userId,
    {
      id: existingRelationship.id,
      from_group: existingRelationship.from_group,
      to_group: existingRelationship.to_group,
      relationship_type: toType,
    }
  );

  return [debug, response];
};

export const saveOnServer = async (
  configurationGroups: ConfiguredProductGroup[] | null,
  configuration: Configuration,
  merchant: string,
  market: string,
  aiControl: AiState,
  idToken: string,
  userId: string | undefined
): Promise<
  | RelationshipsApiResult
  | null
  | ApiResult<{
      configuration: Configuration;
      groups: ConfiguredProductGroup[];
    }>
> => {
  let arrayOfPromises: Function[] = [];
  let debugOperations = [];

  if (aiControl.currentConf !== aiControl.view) {
    /**
     * Unfortunately, the request to update the relationship needs to be done before all the others in a separate promise.
     * This is because otherwise "configurationsMiddleware.edit" request could still return old data in case it finishes before the relationship is updated.
     */
    let [debug, result] = await updateAiStatusOnServer(
      aiControl,
      configuration,
      merchant,
      idToken,
      userId
    );

    if (!result || !result.success) {
      return result;
    }

    debugOperations.push(debug);
  }

  if (configurationGroups) {
    configurationGroups.forEach((configurationGroup) => {
      debugOperations.push(`Edit group ${configurationGroup.group_id}`);
      arrayOfPromises.push(async () => {
        return await groupsMiddleware.edit(
          idToken,
          merchant as string,
          market as string,
          configurationGroup.group_id,
          userId,
          {
            name: configurationGroup.name,
            group_type: configurationGroup.group_type,
            group_definition: configurationGroup.group_definition,
          }
        );
      });
    });
  }

  const groupsPromiseResult = await Promise.allSettled(
    arrayOfPromises.map((func) => func())
  );

  let failed = groupsPromiseResult.find(
    (
      result: PromiseSettledResult<
        | PostPutConfigurationsApiResult
        | PostPutGroupApiResult
        | RelationshipsApiResult
      >
    ) => {
      return result.status === "rejected" || !result.value.success;
    }
  );

  if (failed) {
    return (failed as PromiseFulfilledResult<any>).value;
  }

  debugOperations.push(`Edit configuration ${configuration.id}`);
  let configurationData = await configurationsMiddleware.edit(
    idToken,
    merchant as string,
    configuration.id,
    userId,
    configuration
  );

  if (!configurationData.success) {
    return configurationData;
  }

  //@ts-ignore
  const groupsData = groupsPromiseResult.map((result) => {
    //@ts-ignore
    return result.value.data;
  });

  console.log(
    "%c[CONFIGURATION] Run operations...",
    "color: chocolate",
    debugOperations
  );

  return {
    success: true,
    data: {
      configuration: configurationData.data,
      groups: groupsData,
    },
  };
};

export const getConfigurationStartDate = (): Date => {
  let d = new Date();
  //d.setDate(d.getDate() + 1); //tomorrow
  d.setDate(d.getDate()); //today
  d.setHours(0, 0, 0, 0); // set it to beginning of the day
  return d;
};

export const getConfigurationAiState = (
  configuration: Configuration | null
): AiState => {
  if (
    !configuration ||
    !configuration.relationships ||
    !configuration.relationships.length
  ) {
    return getNullAiState();
  }

  let aiRelationship = getRelationshipByType(configuration, "only_include");

  if (!aiRelationship) {
    return { view: false, currentConf: false };
  }

  return { view: true, currentConf: true };
};

export const enhanceGroupsWithGroupsDefinitions = async (
  groups: string[],
  merchant: string,
  market: string,
  idToken: string,
  userId: string | undefined
): Promise<ApiResult<ConfiguredProductGroup[]>> => {
  let arrayOfPromises: Array<Function> = [];

  groups.forEach((group: string) => {
    arrayOfPromises.push(async () => {
      return await groupsMiddleware.getGroup(
        idToken,
        merchant,
        market,
        group,
        userId
      );
    });
  });

  const results = await Promise.allSettled(
    arrayOfPromises.map((func) => func())
  );

  let failed = results.find(
    (result: PromiseSettledResult<GetGroupApiResult>) => {
      return result.status === "rejected" || !result.value.success;
    }
  );

  if (failed) {
    return (failed as PromiseFulfilledResult<any>).value;
  }

  let enhanced: ConfiguredProductGroup[] = results.map(
    (result: PromiseSettledResult<GetGroupApiResult>) => {
      return result.status !== "rejected" && result.value.success
        ? result.value.data
        : ({} as ConfiguredProductGroup);
    }
  );

  return {
    success: true,
    data: enhanced,
  };
};

export const loadConfigurationGroups = async (
  relationships: Relationship[],
  merchant: string,
  market: string,
  idToken: string,
  userId: string | undefined
): Promise<ApiResult<ConfiguredProductGroup[]>> => {
  const configurationGroups = getConfigurationGroupIds(relationships);

  const enhanced = await enhanceGroupsWithGroupsDefinitions(
    configurationGroups,
    merchant,
    market,
    idToken,
    userId
  );

  return enhanced;
};

export const getUpdatedGroups = (
  allGroups: Omit<ConfiguredProductGroup, "group_definition">[],
  newGroups: ConfiguredProductGroup[],
  operation: "append" | "remove" | "update"
): Omit<ConfiguredProductGroup, "group_definition">[] | null => {
  let proxy = deepCopy(allGroups);

  if (operation === "remove") {
    return null;
  }

  newGroups.forEach(
    (group: Partial<ConfiguredProductGroup> | ConfiguredProductGroup) => {
      switch (operation) {
        case "append":
          if (group.group_definition) {
            delete group.group_definition;
          }
          proxy.push(group as Omit<ConfiguredProductGroup, "group_definition">);
          break;
        case "update":
          const groupIndex = proxy.findIndex((proxied) => {
            return (
              proxied.group_id === group.group_id &&
              typeof group.group_id !== "undefined"
            );
          });
          if (groupIndex === -1) {
            return;
          }
          if (group.group_definition) {
            delete group.group_definition;
          }
          proxy.splice(
            groupIndex,
            1,
            group as Omit<ConfiguredProductGroup, "group_definition">
          );
          break;
      }
    }
  );

  return proxy;
};

export const isTodayWithinSchedule = (
  start: number | null,
  end: number | null
): boolean => {
  let startDate = start;
  if (startDate === null) {
    startDate = 0;
  }

  let endDate = end;
  if (endDate === null) {
    //https://medium.com/@nate510/the-2286-bug-65697bb1b908
    endDate = 2147483647;
  }

  const today = toUtc(new Date());
  const timestamp = Math.floor(+today / 1000);

  return timestamp >= startDate && timestamp <= endDate;
};

export const getAnonymousConfigurationCopy = (
  configuration: Configuration
): Omit<Configuration, "merchant_id" | "id"> => {
  let copy = {
    markets: configuration?.markets,
    display_name: `[Copy] ${configuration?.display_name}`,
    is_empty: configuration.is_empty,
    surfaces: configuration?.surfaces,
    validity_start_timestamp: configuration?.validity_start_timestamp,
    validity_end_timestamp: configuration?.validity_end_timestamp,
    state: "inactive" as ConfigurationState,
    relationships: [],
  };
  return copy;
};

export const getAnonymousGroupCopy = (
  group: ConfiguredProductGroup
): PostGroupsApiParams => {
  return {
    group_type: group.group_type,
    name: "[COPY] " + group.name,
    group_definition: group.group_definition,
  };
};

export const createConfigurationCopy = async (
  configuration: Configuration,
  idToken: string,
  userId: string | undefined
): Promise<PostPutConfigurationsApiResult> => {
  let configurationCopy = getAnonymousConfigurationCopy(configuration);
  let createRequest = await configurationsMiddleware.create(
    idToken,
    configuration?.merchant_id,
    userId,
    configurationCopy
  );
  return createRequest;
};

export const createGroupsCopy = async (
  configuration: Configuration,
  aiState: boolean,
  market: string,
  idToken: string,
  userId: string | undefined
): Promise<ApiResult<{
  from: ConfiguredProductGroup;
  includeTo: ConfiguredProductGroup;
  excludeTo: ConfiguredProductGroup;
}> | null> => {
  const result = await loadConfigurationGroups(
    configuration.relationships,
    configuration?.merchant_id,
    market,
    idToken,
    userId
  );

  if (!result.success) {
    return result;
  }

  const configurationGroups = result.data;

  let from = getRelationshipGroup(
    aiState ? "only_include" : "must_include",
    "from_group",
    configuration.relationships,
    configurationGroups
  );
  let includeTo = getRelationshipGroup(
    aiState ? "only_include" : "must_include",
    "to_group",
    configuration.relationships,
    configurationGroups
  );
  let excludeTo = getRelationshipGroup(
    "do_not_include",
    "to_group",
    configuration.relationships,
    configurationGroups
  );

  if (!from || !includeTo || !excludeTo) {
    return null;
  }

  let response = await createGroupsSet(
    configuration.merchant_id,
    market,
    {
      from: getAnonymousGroupCopy(from),
      includeTo: getAnonymousGroupCopy(includeTo),
      excludeTo: getAnonymousGroupCopy(excludeTo),
    },
    idToken,
    userId
  );

  if (!response.success) {
    return response;
  }

  return {
    success: true,
    data: response.data,
  };
};

export const isGroupEmpty = (group: ConfiguredProductGroup): boolean => {
  return !group.group_definition || !group.group_definition.length;
};

export const haveFromPanelDisabled = (
  surfaces: Surface[] | null,
  selectedSurfaces: string[] | undefined
): boolean => {
  if (!selectedSurfaces || !selectedSurfaces.length) {
    return false;
  }

  let flag = true;
  surfaces?.forEach((surface: Surface) => {
    if (
      selectedSurfaces.includes(surface.depict_name ?? "") &&
      surface.kind !== "user_only"
    ) {
      flag = false;
      return;
    }
  });

  return flag;
};

export const getSurfacePopoverData = (
  surface: string,
  surfaces: Surface[] | null,
  updated: string[],
  refs: { [surface: string]: any },
  open: boolean
): PopoverControlData => {
  const id = surface.replace(/\s/g, "") + "-surfaces-input";
  const ref = refs.current[id];
  const selectedSurface = surfaces?.find(
    (s: Surface) => s.depict_name === surface
  );
  const title = selectedSurface?.display_name ?? surface;
  const action = updated?.includes(surface) ? "Selecting" : "De-selecting";
  const htmlTitle = (
    <>
      {action} {title} will clear
      <br />
      <strong className="text-black">"For these"</strong>
    </>
  );
  return {
    ref,
    open,
    title: htmlTitle,
  };
};

export const getItemIndex = (
  definition: ConfiguredProductGroupDefinition,
  index: string
): number | null => {
  const i = definition.findIndex(
    (item: MainProductIdObj | ProductIdObj | GroupIdObj) => {
      const idKey = getListItemIdKeyFromIdObj(item);
      // @ts-ignore
      const id = item[idKey];
      return id === index;
    }
  );

  return i === -1 ? null : i;
};

export const getSaveModalId = (
  state: ConfigurationState,
  startTimestamp: number | null,
  endTimestamp: number | null
): string => {
  if (state === "indefinite") {
    return "becoming-active";
  }

  if (state === "inactive") {
    return "becoming-inactive";
  }

  if (state === "scheduled") {
    const currentTimestamp = Math.floor(+toUtc(new Date()) / 1000);
    const st = startTimestamp || 0;
    const et = endTimestamp || 2147483647; //max unix value
    if (currentTimestamp >= st && currentTimestamp <= et) {
      return "becoming-active";
    } else {
      return "becoming-scheduled";
    }
  }

  return "save";
};

export const updateOnViewGroups = (
  prevValue: ConfiguredProductGroup[] | null,
  data: ConfiguredProductGroup
): ConfiguredProductGroup[] => {
  let updatedConfigurationGroups: ConfiguredProductGroup[] | null;
  if (!prevValue) {
    updatedConfigurationGroups = [];
  } else {
    updatedConfigurationGroups = deepCopy(prevValue);
  }

  let targetIndex = updatedConfigurationGroups.findIndex(
    (el: ConfiguredProductGroup) => el.group_id === data.group_id
  );

  if (targetIndex === -1) {
    updatedConfigurationGroups.push(data);
  } else {
    updatedConfigurationGroups[targetIndex] = data;
  }

  return updatedConfigurationGroups;
};

import { useEffect, useMemo } from "react";
import "src/assets/scss/depict/components/home.scss";
import SidebarLegend from "src/components/mix/SidebarLegend";
import Highlights from "src/components/sections/Highlights";
import SectionFilters from "src/components/sections/SectionFilters";
import SectionMiniGraphs from "src/components/sections/SectionMiniGraphs";
/**
 * Components
 */
import { css } from "@emotion/react";
import SectionRevenue from "src/components/sections/SectionRevenue";
import SectionTable from "src/components/sections/SectionTable";
import useLoadingQueue from "src/helpers/hooks/app/useLoadingQueue";
import useMarket from "src/helpers/hooks/app/useMarket";
import { useMerchant } from "src/helpers/hooks/app/useMerchant";
import useCompare from "src/helpers/hooks/dashboard/useCompare";
import useEnabledSurfaces from "src/helpers/hooks/dashboard/useEnabledSurfaces";
import usePeriod from "src/helpers/hooks/dashboard/usePeriod";
import useSplitBySurface from "src/helpers/hooks/dashboard/useSplitBySurface";
import useMetric from "src/helpers/hooks/metrics/useMetric";
import useCachedSurfaces from "src/helpers/hooks/surfaces/useCachedSurfaces";
import useEnhancedSurfaces from "src/helpers/hooks/surfaces/useEnhancedSurfaces";
import useSurfaces from "src/helpers/hooks/surfaces/useSurfaces";
import useUser from "src/helpers/hooks/useUser";
import useWindowDimensions from "src/helpers/hooks/useWindowDimensions";
import { useTrackPageView } from "../helpers/hooks/mix/useSegment";
import ProtectedRoute from "../router/ProtectedRoute";

const Home = () => {
  const { market, availableMarkets: markets, setMarket } = useMarket();

  const [_, controlLoadingQueueCB] = useLoadingQueue();
  const { user } = useUser();

  const { merchant } = useMerchant();

  const merchantId = merchant?.id ?? null;

  const { width } = useWindowDimensions();

  useTrackPageView("Home");

  const [period, setPeriodCB] = usePeriod();
  const [compare, setCompareCB] = useCompare();
  const [splitBySurface, setSplitBySurfaceCB] = useSplitBySurface();
  const [enabledSurfaces, setEnabledSurfacesCB] = useEnabledSurfaces();

  const [revenue, revenueCompare] = useMetric(
    "revenue",
    merchantId,
    market,
    period,
    compare,
    splitBySurface,
    controlLoadingQueueCB
  );
  const [purchases, purchasesCompare] = useMetric(
    "purchases",
    merchantId,
    market,
    period,
    compare,
    splitBySurface,
    controlLoadingQueueCB
  );
  const [clicks, clicksCompare] = useMetric(
    "clicks",
    merchantId,
    market,
    period,
    compare,
    splitBySurface,
    controlLoadingQueueCB
  );
  const [addsToCart, addsToCartCompare] = useMetric(
    "adds-to-cart",
    merchantId,
    market,
    period,
    compare,
    splitBySurface,
    controlLoadingQueueCB
  );

  const [getCachedSurfaces] = useCachedSurfaces(
    splitBySurface,
    merchantId,
    enabledSurfaces
  );
  const [merchantSurfaces] = useSurfaces(merchantId, controlLoadingQueueCB);
  const [enhancedSurfaces] = useEnhancedSurfaces(
    merchantSurfaces,
    splitBySurface,
    compare
  );

  // Filter out surfaces that are not present in the data,
  // Mostly for Tinybird merchants who don't have attribution data per recommendation surface
  // but instead have everything merged into "recommend"
  const legendVisibleSurfaces = useMemo(() => {
    if (!enhancedSurfaces?.length || !splitBySurface) return enhancedSurfaces;
    const surfaceIds: Set<string> = new Set();

    for (const market of Object.keys(revenue || {})) {
      for (const surface of Object.keys(revenue![market].attributed)) {
        surfaceIds.add(surface);
      }
    }

    for (const market of Object.keys(clicks || {})) {
      for (const surface of Object.keys(clicks![market].attributed)) {
        surfaceIds.add(surface);
      }
    }

    for (const market of Object.keys(purchases || {})) {
      for (const surface of Object.keys(purchases![market].attributed)) {
        surfaceIds.add(surface);
      }
    }

    for (const market of Object.keys(addsToCart || {})) {
      for (const surface of Object.keys(addsToCart![market].attributed)) {
        surfaceIds.add(surface);
      }
    }

    return enhancedSurfaces.filter((s) => s.alias && surfaceIds.has(s.alias));
  }, [
    enhancedSurfaces,
    splitBySurface,
    revenue,
    clicks,
    purchases,
    addsToCart,
  ]);
  useEffect(() => {
    if (!enhancedSurfaces?.length) {
      setEnabledSurfacesCB(null, "set");
      return;
    }

    const cachedSurfaces = getCachedSurfaces();
    if (cachedSurfaces && splitBySurface) {
      setEnabledSurfacesCB(cachedSurfaces, "set");
      return;
    }
    setEnabledSurfacesCB(enhancedSurfaces, "set");

    return () => {
      setEnabledSurfacesCB(null, "set");
    };

    //eslint-disable-next-line
  }, [
    enhancedSurfaces,
    getCachedSurfaces,
    setEnabledSurfacesCB,
    //splitBySurface, //explicitly ignored
  ]);

  const activeMarket = useMemo(() => {
    return markets.find((m) => m.id === market);
  }, [market, markets]);

  const startTimestamp = useMemo(() => {
    return merchant?.start_timestamp ?? null;
  }, [merchant]);

  const currencyCode = useMemo(() => {
    return activeMarket?.currency?.currency_code ?? null;
  }, [activeMarket]);

  const currencySymbol = useMemo(() => {
    return activeMarket?.currency?.symbol ?? null;
  }, [activeMarket]);

  const symbolLeading = useMemo(() => {
    return activeMarket?.currency?.symbol_is_leading ?? null;
  }, [activeMarket]);

  return (
    <ProtectedRoute
      user={user}
      merchant={merchant}
      accessValidator={() => true}
    >
      <div
        css={css`
          @media (min-width: 1199px) {
            margin-right: 150px;
          }
        `}
      >
        {merchant?.id && (
          <div className="depict--MainContent container-fluid mt-4">
            <div className="row">
              <div
                className="col align-self-center text-end"
                style={{ fontSize: "0.875rem" }}
              >
                Currency: {currencyCode || ""}
              </div>
            </div>

            <SectionFilters
              period={period}
              compare={compare}
              splitBySurface={splitBySurface}
              market={market}
              startTimestamp={startTimestamp}
              availableMarkets={markets}
              setSplitBySurfaceCB={setSplitBySurfaceCB}
              setPeriodCB={setPeriodCB}
              setCompareCB={setCompareCB}
              setMarketCB={setMarket}
            />

            <div className="depict--LegendSidebar">
              <SidebarLegend
                items={legendVisibleSurfaces}
                enabled={enabledSurfaces}
                setEnabledSurfacesCB={setEnabledSurfacesCB}
              />
            </div>

            <Highlights
              revenue={revenue}
              currencyCode={currencyCode}
              currencySymbol={currencySymbol}
              symbolLeading={symbolLeading}
              clicks={clicks}
              purchases={purchases}
              revenueCompare={revenueCompare}
              clicksCompare={clicksCompare}
              purchasesCompare={purchasesCompare}
              market={market}
              compare={compare}
              enabledSurfaces={enabledSurfaces}
            />

            <SectionRevenue
              metric={revenue}
              compareMetric={revenueCompare}
              colors={enhancedSurfaces || []}
              currencyCode={currencyCode}
              currencySymbol={currencySymbol}
              symbolLeading={symbolLeading}
              market={market}
              compare={compare}
              enabledSurfaces={enabledSurfaces}
              splitBySurface={splitBySurface}
            />

            <SectionTable
              metrics={{
                clicks,
                addsToCart,
                purchases,
                revenue,
              }}
              metricsCompare={{
                clicks: clicksCompare,
                addsToCart: addsToCartCompare,
                purchases: purchasesCompare,
                revenue: revenueCompare,
              }}
              currencyCode={currencyCode}
              currencySymbol={currencySymbol}
              symbolLeading={symbolLeading}
              splitBySurface={splitBySurface}
              market={market}
              merchant={merchant?.id}
              period={period}
              compare={compare}
              enabledSurfaces={enabledSurfaces}
            />

            <SectionMiniGraphs
              colors={enhancedSurfaces}
              clicks={clicks}
              clicksCompare={clicksCompare}
              addsToCart={addsToCart}
              addsToCartCompare={addsToCartCompare}
              checkouts={null}
              checkoutsCompare={null}
              purchases={purchases}
              purchasesCompare={purchasesCompare}
              market={market}
              compare={compare}
              enabledSurfaces={enabledSurfaces}
              splitBySurface={splitBySurface}
              windowWidth={width}
            />
          </div>
        )}
      </div>
    </ProtectedRoute>
  );
};

export default Home;

import { Surface } from "src/types/surfaces";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  getSurfacePopoverData,
  haveFromPanelDisabled,
  updateSelectedSurfaces,
} from "src/helpers/configuration";
import { Archery, Cross, Trash } from "../linearicons";
import reactFastCompare from "react-fast-compare";
import Popover from "src/components/mix/Popover";
import {
  PopoverControlData,
  SurfacesSelectorProps,
} from "src/types/components";
import useOpenHelpToSection from "src/helpers/hooks/mix/useOpenHelpToSection";

const SurfacesSelector = (props: SurfacesSelectorProps) => {
  const {
    enabledSurfaces,
    surfaces,
    fromHasMultipleItems,
    groupClearCB,
    configurationModifyCB,
    notifyOfWarningPopupOnScreenCB,
    toggleHelpModalCB,
  } = props;
  const surfacesRef = useRef<{ [surface: string]: any }>({});
  const helpSection = useOpenHelpToSection(toggleHelpModalCB);
  const [popover, setPopover] = useState<PopoverControlData>(null);
  const [popupCallback, setPopupCallback] = useState<Function | null>(null);

  useEffect(() => {
    surfacesRef.current = {};
  }, [surfaces]);

  const updatelocalSurfaces = useCallback(
    (surface: string | null) => {
      if (!surface) {
        return;
      }

      if (!configurationModifyCB) {
        return;
      }

      const updated = updateSelectedSurfaces(surface, enabledSurfaces);
      const fromPanelIsDisabled = haveFromPanelDisabled(surfaces, updated);
      const needsWarning = fromPanelIsDisabled && fromHasMultipleItems === true;

      if (needsWarning) {
        const popoverData = getSurfacePopoverData(
          surface,
          surfaces,
          updated,
          surfacesRef,
          true
        );
        setPopover(popoverData);
        setPopupCallback(() => {
          return () => {
            groupClearCB();
            configurationModifyCB({ surfaces: updated });
          };
        });
        return;
      }

      if (fromPanelIsDisabled) {
        groupClearCB();
      }

      configurationModifyCB({ surfaces: updated });
    },
    [
      configurationModifyCB,
      enabledSurfaces,
      fromHasMultipleItems,
      groupClearCB,
      surfaces,
    ]
  );

  const cancelCallback = useCallback(() => {
    setPopover(null);
    setPopupCallback(null);
  }, []);

  const proceedCallback = useCallback(() => {
    setPopover(null);
    if (!popupCallback) {
      return;
    }
    popupCallback();
  }, [popupCallback]);

  useEffect(() => {
    if (popover) {
      notifyOfWarningPopupOnScreenCB(true);
    } else {
      notifyOfWarningPopupOnScreenCB(false);
    }
  }, [popover, notifyOfWarningPopupOnScreenCB]);

  return (
    <>
      <div className="depict--ConfigurationSettings__title">Surfaces</div>
      <div className="depict--ConfigurationSettings__subtitle mb-2">
        Set which surfaces your configuration will affect.
      </div>
      <div
        className="depict--ConfigurationSettings__form"
        data-tc="surfaces-select"
      >
        <div className="row">
          {surfaces &&
            surfaces.map((surface: Surface, index: number) => {
              let id =
                surface.depict_name?.replace(/\s/g, "") + "-surfaces-input";

              return (
                <div
                  className={surfaces.length > 5 ? "col-xl-6" : "col-12"}
                  key={index}
                >
                  <div className="form-check" title={surface.display_name}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={surface.depict_name || undefined}
                      id={id}
                      checked={
                        enabledSurfaces &&
                        enabledSurfaces.includes(surface.depict_name || "")
                      }
                      onChange={() => {
                        updatelocalSurfaces(surface.depict_name);
                      }}
                    />
                    <label
                      ref={(el) => (surfacesRef.current[id] = el)}
                      id={id + "__label"}
                      className="form-check-label d-flex depict--Surface"
                      htmlFor={id}
                    >
                      <span className="me-1 depict--Surface__name">
                        {surface.display_name}
                      </span>
                      {surface.kind !== "user_only" && (
                        <span className="depict--Surface__icon">
                          <Archery size={12} />
                        </span>
                      )}
                    </label>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <Popover
        className="text-center depict--Popover__depict-red"
        triggerRef={popover?.ref}
        isVisible={popover?.open ?? false}
        placement="right"
        title={popover?.title ?? ""}
        closeOnClickCB={cancelCallback}
      >
        <button
          onClick={() => {
            helpSection("for-these-products-categories");
          }}
          className="link-depict-brown text-decoration-underline btn btn-link p-0 mb-4"
          style={{ fontSize: ".688rem" }}
        >
          Learn more
        </button>
        <br />
        <button
          className="btn btn-sm btn-outline-depict-brown rounded-pill me-2"
          onClick={cancelCallback}
        >
          <Cross size="12" className="me-2" />
          Cancel
        </button>
        <button
          className="btn btn-sm btn-depict-red rounded-pill me-2"
          onClick={proceedCallback}
        >
          <Trash size="12" className="me-2" />
          Yes, clear
        </button>
      </Popover>
    </>
  );
};

export default React.memo(SurfacesSelector, reactFastCompare);

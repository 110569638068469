import {
  InfiniteData,
  UseInfiniteQueryResult,
  UseQueryResult,
} from "@tanstack/react-query";
import { useState, useEffect, useCallback } from "react";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import AddProductsModalListView from "../AddProductsModalListView/AddProductsModalListView";
import WarningAlert from "../Alert/Alerts/WarningAlert";
import { Checkbox } from "../Checkbox/Checkbox";
import { Modal as DepictModal } from "../Modal/Modal";
import { Product } from "../types";
import { LoadingAndErrorState } from "./LoadingAndErrorState";
import "./AddProductsModal.scss";
import { SearchFilter } from "@depict-ai/types/api/SearchResponse";
import { Filters, SelectedFilter } from "./Filters";
import SearchBar from "../SearchBar/SearchBar";
import {
  SearchProductsResponse,
  PortalSearchFilter,
  DeepAny,
} from "src/api/types";

const DONT_ALLOW_SELECT_ALL_MSG = "Too many products to select all";

export type TooManyProductInfo = {
  futureNbProducts: number;
  maxNbProducts: number;
  nbProductsTryingToAdd: number;
};

export type OnSave = (data: { addedIds: string[] }) => Promise<void>;

export interface AddProductsModalV2Props {
  searchTerm: string;
  selectedFilters: SelectedFilter[];
  productsQuery: UseInfiniteQueryResult<
    InfiniteData<SearchProductsResponse | undefined, unknown>,
    Error
  >;
  availableFiltersQuery: UseQueryResult<SearchFilter[] | undefined | null>;
  onSave: OnSave;
  onNewSearchOrFilter: (selectedSearchAndFilters: {
    searchText: string;
    selectedFilters: SelectedFilter[];
  }) => void;
  show: boolean;
  onClose: (addedProductsSinceOpen: string[]) => void;
  maxNbProducts: number;
  currentNbProducts: number;
  currentProductIds: string[];
  onTooManyProductsSelected: (info: TooManyProductInfo) => void;
  type: "collection" | "category";
}

export const AddProductsModal = (props: AddProductsModalV2Props) => {
  const {
    productsQuery,
    availableFiltersQuery,
    onSave,
    onNewSearchOrFilter,
    show,
    onClose,
    maxNbProducts,
    currentNbProducts,
    onTooManyProductsSelected,
    currentProductIds,
  } = props;
  const [checkedProducts, setCheckedProducts] = useState<string[]>([]);
  const [addedProductsSinceOpen, setAddedProductsSinceOpen] = useState<
    string[]
  >([]);

  const { searchTerm, selectedFilters } = props;

  const allProducts = productsQuery.data?.pages
    ?.map(
      (p) => p?.products.filter((p) => typeof p !== "undefined") as Product[]
    )
    .flat();

  const resetFiltersAndSearch = useCallback(() => {
    onNewSearchOrFilter({
      searchText: "",
      selectedFilters: [],
    });
  }, [onNewSearchOrFilter]);

  useEffect(() => {
    if (show) {
      resetFiltersAndSearch();
    }
  }, [resetFiltersAndSearch, show]);

  const onAddCB = (productId: string, checked: boolean) => {
    if (checkedProducts.length + currentNbProducts >= maxNbProducts) {
      onTooManyProductsSelected({
        futureNbProducts: checkedProducts.length + currentNbProducts + 1,
        maxNbProducts,
        nbProductsTryingToAdd: 1,
      });
      return;
    }
    if (!checked) {
      setCheckedProducts((prev) => [...prev, productId]);
    } else {
      setCheckedProducts((prev) => prev.filter((p) => p !== productId));
    }
  };

  const closeModal = () => {
    resetCheckedProducts();
    onClose(addedProductsSinceOpen);
    setAddedProductsSinceOpen([]);
  };

  const totalNrOfProducts =
    (productsQuery.data?.pages
      ? productsQuery.data?.pages[productsQuery.data?.pages?.length - 1]?.hits
      : allProducts?.length) ?? 0;

  const isSelectAllChecked = !!(
    allProducts &&
    allProducts.filter((p) => checkedProducts.includes(p.main_product_id))
      .length === allProducts.length
  );

  const allowSelectAll = !!(
    productsQuery &&
    allProducts &&
    totalNrOfProducts + currentNbProducts <= maxNbProducts
  );

  const onSelectAllChanged = (currentChecked: boolean) => {
    if (!allowSelectAll) {
      return;
    }
    if (currentChecked) {
      setCheckedProducts([]);
    } else {
      setCheckedProducts(allProducts?.map((p) => p.main_product_id) || []);
    }
  };

  const selectionChanged = checkedProducts.length;

  const resetCheckedProducts = () => {
    setCheckedProducts([]);
  };

  const nbAddedInCurrentSession =
    allProducts?.filter((p) => checkedProducts.includes(p.main_product_id))
      .length || 0;

  const tooManyProductsSelected =
    currentNbProducts + nbAddedInCurrentSession > maxNbProducts;

  const [availableFilters, setAvailableFilters] = useState<
    DeepAny<PortalSearchFilter>[] | undefined
  >(undefined);

  useEffect(() => {
    if (productsQuery.data?.pages?.[0]?.filters) {
      setAvailableFilters(productsQuery.data?.pages?.[0]?.filters);
    }
  }, [productsQuery.data?.pages]);

  const onSaveCB = useCallback(async () => {
    setAddedProductsSinceOpen((prev) => [...prev, ...checkedProducts]);
    await onSave({
      addedIds: checkedProducts,
    });
    resetCheckedProducts();
  }, [checkedProducts, onSave]);

  return (
    <DepictModal
      dialogClassName={"add-products-modal"}
      contentClassName="add-products-modal-content"
      className="depict--Modal"
      show={show}
      onClose={closeModal}
    >
      <Modal.Body
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Col className="depict--FilterContainer me-3" xs={4}>
          {availableFiltersQuery.isError && (
            <p>
              Could not load filters :(
              <br />
              Please try refreshing the page
            </p>
          )}
          {(availableFiltersQuery.isLoading ||
            availableFiltersQuery.isSuccess) && (
            <Filters
              availableFilters={availableFilters}
              onFilterChange={(newFilters) => {
                onNewSearchOrFilter({
                  searchText: searchTerm,
                  selectedFilters: newFilters,
                });
              }}
            />
          )}
        </Col>
        <Col xs={8} className="d-flex flex-column position-relative">
          <Row>
            <Col className="w-100">
              <Row>
                <h3 className="fw-bolder">
                  Choose product(s) to add to the {props.type}
                </h3>
              </Row>
              <Row className="mb-2 pt-1">
                <Col className="pe-4">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1 me-2">
                      <SearchBar
                        onChange={(newTerm) => {
                          onNewSearchOrFilter({
                            searchText: newTerm,
                            selectedFilters,
                          });
                        }}
                        placeholder="Search for product name..."
                      />
                    </div>
                    <Button
                      disabled={!selectionChanged}
                      className="py-3"
                      onClick={onSaveCB}
                    >
                      {`Add products ${
                        nbAddedInCurrentSession !== 0
                          ? `(${nbAddedInCurrentSession})`
                          : ""
                      }`}
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                {tooManyProductsSelected && (
                  <WarningAlert
                    title={`You have exceeded the limit of ${maxNbProducts} products allowed in a ${props.type}`}
                    message={`Remove some products to be able to add more.`}
                  />
                )}
              </Row>
              <Row style={{ padding: "10px 12px" }}>
                <Col>
                  <label
                    className="d-flex align-items-center gap-2"
                    style={{
                      cursor: allowSelectAll ? "pointer" : "not-allowed",
                      opacity: allowSelectAll ? 1 : 0.6,
                    }}
                  >
                    <Checkbox
                      disabled={!allowSelectAll}
                      checked={isSelectAllChecked}
                      onChange={onSelectAllChanged}
                      style={{
                        cursor: allowSelectAll ? "pointer" : "not-allowed",
                      }}
                    />
                    {allProducts ? `${totalNrOfProducts} products` : ""}
                    {allowSelectAll || !allProducts ? (
                      ""
                    ) : (
                      <div
                        style={{ opacity: 0.75 }}
                      >{` (${DONT_ALLOW_SELECT_ALL_MSG})`}</div>
                    )}
                  </label>
                </Col>
              </Row>
            </Col>
          </Row>
          <LoadingAndErrorState queryResults={productsQuery} />
          {productsQuery.isSuccess && allProducts && (
            <AddProductsModalListView
              displayedProducts={allProducts || []}
              addedProducts={currentProductIds}
              checkedProducts={checkedProducts}
              onAddCB={onAddCB}
              onScrollAtBottom={() => {
                if (
                  !productsQuery.isFetchingNextPage &&
                  productsQuery.hasNextPage
                ) {
                  console.log(`Should fetch next page`);
                  productsQuery.fetchNextPage();
                }
              }}
              {...props}
            />
          )}
          {productsQuery.isFetchingNextPage && (
            <div
              className="d-flex position-absolute w-100 justify-content-center"
              style={{ bottom: 0 }}
            >
              <Spinner animation="border" />
            </div>
          )}
        </Col>
      </Modal.Body>
    </DepictModal>
  );
};

export default AddProductsModal;

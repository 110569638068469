import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { getData } from "src/api/authorizedApi";
import { PortalSortModel, RequiredPortalSearchFilter } from "src/api/types";
import { SelectedFilter } from "../../components/storybook/AddProductsModal/Filters";
import { QueryId, getQueryKey } from "../../queries/queries";
import useAuthorizedApi from "../hooks/app/useAuthorizedApi";
import useProductInbox from "../hooks/useProductInbox";

export const DEFAULT_SORT = {
  field: "_relevance",
  order: "desc" as const,
};

export default function useProductCatalog({
  merchantId,
  currentView,
  searchTerm,
  selectedFilters,
  selectedSort,
}: {
  merchantId: string | null;
  currentView: "inbox" | "all" | "out-of-stock";
  searchTerm: string;
  selectedFilters: SelectedFilter[];
  selectedSort: PortalSortModel;
}) {
  const { api } = useAuthorizedApi();
  const { inboxMainProductIds } = useProductInbox(merchantId);
  const out_of_stock_filter: RequiredPortalSearchFilter = {
    field: "all_sizes_out_of_stock",
    op: "eq",
    data: true,
  };

  const { data: totalNumberOfProducts } = useQuery({
    queryKey: getQueryKey(QueryId.GetNumberOfProducts, merchantId || ""),
    queryFn: async () => {
      if (!api || !merchantId) return 0;
      const response = await api.POST(
        "/api/v0/catalog/products/{merchant_id}/search",
        {
          params: {
            path: {
              merchant_id: merchantId,
            },
          },
          body: {
            query: "",
            filters: [],
            limit: 0,
          },
        }
      );

      const all = getData(response);

      return all.hits;
    },

    enabled: !!api && !!merchantId,
    staleTime: Infinity,
  });

  const { data: totalNumberOfOutOfStockProducts } = useQuery({
    queryKey: getQueryKey(QueryId.GetNumberOfSalesProducts, merchantId || ""),
    queryFn: async () => {
      if (!api || !merchantId) return 0;
      const response = await api.POST(
        "/api/v0/catalog/products/{merchant_id}/search",
        {
          params: {
            path: {
              merchant_id: merchantId,
            },
          },
          body: {
            query: "",
            filters: [],
            limit: 0,
            required_filters: [out_of_stock_filter],
          },
        }
      );

      const all = getData(response);

      return all.hits;
    },

    enabled: !!api && !!merchantId,
    staleTime: Infinity,
  });

  const filteredProductsQuery = useInfiniteQuery({
    queryKey: getQueryKey(
      QueryId.ProductCatalogSearchAndFilterProvider,
      merchantId || "",
      searchTerm,
      selectedFilters,
      inboxMainProductIds || [],
      selectedSort,
      currentView
    ),
    queryFn: async (context: { pageParam: undefined | string }) => {
      if (!api || !merchantId) return;

      const response = await api.POST(
        "/api/v0/catalog/products/{merchant_id}/search",
        {
          params: {
            path: {
              merchant_id: merchantId,
            },
          },
          body: {
            query: searchTerm,
            filters: selectedFilters,
            cursor: context.pageParam,
            sort: selectedSort,
            limit: 50,
            allowed_main_product_ids:
              currentView === "inbox" ? inboxMainProductIds : undefined,
            required_filters:
              currentView === "out-of-stock"
                ? [out_of_stock_filter]
                : undefined,
          },
        }
      );

      const data = getData(response);

      return data;
    },
    initialPageParam: undefined,
    enabled: !!api && !!merchantId,
    gcTime: 0,
    getNextPageParam: (lastPage) => lastPage?.cursor ?? undefined,
  });

  return {
    totalNumberOfProducts,
    totalNumberOfOutOfStockProducts,
    filteredProductsQuery,
  };
}

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Contacts = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M16.5 1h-13c-0.827 0-1.5 0.673-1.5 1.5v1.5h-0.5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h0.5v1h-0.5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h0.5v1h-0.5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h0.5v1h-0.5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h0.5v1h-0.5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h0.5v1h-0.5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h0.5v1h-0.5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h0.5v1.5c0 0.827 0.673 1.5 1.5 1.5h13c0.827 0 1.5-0.673 1.5-1.5v-16c0-0.827-0.673-1.5-1.5-1.5zM17 18.5c0 0.276-0.224 0.5-0.5 0.5h-13c-0.276 0-0.5-0.224-0.5-0.5v-1.5h1.5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-1.5v-1h1.5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-1.5v-1h1.5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-1.5v-1h1.5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-1.5v-1h1.5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-1.5v-1h1.5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-1.5v-1h1.5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-1.5v-1.5c0-0.276 0.224-0.5 0.5-0.5h13c0.276 0 0.5 0.224 0.5 0.5v16z" fill={color}></path>
<path d="M11.114 15.129c-0 0-0 0-0.001 0-0.992 0-3.346-0.015-3.769-0.154-0.432-0.142-0.65-0.622-0.554-1.223 0.103-0.647 0.533-1.324 1.149-1.811 0.542-0.428 1.513-0.939 3.060-0.94h0.005c1.214 0 2.264 0.318 3.037 0.921 0.623 0.486 1.046 1.139 1.16 1.793 0.106 0.609-0.103 1.102-0.532 1.256-0.12 0.043-0.332 0.092-1.342 0.127-0.596 0.021-1.382 0.032-2.213 0.032zM7.767 14.040c0.423 0.046 1.677 0.089 3.347 0.089 0 0 0.001 0 0.001 0 1.783 0 2.771-0.047 3.116-0.086 0.003-0.141-0.049-0.434-0.28-0.779-0.317-0.472-1.121-1.264-2.945-1.264h-0.004c-1.818 0.001-2.626 0.791-2.946 1.262-0.232 0.342-0.288 0.634-0.287 0.777z" fill={color}></path>
<path d="M11 10c-1.103 0-2-0.897-2-2s0.897-2 2-2c1.103 0 2 0.897 2 2s-0.897 2-2 2zM11 7c-0.551 0-1 0.449-1 1s0.449 1 1 1 1-0.449 1-1-0.449-1-1-1z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Contacts); 
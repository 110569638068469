import { useQuery } from "@tanstack/react-query";
import { getData } from "src/api/authorizedApi";
import { MetricType } from "../../../components/storybook/CatalogV2/CatalogProductSpotlight/CatalogProductSpotlight";
import { getQueryKey, QueryId } from "../../../queries/queries";
import useAuthorizedApi from "../app/useAuthorizedApi";

export const useCatalogProductSpotlights = (merchantId: string) => {
  const { api } = useAuthorizedApi();

  const { data, isLoading, isError } = useQuery({
    queryKey: getQueryKey(QueryId.GetProductSpotlightMetrics, merchantId),
    queryFn: async () => {
      if (!api) return;
      const response = await api.GET(
        "/api/v0/catalog/products/{merchant_id}/metrics",
        {
          params: {
            path: {
              merchant_id: merchantId,
            },
          },
        }
      );

      const data = getData(response);

      return data;
    },

    enabled: !!api,
  });

  const metrics = data && [
    {
      type: MetricType.Bestsellers,
      title: "Bestsellers",
      timeRange: "Last 30 days",
      products: data.best_sellers.map((m) => ({
        title: m.product.title,
        mainProductId: m.product.main_product_id,
        imageUrl: m.product.image_url,
        metricValue: m.metric,
      })),
    },
    {
      type: MetricType.MostViewed,
      title: "Most viewed",
      timeRange: "Last 30 days",
      products: data.views.map((m) => ({
        title: m.product.title,
        mainProductId: m.product.main_product_id,
        imageUrl: m.product.image_url,
        metricValue: m.metric,
      })),
    },
    {
      type: MetricType.Trending,
      title: "Trending",
      timeRange: "Last 30 days",
      products: data.trending.map((m) => ({
        title: m.product.title,
        mainProductId: m.product.main_product_id,
        imageUrl: m.product.image_url,
        metricValue: m.metric,
      })),
    },
  ];

  return {
    metrics,
    isLoading,
    isError,
  };
};

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Icons2 = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M3.5 19h-2c-0.827 0-1.5-0.673-1.5-1.5v-2c0-0.827 0.673-1.5 1.5-1.5h2c0.827 0 1.5 0.673 1.5 1.5v2c0 0.827-0.673 1.5-1.5 1.5zM1.5 15c-0.276 0-0.5 0.224-0.5 0.5v2c0 0.276 0.224 0.5 0.5 0.5h2c0.276 0 0.5-0.224 0.5-0.5v-2c0-0.276-0.224-0.5-0.5-0.5h-2z" fill={color}></path>
<path d="M10.5 19h-2c-0.827 0-1.5-0.673-1.5-1.5v-2c0-0.827 0.673-1.5 1.5-1.5h2c0.827 0 1.5 0.673 1.5 1.5v2c0 0.827-0.673 1.5-1.5 1.5zM8.5 15c-0.276 0-0.5 0.224-0.5 0.5v2c0 0.276 0.224 0.5 0.5 0.5h2c0.276 0 0.5-0.224 0.5-0.5v-2c0-0.276-0.224-0.5-0.5-0.5h-2z" fill={color}></path>
<path d="M17.5 19h-2c-0.827 0-1.5-0.673-1.5-1.5v-2c0-0.827 0.673-1.5 1.5-1.5h2c0.827 0 1.5 0.673 1.5 1.5v2c0 0.827-0.673 1.5-1.5 1.5zM15.5 15c-0.276 0-0.5 0.224-0.5 0.5v2c0 0.276 0.224 0.5 0.5 0.5h2c0.276 0 0.5-0.224 0.5-0.5v-2c0-0.276-0.224-0.5-0.5-0.5h-2z" fill={color}></path>
<path d="M3.5 12h-2c-0.827 0-1.5-0.673-1.5-1.5v-2c0-0.827 0.673-1.5 1.5-1.5h2c0.827 0 1.5 0.673 1.5 1.5v2c0 0.827-0.673 1.5-1.5 1.5zM1.5 8c-0.276 0-0.5 0.224-0.5 0.5v2c0 0.276 0.224 0.5 0.5 0.5h2c0.276 0 0.5-0.224 0.5-0.5v-2c0-0.276-0.224-0.5-0.5-0.5h-2z" fill={color}></path>
<path d="M10.5 12h-2c-0.827 0-1.5-0.673-1.5-1.5v-2c0-0.827 0.673-1.5 1.5-1.5h2c0.827 0 1.5 0.673 1.5 1.5v2c0 0.827-0.673 1.5-1.5 1.5zM8.5 8c-0.276 0-0.5 0.224-0.5 0.5v2c0 0.276 0.224 0.5 0.5 0.5h2c0.276 0 0.5-0.224 0.5-0.5v-2c0-0.276-0.224-0.5-0.5-0.5h-2z" fill={color}></path>
<path d="M17.5 12h-2c-0.827 0-1.5-0.673-1.5-1.5v-2c0-0.827 0.673-1.5 1.5-1.5h2c0.827 0 1.5 0.673 1.5 1.5v2c0 0.827-0.673 1.5-1.5 1.5zM15.5 8c-0.276 0-0.5 0.224-0.5 0.5v2c0 0.276 0.224 0.5 0.5 0.5h2c0.276 0 0.5-0.224 0.5-0.5v-2c0-0.276-0.224-0.5-0.5-0.5h-2z" fill={color}></path>
<path d="M3.5 5h-2c-0.827 0-1.5-0.673-1.5-1.5v-2c0-0.827 0.673-1.5 1.5-1.5h2c0.827 0 1.5 0.673 1.5 1.5v2c0 0.827-0.673 1.5-1.5 1.5zM1.5 1c-0.276 0-0.5 0.224-0.5 0.5v2c0 0.276 0.224 0.5 0.5 0.5h2c0.276 0 0.5-0.224 0.5-0.5v-2c0-0.276-0.224-0.5-0.5-0.5h-2z" fill={color}></path>
<path d="M10.5 5h-2c-0.827 0-1.5-0.673-1.5-1.5v-2c0-0.827 0.673-1.5 1.5-1.5h2c0.827 0 1.5 0.673 1.5 1.5v2c0 0.827-0.673 1.5-1.5 1.5zM8.5 1c-0.276 0-0.5 0.224-0.5 0.5v2c0 0.276 0.224 0.5 0.5 0.5h2c0.276 0 0.5-0.224 0.5-0.5v-2c0-0.276-0.224-0.5-0.5-0.5h-2z" fill={color}></path>
<path d="M17.5 5h-2c-0.827 0-1.5-0.673-1.5-1.5v-2c0-0.827 0.673-1.5 1.5-1.5h2c0.827 0 1.5 0.673 1.5 1.5v2c0 0.827-0.673 1.5-1.5 1.5zM15.5 1c-0.276 0-0.5 0.224-0.5 0.5v2c0 0.276 0.224 0.5 0.5 0.5h2c0.276 0 0.5-0.224 0.5-0.5v-2c0-0.276-0.224-0.5-0.5-0.5h-2z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Icons2); 
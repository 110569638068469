import { css } from "@emotion/react";
import { useTopNavContext } from "src/components/navs/TopNavProvider";
import { theme } from "src/components/storybook/designSystemVariables";
import { useElementSize } from "usehooks-ts";

interface TopBarProps {
  showBorder?: boolean;
  children?: React.ReactNode;
}
export const TopBar = ({ showBorder = true, children }: TopBarProps) => {
  const [ref] = useElementSize<HTMLDivElement>();
  const { topNavHeight, isTopNavOpen } = useTopNavContext();

  const top = isTopNavOpen ? topNavHeight : 0;

  return (
    <div
      ref={ref}
      css={css`
        position: sticky;
        top: ${top}px;
        z-index: 1000;
        height: 68px;
        padding: 12px;
        border-bottom: ${showBorder
          ? `1px solid ${theme.border.subtle.default}`
          : "none"};
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        background-color: ${theme.background.subtle.default};
      `}
    >
      {children}
    </div>
  );
};

export default TopBar;

import {
  InfiniteData,
  UseInfiniteQueryResult,
  UseQueryResult,
} from "@tanstack/react-query";
import { Modal, Row } from "react-bootstrap";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import ProductListCardPlaceholder from "../ProductListCardPlaceholder/ProductListCardPlaceholder";
import { Product } from "../types";
import { SearchProductsResponse } from "src/api/types";

const NB_ITEMS_PLACEHOLDER = 20;

const ListView = () => {
  return (
    <div
      style={{
        overflowY: "scroll",
        overflowX: "hidden",
        padding: "0 10px",
      }}
    >
      {Array.from({ length: NB_ITEMS_PLACEHOLDER }).map((_, i) => (
        <Row key={i} className="p-1 w-100">
          <div className="w-100">
            <ProductListCardPlaceholder />
          </div>
        </Row>
      ))}
    </div>
  );
};

interface LoadingAndErrorStateProps {
  queryResults:
    | UseQueryResult<Product[] | undefined, unknown>
    | UseInfiniteQueryResult<
        InfiniteData<SearchProductsResponse | undefined, unknown>,
        Error
      >;
}
export const LoadingAndErrorState = (props: LoadingAndErrorStateProps) => {
  if (props.queryResults.isSuccess) return <></>;

  if (props.queryResults.isLoading) {
    return <ListView />;
  }

  const data = props.queryResults.data;

  return (
    <Modal.Body>
      {(props.queryResults.isError || !data) && (
        <ErrorMessage message="Couldn't load products" />
      )}
    </Modal.Body>
  );
};

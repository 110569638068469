import { DepictDataPoint, NivoDataPoint } from "src/types/metrics";
import { formatDate } from "src/helpers/dates";

const filterTotalRevenue = (dataset: DepictDataPoint[]): NivoDataPoint[] => {
  var refactored: NivoDataPoint[] = [];

  for (var i = 0; i < dataset.length; i++) {
    const record = dataset[i];
    const recordRefactored = {
      x: formatDate(record["start"]),
      y: record["value"] || 0, // In the future we might want display null values differently than as zero
    } as NivoDataPoint;
    if (record["extra"]) {
      recordRefactored.extra = record["extra"];
    }
    refactored.push(recordRefactored);
  }
  return refactored;
};

export default filterTotalRevenue;

import React, { useEffect, useState } from "react";
import { AppLoaderProps, LoadingProcess } from "src/types/components";

/**
 * taken from here: https://javascript.plainenglish.io/how-to-handle-and-design-the-startup-of-a-react-application-da779f3727e5
 */

/**
 * A component used to display a loading while some processes are loading.
 */
const AppLoader = (props: AppLoaderProps) => {
  const [minimumDurationPassed, setMinimumDurationPassed] = useState<boolean>(
    (props.minimumLoadingTime || 0) <= 0
  );

  // Handle potential minimum duration
  useEffect(() => {
    if (props.minimumLoadingTime) {
      setTimeout(
        () => setMinimumDurationPassed(true),
        props.minimumLoadingTime
      );
    }
    //eslint-disable-next-line
  }, []);

  /**
   * This function was originally "every(props.mandatoryProcesses, "isReady")" imported from "lodash".
   * I wanted to get rid of the dependency and I coded something myself.
   */
  const checkIsReady = (processes: LoadingProcess[] | undefined): boolean => {
    if (typeof processes === "undefined") {
      return true;
    }

    for (var i = 0; i < processes.length; i++) {
      let process = processes[i];
      if (!process.isReady) {
        return false;
      }
    }

    return true;
  };

  return (
    <React.Fragment>
      {!props.isLoading &&
      checkIsReady(props.mandatoryProcesses) &&
      minimumDurationPassed
        ? props.children
        : props.loadingComponent}
    </React.Fragment>
  );
};

export default React.memo(AppLoader);

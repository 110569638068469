import { useCallback } from "react";
import configurationsMiddleware from "src/api/configurationsMiddleware";

import { getAlertMessage } from "src/helpers/errors";
import { useAuth0 } from "@auth0/auth0-react";
import useAuthentication from "../app/useAuthentication";
import { useAlerts } from "../../../components/storybook/Alert/useAlerts";

export default function useCreateNewConfiguration(
  merchant: string | null,
  market: string | null,
  nextConfigurationNumber: number | null,
  setCurrentConfigurationId: Function,
  refreshConfigurations: Function
) {
  const { user } = useAuth0();
  const { addAlert } = useAlerts();
  const [getAccessToken] = useAuthentication();
  const createNewConfiguration = useCallback(async () => {
    console.log(
      `%c[CONFIGURATION] Creating new configuration for ${merchant} on ${market} market`,
      "color: crimson"
    );

    if (!merchant || !market) {
      console.log(`%c[CONF] New configuration failed`, "color: crimson");
      return;
    }
    const id_token = await getAccessToken();

    let request = await configurationsMiddleware.create(
      id_token,
      merchant,
      user?.email,
      {
        display_name: `Configuration ${nextConfigurationNumber}`,
        markets: [market],
        relationships: [],
        state: "inactive",
      }
    );

    if (!request.success) {
      let message = await getAlertMessage(request.error);
      addAlert(message);
      console.log(`%c[CONF] New configuration failed`, "color: crimson");
      return;
    }

    refreshConfigurations();
    setCurrentConfigurationId(request.data.id);
  }, [
    merchant,
    market,
    getAccessToken,
    user?.email,
    nextConfigurationNumber,
    refreshConfigurations,
    setCurrentConfigurationId,
    addAlert,
  ]);

  return [createNewConfiguration];
}

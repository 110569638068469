import React, { useCallback, useMemo } from "react";
import reactFastCompare from "react-fast-compare";
import { useTranslation } from "react-i18next";
import { Comparison, HighlightsProps } from "src/types/components";
import { MetricsMarketData } from "src/types/metrics";
import { getAggregateMetricValue } from "src/helpers/metrics";
import HighlightCard from "src/components/cards/HighlightCard";
import {
  getMarketDataFromMetric,
  getSurfaceConversionRate,
  getSurfacePercentageValue,
} from "src/helpers/metrics";

const Highlights = (props: HighlightsProps) => {
  const { t } = useTranslation();

  const {
    revenue,
    purchases,
    clicks,
    revenueCompare,
    purchasesCompare,
    clicksCompare,
    currencySymbol,
    symbolLeading,
    compare,
    market,
    enabledSurfaces,
  } = props;

  const shouldCompare = useMemo(() => compare && compare !== "none", [compare]);
  const enabledSurfaceKeys = useMemo(
    () => enabledSurfaces?.map((s) => s.id),
    [enabledSurfaces]
  );

  const revenueValue: number | undefined = useMemo(() => {
    let revenueDataset = getMarketDataFromMetric(
      revenue,
      market
    ) as MetricsMarketData;

    if (!revenueDataset) {
      return;
    }

    return getAggregateMetricValue(revenueDataset, enabledSurfaceKeys);
  }, [enabledSurfaceKeys, revenue, market]);

  const compareRevenue: number | undefined = useMemo(() => {
    if (!shouldCompare) {
      return;
    }

    let revenueDataset = getMarketDataFromMetric(
      revenueCompare,
      market
    ) as MetricsMarketData;

    if (!revenueDataset) {
      return;
    }

    return getAggregateMetricValue(revenueDataset, enabledSurfaceKeys);
  }, [enabledSurfaceKeys, revenueCompare, shouldCompare, market]);

  const revenueAsPercentage: number | undefined = useMemo(() => {
    if (!revenueValue) {
      return;
    }

    let revenueDataset = getMarketDataFromMetric(
      revenue,
      market
    ) as MetricsMarketData;

    if (!revenueDataset) {
      return;
    }

    let result = getSurfacePercentageValue(revenueDataset, enabledSurfaceKeys);

    if (!result) {
      return;
    }

    return result;
  }, [enabledSurfaceKeys, revenue, revenueValue, market]);

  const compareRevenuePercentage = useMemo(() => {
    if (!compareRevenue || !shouldCompare) {
      return;
    }

    let revenueDataset = getMarketDataFromMetric(
      revenueCompare,
      market
    ) as MetricsMarketData;

    if (!revenueDataset) {
      return;
    }

    return getSurfacePercentageValue(revenueDataset, enabledSurfaceKeys);
  }, [
    enabledSurfaceKeys,
    revenueCompare,
    compareRevenue,
    shouldCompare,
    market,
  ]);

  const conversionRate = useMemo(() => {
    let purchasesDataset = getMarketDataFromMetric(
      purchases,
      market
    ) as MetricsMarketData;
    let clicksDataset = getMarketDataFromMetric(
      clicks,
      market
    ) as MetricsMarketData;

    if (!purchasesDataset || !clicksDataset) {
      return;
    }

    let result = getSurfaceConversionRate(
      clicksDataset,
      purchasesDataset,
      enabledSurfaceKeys
    );

    if (!result) {
      return;
    }

    return result;
  }, [enabledSurfaceKeys, purchases, clicks, market]);

  const compareConversionRate = useMemo(() => {
    if (!shouldCompare) {
      return;
    }
    let purchasesDataset = getMarketDataFromMetric(
      purchasesCompare,
      market
    ) as MetricsMarketData;
    let clicksDataset = getMarketDataFromMetric(
      clicksCompare,
      market
    ) as MetricsMarketData;

    if (!purchasesDataset || !clicksDataset) {
      return;
    }

    return getSurfaceConversionRate(
      clicksDataset,
      purchasesDataset,
      enabledSurfaceKeys
    );
  }, [
    enabledSurfaceKeys,
    purchasesCompare,
    clicksCompare,
    shouldCompare,
    market,
  ]);

  const getComparisonFor = useCallback(
    (
      metric: "revenue" | "revenuePercentage" | "conversionRate"
    ): Comparison | undefined => {
      switch (metric) {
        case "revenue":
          return shouldCompare && revenueValue && compareRevenue
            ? {
                diff: (revenueValue - compareRevenue) / compareRevenue,
                type: "PERCENTAGE",
              }
            : undefined;
        case "revenuePercentage":
          return shouldCompare &&
            revenueAsPercentage &&
            compareRevenuePercentage
            ? {
                diff: revenueAsPercentage - compareRevenuePercentage,
                type: "PERCENTAGE_POINT",
              }
            : undefined;
        case "conversionRate":
          return shouldCompare && conversionRate && compareConversionRate
            ? {
                diff: conversionRate - compareConversionRate,
                type: "PERCENTAGE_POINT",
              }
            : undefined;
      }
    },
    [
      compareConversionRate,
      compareRevenue,
      compareRevenuePercentage,
      conversionRate,
      revenueAsPercentage,
      revenueValue,
      shouldCompare,
    ]
  );

  return (
    <div className="row">
      <div className="col-sm-4 mb-2b mb-xxl-3">
        <HighlightCard
          title="Revenue through Depict"
          tooltip={t("tooltips.highlights.revenue")}
          value={revenueValue}
          valueFormat={"REVENUE"}
          comparison={getComparisonFor("revenue")}
          prefix={symbolLeading ? `${currencySymbol} ` : undefined}
          suffix={symbolLeading === false ? ` ${currencySymbol}` : undefined}
        />
      </div>
      <div className="col-sm-4 mb-2b mb-xxl-3">
        <HighlightCard
          title="As % of total revenue"
          tooltip={t("tooltips.highlights.revenuePercentage")}
          value={revenueAsPercentage}
          valueFormat={"PERCENTAGE"}
          comparison={getComparisonFor("revenuePercentage")}
          suffix={"%"}
          digitsAfterComma={1}
        />
      </div>
      <div className="col-sm-4 mb-2b mb-xxl-3">
        <HighlightCard
          title="Conversion rate"
          tooltip={t("tooltips.highlights.conversionRate")}
          value={conversionRate}
          valueFormat={"PERCENTAGE"}
          comparison={getComparisonFor("conversionRate")}
          suffix={"%"}
          digitsAfterComma={1}
        />
      </div>
    </div>
  );
};

export default React.memo(Highlights, reactFastCompare);

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Bread = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M7 9.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5z" fill={color}></path>
<path d="M9 9.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5z" fill={color}></path>
<path d="M11 10.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5z" fill={color}></path>
<path d="M10.991 6.511c0 0.27-0.222 0.489-0.495 0.489s-0.495-0.219-0.495-0.489c0-0.27 0.222-0.489 0.495-0.489s0.495 0.219 0.495 0.489z" fill={color}></path>
<path d="M13 6.511c0 0.27-0.224 0.489-0.5 0.489s-0.5-0.219-0.5-0.489c0-0.27 0.224-0.489 0.5-0.489s0.5 0.219 0.5 0.489z" fill={color}></path>
<path d="M4 13.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5z" fill={color}></path>
<path d="M6 13.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5z" fill={color}></path>
<path d="M5 15.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5z" fill={color}></path>
<path d="M3 15.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5z" fill={color}></path>
<path d="M16 4.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5z" fill={color}></path>
<path d="M18.573 3.139c-0.892-0.735-2.072-1.139-3.323-1.139-0.901 0-1.778 0.213-2.536 0.615-0.66 0.351-1.204 0.83-1.589 1.397-1.036 0.066-2.002 0.403-2.754 0.967-0.764 0.573-1.231 1.318-1.344 2.131-0.911 0.166-1.75 0.518-2.436 1.031-1.026 0.768-1.592 1.803-1.592 2.914 0 0.123 0.008 0.247 0.022 0.369-0.489 0.178-0.942 0.41-1.344 0.692-1.082 0.757-1.678 1.782-1.678 2.884 0 1.175 0.451 2.173 1.305 2.884 0.876 0.73 2.154 1.116 3.695 1.116 1.599 0 3.113-0.529 4.63-1.616 1.322-0.948 2.486-2.181 3.719-3.487 0.482-0.511 0.981-1.039 1.486-1.544 0.693-0.693 1.34-1.31 1.911-1.854 2.029-1.933 3.254-3.101 3.254-4.5 0-1.087-0.507-2.103-1.427-2.861zM16.056 9.776c-0.575 0.548-1.227 1.169-1.928 1.871-0.515 0.515-1.019 1.048-1.506 1.564-2.325 2.463-4.522 4.789-7.622 4.789-2.505 0-4-1.122-4-3 0-0.767 0.444-1.5 1.251-2.065 0.861-0.603 2.015-0.935 3.249-0.935 1.011 0 1.968 0.222 2.767 0.642 0.244 0.129 0.547 0.035 0.675-0.21s0.035-0.547-0.21-0.675c-0.942-0.495-2.060-0.757-3.233-0.757-0.513 0-1.016 0.051-1.498 0.149-0.001-0.031-0.002-0.063-0.002-0.094 0-0.788 0.423-1.539 1.191-2.113 0.647-0.484 1.47-0.795 2.366-0.901 0.003-0 0.006-0.001 0.009-0.001 0.224-0.026 0.453-0.040 0.684-0.040 1.11 0 2.163 0.309 2.963 0.872 0.226 0.159 0.538 0.104 0.697-0.122s0.104-0.538-0.122-0.697c-0.967-0.679-2.223-1.053-3.538-1.053-0.060 0-0.12 0.001-0.179 0.003 0.131-0.455 0.439-0.878 0.901-1.224 0.669-0.502 1.567-0.779 2.528-0.779 1.021 0 1.99 0.322 2.66 0.883 0.212 0.177 0.527 0.149 0.704-0.062s0.15-0.527-0.062-0.704c-0.644-0.54-1.495-0.904-2.417-1.048 0.704-0.666 1.749-1.069 2.865-1.069 2.068 0 3.75 1.346 3.75 3 0 0.971-1.171 2.087-2.944 3.776z" fill={color}></path>
<path d="M8 14.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Bread); 
/**
 * This class was originally imported from "BROWSER-TAGS/lib/utilities/element_observer".
 * It has been removed in order to facilitate deployments in docker environments that do not play well with scripts imported from outside the project root.
 */
import { ElementObserverEvent } from "./elementObserver";

const queued_callback_args: Array<any> = [];
const queued_callback_args_for_exists_event: Array<any> = [];

let queued_execute_callbacks = false;

/**
 * A function used internally by element_observer to deduplicate ElementObserverEvent instances
 * @param  listener               The listener of a current event
 * @param  selector                The selector of a ElementObserverEvent being dispatched
 * @param  callback                The callback function called with the ElementObserverEvent
 * @return           An object containing a proxied/modified listener, selector and callback
 */
const observerEventDeduplicator = ({
  listener,
  selector,
  callback,
}: {
  listener: "exists" | "creation" | "removed" | "change";
  selector: string;
  callback: (event: ElementObserverEvent<HTMLElement>) => unknown;
}) => {
  const stock_callback = callback;
  callback = function (...args) {
    // console.log("pushing callback", ...args)
    const [mutation_event] = args;

    const { event, mutation_record } = mutation_event;
    if (!event && !mutation_record) {
      // this has to be a ifexists callback (theoretically the check for no event should suffice), we don't need to queue these as they don't have to be deduplicated.
      // this speeds up adding of placeholders drastically if we're coming when the searched element already exists
      return stock_callback(...args);
    }

    const right_queue =
      !event || event === "exists"
        ? queued_callback_args_for_exists_event
        : queued_callback_args;
    // if there is no event someone either made a mistake or it's a callback by ifexists which doesn't have a event because it's unclear if it was invoked by onexists or manually. We assume the latter of the former - as of now now no mistakes were made (I checked).

    right_queue.push({
      callback: stock_callback,
      args,
    });
    if (!queued_execute_callbacks) {
      queued_execute_callbacks = true;
      if (typeof queueMicrotask === "function") {
        queueMicrotask(execute_callbacks);
      } else {
        Promise.resolve().then(execute_callbacks);
      }
    }
  };
  return {
    listener,
    selector,
    callback,
  };
};
function execute_callbacks() {
  try {
    queued_execute_callbacks = false;
    // const n = performance.now();

    const exists_by_function = new Map();

    for (let i = 0; i < queued_callback_args_for_exists_event.length; i++) {
      const entry = queued_callback_args_for_exists_event[i];

      const fn = entry.callback;
      let val = exists_by_function.get(fn);
      if (!val) {
        val = [];
        exists_by_function.set(fn, val);
      }
      val.push(entry);
    }
    while (queued_callback_args_for_exists_event.length) {
      queued_callback_args_for_exists_event.pop();
    }

    for (const per_fn_arr of exists_by_function.values()) {
      const exists_by_elements = new Map();
      for (let i = 0; i < per_fn_arr.length; i++) {
        const entry = per_fn_arr[i];
        exists_by_elements.set(entry.args[0].element, entry);
      }
      for (const entry of exists_by_elements.values()) {
        const { callback, args } = entry;
        callback(...args);
        // console.log(
        //   "map loop running at",
        //   n,
        //   "is calling",
        //   callback,
        //   "on selector",
        //   args[0].selector,
        //   "and element",
        //   args[0].element
        // );
      }
    }

    remove_duplicates({
      arr: queued_callback_args,
      exclude_keys: ["mutation_record", "disconnector"],
    });

    for (let i = 0; i < queued_callback_args.length; i++) {
      const callback_obj = queued_callback_args[i];

      const { callback, args } = callback_obj;
      callback(...args);
      // console.log(
      //   "loop running at",
      //   n,
      //   "is calling",
      //   callback,
      //   "on selector",
      //   args[0].selector,
      //   "and element",
      //   args[0].element
      // );
    }
    while (queued_callback_args.length) {
      queued_callback_args.pop();
    }
    // console.log("execute callbacks took", performance.now()-n, "ms");
  } catch (e) {
    console.error(e);
  }
}
function remove_duplicates({
  arr,
  exclude_keys,
}: {
  arr: Array<any>;
  exclude_keys?: string[];
}) {
  for (let i = 0; i < arr.length; i++) {
    const value = arr[i];
    if (value?.args?.length && typeof value.args[0] == "object") {
      if (value.args[0]?.event === "change") {
        // we do not want to deduplicate change events, since small granular differences in the mutation_record might be just what one is out after
        return; // this is probably a bug and should be "continue" but I don't dare to change it without being sure
      }
    }
    for (let j = 0; j < arr.length; j++) {
      if (j !== i && is_duplicate(value, arr[j], exclude_keys || [])) {
        arr.splice(i, 1);
        break;
      }
    }
  }
}
function is_duplicate(obj: any, obj2: any, exclude_keys: any) {
  let dupl_obj = true;
  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      if (typeof obj[i] === "object" && !(obj[i] instanceof Element)) {
        if (!is_duplicate(obj[i], obj2[i], exclude_keys)) {
          dupl_obj = false;
          break;
        }
      } else {
        if (obj[i] !== obj2[i]) {
          dupl_obj = false;
          break;
        }
      }
    }
  } else {
    const keys = Object.keys(obj),
      keys2 = Object.keys(obj2);
    for (let i = 0; i < keys.length; i++) {
      if (!exclude_keys.includes(keys[i])) {
        if (keys[i] !== keys2[i]) {
          dupl_obj = false;
          break;
        } else {
          if (
            typeof obj[keys[i]] === "object" &&
            !(obj[keys[i]] instanceof Element)
          ) {
            if (!is_duplicate(obj[keys[i]], obj2[keys[i]], exclude_keys)) {
              dupl_obj = false;
              break;
            }
          } else {
            if (obj[keys[i]] !== obj2[keys[i]]) {
              dupl_obj = false;
              break;
            }
          }
        }
      }
    }
  }
  return dupl_obj;
}

export default observerEventDeduplicator;

import React, { useCallback } from "react";
import ProductGridView, {
  RenderCardCB,
} from "../ProductGridView/ProductGridView";
import { Product } from "../types";
import { CategoryProductGridCard } from "../Categories/CategoryProductGridCard/CategoryProductGridCard";
import { BasePortalProductDto, HiddenPortalProductDto } from "src/api/types";
import { OnSelect } from "../BulkEditBar/BulkEditBar.types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface PinningOptions {
  pinningAllowed: boolean;
  onPinClick: (mainProductId: string) => void;
  onAiSortedClick: (mainProductId: string) => Promise<void> | void;
  onPinToTop: (mainProductId: string) => void;
}

interface HidingOptions {
  hidingAllowed: boolean;
  onHideClick: (mainProductId: string) => void;
  onAiSortedClick: (mainProductId: string) => Promise<void> | void;
}

interface ProductGridViewProduct extends Product {
  pinned: boolean;
  hidden: boolean;
}

interface CategoryProductGridViewProps {
  products: ProductGridViewProduct[];
  onRemoveClick?: ((mainProductId: string) => void) | undefined;
  onPinClick?: (mainProductId: string) => void;
  pinningOptions: Partial<PinningOptions>;
  hidingOptions: Partial<HidingOptions>;
  pinnedProducts: BasePortalProductDto[];
  hiddenProducts: HiddenPortalProductDto[];
  loading: boolean;
  selectedIds: Set<string>;
  disableDropdown?: boolean;
  onSelectClick?: OnSelect;
  productsToHighlight: string[];
  columns: number;
  aiSorted: boolean;
  onScrollAtBottom?: () => void;
  isLoadingMore: boolean;
}

export const CategoryProductGridView = (
  props: CategoryProductGridViewProps
) => {
  const pinnedProductsIds = props.pinnedProducts?.map((p) => p.main_product_id);
  const hiddenProductsIds = props.hiddenProducts?.map((p) => p.main_product_id);
  const autoHiddenProductsIds = props.hiddenProducts
    ?.filter((p) => p.hidden_type === "auto_oos")
    .map((p) => p.main_product_id);

  const isPinned = useCallback(
    (mainProductId: string): boolean => {
      return pinnedProductsIds.includes(mainProductId) ?? false;
    },
    [pinnedProductsIds]
  );

  const isHidden = useCallback(
    (mainProductId: string): boolean => {
      return hiddenProductsIds.includes(mainProductId) ?? false;
    },
    [hiddenProductsIds]
  );

  const isAutoHidden = useCallback(
    (mainProductId: string): boolean => {
      return autoHiddenProductsIds.includes(mainProductId) ?? false;
    },
    [autoHiddenProductsIds]
  );

  const renderCard = useCallback<RenderCardCB<Product>>(
    (item, index, extraStyles) => {
      const highlighted = props.productsToHighlight.includes(
        item.main_product_id
      );

      const pinningOptions = {
        pinningAllowed: !!props.pinningOptions.pinningAllowed,
        pinned: isPinned(item.main_product_id),
      };

      const hidingOptions = {
        hidingAllowed: !!props.hidingOptions.hidingAllowed,
        hidden: isHidden(item.main_product_id),
      };

      const categoryProductGridCard = (
        <CategoryProductGridCard
          id={item.main_product_id}
          product={item}
          pinned={pinningOptions.pinned}
          pinningAllowed={pinningOptions.pinningAllowed}
          hidden={hidingOptions.hidden}
          hidingAllowed={hidingOptions.hidingAllowed}
          index={index}
          disableIndex={false}
          disableDropdown={props.disableDropdown}
          actionCBs={{
            onPinToTop: props.pinningOptions.onPinToTop,
            onPinClick: props.pinningOptions.onPinClick,
            onHideClick: props.hidingOptions.onHideClick,
            onAiSortedClick: props.hidingOptions.onAiSortedClick,
            onRemoveClick: props.onRemoveClick,
            onSelectClick: props.onSelectClick,
          }}
          extraStyles={extraStyles}
          showSelectCheckbox={!!props.onSelectClick}
          isSelected={props.selectedIds.has(item.main_product_id)}
          highlight={highlighted}
          isInSelectMode={props.selectedIds.size > 0}
          aiSorted={props.aiSorted}
          isAutoHidden={isAutoHidden(item.main_product_id)}
        />
      );
      const tooltip = (
        <Tooltip id="tooltip">
          The product is auto hidden go to catalog to un hide it
        </Tooltip>
      );
      if (isAutoHidden(item.main_product_id))
        return (
          <OverlayTrigger placement="top" overlay={tooltip}>
            <span>{categoryProductGridCard}</span>
          </OverlayTrigger>
        );
      else return categoryProductGridCard;
    },
    [
      props.productsToHighlight,
      props.pinningOptions.pinningAllowed,
      props.pinningOptions.onPinToTop,
      props.pinningOptions.onPinClick,
      props.hidingOptions.hidingAllowed,
      props.hidingOptions.onHideClick,
      props.hidingOptions.onAiSortedClick,
      props.disableDropdown,
      props.onRemoveClick,
      props.onSelectClick,
      props.selectedIds,
      props.aiSorted,
      isPinned,
      isHidden,
      isAutoHidden,
    ]
  );

  return (
    <ProductGridView
      items={props.products}
      itemIdKey={"main_product_id"}
      renderCard={renderCard}
      loading={props.loading}
      columns={props.columns}
      onScrollAtBottom={props.onScrollAtBottom}
      isLoadingMore={props.isLoadingMore}
    />
  );
};

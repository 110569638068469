import { css } from "@emotion/react";

export function getGridContainerCss(columns: number) {
  const percentage = 100 / columns;

  return css`
    display: grid;
    grid-template-columns: repeat(${columns}, ${percentage}%);
  `;
}

export function getGridElementCss(): ReturnType<typeof css> {
  return css`
    margin: 0.75rem;
  `;
}

import { Button, Form } from "react-bootstrap";

export interface SelectorButtonProps {
  children: React.ReactNode;
  checked: boolean;
  onClick: () => void;
  disabled?: boolean;
}
export default function SelectorButton({
  children,
  checked,
  onClick,
  disabled,
}: SelectorButtonProps) {
  return (
    <Button
      variant={"outline-secondary"}
      className={`w-100 d-flex justify-content-between align-items-center
        ${checked ? "bg-gray-200" : ""}
      `}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
      <Form.Check type="radio" checked={checked} readOnly />
    </Button>
  );
}

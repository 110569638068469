import { useCallback, useEffect, useState } from "react";
import groupsMiddleware from "src/api/groupsMiddleware";

import { GetGroupsApiResult } from "src/types/api";
import { ConfiguredProductGroup } from "src/types/configuration";

import { getAlertMessage } from "src/helpers/errors";
import { ControlLoadingQueueCB } from "src/types/components";
import { useAuth0 } from "@auth0/auth0-react";
import useAuthentication from "../app/useAuthentication";
import { useAlerts } from "../../../components/storybook/Alert/useAlerts";

type UseGroups = [groups: ConfiguredProductGroup[] | null];

export default function useGroups(
  merchant: string | null,
  market: string | null,
  controlLoadingQueueCB: ControlLoadingQueueCB
) {
  const [groups, setGroups] = useState<
    Omit<ConfiguredProductGroup, "group_definition">[] | null
  >(null);
  const { user } = useAuth0();
  const { addAlert } = useAlerts();
  const [getAccessToken] = useAuthentication();

  const loadMerchantMarketData = useCallback(
    async (merchant: string, market: string) => {
      controlLoadingQueueCB("load-merchant-market-groups", "push");
      const id_token = await getAccessToken();
      const groups = (await groupsMiddleware.get(
        id_token,
        merchant,
        market,
        user?.email
      )) as GetGroupsApiResult;

      if (!groups.success) {
        let message = await getAlertMessage(groups.error);
        addAlert(message);
        setGroups(null);
        controlLoadingQueueCB("load-merchant-market-groups", "remove");
        return;
      }

      setGroups(groups?.success ? groups.data : null);
      controlLoadingQueueCB("load-merchant-market-groups", "remove");
    },
    [controlLoadingQueueCB, getAccessToken, user?.email, addAlert]
  );

  useEffect(() => {
    if (!merchant || !market) {
      setGroups(null);
      return;
    }

    loadMerchantMarketData(merchant, market);

    return () => {
      setGroups(null);
      controlLoadingQueueCB("load-merchant-market-groups", "remove");
    };
  }, [controlLoadingQueueCB, loadMerchantMarketData, market, merchant]);

  return [groups] as UseGroups;
}

import { useEffect, useState } from "react";

/**
 * Hook that tells you if the page is visible or not. See https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event
 */
export function useVisibilityState(): boolean {
  const [isVisible, setIsVisible] = useState(
    document.visibilityState !== "hidden"
  );

  useEffect(() => {
    const handleVisibilityChange = () =>
      setIsVisible(document.visibilityState !== "hidden");

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return isVisible;
}

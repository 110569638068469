 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Shutter = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M17.071 2.929c-1.889-1.889-4.4-2.929-7.071-2.929s-5.182 1.040-7.071 2.929c-1.889 1.889-2.929 4.4-2.929 7.071s1.040 5.182 2.929 7.071c1.889 1.889 4.4 2.929 7.071 2.929s5.182-1.040 7.071-2.929c1.889-1.889 2.929-4.4 2.929-7.071s-1.040-5.182-2.929-7.071zM1 10c0-1.004 0.165-1.97 0.47-2.873l5.701 5.701c0.012 0.012 0.024 0.023 0.037 0.033 0.048 0.047 0.098 0.093 0.149 0.138l-5.843-0c-0.333-0.939-0.515-1.948-0.515-3zM10 13c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zM12.791 7.138c-0.048-0.047-0.098-0.093-0.148-0.138h5.843c0.333 0.939 0.515 1.948 0.515 3 0 1.004-0.165 1.97-0.47 2.873l-5.701-5.701c-0.012-0.012-0.025-0.023-0.037-0.034zM18.061 6h-8.061c-0.084 0-0.168 0.003-0.251 0.008l4.129-4.129c1.811 0.868 3.288 2.325 4.183 4.121zM12.873 1.47l-5.701 5.701c-0.012 0.012-0.022 0.024-0.032 0.036-0.048 0.049-0.094 0.099-0.139 0.15l0-5.843c0.939-0.333 1.949-0.515 3-0.515 1.004 0 1.97 0.165 2.873 0.47zM6 1.939l-0 8.061c0 0 0 0.001 0 0.001 0 0.084 0.003 0.167 0.008 0.25l-4.129-4.129c0.868-1.811 2.325-3.288 4.121-4.183zM1.939 14l8.061 0c0.001 0 0.001-0 0.002-0 0.084-0 0.167-0.003 0.249-0.008l-4.129 4.129c-1.811-0.868-3.288-2.325-4.183-4.121zM7.127 18.53l5.701-5.701c0.012-0.012 0.023-0.025 0.034-0.038 0.047-0.048 0.093-0.098 0.138-0.148l-0 5.843c-0.939 0.333-1.948 0.515-3 0.515-1.004 0-1.97-0.165-2.873-0.47zM14 18.061l0-8.061c0-0.001-0-0.002-0-0.002-0-0.083-0.003-0.166-0.008-0.248l4.129 4.129c-0.868 1.811-2.325 3.288-4.121 4.183z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Shutter); 
import { CollectionListDto, GetCollectionDto } from "src/api/types";
import { SortStateSimple } from "../../../../components/storybook/types";

export function sortCategories<T extends GetCollectionDto | CollectionListDto>(
  sorting: SortStateSimple = SortStateSimple.ABC,
  categories: T[]
): T[] {
  return [...categories].sort((a, b) => {
    if (sorting === SortStateSimple.LATEST) {
      const timeB = b.updated_at ? new Date(b.updated_at).getTime() : 0;
      const timeA = a.updated_at ? new Date(a.updated_at).getTime() : 0;

      return timeB - timeA;
    }
    if (sorting === SortStateSimple.ABC) {
      return a.title.localeCompare(b.title);
    }
    return 0;
  });
}

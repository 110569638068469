import { useCallback, useEffect, useState } from "react";
import { EnhancedSurface } from "src/types/components";

type UseCachedSurfaces = [get: () => EnhancedSurface[] | null];

export default function useCachedSurfaces(
  splitBySurface: boolean,
  merchant: string | null,
  enabledSurfaces: EnhancedSurface[] | null
): UseCachedSurfaces {
  const [cache, setCache] = useState<EnhancedSurface[] | null>(null);

  useEffect(() => {
    const totalIsThere = enabledSurfaces?.filter((surface: EnhancedSurface) => {
      return surface.id === "totalFromRecommendations";
    });

    if (splitBySurface && !totalIsThere?.length) {
      console.log("%c[SURFACES CACHE] setting cache", "color: deeppink");
      setCache(enabledSurfaces);
    }
  }, [splitBySurface, enabledSurfaces]);

  useEffect(() => {
    console.log("%c[SURFACES CACHE] erasing cache", "color: deeppink");
    setCache(null);
  }, [merchant]);

  const get = useCallback((): EnhancedSurface[] | null => {
    console.log("%c[SURFACES CACHE] cache requested.", "color: deeppink");
    return cache;
  }, [cache]);

  return [get];
}

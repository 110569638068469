import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import { theme } from "../../designSystemVariables";

export function TabButton(props: {
  to: string;
  active: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}) {
  return (
    <Link
      {...props}
      css={css`
        text-decoration: none !important;
        border: 1px solid ${props.active ? "#0b0b0b" : "transparent"};
        border-radius: 16px;
        padding: 6px 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        display: flex;
        align-items: center;
        width: fit-content;
        height: 32px;

        background-color: ${props.active ? "#ffffff" : "#f3f3f5"};

        margin-right: 8px;

        &:hover {
          background-color: ${theme.background.subtle.hover};
        }
      `}
    >
      {props.children}
    </Link>
  );
}
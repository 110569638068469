import React from "react";
import { useTrackPageView } from "../helpers/hooks/mix/useSegment";

const NoAccess = () => {
  useTrackPageView("NoAccess");

  return (
    <>
      <div className="depict--MainContent container-fluid d-flex flex-column mt-4">
        <div className="depict--Header row mb-3">
          <div className="col-6 col-sm-auto">
            <h1 className="depict--Title">No Access</h1>
          </div>
        </div>
        <div className="depict--Content row">
          <div className="col">
            <div className="alert alert-info">
              You do not have access to this page
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(NoAccess);

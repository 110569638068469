import ProductBadge from "../ProductBadge/ProductBadge";
import { ProductMetadata } from "../../../helpers/catalog/ProductMetadataProvider";
import { sortMetadata } from "../../../helpers/hooks/catalog/useCatalogMetadata";

export interface ProductBadgesProps {
  metadata: ProductMetadata[];
  oos_auto_hide?: boolean;
}
export const ProductBadges = (props: ProductBadgesProps) => {
  const metadata = props.metadata || [];
  const sortedMetadata = [...metadata].sort(sortMetadata);
  return (
    <>
      {sortedMetadata.map((md, index) => {
        return (
          <ProductBadge
            key={`${md.type}-${index}`}
            metadata={md}
            oos_auto_hide={props.oos_auto_hide}
          />
        );
      })}
    </>
  );
};

export default ProductBadges;

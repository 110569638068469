import mergeClassNames from "merge-class-names";
import React, { useCallback, useMemo, useState } from "react";
import { useElementSize } from "usehooks-ts";
import { ListItem, ProductListItem, SearchProps } from "src/types/components";
import { Magnifier, Plus, Cross } from "src/components/linearicons";
import ListItems from "src/components/lists/Items";
import Item from "src/components/lists/Item";

import {
  ConfiguredProductGroup,
  ConfiguredProductGroupDefinition,
  GroupIdObj,
  MainProductIdObj,
  ProductIdObj,
} from "src/types/configuration";
import { MainProduct, Product } from "src/types/products";
import {
  getListItemType,
  getProductIdFromMainOrRegularProduct,
  getSearchFooterString,
  getSearchHits,
  refactorForList,
  updateSelectedItems,
} from "src/helpers/configuration";
import useDebounce from "src/helpers/hooks/mix/useDebounce";
import reactFastCompare from "react-fast-compare";
import ItemContent from "../lists/ItemContent";

const Search = (props: SearchProps) => {
  const {
    alreadySelectedProducts,
    alreadySelectedGroups,
    products,
    groups,
    buttonOnClickCB,
    isVirtual,
    //dragActiveId,
  } = props;

  const [keyword, setKeyword] = useState<string>("");
  const debouncedKeyword = useDebounce(keyword, 300);
  const [selected, setSelected] = useState<ConfiguredProductGroupDefinition>(
    []
  );
  const [lists, setLists] = useState("all");

  const addToList = useCallback(
    (item: ListItem) => {
      const updated = updateSelectedItems(selected, { item, action: "toggle" });
      setSelected(updated);
    },
    [selected]
  );

  const filterableProducts: Array<MainProduct | Product> | null =
    useMemo(() => {
      if (!products) {
        return null;
      }

      if (!alreadySelectedProducts || !alreadySelectedProducts.length) {
        return products;
      }

      let filterable = products.filter((el: MainProduct | Product) => {
        const id = getProductIdFromMainOrRegularProduct(
          el as MainProductIdObj | ProductIdObj
        );
        return id !== null && !alreadySelectedProducts.includes(id);
      });

      return filterable;
    }, [alreadySelectedProducts, products]);

  const filteredProducts: ListItem[] | null = useMemo(() => {
    if (
      !filterableProducts ||
      filterableProducts.length === 0 ||
      !debouncedKeyword
    ) {
      return refactorForList(filterableProducts);
    }

    let results = [];

    if (getListItemType(filterableProducts[0]) === "main_product") {
      results = getSearchHits(
        "main_product",
        "main_product_id",
        "title",
        filterableProducts,
        debouncedKeyword
      );
    } else {
      results = getSearchHits(
        "product",
        "product_id",
        "title",
        filterableProducts,
        debouncedKeyword
      );
    }

    return refactorForList(results as Array<MainProduct | Product>);
  }, [debouncedKeyword, filterableProducts]);

  const filterableGroups: ConfiguredProductGroup[] | null = useMemo(() => {
    if (!groups) {
      return null;
    }

    if (!alreadySelectedGroups || !alreadySelectedGroups.length) {
      return groups.filter((el: ConfiguredProductGroup) => {
        return el.group_type !== "USER_DEFINED_MANUAL_GROUP";
      });
    }

    return groups.filter((el: ConfiguredProductGroup) => {
      return (
        !alreadySelectedGroups.includes(el.group_id) &&
        el.group_type !== "USER_DEFINED_MANUAL_GROUP"
      );
    });
  }, [alreadySelectedGroups, groups]);

  const filteredGroups: ListItem[] | null = useMemo(() => {
    if (!filterableGroups || !debouncedKeyword) {
      return refactorForList(filterableGroups);
    }

    let results = getSearchHits(
      "group",
      "group_id",
      ["group_id", "group_type", "name"],
      filterableGroups,
      debouncedKeyword
    );

    return refactorForList(results as ConfiguredProductGroup[]);
  }, [debouncedKeyword, filterableGroups]);

  const productsSelectedItems = useMemo(() => {
    return (
      selected
        ?.map((value: MainProductIdObj | ProductIdObj | GroupIdObj) => {
          const asMainProduct = value as MainProductIdObj;
          if (typeof asMainProduct.main_product_id !== "undefined") {
            return asMainProduct.main_product_id;
          }
          const asProduct = value as ProductIdObj;
          if (typeof asProduct.product_id !== "undefined") {
            return asProduct.product_id;
          }
          return "";
        })
        ?.filter((value: string) => {
          return value.length > 0 ? true : false;
        }) ?? []
    );
  }, [selected]);

  const groupsSelectedItems = useMemo(() => {
    return (
      selected
        ?.map((value: MainProductIdObj | ProductIdObj | GroupIdObj) => {
          const asGroup = value as GroupIdObj;
          if (typeof asGroup.group_id !== "undefined") {
            return asGroup.group_id;
          }
          return "";
        })
        ?.filter((value: string) => {
          return value.length > 0 ? true : false;
        }) ?? []
    );
  }, [selected]);

  const [listsWrapperRef, { height }] = useElementSize();

  /**
   * This is how you compute sticky indices in case we want the search to be draggable.
   */
  // const productsVirtualStickyIndices = useMemo(() => {
  //   if (!filteredProducts) {
  //     return undefined;
  //   }
  //   const index = filteredProducts.findIndex((el: ListItem) => {
  //     return el.id === dragActiveId;
  //   });

  //   if (index === -1) {
  //     return undefined;
  //   }
  //   return [index];
  // }, [dragActiveId, filteredProducts]);

  const getItemContent = useCallback((item: ListItem) => {
    return <ItemContent item={item} imageLazyLoad={false} />;
  }, []);

  const getProductListItem = useCallback(
    (item: ListItem) => {
      return (
        <Item
          className={mergeClassNames(
            "depict--ItemsList__item",
            productsSelectedItems?.includes(item.id + "") &&
              "depict--ItemsList__item__selected",
            "depict--ItemsList__item__type__" + item.type,
            (item as ProductListItem).inStock === false &&
              "depict--ItemsList__item__outOfStock",
            (item as ProductListItem).deleted === true &&
              "depict--ItemsList__item__deleted"
          )}
          item={item}
          itemOnClickCB={addToList}
        >
          {getItemContent(item)}
        </Item>
      );
    },
    [addToList, getItemContent, productsSelectedItems]
  );

  const getGroupListItem = useCallback(
    (item: ListItem) => {
      return (
        <Item
          className={mergeClassNames(
            "depict--ItemsList__item",
            groupsSelectedItems?.includes(item.id + "") &&
              "depict--ItemsList__item__selected",
            "depict--ItemsList__item__type__" + item.type
          )}
          item={item}
          itemOnClickCB={addToList}
        >
          {getItemContent(item)}
        </Item>
      );
    },
    [addToList, getItemContent, groupsSelectedItems]
  );

  return (
    <div className="depict--ConfigurationSearch">
      <div className="depict--ConfigurationSearch__input input-group input-group-sm mb-3">
        <input
          data-tc="main-search-input"
          type="text"
          className="form-control"
          placeholder="Search products and groups"
          aria-label="Search products and groups"
          value={keyword}
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
        />
        <span className="input-group-text">
          {keyword ? (
            <Cross
              size="12"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setKeyword("");
              }}
            />
          ) : (
            <Magnifier size="12" />
          )}
        </span>
      </div>
      <div className="row mb-3 depict--ConfigurationSearch__listSelector justify-content-center">
        <div className="col-auto me-5">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              value="1"
              id="search-items-all"
              checked={lists === "all"}
              onChange={() => {
                setLists("all");
              }}
            />
            <label className="form-check-label" htmlFor="search-items-all">
              All
            </label>
          </div>
        </div>
        <div className="col-auto me-5">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              value="1"
              id="search-items-list"
              checked={lists === "items"}
              onChange={() => {
                setLists("items");
              }}
            />
            <label className="form-check-label" htmlFor="search-items-list">
              Products
            </label>
          </div>
        </div>
        <div className="col-auto">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              value="1"
              id="groups-items-list"
              checked={lists === "groups"}
              onChange={() => {
                setLists("groups");
              }}
            />
            <label className="form-check-label" htmlFor="groups-items-list">
              Groups
            </label>
          </div>
        </div>
      </div>
      <div
        className="row depict--ConfigurationSearch__lists"
        ref={listsWrapperRef}
        data-tc="search-results-wrapper"
      >
        {["items", "all"].includes(lists) && (
          <div className="h-100 col-xs-12 col-sm depict--ConfigurationSearch__list">
            <ListItems
              id="search-products"
              items={filteredProducts}
              getItem={getProductListItem}
              emptyListLabel={
                keyword
                  ? `No products found for ${keyword}`
                  : "No products available."
              }
              isVirtual={isVirtual ?? false}
              virtualListHeight={height}
              virtualListItemHeight={52}
              virtualStickyIndices={undefined}
            />
          </div>
        )}
        {["groups", "all"].includes(lists) && (
          <div className="h-100 col-xs-12 col-sm depict--ConfigurationSearch__list">
            <ListItems
              id="search-groups"
              items={filteredGroups}
              getItem={getGroupListItem}
              isDark={true}
              emptyListLabel={
                keyword
                  ? `No groups found for ${keyword}.`
                  : "No groups available."
              }
              isVirtual={isVirtual ?? false}
              virtualListHeight={height}
              virtualListItemHeight={52}
              virtualStickyIndices={undefined}
            />
          </div>
        )}
      </div>
      <div className="depict--ConfigurationSearch__footer d-flex">
        <div
          className="depict--ConfigurationSearch__footerInfo flex-grow-1 text-end me-4 align-items-center"
          data-tc="label-footer-info"
        >
          {getSearchFooterString(selected)}
        </div>
        <div className="depict--ConfigurationSearch__footerButton">
          <button
            data-tc="add-to-panel"
            type="button"
            className="btn btn-sm btn-depict-green rounded-pill"
            disabled={selected.length === 0}
            onClick={() => {
              buttonOnClickCB(selected);
              setSelected([]);
            }}
          >
            <Plus size="12" className="me-2" /> Add
          </button>
        </div>
      </div>
    </div>
  );
};

//7 no deep / 3 deep.
export default React.memo(Search, reactFastCompare);

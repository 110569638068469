import { ChevronDown } from "src/components/linearicons";
import mergeClassNames from "merge-class-names";
import React, { useEffect, useState } from "react";
import { CardCollapsibleProps } from "src/types/components";
import reactFastCompare from "react-fast-compare";

const CardCollapsible = (props: CardCollapsibleProps) => {
  const {
    title,
    id,
    className,
    isVertical,
    onCollapseCB,
    titleTooltip,
    externalToggle,
    isDisabled,
    style,
  } = props;

  const [toggle, setToggle] = useState(true);

  useEffect(() => {
    if (!onCollapseCB || typeof externalToggle !== "undefined") {
      return;
    }

    onCollapseCB(id, toggle);
    //eslint-disable-next-line
  }, [
    //externalToggle, //Intentional
    //id, //Intentional
    //onCollapseCB, //Intentional
    toggle,
  ]);

  useEffect(() => {
    if (typeof externalToggle === "undefined") {
      return;
    }
    console.log(
      "%c[CARDCOLLAPSIBLE] External toggle changed status...",
      "color: green"
    );
    setToggle(externalToggle);
  }, [externalToggle]);

  return (
    <>
      <div
        className={mergeClassNames(
          "h-100 depict--CardCollapsible card card-collapsable",
          isVertical && "depict--CardCollapsible__vertical",
          className,
          toggle && "depict--CardCollapsible__show",
          isDisabled && "depict--CardCollapsible__disabled"
        )}
        style={style}
      >
        <a
          data-bs-toggle={titleTooltip ? "tooltip" : false}
          title={titleTooltip ? titleTooltip : undefined}
          data-toggle="collapse"
          href={`#${id}`}
          role="button"
          aria-expanded={toggle ? "true" : "false"}
          aria-controls={`#${id}`}
          className={mergeClassNames("card-header", !toggle && "collapsed")}
        >
          <div className="depict--CardCollapsible__title">{title}</div>
          <div
            className="card-collapsable-arrow"
            onClick={(e) => {
              e.preventDefault();

              if (isDisabled) {
                return;
              }

              if (typeof externalToggle === "undefined") {
                let newValue = !toggle;
                setToggle(newValue);
              } else if (onCollapseCB) {
                console.log(
                  "%c[CARDCOLLAPSIBLE] Card is controlled from the outside...",
                  "color: green"
                );
                let newValue = !externalToggle;
                onCollapseCB(id, newValue);
              }
            }}
          >
            <ChevronDown />
          </div>
        </a>
        <div
          className={mergeClassNames(toggle ? "collapse show" : "collapse")}
          id={id}
        >
          <div className="card-body">{props.children}</div>
        </div>
      </div>
    </>
  );
};

//export default CardCollapsible;
export default React.memo(CardCollapsible, reactFastCompare);

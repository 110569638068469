import type { MouseEvent, KeyboardEvent } from "react";
import {
  UniqueIdentifier,
  MouseSensor as LibMouseSensor,
  PointerSensor as LibPointerSensor,
  KeyboardSensor as LibKeyboardSensor,
} from "@dnd-kit/core";
import { ListItem } from "src/types/components";
import { ContainersItems } from "src/types/dnd";

export function idIsInItems(id: UniqueIdentifier, items: ListItem[] | null) {
  if (!items) {
    return false;
  }

  return items.some((item) => item.id === id);
}

export function getIndexForId(id: UniqueIdentifier, items: ListItem[] | null) {
  if (!items) {
    return -1;
  }
  return items.findIndex((item) => item.id === id);
}

export const findContainer = (
  id: UniqueIdentifier,
  items: ContainersItems | null
): UniqueIdentifier | null => {
  if (!items) {
    return null;
  }

  if (id in items) {
    //id is a container id.
    return id;
  }

  const container_ids = Object.keys(items);
  const found = container_ids.find((container_id) => {
    const listItems = items[container_id];
    return idIsInItems(id, listItems);
  });

  if (!found) {
    return null;
  }

  return found;
};

export const getIndex = (
  id: UniqueIdentifier,
  items: ContainersItems | null
): number => {
  if (!items) {
    return -1;
  }

  const container = findContainer(id, items);

  if (!container) {
    return -1;
  }

  const listItems = items[container];

  return getIndexForId(id, listItems);
};

export const getSortableContextItems = (items: ListItem[] | null): string[] => {
  let i =
    items?.map((item: ListItem) => {
      return `${item.id}`;
    }) ?? [];
  return i;
};

// This is a wrapper around the MouseSensor from dnd-kit to allow it to ignore nodes in the tree
// https://github.com/clauderic/dnd-kit/issues/477#issuecomment-985194908

export class MouseSensor extends LibMouseSensor {
  static activators = [
    {
      eventName: "onMouseDown" as const,
      handler: ({ nativeEvent: event }: MouseEvent) => {
        return shouldHandleEvent(event.target as HTMLElement);
      },
    },
  ];
}

export class PointerSensor extends LibPointerSensor {
  static activators = [
    {
      eventName: "onPointerDown" as const,
      handler: ({ nativeEvent: event }: MouseEvent) => {
        return shouldHandleEvent(event.target as HTMLElement);
      },
    },
  ];
}

export class KeyboardSensor extends LibKeyboardSensor {
  static activators = [
    {
      eventName: "onKeyDown" as const,
      handler: ({ nativeEvent: event }: KeyboardEvent<Element>) => {
        return shouldHandleEvent(event.target as HTMLElement);
      },
    },
  ];
}

function shouldHandleEvent(element: HTMLElement | null) {
  let cur = element;

  while (cur) {
    if (cur.dataset && cur.dataset.noDnd) {
      return false;
    }
    cur = cur.parentElement;
  }

  return true;
}

import Cache from "src/helpers/cache";
import { SurfacesApiResult } from "src/types/api";
import { SurfacesCacheKey } from "src/types/cache";
import surfacesApi from "./surfaces";

const surfacesMiddleware = (function () {
  let cache: Cache = new Cache();

  return {
    get: async function (
      idToken: string,
      merchant_id: string
    ): Promise<SurfacesApiResult> {
      let cacheKey: SurfacesCacheKey = {
        merchant_id,
      };

      if (cache.has(cacheKey)) {
        console.log(
          "[CACHE] Loading %c" + merchant_id + " %csurface data.",
          "font-weight: bold",
          "font-weight: normal"
        );
        return cache.get(cacheKey);
      }

      const result = await surfacesApi({ idToken, merchant_id });

      if (!result.success) {
        return result;
      }

      cache.set(cacheKey, result);

      return result;
    },
    getCache: function (): Cache {
      return cache;
    },
  };
})();

export default surfacesMiddleware;

import Select, { OptionProps, components } from "react-select";
import ParsingMethodSelector from "../../Integrations/ParsingMethodSelector/ParsingMethodSelector";
import {
  MetafieldDefinition,
  ParsingConfig,
} from "../../Integrations/Shopify/types";
import { ParsingMethod } from "../../Integrations/ParsingMethodSelector/types";
import { Badge, Button } from "react-bootstrap";

function getMetafieldId(namespace: string, key: string) {
  return `${namespace}:${key}`;
}

interface MetafieldOption {
  label: string;
  value: string;
  exampleValues: string[];
}

const CustomOption: React.FC<OptionProps<MetafieldOption, false>> = ({
  data,
  ...props
}) => (
  <components.Option data={data} {...props}>
    {data.label}{" "}
    <span className="mx-2">
      {data.exampleValues.slice(0, 2).map((v) => (
        <Badge bg="light" className="me-1">
          {v.substring(0, 12)}
          {v.length > 12 && "..."}
        </Badge>
      ))}
    </span>
  </components.Option>
);

export function ShopifyAttributeParsingSelector({
  attributeName,
  attributeId,
  parsingConfig,
  onSetParsingConfig,
  availableMetafieldDefinitions,
  availableConstantValues,
  required,
  onDelete,
}: {
  attributeName: string;
  attributeId: string;
  parsingConfig?: ParsingConfig;
  onSetParsingConfig: (config: ParsingConfig) => void;
  availableMetafieldDefinitions: MetafieldDefinition[];
  availableConstantValues: { label: string; value: string | null }[];
  required?: boolean;
  onDelete?: () => void;
}) {
  const metafieldOptions = availableMetafieldDefinitions.map((def) => ({
    label: `${def.name} (${def.namespace})`,
    value: getMetafieldId(def.namespace, def.key),
    exampleValues: def.example_values,
  }));

  const constantValueOptions = availableConstantValues.map((v) => v);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        {required ? (
          <h6>
            {attributeName}
            <span className="text-danger">*</span>
            <span className="text-gray-600"> (required)</span>
          </h6>
        ) : (
          <h6>
            {attributeName}
            <span className="text-gray-600"> (optional)</span>
          </h6>
        )}
        {!required && onDelete && (
          <Button
            onClick={onDelete}
            variant="link"
            className="text-gray-800 p-0"
          >
            Remove
          </Button>
        )}
      </div>
      {required && (
        <ParsingMethodSelector
          selectedAttributeParsingMethod={parsingConfig?.type}
          mappingIsDisabled={availableMetafieldDefinitions.length === 0}
          onSetAttributeParsingMethod={(method) => {
            if (method === "map" && availableMetafieldDefinitions.length > 0) {
              onSetParsingConfig({
                attribute_id: attributeId,
                type: ParsingMethod.Map,
                namespace: availableMetafieldDefinitions[0].namespace,
                key: availableMetafieldDefinitions[0].key,
              });
            } else if (method === "constant") {
              onSetParsingConfig({
                attribute_id: attributeId,
                type: ParsingMethod.Constant,
                value: undefined,
              });
            }
          }}
        />
      )}
      {parsingConfig?.type === "map" && (
        <div>
          <div className="text-gray-800 my-2">Attribute maps to</div>
          <Select<MetafieldOption>
            components={{ Option: CustomOption }}
            value={metafieldOptions.find(
              (option) =>
                option.value ===
                getMetafieldId(parsingConfig.namespace, parsingConfig.key)
            )}
            onChange={(option) => {
              const metafieldDefinition = option
                ? availableMetafieldDefinitions.find(
                    (def) =>
                      getMetafieldId(def.namespace, def.key) === option.value
                  )
                : undefined;
              if (metafieldDefinition) {
                onSetParsingConfig({
                  attribute_id: attributeId,
                  type: ParsingMethod.Map,
                  namespace: metafieldDefinition.namespace,
                  key: metafieldDefinition.key,
                });
              }
            }}
            options={metafieldOptions}
            placeholder={`Select metafield for ${attributeName.toLowerCase()}`}
          />
        </div>
      )}
      {parsingConfig?.type === "constant" && (
        <div>
          <div className="text-gray-800 my-2">Attribute maps to</div>
          <Select
            onChange={(option) => {
              if (option) {
                onSetParsingConfig({
                  attribute_id: attributeId,
                  type: ParsingMethod.Constant,
                  value: option.value ?? undefined,
                });
              }
            }}
            options={constantValueOptions}
            placeholder={`Select value for ${attributeName.toLowerCase()}`}
          />
        </div>
      )}
    </div>
  );
}

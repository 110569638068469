 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Italic = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M15.5 2h-4.001c-0.001 0-0.003 0-0.004 0h-3.995c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h3.39l-2.8 14h-3.59c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h3.999c0.001 0 0.001 0 0.002 0s0.001-0 0.002-0h3.997c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-3.39l2.8-14h3.59c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Italic); 
import { loadConfigurationGroups } from "src/helpers/configuration";
import { getAlertMessage } from "src/helpers/errors";

import { Relationship, ConfiguredProductGroup } from "src/types/configuration";
import { ControlLoadingQueueCB } from "src/types/components";
import { useAuth0 } from "@auth0/auth0-react";
import useAuthentication from "../app/useAuthentication";
import { useAlerts } from "../../../components/storybook/Alert/useAlerts";

export default function useLoadGroups(
  controlLoadingQueueCB: ControlLoadingQueueCB
) {
  const { user } = useAuth0();
  const { addAlert } = useAlerts();
  const [getAccessToken] = useAuthentication();

  const loadGroups = async (
    relationships: Relationship[] | null,
    merchant: string | null,
    market: string | null
  ): Promise<ConfiguredProductGroup[] | null> => {
    if (!relationships || !merchant || !market) {
      return null;
    }

    controlLoadingQueueCB("update-configuration-groups", "push");

    const id_token = await getAccessToken();

    const result = await loadConfigurationGroups(
      relationships,
      merchant,
      market,
      id_token,
      user?.email
    );

    if (!result.success) {
      let message = await getAlertMessage(result.error);
      addAlert(message);
      controlLoadingQueueCB("update-configuration-groups", "remove");
      return null;
    }

    controlLoadingQueueCB("update-configuration-groups", "remove");
    return result.data;
  };

  return [loadGroups];
}

import { css } from "@emotion/react";

export const noSelectCss = css`
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
`;

export const noWrapCss = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

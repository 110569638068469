 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Satellite2 = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M6.5 20c-0.075 0-0.151-0.017-0.223-0.053-0.247-0.123-0.347-0.424-0.224-0.671l2-4c0.123-0.247 0.424-0.347 0.671-0.224s0.347 0.424 0.224 0.671l-2 4c-0.088 0.175-0.264 0.277-0.448 0.277z" fill={color}></path>
<path d="M1.5 18c-0.095 0-0.192-0.027-0.277-0.084-0.23-0.153-0.292-0.464-0.139-0.693l2-3c0.153-0.23 0.464-0.292 0.693-0.139s0.292 0.464 0.139 0.693l-2 3c-0.096 0.145-0.255 0.223-0.417 0.223z" fill={color}></path>
<path d="M18 2.5c0-0.827-0.673-1.5-1.5-1.5-0.803 0-1.46 0.634-1.498 1.427l-8.646 2.594c-0.264 0.079-0.415 0.358-0.335 0.623 0.065 0.217 0.264 0.356 0.479 0.356 0.048 0 0.096-0.007 0.144-0.021l8.646-2.594c0.003 0.005 0.007 0.009 0.010 0.014l-5.216 7.824c-0.153 0.23-0.091 0.54 0.139 0.693 0.085 0.057 0.182 0.084 0.277 0.084 0.162 0 0.32-0.078 0.416-0.223l4.515-6.773-1.419 6.387c-0.060 0.27 0.11 0.537 0.38 0.597 0.037 0.008 0.073 0.012 0.109 0.012 0.229 0 0.436-0.159 0.488-0.392l1.693-7.62c0.742-0.090 1.319-0.723 1.319-1.489zM16.5 3c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5 0.5 0.224 0.5 0.5-0.224 0.5-0.5 0.5z" fill={color}></path>
<path d="M11.628 5.5c0.179 0.163 0.358 0.329 0.537 0.5z" fill={color}></path>
<path d="M12.164 6.5c-0.124 0-0.249-0.046-0.346-0.139-0.173-0.166-0.351-0.331-0.527-0.491-0.205-0.185-0.22-0.502-0.035-0.706s0.502-0.22 0.706-0.035c0.183 0.166 0.367 0.337 0.547 0.509 0.199 0.191 0.206 0.507 0.015 0.707-0.098 0.103-0.23 0.154-0.361 0.154z" fill={color}></path>
<path d="M16.921 11.266c-0.129-0.244-0.432-0.337-0.676-0.207s-0.337 0.432-0.207 0.676c0.977 1.841 1.22 3.349 0.633 3.936-0.517 0.517-1.759 0.394-3.323-0.328-1.763-0.813-3.744-2.272-5.578-4.107s-3.293-3.816-4.107-5.578c-0.722-1.564-0.844-2.806-0.328-3.323 0.407-0.407 1.166-0.349 1.731-0.228 0.898 0.192 1.993 0.682 3.168 1.42 0.234 0.147 0.543 0.076 0.689-0.158s0.076-0.543-0.158-0.689c-1.273-0.799-2.48-1.335-3.491-1.55-1.463-0.312-2.247 0.099-2.647 0.499-0.695 0.695-1.171 1.578-1.416 2.626-0.232 0.994-0.245 2.085-0.037 3.243 0.425 2.376 1.723 4.748 3.654 6.679 0.437 0.437 0.903 0.847 1.386 1.219 0.091 0.070 0.198 0.104 0.305 0.104 0.15 0 0.298-0.067 0.396-0.195 0.169-0.219 0.128-0.533-0.091-0.701-0.449-0.346-0.883-0.728-1.289-1.134-1.788-1.788-2.987-3.972-3.377-6.148-0.182-1.014-0.169-1.97 0.025-2.813 0.123 0.486 0.313 1.010 0.571 1.568 0.862 1.867 2.391 3.95 4.308 5.867s4 3.446 5.867 4.308c0.566 0.261 1.098 0.454 1.589 0.576-1.258 0.295-2.765 0.174-4.352-0.384-0.261-0.092-0.546 0.045-0.638 0.306s0.045 0.546 0.306 0.638c0.589 0.207 1.178 0.36 1.757 0.458l0.422 1.771c0.055 0.23 0.26 0.384 0.486 0.384 0.038 0 0.077-0.004 0.116-0.014 0.269-0.064 0.434-0.334 0.37-0.602l-0.338-1.42c0.153 0.008 0.304 0.013 0.454 0.013 0.306 0 0.606-0.017 0.899-0.052 1.358-0.161 2.526-0.696 3.377-1.547 0.951-0.951 0.789-2.767-0.457-5.112z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Satellite2); 
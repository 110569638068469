import { useQuery } from "@tanstack/react-query";
import { QueryId, getQueryKey } from "src/queries/queries";

export type GetCategoryProductIdsCallback = (
  merchantId: string,
  categoryId: string
) => Promise<string[]>;

export type GetCategoryProductIdsConfig = {
  enabled: boolean;
  querySalt: string;
  callback: GetCategoryProductIdsCallback;
};

export function useCategoryProductIds(
  merchantId: string | null,
  categoryId: string | null,
  getCategoryProductIdsConfig: GetCategoryProductIdsConfig | undefined
) {
  const enabled =
    !!merchantId && !!categoryId && getCategoryProductIdsConfig?.enabled;

  const callback = getCategoryProductIdsConfig?.callback;

  const getCategoryProductIdsCB = async () => {
    if (merchantId && categoryId && callback) {
      const response = await callback(merchantId, categoryId);

      return response;
    } else {
      return [];
    }
  };

  const productOrderQuery = useQuery({
    queryKey: getQueryKey(
      QueryId.GetCategoryProductIds,
      merchantId || "",
      categoryId || "",
      getCategoryProductIdsConfig?.querySalt || ""
    ),
    queryFn: getCategoryProductIdsCB,
    enabled,
    refetchOnMount: false,
  });

  return {
    data: productOrderQuery.data,
    isLoading: productOrderQuery.isLoading,
    isError: productOrderQuery.isError,
    refetch: productOrderQuery.refetch,
  };
}

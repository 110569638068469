 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Clubs = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M13.5 20h-8c-0.232 0-0.433-0.159-0.487-0.385s0.056-0.458 0.263-0.562c1.483-0.742 2.159-1.939 2.466-2.87-0.805 0.529-1.753 0.817-2.743 0.817-2.757 0-5-2.243-5-5 0-1.319 0.509-2.564 1.434-3.505 0.762-0.775 1.734-1.272 2.79-1.435-0.149-0.504-0.224-1.026-0.224-1.56 0-3.033 2.467-5.5 5.5-5.5s5.5 2.467 5.5 5.5c0 0.534-0.075 1.056-0.224 1.56 1.056 0.164 2.028 0.66 2.79 1.435 0.925 0.941 1.434 2.185 1.434 3.505 0 2.757-2.243 5-5 5-0.991 0-1.939-0.289-2.744-0.818 0.029 0.090 0.062 0.182 0.099 0.276 0.454 1.163 1.251 2.037 2.368 2.595 0.207 0.104 0.316 0.336 0.263 0.562s-0.255 0.385-0.487 0.385zM7.101 19h4.798c-0.654-0.604-1.16-1.353-1.491-2.22-0.403-1.054-0.408-1.916-0.408-1.952 0-0.212 0.133-0.401 0.333-0.471s0.422-0.008 0.556 0.157c0.764 0.944 1.898 1.486 3.111 1.486 2.206 0 4-1.794 4-4 0-2.166-1.761-3.96-3.926-3.999-0.167-0.003-0.322-0.089-0.412-0.23s-0.104-0.317-0.037-0.47c0.249-0.569 0.375-1.175 0.375-1.801 0-2.481-2.019-4.5-4.5-4.5s-4.5 2.019-4.5 4.5c0 0.626 0.126 1.232 0.375 1.801 0.067 0.153 0.053 0.33-0.037 0.47s-0.245 0.227-0.412 0.23c-2.165 0.039-3.926 1.833-3.926 3.999 0 2.206 1.794 4 4 4 1.213 0 2.347-0.542 3.111-1.486 0.133-0.165 0.356-0.228 0.556-0.157s0.333 0.26 0.333 0.471c0 0.036-0.005 0.897-0.408 1.952-0.331 0.866-0.837 1.615-1.491 2.22z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Clubs); 
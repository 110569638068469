import { css } from "@emotion/react";
import { Icon, IconWeight } from "@phosphor-icons/react";
import React, { useContext, useEffect, useMemo } from "react";
import { HoverTitle } from "../HoverTitle/HoverTitle";
import { BulkEditBarContext } from "./BulkEditBar.provider";
import { theme } from "../designSystemVariables";
import { Checkbox } from "../Checkbox/Checkbox";
import useIsInputFocus from "src/helpers/hooks/useIsInputFocus";

const ICON_SIZE = "2em";
const BAR_SPACING = 12; // px
const BAR_HEIGHT = 64;

export interface BulkAction {
  icon: Icon;
  label: string | JSX.Element;
  keyShortcut?: string;
  onClick?: () => void;
  iconWeight?: IconWeight | undefined;
}

interface BulkEditBarProps {
  open: boolean;
  selected: number;
  actions: BulkAction[];
  unselectAll: () => void;
}

export function BulkEditBar(props: BulkEditBarProps) {
  const { elementToHookToRef, shortcutsDisabled } =
    useContext(BulkEditBarContext);

  const isInputFocused = useIsInputFocus();

  const isInputFocusedOrShortcutsDisabled = isInputFocused || shortcutsDisabled;

  const BulkActionComponent = (props: {
    icon: JSX.Element;
    label: string | JSX.Element;
    keyShortcut?: string;
    onClick?: () => void;
  }) => {
    return (
      <div
        css={css`
          &:hover {
            .depict--HoverTitle {
              opacity: 1;
            }
          }
        `}
        onClick={props.onClick}
      >
        <div
          css={css`
            position: relative;
            top: -3.85em;
          `}
        >
          <HoverTitle
            content={
              <div style={{ padding: "0.1em" }}>
                {props.label}{" "}
                <span
                  css={css`
                    background: ${theme.background.inverse.hover};
                    padding: 0.11em 0.25em;
                    margin: 0.1em;
                    border-radius: 0.15em;
                    border: 0.5px ${theme.textIcon.inverse.hover} solid;
                  `}
                >
                  {props.keyShortcut}
                </span>
              </div>
            }
          />
        </div>
        <button
          css={css`
            margin: 0;
            border: 0;
            color: ${theme.textIcon.subtle.disabled};
            padding: 0.5em;
            cursor: pointer;
            border-radius: 0.5em;
            background: rgba(0, 0, 0, 0);
            &:hover {
              background: rgba(0, 0, 0, 1);
            }
          `}
        >
          {props.icon}
        </button>
      </div>
    );
  };

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      const relatedAction = props.actions.find(
        (action) => action.keyShortcut?.toLowerCase() === e.key.toLowerCase()
      );

      if (relatedAction && !isInputFocusedOrShortcutsDisabled) {
        relatedAction.onClick?.();
        e.stopPropagation();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [isInputFocusedOrShortcutsDisabled, props, shortcutsDisabled]);

  const [rect, setRect] = React.useState<{
    width: number;
    x: number;
  }>({
    width: 0,
    x: 0,
  });

  // Set bar to same width as container, even on resize
  useEffect(() => {
    const resizeListener = () => {
      if (elementToHookToRef.current) {
        const boundingRect = elementToHookToRef.current.getBoundingClientRect();

        setRect((prev) => {
          if (prev.width === boundingRect.width && prev.x === boundingRect.x) {
            return prev;
          }

          return {
            width: boundingRect.width,
            x: boundingRect.x,
          };
        });
      }
    };
    resizeListener();
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [elementToHookToRef]);

  const actionItems = useMemo(() => {
    return props.actions.map((action, index) => {
      const Icon = action.icon;

      return (
        <BulkActionComponent
          key={index}
          icon={
            <Icon
              weight={action.iconWeight}
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
          }
          label={action.label}
          keyShortcut={action.keyShortcut}
          onClick={action.onClick}
        />
      );
    });
  }, [props.actions]);

  return (
    <div
      css={css`
        position: fixed;
        bottom: 0;
        left: ${rect.x + BAR_SPACING}px;
        right: 0;
        z-index: 1000;
        width: ${rect.width - BAR_SPACING * 2}px;
        z-index: 102;
        background: rgba(28, 28, 28, 0.85);
        backdrop-filter: blur(24px);
        padding: 1em;
        border-radius: 1em;
        color: ${theme.textIcon.subtle.disabled};
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1em;
        position: fixed;
        bottom: ${BAR_SPACING}px;
        right: ${BAR_SPACING}px;
        height: ${BAR_HEIGHT}px;
        transform: translate(0, ${props.open ? 0 : BAR_HEIGHT + BAR_SPACING}px);
        transition: transform 0.2s;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          gap: 0.5em;
        `}
      >
        <Checkbox
          checked={props.selected > 0 ? true : false}
          onChange={() => {
            if (props.selected > 0) {
              props.unselectAll();
            }
          }}
        />
        <div
          css={css(
            [theme.typography.paragraph[100].light],
            `
            display: flex;
            align-items: center;
            ${theme.typography.paragraph[100]}
          `
          )}
          onClick={props.unselectAll}
        >
          Unselect all
        </div>
      </div>
      <div
        css={css`
          display: flex;
          justify-content: end;
          align-items: center;
        `}
      >
        <div
          css={css`
            margin-right: 1em;
          `}
        >
          {props.selected} selected:
        </div>
        {actionItems}
      </div>
    </div>
  );
}

import { useQuery } from "@tanstack/react-query";
import { getData } from "src/api/authorizedApi";
import useAuthorizedApi from "../../../helpers/hooks/app/useAuthorizedApi";
import { QueryId, getQueryKey } from "../../../queries/queries";
import ShopifyMarketConfig from "../../storybook/Integrations/Shopify/ShopifyMarketConfig";
import { ShopifyMarket } from "../../storybook/Integrations/Shopify/types";
import { paths } from "src/api/generated/openapi";

type ShopifyMarketInfo =
  paths["/api/v0/integrations/shopify/market-info"]["post"]["responses"]["200"]["content"]["application/json"];

export default function ShopifyMarketConfigStep({
  adminApiUrl,
  adminApiKey,
  onSubmit,
  onBack,
  merchantId,
}: {
  adminApiUrl?: string;
  adminApiKey?: string;
  onSubmit: (markets: ShopifyMarket[]) => void;
  onBack: () => void;
  merchantId: string;
}) {
  const { api } = useAuthorizedApi();
  const {
    data: returnedMarketInfo,
    isLoading,
    error,
  } = useQuery<ShopifyMarketInfo | undefined, any & { message: string }>({
    queryKey: getQueryKey(
      QueryId.GetAvailableShopifyMarkets,
      merchantId,
      adminApiUrl || "",
      adminApiKey || ""
    ),
    queryFn: async () => {
      if (api && adminApiUrl && adminApiKey) {
        const response = await api.POST(
          "/api/v0/integrations/shopify/market-info",
          {
            body: {
              admin_api_url: adminApiUrl,
              admin_api_key: adminApiKey,
            },
          }
        );
        const data = getData(response);

        return data;
      }
    },

    enabled: !!api && !!adminApiUrl && !!adminApiKey,
  });

  const onSelectMarketIds = (selectedMarketIds: string[]) => {
    const selectedMarkets = returnedMarketInfo?.markets.filter((m) =>
      selectedMarketIds.includes(m.market_id)
    );
    if (selectedMarkets) {
      onSubmit(
        selectedMarkets.map((m) => ({
          id: m.market_id,
          name: m.name,
          reference_country_code: m.reference_country_code,
        }))
      );
    }
  };

  return (
    <ShopifyMarketConfig
      availableMarkets={
        returnedMarketInfo?.markets.map((m) => ({
          id: m.market_id,
          name: m.name,
        })) ?? []
      }
      isLoading={isLoading}
      errorMessage={error?.message}
      onSubmit={onSelectMarketIds}
      onBack={onBack}
    />
  );
}

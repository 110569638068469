import React, { useEffect } from "react";
import mergeClassNames from "merge-class-names";
import styles from "src/assets/scss/dnd-kit/Item.module.scss";
import reactFastCompare from "react-fast-compare";
import { DraggableItemProps } from "src/types/dnd";

const DraggableItem = React.forwardRef<HTMLLIElement, DraggableItemProps>(
  (
    {
      color,
      dragOverlay,
      dragging,
      disabled,
      fadeIn,
      handle,
      handleProps,
      height,
      index,
      listeners,
      onRemove,
      renderItem,
      sorting,
      style,
      transition,
      transform,
      value,
      wrapperStyle,
      wrapperClass,
      children,
      customTag,
      ...props
    },
    ref
  ) => {
    const CustomTag = customTag ? `${customTag}` : "div";

    useEffect(() => {
      if (!dragOverlay) {
        return;
      }

      document.body.style.cursor = "grabbing";

      return () => {
        document.body.style.cursor = "";
      };
    }, [dragOverlay]);

    return renderItem ? (
      renderItem({
        dragOverlay: Boolean(dragOverlay),
        dragging: Boolean(dragging),
        sorting: Boolean(sorting),
        index,
        fadeIn: Boolean(fadeIn),
        listeners,
        ref,
        style,
        transform,
        transition,
        value,
      })
    ) : (
      //@ts-ignore
      <CustomTag
        className={mergeClassNames(
          "depict--DraggableItem__wrapper",
          wrapperClass,
          styles.Wrapper,
          fadeIn && styles.fadeIn,
          sorting && styles.sorting,
          dragOverlay && styles.dragOverlay
        )}
        style={
          {
            ...wrapperStyle,
            transition: [transition, wrapperStyle?.transition]
              .filter(Boolean)
              .join(", "),
            "--translate-x": transform
              ? `${Math.round(transform.x)}px`
              : undefined,
            "--translate-y": transform
              ? `${Math.round(transform.y)}px`
              : undefined,
            "--scale-x": transform?.scaleX ? `${transform.scaleX}` : undefined,
            "--scale-y": transform?.scaleY ? `${transform.scaleY}` : undefined,
            "--index": index,
            "--color": color,
          } as React.CSSProperties
        }
        ref={ref}
      >
        <div
          className={mergeClassNames(
            styles.Item,
            dragging && styles.dragging,
            handle && styles.withHandle,
            dragOverlay && styles.dragOverlay,
            disabled && styles.disabled,
            color && styles.color,
            "depict--DraggableItem__item"
          )}
          style={style}
          {...(!handle ? listeners : undefined)}
          {...props}
          tabIndex={!handle ? 0 : undefined}
        >
          {children}
        </div>
      </CustomTag>
    );
  }
);

export default React.memo(DraggableItem, reactFastCompare);

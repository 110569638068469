import React from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import Card from "../../Card/Card";
import LoadingBadge from "../../LoadingBadge/LoadingBadge";
import IntegrationStepHeader from "../IntegrationStepHeader/IntegrationStepHeader";

export default function CentraSuccess({
  isLoading,
  errorMessage,
  onComplete,
}: {
  isLoading: boolean;
  errorMessage: string | null;
  onComplete: () => void;
}) {
  return (
    <Card>
      <Card.Body>
        <IntegrationStepHeader
          title={"Complete Centra data integration"}
          stepNumber={5}
        />
        <Card.Text>
          You have successfully configured the Centra integration. Click
          continue to finish the integration.
        </Card.Text>
        <Card.Text>
          Warning, if you have an existing Centra integration at Depict, this
          will overwrite the existing configuration.
        </Card.Text>
        {errorMessage && <Alert variant="danger">Error: {errorMessage}</Alert>}
        {isLoading && (
          <LoadingBadge label={"Completing Centra integration..."} />
        )}
      </Card.Body>
      <Card.Footer>
        <Row>
          <Col>
            <Button variant="primary" className="w-100" onClick={onComplete}>
              Continue
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
}

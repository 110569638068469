import { produce } from "immer";
import { Action, ActionType, State } from "./BoostBury.reducer.types";
import { BoostRule } from "src/api/types";

export const initialState: State = {
  local: {
    boostRules: [],
    buryRules: [],
  },
  server: {
    boostRules: [],
    buryRules: [],
  },
  localBoostBuryManagement: {
    addedRules: [],
    removedRules: [],
    updatedRules: [],
  },
};

// Since the rule ID is an incremented number, and we might get new rules from the server,
// we need another way of telling if two rules are the same
const compareRules = (rule1: BoostRule, rule2: BoostRule) => {
  const sameEffect = rule1.effect === rule2.effect;
  const sameType = rule1.type === rule2.type;
  if (!sameEffect || !sameType) {
    return false;
  }
  let sameMeta = true;
  if (rule1.type === "scollection" && rule2.type === "scollection") {
    sameMeta = rule1.collection_id === rule2.collection_id;
  }

  return sameEffect && sameType && sameMeta;
};

export const reducer = produce((draft: State, action: Action) => {
  switch (action.type) {
    case ActionType.INIT_FROM_SERVER: {
      const { rules } = action.payload;
      draft.local.boostRules = rules.filter((rule) => rule.effect === "boost");
      draft.local.buryRules = rules.filter((rule) => rule.effect === "bury");

      // copy local state to server state
      draft.server = draft.local;

      return;
    }

    case ActionType.SET_SERVER_TO_LOCAL: {
      draft.server = draft.local;
      draft.localBoostBuryManagement = {
        addedRules: [],
        removedRules: [],
        updatedRules: [],
      };
      return;
    }

    case ActionType.SET_LOCAL_TO_SERVER: {
      draft.local = draft.server;
      draft.localBoostBuryManagement = {
        addedRules: [],
        removedRules: [],
        updatedRules: [],
      };
      return;
    }

    case ActionType.REMOVE_RULE: {
      const { rule } = action.payload;

      if (rule.effect === "boost") {
        draft.local.boostRules = draft.local.boostRules.filter(
          (r) => !compareRules(r, rule)
        );
      } else {
        draft.local.buryRules = draft.local.buryRules.filter(
          (r) => !compareRules(r, rule)
        );
      }
      draft.localBoostBuryManagement.removedRules.push(rule);

      return;
    }

    case ActionType.ADD_RULE: {
      const { rule } = action.payload;

      if (rule.effect === "boost") {
        draft.local.boostRules.push(rule);
      } else {
        draft.local.buryRules.push(rule);
      }
      draft.localBoostBuryManagement.addedRules.push(rule);

      return;
    }

    case ActionType.UPDATE_RULE: {
      const { oldRule, newRule } = action.payload;
      // For simplicity for the backend, we just remove the old rule and add the new one

      if (oldRule.effect === "boost") {
        draft.local.boostRules = draft.local.boostRules.filter(
          (r) => !compareRules(r, oldRule)
        );
        draft.local.boostRules.push(newRule);
        draft.localBoostBuryManagement.updatedRules.push([oldRule, newRule]);
        return;
      } else {
        draft.local.buryRules = draft.local.buryRules.filter(
          (r) => !compareRules(r, oldRule)
        );
        draft.local.buryRules.push(newRule);
        draft.localBoostBuryManagement.updatedRules.push([oldRule, newRule]);
        return;
      }
    }

    default:
      return;
  }
});

import { useMemo } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { ChildCollectionDto } from "src/api/types";
import "./categorybreadcrumb.scss";

export interface ListingItemBase {
  id: string;
  title: string;
  children?: Array<ListingItemBase>;
}

/** Returns a flat array that represents the path to the targetCategory from rootCategory through the .subCategories
 * Returns null if no such path is possible */
function getCategoryPath(
  rootCategory: ListingItemBase,
  targetCategory: ChildCollectionDto
): ListingItemBase[] | null {
  if (rootCategory.id === targetCategory.collection_id) {
    return [{ id: targetCategory.collection_id, title: targetCategory.title }];
  }

  if (!rootCategory.children) {
    return null;
  }

  for (const subCategory of rootCategory.children) {
    const path = getCategoryPath(subCategory, targetCategory);
    if (path) {
      return [rootCategory, ...path];
    }
  }

  return null;
}

function getCategoryPathFromMultipleRoots(
  categoryRoots: ListingItemBase[],
  targetCategory: ChildCollectionDto
): ListingItemBase[] {
  for (const root of categoryRoots) {
    const path = getCategoryPath(root, targetCategory);
    if (path) {
      return path;
    }
  }

  return [];
}

interface CategoryBreadcrumbProps {
  rootCategories: ListingItemBase[];
  targetCategory: ChildCollectionDto | null;
  onBreadcrumbClick: (category: ListingItemBase) => void;
}

export function CategoryBreadcrumb(props: CategoryBreadcrumbProps) {
  const path = useMemo(
    () =>
      props.targetCategory && props.rootCategories
        ? getCategoryPathFromMultipleRoots(
            props.rootCategories,
            props.targetCategory
          )
        : null,
    [props.rootCategories, props.targetCategory]
  );

  return (
    <Breadcrumb className="depict--CategoryBreadcrumb">
      {path &&
        path.map((category, i) => {
          const target = i === path.length;
          return (
            <Breadcrumb.Item
              key={category.id}
              className={target ? "target" : ""}
              aria-disabled={target}
              onClick={() => props.onBreadcrumbClick(category)}
            >
              {category.title}
            </Breadcrumb.Item>
          );
        })}
    </Breadcrumb>
  );
}

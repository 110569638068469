import { formatNumber } from "src/helpers";
import { Comparison } from "src/types/components";
import { MetricComparison } from "../mix/MetricComparison";
import reactFastCompare from "react-fast-compare";
import React from "react";

type ValueFormat = "REVENUE" | "PERCENTAGE";

const HighlightCard = (props: {
  title: string;
  tooltip: string;
  value: number | undefined;
  valueFormat: ValueFormat;
  comparison: Comparison | undefined;
  prefix?: string;
  suffix?: string;
  digitsAfterComma?: number;
}) => {
  const getformattedValue = (
    value: number | undefined,
    format: ValueFormat,
    prefix?: string,
    suffix?: string,
    digitsAfterComma?: number
  ): string => {
    if (typeof value === "undefined") {
      return " -- ";
    }

    return formatNumber({
      prefix: prefix,
      number: value,
      digitsAfterComma: digitsAfterComma || 0,
      suffix: suffix,
    });
  };

  return (
    <div className="depict--hightlightCard card h-100">
      <div className="card-body h-100">
        <div className="row align-items-center">
          <div className="text-center">
            <h3 className="text-gray-600">{props.title}</h3>
            <div
              className="text-black fw-bolder"
              data-bs-toggle="tooltip"
              title={props.tooltip}
            >
              <div>
                {getformattedValue(
                  props.value,
                  props.valueFormat,
                  props.prefix,
                  props.suffix,
                  props.digitsAfterComma
                )}
              </div>
              <MetricComparison comparison={props.comparison} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(HighlightCard, reactFastCompare);

import config from "src/config";
import { EnhancedSurface, LineStyle } from "src/types/components";
import { Surface } from "src/types/surfaces";
import { deepCopy } from "..";

export const getDefaultCustomSurfaces = (): {
  [surface_id: string]: EnhancedSurface;
} => {
  return {
    /**
     * These custom surfaces were used before we allowed the user to have both splitBySurface and compare enabled.
     * They might be useful in case we want to implement a custom behaviour for when (splitBySurface === false && compare !== "none").
     *
     * Eg:
     * - metric line + compare line of different colors.
     * - custom labels for compare line tooltips.
     *
     * In "getEnhancedSurfaces()" we would get one of these based on the "compare" value and assign it to the ".children" array property.
     */
    // previous: {
    //   id: "previous",
    //   depict_name: "previous",
    //   alias: "previous",
    //   display_name: "Previous period",
    //   color: config.plotColors[1],
    //   style: "dashed",
    //   kind: null,
    // },
    // "same-last-year": {
    //   id: "same-last-year",
    //   depict_name: "same-last-year",
    //   alias: "same-last-year",
    //   display_name: "YoY",
    //   color: config.plotColors[1],
    //   style: "dashed",
    //   kind: null,
    // },
    // average: {
    //   id: "average",
    //   depict_name: "average",
    //   alias: "average",
    //   display_name: "Average",
    //   color: config.plotColors[1],
    //   style: "dashed",
    //   kind: null,
    // },
    // compare: {
    //   id: "compare",
    //   depict_name: "compare",
    //   alias: "compare",
    //   display_name: "Compare",
    //   color: config.plotColors[1],
    //   style: "dashed",
    //   kind: null,
    // },
    totalFromRecommendations: {
      id: "totalFromRecommendations",
      depict_name: "totalFromRecommendations",
      alias: "totalFromRecommendations",
      display_name: "Total",
      color: config.plotColors[0],
      style: "solid",
      kind: null,
    },
  };
};

export const enhanceSurfaces = (
  surfaces: Surface[] | null
): EnhancedSurface[] => {
  let enhancedSurfaces: EnhancedSurface[] = [];

  if (!surfaces) {
    return enhancedSurfaces;
  }

  let colorIndex = 0;
  const colorsLength = config.plotColors.length;
  for (const surface of surfaces) {
    let copy = deepCopy(surface);
    const enhanced: EnhancedSurface = Object.assign(copy, {
      id: copy.alias ?? copy.depict_name ?? "null",
      color: config.plotColors[colorIndex],
      style: "solid" as LineStyle,
    });

    if (colorIndex === colorsLength - 1) {
      colorIndex = 0;
    } else {
      colorIndex++;
    }
    enhancedSurfaces.push(enhanced);
  }
  return enhancedSurfaces;
};

export const isSurfaceActive = (
  surface: EnhancedSurface,
  surfaces: EnhancedSurface[]
): boolean => {
  const index = surfaces.findIndex((s: EnhancedSurface) => {
    return s.id === surface.id;
  });

  return index !== -1;
};

export const getLineColor = (
  id: string,
  surfaces: EnhancedSurface[]
): string | null => {
  for (var i = 0; i < surfaces.length; i++) {
    const surface = surfaces[i];

    if (surface.id === id) {
      return surface.color ?? null;
    }

    if (surface.children) {
      const color = getLineColor(id, surface.children);
      if (color) {
        return color;
      }
    }
  }

  return null;
};

export const getLineStyle = (
  id: string,
  surfaces: EnhancedSurface[]
): LineStyle | null => {
  for (var i = 0; i < surfaces.length; i++) {
    const surface = surfaces[i];

    if (surface.id === id) {
      return surface.style ?? null;
    }

    if (surface.children) {
      const style = getLineStyle(id, surface.children);
      if (style) {
        return style;
      }
    }
  }

  return null;
};

export const getEnhancedSurface = (
  id: string,
  surfaces: EnhancedSurface[]
): EnhancedSurface | null => {
  for (var i = 0; i < surfaces.length; i++) {
    const surface = surfaces[i];

    if (surface.id === id) {
      return surface ?? null;
    }

    if (surface.children) {
      const found = getEnhancedSurface(id, surface.children);
      if (found) {
        return found;
      }
    }
  }

  return null;
};

import { Button, Modal } from "react-bootstrap";
import { Modal as DepictModal } from "../Modal/Modal";

type ButtonVariant =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "light"
  | "dark"
  | "link"
  | "outline-primary"
  | "outline-secondary"
  | "outline-success"
  | "outline-danger"
  | "outline-warning"
  | "outline-info"
  | "outline-light"
  | "outline-dark";

export interface ConfirmationDialogProps {
  show: boolean;
  onConfirmClick: () => Promise<void>;
  onClose?: () => Promise<void>;
  title: string;
  message: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  cancelButtonVariant?: ButtonVariant;
  primaryButtonVariant?: ButtonVariant;
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const handleClose = async () => {
    props?.onClose?.();
  };

  return (
    <DepictModal show={props.show} onClose={handleClose} title={props.title}>
      <Modal.Body>{props.message}</Modal.Body>
      <Modal.Footer>
        <Button
          variant={props.cancelButtonVariant ?? "link"}
          onClick={handleClose}
        >
          {props.cancelButtonText ?? "Cancel"}
        </Button>
        <Button
          variant={props.primaryButtonVariant ?? "danger"}
          onClick={props.onConfirmClick}
        >
          {props.confirmButtonText ?? "Confirm"}
        </Button>
      </Modal.Footer>
    </DepictModal>
  );
};

export default ConfirmationDialog;

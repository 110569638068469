import styled from "@emotion/styled";
import { Desktop, DeviceMobile } from "@phosphor-icons/react";
import { AnimatePresence, motion } from "framer-motion";

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
`;

export interface NbColumnsSwitcherProps {
  columns: number;
  setColumns: (columns: number) => void;
}
export const NbColumnsSwitcher = (props: NbColumnsSwitcherProps) => {
  const nbDesktopColumns = 4;
  const nbMobileColumns = 2;

  const displayMobileIcon = props.columns === 2;
  const displayDesktopIcon = !displayMobileIcon;

  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  return (
    <div
      css={{
        cursor: "pointer",
      }}
    >
      <AnimatePresence mode="popLayout">
        {displayMobileIcon && (
          <motion.div
            key="mobile"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={variants}
          >
            <IconContainer onClick={() => props.setColumns(nbDesktopColumns)}>
              <DeviceMobile weight="bold" />
            </IconContainer>
          </motion.div>
        )}
        {displayDesktopIcon && (
          <motion.div
            key="desktop"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={variants}
          >
            <IconContainer onClick={() => props.setColumns(nbMobileColumns)}>
              <Desktop weight="bold" />
            </IconContainer>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default NbColumnsSwitcher;

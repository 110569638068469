import * as React from "react";
// @ts-ignore
const DepictLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={99}
    height={98}
    viewBox="0 0 99 98"
    fill="none"
    scale={props.scale ?? 1}
    {...props}
  >
    <path
      fill={props.color ?? "#000"}
      d="M49.766 20.561c9.808 0 17.846-7.65 18.5-17.262-4-1.615-8.23-2.691-12.654-3.23.192.616.346 1.27.346 1.961 0 3.422-2.769 6.19-6.192 6.19a6.187 6.187 0 0 1-6.192-6.19c0-.692.115-1.345.346-1.96-4.423.538-8.654 1.614-12.654 3.23.654 9.61 8.692 17.261 18.5 17.261ZM25.035 63.43C20.112 54.932 9.497 51.78.805 56.047a49.54 49.54 0 0 0 3.5 12.572c.422-.5.96-.923 1.538-1.27 2.961-1.69 6.73-.691 8.423 2.27 1.692 2.96.692 6.727-2.27 8.419a5.888 5.888 0 0 1-1.884.692 49.735 49.735 0 0 0 9.115 9.304c8.077-5.382 10.693-16.147 5.808-24.606ZM89.343 78.73a5.886 5.886 0 0 1-1.885-.691c-2.961-1.692-3.961-5.498-2.269-8.42 1.692-2.922 5.5-3.96 8.423-2.268a5.543 5.543 0 0 1 1.539 1.269 49.102 49.102 0 0 0 3.5-12.573c-8.654-4.229-19.308-1.114-24.193 7.382-4.884 8.497-2.307 19.262 5.693 24.644 3.5-2.73 6.577-5.843 9.192-9.342ZM56.997 37.17a24.755 24.755 0 0 0 17.461-7.228l12.885-12.88a49.614 49.614 0 0 0-9.154-8.342L49.728 37.17h7.269Z"
    />
    <path
      fill={props.color ?? "#000"}
      d="M42.535 37.17h7.23L21.306 8.72a49.615 49.615 0 0 0-9.155 8.343l12.885 12.88c4.654 4.613 10.923 7.227 17.5 7.227ZM35.766 48.858c-3.27-5.69-8.692-9.804-15-11.495L3.15 32.633C1.804 36.44.92 40.516.535 44.707l38.846 10.419-3.615-6.267ZM43.035 61.392l-3.615-6.267-10.423 38.83a48.931 48.931 0 0 0 11.769 3.768l4.73-17.608a24.693 24.693 0 0 0-2.46-18.723ZM56.497 61.392a24.788 24.788 0 0 0-2.462 18.761l4.73 17.609a48.932 48.932 0 0 0 11.77-3.768l-10.384-38.87-3.654 6.268Z"
    />
    <path
      fill={props.color ?? "#000"}
      d="m63.766 48.858-3.615 6.267 38.846-10.42c-.385-4.19-1.27-8.265-2.616-12.071l-17.615 4.729c-6.346 1.653-11.73 5.805-15 11.495Z"
    />
  </svg>
);
export default DepictLogo;

import { WarningCircle } from "@phosphor-icons/react";
import { theme } from "../../designSystemVariables";
import Body from "../Body/Body";

interface DangerAlertProps {
  title?: string;
  message: string | JSX.Element;
}
const DangerAlert = (props: DangerAlertProps) => {
  return (
    <Body
      backgroundColor={theme.background.danger.default}
      titleColor={theme.textIcon.base.default}
      title={props.title || "Danger"}
      message={props.message}
      messageColor={theme.textIcon.base.default}
      icon={<WarningCircle size={24} color={theme.textIcon.danger.default} />}
    />
  );
};

export default DangerAlert;

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Footprint = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M14 11.997c-1.145 0-2.177-0.562-2.907-1.583-0.715-1.001-1.093-2.354-1.093-3.914 0-0.695 0.053-2.48 0.549-4.036 0.648-2.036 1.691-2.464 2.451-2.464 1.12 0 2.152 0.712 2.907 2.005 0.705 1.209 1.093 2.805 1.093 4.495 0 1.269 0 2.581-0.338 3.594-0.421 1.263-1.317 1.903-2.662 1.903zM13 1c-0.774 0-1.242 0.961-1.498 1.767-0.453 1.423-0.502 3.085-0.502 3.733 0 1.331 0.322 2.515 0.907 3.333 0.545 0.762 1.268 1.164 2.093 1.164 0.916 0 1.428-0.365 1.713-1.219 0.287-0.86 0.287-2.089 0.287-3.278 0-2.981-1.374-5.5-3-5.5z" fill={color}></path>
<path d="M13.5 18c-0.765 0-1.773-0.311-2.228-1.79-0.272-0.884-0.272-1.939-0.272-2.71 0-0.173 0.090-0.334 0.237-0.425s0.331-0.099 0.486-0.022c0.404 0.202 1.068 0.322 1.776 0.322s1.373-0.12 1.776-0.322c0.155-0.077 0.339-0.069 0.486 0.022s0.237 0.252 0.237 0.425c0 0.77 0 1.825-0.272 2.71-0.455 1.48-1.463 1.791-2.228 1.791zM12.006 14.206c0.015 0.57 0.063 1.195 0.221 1.709 0.296 0.961 0.835 1.085 1.272 1.085s0.976-0.123 1.272-1.085c0.158-0.515 0.207-1.139 0.221-1.709-0.443 0.109-0.957 0.169-1.494 0.169s-1.051-0.059-1.494-0.169z" fill={color}></path>
<path d="M5 13.997c-1.345 0-2.241-0.64-2.662-1.903-0.338-1.014-0.338-2.326-0.338-3.594 0-1.69 0.388-3.286 1.093-4.495 0.754-1.293 1.787-2.005 2.907-2.005 0.761 0 1.804 0.427 2.451 2.464 0.495 1.557 0.549 3.341 0.549 4.036 0 1.56-0.378 2.914-1.093 3.914-0.73 1.021-1.762 1.583-2.907 1.583zM6 3c-1.626 0-3 2.519-3 5.5 0 1.189 0 2.418 0.287 3.278 0.285 0.855 0.797 1.219 1.713 1.219 0.825 0 1.549-0.403 2.093-1.164 0.585-0.818 0.907-2.002 0.907-3.333 0-0.648-0.049-2.31-0.502-3.733-0.256-0.806-0.725-1.767-1.498-1.767z" fill={color}></path>
<path d="M5.5 20c-0.765 0-1.773-0.311-2.228-1.79-0.272-0.884-0.272-1.939-0.272-2.71 0-0.173 0.090-0.334 0.237-0.425s0.331-0.099 0.486-0.022c0.404 0.202 1.068 0.322 1.776 0.322s1.373-0.12 1.776-0.322c0.155-0.077 0.339-0.069 0.486 0.022s0.237 0.252 0.237 0.425c0 0.77 0 1.825-0.272 2.71-0.455 1.48-1.463 1.791-2.228 1.791zM4.007 16.206c0.015 0.57 0.063 1.195 0.221 1.709 0.296 0.961 0.835 1.085 1.272 1.085s0.976-0.123 1.272-1.085c0.158-0.515 0.207-1.139 0.221-1.709-0.443 0.109-0.957 0.169-1.493 0.169s-1.051-0.059-1.494-0.169z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Footprint); 
import { css } from "@emotion/react";
import { Plugs } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getData } from "src/api/authorizedApi";
import ShopifyIntegration from "src/components/integrations/shopify/ShopifyIntegration";
import TopBar from "src/components/storybook/TopBar/TopBar";
import { theme } from "src/components/storybook/designSystemVariables";
import useAuthorizedApi from "src/helpers/hooks/app/useAuthorizedApi";
import { useMerchant } from "src/helpers/hooks/app/useMerchant";
import useUser from "src/helpers/hooks/useUser";
import { QueryId, getQueryKey } from "src/queries/queries";
import "src/scss/views/integrations.scss";
import CentraIntegrationEditor from "../components/integrations/centra/CentraIntegrationEditor";
import IntegrationCard from "../components/storybook/Integrations/IntegrationCard/IntegrationCard";
import {
  CentraLogo,
  ShopifyLogo,
} from "../components/storybook/Integrations/IntegrationCard/icons";
import { Platform } from "../components/storybook/Integrations/Onboarding/SelectPlatform/SelectPlatform";
import { useTrackPageView } from "../helpers/hooks/mix/useSegment";
import ProtectedRoute from "../router/ProtectedRoute";

const useIntegrations = (merchantId: string | null) => {
  const { api } = useAuthorizedApi();

  const query = useQuery({
    queryKey: getQueryKey(QueryId.GetIntegrations, merchantId || ""),
    queryFn: async () => {
      if (merchantId && api) {
        const response = await api.GET(`/api/v0/integrations/{merchant_id}`, {
          params: {
            path: {
              merchant_id: merchantId,
            },
          },
        });

        return getData(response);
      }
    },
    enabled: !!merchantId && !!api,
  });

  return {
    completedIntegrations: query.data?.map((integration) => integration.type),
  };
};

const Integrations = () => {
  const { user } = useUser();

  const { merchant, merchantId } = useMerchant();
  useTrackPageView("Integrations");
  const [viewedIntegrationId, setViewedIntegrationId] =
    useState<Platform | null>(null);
  const { completedIntegrations } = useIntegrations(merchantId);

  const isCentraIntegrated = !!completedIntegrations?.includes("CentraSource");
  const isShopifyIntegrated =
    !!completedIntegrations?.includes("ShopifySource");

  const renderViewedIntegration = () => {
    if (!merchant) return null;
    switch (viewedIntegrationId) {
      case Platform.CENTRA:
        return (
          <CentraIntegrationEditor
            merchant={merchant.id}
            onClose={() => {
              setViewedIntegrationId(null);
            }}
          />
        );
      case Platform.SHOPIFY:
        return (
          <ShopifyIntegration
            merchant={merchant.id}
            onClose={() => {
              setViewedIntegrationId(null);
            }}
            onSuccess={() => {
              setViewedIntegrationId(null);
            }}
          />
        );
      default:
        return (
          <Row>
            <IntegrationCard
              platform={Platform.CENTRA}
              onViewIntegration={(integrationId) =>
                setViewedIntegrationId(integrationId)
              }
              name="Centra"
              description="Connect Centra to Depict to supercharge your catalog with AI-driven features such as chunking, automated categories, collection generation and more!"
              isIntegrated={isCentraIntegrated}
              logo={<CentraLogo />}
            />
            <IntegrationCard
              platform={Platform.SHOPIFY}
              onViewIntegration={(integrationId) =>
                setViewedIntegrationId(integrationId)
              }
              name="Shopify"
              description="Connect Shopify to Depict to supercharge your catalog with AI-driven features such as chunking, automated categories, collection generation and more!"
              isIntegrated={isShopifyIntegrated}
              logo={<ShopifyLogo />}
            />
          </Row>
        );
    }
  };

  return (
    <ProtectedRoute
      user={user}
      merchant={merchant}
      accessValidator={(u, m) => m.no_code_config || u.is_superuser}
    >
      <TopBar>
        <Container
          fluid
          css={css`
            padding: 0;
            align-self: center;
            justify-content: center;
          `}
        >
          <Row
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <Col
              css={css`
                display: flex;
                align-items: center;
                flex-direction: row;
              `}
            >
              <div
                css={css`
                  padding: 1rem;
                  line-height: 1rem;
                `}
              >
                <Plugs size={"1rem"} />
              </div>
              <p
                css={css`
                  margin-bottom: 0;
                  color: ${theme.textIcon.base.default};
                `}
              >
                Integrations
              </p>
            </Col>
            <Col
              css={css`
                display: flex;
                justify-content: end;
              `}
            ></Col>
          </Row>
        </Container>
      </TopBar>
      <div
        className="depict--MainContent container-fluid mt-4"
        id="integrations"
      >
        <div>{renderViewedIntegration()}</div>
      </div>
    </ProtectedRoute>
  );
};

export default React.memo(Integrations);

import {
  Eye,
  EyeClosed,
  PushPin,
  PushPinSlash,
  Sparkle,
} from "@phosphor-icons/react";
import { FC, PropsWithChildren, useMemo } from "react";
import { CurvyCorner } from "../../CurvyCorner/CurvyCorner";
import { theme } from "../../designSystemVariables";
import { css } from "@emotion/react";
import {
  CategoryProductGridCardProps,
  ImageContainer,
} from "./CategoryProductGridCard";
import { HoverableIcon } from "../HoverableIcon";

const IconOuterContainer: FC<PropsWithChildren> = ({ children }) => (
  <div
    css={css`
      display: flex;
      padding: 0px 0px 8px 8px;
      flex-direction: column;
      align-items: flex-end;
      gap: 0;
      transition: gap 0.2s;

      ${ImageContainer}:hover & {
        gap: 8px;
      }
    `}
  >
    {children}
  </div>
);

const CurationCornerIcon: FC<{ visible?: boolean } & PropsWithChildren> = ({
  visible,
  children,
}) => (
  <div
    css={css`
      ${!visible &&
      `
        height: 0;
        transition: height 0.2s;
        overflow: hidden;

        ${ImageContainer}:hover & {
          height: 20px;
        }
      `}
    `}
  >
    {children}
  </div>
);

const FilledPinIcon = (
  <PushPin weight="fill" color={theme.textIcon.base.default} />
);
const buildFilledAiSortedIcon = (color: string) => (
  <Sparkle weight="fill" color={color} />
);

type CurationCornerProps = CategoryProductGridCardProps & {
  backgroundColor: string;
};

export function CurationCorner(props: CurationCornerProps) {
  const { pinned, hidden } = props;
  const aiSorted = !pinned && !hidden;

  const pinningIcon = useMemo(() => {
    const onClick = pinned
      ? props.actionCBs.onAiSortedClick
      : props.actionCBs.onPinToTop;

    return (
      <HoverableIcon
        iconWhenHovered={
          pinned ? (
            <PushPinSlash color={theme.textIcon.base.default} />
          ) : (
            FilledPinIcon
          )
        }
        iconWhenNotHovered={
          pinned ? (
            FilledPinIcon
          ) : (
            <PushPin color={theme.textIcon.base.default} />
          )
        }
        onClick={() => onClick?.(props.product.main_product_id)}
      />
    );
  }, [
    pinned,
    props.actionCBs.onAiSortedClick,
    props.actionCBs.onPinToTop,
    props.product.main_product_id,
  ]);

  const hidingIcon = useMemo(() => {
    const onClick = hidden
      ? props.actionCBs.onAiSortedClick
      : props.actionCBs.onHideClick;

    if (props.isAutoHidden) {
      return <EyeClosed />;
    }

    return (
      <HoverableIcon
        iconWhenHovered={
          hidden ? <Eye color={theme.textIcon.base.default} /> : <EyeClosed />
        }
        iconWhenNotHovered={
          hidden ? <EyeClosed /> : <Eye color={theme.textIcon.base.default} />
        }
        onClick={() => onClick?.(props.product.main_product_id)}
      />
    );
  }, [
    hidden,
    props.actionCBs.onAiSortedClick,
    props.actionCBs.onHideClick,
    props.product.main_product_id,
  ]);

  const aiSortedIcon = useMemo(() => {
    const onClick = aiSorted ? undefined : props.actionCBs.onAiSortedClick;

    return (
      <HoverableIcon
        iconWhenHovered={buildFilledAiSortedIcon(theme.textIcon.base.default)}
        iconWhenNotHovered={
          aiSorted ? (
            buildFilledAiSortedIcon(theme.textIcon.accent.default)
          ) : (
            <Sparkle color={theme.textIcon.base.default} />
          )
        }
        onClick={() => onClick?.(props.product.main_product_id)}
      />
    );
  }, [
    aiSorted,
    props.actionCBs.onAiSortedClick,
    props.product.main_product_id,
  ]);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <CurvyCorner
        corner={"top-right"}
        borderRadius={"0.75em"}
        contentContainerStyles={{
          borderTopRightRadius: "0.5em",
        }}
        backgroundColor={props.backgroundColor}
      >
        <IconOuterContainer>
          {!props.isAutoHidden && (
            <>
              <CurationCornerIcon visible={pinned}>
                {pinningIcon}
              </CurationCornerIcon>
              <CurationCornerIcon visible={!pinned && !hidden}>
                {aiSortedIcon}
              </CurationCornerIcon>
            </>
          )}
          <CurationCornerIcon visible={hidden}>{hidingIcon}</CurationCornerIcon>
        </IconOuterContainer>
      </CurvyCorner>
    </div>
  );
}

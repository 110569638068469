import { BoostRule } from "src/api/types";

export type BoostRuleType = BoostRule["type"];

export type Option = {
  label: string;
  value: Extract<BoostRuleType, string>;
  isDisabled?: boolean;
};

const checkIfRuleIsDisabled = (
  rules: BoostRule[],
  ruleType: Extract<BoostRuleType, string>
) => {
  return typeof rules.find((rule) => rule.type === ruleType) !== "undefined";
};

const getCollectionOption = (
  collectionBoostRuleType: "scollection"
): Option => {
  return {
    label: "Campaign",
    value: collectionBoostRuleType,
  };
};

export const getBoostOptions = (
  currentRules: BoostRule[],
  collectionBoostRuleType: "scollection"
): Option[] => [
  {
    label: "New in",
    value: "new_products" as const,
    isDisabled: checkIfRuleIsDisabled(currentRules, "new_products"),
  },
  {
    label: "Bestseller",
    value: "best_selling" as const,
    isDisabled: checkIfRuleIsDisabled(currentRules, "best_selling"),
  },
  {
    label: "On sale",
    value: "on_sale" as const,
    isDisabled: checkIfRuleIsDisabled(currentRules, "on_sale"),
  },
  getCollectionOption(collectionBoostRuleType),
];

export const getBuryOptions = (
  currentRules: BoostRule[],
  collectionBoostRuleType: "scollection"
): Option[] => [
  {
    label: "Out of stock",
    value: "out_of_stock" as const,
    isDisabled: checkIfRuleIsDisabled(currentRules, "out_of_stock"),
  },
  {
    label: "Few sizes available",
    value: "few_sizes_available" as const,
    isDisabled: checkIfRuleIsDisabled(currentRules, "few_sizes_available"),
  },
  {
    label: "On sale",
    value: "on_sale" as const,
    isDisabled: checkIfRuleIsDisabled(currentRules, "on_sale"),
  },
  getCollectionOption(collectionBoostRuleType),
];

import { useMemo } from "react";
import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { PathContext, PathContextValue } from "src/contexts/PathContext";
import { useAuthorizedMerchantsContext } from "src/helpers/hooks/app/useAuthorizedMerchantsContext";
import ErrorLayout from "src/layouts/ErrorLayout";
import SidenavLayout from "../layouts/SidenavLayout";
import { SidebarLayoutProps } from "../types/components";
import {
  ROUTES,
  buildPathWithMerchantId,
  getPath,
  routeConfigs,
} from "./Routes";

import NotFound from "src/views/404";
import Catalog from "src/views/CatalogV2";
import CategoryV3 from "src/views/CategoryV3/CategoryV3";
import Configure from "src/views/Configure";
import Home from "src/views/Home";
import Integrations from "src/views/Integrations";
import NoMerchants from "src/views/NoMerchants";
import Onboarding from "src/views/Onboarding";
import { SidebarContextProvider } from "../contexts/SidebarContext";
import BoostAndBuryView from "../views/BoostAndBury";
import Search from "../views/Search/Search";

import { AppShell } from "./AppShell";
import Maintenance from "../views/Maintenance";

export type PortalRoutesProps = SidebarLayoutProps;

const DepictRouteElement = (route: ROUTES, element: JSX.Element) => {
  const pathContextValue = useMemo<PathContextValue>(() => {
    return {
      path: route,
      config: routeConfigs[route],
    };
  }, [route]);

  return (
    <PathContext.Provider value={pathContextValue}>
      <SidebarContextProvider>
        <SidenavLayout>{element}</SidenavLayout>
      </SidebarContextProvider>
    </PathContext.Provider>
  );
};

function NavigateToDashboard() {
  const fallbackMerchant =
    useAuthorizedMerchantsContext()?.authorizedMerchants?.[0];

  const path = buildPathWithMerchantId(
    ROUTES.DASHBOARD,
    fallbackMerchant?.id || ""
  );

  return <Navigate replace to={path} />;
}

export function MyRouter(baseName: string) {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route element={<AppShell />}>
        <Route path={getPath(ROUTES.HOME)} element={<NavigateToDashboard />} />
        <Route path={getPath(ROUTES.LOGIN)} element={<NavigateToDashboard />} />
        <Route
          path={getPath(ROUTES.BOOST_AND_BURY)}
          element={DepictRouteElement(
            ROUTES.BOOST_AND_BURY,
            <BoostAndBuryView />
          )}
        />
        <Route
          path={getPath(ROUTES.DASHBOARD)}
          element={DepictRouteElement(ROUTES.DASHBOARD, <Home />)}
        />
        <Route
          path={getPath(ROUTES.CONFIGURE)}
          element={DepictRouteElement(ROUTES.CONFIGURE, <Configure />)}
        />
        <Route
          path={getPath(ROUTES.CATEGORY_V3)}
          element={DepictRouteElement(ROUTES.CATEGORY_V3, <CategoryV3 />)}
        />
        <Route
          path={getPath(ROUTES.CATALOG)}
          element={DepictRouteElement(ROUTES.CATALOG, <Catalog />)}
        />
        <Route
          path={getPath(ROUTES.SEARCH)}
          element={DepictRouteElement(ROUTES.SEARCH, <Search />)}
        />
        <Route
          path={getPath(ROUTES.INTEGRATIONS)}
          element={DepictRouteElement(ROUTES.INTEGRATIONS, <Integrations />)}
        />
        <Route path={getPath(ROUTES.ONBOARDING)} element={<Onboarding />} />
        <Route element={<ErrorLayout />}>
          <Route
            path={getPath(ROUTES.NO_MERCHANTS)}
            element={<NoMerchants />}
          />
          <Route path={getPath(ROUTES.MAINTENANCE)} element={<Maintenance />} />
          <Route path={getPath(ROUTES.NOT_FOUND)} element={<NotFound />} />
          <Route
            path={getPath(ROUTES.CATCH_ALL)}
            element={<Navigate replace to="/404" />}
          />
        </Route>
      </Route>
    ),
    {
      basename: baseName,
    }
  );
}

import React from "react";
import Button from "../Button/Button";
import { Plus } from "@phosphor-icons/react";

interface AddProductsButtonProps {
  onClick: () => void;
  buttonsDisabled: boolean;
}

export function AddProductsButton(props: AddProductsButtonProps) {
  return (
    <Button
      text="Products"
      icon={<Plus />}
      variant="primary"
      onClick={props.onClick}
      disabled={props.buttonsDisabled}
    />
  );
}

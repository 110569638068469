import React, { useMemo } from "react";
import Select, { SingleValue } from "react-select";
import { MarketSelectorProps } from "src/types/components";
import { Market } from "src/api/types";

const MarketSelector = (props: MarketSelectorProps) => {
  const { uppercase, currentMarket, availableMarkets, setMarketCB } = props;

  const customStyles = {
    container: (provided: any) => ({
      ...provided,
      fontSize: ".75rem",
      fontWeight: "500",
      borderRadius: "1.5rem",
      paddingLeft: "1.125rem",
      paddingRight: "2px",
    }),
    menu: (provided: any) => ({
      ...provided,
      fontSize: "1rem",
      minWidth: "285px",
      boxShadow: "2px 2px 8px 4px rgba(188,188,190,0.1)",
    }),
    control: (provided: any) => ({
      ...provided,
      background: "transparent",
      border: 0,
      boxShadow: "0 0 0 0px",
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: "none",
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23333335' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "16px 12px",
      color: "#0f0f0f",
      paddingLeft: "0",
    }),
    option: (provided: any, state: any) => {
      let bgColor = state.isFocused ? "#eeeef0" : "transparent";
      if (state.isSelected) {
        bgColor = "#dfecfd";
      }

      return {
        ...provided,
        paddingLeft: "24px",
        paddingRight: "24px",
        color: "#0f0f0f",
        textTransform: uppercase ? "uppercase" : "none",
        cursor: "pointer",
        fontWeight: "normal",
        backgroundColor: bgColor,
      };
    },
    singleValue: (provided: any) => ({
      ...provided,
      textTransform: uppercase ? "uppercase" : "none",
      marginLeft: 0,
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      paddingLeft: "0px",
      paddingRight: "0px",
    }),
  };

  const groupStyles = {
    paddingLeft: "12px",
    paddingRight: "12px",
    fontSize: "0.875rem",
    textTransform: "none",
    color: "#868689",
  } as any;

  const marketOnChange = (
    market: SingleValue<{
      label: string | undefined | null;
      value: string | null;
    }>
  ) => {
    setMarketCB(market?.value ? market.value : null);
  };

  const currentLabel = useMemo(() => {
    const market: Market | undefined = availableMarkets?.find(
      (m) => m.id === currentMarket
    );
    return market?.display_name;
  }, [currentMarket, availableMarkets]);

  return (
    <>
      <div className="depict--marketSelector" data-tc="market-select">
        <label htmlFor="market" className="form-label">
          Market
        </label>
        {!availableMarkets && (
          <button
            className="btn rounded-pill dpt-color-background-neutral-default"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            &nbsp;
          </button>
        )}
        {availableMarkets && availableMarkets.length === 1 && (
          <button
            className="btn rounded-pill dpt-color-background-neutral-default"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {availableMarkets[0]?.display_name}
          </button>
        )}
        {availableMarkets && availableMarkets.length > 1 && (
          <Select
            className="dpt-color-background-neutral-default"
            styles={customStyles}
            formatGroupLabel={(data: any) => (
              <div style={groupStyles}>
                <span>{data.label}</span>
              </div>
            )}
            onChange={marketOnChange}
            value={{
              value: currentMarket ?? null,
              label: currentLabel,
            }}
            options={[
              {
                label: "Markets",
                options: availableMarkets?.map((market: Market) => {
                  return {
                    value: market.id,
                    label: market?.display_name,
                  };
                }),
              },
            ]}
          />
        )}
      </div>
    </>
  );
};

export default React.memo(MarketSelector);

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Barcode2 = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M0 3h1v12h-1v-12z" fill={color}></path>
<path d="M16 3h0.5v12h-0.5v-12z" fill={color}></path>
<path d="M3.5 3h0.5v12h-0.5v-12z" fill={color}></path>
<path d="M12 3h1v12h-1v-12z" fill={color}></path>
<path d="M2 3h1v12h-1v-12z" fill={color}></path>
<path d="M7.5 3h0.5v12h-0.5v-12z" fill={color}></path>
<path d="M6 3h1v12h-1v-12z" fill={color}></path>
<path d="M19.5 3h0.5v12h-0.5v-12z" fill={color}></path>
<path d="M17 3h1v12h-1v-12z" fill={color}></path>
<path d="M8.5 3h0.5v12h-0.5v-12z" fill={color}></path>
<path d="M10 3h1v12h-1v-12z" fill={color}></path>
<path d="M0 16h1v1h-1v-1z" fill={color}></path>
<path d="M2 16h1v1h-1v-1z" fill={color}></path>
<path d="M3.5 16h0.5v1h-0.5v-1z" fill={color}></path>
<path d="M6 16h1v1h-1v-1z" fill={color}></path>
<path d="M7.5 16h0.5v1h-0.5v-1z" fill={color}></path>
<path d="M8.5 16h0.5v1h-0.5v-1z" fill={color}></path>
<path d="M10 16h1v1h-1v-1z" fill={color}></path>
<path d="M12 16h1v1h-1v-1z" fill={color}></path>
<path d="M16 16h0.5v1h-0.5v-1z" fill={color}></path>
<path d="M17 16h1v1h-1v-1z" fill={color}></path>
<path d="M19.5 16h0.5v1h-0.5v-1z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Barcode2); 
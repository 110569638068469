import React, { useEffect, useMemo, useState } from "react";
import CardCollapsible from "src/components/cards/CardCollapsible";
import ConfigurationsSearch from "src/components/configuration/ConfigurationsSearch";
import MarketSelector from "src/components/filters/MarketSelector";
import Button from "src/components/storybook/Button/Button";
/**
 * Components
 */
import { Copy, Plus } from "src/components/linearicons";
import SectionConfiguration from "src/components/sections/SectionConfiguration";
import config from "src/config";
import { getConfigurationAiState } from "src/helpers/configuration";
import { useMarket } from "src/helpers/hooks/app/useMarket";
import { useMerchant } from "src/helpers/hooks/app/useMerchant";
import useConfigurations from "src/helpers/hooks/configurations/useConfigurations";
import useCreateNewConfiguration from "src/helpers/hooks/configurations/useCreateNewConfiguration";
import useCreateNewRelationships from "src/helpers/hooks/configurations/useCreateNewRelationships";
import useDeleteConfiguration from "src/helpers/hooks/configurations/useDeleteConfiguration";
import useDuplicateConfiguration from "src/helpers/hooks/configurations/useDuplicateConfiguration";
/**
 * Helpers
 */
import useGroups from "src/helpers/hooks/configurations/useGroups";
import useMarketConfigurations from "src/helpers/hooks/configurations/useMarketConfigurations";
import useOnViewConfiguration from "src/helpers/hooks/configurations/useOnViewConfiguration";
import useOnViewGroups from "src/helpers/hooks/configurations/useOnViewGroups";
import useProducts from "src/helpers/hooks/configurations/useProducts";
import useSaveConfiguration from "src/helpers/hooks/configurations/useSaveConfiguration";
import useLoadingStatus from "src/helpers/hooks/mix/useLoadingStatus";
import useSurfaces from "src/helpers/hooks/surfaces/useSurfaces";
import useWindowDimensions from "src/helpers/hooks/useWindowDimensions";
import "src/scss/views/configure.scss";
/**
 * Typing
 */
import { css } from "@emotion/react";
import { setRightSidebarOverlayClass } from "src/helpers";
import useHelpModal from "src/helpers/hooks/app/useHelpModal";
import useLoadingQueue from "src/helpers/hooks/app/useLoadingQueue";
import useRightSidebarOverlay from "src/helpers/hooks/app/useRightSidebarOverlay";
import useUser from "src/helpers/hooks/useUser";
import { Surface } from "src/types/surfaces";
import { ImageResizeProvider } from "../components/storybook/ImageResizeProvider/ImageResizeProvider";
import { useTrackPageView } from "../helpers/hooks/mix/useSegment";
import ProtectedRoute from "../router/ProtectedRoute";

const Configure = () => {
  const { user } = useUser();
  const { merchant, merchantId } = useMerchant();
  const [isRightSidebarOpen, toggleRightSidebarOverlayCB] =
    useRightSidebarOverlay();

  useEffect(() => {
    setRightSidebarOverlayClass(isRightSidebarOpen as boolean);
    return () => {
      setRightSidebarOverlayClass(false);
    };
  }, [isRightSidebarOpen]);

  const [loadingQueue, controlLoadingQueueCB] = useLoadingQueue();

  const [_, toggleHelpModalCB] = useHelpModal();

  const [isLoading] = useLoadingStatus(loadingQueue);

  const { width } = useWindowDimensions();

  const { market, setMarket, availableMarkets } = useMarket();

  useTrackPageView("Configure");

  const [currentConfigurationId, setCurrentConfigurationId] = useState<
    number | null
  >(null);

  const [configurations, refreshConfigurations] = useConfigurations(
    merchantId,
    controlLoadingQueueCB
  );
  const [surfaces] = useSurfaces(merchantId, controlLoadingQueueCB);
  const [products] = useProducts(merchant, market, controlLoadingQueueCB);
  const [groups] = useGroups(merchantId, market, controlLoadingQueueCB);

  useEffect(() => {
    setCurrentConfigurationId(null);
  }, [market, merchantId]);

  const [marketConfigurations] = useMarketConfigurations(
    configurations,
    market
  );

  const [onViewConfiguration] = useOnViewConfiguration(
    marketConfigurations,
    currentConfigurationId
  );

  const [onViewGroups] = useOnViewGroups(
    onViewConfiguration?.relationships ?? null,
    merchantId,
    market,
    controlLoadingQueueCB
  );

  const [createNewConfiguration] = useCreateNewConfiguration(
    merchantId,
    market,
    configurations?.length ?? 0,
    setCurrentConfigurationId,
    refreshConfigurations
  );

  const [createNewRelationships] = useCreateNewRelationships(
    merchantId,
    market,
    refreshConfigurations,
    controlLoadingQueueCB
  );

  const onViewAiState = useMemo(
    () => getConfigurationAiState(onViewConfiguration),
    [onViewConfiguration]
  );

  const [duplicateConfiguration] = useDuplicateConfiguration(
    market,
    onViewConfiguration,
    onViewAiState.currentConf ?? false,
    setCurrentConfigurationId,
    refreshConfigurations,
    controlLoadingQueueCB
  );

  const [saveConfiguration] = useSaveConfiguration(
    merchantId,
    market,
    refreshConfigurations,
    controlLoadingQueueCB
  );
  const [deleteConfiguration] = useDeleteConfiguration(
    merchantId,
    refreshConfigurations,
    controlLoadingQueueCB
  );

  const filteredSurfaces = useMemo(() => {
    return (
      surfaces?.filter((surface: Surface) => {
        return surface.depict_name !== null;
      }) || []
    );
  }, [surfaces]);

  return (
    <ProtectedRoute
      user={user}
      merchant={merchant}
      accessValidator={(u, m) =>
        u.is_superuser || m.product_status.recommendations_state !== "inactive"
      }
    >
      <ImageResizeProvider merchantId={merchant?.id}>
        <div
          className="depict--MainContent container-fluid mt-4"
          css={css`
            flex: 1;
            display: flex;
            flex-direction: column;
          `}
        >
          <div className="depict--Header row mb-3">
            <div className="col-1 col-sm-auto depict--Separator">
              <div>-</div>
            </div>
            <div className="col-5 col-sm-auto">
              <MarketSelector
                currentMarket={market}
                availableMarkets={availableMarkets}
                setMarketCB={setMarket}
              />
            </div>
          </div>
          <div
            className="depict--Content row"
            css={css`
              flex: 1;
            `}
          >
            <div className="col-lg-auto">
              <div
                className="depict--ConfigureConfigurations"
                css={css`
                  height: 100%;
                `}
              >
                <CardCollapsible
                  title="Configurations"
                  id="collapseCardConfigurations"
                  className="mb-3"
                  isVertical={
                    width < config.const.bootstrapLgWidth ? false : true
                  }
                >
                  <div className="depict--CardContent__zeroPad">
                    <ConfigurationsSearch
                      items={marketConfigurations}
                      listItemOnClickCB={setCurrentConfigurationId}
                      selectedItem={
                        onViewConfiguration ? onViewConfiguration : null
                      }
                    />
                  </div>
                  <div className="depict--ConfigurationsFooter">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-depict-green btn-icon me-2"
                      onClick={duplicateConfiguration}
                      disabled={onViewConfiguration === null || isLoading}
                      data-tc="duplicate-current-configuration"
                    >
                      <Copy size="12" />
                    </button>
                    <Button
                      onClick={createNewConfiguration}
                      data-tc="create-new-configuration"
                      text="Create new"
                      icon={<Plus size="12" className="me-2" />}
                      disabled={false}
                    />
                  </div>
                </CardCollapsible>
              </div>
            </div>
            <div className="col">
              {onViewConfiguration && (
                <SectionConfiguration
                  onViewConfiguration={onViewConfiguration}
                  showAiControls={!(merchant?.no_code_config ?? false)}
                  onViewAiState={onViewAiState}
                  onViewGroups={onViewGroups}
                  products={products}
                  surfaces={filteredSurfaces}
                  groups={groups}
                  saveConfigurationCB={saveConfiguration}
                  deleteConfigurationCB={deleteConfiguration}
                  createNewRelationshipsCB={createNewRelationships}
                  hasLoadingProcesses={isLoading}
                  toggleRightSidebarOverlayCB={toggleRightSidebarOverlayCB}
                  toggleHelpModalCB={toggleHelpModalCB}
                />
              )}
            </div>
          </div>
        </div>
      </ImageResizeProvider>
    </ProtectedRoute>
  );
};

export default React.memo(Configure);

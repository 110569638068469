import { Col, Container, Row } from "react-bootstrap";
import ShopifyIntegrationSteps from "./ShopifyIntegrationSteps";
import { memo } from "react";

function ShopifyIntegration({
  merchant,
  onClose,
  onSuccess,
}: {
  merchant: string;
  onClose: () => void;
  onSuccess: () => void;
}) {
  return (
    <Container>
      <Row className="justify-content-md-center align-items-center min-vh-100">
        <Col md={6}>
          <ShopifyIntegrationSteps
            merchant={merchant}
            onClose={onClose}
            onSuccess={onSuccess}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default memo(ShopifyIntegration);

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Pilcrow = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M15.5 2h-8.5c-2.206 0-4 1.794-4 4s1.794 4 4 4h1v7.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-14.5h3v14.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-14.5h2.5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5zM8 9h-1c-1.654 0-3-1.346-3-3s1.346-3 3-3h1v6z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Pilcrow); 
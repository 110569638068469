import { useCallback, useState } from "react";
import { getAuthorizedApi, throwErrorIfAny } from "src/api/authorizedApi";
import {
  CentraMappedAttributeConfig,
  ConstantParsingConfig,
} from "src/api/types";
import { ParsingMethod } from "src/components/storybook/Integrations/ParsingMethodSelector/types";
import useAuthentication from "../../../helpers/hooks/app/useAuthentication";
import {
  CentraAttributeConfigWithAttributeId,
  CentraConfig,
  CompleteCentraConfig,
} from "../../../types/centra";
import CentraSuccess from "../../storybook/Integrations/Centra/CentraSuccess";
import CentraApiConfigStep from "./CentraApiConfigStep";
import CentraAttributesStep from "./CentraAttributesStep";
import CentraMarketsConfigStep from "./CentraMarketsConfigStep";

enum CentraStep {
  API_CREDENTIALS,
  MARKETS,
  ATTRIBUTES,
  SUCCESS,
}

const useCentraIntegration = (merchantId: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [getAccessToken] = useAuthentication();

  const upsertCentraConfig = useCallback(
    async (centraConfig: CompleteCentraConfig) => {
      const api = getAuthorizedApi(await getAccessToken());

      setIsLoading(true);
      try {
        type AttributeParsingConfigs = (
          | ConstantParsingConfig
          | CentraMappedAttributeConfig
        )[];

        const attributeParsingConfigs = centraConfig.attributeConfigs.map<
          AttributeParsingConfigs[number]
        >((attributeConfig) => {
          if (attributeConfig.type === ParsingMethod.Constant) {
            const config: ConstantParsingConfig = {
              attribute_id: attributeConfig.attributeId,
              type: attributeConfig.type,
              value: attributeConfig.value,
            };

            return config;
          } else if (attributeConfig.type === ParsingMethod.Map) {
            const config: CentraMappedAttributeConfig = {
              attribute_id: attributeConfig.attributeId,
              type: attributeConfig.type,
              attribute_type: attributeConfig.attributeType,
              element_key: attributeConfig.elementKey,
            };

            return config;
          } else {
            throw new Error("Invalid parsing method");
          }
        });

        const response = await api.POST(
          "/api/v0/integrations/centra/config/{merchant_id}",
          {
            params: {
              path: {
                merchant_id: merchantId,
              },
            },
            body: {
              api_key: centraConfig.apiKey,
              api_url: centraConfig.apiUrl,
              markets: centraConfig.markets,
              attribute_parsing_configs: attributeParsingConfigs,
              store_id: centraConfig.storeId,
            },
          }
        );

        throwErrorIfAny(response);

        setIsLoading(false);
      } catch (e: any | { message: string }) {
        setIsLoading(false);
        setError(e.message);
      }
    },
    [getAccessToken, merchantId]
  );

  return { isLoading, error, upsertCentraConfig };
};

export default function CentraIntegrationSteps({
  merchant,
  onClose,
  onSuccess,
}: {
  merchant: string;
  onClose: () => void;
  onSuccess: () => void;
}) {
  const [currentStep, setCurrentStep] = useState<CentraStep>(
    CentraStep.API_CREDENTIALS
  );
  const [centraConfig, setCentraConfig] = useState<CentraConfig>({});
  const { isLoading, error, upsertCentraConfig } =
    useCentraIntegration(merchant);

  const onSetApiCredentials = (apiUrl: string, apiKey: string) => {
    setCentraConfig({
      ...centraConfig,
      apiUrl,
      apiKey,
    });
  };

  const onSetMarkets = (storeId: string, markets: string[]) => {
    setCentraConfig({
      ...centraConfig,
      storeId,
      markets,
    });
  };

  const onSetAttributes = (
    attributeConfigs: CentraAttributeConfigWithAttributeId[]
  ) => {
    setCentraConfig({
      ...centraConfig,
      attributeConfigs,
    });
  };

  switch (currentStep) {
    case CentraStep.API_CREDENTIALS:
      return (
        <CentraApiConfigStep
          merchant={merchant}
          apiUrl={centraConfig.apiUrl ?? ""}
          apiKey={centraConfig.apiKey ?? ""}
          onSubmit={(apiUrl, apiKey) => {
            onSetApiCredentials(apiUrl, apiKey);
            setCurrentStep(CentraStep.MARKETS);
          }}
          onBack={() => onClose()}
        />
      );
    case CentraStep.MARKETS:
      return (
        <CentraMarketsConfigStep
          apiUrl={centraConfig.apiUrl ?? ""}
          apiKey={centraConfig.apiKey ?? ""}
          chosenMarketIds={centraConfig.markets ?? []}
          onBack={() => setCurrentStep(CentraStep.API_CREDENTIALS)}
          onSubmit={(storeId, markets) => {
            onSetMarkets(storeId, markets);
            setCurrentStep(CentraStep.ATTRIBUTES);
          }}
        />
      );
    case CentraStep.ATTRIBUTES:
      return (
        <CentraAttributesStep
          merchantId={merchant}
          apiUrl={centraConfig.apiUrl ?? ""}
          apiKey={centraConfig.apiKey ?? ""}
          onBack={() => setCurrentStep(CentraStep.MARKETS)}
          onSubmit={(
            colorParsingConfig,
            genderParsingConfig,
            ageParsingConfig,
            optionalParsingConfigs
          ) => {
            onSetAttributes([
              {
                ...colorParsingConfig,
                attributeId: "color_name",
              },
              {
                ...genderParsingConfig,
                attributeId: "gender",
              },
              {
                ...ageParsingConfig,
                attributeId: "age",
              },
              ...optionalParsingConfigs,
            ]);
            setCurrentStep(CentraStep.SUCCESS);
          }}
        />
      );
    case CentraStep.SUCCESS:
      return (
        <CentraSuccess
          isLoading={isLoading}
          errorMessage={error}
          onComplete={() => {
            upsertCentraConfig(centraConfig as CompleteCentraConfig).then(
              onSuccess
            );
          }}
        />
      );
    default:
      return null;
  }
}

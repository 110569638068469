import {
  BasePortalProductDto,
  HiddenPortalProductDto,
  SortModel,
} from "src/api/types";
import { CategoryRankingOrderOptionType } from "../../storybook/Categories/CategoryRankingOrder/CategoryRankingOrderOption";

type Products = Record<string, BasePortalProductDto | HiddenPortalProductDto>;

export type ProductVisibilityState = {
  title: string | null;
  rankingType: CategoryRankingOrderOptionType | null;
  /*
   * The pinnedProducts state is an array of product ids.
   * This is used to keep track of the order of the pinned products.
   * The pinned products are the products that are pinned to the top of the category.
   */
  pinnedProducts: string[];
  /*
   * The productsByRelevance state is an array of product ids.
   * This is used to keep track of the order of the productsByRelevance.
   * The products by popularity are the products that are neither pinned nor hidden.
   */
  productsByRelevance: string[];
  /*
   * The hiddenProducts state is an array of product ids.
   * This is used to keep track of the order of the hidden products.
   * The hidden products are the products that are hidden.
   */
  hiddenProducts: string[];
};

export interface LocalProductManagement {
  addedProducts: string[];
  removedProducts: string[];
}

export interface State {
  isInitFromServer: boolean;
  /*
   * The products state is an object with two properties:
   * byId: a record of products, keyed by their id
   * allIds: an array of product ids
   */
  products: { byId: Products; allIds: Array<string> };

  /*
   * The productOrder state is an array of product ids.
   * This is used to keep track of the order of the products (as returned by the server).
   * The productOrder is used to determine the order of the products in the category.
   * It's also used to put the products in the order they were in before the user pinned or hid them.
   */
  relevanceProductOrder: string[];

  /* 
    The total number of products in a category, including pinned, hidden and relevant products (without considering pagination)
  */
  categoryTotalProductCount: number;

  /*
    The total product IDs in a category, including pinned, hidden and relevant products (without considering pagination)
  */
  categoryProductIds: string[];

  /*
   * The local state
   */
  local: ProductVisibilityState;

  /*
   * The server state (last time we got it from the server)
   */
  server: ProductVisibilityState;

  localProductManagement: LocalProductManagement;

  titleIsValid: boolean;

  productSortMethod: SortModel | undefined;

  isSaving: boolean;
}

export enum ActionType {
  INIT_FROM_SERVER = "INIT_FROM_SERVER",
  SET_NOT_CURATED_PRODUCTS = "SET_NOT_CURATED_PRODUCTS",
  IS_SAVING = "IS_SAVING",
  REVERT_CHANGES = "REVERT_CHANGES",
  PIN = "PIN",
  HIDE_PRODUCTS = "HIDE_PRODUCTS",
  AI_SORTED = "AI_SORTED",
  PIN_TO_BEGINNING = "PIN_TO_BEGINNING",
  REORDER_PINNED_PRODUCTS = "REORDER_PINNED_PRODUCTS",
  ADD_PRODUCTS = "ADD_PRODUCTS",
  REMOVE_PRODUCTS = "REMOVE_PRODUCTS",
  SET_TITLE = "SET_TITLE",
  SET_LOCAL_TITLE = "SET_LOCAL_TITLE",
  ADD_PRODUCTS_TO_DICT = "ADD_PRODUCTS_TO_DICT",
  SET_PRODUCT_SORT_METHOD = "SET_PRODUCT_SORT_METHOD",
  SET_RANKING_TYPE = "SET_RANKING_TYPE",
  SET_LOCAL_RANKING_TYPE = "SET_LOCAL_RANKING_TYPE",
}

export interface InitAction {
  type: ActionType.INIT_FROM_SERVER;
  payload: {
    curatedProducts: (BasePortalProductDto | HiddenPortalProductDto)[];
    pinnedProducts: string[];
    hiddenProducts: string[];
    totalProductCount: number;
    categoryProductIds: string[];
  };
}

export interface SetNotCuratedProductsAction {
  type: ActionType.SET_NOT_CURATED_PRODUCTS;
  payload: {
    notCuratedProducts: BasePortalProductDto[];
  };
}

export interface SetIsSavingAction {
  type: ActionType.IS_SAVING;
  payload: {
    isSaving: boolean;
  };
}

export interface RevertChangesAction {
  type: ActionType.REVERT_CHANGES;
  payload: {};
}

export interface PinAction {
  type: ActionType.PIN;
  payload: {
    productIds: string[];
  };
}

export interface HideAction {
  type: ActionType.HIDE_PRODUCTS;
  payload: {
    productIds: string[];
  };
}

export interface AISortedAction {
  type: ActionType.AI_SORTED;
  payload: {
    productIds: string[];
  };
}

export interface PinToBeginningAction {
  type: ActionType.PIN_TO_BEGINNING;
  payload: {
    productIds: string[];
  };
}

export interface ReorderPinnedProductAction {
  type: ActionType.REORDER_PINNED_PRODUCTS;
  payload: {
    newIndex: number;
    productIds: string[];
  };
}

export interface AddProductsAction {
  type: ActionType.ADD_PRODUCTS;
  payload: {
    productIds: string[];
  };
}

export interface RemoveProductsAction {
  type: ActionType.REMOVE_PRODUCTS;
  payload: {
    productIds: string[];
  };
}

export interface SetTitleAction {
  type: ActionType.SET_TITLE;
  payload: {
    title: string;
  };
}

export interface SetLocalTitleAction {
  type: ActionType.SET_LOCAL_TITLE;
  payload: {
    title: string;
    isValid: boolean;
  };
}

export interface AddProductsToDictAction {
  type: ActionType.ADD_PRODUCTS_TO_DICT;
  payload: {
    products: BasePortalProductDto[];
  };
}

export interface SetProductSortMethod {
  type: ActionType.SET_PRODUCT_SORT_METHOD;
  payload: {
    productSortMethod: SortModel;
  };
}

export interface SetRankingType {
  type: ActionType.SET_RANKING_TYPE;
  payload: {
    rankingType: CategoryRankingOrderOptionType;
  };
}

export interface SetLocalRankingType {
  type: ActionType.SET_LOCAL_RANKING_TYPE;
  payload: {
    rankingType: CategoryRankingOrderOptionType;
  };
}

export type Action =
  | InitAction
  | SetNotCuratedProductsAction
  | SetIsSavingAction
  | RevertChangesAction
  | PinAction
  | HideAction
  | AISortedAction
  | PinToBeginningAction
  | ReorderPinnedProductAction
  | AddProductsAction
  | RemoveProductsAction
  | SetTitleAction
  | SetLocalTitleAction
  | AddProductsToDictAction
  | SetProductSortMethod
  | SetRankingType
  | SetLocalRankingType;

import { useQuery } from "@tanstack/react-query";
import { CentraAvailableAttributes } from "src/api/types";
import useAuthorizedApi, {
  AuthorizedApi,
} from "src/helpers/hooks/app/useAuthorizedApi";
import { QueryId, getQueryKey } from "../../../queries/queries";
import CentraAttributesConfig from "../../storybook/Integrations/Centra/CentraAttributesConfig";
import {
  CentraMappedAttributeDefinition,
  CentraOptionalParsingConfig,
  CentraParsingConfig,
} from "../../storybook/Integrations/Centra/types";
import { getData } from "src/api/authorizedApi";

const query = async (
  api: AuthorizedApi | null,
  merchantId: string,
  apiUrl: string,
  apiKey: string
): Promise<CentraAvailableAttributes | undefined> => {
  if (api) {
    const reponse = await api.POST("/api/v0/integrations/centra/attributes", {
      body: {
        api_key: apiKey,
        api_url: apiUrl,
      },
    });

    const data = getData(reponse);

    return data;
  }
};

export default function CentraAttributesStep({
  apiUrl,
  apiKey,
  onBack,
  onSubmit,
  merchantId,
}: {
  apiUrl: string;
  apiKey: string;
  onBack: () => void;
  onSubmit: (
    colorParsingConfig: CentraParsingConfig,
    genderParsingConfig: CentraParsingConfig,
    ageParsingConfig: CentraParsingConfig,
    optionalParsingConfigs: CentraOptionalParsingConfig[]
  ) => void;
  merchantId: string;
}) {
  const { api } = useAuthorizedApi();
  const { data, isLoading, error } = useQuery({
    queryKey: getQueryKey(
      QueryId.GetAvailableCentraAttributes,
      merchantId,
      apiUrl,
      apiKey
    ),
    queryFn: async () => {
      return query(api, merchantId, apiUrl, apiKey);
    },
    enabled: !!apiUrl && !!apiKey && !!api,
  });

  const availableAttributes: CentraMappedAttributeDefinition[] = [];

  if (data) {
    for (const attributeType of Object.keys(data)) {
      availableAttributes.push({
        attributeType: attributeType,
        elementKeys: data.mapped_attributes[attributeType],
      });
    }
  }

  return (
    <CentraAttributesConfig
      availableAttributes={availableAttributes}
      availableOptionalAttributeIds={data?.optional_attributes ?? []}
      isLoading={isLoading}
      error={error?.message ?? null}
      onBack={onBack}
      onSubmit={(
        colorParsingConfig: CentraParsingConfig,
        genderParsingConfig: CentraParsingConfig,
        ageParsingConfig: CentraParsingConfig,
        optionalParsingConfigs: CentraOptionalParsingConfig[]
      ) => {
        onSubmit(
          colorParsingConfig,
          genderParsingConfig,
          ageParsingConfig,
          optionalParsingConfigs
        );
      }}
    />
  );
}

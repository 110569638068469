import { useState, useCallback } from "react";

export default function useParam(): [
  parameter: boolean,
  setCallback: (value: boolean) => void
] {
  const [parameter, setParameter] = useState<boolean>(false);

  const setCallback = useCallback((value: boolean) => {
    console.log(`[FILTERS] split by surfaces is ${value ? "true" : "false"}`);
    setParameter(value);
  }, []);
  return [parameter, setCallback];
}

import React, { createContext, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CollectionType } from "src/api/types";
import {
  GetCollectionConfig,
  useCategory,
} from "src/helpers/hooks/categories_v2_v3_common/useCategory/useCategory";
import useListingItems from "src/helpers/hooks/useListingItems";
import { ROUTES, buildPathWithMerchantId } from "src/router/Routes";
import useMerchant from "../../../helpers/hooks/app/useMerchant";
import { useAlerts } from "../../storybook/Alert/useAlerts";
import useCategoryId from "../Category/useCategoryId";
import { CategoryConfigModal, onSaveCB } from "./CategoryConfigModal";
import { CategoryConfig } from "./CategoryConfigModal.types";

export interface CategoryConfigModalProviderContextProps {
  openModal: () => void;
}

export const CategoryConfigModalContext =
  createContext<CategoryConfigModalProviderContextProps | null>(null);

export type SaveConfigCallback = (
  merchantId: string,
  categoryId: string,
  titles: Record<string, string> | null,
  queryId: string | null,
  config: CategoryConfig
) => Promise<void>;

export type DeleteCategoryCb = (
  merchantId: string,
  categoryId: string
) => Promise<void>;

export interface CategoryConfigModalProviderProps {
  children: React.ReactNode;
  saveConfigCB: SaveConfigCallback;
  deleteCategoryCb?: DeleteCategoryCb | undefined;
  canEditMetaData?: boolean;
  isAbleToDelete?: boolean;
  getCategory: GetCollectionConfig;
  collectionType: CollectionType | undefined;
}

const getCollectionDescription = (
  collectionType: CollectionType | undefined
): string => {
  switch (collectionType) {
    case "brand":
    case "campaign":
    case "category":
    case "style":
      return collectionType;
    case "long_tail_collection":
      return "long tail collection";
    case "smart_pick":
      return "smart pick";
  }

  return "collection";
};

export const CategoryConfigModalProvider = ({
  children,
  saveConfigCB,
  deleteCategoryCb,
  canEditMetaData,
  isAbleToDelete,
  getCategory,
  collectionType,
}: CategoryConfigModalProviderProps) => {
  const { merchant, merchantId } = useMerchant();
  const { categoryId } = useCategoryId();
  const navigate = useNavigate();
  const { refetch: refetchListingItems } = useListingItems();
  const { category: serverCategory, refetch: refetchCategory } = useCategory(
    merchant?.id || null,
    categoryId,
    getCategory
  );

  const { addAlert } = useAlerts();

  const [modalOpen, setModalOpen] = useState(false);

  const onSaveConfig = useCallback<onSaveCB>(
    async (titles, queryId, config) => {
      if (!merchant || !categoryId) {
        throw new Error("merchant or categoryId is not defined");
      }

      try {
        await saveConfigCB(merchant.id, categoryId, titles, queryId, config);
      } catch (e) {
        addAlert({
          message: "Error saving category settings",
          type: "danger",
          autohide: true,
          id: "category-config-error",
        });
      }

      refetchCategory();
      refetchListingItems();

      addAlert({
        message: "Category settings saved",
        type: "success",
        autohide: true,
        id: "category-config-saved",
      });
    },
    [
      addAlert,
      categoryId,
      merchant,
      saveConfigCB,
      refetchCategory,
      refetchListingItems,
    ]
  );

  const getPath = useCallback(
    (path: ROUTES) => {
      if (!merchantId) return path;
      return buildPathWithMerchantId(path, merchantId);
    },
    [merchantId]
  );

  const onDeleteConfig = useCallback(async () => {
    if (!merchant || !categoryId) {
      throw new Error("merchant or categoryId is not defined");
    }

    await deleteCategoryCb?.(merchant.id, categoryId);
    refetchListingItems();

    navigate(getPath(ROUTES.DASHBOARD));

    addAlert({
      message: "Category deleted",
      type: "success",
      autohide: true,
      id: "category-deleted",
    });
  }, [
    merchant,
    categoryId,
    deleteCategoryCb,
    refetchListingItems,
    navigate,
    getPath,
    addAlert,
  ]);

  return (
    <CategoryConfigModalContext.Provider
      value={{
        openModal: () => setModalOpen(true),
      }}
    >
      {children}
      <CategoryConfigModal
        show={modalOpen}
        canEditMetaData={canEditMetaData}
        isAbleToDelete={isAbleToDelete}
        isAbleToChangeVisibility
        serverCategory={serverCategory}
        collectionDescription={getCollectionDescription(collectionType)}
        onSave={onSaveConfig}
        onDelete={onDeleteConfig}
        onClose={() => setModalOpen(false)}
        defaultLocale={merchant?.default_locale || ""}
      />
    </CategoryConfigModalContext.Provider>
  );
};

import React, { HTMLAttributes } from "react";
import { Badge as BootstrapBadge } from "react-bootstrap";
import { EyeClosed, Icon } from "@phosphor-icons/react";

export interface BadgeProps {
  text: string;
  variant?:
    | "custom"
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark";

  style?: React.CSSProperties;
  icon?: Icon;
  rightIcon?: React.ReactNode;
}

export const Badge = (props: BadgeProps) => {
  const style: React.CSSProperties = {
    ...props.style,
    display: "flex",
    alignItems: "center",
  };

  return (
    <BootstrapBadge style={style} bg={props.variant || "primary"} pill>
      {props.icon && <props.icon style={{ marginRight: "4px" }} />}
      {props.text}
      {props.rightIcon}
    </BootstrapBadge>
  );
};

import { ProductMetadata } from "./ProductMetadataProvider";
import { boolean } from "zod";

export const metadataTypeBadgePositionMap = {
  on_sale: "bottom",
  low_stock: "bottom",
  out_of_stock: "bottom",
  boosted: "top",
  buried: "top",
  new_in: "top",
  bestseller: "bottom",
  trending: "bottom",
  slow_mover: "bottom",
  inactive: "top",
};

export const getLabelFromProductMetadata = (
  metadata: ProductMetadata
): string => {
  switch (metadata.type) {
    case "on_sale":
      if (metadata.max_percentage && metadata.min_percentage) {
        const percentToShow =
          metadata.max_percentage === metadata.min_percentage
            ? metadata.max_percentage
            : `${metadata.min_percentage}-${metadata.max_percentage}`;
        return `Sale ${percentToShow}%`;
      }
      return "On sale";
    case "low_stock":
      return "Low stock";
    case "out_of_stock":
      return "Out of stock";
    case "new_in":
      return "New in";
    case "bestseller":
      return "Bestseller";
    case "trending":
      return "Trending";
    case "slow_mover":
      return "Slow mover";
    case "inactive":
      return "Inactive";
    default:
      return "Unknown";
  }
};

export const getDetailFromProductMetadata = (
  metadata: ProductMetadata,
  oos_auto_hide?: boolean
): string | null => {
  switch (metadata.type) {
    case "on_sale":
      return null;
    case "low_stock":
      if (metadata.size_labels_in_stock) {
        return `Only ${metadata.size_labels_in_stock.join(", ")} left`;
      }
      return null;
    case "out_of_stock":
      if (oos_auto_hide)
        return "Out of stock products are hidden automatically";
      else return "All sizes are out of stock";
    case "new_in":
      if (metadata.created_at) {
        return `Added ${new Date(metadata.created_at).toLocaleDateString()}`;
      }
      return null;
    case "bestseller":
      if (metadata.meta_data !== null && metadata.n_days !== null) {
        let metaText;
        if (metadata.meta_data === 1) {
          metaText = `Sold ${metadata.meta_data} time in the last ${metadata.n_days} days`;
        } else {
          metaText = `Sold ${metadata.meta_data} times in the last ${metadata.n_days} days`;
        }
        return metaText;
      }
      return null;
    case "trending":
      if (metadata.meta_data && metadata.n_days) {
        return `Climbed ${metadata.meta_data} ranks in sales in the last ${metadata.n_days} days`;
      }
      return null;
    case "slow_mover":
      if (metadata.meta_data !== null && metadata.n_days !== null) {
        let metaText;
        if (metadata.meta_data === 1) {
          metaText = `Only sold ${metadata.meta_data} time in the last ${metadata.n_days} days`;
        } else {
          metaText = `Only sold ${metadata.meta_data} times in the last ${metadata.n_days} days`;
        }
        return metaText;
      }
      return null;
    case "inactive":
      return "This product will not be shown to customers";
    default:
      return null;
  }
};

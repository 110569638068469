 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const GroupWork = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M13 6.467c-0.004-0.166-0.038-0.909-0.351-1.659-0.214-0.514-0.514-0.928-0.891-1.229-0.096-0.077-0.198-0.146-0.303-0.208 0.338-0.358 0.545-0.84 0.545-1.37 0-1.103-0.897-2-2-2s-2 0.897-2 2c0 0.53 0.208 1.012 0.545 1.37-0.105 0.062-0.206 0.131-0.303 0.208-0.377 0.302-0.677 0.716-0.891 1.229-0.347 0.833-0.351 1.658-0.351 1.692 0 0.276 0.224 0.5 0.5 0.5h5c0 0 0 0 0.001 0 0.276 0 0.5-0.224 0.5-0.5 0-0.011-0-0.022-0.001-0.033zM9 2c0-0.551 0.449-1 1-1s1 0.449 1 1-0.449 1-1 1c-0.551 0-1-0.449-1-1zM8.050 6c0.038-0.234 0.106-0.523 0.224-0.808 0.334-0.802 0.899-1.192 1.726-1.192 1.429 0 1.837 1.268 1.953 2h-3.904z" fill={color}></path>
<path d="M6 17.467c-0.004-0.166-0.038-0.909-0.351-1.659-0.214-0.514-0.514-0.927-0.891-1.229-0.096-0.077-0.198-0.146-0.303-0.208 0.338-0.358 0.545-0.84 0.545-1.37 0-1.103-0.897-2-2-2s-2 0.897-2 2c0 0.53 0.208 1.012 0.545 1.37-0.105 0.062-0.206 0.131-0.303 0.208-0.377 0.302-0.677 0.716-0.891 1.229-0.347 0.833-0.351 1.658-0.351 1.692 0 0.276 0.224 0.5 0.5 0.5h5c0-0 0-0 0.001 0 0.276 0 0.5-0.224 0.5-0.5 0-0.011-0-0.022-0.001-0.033zM2 13c0-0.551 0.449-1 1-1s1 0.449 1 1-0.449 1-1 1-1-0.449-1-1zM1.050 17c0.038-0.234 0.106-0.523 0.224-0.808 0.334-0.802 0.899-1.192 1.726-1.192 1.429 0 1.837 1.268 1.953 2h-3.904z" fill={color}></path>
<path d="M20 17.467c-0.004-0.166-0.038-0.909-0.351-1.659-0.214-0.514-0.514-0.927-0.891-1.229-0.096-0.077-0.198-0.146-0.303-0.208 0.338-0.358 0.545-0.84 0.545-1.37 0-1.103-0.897-2-2-2s-2 0.897-2 2c0 0.53 0.208 1.012 0.545 1.37-0.105 0.062-0.206 0.131-0.303 0.208-0.377 0.302-0.677 0.716-0.891 1.229-0.347 0.833-0.351 1.658-0.351 1.692 0 0.276 0.224 0.5 0.5 0.5h5c0 0 0 0 0.001 0 0.276 0 0.5-0.224 0.5-0.5 0-0.011-0-0.022-0.001-0.033zM16 13c0-0.551 0.449-1 1-1s1 0.449 1 1-0.449 1-1 1-1-0.449-1-1zM15.050 17c0.038-0.234 0.106-0.523 0.224-0.808 0.334-0.802 0.899-1.192 1.726-1.192 1.429 0 1.837 1.268 1.953 2h-3.904z" fill={color}></path>
<path d="M10 20c-1.3 0-2.591-0.319-3.734-0.923-0.244-0.129-0.337-0.432-0.208-0.676s0.432-0.337 0.676-0.208c0.999 0.528 2.128 0.807 3.266 0.807s2.267-0.279 3.266-0.807c0.244-0.129 0.547-0.036 0.676 0.208s0.036 0.547-0.208 0.676c-1.142 0.604-2.433 0.923-3.734 0.923z" fill={color}></path>
<path d="M17.23 10.5c-0.219 0-0.421-0.145-0.482-0.367-0.464-1.68-1.535-3.12-3.015-4.056-0.233-0.148-0.303-0.456-0.156-0.69s0.456-0.303 0.69-0.156c1.692 1.069 2.915 2.715 3.445 4.635 0.073 0.266-0.083 0.542-0.349 0.615-0.044 0.012-0.089 0.018-0.133 0.018z" fill={color}></path>
<path d="M2.77 10.5c-0.044 0-0.089-0.006-0.133-0.018-0.266-0.073-0.422-0.349-0.349-0.615 0.53-1.92 1.754-3.566 3.445-4.635 0.233-0.147 0.542-0.078 0.69 0.156s0.078 0.542-0.156 0.69c-1.481 0.935-2.552 2.376-3.015 4.056-0.061 0.222-0.262 0.367-0.482 0.367z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(GroupWork); 
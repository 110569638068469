 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Tape = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M8 7c-1.001 0-1.95-0.17-2.672-0.48-1.156-0.495-1.328-1.165-1.328-1.52s0.173-1.025 1.328-1.52c0.722-0.309 1.671-0.48 2.672-0.48s1.95 0.17 2.672 0.48c1.156 0.495 1.328 1.165 1.328 1.52s-0.173 1.025-1.328 1.52c-0.722 0.309-1.671 0.48-2.672 0.48zM8 4c-1.861 0-3 0.647-3 1s1.139 1 3 1 3-0.647 3-1-1.139-1-3-1z" fill={color}></path>
<path d="M19.859 8.253c-0.067-0.070-1.59-1.625-3.933-2.561 0.049-0.227 0.074-0.457 0.074-0.691 0-1.385-0.866-2.667-2.439-3.611-1.493-0.896-3.468-1.389-5.561-1.389s-4.067 0.493-5.561 1.389c-1.573 0.944-2.439 2.226-2.439 3.611s0.866 2.667 2.439 3.611c0.18 0.108 0.367 0.21 0.561 0.306v2.167c-0.847 0.421-1.557 0.952-2.064 1.548-0.612 0.719-0.936 1.538-0.936 2.368 0 1.385 0.866 2.667 2.439 3.611 1.493 0.896 3.468 1.389 5.561 1.389 1.896 0 3.731-0.415 5.168-1.169 1.199-0.629 2.065-1.463 2.504-2.397 0.357 0.272 0.726 0.581 1.107 0.926 1.332 1.209 2.321 2.439 2.331 2.451 0.097 0.121 0.242 0.188 0.391 0.188 0.055 0 0.111-0.009 0.165-0.028 0.2-0.070 0.334-0.259 0.334-0.472v-10.9c0-0.13-0.050-0.254-0.141-0.348zM1 5c0-1.019 0.694-1.997 1.954-2.753 1.34-0.804 3.132-1.247 5.046-1.247s3.706 0.443 5.046 1.247c1.26 0.756 1.954 1.734 1.954 2.753 0 0.28-0.053 0.557-0.155 0.826-0.001 0.002-0.001 0.003-0.002 0.005s-0.001 0.002-0.001 0.003c-0.271 0.71-0.886 1.372-1.796 1.919-0.232 0.139-0.478 0.267-0.735 0.384-0.009 0.004-0.018 0.008-0.027 0.012-1.224 0.552-2.713 0.85-4.285 0.85-1.57 0-3.057-0.298-4.281-0.849-0.010-0.005-0.021-0.010-0.032-0.015-0.257-0.117-0.502-0.245-0.734-0.384-1.26-0.756-1.954-1.734-1.954-2.753zM8 10c1.427 0 2.798-0.229 4-0.659v5.159c0 0.311-0.391 0.677-1.021 0.957-0.787 0.35-1.845 0.543-2.979 0.543s-2.192-0.193-2.979-0.543c-0.63-0.28-1.021-0.647-1.021-0.957v-5.159c1.202 0.43 2.573 0.659 4 0.659zM12.703 17.946c-1.296 0.68-2.966 1.054-4.703 1.054-1.914 0-3.706-0.443-5.046-1.247-1.26-0.756-1.954-1.734-1.954-2.753 0-1.027 0.735-2.032 2-2.78v2.28c0 0.744 0.574 1.408 1.615 1.871 0.912 0.406 2.115 0.629 3.385 0.629s2.473-0.223 3.385-0.629c0.785-0.349 1.304-0.812 1.512-1.338 0.568 0.090 1.22 0.366 1.938 0.818-0.311 0.795-1.055 1.529-2.132 2.095zM19 18.164c-0.409-0.444-0.937-0.987-1.539-1.534-1.692-1.539-3.19-2.408-4.461-2.592v-5.121c0.193-0.096 0.381-0.198 0.561-0.306 0.931-0.558 1.614-1.235 2.017-1.982 1.744 0.704 3.014 1.802 3.423 2.183v9.352z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Tape); 
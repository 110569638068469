import { useEffect, useState } from "react";
import { deepCopy } from "src/helpers";
import { Configuration } from "src/types/configuration";

type UseOnViewConfiguration = [onViewConfiguration: Configuration | null];

export default function useOnViewConfiguration(
  marketConfigurations: Configuration[] | null,
  currentConfigurationId: number | null
): UseOnViewConfiguration {
  const [onViewConfiguration, setOnViewConfiguration] =
    useState<Configuration | null>(null);

  useEffect(() => {
    if (!currentConfigurationId || !marketConfigurations) {
      setOnViewConfiguration(null);
      return;
    }

    const found = marketConfigurations?.find(
      (conf) => conf.id === currentConfigurationId
    );

    if (!found) {
      setOnViewConfiguration(null);
      return;
    }

    setOnViewConfiguration(deepCopy(found));

    return () => {
      setOnViewConfiguration(null);
    };
  }, [marketConfigurations, currentConfigurationId]);

  return [onViewConfiguration];
}

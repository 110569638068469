import { ReactElement } from "react";

interface BodyProps {
  backgroundColor: string;
  icon?: JSX.Element | ReactElement<any, any>;
  titleColor: string;
  title: string;
  message: string | JSX.Element;
  messageColor: string;
}
export const Body = (props: BodyProps) => {
  return (
    <div
      style={{
        borderRadius: "0.5rem",
        backgroundColor: props.backgroundColor,
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        padding: "1rem",
        gap: "0.25rem",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "0.5rem",
        }}
      >
        {props.icon && (
          <div
            style={{
              width: "1.5rem",
              height: "1.5rem",
            }}
          >
            {props.icon}
          </div>
        )}
        <div
          style={{
            fontSize: "1.25rem",
            fontWeight: 700,
            lineHeight: "1.25rem",
            color: props.titleColor,
          }}
        >
          {props.title}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          padding: "0 0 0 2rem",
          color: props.messageColor,
        }}
      >
        {props.message}
      </div>
    </div>
  );
};

export default Body;

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Alarm2 = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M15.129 7.25c-0.138-0.239-0.444-0.321-0.683-0.183l-4.92 2.841-3.835-2.685c-0.226-0.158-0.538-0.103-0.696 0.123s-0.103 0.538 0.123 0.696l4.096 2.868c0.001 0.001 0.002 0.001 0.003 0.002 0.008 0.006 0.017 0.011 0.026 0.016 0.002 0.001 0.005 0.003 0.007 0.004 0.009 0.005 0.018 0.010 0.027 0.014 0.002 0.001 0.004 0.002 0.006 0.003 0.010 0.005 0.020 0.010 0.031 0.014 0.006 0.002 0.012 0.005 0.019 0.007 0.005 0.002 0.009 0.003 0.014 0.005 0.007 0.002 0.013 0.004 0.020 0.006 0.004 0.001 0.009 0.002 0.013 0.003 0.007 0.002 0.014 0.003 0.020 0.005 0.005 0.001 0.009 0.002 0.014 0.003 0.006 0.001 0.013 0.002 0.019 0.003s0.012 0.001 0.018 0.002c0.005 0.001 0.011 0.001 0.016 0.002 0.012 0.001 0.023 0.001 0.035 0.001 0.019 0 0.038-0.001 0.057-0.003 0-0 0.001-0 0.001-0 0.018-0.002 0.037-0.006 0.055-0.010 0.001-0 0.003-0.001 0.004-0.001 0.017-0.004 0.034-0.009 0.051-0.016 0.003-0.001 0.005-0.002 0.007-0.003 0.016-0.006 0.032-0.013 0.047-0.021 0.003-0.002 0.006-0.003 0.009-0.005 0.006-0.003 0.011-0.006 0.017-0.009l5.196-3c0.239-0.138 0.321-0.444 0.183-0.683z" fill={color}></path>
<path d="M16.354 17.146c-0.011-0.011-0.023-0.022-0.035-0.032 1.73-1.782 2.682-4.125 2.682-6.614 0-2.538-0.988-4.923-2.782-6.718s-4.18-2.782-6.718-2.782-4.923 0.988-6.717 2.782-2.782 4.18-2.782 6.718c0 2.489 0.952 4.832 2.682 6.614-0.012 0.010-0.024 0.021-0.035 0.032l-2 2c-0.195 0.195-0.195 0.512 0 0.707 0.098 0.098 0.226 0.147 0.354 0.147s0.256-0.049 0.354-0.147l2-2c0.020-0.020 0.038-0.042 0.054-0.064 1.706 1.431 3.839 2.211 6.093 2.211s4.387-0.78 6.093-2.211c0.016 0.023 0.034 0.044 0.054 0.064l2 2c0.098 0.098 0.226 0.146 0.353 0.146s0.256-0.049 0.354-0.147c0.195-0.195 0.195-0.512 0-0.707l-2-2zM1 10.5c0-4.687 3.813-8.5 8.5-8.5s8.5 3.813 8.5 8.5c0 4.687-3.813 8.5-8.5 8.5s-8.5-3.813-8.5-8.5z" fill={color}></path>
<path d="M0.56 4.605c-0.233 0-0.441-0.163-0.489-0.4-0.047-0.231-0.071-0.468-0.071-0.705 0-1.93 1.57-3.5 3.5-3.5 0.414 0 0.82 0.072 1.205 0.213 0.259 0.095 0.392 0.382 0.297 0.642s-0.382 0.392-0.642 0.297c-0.275-0.101-0.565-0.152-0.861-0.152-1.378 0-2.5 1.122-2.5 2.5 0 0.17 0.017 0.34 0.051 0.504 0.055 0.271-0.119 0.535-0.39 0.59-0.034 0.007-0.068 0.010-0.101 0.010z" fill={color}></path>
<path d="M18.44 4.605c-0.033 0-0.067-0.003-0.101-0.010-0.271-0.055-0.445-0.32-0.39-0.59 0.034-0.165 0.051-0.334 0.051-0.504 0-1.379-1.122-2.5-2.5-2.5-0.296 0-0.586 0.051-0.861 0.152-0.259 0.095-0.547-0.038-0.642-0.297s0.038-0.547 0.297-0.642c0.386-0.142 0.791-0.213 1.206-0.213 1.93 0 3.5 1.57 3.5 3.5 0 0.237-0.024 0.474-0.071 0.705-0.048 0.237-0.257 0.4-0.489 0.4z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Alarm2); 
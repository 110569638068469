 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Rocket = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M13.5 9c-1.378 0-2.5-1.122-2.5-2.5s1.122-2.5 2.5-2.5 2.5 1.122 2.5 2.5-1.122 2.5-2.5 2.5zM13.5 5c-0.827 0-1.5 0.673-1.5 1.5s0.673 1.5 1.5 1.5 1.5-0.673 1.5-1.5-0.673-1.5-1.5-1.5z" fill={color}></path>
<path d="M0.5 20c-0.13 0-0.258-0.051-0.354-0.146-0.137-0.137-0.183-0.342-0.116-0.524 1.539-4.231 3.157-6.377 4.808-6.377 0.545 0 1.054 0.234 1.515 0.694 0.728 0.728 0.754 1.453 0.648 1.933-0.334 1.508-2.464 2.985-6.33 4.391-0.056 0.020-0.114 0.030-0.171 0.030zM4.839 13.952c-0.495 0-1.078 0.434-1.685 1.255-0.597 0.807-1.197 1.957-1.788 3.427 1.335-0.537 2.408-1.082 3.199-1.625 1.067-0.733 1.383-1.3 1.46-1.646s-0.047-0.677-0.379-1.009c-0.27-0.27-0.534-0.401-0.808-0.401z" fill={color}></path>
<path d="M19.5 0c-2.595 0-4.907 0.44-6.874 1.308-1.621 0.716-3.009 1.72-4.126 2.986-0.208 0.236-0.401 0.475-0.58 0.715-0.862 0.057-1.744 0.4-2.624 1.020-0.745 0.524-1.493 1.25-2.225 2.157-1.23 1.524-1.986 3.027-2.018 3.090-0.109 0.219-0.044 0.485 0.154 0.628 0.088 0.064 0.191 0.095 0.293 0.095 0.128 0 0.256-0.049 0.353-0.145 0.005-0.005 0.483-0.475 1.298-0.936 0.672-0.38 1.708-0.827 2.961-0.906 0.317 0.811 1.224 1.781 1.659 2.216s1.405 1.342 2.216 1.659c-0.079 1.253-0.526 2.289-0.906 2.961-0.462 0.816-0.931 1.293-0.935 1.297-0.173 0.173-0.195 0.447-0.052 0.646 0.097 0.134 0.249 0.208 0.406 0.208 0.076 0 0.152-0.017 0.223-0.053 0.063-0.032 1.566-0.788 3.090-2.018 0.907-0.732 1.633-1.48 2.157-2.225 0.62-0.881 0.962-1.762 1.020-2.624 0.239-0.178 0.478-0.371 0.715-0.58 1.266-1.117 2.27-2.505 2.986-4.126 0.868-1.966 1.308-4.279 1.308-6.874v-0.5h-0.5zM3.107 9.808c0.953-1.383 2.41-3.098 4.052-3.637-0.649 1.137-0.985 2.181-1.106 2.843-1.159 0.071-2.158 0.408-2.946 0.794zM10.193 16.89c0.386-0.788 0.723-1.786 0.793-2.944 0.661-0.121 1.706-0.457 2.842-1.105-0.54 1.639-2.254 3.096-3.636 4.049zM15.044 10.75c-2.080 1.835-4.234 2.25-4.544 2.25-0.002 0-0.207-0.004-0.759-0.393-0.385-0.271-0.834-0.657-1.262-1.085s-0.814-0.877-1.085-1.262c-0.389-0.552-0.393-0.757-0.393-0.759 0-0.31 0.415-2.464 2.25-4.544 2.196-2.488 5.557-3.849 9.744-3.95-0.101 4.187-1.461 7.548-3.95 9.744z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Rocket); 
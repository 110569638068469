 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Bug = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M17.5 12h-1.515c-0.062-1.099-0.33-2.153-0.781-3.102 1.036-0.305 1.795-1.264 1.795-2.398 0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5c0 0.761-0.57 1.392-1.306 1.487-0.174-0.266-0.365-0.521-0.571-0.762-0.108-0.126-0.219-0.246-0.333-0.362 0.14-0.439 0.21-0.896 0.21-1.363 0-2.481-2.019-4.5-4.5-4.5s-4.5 2.019-4.5 4.5c0 0.467 0.071 0.924 0.21 1.363-0.114 0.116-0.225 0.236-0.333 0.362-0.207 0.241-0.397 0.496-0.571 0.762-0.736-0.095-1.306-0.726-1.306-1.487 0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5c0 1.134 0.759 2.093 1.795 2.398-0.451 0.949-0.718 2.003-0.781 3.102h-1.514c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h1.515c0.062 1.099 0.33 2.153 0.781 3.102-1.036 0.305-1.795 1.264-1.795 2.398 0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5c0-0.761 0.57-1.392 1.306-1.487 0.174 0.266 0.365 0.521 0.571 0.762 1.23 1.435 2.871 2.225 4.622 2.225s3.393-0.79 4.622-2.225c0.207-0.241 0.397-0.496 0.571-0.762 0.736 0.095 1.306 0.726 1.306 1.487 0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5c0-1.134-0.759-2.093-1.795-2.398 0.451-0.949 0.718-2.003 0.781-3.102h1.514c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5zM9.5 2c1.93 0 3.5 1.57 3.5 3.5 0 0.215-0.019 0.426-0.057 0.634-1.022-0.738-2.205-1.134-3.443-1.134s-2.421 0.395-3.443 1.134c-0.038-0.208-0.057-0.419-0.057-0.634 0-1.93 1.57-3.5 3.5-3.5zM4 12.5c0-3.385 2.201-6.173 5-6.473v12.946c-2.799-0.3-5-3.088-5-6.473zM10 18.973v-12.946c2.799 0.3 5 3.088 5 6.473s-2.201 6.173-5 6.473z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Bug); 
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { MerchantSynonym } from "../../../api/types";
import { useAlerts } from "../../../components/storybook/Alert/useAlerts";
import { QueryId, getQueryKey } from "../../../queries/queries";
import useAuthorizedApi from "../app/useAuthorizedApi";
import { getData } from "src/api/authorizedApi";

export const useSynonyms = (merchantId: string, localeId: string) => {
  const { api } = useAuthorizedApi();
  const queryClient = useQueryClient();
  const { addAlert } = useAlerts();

  const { data: synonyms, isLoading: synonymsIsLoading } = useQuery({
    queryKey: getQueryKey(
      QueryId.GetSynonyms,
      merchantId || "",
      localeId || ""
    ),
    queryFn: async () => {
      if (api) {
        const response = await api.GET(`/api/v0/synonyms/{merchant_id}`, {
          params: {
            path: {
              merchant_id: merchantId,
            },
            query: {
              locale_id: localeId,
            },
          },
        });

        return getData(response);
      }
    },
    enabled: !!api && !!merchantId && !!localeId,
  });

  const { mutate: updateSynonym } = useMutation({
    mutationFn: async (synonym: MerchantSynonym) => {
      if (api) {
        const response = await api.POST(`/api/v0/synonyms/{merchant_id}`, {
          body: {
            id: synonym.id,
            locale_id: synonym.locale_id,
            synonyms: synonym.synonyms,
          },
          params: {
            path: {
              merchant_id: merchantId,
            },
          },
        });

        return getData(response);
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: getQueryKey(
          QueryId.GetSynonyms,
          merchantId || "",
          localeId || ""
        ),
      });
      addAlert({
        id: "synonym-updated",
        message: "It may take up to 30 minutes to take effect.",
        type: "success",
        autohide: true,
      });
    },
    onError: () => {
      addAlert({
        id: "synonym-updated",
        message: "Failed to save synonym",
        type: "danger",
        autohide: true,
      });
    },
  });

  const { mutate: deleteSynonym } = useMutation({
    mutationFn: async (synonymId: number) => {
      if (api) {
        const response = await api.DELETE(
          `/api/v0/synonyms/{merchant_id}/{synonym_id}`,
          {
            params: {
              path: {
                merchant_id: merchantId,
                synonym_id: synonymId,
              },
            },
          }
        );

        return getData(response);
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: getQueryKey(
          QueryId.GetSynonyms,
          merchantId || "",
          localeId || ""
        ),
      });
      addAlert({
        id: "synonym-deleted",
        message: "It may take up to 30 minutes to take effect.",
        type: "success",
        autohide: true,
      });
    },
    onError: () => {
      addAlert({
        id: "synonym-deleted",
        message: "Failed to delete synonym",
        type: "danger",
      });
    },
  });

  return {
    synonyms,
    synonymsIsLoading,
    updateSynonym,
    deleteSynonym,
  };
};

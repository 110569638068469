/**
 * This class was originally imported from "BROWSER-TAGS/lib/utilities/element_observer".
 * It has been removed in order to facilitate deployments in docker environments that do not play well with scripts imported from outside the project root.
 */

import deduplicator from "./observerEventDeduplicator";

export type Disconnector = () => unknown;

type EO_listener = <ElemType extends HTMLElement = HTMLElement>(
  selector: string,
  callback: (event: ElementObserverEvent<ElemType>) => unknown
) => Disconnector;

export interface ElementObserver {
  onexists: EO_listener;
  oncreation: EO_listener;
  onremoved: EO_listener;
  onchange: EO_listener;
  ifexists: EO_listener;
  wait_for_element: <ElemType extends HTMLElement = HTMLElement>(
    selector: string,
    timeout?: number
  ) => Promise<ElemType>;
  wait_for_listener: <ElemType extends HTMLElement = HTMLElement>(
    listener: string,
    selector: string,
    timeout?: number
  ) => Promise<ElemType>;
}

export class ElementObserver {
  #the_mutation_observer: MutationObserver;
  #observing = {};
  #self_proxied: ElementObserver;
  #add_listener_hooks: typeof deduplicator[]; // for extending / changing functionality
  #attribute_attribution_map_object: {
    [key: string]: WeakSet<Element>;
  } = {};
  #territory: HTMLElement | Document | DocumentFragment = document;
  #listeners = ["exists", "creation", "removed", "change"] as const;
  #verbs = {
    // add "normal" functions for the class that can be used as i.e. observer.ifexists() here
    ifexists: (selector: string, callback: Function) => {
      this.#check_observer_args(selector, callback);
      const our_set = (this.#attribute_attribution_map_object[selector] ||=
        new WeakSet());

      const elements = this.#territory.querySelectorAll<HTMLElement>(selector);
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        our_set.add(element);
        callback(
          new ElementObserverEvent({
            element,
            selector,
            disconnector: this.#disconnect_fn({
              event: "exists",
              selector,
              callback,
            }),
          })
        );
      }
    },
    wait_for_element: (selector: string, timeout: number) => {
      return this.#self_proxied.wait_for_listener("exists", selector, timeout);
    },
    wait_for_listener: (
      listener: string,
      selector: string,
      timeout: number
    ) => {
      this.#check_observer_args(listener, () => {});
      // console.log("waiting for listener", listener, "selector", selector, "timeout", timeout);
      return new Promise((r) => {
        //@ts-ignore
        const stop_observing = this.#self_proxied["on" + listener](
          selector,
          ({ element, disconnector }: { element: any; disconnector: any }) => {
            r(element);
            disconnector();
            if (timeout) {
              clearTimeout(obs_timeout);
            }
          }
        );
        if (timeout) {
          var obs_timeout = setTimeout(() => {
            stop_observing();
            r(undefined);
          }, timeout);
        }
      });
    },
  };
  #kickstart_observer() {
    this.#the_mutation_observer = new MutationObserver(this.#observer_callback);
    this.#the_mutation_observer.observe(this.#territory, {
      attributes: true,
      childList: true,
      subtree: true,
      attributeOldValue: true,
      characterData: true,
      characterDataOldValue: true,
    });
  }
  #disconnect_fn({
    event,
    selector,
    callback,
  }: {
    event: any;
    selector: any;
    callback: any;
  }) {
    return () => {
      const observing: any = this.#observing;
      let callbacks = observing[event];
      if (!callbacks || !(callbacks = callbacks[selector])) {
        // console.info("disconnect_fn ran but callback has already been removed or was called by someone who called ifexists");
        return;
      }
      const index_of_callback = callbacks.indexOf(callback);
      if (index_of_callback < 0) {
        // console.info("disconnector was called twice, not doing anything");
        return;
      }
      callbacks.splice(index_of_callback, 1); // remove callback from callbacks for selector
      if (!callbacks.length) {
        delete observing[event][selector];
      }
      if (!Object.keys(observing[event]).length) {
        delete observing[event];
      }
      if (!Object.keys(observing).length) {
        this.#the_mutation_observer.disconnect();
        //@ts-ignore
        this.#the_mutation_observer = undefined;
      }
    };
  }
  #clone_deep(obj: { [key: string]: any }) {
    //@ts-ignore
    const new_obj = Object.assign(new obj.constructor(), obj);
    const stack = [new_obj];
    while (stack.length) {
      const o = stack.pop();
      for (const key in o) {
        if (typeof o[key] === "object") {
          o[key] = Object.assign(new o[key].constructor(), o[key]);
          stack.push(o[key]);
        }
      }
    }
    return new_obj;
  }
  #observer_callback = (records: MutationRecord[]) => {
    // this is the function which needs to be as fast as possible because it is called on EVERY mutation in the DOM
    try {
      const observing = this.#clone_deep({ ...this.#observing }); // clone observing to not have messed up things happening if a callback changes observing
      const attribute_attribution_map_object =
        this.#attribute_attribution_map_object;

      for (
        let j = 0, nodes_to_check: Node[] | NodeList;
        j < records.length;
        j++
      ) {
        const record = records[j],
          events_for_discovery = [];

        // code for handling a record containing wanted elements in addedNodes or the children of added nodes and attribute changes that make an existing node match a selector, mainly for oncreation and onexists but it will fire onchange if someone is listening if an attribute change makes an element match a selector
        if (!record.addedNodes.length && !record.removedNodes.length) {
          nodes_to_check = [record.target];
        } else {
          nodes_to_check = record.addedNodes;
          if (observing["creation"]) {
            events_for_discovery.push("creation");
          }
        }
        if (observing["exists"]) {
          events_for_discovery.push("exists");
        }
        if (observing["change"]) {
          events_for_discovery.push("change");
        }
        for (
          let n = 0, event: "change" | "exists", selectors: string[];
          n < events_for_discovery.length;
          n++
        ) {
          event = events_for_discovery[n] as "exists" | "change";
          selectors = Object.keys(observing[event]);
          if (
            event === "change" &&
            (record.addedNodes.length || record.removedNodes.length)
          ) {
            // we check the selectors first because I'm DUMB and already have come so far
            for (let tempsel: string, r = 0; r < selectors.length; r++) {
              tempsel = selectors[r];
              //@ts-ignore
              if (record.target?.matches?.(tempsel)) {
                // we WANT change event and there are children to an element that interests us - act on it
                for (let s = 0; s < observing[event][tempsel].length; s++) {
                  const callback = observing[event][tempsel][s];
                  callback(
                    new ElementObserverEvent({
                      //@ts-ignore
                      element: record.target,
                      selector: tempsel,
                      event,
                      disconnector: this.#disconnect_fn({
                        event,
                        selector: tempsel,
                        callback,
                      }),
                      mutation_record: record,
                    })
                  );
                }
              }
            }
            break;
          }
          for (let i = 0, node; i < nodes_to_check.length; i++) {
            node = nodes_to_check[i];
            for (let l = 0, selector: string; l < selectors.length; l++) {
              selector = selectors[l];
              //@ts-ignore
              const matches_now = node?.matches?.(selector);

              let our_set: WeakSet<Node>;
              if (event === "exists") {
                // we want the change event to trigger to any change (exists only if it makes an element match a selector that didn't previously match)
                our_set = attribute_attribution_map_object[selector] ||=
                  new WeakSet<Node>();

                if (record.type === "attributes" && matches_now != null) {
                  const matched_last = our_set.has(node);

                  if (matched_last && matches_now) {
                    // do not trigger on unrelated attribute changes, this is the reason for the set
                    continue;
                  }
                }

                if (matches_now) {
                  our_set.add(node);
                } else {
                  our_set.delete(node);
                }
              }

              if (matches_now) {
                for (let k = 0; k < observing[event][selector].length; k++) {
                  const callback = observing[event][selector][k];
                  callback(
                    new ElementObserverEvent({
                      element: node as HTMLElement,
                      selector,
                      event,
                      disconnector: this.#disconnect_fn({
                        event,
                        selector,
                        callback,
                      }),
                      mutation_record: record,
                    })
                  );
                }
              }
              if (
                (record.addedNodes.length || record.removedNodes.length) &&
                node.hasChildNodes() /* it is possible to add an element that already has children */ &&
                (node as HTMLElement)
                  .querySelectorAll /* don't act on weird elements, dunno if real fear */
              ) {
                const matching_children = (
                  node as HTMLElement
                ).querySelectorAll(selector);
                for (let m = 0; m < matching_children.length; m++) {
                  // always use for loops because this can't be slow and forEach takes ages
                  if (event === "exists") {
                    //@ts-ignore
                    our_set.add(matching_children[m]);
                  }
                  for (let o = 0; o < observing[event][selector].length; o++) {
                    const callback = observing[event][selector][o];
                    callback(
                      new ElementObserverEvent({
                        element: matching_children[m] as HTMLElement,
                        selector,
                        event,
                        disconnector: this.#disconnect_fn({
                          event,
                          selector,
                          callback,
                        }),
                        mutation_record: record,
                      })
                    );
                  }
                }
              }
            }
          }
        }

        // code for handling changes to children of an observed element
        if (observing["change"]) {
          let target_el = record.target;
          while ((target_el = target_el?.parentNode as Node)) {
            if ((target_el as HTMLElement)?.matches) {
              for (
                let p = 0,
                  selectors = Object.keys(observing["change"]),
                  selector;
                p < selectors.length;
                p++
              ) {
                selector = selectors[p];
                if ((target_el as HTMLElement).matches?.(selector)) {
                  const callbacks = observing["change"][selector];
                  for (let q = 0; q < callbacks.length; q++) {
                    callbacks[q](
                      new ElementObserverEvent({
                        element: target_el as HTMLElement,
                        selector,
                        event: "change",
                        disconnector: this.#disconnect_fn({
                          event: "change",
                          selector,
                          callback: callbacks[q],
                        }),
                        mutation_record: record,
                      })
                    );
                  }
                }
              }
            }
          }
        }

        // code for observing observed elements disappearing
        if (observing["removed"] && record.removedNodes?.length) {
          const selectors = Object.keys(observing["removed"]);
          for (let t = 0; t < record.removedNodes.length; t++) {
            for (let u = 0, node, selector; u < selectors.length; u++) {
              const matching_nodes = [];
              selector = selectors[u];
              node = record.removedNodes[t];
              if ((node as HTMLElement)?.matches?.(selector)) {
                matching_nodes.push(node);
              }
              matching_nodes.push(
                ...((node as HTMLElement)?.querySelectorAll?.(selector) || [])
              );
              for (let v = 0; v < matching_nodes.length; v++) {
                for (
                  let w = 0, callback;
                  w < observing["removed"][selector].length;
                  w++
                ) {
                  callback = observing["removed"][selector][w];
                  callback(
                    new ElementObserverEvent({
                      element: matching_nodes[v] as HTMLElement,
                      selector: selector,
                      event: "removed",
                      disconnector: this.#disconnect_fn({
                        event: "removed",
                        selector: selector,
                        callback,
                      }),
                      mutation_record: record,
                    })
                  );
                }
              }
            }
          }
        }
      }
      // const end = new Date().getTime();
      // console.log("procesed",records.length,"in", end-start, this.#observing);
    } catch (e) {
      //err(e);
    }
  };
  #check_observer_args(selector: string, callback: Function) {
    if (typeof selector !== "string") {
      throw new Error("no selector specified");
    }
    if (typeof callback !== "function") {
      throw new Error("no callback specified");
    }
  }
  #add_listener(listener: any) {
    // called when observer.onsomethinglistener() is called, somethinglistener being something in this.#listeners
    const the_class = this;
    return function (selector: any, callback: any) {
      for (const hook of the_class.#add_listener_hooks) {
        const hooked = hook({ listener, selector, callback });
        listener = hooked.listener;
        selector = hooked.selector;
        callback = hooked.callback;
      }
      if (!listener || !selector || !callback) {
        console.log(
          "listener, selector or callback is falsey",
          listener,
          selector,
          callback
        );
        return;
      }
      the_class.#check_observer_args(selector, callback);
      const observing = the_class.#observing;
      //@ts-ignore
      if (!observing[listener]) {
        //@ts-ignore
        observing[listener] = {};
      }
      //@ts-ignore
      if (!observing[listener][selector]) {
        //@ts-ignore
        observing[listener][selector] = [callback];
      } else {
        //@ts-ignore
        observing[listener][selector].push(callback);
      }
      if (!the_class.#the_mutation_observer) {
        the_class.#kickstart_observer();
      }
      if (listener === "exists") {
        the_class.#verbs.ifexists(selector, callback);
      }
      // console.log(listener, selector, callback);
      return the_class.#disconnect_fn({
        event: listener,
        selector,
        callback,
      });
    };
  }
  #get(target: any, verb: string) {
    const is_listener = target.#listeners.includes(verb.substr(2)),
      is_verb = Object.keys(target.#verbs).includes(verb);
    if (is_verb) {
      return target.#verbs[verb];
    } else if (is_listener) {
      return target.#add_listener(verb.substr(2));
    } else {
      throw new Error(
        "Property " +
          verb +
          " is not valid. Valid listeners: " +
          target.#listeners.join(", ") +
          "; valid verbs: " +
          Object.keys(target.#verbs).join(", ")
      );
    }
  }
  constructor(
    options: {
      add_listener_hooks?: typeof deduplicator[];
      territory?: HTMLElement | Document | DocumentFragment;
    } = {}
  ) {
    if (
      process.env.BUILD_TARGET !== "modern" &&
      Proxy.toString().indexOf("[native code]") === -1
    ) {
      //@ts-ignore
      this.#listeners.forEach((listener) => (this["on" + listener] = () => {})); // define listeners, needed for IE11 proxy polyfill
      Object.keys(this.#verbs).forEach(
        //@ts-ignore
        (listener) => (this[listener] = () => {})
      );
    }

    if (options.territory) {
      this.#territory = options.territory;
    }

    this.#add_listener_hooks = options.add_listener_hooks || [];

    return (this.#self_proxied = new Proxy(this, { get: this.#get }));
  }
}

export class ElementObserverEvent<ElemType extends HTMLElement = HTMLElement> {
  constructor(opts: {
    element: ElemType;
    selector: string;
    disconnector: Disconnector;
    mutation_record?: MutationRecord;
    event?: "exists" | "creation" | "removed" | "change";
  }) {
    Object.assign(this, opts);
  }
}

export interface ElementObserverEvent<
  ElemType extends HTMLElement = HTMLElement
> {
  element: ElemType;
  selector: string;
  disconnector: Disconnector;
  mutation_record?: MutationRecord;
  event?: "exists" | "creation" | "removed" | "change";
}

export const observer = /*@__PURE__*/ make_default_observer();
function make_default_observer() {
  try {
    return new ElementObserver({
      add_listener_hooks: [deduplicator],
    });
  } catch (error) {
    console.log(error);
  }
}

import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import mergeClassNames from "merge-class-names";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import reactFastCompare from "react-fast-compare";
import { useTranslation } from "react-i18next";
import config from "src/config";

import { SectionConfigurationRelationshipsProps } from "src/types/components";

import CardCollapsible from "src/components/cards/CardCollapsible";
import ConfigurationSearch from "src/components/configuration/Search";
import DroppableContainer from "src/components/dragndrop/DroppableContainer";
import MultipleContainers from "src/components/dragndrop/MultipleContainers";
import ListItems from "src/components/lists/Items";
import SidebarOverlayRight from "src/components/navs/SidebarOverlayRight";

import { UniqueIdentifier } from "@dnd-kit/core";
import { css } from "@emotion/react";
import {
  getGroupIdFromObj,
  getProductIdFromMainOrRegularProduct,
  refactorForList,
} from "src/helpers/configuration";
import { getSortableContextItems } from "src/helpers/dnd";
import usePanelGetItem from "src/helpers/hooks/configuration/usePanelGetItem";
import useAlreadySelected from "src/helpers/hooks/relationships/useAlreadySelected";
import useDeleteItemCallback from "src/helpers/hooks/relationships/useDeleteItemCallback";
import useDragHandlers from "src/helpers/hooks/relationships/useDragHandlers";
import usePanelsFeatures from "src/helpers/hooks/relationships/usePanelsFeatures";
import useRelationshipCollapseStatus from "src/helpers/hooks/relationships/useRelationshipCollapseStatus";
import useRelationshipGroup from "src/helpers/hooks/relationships/useRelationshipGroup";
import useSearchCallback from "src/helpers/hooks/relationships/useSearchCallback";
import useSetItems from "src/helpers/hooks/relationships/useSetItems";
import useWindowDimensions from "src/helpers/hooks/useWindowDimensions";
import { ConfiguredProductGroupDefinition } from "src/types/configuration";
import Button from "../storybook/Button/Button";

export const SectionConfigurationRelationships = (
  props: SectionConfigurationRelationshipsProps
) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const [dragActiveId, setDragActiveId] = useState<UniqueIdentifier | null>(
    null
  );

  const {
    configuration,
    configurationGroups,
    aiControl,
    products,
    groups,
    isFromDisabled,
    isFromPanelDanger,
    groupModifyCB,
    fromHasMultipleItemsCB,
    toggleRightSidebarOverlayCB,
    toggleHelpModalCB,
  } = props;

  const [searchTarget, setSearchTarget] = useState<string | null>(null);

  const [
    fromIcon,
    includeIcon,
    excludeIcon,
    noListComponent,
    fromTitle,
    includeTitle,
    excludeTitle,
  ] = usePanelsFeatures(toggleHelpModalCB);

  const [
    fromGroup,
    fromIsEmpty, //eslint-disable-line
    fromHasMultipleItems,
  ] = useRelationshipGroup({
    configurationGroups,
    configuration,
    relationship: aiControl.currentConf ? "only_include" : "must_include",
    side: "from_group",
  });

  const [includeGroup] = useRelationshipGroup({
    configurationGroups,
    configuration,
    relationship: aiControl.currentConf ? "only_include" : "must_include",
    side: "to_group",
  });

  const [excludeGroup] = useRelationshipGroup({
    configurationGroups,
    configuration,
    relationship: "do_not_include",
    side: "to_group",
  });

  let [fromSetItems] = useSetItems({ group: fromGroup, products, groups });
  let [includeSetItems] = useSetItems({
    group: includeGroup,
    products,
    groups,
  });
  let [excludeSetItems] = useSetItems({
    group: excludeGroup,
    products,
    groups,
  });

  const [alreadySelectedProducts] = useAlreadySelected({
    fromGroup,
    includeGroup,
    excludeGroup,
    getObjKey: getProductIdFromMainOrRegularProduct,
  });

  const [alreadySelectedGroups] = useAlreadySelected({
    fromGroup,
    includeGroup,
    excludeGroup,
    getObjKey: getGroupIdFromObj,
  });

  const { searchCallback } = useSearchCallback({
    searchTarget,
    configurationGroups,
    groupModifyCB,
    toggleRightSidebarOverlayCB,
  });

  const { deleteItem } = useDeleteItemCallback({
    configurationGroups,
    groupModifyCB,
  });

  const buttonOnClickCB = useCallback(
    (data: ConfiguredProductGroupDefinition) => {
      searchCallback(data);
    },
    [searchCallback]
  );

  const openSearchForm = useCallback(
    (group_id: string) => {
      setSearchTarget(group_id);
      toggleRightSidebarOverlayCB();
    },
    [toggleRightSidebarOverlayCB]
  );

  useEffect(() => {
    fromHasMultipleItemsCB(fromHasMultipleItems);
  }, [fromHasMultipleItems, fromHasMultipleItemsCB]);

  const [
    fromId,
    includeId,
    excludeId,
    isPanelExpanded,
    isLastExpandedPanel,
    collapseCallback,
  ] = useRelationshipCollapseStatus(
    fromGroup,
    includeGroup,
    excludeGroup,
    isFromDisabled
  );

  const isReadyToBeOnScreen = useMemo((): boolean => {
    return fromId !== null && includeId !== null && excludeId !== null;
  }, [fromId, includeId, excludeId]);

  const headerTitle = useMemo(() => {
    return <p className="text-center fw-500 mb-3">Add products & groups</p>;
  }, []);

  const [handleDragOver, handleDragEnd] = useDragHandlers(
    fromGroup,
    includeGroup,
    excludeGroup,
    groupModifyCB
  );

  const handleActiveIdChange = useCallback((id: UniqueIdentifier | null) => {
    setDragActiveId(id);
  }, []);

  const [fromGetItem] = usePanelGetItem(deleteItem, fromId as string);
  const [includeGetItem] = usePanelGetItem(deleteItem, includeId as string);
  const [excludeGetItem] = usePanelGetItem(deleteItem, excludeId as string);

  return (
    <>
      {isReadyToBeOnScreen && (
        <div
          className="row depict--ConfigureConfiguration__relationship mb-3"
          css={css`
            height: 100%;
          `}
        >
          <MultipleContainers
            items={{
              from: refactorForList(fromSetItems),
              include: refactorForList(includeSetItems),
              exclude: refactorForList(excludeSetItems),
              /**
               * These two below we need to add in case we need to add draggability to the Search.
               * + Move <MultipleContainers /> up a couple of divs so it includes the <Search />
               *
               * I have investigated that the main problem when it comes to make the search draggable, is the size of the list.
               *
               */
              // "search-products": refactorForList(products),
              // "search-groups": refactorForList(groups),
            }}
            onDragOverCB={handleDragOver}
            onDragEndCB={handleDragEnd}
            onActiveIdChange={handleActiveIdChange}
          >
            <div
              className={mergeClassNames([
                isPanelExpanded(fromId) ? "col" : "col-auto",
                isLastExpandedPanel(fromId) &&
                  width >= config.const.bootstrapSmWidth &&
                  "depict--lastExpandedPanel",
              ])}
            >
              <div
                data-tc="from-panel"
                className={mergeClassNames([
                  "depict--ConfigureConfiguration__relationship__from",
                  isFromDisabled &&
                    "depict--ConfigureConfiguration__relationship__isDisabled",
                ])}
                css={css`
                  height: 100%;
                `}
              >
                <CardCollapsible
                  title={fromTitle}
                  id={`${fromId}`}
                  isVertical={
                    width < config.const.bootstrapSmWidth ? false : true
                  }
                  onCollapseCB={collapseCallback}
                  titleTooltip={t("tooltips.configuration.panels.from")}
                  //@ts-ignore
                  externalToggle={
                    isPanelExpanded(fromId) !== null
                      ? isPanelExpanded(fromId)
                      : undefined
                  }
                  isDisabled={isFromDisabled}
                  className={
                    isFromPanelDanger
                      ? "depict--CardCollapsible__borderRed"
                      : ""
                  }
                >
                  <DroppableContainer
                    key={"from"}
                    id={"from"}
                    columns={1}
                    items={refactorForList(fromSetItems)}
                    style={{ zIndex: 1, flex: 1 }}
                    placeholder={true}
                  >
                    <SortableContext
                      items={getSortableContextItems(
                        refactorForList(fromSetItems)
                      )}
                      strategy={verticalListSortingStrategy}
                    >
                      <ListItems
                        id={`${fromId}`}
                        items={refactorForList(fromSetItems)}
                        noListComponent={noListComponent}
                        isSortable={true}
                        getItem={fromGetItem}
                      />
                    </SortableContext>
                  </DroppableContainer>
                  <Button
                    variant="secondary"
                    text="Add"
                    onClick={() => {
                      if (!fromId) {
                        console.warn(
                          "[CONFIGURATION] No group_id in SetPanel.tsx"
                        );
                        return;
                      }
                      openSearchForm(fromId);
                    }}
                  />
                  {fromIcon}
                </CardCollapsible>
              </div>
            </div>
            <div
              key={includeId}
              className={mergeClassNames([
                isPanelExpanded(includeId) ? "col" : "col-auto",
                isLastExpandedPanel(includeId) &&
                  width >= config.const.bootstrapSmWidth &&
                  "depict--lastExpandedPanel",
              ])}
            >
              <div
                data-tc="include-panel"
                className={mergeClassNames([
                  "depict--ConfigureConfiguration__relationship__to",
                ])}
                css={css`
                  height: 100%;
                `}
              >
                <CardCollapsible
                  title={includeTitle}
                  id={`${includeId}`}
                  isVertical={
                    width < config.const.bootstrapSmWidth ? false : true
                  }
                  onCollapseCB={collapseCallback}
                  //@ts-ignore
                  externalToggle={
                    isPanelExpanded(includeId) !== null
                      ? isPanelExpanded(includeId)
                      : undefined
                  }
                >
                  <DroppableContainer
                    key={"include"}
                    id={"include"}
                    columns={1} //TODO: compute number of columns in case large screen.
                    items={refactorForList(includeSetItems)}
                    style={{ zIndex: 1, flex: 1 }}
                    placeholder={true}
                  >
                    <SortableContext
                      items={getSortableContextItems(
                        refactorForList(includeSetItems)
                      )}
                      strategy={verticalListSortingStrategy}
                    >
                      <ListItems
                        id={`${includeId}`}
                        items={refactorForList(includeSetItems)}
                        getItem={includeGetItem}
                        isSortable={true}
                      />
                    </SortableContext>
                  </DroppableContainer>
                  <Button
                    variant="secondary"
                    text="Add"
                    onClick={() => {
                      if (!includeId) {
                        console.warn(
                          "[CONFIGURATION] No group_id in SetPanel.tsx"
                        );
                        return;
                      }
                      openSearchForm(includeId);
                    }}
                  />
                  {includeIcon}
                </CardCollapsible>
              </div>
            </div>
            <div
              key={excludeId}
              className={mergeClassNames([
                isPanelExpanded(excludeId) ? "col" : "col-auto",
                isLastExpandedPanel(excludeId) &&
                  width >= config.const.bootstrapSmWidth &&
                  "depict--lastExpandedPanel",
              ])}
            >
              <div
                data-tc="exclude-panel"
                className={mergeClassNames([
                  "depict--ConfigureConfiguration__relationship__to",
                ])}
                css={css`
                  height: 100%;
                `}
              >
                <CardCollapsible
                  title={excludeTitle}
                  id={`${excludeId}`}
                  isVertical={
                    width < config.const.bootstrapSmWidth ? false : true
                  }
                  onCollapseCB={collapseCallback}
                  //@ts-ignore
                  externalToggle={
                    isPanelExpanded(excludeId) !== null
                      ? isPanelExpanded(excludeId)
                      : undefined
                  }
                >
                  <DroppableContainer
                    key={"exclude"}
                    id={"exclude"}
                    columns={1} //TODO: compute number of columns in case large screen.
                    items={refactorForList(excludeSetItems)}
                    style={{ zIndex: 1, flex: 1 }}
                    placeholder={true}
                  >
                    <SortableContext
                      items={getSortableContextItems(
                        refactorForList(excludeSetItems)
                      )}
                      strategy={verticalListSortingStrategy}
                    >
                      <ListItems
                        id={`${excludeId}`}
                        items={refactorForList(excludeSetItems)}
                        getItem={excludeGetItem}
                        isSortable={true}
                      />
                    </SortableContext>
                  </DroppableContainer>
                  <Button
                    variant="secondary"
                    text="Add"
                    onClick={() => {
                      if (!excludeId) {
                        console.warn(
                          "[CONFIGURATION] No group_id in SetPanel.tsx"
                        );
                        return;
                      }
                      openSearchForm(excludeId);
                    }}
                  />
                  {excludeIcon}
                </CardCollapsible>
              </div>
            </div>
          </MultipleContainers>
        </div>
      )}
      <SidebarOverlayRight
        toggleRightSidebarOverlayCB={toggleRightSidebarOverlayCB}
      >
        {headerTitle}
        <ConfigurationSearch
          buttonOnClickCB={buttonOnClickCB}
          alreadySelectedProducts={alreadySelectedProducts}
          alreadySelectedGroups={alreadySelectedGroups}
          products={products}
          groups={groups}
          isVirtual={true}
          dragActiveId={dragActiveId ?? undefined}
        />
      </SidebarOverlayRight>
    </>
  );
};

//7 no deep. 5 deep.
//export default SectionConfigurationRelationships;
export default React.memo(SectionConfigurationRelationships, reactFastCompare);

import { useInfiniteQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { BasePortalProductDto } from "src/api/types";
import { SortModel } from "src/api/types";
import { QueryId, getQueryKey } from "src/queries/queries";
import { GetNotCuratedOrderProductsConfig } from "./useCategoryNotCuratedProducts";

export function usePreviewProducts(
  merchantId: string | null,
  categoryId: string | null,
  getNotCuratedProductsConfig: GetNotCuratedOrderProductsConfig,
  sortBy: SortModel | undefined,
  isPreviewingProducts: boolean
) {
  const enabled =
    !!merchantId &&
    !!categoryId &&
    getNotCuratedProductsConfig.enabled &&
    !!sortBy &&
    isPreviewingProducts;

  const { callback } = getNotCuratedProductsConfig;

  const getProductsCB = async ({ pageParam }: { pageParam: string | null }) => {
    const canRun = !!merchantId && !!categoryId && !!sortBy;

    if (canRun) {
      const response = await callback(
        merchantId,
        categoryId,
        [],
        [],
        pageParam,
        sortBy
      );

      return response;
    }
  };

  const productOrderQuery = useInfiniteQuery({
    queryKey: getQueryKey(
      QueryId.GetGetCategoryPreviewProducts,
      merchantId || "",
      categoryId || "",
      getNotCuratedProductsConfig.querySalt,
      sortBy
    ),
    queryFn: getProductsCB,
    enabled,
    refetchOnMount: false,
    getNextPageParam: (lastPage) => lastPage?.cursor ?? undefined,
    initialPageParam: null,
  });

  const productOrder = useMemo(() => {
    const productDTOMapping: { [key: string]: BasePortalProductDto } = {};
    productOrderQuery.data?.pages.forEach((page) => {
      Object.values(page?.notCuratedProducts ?? []).forEach((product) => {
        productDTOMapping[product.main_product_id] = product;
      });
    });
    return (
      productOrderQuery.data?.pages
        .map((page) => {
          return page?.notCuratedOrder ?? [];
        })
        .flat()
        .map((productId) => productDTOMapping[productId]) ?? []
    );
  }, [productOrderQuery.data]);

  return {
    previewProducts: productOrder,
    getMorePreviewProducts: productOrderQuery.fetchNextPage,
    hasMorePreviewProducts: !!productOrderQuery.hasNextPage,
    isLoading: productOrderQuery.isLoading || productOrderQuery.isRefetching,
    isError: productOrderQuery.isError,
    isFetchingMore: productOrderQuery.isFetchingNextPage,
  };
}

import { useQuery } from "@tanstack/react-query";
import { getData } from "src/api/authorizedApi";
import { QueryId, getQueryKey } from "../../../queries/queries";
import useAuthorizedApi from "../app/useAuthorizedApi";
import { useVisibilityState } from "../app/useVisibilityState";

export default function useCatalogSyncStatus(merchantId: string | null) {
  const { api } = useAuthorizedApi();
  const pageVisible = useVisibilityState();

  const { data, isLoading, isError } = useQuery({
    queryKey: getQueryKey(QueryId.GetCatalogSyncStatus, merchantId || ""),
    queryFn: async () => {
      if (!api || !merchantId) return;
      const response = await api.GET(
        "/api/v0/integrations/workflow/{merchant_id}",
        {
          params: {
            path: {
              merchant_id: merchantId,
            },
          },
        }
      );

      const data = getData(response);

      return data;
    },

    enabled: !!api && !!merchantId,
    gcTime: 1000 * 60 * 60,
    staleTime: 1000 * 60 * 30,
    retry: 2,
    // Refetch periodically so people don't have to refresh the page to see sync status
    // only refetch when tab is focused
    refetchInterval: pageVisible ? 5000 : false,
  });

  return {
    lastSync: data?.last_succeeded,
    syncInProgress: data?.running,
    isLoading,
    isError,
    nQueued: data?.n_queued,
  };
}

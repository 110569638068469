import { Interpolation, Theme, css } from "@emotion/react";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { theme } from "../designSystemVariables";

const inputStyle: Interpolation<Theme> = [
  theme.typography.paragraph[200].light,
  {
    display: "flex",
    alignItems: "center",
    color: theme.textIcon.neutral.default,
    flexGrow: 1,
    border: "none",
    backgroundColor: "inherit",
    ":focus": {
      outline: "none",
    },
  },
];

interface SearchBarProps {
  placeholder?: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  autoFocus?: boolean;
}
export const SearchBar = (props: SearchBarProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(containerRef, () => {
    props.onBlur?.();
  });

  const focusInput = () => {
    inputRef.current?.focus();
    props.onFocus?.();
  };

  return (
    <div
      ref={containerRef}
      onClick={focusInput}
      css={css`
        width: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0.75rem 0.75rem;
          gap: 0.5rem;
          border-width: 1px;
          border-style: solid;
          border-color: ${theme.border.subtle.default};
          border-radius: 0.5rem;
          :focus-within {
            border-color: ${theme.border.neutral.default};
          }
          flex-grow: 1;
        `}
      >
        <MagnifyingGlass
          width="1.25rem"
          height="1.25rem"
          color={theme.textIcon.base.default}
        />
        <input
          ref={inputRef}
          onChange={(e) => {
            e.preventDefault();
            return props.onChange(e.target.value);
          }}
          css={inputStyle}
          placeholder={props.placeholder}
          autoFocus={props.autoFocus || false}
        />
      </div>
    </div>
  );
};

export default SearchBar;

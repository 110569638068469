import { createContext, useCallback, useContext } from "react";

type ImageResizeContextProps = {
  getImageUrl: (url: string, width?: number, format?: string) => string;
};

const ImageResizeContext = createContext<ImageResizeContextProps | undefined>(
  undefined
);

export function ImageResizeProvider({
  children,
  merchantId,
}: {
  children: React.ReactNode;
  merchantId?: string;
}) {
  const getImageUrl = useCallback(
    (url: string, width = 250, format = "webp") => {
      if (!merchantId) {
        return url;
      }
      const newUrl = new URL(
        `https://img.depict.ai/ResizeImage/${merchantId}?format=${format}&width=${width}`
      );
      // This is to ensure proper encoding of the url
      newUrl.searchParams.set("url", url);
      return newUrl.href;
    },
    [merchantId]
  );

  return (
    <ImageResizeContext.Provider value={{ getImageUrl }}>
      {children}
    </ImageResizeContext.Provider>
  );
}

export function useImageResize() {
  const context = useContext(ImageResizeContext);

  if (!context) {
    throw new Error("useImageResize must be used within a ImageResizeProvider");
  }

  return context;
}

import reactFastCompare from "react-fast-compare";
import React from "react";
import mergeClassNames from "merge-class-names";
import { useTranslation } from "react-i18next";
import filterTotal from "src/helpers/filters/total";
import { SectionMiniGraphsProps } from "src/types/components";

import lessDataPoints from "src/helpers/filters/lessDataPoints";

import CardMetric from "src/components/cards/CardMetric";
import ExpandIcon from "src/components/mix/ExpandIcon";

import { FingerTap, CartPlus, Receipt } from "src/components/linearicons";
import { useCallback, useMemo, useState } from "react";
import config from "src/config";

const SectionMiniGraphs = (props: SectionMiniGraphsProps) => {
  const { t } = useTranslation();
  type MiniGraph = "clicks" | "checkouts" | "adds-to-cart" | "purchases";

  const {
    colors,
    clicks,
    clicksCompare,
    addsToCart,
    addsToCartCompare,
    checkouts, //eslint-disable-line
    checkoutsCompare, //eslint-disable-line
    purchases,
    purchasesCompare,
    market,
    compare,
    enabledSurfaces,
    splitBySurface,
    windowWidth,
  } = props;

  const [currentExpanded, setCurrentExpanded] = useState<null | MiniGraph>();

  const graphClick = (graph: MiniGraph) => {
    if (currentExpanded === graph) {
      setCurrentExpanded(null);
      return;
    }
    setCurrentExpanded(graph);
  };

  const minimizedOptions = useMemo(() => {
    return {
      isInteractive: false,
      axisTop: null,
      axisBottom: null,
      axisLeft: null,
      margin: {},
      pointSize: 1,
      pointBorderWidth: 1,
      theme: {},
      enableGridX: false,
      enableGridY: false,
      lineWidth: 1,
    };
  }, []);

  const classes = useCallback(
    (metric: string) => {
      return mergeClassNames(
        "mb-2b mb-xxl-3 depict--miniGraph",
        (currentExpanded === metric ||
          windowWidth < config.const.bootstrapSmWidth) &&
          "depict--miniGraph__expanded depict--fadeIn"
      );
    },
    [currentExpanded, windowWidth]
  );

  const parentClasses = useMemo(() => {
    return mergeClassNames(
      "row depict--miniGraphs depict-miniGraphs__row3",
      !currentExpanded && "depict--miniGraphs__allMinimized"
    );
  }, [currentExpanded]);

  const size = useCallback(
    (metric: string) => {
      return currentExpanded === metric ||
        windowWidth < config.const.bootstrapSmWidth
        ? 24
        : 12;
    },
    [currentExpanded, windowWidth]
  );

  const options = useCallback(
    (metric: string) => {
      return currentExpanded === metric ||
        windowWidth < config.const.bootstrapSmWidth
        ? undefined
        : minimizedOptions;
    },
    [currentExpanded, minimizedOptions, windowWidth]
  );

  const filters = useCallback(
    (metric: string) => {
      if (
        currentExpanded === metric ||
        windowWidth < config.const.bootstrapSmWidth
      ) {
        return [filterTotal];
      }

      return [lessDataPoints, filterTotal];
    },
    [currentExpanded, windowWidth]
  );

  return (
    <div className={parentClasses}>
      <div className="col depict--miniGraphDivider"></div>
      <div
        className={classes("clicks")}
        onClick={() => {
          graphClick("clicks");
        }}
        data-bs-toggle="tooltip"
        title={t("tooltips.graphs.clicks.title")}
      >
        <CardMetric
          iconTopLeft={<FingerTap size={size("clicks")} />}
          yTooltipLabel="clicks"
          title="Clicks"
          data={clicks}
          dataCompare={clicksCompare}
          colors={colors}
          plotOptions={options("clicks")}
          filters={filters("clicks")}
          market={market}
          compare={compare}
          enabledSurfaces={enabledSurfaces}
          splitBySurface={splitBySurface}
        />
        <ExpandIcon />
      </div>
      <div
        className={classes("adds-to-cart")}
        onClick={() => {
          graphClick("adds-to-cart");
        }}
        data-bs-toggle="tooltip"
        title={t("tooltips.graphs.addsToCart.title")}
      >
        <CardMetric
          iconTopLeft={<CartPlus size={size("adds-to-cart")} />}
          yTooltipLabel="adds to cart"
          title="Adds to cart"
          data={addsToCart}
          dataCompare={addsToCartCompare}
          colors={colors}
          plotOptions={options("adds-to-cart")}
          filters={filters("adds-to-cart")}
          market={market}
          compare={compare}
          enabledSurfaces={enabledSurfaces}
          splitBySurface={splitBySurface}
        />
        <ExpandIcon />
      </div>
      {/*
       * Checkout data would be empty for most of our customers.
       * Until we improve the backend to either deliver supported metrics for merchant OR support checkout metrics for all customers,
       * this is removed. 02/03/2022
       */}
      {/* <div
        className={classes("checkouts")}
        onClick={() => {
          graphClick("checkouts");
        }}
        data-bs-toggle="tooltip"
        title={t("tooltips.graphs.checkout.title")}
      >
        <CardMetric
          iconTopLeft={<DollarSign size={size("checkouts")} />}
          yTooltipLabel="checkouts"
          title="Checkouts"
          data={checkouts}
          dataCompare={checkoutsCompare}
          colors={colors}
          plotOptions={options("checkouts")}
          filters={filters("checkouts")}
          market={market}
          compare={compare}
          enabledSurfaces={enabledSurfaces}
          splitBySurface={splitBySurface}
        />
        <ExpandIcon />
      </div> */}
      <div
        className={classes("purchases")}
        onClick={() => {
          graphClick("purchases");
        }}
        data-bs-toggle="tooltip"
        title={t("tooltips.graphs.uniquePurchases.title")}
      >
        <CardMetric
          iconTopLeft={<Receipt size={size("purchases")} />}
          yTooltipLabel="purchases"
          title="Unique purchases"
          data={purchases}
          dataCompare={purchasesCompare}
          colors={colors}
          plotOptions={options("purchases")}
          filters={filters("purchases")}
          market={market}
          compare={compare}
          enabledSurfaces={enabledSurfaces}
          splitBySurface={splitBySurface}
        />
        <ExpandIcon />
      </div>
    </div>
  );
};

export default React.memo(SectionMiniGraphs, reactFastCompare);

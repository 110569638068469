import { Col, Row } from "react-bootstrap";
import { OpenDropdownProvider } from "../Dropdown/WhichDropdownOpenContext";
import { theme } from "../designSystemVariables";
import ProductListViewPlaceholder from "../ProductListViewPlaceholder/ProductListViewPlaceholder";
import { css } from "@emotion/react";
import { depictCardClassName } from "../common";
import { useRef } from "react";
import { useIntersectionObserver } from "usehooks-ts";
import useOnValueChange from "src/helpers/hooks/useOnValueChange";

type StringOrNumberKeys<T> = {
  [K in keyof T]: T[K] extends string | number ? K : never;
}[keyof T];

export type RenderCardCB<T> = (item: T, index: number) => React.ReactNode;

interface ProductListViewProps<T> {
  items: T[];
  itemIdKey: StringOrNumberKeys<T>;
  renderCard: RenderCardCB<T>;
  loading: boolean;
  onScrollAtBottom?: () => void;
  isLoadingMore: boolean;
}

export default function ProductListView<T extends Record<string, any>>(
  props: ProductListViewProps<T>
) {
  const lastElementRef = useRef<HTMLDivElement>(null);
  const lastElement = useIntersectionObserver(lastElementRef, {});
  const lastElementIsVisible = lastElement?.isIntersecting;

  useOnValueChange(lastElementIsVisible, (isVisible) => {
    if (isVisible && props.onScrollAtBottom) {
      props.onScrollAtBottom();
    }
  });

  return (
    <OpenDropdownProvider>
      {props.loading && <ProductListViewPlaceholder />}
      {!props.loading &&
        props.items.map((item, index) => {
          const isLastElement = index === props.items.length - 1;

          return (
            <div
              ref={isLastElement ? lastElementRef : null}
              key={item.mainProductId}
              className={`${depictCardClassName}`}
              css={css`
                border-color: ${theme.border.subtle.default};
                flex-wrap: nowrap;
                padding: 0.5rem;
              `}
            >
              <Col>{props.renderCard(item, index)}</Col>
            </div>
          );
        })}
      {props.isLoadingMore && <ProductListViewPlaceholder />}
    </OpenDropdownProvider>
  );
}

import { useBlocker } from "react-router-dom";

export const LEAVE_TEXT = "You have unsaved changes";

export function useDepictBlocker(shouldBlock: boolean, text?: string) {
  const usedText = text || LEAVE_TEXT;

  window.onbeforeunload = () => (shouldBlock ? usedText : undefined);

  useBlocker(() => shouldBlock && !window.confirm(usedText));
}

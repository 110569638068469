 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Football = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M19.147 3.439c-0.060-0.060-1.517-1.479-4.818-1.479-1.661 0-3.51 0.36-5.496 1.069-2.005 0.716-3.718 1.763-5.091 3.112-1.102 1.082-1.986 2.357-2.629 3.791-1.095 2.44-1.11 4.482-1.11 4.567-0.001 0.71 0.421 1.574 0.981 2.009 0.079 0.062 1.981 1.511 5.414 1.511 1.69 0 3.467-0.354 5.282-1.053 1.888-0.727 3.502-1.781 4.796-3.134 1.036-1.084 1.869-2.358 2.475-3.788 1.030-2.431 1.048-4.458 1.048-4.543 0.002-0.689-0.364-1.575-0.851-2.062zM18.013 9.691c-1.279 2.992-3.531 5.126-6.694 6.342-1.7 0.654-3.357 0.986-4.923 0.986-3.068 0-4.784-1.288-4.8-1.3-0.127-0.099-0.249-0.254-0.35-0.429 0.623-0.861 2.064-2.612 4.544-4.385 0.225-0.161 0.277-0.473 0.116-0.698s-0.473-0.277-0.698-0.116c-1.988 1.421-3.345 2.838-4.168 3.834 0.078-0.73 0.307-2.077 0.983-3.584 1.354-3.018 3.757-5.161 7.144-6.371 1.878-0.671 3.614-1.011 5.16-1.011 1.495 0 2.535 0.324 3.144 0.596 0.47 0.21 0.763 0.422 0.892 0.526-0.848 0.112-2.157 0.366-4.011 0.937-0.264 0.081-0.412 0.361-0.331 0.625 0.066 0.215 0.264 0.353 0.478 0.353 0.049 0 0.098-0.007 0.147-0.022 2.195-0.676 3.582-0.885 4.27-0.948 0.050 0.168 0.080 0.333 0.080 0.471-0 0.019-0.022 1.941-0.984 4.193z" fill={color}></path>
<path d="M7.5 10.999c-0.276 0-0.5-0.224-0.5-0.5 0-0.828-0.673-1.501-1.501-1.501-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5c1.379 0 2.501 1.122 2.501 2.501 0 0.276-0.224 0.5-0.5 0.5z" fill={color}></path>
<path d="M9.5 9.999c-0.276 0-0.5-0.224-0.5-0.5 0-0.828-0.673-1.501-1.501-1.501-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5c1.379 0 2.501 1.122 2.501 2.501 0 0.276-0.224 0.5-0.5 0.5z" fill={color}></path>
<path d="M11.5 8.999c-0.276 0-0.5-0.224-0.5-0.5 0-0.828-0.673-1.501-1.501-1.501-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5c1.379 0 2.501 1.122 2.501 2.501 0 0.276-0.224 0.5-0.5 0.5z" fill={color}></path>
<path d="M13.5 7.999c-0.276 0-0.5-0.224-0.5-0.5 0-0.828-0.673-1.501-1.501-1.501-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5c1.379 0 2.501 1.122 2.501 2.501 0 0.276-0.224 0.5-0.5 0.5z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Football); 
// CategoryProvider.tsx

import { createContext, useMemo, useReducer } from "react";
import { BasePortalProductDto, HiddenPortalProductDto } from "src/api/types";
import { SortModel } from "src/api/types";
import { initialState, reducer } from "./Category.reducer";
import { Action, ActionType, State } from "./Category.reducer.types";
import {
  getAddedProducts,
  getAllLocalProductIdsInOrder,
  getCuratedProductIds,
  getCurrentNotCuratedCount,
  getHiddenProductIds,
  getHiddenProducts,
  getNotCuratedTotalCount,
  getPinnedProductIds,
  getPinnedProducts,
  getProductsByRelevance,
  getRemovedProducts,
  getTotalProductCount,
  isLocalStateDifferentThanServer,
  getHasHiddenChanged,
  getHasPinnedChanged,
  getAutoHiddenProducts,
  getManuallyHiddenProducts,
} from "./Category.selectors";
import { CategoryRankingOrderOptionType } from "../../storybook/Categories/CategoryRankingOrder/CategoryRankingOrderOption";
export { ActionType };

export interface CategoryContextProps extends State { }

export const CategoryContext = createContext<CategoryContextProps | null>(null);
export const CategoryDispatchContext = createContext<React.Dispatch<Action>>(
  () => { }
);

interface CategorySelectors {
  isLocalStateDifferentThanServer: boolean;
  totalProductCount: number;
  currentNotCuratedCount: number;
  totalNotCuratedCount: number;
  pinnedProducts: BasePortalProductDto[];
  pinnedProductIds: string[];
  productsByRelevance: BasePortalProductDto[];
  hiddenProducts: HiddenPortalProductDto[];
  hiddenProductIds: string[];
  autoHiddenProducts: HiddenPortalProductDto[];
  manuallyHiddenProducts: HiddenPortalProductDto[];
  curatedProductIds: string[] | null;
  addedProducts: string[];
  removedProducts: string[];
  allLocalProductIds: string[];
  hasHiddenChanged: boolean;
  hasPinnedChanged: boolean;
  title: string | null;
  titleIsValid: boolean;
  productSortMethod: SortModel | undefined;
  rankingType: CategoryRankingOrderOptionType | null;
  isSaving: boolean;
}
export const CategorySelectorsContext = createContext<CategorySelectors>({
  isLocalStateDifferentThanServer: false,
  totalProductCount: 0,
  currentNotCuratedCount: 0,
  totalNotCuratedCount: 0,
  pinnedProducts: [],
  pinnedProductIds: [],
  productsByRelevance: [],
  hiddenProducts: [],
  hiddenProductIds: [],
  autoHiddenProducts: [],
  manuallyHiddenProducts: [],
  curatedProductIds: [],
  addedProducts: [],
  removedProducts: [],
  allLocalProductIds: [],
  hasHiddenChanged: false,
  hasPinnedChanged: false,
  title: "",
  titleIsValid: true,
  productSortMethod: undefined,
  rankingType: null,
  isSaving: false,
});

interface UseCategoryContextProps {
  children: React.ReactNode;
}

export const CategoryProvider = ({ children }: UseCategoryContextProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const selectors: CategorySelectors = useMemo(() => {
    return {
      isLocalStateDifferentThanServer: isLocalStateDifferentThanServer(state),
      totalProductCount: getTotalProductCount(state),
      currentNotCuratedCount: getCurrentNotCuratedCount(state),
      totalNotCuratedCount: getNotCuratedTotalCount(state),
      pinnedProducts: getPinnedProducts(state),
      pinnedProductIds: getPinnedProductIds(state),
      productsByRelevance: getProductsByRelevance(state),
      hiddenProducts: getHiddenProducts(state),
      autoHiddenProducts: getAutoHiddenProducts(state),
      manuallyHiddenProducts: getManuallyHiddenProducts(state),
      hiddenProductIds: getHiddenProductIds(state),
      curatedProductIds: getCuratedProductIds(state),
      addedProducts: getAddedProducts(state),
      removedProducts: getRemovedProducts(state),
      allLocalProductIds: getAllLocalProductIdsInOrder(state),
      hasHiddenChanged: getHasHiddenChanged(state),
      hasPinnedChanged: getHasPinnedChanged(state),
      title: state.local.title,
      titleIsValid: state.titleIsValid,
      productSortMethod: state.productSortMethod,
      rankingType: state.local.rankingType,
      isSaving: state.isSaving,
    };
  }, [state]);

  return (
    <CategoryContext.Provider value={state}>
      <CategoryDispatchContext.Provider value={dispatch}>
        <CategorySelectorsContext.Provider value={selectors}>
          {children}
        </CategorySelectorsContext.Provider>
      </CategoryDispatchContext.Provider>
    </CategoryContext.Provider>
  );
};

import { useContext, useMemo } from "react";
import { CategorySelectorsContext } from "./Category.provider";
import { SortableGridView } from "./SortableGridView";
import { SortableListView } from "./SortableListView";

interface SortableViewProps {
  isLoading: boolean;
  view: string;
  onPinClick: (id: string) => void;
  onHidingClick: (id: string) => void;
  onAiSortedClick: (id: string) => void;
  onPinToBeginning: (id: string) => void;
  onDragEnd: (mainProductIds: string[], newIndex: number) => void;
  onSelectClick: (id: string, alreadySelected: boolean) => void;
  selectedIds: Set<string>;
  onRemoveClick: ((productId: string) => void) | undefined;
  columns: number;
  aiSorted: boolean;
}

export const SortableView = (props: SortableViewProps) => {
  const {
    isLoading,
    view,
    onPinClick,
    onHidingClick,
    onAiSortedClick,
    onPinToBeginning,
    onDragEnd,
    onSelectClick,
    selectedIds,
    onRemoveClick,
    columns,
  } = props;

  const { pinnedProducts } = useContext(CategorySelectorsContext);

  const disableDrag = useMemo(() => {
    // If selectedIds is empty, we can drag
    if (props.selectedIds.size === 0) {
      return false;
    }

    // If selectedIds is not empty, we can drag if all selectedIds are pinned
    const selectedIdsArray = Array.from(props.selectedIds);
    const pinnedIds = pinnedProducts.map((p) => p.main_product_id);
    const allSelectedIdsArePinned = selectedIdsArray.every((id) =>
      pinnedIds.includes(id)
    );
    return !allSelectedIdsArePinned;
  }, [pinnedProducts, props.selectedIds]);

  return (
    <>
      {view === "list" && (
        <SortableListView
          isLoading={isLoading}
          onPinClick={onPinClick}
          onHidingClick={onHidingClick}
          onAiSortedClick={onAiSortedClick}
          onPinToBeginning={onPinToBeginning}
          onDragEnd={onDragEnd}
          onSelectClick={onSelectClick}
          selectedIds={selectedIds}
          onRemoveClick={onRemoveClick}
          disableDrag={disableDrag}
          aiSorted={props.aiSorted}
        />
      )}
      {view === "grid" && (
        <SortableGridView
          isLoading={isLoading}
          onPinClick={onPinClick}
          onHidingClick={onHidingClick}
          onAiSortedClick={onAiSortedClick}
          onPinToBeginning={onPinToBeginning}
          onDragEnd={onDragEnd}
          onSelectClick={onSelectClick}
          selectedIds={selectedIds}
          onRemoveClick={onRemoveClick}
          disableDrag={disableDrag}
          columns={columns}
          aiSorted={props.aiSorted}
        />
      )}
    </>
  );
};

import { Link } from "react-router-dom";
import { getPath, ROUTES } from "src/router/Routes";
import { useTrackPageView } from "../helpers/hooks/mix/useSegment";

const NotFound = () => {
  useTrackPageView("404");

  return (
    <>
      <div className="container-xl px-4 animated--fade-in-up">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="">
              <h1
                className=""
                style={{
                  color: "rgba(41,59,155,.03)",
                  fontSize: "240px",
                }}
              >
                404
              </h1>
              <h2 className="mb-5" style={{ fontSize: "1.7rem" }}>
                The page you're looking for isn't available.
              </h2>
              <p className="fs-5">
                {" "}
                No worries though! At Depict we are experts in recommendations.
                <br />
                We suggest you try{" "}
                <Link
                  to={getPath(ROUTES.HOME)}
                  className="link-dark text-decoration-underline"
                >
                  go back home
                </Link>{" "}
                or{" "}
                <a
                  className="link-dark text-decoration-underline"
                  href="mailto:support@depict.ai"
                >
                  get in contact
                </a>{" "}
                with us if you experienced any problem with our Portal.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;

import { useCallback } from "react";
import { saveOnServer } from "src/helpers/configuration";
import { getErrorMessage, getAlertMessage } from "src/helpers/errors";

import {
  AiState,
  ControlLoadingQueueCB,
  SaveConfigurationCB,
} from "src/types/components";
import { Configuration, ConfiguredProductGroup } from "src/types/configuration";
import { useAuth0 } from "@auth0/auth0-react";
import useAuthentication from "../app/useAuthentication";
import { useAlerts } from "../../../components/storybook/Alert/useAlerts";

export default function useSaveConfiguration(
  merchant: string | null,
  market: string | null,
  refreshConfigurations: Function,
  controlLoadingQueueCB: ControlLoadingQueueCB
): [SaveConfigurationCB] {
  const { user } = useAuth0();
  const { addAlert } = useAlerts();
  const [getAccessToken] = useAuthentication();

  const saveConfiguration = useCallback(
    async (
      configuration: Configuration | null,
      groups: ConfiguredProductGroup[] | null,
      aiState: AiState | null
    ) => {
      if (!merchant || !market || !configuration || !groups || !aiState) {
        const message = getErrorMessage("save-configuration");
        if (message) {
          addAlert(message);
        }
        return;
      }

      controlLoadingQueueCB("save-configuration", "push");

      const id_token = await getAccessToken();

      let result = await saveOnServer(
        groups,
        configuration,
        merchant,
        market,
        aiState,
        id_token,
        user?.email
      );

      if (!result) {
        console.log("[CONFIGURATION] Save configuration failed.");
        const message = getErrorMessage("save-configuration");
        if (message) {
          addAlert(message);
        }
        controlLoadingQueueCB("save-configuration", "remove");
        return;
      }

      if (!result.success) {
        console.log("[CONFIGURATION] Saving configuration failed.");
        let message = await getAlertMessage(result.error);
        addAlert(message);
        controlLoadingQueueCB("save-configuration", "remove");
        return;
      }

      refreshConfigurations();
      controlLoadingQueueCB("save-configuration", "remove");
    },
    [
      merchant,
      market,
      controlLoadingQueueCB,
      getAccessToken,
      user?.email,
      refreshConfigurations,
      addAlert,
    ]
  );

  return [saveConfiguration];
}

import SelectPlatform, {
  Platform,
} from "../storybook/Integrations/Onboarding/SelectPlatform/SelectPlatform";
import { useEffect, useState } from "react";
import CentraIntegrationSteps from "./centra/CentraIntegrationSteps";
import ShopifyIntegrationSteps from "./shopify/ShopifyIntegrationSteps";
import CustomFeed from "../storybook/Integrations/CustomFeed/CustomFeed";
import { memo } from "react";
import { useLocation } from "react-router-dom";

function OnboardingIntegration({
  merchant,
  onBack,
  onSuccess,
}: {
  merchant: string;
  onBack: () => void;
  onSuccess: () => void;
}) {
  const [selectedPlatform, setSelectedPlatform] = useState<Platform>();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const platform = params.get("platform");

  useEffect(() => {
    if (platform && Object.values(Platform).includes(platform as Platform)) {
      setSelectedPlatform(platform as Platform);
    }
  }, [platform]);

  switch (selectedPlatform) {
    case Platform.CENTRA:
      return (
        <CentraIntegrationSteps
          merchant={merchant}
          onClose={() => {
            setSelectedPlatform(undefined);
          }}
          onSuccess={onSuccess}
        />
      );
    case Platform.SHOPIFY:
      return (
        <ShopifyIntegrationSteps
          merchant={merchant}
          onClose={() => {
            setSelectedPlatform(undefined);
          }}
          onSuccess={onSuccess}
        />
      );
    case Platform.CUSTOM:
      return <CustomFeed />;
    default:
      return (
        <SelectPlatform
          selectedPlatform={selectedPlatform}
          onBack={() => {
            onBack();
          }}
          onSelectPlatform={(platform) => setSelectedPlatform(platform)}
        />
      );
  }
}

export default memo(OnboardingIntegration);

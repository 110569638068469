import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { attributeIdToName } from "../../util";

export default function AddOptionalAttribute({
  availableOptionalAttributeIds,
  alreadyUsedOptionalAttributeIds,
  onAdd,
}: {
  availableOptionalAttributeIds: string[];
  alreadyUsedOptionalAttributeIds: string[];
  onAdd: (attributeId: string) => void;
}) {
  return (
    <Row className="mt-3">
      <Col>
        <Select
          value={null}
          options={availableOptionalAttributeIds
            .filter((attr) => !alreadyUsedOptionalAttributeIds.includes(attr))
            .map((attr) => ({
              label: attributeIdToName(attr),
              value: attr,
            }))}
          onChange={(option) => {
            if (option) {
              onAdd(option.value);
            }
          }}
          placeholder="Add optional attribute"
        />
      </Col>
    </Row>
  );
}

import React, { useEffect } from "react";
import { useMaintenance } from "../helpers/hooks/app/useMaintenance";

export const Maintenance = () => {
  const { maintenanceInfo, isLoading } = useMaintenance();

  useEffect(() => {
    if (maintenanceInfo?.status === "LIVE") {
      const host = window.location.host;
      // Using window location replace instead of navigate because I don't trust navigate to actually refresh the page
      window.location.replace(`https://${host}/login`);
    }
  }, [maintenanceInfo?.status]);

  return (
    <>
      <div className="container-xl px-4 animated--fade-in-up">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="">
              <h1
                className=""
                style={{
                  color: "rgba(41,59,155,.10)",
                  fontSize: "120px",
                }}
              >
                Maintenance
              </h1>
              <h2 className="mb-5" style={{ fontSize: "1.7rem" }}>
                We are upgrading our systems making them better than ever!
              </h2>
              {!isLoading && maintenanceInfo?.end && (
                <p className="fs-5">
                  Expected resolution time:{" "}
                  {new Date(maintenanceInfo.end).toLocaleString()}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Maintenance;

import { Button, Col, Row, Stack } from "react-bootstrap";
import SelectorButton from "../../../SelectorButton/SelectorButton";
import { CentraLogo, ShopifyLogo } from "../../IntegrationCard/icons";
import Custom from "src/assets/img/custom.svg";
import { useState } from "react";
import Card from "../../../Card/Card";
import IntegrationStepHeader from "../../IntegrationStepHeader/IntegrationStepHeader";

export enum Platform {
  SHOPIFY = "shopify",
  CENTRA = "centra",
  CUSTOM = "custom",
}

export default function SelectPlatform({
  selectedPlatform,
  onBack,
  onSelectPlatform,
}: {
  selectedPlatform?: Platform;
  onBack: () => void;
  onSelectPlatform: (platform: Platform) => void;
}) {
  const [newPlatform, setNewPlatform] = useState<Platform | undefined>(
    selectedPlatform
  );
  return (
    <Card>
      <Card.Body>
        <IntegrationStepHeader
          title="Select e-commerce platform"
          stepNumber={1}
        />
        <Card.Text>
          Select the platform or data source you use to connect your product
          data to Depict. Connecting your data allows Depict to generate
          recommendations, display product listing pages and power search.
        </Card.Text>
        <Stack gap={3} className="mt-5">
          <SelectorButton
            checked={newPlatform === Platform.CENTRA}
            onClick={() => {
              setNewPlatform(Platform.CENTRA);
            }}
          >
            <div>
              <CentraLogo />
              <span className="mx-2 fw-bolder">Centra</span>
            </div>
          </SelectorButton>
          <SelectorButton
            checked={newPlatform === Platform.SHOPIFY}
            onClick={() => {
              setNewPlatform(Platform.SHOPIFY);
            }}
          >
            <div>
              <ShopifyLogo />
              <span className="mx-2 fw-bolder">Shopify</span>
            </div>
          </SelectorButton>
          <SelectorButton
            checked={newPlatform === Platform.CUSTOM}
            onClick={() => {
              setNewPlatform(Platform.CUSTOM);
            }}
          >
            <div>
              <img src={Custom} width={40} height={40} alt="custom feed" />
              <span className="mx-2 fw-bolder">Custom feed</span>
            </div>
          </SelectorButton>
        </Stack>
      </Card.Body>
      <Card.Footer>
        <Row>
          <Col>
            <Button
              className="w-100"
              variant="outline-secondary"
              onClick={() => {
                onBack();
              }}
            >
              Back
            </Button>
          </Col>
          <Col>
            <Button
              className="w-100"
              disabled={!newPlatform}
              onClick={() => {
                if (newPlatform) {
                  onSelectPlatform(newPlatform);
                }
              }}
            >
              Continue
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
}

import { ArrowSquareOut, Check, Trash } from "@phosphor-icons/react";
import { Button } from "react-bootstrap";
import { theme } from "../designSystemVariables";

import { useState } from "react";
import { MerchantContent } from "src/api/types";

import { css } from "@emotion/react";
import { AutoSaveInput } from "../AutoSaveInput/AutoSaveInput";
import ContentKeywords from "./ContentKeywords";

type ContentItemRowProps = {
  item: MerchantContent;
  onUpdate: (item: MerchantContent) => void;
  onDelete: (item: MerchantContent) => void;
  isNewContent: boolean | null;
  setIsAddingContent: (isAddingContent: boolean) => void;
};

function ContentItemRow(props: ContentItemRowProps) {
  const { item, onUpdate, setIsAddingContent } = props;

  const isNewContent = props.isNewContent || false;
  const [newItem, setNewItem] = useState<MerchantContent>(item);

  return (
    <tr
      className="border-gray-300 display-block"
      css={css`
        :last-child td {
          border-bottom: none !important;
        }
      `}
    >
      <td className="text-start w-100" style={{ width: "0" }} colSpan={3}>
        <AutoSaveInput
          placeholder="Frequently asked questions"
          saveOnChange={isNewContent}
          initialValue={item.title}
          onSave={(value) => {
            if (isNewContent) {
              // For new content we don't want to upsert the content until
              // the user presses the "Add" button.
              setNewItem({
                ...newItem,
                title: value,
              });
            } else {
              onUpdate({
                ...item,
                title: value,
              });
            }
          }}
        />
        <div
          style={{
            display: "flex",
            marginTop: "0.4em",
            alignItems: "center",
          }}
        >
          <AutoSaveInput
            placeholder="https://example.com/frequently-asked-questions"
            saveOnChange={isNewContent}
            initialValue={item.uri}
            style={{
              color: theme.textIcon.inverse.disabled,
            }}
            onSave={(value) => {
              if (isNewContent) {
                // For new content we don't want to upsert the content until
                // the user presses the "Add" button.
                setNewItem({
                  ...newItem,
                  uri: value,
                });
              } else {
                onUpdate({
                  ...newItem,
                  uri: value,
                });
              }
            }}
          />
        </div>
      </td>
      {isNewContent && (
        <>
          <td />
          <td>
            <Button
              variant={"outline-secondary"}
              style={{ padding: "6px" }}
              onClick={() => {
                if (!newItem.title || !newItem.uri) {
                  return;
                }

                onUpdate(newItem);
                setIsAddingContent(false);
              }}
              disabled={newItem.title.length < 3 || newItem.uri.length < 3}
            >
              <Check size={16} />
            </Button>
          </td>
        </>
      )}
      {!isNewContent && (
        <>
          <td
            className="text-start w-100"
            style={{
              minWidth: "500px",
              overflowWrap: "break-word",
              whiteSpace: "normal",
              flexWrap: "wrap",
              gap: "0.5em",
              alignItems: "center",
            }}
          >
            <ContentKeywords
              keywords={item.keywords}
              setKeywords={(keywords: string[]) => {
                onUpdate({
                  ...item,
                  keywords,
                });
              }}
            />
          </td>
          <td>
            <a
              href={item.uri}
              target="_blank"
              rel="noreferrer"
              style={{
                color: theme.textIcon.base.pressed,
                marginRight: "0.3em",
              }}
              tabIndex={-1}
            >
              <ArrowSquareOut size={16} />
            </a>
            <Button
              variant="link"
              style={{
                padding: "6px",
                color: theme.textIcon.base.pressed,
              }}
              onClick={() => {
                props.onDelete(item);
              }}
            >
              <Trash size={16} />
            </Button>
          </td>
        </>
      )}
    </tr>
  );
}

export default ContentItemRow;

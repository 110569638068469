import React from "react";
import { theme } from "../designSystemVariables";
import { ProductMetadata } from "../../../helpers/catalog/ProductMetadataProvider";

export const metadataStyles: Record<
  NonNullable<ProductMetadata["type"]>[number],
  React.CSSProperties
> = {
  on_sale: {
    backgroundColor: "#D43E3E",
    color: theme.textIcon.inverse.default,
  },
  low_stock: {
    backgroundColor: theme.background.warning.default,
    color: theme.textIcon.warning.default,
  },
  out_of_stock: {
    backgroundColor: theme.background.subtle.default,
    color: theme.textIcon.subtle.default,
  },
  inactive: {
    backgroundColor: "#FAD2D5",
    color: "#92363E",
  },
  new_in: {
    backgroundColor: "#3018C1",
    color: theme.textIcon.inverse.default,
  },
  bestseller: {
    backgroundColor: "#DC9100",
    color: theme.textIcon.inverse.default,
  },
  trending: {
    backgroundColor: "#388438",
    color: theme.textIcon.inverse.default,
  },
  slow_mover: {
    backgroundColor: "#000000",
    color: theme.textIcon.inverse.default,
  },
};

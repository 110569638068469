import { ParsingMethod } from "../../Integrations/ParsingMethodSelector/types";
import { z } from "zod";

export type CentraCredentialsVerifcationResponse = {
  status: "success" | "failed";
  errors: {
    apiUrlError: string | null;
    apiKeyError: string | null;
  };
};

type CentraMarket = {
  id: string;
  name: string;
};

export type CentraStore = {
  id: string;
  name: string;
  markets: CentraMarket[];
};

export type CentraMappedAttributeDefinition = {
  attributeType: string;
  elementKeys: string[];
};

const CentraAttributeParsingConfigSchema = z.object({
  type: z.literal(ParsingMethod.Map),
  attributeType: z.string(),
  elementKey: z.string(),
});

const CentraConstantParsingConfigSchema = z.object({
  type: z.literal(ParsingMethod.Constant),
  value: z.string().optional(),
});

export const CentraParsingConfigSchema = z.union([
  CentraAttributeParsingConfigSchema,
  CentraConstantParsingConfigSchema,
]);

export const CentraOptionalAttributeParsingConfigSchema = z.intersection(
  CentraParsingConfigSchema,
  z.object({
    isOptional: z.literal(true),
    attributeId: z.string(),
  })
);

export type CentraParsingConfig = z.infer<typeof CentraParsingConfigSchema>;
export type CentraOptionalParsingConfig = z.infer<
  typeof CentraOptionalAttributeParsingConfigSchema
>;
export type PartialCentraParsingConfig = Partial<CentraParsingConfig>;
export type PartialOptionalCentraParsingConfig =
  Partial<CentraOptionalParsingConfig> & {
    isOptional: true;
    attributeId: string;
  };

import { Accordion, Alert, Form } from "react-bootstrap";
import { CentraStore } from "../../Integrations/Centra/types";

export default function CentraMarketsConfigForm({
  error,
  isLoading,
  availableStores,
  chosenMarketIds,
  viewedStoreId,
  onSetChosenMarketIds,
  setViewedStoreId,
}: {
  error: string | null;
  isLoading: boolean;
  availableStores: CentraStore[];
  chosenMarketIds: string[];
  viewedStoreId: string | null;
  onSetChosenMarketIds: (marketIds: string[]) => void;
  setViewedStoreId: (storeId: string | null) => void;
}) {
  const chosenStoreId = availableStores.find((s) =>
    s.markets.some((m) => chosenMarketIds.includes(m.id))
  )?.id;

  return (
    <>
      {error ? (
        <Alert variant="danger">{error}</Alert>
      ) : isLoading ? (
        <Alert variant="light">Getting your centra markets...</Alert>
      ) : (
        <Accordion activeKey={viewedStoreId}>
          {availableStores.map((store) => (
            <Accordion.Item eventKey={store.id} key={store.id}>
              <Accordion.Header
                style={{ lineHeight: "inherit" }}
                onClick={() => {
                  if (viewedStoreId === store.id) {
                    setViewedStoreId(null); // Close the accordion item if already active
                  } else {
                    setViewedStoreId(store.id); // Set the activeKey to the clicked store id
                  }
                }}
              >
                <Form.Check
                  type="radio"
                  label={store.name}
                  checked={chosenStoreId === store.id}
                  onClick={(e) => {
                    if (
                      chosenStoreId != store.id &&
                      viewedStoreId === store.id
                    ) {
                      e.stopPropagation();
                    }
                    setViewedStoreId(store.id);
                  }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      onSetChosenMarketIds(store.markets.map((m) => m.id));
                    }
                  }}
                />
              </Accordion.Header>
              <Accordion.Body>
                <Form.Group>
                  {store.markets.map((market) => (
                    <Form.Check
                      key={market.id}
                      type="checkbox"
                      label={market.name}
                      checked={chosenMarketIds.includes(market.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          onSetChosenMarketIds([
                            ...chosenMarketIds.filter((m) =>
                              store.markets.map((m) => m.id).includes(m)
                            ),
                            market.id,
                          ]);
                        } else {
                          onSetChosenMarketIds(
                            chosenMarketIds.filter((m) => m !== market.id)
                          );
                        }
                      }}
                    />
                  ))}
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
    </>
  );
}

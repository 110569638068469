import { useTranslation } from "react-i18next";
import { SplitBySurfaceProps } from "src/types/components";
import { useCallback } from "react";
import { useTrackEvent } from "../../helpers/hooks/mix/useSegment";
import { SwitchButton } from "../storybook/SwitchButton/SwitchButton";

const SplitBySurface = (props: SplitBySurfaceProps) => {
  const trackEvent = useTrackEvent("SplitBySurface");
  const { splitBySurface, setSplitBySurfaceCB } = props;
  const { t } = useTranslation();

  const splitBySurfaceOnChange = useCallback(() => {
    const newValue = !splitBySurface;
    setSplitBySurfaceCB(newValue);
    trackEvent({ changedTo: newValue });
  }, [setSplitBySurfaceCB, splitBySurface]);

  return (
    <>
      <label htmlFor="split-by-surface" className="form-label invisible">
        Surfaces
      </label>
      <div
        className="form-check form-switch"
        data-bs-toggle="tooltip"
        data-bs-title={t("tooltips.splitBySurface.active")}
      >
        <SwitchButton
          checked={splitBySurface}
          onChange={splitBySurfaceOnChange}
          id="split-by-surface"
          name="split-by-surface"
        />
        <label className="form-check-label" htmlFor="split-by-surface">
          Surfaces
        </label>
      </div>
    </>
  );
};

export default SplitBySurface;

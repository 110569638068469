import { BoostRule } from "src/api/types";

export type BoostBuryRulesState = {
  boostRules: BoostRule[];
  buryRules: BoostRule[];
};

export interface LocalBoostBuryManagement {
  addedRules: BoostRule[];
  removedRules: BoostRule[];
  updatedRules: [BoostRule, BoostRule][];
}

export interface State {
  /*
   * The local state
   */
  local: BoostBuryRulesState;

  /*
   * The server state (last time we got it from the server)
   */
  server: BoostBuryRulesState;

  localBoostBuryManagement: LocalBoostBuryManagement;
}

export enum ActionType {
  INIT_FROM_SERVER = "INIT_FROM_SERVER",
  SET_SERVER_TO_LOCAL = "SET_SERVER_TO_LOCAL",
  SET_LOCAL_TO_SERVER = "SET_LOCAL_TO_SERVER",
  REMOVE_RULE = "REMOVE_RULE",
  ADD_RULE = "ADD_RULE",
  UPDATE_RULE = "UPDATE_RULE",
}

export interface InitAction {
  type: ActionType.INIT_FROM_SERVER;
  payload: {
    rules: BoostRule[];
  };
}

export interface SetServerToLocalAction {
  type: ActionType.SET_SERVER_TO_LOCAL;
  payload: {};
}

export interface SetLocalToServerAction {
  type: ActionType.SET_LOCAL_TO_SERVER;
  payload: {};
}

export interface RemoveRuleAction {
  type: ActionType.REMOVE_RULE;
  payload: {
    rule: BoostRule;
  };
}

export interface AddRuleAction {
  type: ActionType.ADD_RULE;
  payload: {
    rule: BoostRule;
  };
}

export interface UpdateRuleAction {
  type: ActionType.UPDATE_RULE;
  payload: {
    oldRule: BoostRule;
    newRule: BoostRule;
  };
}

export type Action =
  | InitAction
  | SetServerToLocalAction
  | SetLocalToServerAction
  | RemoveRuleAction
  | AddRuleAction
  | UpdateRuleAction;

import { deepEqual } from "src/helpers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { deepCopy } from "src/helpers";
import { Configuration } from "src/types/configuration";
import { getUpdatedConfiguration } from "src/helpers/configuration";
import { PutConfigurationsApiParams } from "src/types/api";
import { ConfigurationModifyCB } from "src/types/components";

type UseLocalConfiguration = [
  local: Configuration | null,
  modify: ConfigurationModifyCB,
  restore: Function,
  hasChanges: boolean
];

export default function useLocalConfiguration(
  configuration: Configuration | null
): UseLocalConfiguration {
  const [local, set] = useState<Configuration | null>(null);
  const [backup, setBackup] = useState<Configuration | null>(null);

  useEffect(() => {
    console.log(
      `%c[CONFIGURATION] Creating current configuration backup.`,
      "color: tomato"
    );

    set(deepCopy(configuration));
    setBackup(deepCopy(configuration));

    return () => {
      set(null);
      setBackup(null);
    };
  }, [configuration]);

  const hasChanges = useMemo(() => {
    return !deepEqual(local, backup);
  }, [local, backup]);

  const restore = useCallback(() => {
    if (!backup) {
      return;
    }

    set(deepCopy(backup));
  }, [backup]);

  const modify = useCallback(
    (data: PutConfigurationsApiParams) => {
      console.log(
        `%c[CONFIGURATION] Updating configuration with id ${local?.id}`,
        "color: crimson",
        data
      );

      if (!local) {
        return null;
      }

      set((prevValue: Configuration | null) => {
        let updatedConfiguration = getUpdatedConfiguration(
          deepCopy(prevValue as Configuration),
          data
        );
        return updatedConfiguration;
      });

      return local;
    },
    [local]
  );

  return [local, modify, restore, hasChanges];
}

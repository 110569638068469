import {
  closestCenter,
  CollisionDetection,
  DragOverEvent,
  rectIntersection,
  UniqueIdentifier,
} from "@dnd-kit/core";
import { useCallback, useEffect, useRef } from "react";
import { findContainer, getIndexForId } from "src/helpers/dnd";
import { ContainersItems } from "src/types/dnd";

type UseOnDragOverProps = {
  items: ContainersItems | null;
  onDragOverCB?: (items: ContainersItems | null) => any;
  activeId: UniqueIdentifier | null;
};

type UseOnDragOver = [
  onDragOver: (event: DragOverEvent) => void,
  collisionDetectionStrategy:
    | ((event: DragOverEvent) => void)
    | CollisionDetection
];

export default function useOnDragOver(
  props: UseOnDragOverProps
): UseOnDragOver {
  const { items, onDragOverCB, activeId } = props;
  const lastOverId = useRef<UniqueIdentifier | null>(null);
  const recentlyMovedToNewContainer = useRef(false);

  const onDragOver = useCallback(
    (event: DragOverEvent) => {
      const { active, over } = event;
      const overId = over?.id;

      if (!items) {
        return;
      }

      if (!overId || active.id in items) {
        return;
      }

      const overContainer = findContainer(overId, items);
      const activeContainer = findContainer(active.id, items);

      if (!overContainer || !activeContainer) {
        return;
      }

      if (activeContainer === overContainer) {
        return;
      }

      const activeItems = items[activeContainer];
      const overItems = items[overContainer];
      const overIndex = getIndexForId(overId, overItems);
      const activeIndex = getIndexForId(active.id, activeItems);
      let newIndex: number;

      if (overId in items) {
        //the case in which the item is dragged over the panel and not its items (ideally at the end of the list)
        //In that case the index of the item is goign to be the last index of the container + 1
        newIndex = overItems ? overItems.length + 1 : 0;
      } else {
        //here we are hovering over an item of the container
        const isBelowOverItem =
          over &&
          active.rect.current.translated &&
          //@ts-ignore
          active.rect.current.translated.offsetTop >
            //@ts-ignore
            over.rect.offsetTop + over.rect.height;

        const modifier = isBelowOverItem ? 1 : 0;

        newIndex =
          overIndex >= 0
            ? overIndex + modifier
            : overItems
            ? overItems.length + 1
            : 0;
      }

      recentlyMovedToNewContainer.current = true;

      let refactoredItems = {
        ...items,
        [activeContainer]: (items[activeContainer] ?? []).filter(
          (item) => item.id !== active.id
        ),
        [overContainer]: [
          ...(items[overContainer] ?? []).slice(0, newIndex),
          (items[activeContainer] ?? [])[activeIndex],
          ...(items[overContainer] ?? []).slice(
            newIndex,
            (items[overContainer] ?? []).length
          ),
        ],
      };

      if (onDragOverCB) {
        onDragOverCB(refactoredItems);
      }
    },
    [items, onDragOverCB, recentlyMovedToNewContainer]
  );

  //@ts-ignore
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args: any) => {
      // Start by finding any intersecting droppable
      let overId = rectIntersection(args);

      if (!items) {
        return overId;
      }

      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container: any) => container.id in items
          ),
        });
      }

      if (overId != null) {
        //@ts-ignore
        if (overId in items) {
          //@ts-ignore
          const containerItems = items[overId];

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container: any) =>
                  //@ts-ignore
                  container.id !== overId &&
                  containerItems.includes(container.id)
              ),
            });
          }
        }

        //@ts-ignore
        lastOverId.current = overId;

        return overId;
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current;
    },
    [activeId, items]
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  return [onDragOver, collisionDetectionStrategy];
}

import { Placeholder } from "react-bootstrap";

export function RuleCardPlaceholder() {
  return (
    <Placeholder animation="glow">
      <Placeholder
        className="py-2 px-3 rounded  d-flex justify-content-between align-items-center"
        style={{
          height: 60,
        }}
      >
        <Placeholder className="d-flex align-items-center">
          <Placeholder sm={1} className=" gap-3 " style={{ flex: 1 }} />
        </Placeholder>
      </Placeholder>
    </Placeholder>
  );
}

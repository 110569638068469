import { Contract2, Expand2 } from "src/components/linearicons";

const ExpandIcon = () => {
  return (
    <div className="depict-miniGraphIcon">
      <div className="depict-miniGraphIcon__expand">
        <Expand2 size="12" />
      </div>
      <div className="depict-miniGraphIcon__minimize">
        <Contract2 size="12" />
      </div>
    </div>
  );
};

export default ExpandIcon;

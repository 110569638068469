import { css } from "@emotion/react";

export const iconSize = "1.25rem";
export const innerIconSize = "1rem";

const containerCss = css`
  &:hover {
    cursor: pointer;
  }
`;
const iconContainerCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${iconSize};
  height: ${iconSize};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const innerIconCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${innerIconSize};
  height: ${innerIconSize};
  border-radius: 50%;
`;
const iconCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
interface IconContainerProps {
  onClick?: () => void;
  children: React.ReactNode;
}
export function IconContainer(props: IconContainerProps) {
  return (
    <div css={containerCss} onClick={props.onClick}>
      <div css={iconContainerCss}>
        <div css={innerIconCss}>
          <div css={iconCss}>{props.children}</div>
        </div>
      </div>
    </div>
  );
}

import { useMemo } from "react";
import {
  ConfiguredProductGroup,
  GroupIdObj,
  MainProductIdObj,
  ProductIdObj,
} from "src/types/configuration";

export default function useAlreadySelected(props: {
  fromGroup: ConfiguredProductGroup | null;
  includeGroup: ConfiguredProductGroup | null;
  excludeGroup: ConfiguredProductGroup | null;
  getObjKey: Function;
}) {
  const { fromGroup, includeGroup, excludeGroup, getObjKey } = props;

  const alreadySelected: string[] = useMemo(() => {
    const from = fromGroup?.group_definition || [];
    const include = includeGroup?.group_definition || [];
    const exclude = excludeGroup?.group_definition || [];
    const result: string[] = [];

    from
      .concat(include, exclude)
      .forEach((obj: MainProductIdObj | ProductIdObj | GroupIdObj) => {
        const objKey = getObjKey(obj as any);
        if (typeof objKey !== "undefined") {
          result.push(objKey);
        }
      });

    return result;
  }, [
    getObjKey,
    fromGroup?.group_definition,
    includeGroup?.group_definition,
    excludeGroup?.group_definition,
  ]);

  return [alreadySelected];
}

import { useCallback } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate } from "react-router-dom";
import { CollectionType, Merchant } from "src/api/types";
import useAuthorizedApi from "src/helpers/hooks/app/useAuthorizedApi";
import {
  ALLOWED_SCOLLECTION_TYPES_FOR_EDITING,
  useListingEditorContext,
} from "../../contexts/ListingTreeSelectorContext";
import { useCreateSCollectionMutation } from "../../helpers/categories/categoryV3.helper";
import { getNextListingName } from "../../helpers/categories/getNextListingName";
import useListingItems from "../../helpers/hooks/useListingItems";
import useScollections from "../../helpers/hooks/useScollections";
import { OnAddListingItem } from "../navs/types";
import ListingTreeMenu from "../storybook/ListingTreeMenu/ListingTreeMenu";

export default function ListingTree({ merchant }: { merchant: Merchant }) {
  const {
    listingItems,
    isLoading: listingItemsAreLoading,
    currentListingItemId,
    refetch,
  } = useListingItems();

  const { onUpdateListingType, onUpdateParentId, onUpdateListingMainProducts } =
    useScollections();

  const {
    inSelectMode,
    selectedListingItemIds,
    indeterminateListingItemIds,
    selectedMainProductIds,
  } = useListingEditorContext(merchant.id);

  const navigate = useNavigate();

  const { api } = useAuthorizedApi();

  const createScollectionMutation = useCreateSCollectionMutation(
    api,
    merchant?.id ?? ""
  );

  const onAddListingItem = useCallback<OnAddListingItem>(
    async (type) => {
      if (type === "campaign") {
        const returned = await createScollectionMutation.mutateAsync({
          title: getNextListingName(listingItems, "campaign"),
          type: "campaign",
        });
        const categoryId = returned?.collection_id;
        navigate(`/${merchant?.id}/categories-v3/${categoryId}`);
        await refetch();
      } else if (type === "category") {
        const returned = await createScollectionMutation.mutateAsync({
          title: getNextListingName(listingItems, "category"),
          type: "category",
        });

        const categoryId = returned?.collection_id;

        if (!categoryId) {
          throw new Error("Category ID is null, should never happen");
        }

        navigate(`/${merchant?.id}/categories-v3/${categoryId}`);
        await refetch();
      }
    },
    [createScollectionMutation, listingItems, merchant?.id, navigate, refetch]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <ListingTreeMenu
        onSetListingType={(listingType, listingItemId) =>
          onUpdateListingType(listingItemId, listingType)
        }
        editableListingTypes={
          merchant.access_categories_v3
            ? ALLOWED_SCOLLECTION_TYPES_FOR_EDITING
            : []
        }
        onSetParentId={(listingItemId, parentId) =>
          onUpdateParentId(listingItemId, parentId)
        }
        isLoading={listingItemsAreLoading}
        listingItems={listingItems ?? []}
        currentListingItemId={currentListingItemId ?? undefined}
        onAddListingItem={onAddListingItem}
        inSelectMode={inSelectMode}
        selectedListingItemIds={selectedListingItemIds}
        onChangeSelectedListingItemIds={(checked, listingItemIds) => {
          onUpdateListingMainProducts(
            listingItemIds,
            selectedMainProductIds,
            !checked
          );
        }}
        indeterminateListingItemIds={indeterminateListingItemIds}
      />
    </DndProvider>
  );
}

import { RefObject, createContext, useMemo, useRef, useState } from "react";

interface BulkEditBarState {
  elementToHookToRef: RefObject<HTMLDivElement>;
  setShortcutsDisabled: (value: boolean) => void;
  shortcutsDisabled: boolean;
}

interface BulkEditBarProviderProps {
  children: React.ReactNode;
}
export const BulkEditBarContext = createContext<BulkEditBarState>({
  elementToHookToRef: { current: null },
  setShortcutsDisabled: () => {},
  shortcutsDisabled: false,
});

const BulkEditBarProvider = ({ children }: BulkEditBarProviderProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [shortcutsDisabled, setShortcutsDisabled] = useState(false);

  const value = useMemo<BulkEditBarState>(() => {
    return {
      elementToHookToRef: containerRef,
      setShortcutsDisabled,
      shortcutsDisabled,
    };
  }, [shortcutsDisabled]);

  return (
    <BulkEditBarContext.Provider value={value}>
      {children}
    </BulkEditBarContext.Provider>
  );
};

export default BulkEditBarProvider;

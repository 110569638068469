 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Teacup = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M17.733 12.649c-0.188-0.314-0.475-0.523-0.829-0.604-0.37-0.085-0.769-0.017-1.117 0.096 0.137-0.742 0.189-1.299 0.205-1.516 0.005-0.044 0.007-0.085 0.007-0.125 0-0.006-0-0.012-0.001-0.019-0-0.002-0-0.005-0-0.007-0.016-0.702-0.666-1.28-1.934-1.719-1.059-0.367-2.524-0.62-4.125-0.714-0.276-0.016-0.512 0.194-0.528 0.47s0.194 0.512 0.47 0.528c1.512 0.088 2.881 0.323 3.856 0.661 1.005 0.348 1.25 0.688 1.262 0.792-0.001 0.007-0.001 0.016-0.002 0.027-0.030 0.139-0.374 0.54-1.685 0.914-1.279 0.365-2.988 0.567-4.812 0.567s-3.534-0.201-4.812-0.567c-1.311-0.375-1.655-0.776-1.685-0.914-0.001-0.011-0.002-0.020-0.002-0.028 0.021-0.231 0.88-0.939 3.57-1.304 0.274-0.037 0.465-0.289 0.428-0.563s-0.289-0.465-0.563-0.428c-1.226 0.166-2.272 0.43-3.025 0.763-0.924 0.409-1.399 0.919-1.411 1.516-0 0.001-0 0.002-0 0.003-0 0.007-0.001 0.014-0.001 0.021 0 0.039 0.002 0.081 0.007 0.125 0.035 0.467 0.233 2.503 1.094 4.568 1.311 3.145 3.523 4.808 6.399 4.808 1.845 0 3.418-0.685 4.654-2.006 0.046-0.007 0.091-0.020 0.135-0.040 0.040-0.019 0.993-0.463 2.029-1.138 1.451-0.945 2.319-1.857 2.581-2.71 0.157-0.511 0.1-1.014-0.166-1.456zM8.5 19c-2.468 0-4.307-1.403-5.467-4.171-0.434-1.037-0.69-2.084-0.839-2.891 0.337 0.169 0.745 0.322 1.218 0.457 1.366 0.39 3.172 0.605 5.087 0.605s3.722-0.215 5.087-0.605c0.473-0.135 0.881-0.288 1.218-0.457-0.148 0.808-0.404 1.855-0.839 2.891-1.16 2.767-2.999 4.171-5.467 4.171zM16.942 13.812c-0.185 0.604-0.953 1.371-2.161 2.159-0.121 0.079-0.241 0.155-0.359 0.227 0.17-0.316 0.329-0.652 0.477-1.006 0.263-0.631 0.464-1.258 0.617-1.84 0.299-0.172 0.838-0.408 1.164-0.333 0.090 0.021 0.146 0.062 0.195 0.144 0.12 0.2 0.142 0.407 0.067 0.648z" fill={color}></path>
<path d="M9.494 11c0.008 0 0.014-0 0.016-0 0.276-0.006 0.497-0.234 0.491-0.51s-0.233-0.496-0.509-0.49c-0.007 0-0.688 0.001-1.117-0.425-0.249-0.247-0.376-0.609-0.376-1.075 0-1.44 0.359-2.096 0.74-2.791 0.374-0.682 0.76-1.388 0.76-2.709 0-1.6-0.53-2.678-0.553-2.724-0.124-0.247-0.424-0.347-0.671-0.224s-0.347 0.424-0.224 0.671c0.004 0.009 0.447 0.91 0.447 2.276 0 1.065-0.295 1.604-0.637 2.229-0.404 0.738-0.863 1.575-0.863 3.271 0 0.748 0.229 1.352 0.681 1.794 0.694 0.68 1.671 0.706 1.814 0.706z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Teacup); 
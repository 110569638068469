import { Outlet } from "react-router-dom";
import Footer from "src/components/footer/Footer";
import "src/assets/scss/depict/layouts/authentication.scss";

import "src/assets/scss/depict/layouts/error.scss";

const ErrorLayout = () => {
  return (
    <>
      <div id="layoutError">
        <div id="layoutError_content">
          <main className="d-flex flex-column flex-grow-1 justify-content-center">
            <Outlet />
          </main>
        </div>
        <div id="layoutError_footer">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default ErrorLayout;

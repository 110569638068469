import React from "react";
import reactFastCompare from "react-fast-compare";
import mergeClassNames from "merge-class-names";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row } from "src/types/table";
import { TableProps } from "src/types/components";
import CsvDownload from "src/components/csv/CsvDownload";

import DropDown from "src/components/mix/DropDown";
import { ChevronDown, Ellipsis } from "src/components/linearicons";

import { tableHeaders } from "src/helpers/table";
import useTableData from "src/helpers/hooks/table/useTableData";
import { getPeriodFromUi } from "src/helpers/compare";

/**
 * use this if dynamic tables is going to be a thing: https://github.com/fiduswriter/Simple-DataTables
 */

const SectionTable = (props: TableProps) => {
  const { t, i18n } = useTranslation();

  const {
    metrics,
    metricsCompare,
    market,
    merchant,
    symbolLeading,
    currencySymbol,
    splitBySurface,
    period,
    compare,
    enabledSurfaces,
  } = props;

  /**
   * UI
   */
  const [togglePanel, setTogglePanel] = useState<boolean>(false);
  const [height, setHeight] = useState(800);
  const referenceForHeight = useRef<HTMLDivElement>(null);

  /**
   * State
   */

  const comparePeriod = useMemo(
    () => getPeriodFromUi(period, compare, "compare"),
    [period, compare]
  );

  const [partialTableData] = useTableData(
    metrics,
    merchant,
    market,
    splitBySurface,
    period,
    currencySymbol,
    symbolLeading,
    enabledSurfaces
  );

  const [partialCompareTableData] = useTableData(
    metricsCompare,
    merchant,
    market,
    splitBySurface,
    comparePeriod,
    currencySymbol,
    symbolLeading,
    enabledSurfaces,
    " (compare)"
  );

  const tableData = useMemo(() => {
    const data = partialTableData ? partialTableData : [];
    const compare = partialCompareTableData ? partialCompareTableData : [];
    const merged = [...data, ...compare];
    const sorted = merged.sort((a: Row, b: Row) => {
      /*
       * 2 is the index of the surface name in the Row.
       * If the sorting in the table is messed up, might be that the index has changed.
       */
      return (a[2] as string).toLowerCase() >= (b[2] as string).toLowerCase()
        ? 1
        : -1;
    });
    return sorted;
  }, [partialTableData, partialCompareTableData]);

  const triggerButton = useMemo(() => {
    return (
      <button
        className="btn btn-xs btn-transparent-dark btn-icon ps-1 pe-1"
        type="button"
      >
        <Ellipsis size={16} />
      </button>
    );
  }, []);

  useEffect(() => {
    if (referenceForHeight?.current) {
      setHeight((referenceForHeight?.current as HTMLElement).clientHeight);
    }
    return () => {
      setHeight(800);
    };
  }, [tableData]);

  return (
    <div className="row depict--sectionTable">
      <div className="col-xl-12 mb-2b mb-xxl-3">
        <div className="card card-header-actions h-100 depict--CardActionDropdown">
          <div className="card-header">
            <DropDown
              id="table-actions"
              animation="fade-in"
              position="end"
              trigger={triggerButton}
            >
              <CsvDownload
                metrics={metrics}
                metricsCompare={metricsCompare}
                splitBySurface={splitBySurface}
                market={market}
                merchant={merchant}
                period={period}
                compare={compare}
                currencySymbol={currencySymbol}
                enabledSurfaces={enabledSurfaces}
              />
            </DropDown>
          </div>
          <div className="card-body">
            <div
              className={mergeClassNames(
                "depict--tablePanel",
                tableData &&
                  tableData.length > 4 &&
                  "depict--tablePanel__hasMoreThan3Rows",
                togglePanel && "depict--tablePanel__isExpanded"
              )}
            >
              <div
                className="depict--tablePanelContainer"
                style={{
                  maxHeight: height + "px",
                }}
              >
                <div className="table-responsive" ref={referenceForHeight}>
                  <table
                    className={mergeClassNames(
                      "table",
                      partialCompareTableData
                        ? "table-striped-double"
                        : "table-striped"
                    )}
                  >
                    <thead>
                      <tr>
                        {tableHeaders.map(
                          (header: { key: string; label: string }) => {
                            let stringKey =
                              "tooltips.table.headers." + header.key;
                            let tooltipText = i18n.exists(stringKey)
                              ? t(stringKey)
                              : "";
                            return (
                              <th
                                data-content={header.key}
                                key={header.key}
                                scope="col"
                                data-bs-toggle={tooltipText ? "tooltip" : ""}
                                title={tooltipText}
                              >
                                {header.label}
                              </th>
                            );
                          }
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData &&
                        tableData.length > 0 &&
                        tableData.map(
                          (row: Array<string | number>, key: any) => {
                            return (
                              <tr key={key}>
                                {row?.map((column: string | number, key) => {
                                  return (
                                    <td
                                      data-content={tableHeaders[key].key}
                                      key={key}
                                    >
                                      {column}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
              {tableData && tableData.length > 4 && (
                <div
                  className="depict--dropdownToggle"
                  onClick={() => {
                    setTogglePanel(!togglePanel);
                  }}
                >
                  <ChevronDown />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SectionTable, reactFastCompare);

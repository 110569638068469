import { useCallback } from "react";
import {
  ControlLoadingQueueCB,
  CreateNewRelationshipsCB,
} from "src/types/components";
import {
  createBasicRelationships,
  createGroupsSet,
  getStarterGroup,
} from "src/helpers/configuration";
import { getAlertMessage, getErrorMessage } from "src/helpers/errors";

import { PostGroupsApiParams } from "src/types/api";
import { Configuration } from "src/types/configuration";
import configurationsMiddleware from "src/api/configurationsMiddleware";
import { useAuth0 } from "@auth0/auth0-react";
import useAuthentication from "../app/useAuthentication";
import { useAlerts } from "../../../components/storybook/Alert/useAlerts";

export default function useCreateNewRelationships(
  merchant: string | null,
  market: string | null,
  refreshConfigurations: Function,
  controlLoadingQueueCB: ControlLoadingQueueCB
): [CreateNewRelationshipsCB] {
  const { user } = useAuth0();
  const [getAccessToken] = useAuthentication();
  const { addAlert } = useAlerts();
  const createRelationships = useCallback(
    async (
      configuration_id: number,
      merchant: string,
      market: string,
      groups: {
        from: PostGroupsApiParams;
        includeTo: PostGroupsApiParams;
        excludeTo: PostGroupsApiParams;
      }
    ) => {
      controlLoadingQueueCB("create-new-relationships", "push");
      const id_token = await getAccessToken();

      const response = await createGroupsSet(
        merchant,
        market,
        groups,
        id_token,
        user?.email
      );

      if (!response.success) {
        let message = await getAlertMessage(response.error);
        addAlert(message);
        controlLoadingQueueCB("create-new-relationships", "remove");
        return;
      }

      console.log(
        `%c[CONFIGURATION] Created Groups for configuration id:${configuration_id}`,
        "color: purple"
      );

      const relationshipGroups = response.data;

      let response2 = await createBasicRelationships(
        merchant,
        configuration_id,
        false,
        relationshipGroups,
        id_token,
        user?.email
      );

      if (!response2.success) {
        let message = await getAlertMessage(response2.error);
        addAlert(message);
        controlLoadingQueueCB("create-new-relationships", "remove");
        return;
      }

      console.log(
        `%c[CONFIGURATION] Created Relationships for configuration id: ${configuration_id}`,
        "color: purple"
      );

      refreshConfigurations();
      controlLoadingQueueCB("create-new-relationships", "remove");
    },
    [
      controlLoadingQueueCB,
      getAccessToken,
      user?.email,
      refreshConfigurations,
      addAlert,
    ]
  );

  const createNewRelationships = useCallback(
    async (configuration: Configuration | null) => {
      if (!configuration || !configuration.id || !merchant || !market) {
        const message = getErrorMessage("create-relationships");
        if (message) {
          addAlert(message);
        }
        return;
      }

      const id_token = await getAccessToken();

      /**
       * Due to how the app architecture is structured, we need here to save the current status of the configuration before proceeding to createRelationships.
       * Not saving would cause the Ui to display the configuration top part as "default" even if the user has done some modifications.
       * (default = no surfaces selected / inactive).
       * Why? Because after createRelationships we run refreshConfigurations().
       * refreshConfigurations picks the configurations from the cache in this case,
       * and the configuration in cache still has the "default" settings that it had when created.
       * We could have just update the cache instead of going through configurationsMiddleware.edit(),
       * but that would've created a missmatch between what is in cache and what is on the server (should always be the same)
       */
      let upddatedConfiguration = await configurationsMiddleware.edit(
        id_token,
        merchant as string,
        configuration.id,
        user?.email,
        configuration
      );

      if (!upddatedConfiguration.success) {
        const message = getErrorMessage("create-relationships");
        if (message) {
          addAlert(message);
        }
        return;
      }

      createRelationships(configuration.id, merchant, market, {
        from: getStarterGroup("from"),
        includeTo: getStarterGroup("include"),
        excludeTo: getStarterGroup("exclude"),
      });
    },
    [
      merchant,
      market,
      getAccessToken,
      user?.email,
      createRelationships,
      addAlert,
    ]
  );

  return [createNewRelationships];
}

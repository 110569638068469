import { useEffect, useState } from "react";
import { Surface } from "src/types/surfaces";

import { Compare, EnhancedSurface } from "src/types/components";
import { getEnhancedSurfaces } from "src/helpers/metrics";

type UseEnhancedSufaces = [enhancedSurfaces: EnhancedSurface[] | null];

export default function useEnhancedSurfaces(
  merchantSurfaces: Surface[] | null,
  splitBySurface: boolean,
  compare: Compare
): UseEnhancedSufaces {
  const [enhancedSurfaces, setEnhancedSurfaces] = useState<
    EnhancedSurface[] | null
  >(null);

  useEffect(() => {
    if (!merchantSurfaces) {
      return;
    }

    const hasCompare = compare !== "none";

    let enhancedSurfaces = getEnhancedSurfaces(
      merchantSurfaces,
      splitBySurface,
      hasCompare
    );
    let surfaces = enhancedSurfaces.filter((s: EnhancedSurface) => {
      return s.alias != null;
    });

    setEnhancedSurfaces(surfaces);

    return () => {
      setEnhancedSurfaces(null);
    };
  }, [merchantSurfaces, splitBySurface, compare]);

  return [enhancedSurfaces];
}

import { createSelector } from "reselect";
import {
  CollectionSchema,
  categorySchema,
} from "src/components/storybook/Categories/categories.schemas";
import { State } from "./Category.reducer.types";
import { BasePortalProductDto, HiddenPortalProductDto } from "src/api/types";

const getLocalState = (state: State) => state.local;
const getServerState = (state: State) => state.server;
const getProducts = (state: State) => state.products;
const getCategoryTotalProductCount = (state: State) =>
  state.categoryTotalProductCount;
const getIsInitFromServer = (state: State) => state.isInitFromServer;

const getLocalProductManagement = (state: State) =>
  state.localProductManagement;

export const getHasPinnedChanged = createSelector(
  [getLocalState, getServerState],
  (local, server) => {
    return (
      JSON.stringify(local.pinnedProducts) !==
      JSON.stringify(server.pinnedProducts)
    );
  }
);

export const getHasHiddenChanged = createSelector(
  [getLocalState, getServerState],
  (local, server) => {
    return (
      JSON.stringify(local.hiddenProducts) !==
      JSON.stringify(server.hiddenProducts)
    );
  }
);

export const getHasModifiedCategoryProducts = createSelector(
  [getLocalProductManagement],
  (localProductManagement) =>
    localProductManagement.addedProducts.length > 0 ||
    localProductManagement.removedProducts.length > 0
);

export const isLocalStateDifferentThanServer = createSelector(
  [
    getLocalState,
    getServerState,
    getHasPinnedChanged,
    getHasHiddenChanged,
    getHasModifiedCategoryProducts,
  ],
  (
    local,
    server,
    hasPinnedChanged,
    hasHiddenChanged,
    hasModifiedCategoryProducts
  ) => {
    const isDifferent =
      // Iterate the keys of the schema and differentiate all keys between server and local
      (Object.keys(categorySchema.shape) as Array<keyof CollectionSchema>).some(
        (key) => local?.[key] !== server?.[key]
      ) ||
      local.rankingType !== server.rankingType ||
      hasModifiedCategoryProducts ||
      hasPinnedChanged ||
      hasHiddenChanged;

    return isDifferent;
  }
);

const getProductDescriptionById = (
  products: State["products"],
  id: string
): BasePortalProductDto | HiddenPortalProductDto => products.byId[id];

export const getCurrentNotCuratedCount = createSelector(
  [getLocalState],
  (local) => {
    return local.productsByRelevance.length;
  }
);

export const getPinnedProducts = createSelector(
  [getLocalState, getProducts],
  (local, products) =>
    local.pinnedProducts.map((id) => getProductDescriptionById(products, id))
);

export const getPinnedProductIds = createSelector(
  [getPinnedProducts],
  (pinnedProducts) => pinnedProducts.map((product) => product.main_product_id)
);

export const getProductsByRelevance = createSelector(
  [getLocalState, getProducts],
  (local, products) =>
    local.productsByRelevance.map((id) =>
      getProductDescriptionById(products, id)
    )
);

export const getProductsByRelevanceIds = createSelector(
  [getLocalState],
  (local) => local.productsByRelevance
);

export const getHiddenProducts = createSelector(
  [getLocalState, getProducts],
  (local, products) =>
    local.hiddenProducts.map((id) => getProductDescriptionById(products, id))
);

export const getAutoHiddenProducts = createSelector(
  [getHiddenProducts],
  (hiddenProducts) =>
    hiddenProducts.filter((product) => {
      const hiddenProduct: HiddenPortalProductDto =
        product as HiddenPortalProductDto;
      return hiddenProduct.hidden_type === "auto_oos";
    })
);

export const getManuallyHiddenProducts = createSelector(
  [getHiddenProducts],
  (hiddenProducts) =>
    hiddenProducts.filter((product) => {
      const hiddenProduct: HiddenPortalProductDto =
        product as HiddenPortalProductDto;
      return (
        hiddenProduct.hidden_type === "manual" ||
        hiddenProduct.hidden_type === undefined
      );
    })
);

export const getHiddenProductIds = createSelector(
  [getHiddenProducts],
  (hiddenProducts) => hiddenProducts.map((product) => product.main_product_id)
);

const getCuratedProducts = createSelector(
  [getPinnedProducts, getHiddenProducts, getIsInitFromServer],
  (pinned, hidden, isInitFromServer) => {
    if (isInitFromServer) {
      return [...pinned, ...hidden];
    } else {
      return null;
    }
  }
);

export const getCuratedProductIds = createSelector(
  [getCuratedProducts],
  (curatedProducts) => {
    if (curatedProducts === null) return null;

    return curatedProducts.map((product) => product.main_product_id);
  }
);

const getCuratedCount = createSelector(
  [getCuratedProducts],
  (curatedProducts) => {
    if (curatedProducts === null) return null;

    return curatedProducts.length;
  }
);

export const getAddedProducts = createSelector(
  [getLocalProductManagement],
  (localProductManagement) => localProductManagement.addedProducts
);

export const getRemovedProducts = createSelector(
  [getLocalProductManagement],
  (localProductManagement) => localProductManagement.removedProducts
);

export const getNotCuratedTotalCount = createSelector(
  [
    getCategoryTotalProductCount,
    getCuratedCount,
    getAddedProducts,
    getRemovedProducts,
  ],
  (total, curated, addedProducts, removedProducts) => {
    if (curated === null) return 0;

    return total - curated + addedProducts.length - removedProducts.length;
  }
);

export const getAllLocalProductIdsInOrder = createSelector(
  [getPinnedProductIds, getProductsByRelevanceIds, getHiddenProductIds],
  (pinned, relevance, hidden) => {
    return [...pinned, ...relevance, ...hidden];
  }
);

export const getTotalProductCount = createSelector(
  [getCategoryTotalProductCount, getAddedProducts, getRemovedProducts],
  (totalProductCount, addedProducts, removedProducts) => {
    return totalProductCount + addedProducts.length - removedProducts.length;
  }
);

import { formatNumber } from "src/helpers";
import { DepictMetricsData } from "src/types/metrics";
import { Table } from "src/types/table";
import { DataPeriod, EnhancedSurface } from "src/types/components";
import {
  getMarketDataFromMetric,
  getSurfaceAggregatedValue,
  getSurfaceConversionRate,
  getSurfacePercentageValue,
} from "src/helpers/metrics";
import { getDataPeriodSpanText } from "src/helpers/dates";

export const tableHeaders: Array<{ key: string; label: string }> = [
  { key: "market", label: "Market" },
  { key: "period", label: "Period" },
  { key: "surface", label: "Surface" },
  { key: "clicks", label: "Clicks" },
  { key: "addsToCart", label: "Adds to cart" },
  /**
   * Checkout data would be empty for most of our customers.
   * Until we improve the backend to either deliver supported metrics for merchant OR support checkout metrics for all customers,
   * this is removed. 02/03/2022
   */
  // { key: "checkouts", label: "Checkouts" },
  { key: "purchases", label: "Unique Purchases" },
  { key: "conversionRate", label: "Conversion rate" },
  { key: "revenue", label: "Rec. revenue" },
  { key: "percentageRevenue", label: "% of total revenue" },
];

export const getTableRows = (
  metrics: {
    [metric: string]: DepictMetricsData | null;
  },
  enabledSurfaces: EnhancedSurface[] | null
): string[] => {
  let rows: string[] = [];
  const enabledSurfaceNames =
    enabledSurfaces?.map((surface) => surface.id) || [];

  if (metrics) {
    for (const name in metrics) {
      const metric = metrics[name];
      for (const market in metric) {
        const marketData = metric[market];
        if (!marketData.attributed) {
          continue;
        }
        let keys = Object.keys(marketData.attributed).filter((key) =>
          enabledSurfaceNames.includes(key)
        );
        rows = [...new Set([...rows, ...keys])];

        if (!marketData.total) {
          continue;
        }

        rows = [...new Set([...rows, ...["totalFromRecommendations"]])];
        break; //We assume all markets have the same surfaces. Surfaces identify the table rows
      }
      break; //We assume all metrics have the same surfaces. Surfaces identify the table rows
    }
  }

  return rows;
};

export const getTableData = (
  m: {
    [metric: string]: DepictMetricsData | null;
  },
  rows: string[],
  headers: Array<{ key: string; label: string }>,
  market: string,
  splitBySurface: boolean | undefined,
  period: DataPeriod | undefined,
  suffix?: string,
  currencySymbol?: string | null,
  symbolLeading?: boolean | null
): Table => {
  let data = [];
  let i = 0;
  let emptyDefault = "-";
  for (const row of rows) {
    if (!splitBySurface && row !== "totalFromRecommendations") {
      continue;
    }

    let surface = row;
    let rowData = [];
    for (const column of headers) {
      switch (column.key) {
        case "market":
          let label = market !== "EMPTY_KEY" ? market : "market";
          if (suffix) {
            label += suffix;
          }
          rowData.push(label);
          break;
        case "period":
          if (period) {
            rowData.push(getDataPeriodSpanText(period, true));
          } else {
            rowData.push(emptyDefault);
          }
          break;
        case "surface":
          rowData.push(
            surface !== "totalFromRecommendations" ? surface : "Total"
          );
          break;
        case "conversionRate":
          let clicks = getMarketDataFromMetric(m.clicks, market);
          let purchases = getMarketDataFromMetric(m.purchases, market);

          if (!clicks || !purchases) {
            rowData.push(emptyDefault);
            break;
          }

          let conversionRate = getSurfaceConversionRate(clicks, purchases, [
            row,
          ]);

          rowData.push(
            conversionRate
              ? formatNumber({
                  number: conversionRate,
                  suffix: "%",
                  digitsAfterComma: 2,
                })
              : emptyDefault
          );
          break;
        case "percentageRevenue":
          let marketDataset = getMarketDataFromMetric(m.revenue, market);

          if (!marketDataset) {
            rowData.push(emptyDefault);
            break;
          }

          let value = getSurfacePercentageValue(marketDataset, [row]);
          rowData.push(
            formatNumber({
              number: value as number,
              suffix: "%",
              digitsAfterComma: 2,
            })
          );
          break;
        default:
          if (!m[column.key]) {
            rowData.push(emptyDefault);
            break;
          }

          let metricMarketData = getMarketDataFromMetric(m[column.key], market);

          if (!metricMarketData) {
            rowData.push(emptyDefault);
            break;
          }

          let aggregated = getSurfaceAggregatedValue(metricMarketData, surface);

          const shouldDisplaySymbol = column.key === "revenue";
          rowData.push(
            formatNumber({
              number: aggregated,
              digitsAfterComma: 2,
              prefix:
                shouldDisplaySymbol && symbolLeading
                  ? `${currencySymbol} ` || ""
                  : undefined,
              suffix:
                shouldDisplaySymbol && symbolLeading === false
                  ? ` ${currencySymbol}` || ""
                  : undefined,
            })
          );
          break;
      }
    }
    data[i] = rowData;
    i++;
  }

  return data;
};

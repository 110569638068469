 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Bandage = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M17.604 2.396c-0.878-0.878-2.089-1.362-3.409-1.362-1.548 0-3.1 0.665-4.26 1.825l-8.075 8.075c-1.073 1.073-1.717 2.457-1.813 3.897-0.097 1.465 0.382 2.805 1.35 3.773 0.878 0.878 2.089 1.362 3.41 1.362 1.548 0 3.1-0.665 4.26-1.825l8.075-8.075c1.073-1.073 1.717-2.457 1.813-3.897 0.097-1.465-0.382-2.805-1.35-3.772zM9.5 4.707l5.793 5.793-5.793 5.793-5.793-5.793 5.793-5.793zM4.806 18.966c-1.053 0-2.013-0.38-2.702-1.069-1.597-1.597-1.39-4.403 0.462-6.255l0.434-0.434 5.793 5.793-0.434 0.434c-0.973 0.973-2.268 1.532-3.553 1.532zM16.434 9.359l-0.434 0.434-5.793-5.793 0.434-0.434c0.973-0.973 2.268-1.532 3.553-1.532 1.053 0 2.013 0.38 2.702 1.069 1.597 1.597 1.39 4.403-0.462 6.255z" fill={color}></path>
<path d="M10 6.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5z" fill={color}></path>
<path d="M8 8.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5z" fill={color}></path>
<path d="M6 10.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5z" fill={color}></path>
<path d="M10 8.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5z" fill={color}></path>
<path d="M12 8.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5z" fill={color}></path>
<path d="M8 10.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5z" fill={color}></path>
<path d="M10 10.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5z" fill={color}></path>
<path d="M12 10.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5z" fill={color}></path>
<path d="M14 10.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5z" fill={color}></path>
<path d="M8 12.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5z" fill={color}></path>
<path d="M10 12.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5z" fill={color}></path>
<path d="M12 12.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5z" fill={color}></path>
<path d="M10 14.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Bandage); 
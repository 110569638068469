import {
  AutoScrollActivator,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
} from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import React, { useContext, useMemo, useRef, useState } from "react";
import { OpenDropdownProvider } from "../../Dropdown/WhichDropdownOpenContext";
import {
  ActionType,
  ProductsContext,
  ProductsDispatchContext,
} from "../../SortableComponents/ProductsProvider/ProductsProvider";
import {
  sortableDndCollisionDetection,
  useDndSensors,
} from "../../SortableComponents/dndKitCommons";
import { Product } from "../../types";
import {
  SortableProduct,
  useSortablePairedProducts,
} from "../useSortablePairedProducts";
import {
  ProductGridCardRef,
  ProductGridSortableCard,
} from "./ProductGridSortableCard";
import ProductGridViewPlaceholder from "./ProductGridViewPlaceholder/ProductGridViewPlaceholder";
import {
  generateDnDItems,
  generateDnDItemsForSelection,
  getOverIndexesLookupTable,
  sortableFindGridIndexes,
  useDraggedPairOffset,
} from "./utils";
import { depictCardClassName } from "../../common";
import { css } from "@emotion/react";
import {
  getGridContainerCss,
  getGridElementCss,
} from "../../ProductGridView/css";

export type RenderCardCB = (
  item: SortableProduct,
  index: number,
  extraStyle: React.CSSProperties | undefined,
  isPair: boolean
) => React.ReactNode;

interface GridViewSortableProps {
  isLoading: boolean;
  renderCard: RenderCardCB;
  onDragEnd?: (mainProductIds: string[], newIndex: number) => void;
  columns: number;
  selectedIds: Set<string>;
  disableDrag: boolean;
}

function GridViewDraggable({
  isLoading,
  renderCard,
  onDragEnd,
  columns,
  selectedIds,
  disableDrag,
}: GridViewSortableProps) {
  // Notes on how this logic works:
  // - dnd-kit doesn't support dragging multiple elements at once
  // - We can't put a pair into a single draggable element because this causes grid flow issues
  // - Result is that we need to create a draggable element for each product in a pair (and cleverly draw them to make them look like a single element)
  // - When the user "picks up" an element (product in a pair), we draw it as the dragging indicator,
  // - and remove the other products in the pair from the grid.
  // - Example:
  //    - When picking up p4, grid went from this: [(p1, p2), (p3, p4, p5), (p6, p7)] to this: [(p1, p2), (INDICATOR), (p6, p7)].
  //    - The round brackets () indicate pairs, but remember that they are drawn flat in the grid.
  // - Meanwhile, on the dragging overlay, we show the "deck" of cards, drawing all products from the pair on top of each other
  // - When the user drops the dragging overlay deck, we need to figure out where to put the pair back in the grid.
  const productsDispatch = useContext(ProductsDispatchContext);
  const productsContext = useContext(ProductsContext);

  if (!productsContext) {
    throw new Error(
      "useSortablePairedProducts must be used within a ProductsProvider"
    );
  }

  const { products, productGroups, productGroupIds } = productsContext;
  const { sortableProducts: pairedProducts } = useSortablePairedProducts({
    products,
    productGroups,
    productGroupIds,
  });

  const indicatorOverIndex = useRef<number>(0);

  const [draggedId, setDraggedId] = useState<string | null>(null);
  const draggedPair = useMemo(
    () => pairedProducts.find((p) => p.find((p) => p.id === draggedId)),
    [draggedId, pairedProducts]
  );

  const isInSelectMode = selectedIds.size > 0;

  const idsToConsider = useMemo(() => {
    return isInSelectMode
      ? selectedIds
      : draggedId
      ? new Set([draggedId])
      : new Set<string>();
  }, [draggedId, isInSelectMode, selectedIds]);

  function handleDragStart(e: DragStartEvent) {
    setDraggedId((e.active?.id as string) ?? null);
  }

  function handleDragEnd(e: DragEndEvent) {
    setDraggedId(null);
    const draggedId = e.active.id;

    const payload = sortableFindGridIndexes(
      pairedProducts,
      generateDnDItems(pairedProducts, draggedId as string).dndPairsFlatIds,
      draggedId,
      indicatorOverIndex.current
    );

    const productsWithoutSelected = pairedProducts.flat().filter((p) => {
      return !idsToConsider.has(p.id);
    });
    const productsWithSelected = pairedProducts.flat().filter((p) => {
      return idsToConsider.has(p.id);
    });

    // Insert the selected products at the correct index
    const productsWithSelectedInserted = [...productsWithoutSelected];
    productsWithSelectedInserted.splice(
      indicatorOverIndex.current,
      0,
      ...productsWithSelected
    );

    productsDispatch({
      type: ActionType.UPDATE_PRODUCTS_AND_GROUPS,
      payload: {
        products: productsWithSelectedInserted.reduce((acc, product) => {
          acc[product.main_product_id] = product;
          return acc;
        }, {} as Record<string, Product>),
        groups: productsWithSelectedInserted.map((p) => [p.id]),
      },
    });

    onDragEnd?.([...idsToConsider], payload.newIndex);
  }

  const showPlaceholders = isLoading;

  const gridRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  const getOffset = useDraggedPairOffset(draggedPair, overlayRef);

  const {
    dndPairsFlatIds,
    draggedPairIndex,
    draggedProductInPairIndex,
    draggedProductFlatIndex,
  } = useMemo(
    () =>
      generateDnDItemsForSelection(pairedProducts, draggedId, idsToConsider),
    [pairedProducts, draggedId, idsToConsider]
  );

  const dragOverlayProducts = pairedProducts
    .filter(([p]) => {
      return idsToConsider.has(p.id);
    })
    .flat();

  const validOverIndexes = useMemo(
    () =>
      getOverIndexesLookupTable({
        draggedIndex: draggedProductFlatIndex,
        dndItemsIds: dndPairsFlatIds,
        pairedProducts,
      }),
    [draggedProductFlatIndex, dndPairsFlatIds, pairedProducts]
  );

  const sensors = useDndSensors();

  return (
    <DndContext
      collisionDetection={(arg) => {
        return sortableDndCollisionDetection(arg, overlayRef, getOffset);
      }}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      sensors={sensors}
      autoScroll={{
        activator: AutoScrollActivator.DraggableRect,
      }}
    >
      <SortableContext
        items={dndPairsFlatIds}
        strategy={(arg) => {
          arg.overIndex = validOverIndexes[arg.overIndex];
          indicatorOverIndex.current = arg.overIndex;
          return rectSortingStrategy(arg);
        }}
      >
        <OpenDropdownProvider>
          <div className="grid-pairs-container">
            {showPlaceholders && (
              <ProductGridViewPlaceholder columns={columns} />
            )}
            {!showPlaceholders && (
              <div
                className="depict--grid-pairs"
                css={getGridContainerCss(columns)}
                ref={gridRef}
              >
                {pairedProducts.map((pair, pairIndex) => {
                  return pair.map((product, productInPairIndex) => {
                    const isSingle = pair.length === 1;
                    const isFirst = productInPairIndex === 0;
                    const isLast = productInPairIndex === pair.length - 1;

                    const isDraggedPair = draggedPairIndex === pairIndex;
                    const isDraggedProductInPair =
                      isDraggedPair &&
                      productInPairIndex === draggedProductInPairIndex;

                    if (isDraggedPair && !isDraggedProductInPair) {
                      // This pair is being dragged, but we are not the product picked up
                      // Let's get out of the way
                      return null;
                    }

                    // This pair is being dragged, and we're the specific product that was picked up
                    const shouldBeIndicated =
                      isDraggedPair && isDraggedProductInPair;

                    const isSelected = idsToConsider.has(product.id);
                    const isDragged = draggedId === product.id;

                    const className =
                      isSingle || isDraggedPair
                        ? ""
                        : isFirst
                        ? "p-first"
                        : isLast
                        ? "p-last"
                        : "p-middle";

                    if (draggedId && isSelected && !isDragged) {
                      return null;
                    }

                    const productsToRender = isDragged
                      ? pairedProducts
                          .filter(([p]) => idsToConsider.has(p.id))
                          .flat()
                      : [product];

                    const _disableDrag =
                      (isInSelectMode && !isSelected) || disableDrag;

                    return (
                      <div
                        className={`${depictCardClassName}`}
                        key={product.main_product_id}
                        css={[
                          getGridElementCss(),
                          css`
                            position: relative;
                          `,
                        ]}
                      >
                        <ProductGridSortableCard
                          id={product.id}
                          beingDragged={isDraggedPair}
                          shouldBeIndicated={shouldBeIndicated}
                          className={className}
                          products={productsToRender}
                          pairsLength={productsToRender.length}
                          disableDrag={_disableDrag}
                          gridWidth={gridRef.current?.offsetWidth || 0}
                          isPair={productsToRender.length > 1}
                          groupId={pair[0].main_product_id}
                          renderCard={(product, index, style, isPair) => {
                            return renderCard(
                              product,
                              product.index,
                              style,
                              isPair
                            );
                          }}
                        />
                      </div>
                    );
                  });
                })}
              </div>
            )}
          </div>
        </OpenDropdownProvider>
      </SortableContext>
      <DragOverlay>
        {draggedPair && (
          <ProductGridCardRef
            isOverlayItem={true}
            products={dragOverlayProducts}
            isPair={false}
            ref={overlayRef}
            renderCard={renderCard}
            disableDrag={disableDrag}
          />
        )}
      </DragOverlay>
    </DndContext>
  );
}

export default React.memo(GridViewDraggable);

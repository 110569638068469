import { useParams } from "react-router-dom";

/**
 * useMerchantId is a React hook that provides a convenient way to access the
 * merchantId from the URL.
 * Note: This is mainly used as a dependency for other `useMerchant`. It's recommended that you use `merchant?.id` throughout the app
 */
export const useMerchantId = (): { merchantId: string } => {
  const { merchantId: merchantIdFromParams } = useParams();

  if (merchantIdFromParams) {
    return { merchantId: merchantIdFromParams };
  } else {
    throw new Error("No merchantId found");
  }
};

export default useMerchantId;

import React from "react";
import CentraMarketsConfig from "../../storybook/Integrations/Centra/CentraMarketsConfig";
import { useCentraStores } from "./UseCentraStores";

export default function CentraMarketsConfigStep({
  apiUrl,
  apiKey,
  chosenMarketIds,
  onSubmit,
  onBack,
}: {
  apiUrl: string;
  apiKey: string;
  chosenMarketIds: string[];
  onSubmit: (storeId: string, markets: string[]) => void;
  onBack: () => void;
}) {
  const { availableStores, isLoading, error } = useCentraStores(apiUrl, apiKey);

  return (
    <CentraMarketsConfig
      error={error}
      isLoading={isLoading}
      availableStores={availableStores}
      chosenMarketIds={chosenMarketIds}
      onBack={onBack}
      onSubmit={(storeId, markets) => onSubmit(storeId, markets)}
    />
  );
}

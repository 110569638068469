import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getData, throwErrorIfAny } from "src/api/authorizedApi";
import { MerchantContent } from "../../../api/types";
import { useAlerts } from "../../../components/storybook/Alert/useAlerts";
import { QueryId, getQueryKey } from "../../../queries/queries";
import useAuthorizedApi from "../app/useAuthorizedApi";

export const useContentSearch = (merchantId: string, localeId: string) => {
  const { api } = useAuthorizedApi();
  const queryClient = useQueryClient();
  const { addAlert } = useAlerts();

  const { data: content, isLoading: contentIsLoading } = useQuery({
    queryKey: getQueryKey(
      QueryId.GetContentSearch,
      merchantId || "",
      localeId || ""
    ),
    queryFn: async () => {
      if (api) {
        const response = await api.GET("/api/v0/content-search/{merchant_id}", {
          params: {
            path: {
              merchant_id: merchantId,
            },
            query: {
              locale_id: localeId,
            },
          },
        });

        const data = getData(response);

        return data;
      }
    },

    enabled: !!api && !!merchantId && localeId?.length > 0,
  });

  const { mutate: updateContent } = useMutation({
    mutationFn: async (content: MerchantContent) => {
      if (api) {
        const response = await api.POST(
          "/api/v0/content-search/{merchant_id}",
          {
            params: {
              path: {
                merchant_id: merchantId,
              },
            },
            body: {
              content_id: content.content_id,
              locale_id: content.locale_id,
              title: content.title,
              uri: content.uri,
              keywords: content.keywords,
            },
          }
        );

        throwErrorIfAny(response);
      }
    },

    onSuccess: async (_, rule) => {
      await queryClient.invalidateQueries({
        queryKey: getQueryKey(
          QueryId.GetContentSearch,
          merchantId || "",
          localeId || ""
        ),
      });
      addAlert({
        id: "content-updated",
        message: `It may take up to 30 minutes to take effect.`,
        type: "success",
        autohide: true,
      });
    },
    onError: async (_, rule) => {
      addAlert({
        id: "content-updated",
        message: `Failed to save content`,
        type: "danger",
        autohide: true,
      });
    },
  });

  const { mutate: deleteContent } = useMutation({
    mutationFn: async (contentId: string) => {
      if (api) {
        const response = await api.DELETE(
          "/api/v0/content-search/{merchant_id}/{content_id}",
          {
            params: {
              path: {
                merchant_id: merchantId,
                content_id: contentId,
              },
            },
          }
        );

        throwErrorIfAny(response);
      }
    },

    onSuccess: async (_, rule) => {
      await queryClient.invalidateQueries({
        queryKey: getQueryKey(
          QueryId.GetContentSearch,
          merchantId || "",
          localeId || ""
        ),
      });
      addAlert({
        id: "content-deleted",
        message: "It may take up to 30 minutes to take effect.",
        type: "success",
        autohide: true,
      });
    },
    onError: async (_, rule) => {
      addAlert({
        id: "content-deleted",
        message: `Failed to delete content`,
        type: "danger",
      });
    },
  });

  return {
    content,
    contentIsLoading,
    updateContent,
    deleteContent,
  };
};

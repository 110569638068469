import { z } from "zod";

export const minCollectionTitleLength = 1;
export const maxCollectionTitleLength = 128;

export const CATEGORY_NAME_ERROR_MSG = `Title must be non-empty and between ${minCollectionTitleLength} and ${maxCollectionTitleLength} characters`;

export const titleSchema = z
  .string({ errorMap: () => ({ message: CATEGORY_NAME_ERROR_MSG }) })
  .min(minCollectionTitleLength)
  .max(maxCollectionTitleLength)
  .nonempty();

export const categorySchema = z.object({
  title: titleSchema,
});

export const queryIdSchema = z.string();

export const titlesSchema = z.record(titleSchema);

export const collectionMetadataSchema = z
  .object({
    titles: titlesSchema,
    queryId: queryIdSchema,
    show_in_filter: z.boolean(),
    display_breadcrumb: z.boolean(),
    appear_in_quicklinks: z.boolean(),
  })
  .required();

export type CollectionSchema = z.infer<typeof categorySchema>;

export type CollectionMetadataSchema = z.infer<typeof collectionMetadataSchema>;

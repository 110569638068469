import reactFastCompare from "react-fast-compare";
import React, { useMemo } from "react";
import { ItemContentProps, ProductListItem } from "src/types/components";
import { Trash } from "src/components/linearicons";
import { useImageResize } from "../storybook/ImageResizeProvider/ImageResizeProvider";

const ItemContent = (props: ItemContentProps) => {
  const { item, itemRightIconOnClickCB, imageLazyLoad } = props;

  const loading = useMemo(() => {
    return imageLazyLoad ? "lazy" : "eager";
  }, [imageLazyLoad]);

  const { getImageUrl } = useImageResize();

  return (
    <>
      {item.imageUrl && (
        <div className="depict--ItemsList__img">
          <img
            loading={loading}
            alt={item.title}
            src={getImageUrl(item.imageUrl)}
          />
        </div>
      )}
      <div className="depict--ItemsList__content" data-item-id={item.id}>
        <div className="depict--ItemsList__title">{item.title}</div>
        {["product", "main_product"].includes(item.type) &&
        (item as ProductListItem).deleted === true ? (
          <div className="depict--ItemsList__status">Deleted</div>
        ) : (
          <>
            {["product", "main_product"].includes(item.type) &&
              (item as ProductListItem).inStock === false && (
                <div className="depict--ItemsList__status">Out of stock</div>
              )}
          </>
        )}
      </div>
      {itemRightIconOnClickCB && (
        <div
          className="depict--ItemsList__icon pointer"
          onClick={(e) => {
            e.stopPropagation();
            itemRightIconOnClickCB(item);
          }}
          data-no-dnd="true"
        >
          <Trash className="pointer" size="14" data-no-dnd="true" />
        </div>
      )}
    </>
  );
};

export default React.memo(ItemContent, reactFastCompare);

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const AudioBook = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M18.5 16.5c-0.13 0-0.258-0.051-0.354-0.146-0.503-0.503-1.942-1.354-4.146-1.354s-3.644 0.851-4.146 1.354c-0.195 0.195-0.512 0.195-0.707 0-0.503-0.503-1.942-1.354-4.146-1.354s-3.644 0.851-4.146 1.354c-0.143 0.143-0.358 0.186-0.545 0.108s-0.309-0.26-0.309-0.462v-11c0-0.133 0.053-0.26 0.146-0.354 0.759-0.76 2.486-1.646 4.854-1.646 2.052 0 3.623 0.667 4.5 1.339 0.877-0.672 2.448-1.339 4.5-1.339 2.367 0 4.094 0.887 4.854 1.646 0.094 0.094 0.146 0.221 0.146 0.354v11c0 0.202-0.122 0.385-0.309 0.462-0.062 0.026-0.127 0.038-0.191 0.038zM5 14c2.052 0 3.623 0.667 4.5 1.339 0.877-0.672 2.448-1.339 4.5-1.339 1.707 0 3.082 0.461 4 1.004v-9.783c-0.604-0.506-1.981-1.22-4-1.22-2.205 0-3.644 0.851-4.146 1.354-0.195 0.195-0.512 0.195-0.707 0-0.503-0.503-1.942-1.354-4.146-1.354-2.019 0-3.396 0.714-4 1.22v9.783c0.918-0.542 2.293-1.004 4-1.004z" fill={color}></path>
<path d="M7.5 13.001c-0.112 0-0.222-0.037-0.312-0.11l-2.363-1.891h-0.325c-0.276 0-0.5-0.224-0.5-0.5v-2c0-0.276 0.224-0.5 0.5-0.5h0.325l2.363-1.891c0.15-0.12 0.356-0.143 0.529-0.060s0.283 0.258 0.283 0.451v6c0 0.192-0.11 0.367-0.283 0.451-0.069 0.033-0.143 0.049-0.217 0.049zM5 10c0.114 0 0.224 0.039 0.312 0.11l1.688 1.35v-3.92l-1.688 1.35c-0.089 0.071-0.199 0.11-0.312 0.11v1z" fill={color}></path>
<path d="M10.736 12c-0.119 0-0.238-0.042-0.333-0.127-0.206-0.184-0.223-0.5-0.039-0.706 0.411-0.459 0.637-1.050 0.637-1.667s-0.226-1.208-0.637-1.667c-0.184-0.206-0.167-0.522 0.039-0.706s0.522-0.167 0.706 0.039c0.575 0.642 0.891 1.471 0.891 2.334s-0.317 1.691-0.892 2.334c-0.099 0.11-0.235 0.167-0.373 0.167z" fill={color}></path>
<path d="M12.5 13c-0.105 0-0.21-0.033-0.3-0.1-0.221-0.166-0.265-0.479-0.099-0.7 0.589-0.783 0.9-1.717 0.9-2.7s-0.311-1.916-0.899-2.7c-0.166-0.221-0.121-0.534 0.099-0.7s0.534-0.121 0.7 0.1c0.72 0.958 1.1 2.099 1.1 3.3s-0.38 2.343-1.1 3.301c-0.098 0.131-0.248 0.2-0.4 0.2z" fill={color}></path>
<path d="M9.366 10.5c-0.085 0-0.171-0.022-0.25-0.067-0.239-0.138-0.321-0.444-0.182-0.683 0.044-0.076 0.067-0.16 0.067-0.25s-0.022-0.173-0.067-0.25c-0.138-0.239-0.057-0.545 0.182-0.683s0.545-0.057 0.683 0.182c0.132 0.227 0.201 0.487 0.201 0.751s-0.070 0.523-0.201 0.751c-0.093 0.16-0.261 0.25-0.433 0.25z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(AudioBook); 
import { useCallback, useState } from "react";
import { OnSelect } from "src/components/storybook/BulkEditBar/BulkEditBar.types";

export function useOnSelectCB(
  setSelectedIds: React.Dispatch<React.SetStateAction<Set<string>>>,
  productIds: string[],
  productIdsToExclude: string[] = []
) {
  const [selectionState, setSelectionState] = useState({
    pivot: undefined as string | undefined,
    lastRange: null as { start: number; end: number } | null,
  });

  return useCallback<OnSelect>(
    (productId, alreadySelected, shortcut) => {
      setSelectedIds((prev) => {
        const currentIndex = productIds.indexOf(productId);

        if (shortcut === "shift" && selectionState.pivot !== undefined) {
          const pivotIndex = productIds.indexOf(selectionState.pivot);
          const minIndex = Math.min(pivotIndex, currentIndex);
          const maxIndex = Math.max(pivotIndex, currentIndex);

          if (
            selectionState.lastRange &&
            selectionState.lastRange.start <= currentIndex &&
            currentIndex <= selectionState.lastRange.end
          ) {
            const newRange = new Set(
              productIds
                .slice(minIndex, maxIndex + 1)
                .filter((id) => !productIdsToExclude.includes(id))
            );

            setSelectionState({
              pivot: selectionState.pivot,
              lastRange: { start: minIndex, end: maxIndex },
            });
            return newRange;
          } else {
            const newSelectedIds = productIds
              .slice(minIndex, maxIndex + 1)
              .filter((id) => !productIdsToExclude.includes(id));

            setSelectionState({
              pivot: selectionState.pivot,
              lastRange: { start: minIndex, end: maxIndex },
            });
            return new Set([...prev, ...newSelectedIds]);
          }
        }

        setSelectionState({
          pivot: alreadySelected ? undefined : productId,
          lastRange: null,
        });

        if (alreadySelected) {
          prev.delete(productId);
        } else {
          prev.add(productId);
        }

        return new Set(prev);
      });
    },
    [setSelectedIds, productIds, productIdsToExclude, selectionState]
  );
}

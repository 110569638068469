import mergeClassNames from "merge-class-names";
import React, { useEffect, useMemo, useRef, useState } from "react";
import config from "src/config";
import {
  Compare,
  CompareIdentifiers,
  DataPeriod,
  FilterCompareProps,
  Period,
} from "src/types/components";

import { getPeriodFromUi, isCompareOptionDisabled } from "src/helpers/compare";
import {
  getMaxDate,
  getDefaultEndDate,
  getDefaultStartDate,
  toUtc,
  compensateUTC,
  compensateDatepickerOutput,
  getDataPeriodSpanText,
} from "src/helpers/dates";
import { deepEqual } from "src/helpers";
import { useTranslation } from "react-i18next";
import useMinDate from "src/helpers/hooks/mix/useMinDate";
/**
 * Components
 */
import DatePicker from "react-datepicker";
import { CalendarEmpty } from "src/components/linearicons";

import CustomInput from "src/components/mix/CustomInput";
import Tooltip from "bootstrap/js/dist/tooltip";

const FilterCompare = (props: FilterCompareProps) => {
  const { compare, period, startTimestamp, setCompareCB } = props;
  const staticValues: CompareIdentifiers[] = [
    "none",
    "previous",
    "average",
    "same-last-year",
  ];
  const buttons: Array<Compare> = useMemo(
    () => [
      "none",
      "previous",
      // "average", //temporary remove average since it is not implemented yet --- 25 / 02 / 2022
      "same-last-year",
      { start: 0, end: 0 } as DataPeriod,
    ],
    []
  );
  const { t, i18n } = useTranslation();
  const tooltips = useRef({} as any);
  const [dropdownPanelClasses, setDropdownPanelClasses] = useState("");

  const customTimeout = useRef<ReturnType<typeof setTimeout>>();

  const [maxStartDate, setMaxStartDate] = useState<Date>(getDefaultStartDate());
  const [startDate, setStartDate] = useState(getDefaultStartDate());
  const [endDate, setEndDate] = useState(getDefaultEndDate());

  const startDatePickerRef = useRef<any>(null);
  const endDatePickerRef = useRef<any>(null);

  const periodSpanText = useMemo(() => {
    if (compare === "none") {
      return "";
    }
    const newComparePeriod = getPeriodFromUi(period, compare, "compare");
    if (newComparePeriod) {
      return getDataPeriodSpanText(newComparePeriod, true);
    }
    return "";
  }, [compare, period]);

  const setDate = (date: Date, which: "end" | "start") => {
    /**
     * Dates selected in the custom "compare" datepickers must be liked together, depending on what "period" is selected.
     * If 30-days is the period selected, then "compare" dates can only be 30 days apart.
     * If 90-days is the period selected, then "compare" dates can only be 90 days apart.
     * And so on....
     */
    let delta: number;
    delta = period ? period.end - period.start : 1;

    if (which === "start") {
      setStartDate(date);
      let dateTimestamp = +date;
      let otherDateTimestamp = dateTimestamp + delta * 1000;
      let endDate = toUtc(new Date(otherDateTimestamp));
      setEndDate(endDate);
    } else {
      setEndDate(date);
      let dateTimestamp = +date;
      let otherDateTimestamp = dateTimestamp - delta * 1000;
      let endDate = toUtc(new Date(otherDateTimestamp));
      setStartDate(endDate);
    }
  };

  const buttonOnClick = (value: CompareIdentifiers) => {
    setDropdownPanelClasses("");

    if (value === compare) {
      return;
    }
    setCompareCB(value);
  };

  const customOnClick = () => {
    if (dropdownPanelClasses === "") {
      setDropdownPanelClasses("depict--filterPanel__open");
      console.log(
        "%c[EXPANDABLE PANEL Compare] Setting timeout",
        "color: hotpink"
      );
      customTimeout.current = setTimeout(() => {
        console.log(
          "%c[EXPANDABLE PANEL Compare] Running timeout",
          "color: hotpink"
        );
        setDropdownPanelClasses(
          "depict--filterPanel__open depict--filterPanel__allowOverflow"
        );
      }, 300);
    } else {
      setDropdownPanelClasses("");
    }

    let span: DataPeriod = {
      start: Math.floor(+startDate / 1000),
      end: Math.floor(+endDate / 1000),
    };

    if (deepEqual(span, compare)) {
      return;
    }

    setCompareCB(span);
  };

  useEffect(() => {
    if (typeof compare === "string") {
      return;
    }

    let span: DataPeriod = {
      start: Math.floor(+startDate / 1000),
      end: Math.floor(+endDate / 1000),
    };

    if (deepEqual(span, compare)) {
      return;
    }

    setCompareCB(span);
  }, [compare, startDate, endDate, setCompareCB]);

  useEffect(() => {
    /**
     * Here we verify wether the current value for "compare" is allowed or not given the value for "period".
     * If the value is not allowed, then we set "compare" to be "none".
     */

    if (isCompareOptionDisabled(compare, period, startTimestamp || 1)) {
      setDropdownPanelClasses("");
      setCompareCB("none");
      return;
    }
  }, [period, compare, startTimestamp, setCompareCB]);

  useEffect(() => {
    /**
     * Here we update the maxDate for the startDate datepicker.
     * The maximum starting date depends on which period is selected.
     * if 30-days is selected, then the max date will be 30 days ago.
     * if 90-days is selected, then the max date will be 90 days ago.
     */
    let maxDate: Date = getMaxDate(period as Period);
    setMaxStartDate(maxDate);

    /**
     * Here we reset the datepicker dates depending on the period chosen.
     */
    let currentPeriod = period;
    let span;
    let offset = config.const.defaultCompareOffset;

    span = currentPeriod;

    if (!span?.start || !span?.end) {
      return;
    }

    let start: number = (span?.start - offset) * 1000;
    let end: number = (span?.end - offset) * 1000;
    setStartDate(toUtc(new Date(start)));
    setEndDate(toUtc(new Date(end)));
  }, [period]);

  useEffect(() => {
    return () => {
      console.log(
        "%c[EXPANDABLE PANEL Compare] Clearing timeout",
        "color: hotpink"
      );
      clearTimeout(customTimeout.current as ReturnType<typeof setTimeout>);
    };
  }, []);

  useEffect(() => {
    for (let button of buttons) {
      let buttonClass = typeof button === "string" ? button : "custom";
      if (tooltips.current[buttonClass]) {
        tooltips.current[buttonClass].dispose();
        tooltips.current[buttonClass] = null;
      }

      let els = document.getElementsByClassName(
        "depict--tooltipTrigger__" + buttonClass
      );
      if (!els[0]) {
        continue;
      }
      let el = els[0];
      let title = el.getAttribute("data-dp-title");
      if (title) {
        tooltips.current[buttonClass] = new Tooltip(el, {
          delay: config.const.bootstrapTooltipDelay,
          placement: "top",
          title: title,
        });
      }
    }

    return () => {
      for (let button of buttons) {
        let buttonClass = typeof button === "string" ? button : "custom";
        if (tooltips.current[buttonClass]) {
          tooltips.current[buttonClass].dispose();
        }
      }
      tooltips.current = {};
    };
  }, [buttons, compare, period]);

  const [minDate] = useMinDate(startTimestamp);

  useEffect(() => {
    if (+startDate < +minDate) {
      setStartDate(minDate);
    }
  }, [minDate, startDate]);

  const openStartDatePicker = () => {
    const datepickerElement = startDatePickerRef.current;
    datepickerElement.setOpen(true);
  };

  const openEndDatePicker = () => {
    const datepickerElement = endDatePickerRef.current;
    datepickerElement.setOpen(true);
  };

  return (
    <div
      className={mergeClassNames(
        dropdownPanelClasses,
        "depict--filterCompareSection"
      )}
      data-testid="depict--filterCompareSection"
    >
      <label htmlFor="exampleFormControlInput1" className="form-label">
        Compare to
      </label>
      <div className="depict--sectionTop">
        <div
          className="btn-group border border-primary-light border-1 rounded-pill"
          role="group"
          aria-label="Compare"
          style={{ display: "flex" }}
        >
          {buttons.map((btn: Compare, i: number) => {
            let label = typeof btn === "string" ? btn : "custom";
            let stringKey = "tooltips.compare." + label;
            let tooltipText = i18n.exists(stringKey) ? t(stringKey) : "";
            let isDisabled = isCompareOptionDisabled(
              btn,
              period,
              startTimestamp || 1
            );

            if (isDisabled) {
              tooltipText =
                btn !== "average"
                  ? t("tooltips.compare.filterDisabled")
                  : t("tooltips.compare.comingSoon");
            }

            let isSelected = compare === btn;
            if (label === "custom") {
              isSelected = !staticValues.includes(
                compare as CompareIdentifiers
              );
            }

            const hasCompressed = i18n.exists(
              `filters.compare.buttons.${label}.compressed`
            );

            return (
              <button
                key={i}
                type="button"
                data-testid={"button-compare-" + label}
                className={mergeClassNames(
                  "depict--tooltipTrigger__" + label,
                  "btn rounded-pill",
                  isSelected &&
                    "dpt-color-background-neutral-default fw-bolder",
                  isDisabled && "btn-disabled"
                )}
                onClick={() => {
                  if (isDisabled) {
                    return;
                  }
                  if (label !== "custom") {
                    buttonOnClick(label as CompareIdentifiers);
                  } else {
                    customOnClick();
                  }
                }}
                data-dp-toggle="tooltip"
                data-dp-title={tooltipText}
              >
                <span
                  className={mergeClassNames(
                    hasCompressed && "d-none d-xxl-block"
                  )}
                >
                  {t(`filters.compare.buttons.${label}.regular`)}
                </span>
                {hasCompressed && (
                  <span
                    className={mergeClassNames(hasCompressed && "d-xxl-none")}
                  >
                    {t(`filters.compare.buttons.${label}.compressed`)}
                  </span>
                )}
              </button>
            );
          })}
        </div>
      </div>

      <div className="depict--sectionMiddle">
        <div className="rounded-pill border border-1 border-primary-light d-flex justify-content-around">
          <div className="depict--datepickerWrapper flex-grow-0">
            <div className="input-group input-group-sm rounded-pill">
              <DatePicker
                ref={startDatePickerRef}
                dateFormat={config.const.dateFormat}
                selected={compensateUTC(startDate)}
                onChange={(date) => {
                  setDate(compensateDatepickerOutput(date as Date), "start");
                }}
                selectsStart
                minDate={compensateUTC(minDate)}
                startDate={compensateUTC(startDate)}
                endDate={compensateUTC(endDate)}
                maxDate={compensateUTC(maxStartDate)}
                customInput={<CustomInput />}
              >
                <h6
                  className="text-center pt-1 pb-1 m-0 fw-bolder"
                  style={{ backgroundColor: "#f0f0f0", fontSize: "0.75rem" }}
                >
                  Start Date
                </h6>
              </DatePicker>
              <span className="input-group-text" onClick={openStartDatePicker}>
                <CalendarEmpty size={10} />
              </span>
            </div>
          </div>
          <div className="depict--datepickerWrapper flex-grow-0">
            <div className="input-group input-group-sm  rounded-pill">
              <DatePicker
                ref={endDatePickerRef}
                dateFormat={config.const.dateFormat}
                selected={compensateUTC(endDate)}
                onChange={(date) => {
                  setDate(compensateDatepickerOutput(date as Date), "end");
                }}
                selectsEnd
                startDate={compensateUTC(startDate)}
                endDate={compensateUTC(endDate)}
                minDate={compensateUTC(startDate)}
                maxDate={getDefaultEndDate()}
                customInput={<CustomInput />}
              >
                {" "}
                <h6
                  className="text-center pt-1 pb-1 m-0 fw-bolder"
                  style={{ backgroundColor: "#f0f0f0", fontSize: "0.75rem" }}
                >
                  End Date
                </h6>
              </DatePicker>
              <span className="input-group-text" onClick={openEndDatePicker}>
                <CalendarEmpty size={10} />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="depict--sectionBottom mt-2">
        <div className="d-flex justify-content-center align-items-center depict--calendarBasedWrapper">
          <div className="depict--calendarBasedInfoWrapper">
            <label className="form-label mb-0">{periodSpanText}</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(FilterCompare);

import { Alert, Button, Col, Row, Spinner, Stack } from "react-bootstrap";
import Card from "src/components/storybook/Card/Card";
import { useState } from "react";
import { ShopifyAttributeParsingSelector } from "../../Integrations/Shopify/ShopifyAttributeParsingSelector";

import {
  MetafieldDefinition,
  ParsingConfig,
} from "../../Integrations/Shopify/types";
import { AGE_CONSTANTS, GENDER_CONSTANTS } from "../constants";
import IntegrationStepHeader from "../IntegrationStepHeader/IntegrationStepHeader";
import AddOptionalAttribute from "../Onboarding/AddOptionalAttribute/AddOptionalAttribute";
import { ParsingMethod } from "../ParsingMethodSelector/types";
import { attributeIdToName } from "../util";

export default function ShopifyMetafieldsConfig({
  genderParsingConfig,
  ageParsingConfig,
  optionalAttributeParsingConfigs,
  metafieldDefinitions,
  availableOptionalAttributeIds,
  isLoading,
  errorMessage,
  onSubmit,
  onBack,
}: {
  genderParsingConfig?: ParsingConfig;
  ageParsingConfig?: ParsingConfig;
  optionalAttributeParsingConfigs?: ParsingConfig[];
  availableOptionalAttributeIds: string[];
  isLoading: boolean;
  errorMessage: string | null;
  onSubmit: (
    genderParsingConfig: ParsingConfig,
    ageParsingConfig: ParsingConfig,
    optionalAttributeParsingConfigs: ParsingConfig[]
  ) => void;
  onBack: () => void;
  metafieldDefinitions: MetafieldDefinition[];
}) {
  const [newGenderParsingConfig, setNewGenderParsingConfig] = useState<
    ParsingConfig | undefined
  >(genderParsingConfig);
  const [newAgeParsingConfig, setNewAgeParsingConfig] = useState<
    ParsingConfig | undefined
  >(ageParsingConfig);
  const [
    newOptionalAttributeParsingConfigs,
    setNewOptionalAttributeParsingConfigs,
  ] = useState<ParsingConfig[]>(optionalAttributeParsingConfigs ?? []);

  return (
    <Card>
      <Card.Body>
        <IntegrationStepHeader title="Map required attributes" stepNumber={4} />
        <Card.Text>
          Depict has not found corresponding attributes for Age and Gender.
          Please map Age and Gender to their corresponding metafields from
          Shopify. Alternatively you can select one constant value for Age or
          Gender. (e.g. All your products are for Men.)
        </Card.Text>
        {isLoading && (
          <div className="d-flex justify-content-center py-4">
            <Spinner />
          </div>
        )}
        {errorMessage && (
          <Alert variant="danger">
            Error fetching metafields from Shopify: {errorMessage}
          </Alert>
        )}
        <Stack gap={4} direction="vertical" className="mt-5">
          <ShopifyAttributeParsingSelector
            attributeId="gender"
            parsingConfig={newGenderParsingConfig}
            onSetParsingConfig={(config) => setNewGenderParsingConfig(config)}
            attributeName="Gender"
            availableMetafieldDefinitions={metafieldDefinitions}
            availableConstantValues={GENDER_CONSTANTS}
            required
          />
          <ShopifyAttributeParsingSelector
            attributeId="age"
            parsingConfig={newAgeParsingConfig}
            onSetParsingConfig={(config) => setNewAgeParsingConfig(config)}
            attributeName="Age Group"
            availableMetafieldDefinitions={metafieldDefinitions}
            availableConstantValues={AGE_CONSTANTS}
            required
          />
          {newOptionalAttributeParsingConfigs.map((config, index) => (
            <ShopifyAttributeParsingSelector
              key={index}
              attributeName={attributeIdToName(config.attribute_id)}
              attributeId={config.attribute_id}
              parsingConfig={config}
              onSetParsingConfig={(config) => {
                const newConfigs = [...newOptionalAttributeParsingConfigs];
                newConfigs[index] = config;
                setNewOptionalAttributeParsingConfigs(newConfigs);
              }}
              availableMetafieldDefinitions={metafieldDefinitions}
              availableConstantValues={[]}
              onDelete={() => {
                const newConfigs = [...newOptionalAttributeParsingConfigs];
                newConfigs.splice(index, 1);
                setNewOptionalAttributeParsingConfigs(newConfigs);
              }}
            />
          ))}
        </Stack>
        <Card.Text className="mt-3">
          If you would like to map additional attributes, please select them
          below.
        </Card.Text>
        <AddOptionalAttribute
          availableOptionalAttributeIds={availableOptionalAttributeIds}
          alreadyUsedOptionalAttributeIds={newOptionalAttributeParsingConfigs.map(
            (config) => config.attribute_id
          )}
          onAdd={(attributeId) => {
            const newConfigs = [...newOptionalAttributeParsingConfigs];
            newConfigs.push({
              attribute_id: attributeId,
              type: ParsingMethod.Map,
              namespace: metafieldDefinitions[0].namespace,
              key: metafieldDefinitions[0].key,
            });
            setNewOptionalAttributeParsingConfigs(newConfigs);
          }}
        />
      </Card.Body>
      <Card.Footer>
        <Row>
          <Col>
            <Button
              variant="outline-secondary"
              className="w-100"
              onClick={onBack}
            >
              Back
            </Button>
          </Col>
          <Col>
            <Button
              variant="primary"
              className="w-100"
              onClick={() => {
                if (newGenderParsingConfig && newAgeParsingConfig) {
                  onSubmit(
                    newGenderParsingConfig,
                    newAgeParsingConfig,
                    newOptionalAttributeParsingConfigs
                  );
                }
              }}
              disabled={
                !newGenderParsingConfig || !newAgeParsingConfig || isLoading
              }
            >
              Continue
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
}

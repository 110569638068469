import Cache from "src/helpers/cache";
import {
  GetGroupApiResult,
  GetGroupsApiResult,
  PostPutGroupApiResult,
} from "src/types/api";
import groupsApi from "src/api/groups";
import { ConfiguredProductGroup } from "src/types/configuration";
import { GroupsCacheKey } from "src/types/cache";

const groupsMiddleware = (function () {
  let cache: Cache = new Cache();
  return {
    get: async function (
      idToken: string,
      merchant_id: string,
      market_id: string,
      user_id: string | undefined
    ): Promise<GetGroupsApiResult> {
      const cacheKey: GroupsCacheKey = {
        merchant_id: merchant_id,
        market_id: market_id,
        user_id: user_id ?? "no-user",
      };

      if (cache.has(cacheKey)) {
        console.log(
          "[CACHE] Loading %c" + merchant_id + " %c groups.",
          "font-weight: bold",
          "font-weight: normal"
        );
        return { success: true, data: cache.get(cacheKey) };
      }

      const result = await groupsApi({ merchant_id, market_id, idToken });

      if (!result.success) {
        return result;
      }

      cache.set(cacheKey, result.data);

      return result as GetGroupsApiResult;
    },
    edit: async (
      idToken: string,
      merchant_id: string,
      market_id: string,
      group_id: string,
      user_id: string | undefined,
      params: Omit<
        ConfiguredProductGroup,
        "group_id" | "merchant_id" | "market"
      >
    ): Promise<PostPutGroupApiResult> => {
      const result = await groupsApi({
        idToken,
        merchant_id,
        market_id,
        group_id,
        body: params,
        method: "PUT",
      });

      if (result.success) {
        const cacheKey: GroupsCacheKey = {
          merchant_id,
          market_id,
          group_id,
          user_id: user_id ?? "no-user",
        };
        cache.delete(cacheKey);
        cache.set(cacheKey, result.data);
        const groupsCacheKey = {
          merchant_id: merchant_id,
          market_id: market_id,
          user_id: user_id ?? "no-user",
        };
        if (cache.has(groupsCacheKey)) {
          const cached = cache.get(groupsCacheKey);
          const removed = cached.filter((group: ConfiguredProductGroup) => {
            return group.group_id !== group_id;
          });
          const updated = removed.concat([result.data]);
          cache.delete(groupsCacheKey);
          cache.set(groupsCacheKey, updated);
        }
      }

      return result as PostPutGroupApiResult;
    },
    create: async (
      idToken: string,
      merchant_id: string,
      market_id: string,
      user_id: string | undefined,
      params: Omit<
        ConfiguredProductGroup,
        "group_id" | "merchant_id" | "market"
      >
    ): Promise<PostPutGroupApiResult> => {
      const result = await groupsApi({
        idToken,
        merchant_id,
        market_id,
        body: params,
        method: "POST",
      });

      if (result.success) {
        const cacheKey: GroupsCacheKey = {
          merchant_id,
          market_id,
          group_id: (result.data as ConfiguredProductGroup).group_id,
          user_id: user_id ?? "no-user",
        };
        cache.set(cacheKey, result.data); //Add new group to cache.
        const groupsCacheKey = {
          merchant_id: merchant_id,
          market_id: market_id,
          user_id: user_id ?? "no-user",
        };
        if (cache.has(groupsCacheKey)) {
          //Add new group to cached groups array.
          const cached = cache.get(groupsCacheKey);
          const updated = cached.concat([result.data]);
          cache.delete(groupsCacheKey);
          cache.set(groupsCacheKey, updated);
        }
      }

      return result as PostPutGroupApiResult;
    },
    getGroup: async function (
      idToken: string,
      merchant_id: string,
      market_id: string,
      group_id: string,
      user_id: string | undefined
    ): Promise<GetGroupApiResult> {
      const cacheKey: GroupsCacheKey = {
        merchant_id,
        market_id,
        group_id,
        user_id: user_id ?? "no-user",
      };

      if (cache.has(cacheKey)) {
        console.log(
          "[CACHE] Loading %c" + group_id + " %c group.",
          "font-weight: bold",
          "font-weight: normal"
        );
        return { success: true, data: cache.get(cacheKey) };
      }

      const result = await groupsApi({
        idToken,
        merchant_id,
        market_id,
        group_id,
      });

      if (!result.success) {
        return result;
      }

      cache.set(cacheKey, result.data);

      return result as GetGroupApiResult;
    },
    getCache: function (): Cache {
      return cache;
    },
    clearCache: function (): void {
      console.log("[CACHE] Clearing groups cache.");
      cache = new Cache();
    },
  };
})();

export default groupsMiddleware;

import { css } from "@emotion/css";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { useNavigate } from "react-router-dom";
import useUser from "src/helpers/hooks/useUser";
import { getAuthorizedApi, getData } from "../api/authorizedApi";
import OnboardingIntegration from "../components/integrations/OnboardingIntegration";
import { useAlerts } from "../components/storybook/Alert/useAlerts";
import OnboardingHeader from "../components/storybook/Integrations/Onboarding/OnboardingHeader/OnboardingHeader";
import OnboardingSetUpSite from "../components/storybook/Integrations/Onboarding/OnboardingSetUpSite/OnboardingSetUpSite";
import { ProductOverviewStep } from "../components/storybook/Integrations/Onboarding/OnboardingWelcomeStep/OnboardingProductOverview";
import OnboardingWelcomeStep from "../components/storybook/Integrations/Onboarding/OnboardingWelcomeStep/OnboardingWelcomeStep";
import { OnboardingStep } from "../components/storybook/Integrations/Onboarding/types";
import useAuthentication from "../helpers/hooks/app/useAuthentication";
import useMerchant from "../helpers/hooks/app/useMerchant";
import queryClient from "../queries/client";
import { QueryId, getQueryKey } from "../queries/queries";
import ProtectedRoute from "../router/ProtectedRoute";

const Onboarding = () => {
  const { user } = useUser();
  const { merchant, merchantId } = useMerchant();
  const [currentStep, setCurrentStep] = React.useState<OnboardingStep>(
    OnboardingStep.WELCOME
  );
  const navigate = useNavigate();
  const [getAccessToken] = useAuthentication();

  const { addAlert } = useAlerts();

  const { mutate } = useMutation({
    mutationFn: async () => {
      const api = getAuthorizedApi(await getAccessToken());
      const response = await api.POST(
        `/api/v0/integrations/{merchant_id}/complete`,
        {
          params: {
            path: {
              merchant_id: merchantId,
            },
          },
        }
      );

      return getData(response);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: getQueryKey(QueryId.GetAuthorizedMerchants),
      });
      navigate(`/${merchantId}/catalog`);
    },
    onError: () => {
      addAlert({
        id: "onboarding-completion-error",
        type: "error",
        message: "Something went wrong. Please contact support.",
      });
    },
  });

  const [cardContainerHeight, setCardContainerHeight] = React.useState(0);
  const cardContainerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!cardContainerRef.current) return;
    const resizeObserver = new ResizeObserver((entries) =>
      setCardContainerHeight((entries[0].target as HTMLDivElement).offsetHeight)
    );
    resizeObserver.observe(cardContainerRef.current);

    return () => resizeObserver.disconnect();
  }, []);

  const headerHeight = "calc(calc(1.5rem * 2) + 32px)"; // OnboardingHeaderStepLabel's height + p-4 class

  const productOverviewSteps: ProductOverviewStep[] = [];

  if (
    merchant?.product_status.plp_state === "in_development" ||
    merchant?.product_status.plp_state === "live"
  ) {
    productOverviewSteps.push(ProductOverviewStep.PLP);
  }
  if (
    merchant?.product_status.search_state === "in_development" ||
    merchant?.product_status.search_state === "live"
  ) {
    productOverviewSteps.push(ProductOverviewStep.SEARCH);
  }
  if (
    merchant?.product_status.recommendations_state === "in_development" ||
    merchant?.product_status.recommendations_state === "live"
  ) {
    productOverviewSteps.push(ProductOverviewStep.RECOMMENDATIONS);
  }

  return (
    <ProtectedRoute
      user={user}
      merchant={merchant}
      accessValidator={(u, m) => m.no_code_config || u.is_superuser}
    >
      <main>
        <OnboardingHeader currentStep={currentStep} />
        <div
          style={{
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            position: "absolute",
            // Position the card at the center in Y axis, but have a "min distance to top" that's the same as the header
            top: `calc(max(50%, calc(${headerHeight} + ${cardContainerHeight}px / 2) + 5px)`, // the 5px is for the shadow and also acts as spacing to the header
            transform: "translateY(-50%)",
            paddingTop: "5px", // so shadow can come through
            paddingBottom: "5px",
            marginBottom: "5px",
            overscrollBehavior: "contain",
          }}
        >
          <div
            ref={cardContainerRef}
            className={css`
              max-width: 600px;
              max-height: calc(
                100vh - ${headerHeight}
              ); // fallback for browsers that don't support dvh
            `}
            style={{
              // so it doesn't scroll into the header
              // the 10px are for the shadow
              maxHeight: `calc(
                100dvh - calc(${headerHeight} + 10px)
              )`,
            }}
          >
            {currentStep === OnboardingStep.WELCOME && (
              <OnboardingWelcomeStep
                onContinue={() => {
                  setCurrentStep(OnboardingStep.CONNECT_DATA);
                }}
                productOverviewSteps={productOverviewSteps}
              />
            )}
            {currentStep === OnboardingStep.CONNECT_DATA && (
              <OnboardingIntegration
                merchant={merchantId}
                onBack={() => {
                  setCurrentStep(OnboardingStep.WELCOME);
                }}
                onSuccess={() => {
                  setCurrentStep(OnboardingStep.SET_UP_SITE);
                }}
              />
            )}
            {currentStep === OnboardingStep.SET_UP_SITE && (
              <OnboardingSetUpSite
                onComplete={() => {
                  mutate();
                }}
              />
            )}
          </div>
        </div>
      </main>
    </ProtectedRoute>
  );
};

export default React.memo(Onboarding);

import { Eye, EyeClosed, PushPin, Sparkle, Trash } from "@phosphor-icons/react";
import { useCallback, useContext, useMemo } from "react";
import {
  BulkAction,
  BulkEditBar,
} from "src/components/storybook/BulkEditBar/BulkEditBar";
import { CategorySelectorsContext } from "./Category.provider";

export interface CategoryBulkEditBarProps {
  open: boolean;
  selectedIds: string[];
  actions: {
    onHideAction?: (selectedIds: string[]) => void;
    onDeleteAction?: (selectedIds: string[]) => void;
    aiSortedAction?: (selectedIds: string[]) => void;
    onPinToTopAction?: (selectedIds: string[]) => void;
  };
  unselectAll: () => void;
}

type ActionConfiguration = {
  action: BulkAction;
  predicate: () => boolean;
};

const CategoryBulkEditBar = (props: CategoryBulkEditBarProps) => {
  const { pinnedProductIds, hiddenProductIds } = useContext(
    CategorySelectorsContext
  );

  const inPinned = useCallback(
    (id: string): boolean => pinnedProductIds.includes(id),
    [pinnedProductIds]
  );
  const hasPinnedProductsSelected = useMemo(
    () => props.selectedIds.some(inPinned),
    [props.selectedIds, inPinned]
  );

  const inHidden = useCallback(
    (id: string): boolean => hiddenProductIds.includes(id),
    [hiddenProductIds]
  );
  const hasHiddenProductsSelected = useMemo(
    () => props.selectedIds.some(inHidden),
    [props.selectedIds, inHidden]
  );

  const notHiddenNorPinned = useCallback(
    (id: string): boolean => !inHidden(id) && !inPinned(id),
    [inHidden, inPinned]
  );
  const hasNotCuratedProductsSelected = useMemo(() => {
    return props.selectedIds.some(notHiddenNorPinned);
  }, [props.selectedIds, notHiddenNorPinned]);

  const onlyHidden =
    !hasPinnedProductsSelected &&
    hasHiddenProductsSelected &&
    !hasNotCuratedProductsSelected;

  const hideAction = useMemo<ActionConfiguration>(() => {
    return {
      action: {
        icon: EyeClosed,
        label: "Hide",
        keyShortcut: "H",
        onClick: () => props.actions.onHideAction?.(props.selectedIds),
      },
      predicate: () => !onlyHidden,
    };
  }, [onlyHidden, props.actions, props.selectedIds]);

  const pinAction = useMemo<ActionConfiguration>(() => {
    return {
      action: {
        icon: PushPin,
        label: "Pin",
        keyShortcut: "P",
        onClick: () => props.actions.onPinToTopAction?.(props.selectedIds),
      },
      predicate: () =>
        hasNotCuratedProductsSelected || hasHiddenProductsSelected,
    };
  }, [
    hasHiddenProductsSelected,
    hasNotCuratedProductsSelected,
    props.actions,
    props.selectedIds,
  ]);

  const aiSortedAction = useMemo<ActionConfiguration>(() => {
    return {
      action: {
        icon: Sparkle,
        label: "Sort with Depict",
        keyShortcut: "D",
        onClick: () => props.actions.aiSortedAction?.(props.selectedIds),
      },
      predicate: () => hasHiddenProductsSelected || hasPinnedProductsSelected,
    };
  }, [
    hasHiddenProductsSelected,
    hasPinnedProductsSelected,
    props.actions,
    props.selectedIds,
  ]);

  const showAction = useMemo<ActionConfiguration>(() => {
    return {
      action: {
        icon: Eye,
        label: "Show",
        keyShortcut: "S",
        onClick: () => props.actions.aiSortedAction?.(props.selectedIds),
      },
      predicate: () => hasHiddenProductsSelected && !hasPinnedProductsSelected,
    };
  }, [
    hasHiddenProductsSelected,
    hasPinnedProductsSelected,
    props.actions,
    props.selectedIds,
  ]);

  const unpinnedAction = useMemo<ActionConfiguration>(() => {
    return {
      action: {
        icon: PushPin,
        label: "Unpin",
        keyShortcut: "U",
        onClick: () => props.actions.aiSortedAction?.(props.selectedIds),
      },
      predicate: () => hasPinnedProductsSelected && !hasHiddenProductsSelected,
    };
  }, [
    hasHiddenProductsSelected,
    hasPinnedProductsSelected,
    props.actions,
    props.selectedIds,
  ]);

  const deleteAction = useMemo<ActionConfiguration>(() => {
    return {
      action: {
        icon: Trash,
        label: "Delete",
        keyShortcut: "Backspace",
        onClick: () => props.actions.onDeleteAction?.(props.selectedIds),
      },
      predicate: () => true,
    };
  }, [props.actions, props.selectedIds]);

  const actionConfigurations: ActionConfiguration[] = useMemo(() => {
    return [
      pinAction,
      aiSortedAction,
      hideAction,
      showAction,
      unpinnedAction,
      deleteAction,
    ];
  }, [
    pinAction,
    aiSortedAction,
    hideAction,
    showAction,
    unpinnedAction,
    deleteAction,
  ]);

  const actions: BulkAction[] = useMemo(() => {
    const actions = actionConfigurations
      .filter((config) => config.predicate())
      .map((config) => config.action);

    return actions;
  }, [actionConfigurations]);

  return (
    <BulkEditBar
      open={props.open}
      selected={props.selectedIds.length}
      actions={actions}
      unselectAll={props.unselectAll}
    />
  );
};

export default CategoryBulkEditBar;

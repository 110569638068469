import { useCallback, useEffect, useMemo, useState } from "react";
import { ConfiguredProductGroup } from "src/types/configuration";
import { GroupModifyCB } from "src/types/components";
import { deepEqual, deepCopy } from "src/helpers";
import { updateOnViewGroups } from "src/helpers/configuration";

type UseLocalConfigurationGroups = [
  local: ConfiguredProductGroup[] | null,
  modify: GroupModifyCB,
  restore: Function,
  hasChanges: boolean
];

export default function useLocalConfigurationGroups(
  configurationGroups: ConfiguredProductGroup[] | null
): UseLocalConfigurationGroups {
  const [local, set] = useState<ConfiguredProductGroup[] | null>(null);
  const [backup, setBackup] = useState<ConfiguredProductGroup[] | null>(null);

  useEffect(() => {
    if (!configurationGroups) {
      set(null);
      setBackup(null);
      return;
    }

    console.log(
      `%c[CONFIGURATION] Setting groups for on-view configuration.`,
      "color: purple"
    );

    set(deepCopy(configurationGroups));
    setBackup(deepCopy(configurationGroups));

    return () => {
      set(null);
      setBackup(null);
    };
  }, [configurationGroups]);

  const restore = useCallback(() => {
    if (!backup) {
      return;
    }
    set(deepCopy(backup));
  }, [backup]);

  const modify = useCallback((data: ConfiguredProductGroup) => {
    set((prevValue: ConfiguredProductGroup[] | null) => {
      console.log("[CONFIGURATION] Updating groups for current configuration.");
      return updateOnViewGroups(prevValue, data);
    });
  }, []);

  const hasChanges = useMemo(() => {
    return !deepEqual(local, backup);
  }, [local, backup]);

  return [local, modify, restore, hasChanges];
}

// BoostBuryProvider.tsx

import { createContext, useMemo, useReducer } from "react";
import { initialState, reducer } from "./BoostBury.reducer";
import { Action, ActionType, State } from "./BoostBury.reducer.types";
import {
  isLocalStateDifferentThanServer,
  getLocalRules,
  getChangedRules,
} from "./BoostBury.selectors";
import { BoostRule } from "src/api/types";
export { ActionType };

export interface BoostBuryContextProps extends State {}

export const BoostBuryContext = createContext<BoostBuryContextProps | null>(
  null
);
export const BoostBuryDispatchContext = createContext<React.Dispatch<Action>>(
  () => {}
);

interface BoostBurySelectors {
  isLocalStateDifferentThanServer: boolean;
  allRules: BoostRule[];
  boostRules: BoostRule[];
  buryRules: BoostRule[];
  addedRules: BoostRule[];
  removedRules: BoostRule[];
  updatedRules: [BoostRule, BoostRule][];
}
export const BoostBurySelectorsContext = createContext<BoostBurySelectors>({
  isLocalStateDifferentThanServer: false,
  allRules: [],
  boostRules: [],
  buryRules: [],
  addedRules: [],
  removedRules: [],
  updatedRules: [],
});

interface UseBoostBuryContextProps {
  children: React.ReactNode;
}

export const BoostBuryProvider = ({ children }: UseBoostBuryContextProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const selectors: BoostBurySelectors = useMemo(() => {
    const local = getLocalRules(state);
    const changes = getChangedRules(state);
    return {
      isLocalStateDifferentThanServer: isLocalStateDifferentThanServer(state),
      allRules: local.allRules,
      boostRules: local.boostRules,
      buryRules: local.buryRules,
      addedRules: changes.addedRules,
      removedRules: changes.removedRules,
      updatedRules: changes.updatedRules,
    };
  }, [state]);

  return (
    <BoostBuryContext.Provider value={state}>
      <BoostBuryDispatchContext.Provider value={dispatch}>
        <BoostBurySelectorsContext.Provider value={selectors}>
          {children}
        </BoostBurySelectorsContext.Provider>
      </BoostBuryDispatchContext.Provider>
    </BoostBuryContext.Provider>
  );
};

import { Action, ActionType, State } from "./ProductsProvider.type";
import { produce } from "immer";
import { Product } from "../../types";

export const initialState: State = {
  products: {
    byId: {},
    allIds: [],
  },
  productGroups: {
    byId: {},
    allIds: [],
  },
  productGroupIds: [],
  loading: true,
};

/*
  This function is used to create the initial state of the context.
  It takes an array of products and an array of groups.

  If groups is undefined, it will create a group for each product, with the product's mainProductId as the only member of the group.
  If groups is defined, it will use the groups where applicable, and create a group for each product that is not in a group.

  The order is defined by the order of the products array.

  This is temporary, and the groups should be defined by the backend.
*/
export const adaptGroups = (
  groups: string[][] | undefined,
  products: Product[]
) => {
  const productIds = products.map((product) => product.main_product_id);

  if (!groups) {
    // No groups defined, use the order of the products array
    return productIds.map((id) => [id]);
  }

  const mergedGroups: string[][] = [];

  for (const group of groups) {
    // Check if the group contains any product from the products array
    const groupIntersection = group.filter((id) => productIds.includes(id));

    if (groupIntersection.length > 0) {
      mergedGroups.push(groupIntersection);
    }
  }

  // Add remaining products that are not in any group
  const productsWithoutGroup = productIds.filter(
    (id) => !mergedGroups.flat().includes(id)
  );

  for (const productId of productsWithoutGroup) {
    mergedGroups.push([productId]);
  }

  return mergedGroups;
};

export const setStateFromProductsAndGroups = (
  draft: State,
  products: Product[],
  groups?: string[][]
) => {
  const definiteGroups = adaptGroups(groups, products);

  draft.products.byId = products.reduce<State["products"]["byId"]>(
    (acc, product) => ({ ...acc, [product.main_product_id]: product }),
    {}
  );
  draft.products.allIds = products.map((product) => product.main_product_id);

  draft.productGroups.byId = definiteGroups.reduce<
    State["productGroups"]["byId"]
  >(
    (acc, group) => ({
      ...acc,
      [group[0]]: { id: group[0], mainProductIds: group },
    }),
    {}
  );
  draft.productGroups.allIds = definiteGroups.map((group) => group[0]);

  draft.productGroupIds = definiteGroups.map((group) => group[0]);

  return draft;
};

export const reducer = produce((draft: State, action: Action) => {
  switch (action.type) {
    case ActionType.DELETE_PRODUCTS_AND_RELATED_GROUPS: {
      const productsToDelete = action.payload;

      productsToDelete.forEach((id) => delete draft.products.byId[id]);
      draft.products.allIds = draft.products.allIds.filter(
        (id) => !productsToDelete.includes(id)
      );

      productsToDelete.forEach((id) => {
        // find group that contains the product
        const group = draft.productGroups.allIds.find((groupId) => {
          const group = draft.productGroups.byId[groupId];
          if (!group) return false;
          return group.mainProductIds.includes(id);
        });
        if (group) {
          // delete the products that were in the group
          const productsToDelete =
            draft.productGroups.byId[group].mainProductIds;
          productsToDelete.forEach((id) => delete draft.products.byId[id]);
          draft.products.allIds = draft.products.allIds.filter(
            (id) => !productsToDelete.includes(id)
          );

          // delete the group
          delete draft.productGroups.byId[group];
          draft.productGroups.allIds = draft.productGroups.allIds.filter(
            (groupId) => groupId !== group
          );

          draft.productGroupIds = draft.productGroupIds.filter(
            (groupId) => groupId !== group
          );
        }
      });

      return;
    }

    case ActionType.UPDATE_PRODUCTS: {
      const { productsToAdd, productsToDelete } = action.payload;

      productsToDelete.forEach((id) => delete draft.products.byId[id]);
      draft.products.allIds = draft.products.allIds.filter(
        (id) => !productsToDelete.includes(id)
      );

      productsToAdd.forEach((product) => {
        draft.products.byId[product.main_product_id] = product;
        draft.products.allIds.push(product.main_product_id);
      });

      // Add the products in their own groups of one

      productsToAdd.forEach((product) => {
        draft.productGroups.byId[product.main_product_id] = {
          id: product.main_product_id,
          mainProductIds: [product.main_product_id],
        };
        draft.productGroups.allIds.push(product.main_product_id);
      });

      // Add the products to the beginning of the productGroupIds array

      draft.productGroupIds.unshift(
        ...productsToAdd.map((p) => p.main_product_id)
      );

      // Delete groups that are now empty

      productsToDelete.forEach((id) => {
        const group = draft.productGroups.byId[id];
        if (group) {
          delete draft.productGroups.byId[id];
          draft.productGroups.allIds = draft.productGroups.allIds.filter(
            (groupId) => groupId !== id
          );
        }
      });

      draft.productGroupIds = draft.productGroupIds.filter(
        (groupId) => !productsToDelete.includes(groupId)
      );

      return;
    }

    case ActionType.ADD_PRODUCT_TO_GROUP: {
      const groupToAdd = draft.productGroups.byId[action.payload.groupId];
      if (groupToAdd) {
        groupToAdd.mainProductIds.push(action.payload.productId);
      }
      return;
    }

    case ActionType.DISSOLVE_GROUP: {
      if (draft.productGroups.byId[action.payload.groupId]) {
        const mainProductIdsInGroup =
          draft.productGroups.byId[action.payload.groupId].mainProductIds;
        delete draft.productGroups.byId[action.payload.groupId];
        const indexOfGroupInOrder = draft.productGroupIds.indexOf(
          action.payload.groupId
        );
        //Remove group from productGroupIds
        draft.productGroupIds = draft.productGroupIds.filter(
          (groupId) => groupId !== action.payload.groupId
        );
        draft.productGroups.allIds = draft.productGroups.allIds.filter(
          (groupId) => groupId !== action.payload.groupId
        );

        //Add all products in the group as their own groups
        const newGroupIds: string[] = [];
        mainProductIdsInGroup.forEach((productId) => {
          const newGroupId = productId;
          draft.productGroups.byId[newGroupId] = {
            id: newGroupId,
            mainProductIds: [productId],
          };
          draft.productGroups.allIds.push(newGroupId);
          newGroupIds.push(newGroupId);
        });

        //Add back all new groups, starting at the position of the deleted group
        draft.productGroupIds.splice(indexOfGroupInOrder, 0, ...newGroupIds);
      }
      return;
    }

    case ActionType.REMOVE_PRODUCT_FROM_GROUP: {
      const groupToRemove = draft.productGroups.byId[action.payload.groupId];
      if (groupToRemove) {
        groupToRemove.mainProductIds = groupToRemove.mainProductIds.filter(
          (id) => id !== action.payload.productId
        );
      }
      return;
    }

    case ActionType.MOVE_PRODUCT_GROUP: {
      const { oldIndex, newIndex } = action.payload;
      const [removedGroup] = draft.productGroupIds.splice(oldIndex, 1);
      draft.productGroupIds.splice(newIndex, 0, removedGroup);
      return;
    }

    case ActionType.UPDATE_PRODUCTS_AND_GROUPS: {
      const { products, groups } = action.payload;

      const productsArray = Object.values(products);

      // Filter out the products from 'products' that are not in 'groups'

      const productsInGroups = productsArray.filter((product) =>
        groups?.flat().includes(product.main_product_id)
      );

      draft.loading = false;
      setStateFromProductsAndGroups(draft, productsInGroups, groups);
      return;
    }

    case ActionType.UPDATE_PRODUCTS_GROUPS: {
      const { groups } = action.payload;
      const products = Object.values(draft.products.byId);
      setStateFromProductsAndGroups(draft, products, groups);
      return;
    }

    case ActionType.MOVE_PRODUCT_GROUPS_TO_BEGINNING: {
      const { mainProductIds } = action.payload;

      // Find the groups that contain the products
      const groupIds: string[] = [];

      mainProductIds.forEach((mainProductId) => {
        const groupId = draft.productGroupIds.find((groupId) => {
          const group = draft.productGroups.byId[groupId];
          if (!group) return false;
          return group.mainProductIds.includes(mainProductId);
        });
        if (groupId) groupIds.push(groupId);
      });

      if (groupIds.length === 0) return;
      // Move the groups to the beginning of the productGroupIds array, in the order they were found
      // mind you, there can be gaps in the array, so we need to find the index of the group in the array
      // and then remove it from the array and add it back at the beginning, but we need to do it in reverse
      // order so that the indices don't change
      groupIds.reverse().forEach((groupId) => {
        const groupIndex = draft.productGroupIds.indexOf(groupId);
        if (groupIndex > -1) {
          draft.productGroupIds.splice(groupIndex, 1);
          draft.productGroupIds.unshift(groupId);
        }
      });

      return;
    }

    case ActionType.MOVE_PRODUCT_GROUPS_TO_END: {
      const { mainProductIds } = action.payload;

      // Find the groups that contain the products
      const groupIds: string[] = [];

      mainProductIds.forEach((mainProductId) => {
        const groupId = draft.productGroupIds.find((groupId) => {
          const group = draft.productGroups.byId[groupId];
          if (!group) return false;
          return group.mainProductIds.includes(mainProductId);
        });
        if (groupId) groupIds.push(groupId);
      });

      if (groupIds.length === 0) return;

      // Move the groups to the end of the productGroupIds array, in the order they were found
      // mind you, there can be gaps in the array, so we need to find the index of the group in the array
      // and then remove it from the array and add it back at the end, but we need to do it in reverse
      // order so that the indices don't change
      groupIds.forEach((groupId) => {
        const groupIndex = draft.productGroupIds.indexOf(groupId);
        if (groupIndex > -1) {
          draft.productGroupIds.splice(groupIndex, 1);
          draft.productGroupIds.push(groupId);
        }
      });

      return;
    }

    case ActionType.UNDO_ALL_GROUPS: {
      const products = Object.values(draft.products.byId);
      setStateFromProductsAndGroups(draft, products);
      return;
    }

    default:
      return;
  }
});

import React, { useEffect } from "react";
import useFilteredMarkets from "./useFilteredMarkets";
import { useMerchantId } from "./useMerchantId";

/**
 * useMarket is a React hook that provides a convenient way to access the
 * current market, and the list of available markets for the current merchant.
 *
 * the current market is the first market in the list of available markets.
 * Available markets are filtered by the marketAvailableFilter (that's dependent
 * on the Route).
 */
export const useMarket = () => {
  const { merchantId } = useMerchantId();
  const { markets, loading } = useFilteredMarkets(merchantId);
  const [market, setMarket] = React.useState<null | string>(
    markets.length > 0 ? markets[0].id : null
  );

  useEffect(() => {
    if (markets.length > 0 && !loading) {
      setMarket(markets[0].id);
    } else {
      setMarket(null);
    }
  }, [merchantId, markets, loading]);

  return { market, setMarket, availableMarkets: markets, loading };
};

export default useMarket;

import { forwardRef, LegacyRef } from "react";

const CustomInput = forwardRef(
  (
    { value, onClick, onChange, placeholder, disabled }: any,
    ref: LegacyRef<HTMLInputElement>
  ): any => (
    <input
      type="text"
      className=""
      onClick={onClick}
      onChange={onChange}
      ref={ref}
      value={value}
      data-testid="depict--ReactDatepicker"
      placeholder={placeholder}
      disabled={disabled}
    />
  )
);

export default CustomInput;

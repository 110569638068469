import mergeClassNames from "merge-class-names";
import { useEffect, useState } from "react";
import React from "react";
import { ModalProps } from "src/types/components";
import reactFastCompare from "react-fast-compare";

const Modal = (props: ModalProps) => {
  const {
    title,
    closeButton,
    show,
    buttons,
    scrollable,
    closeCallback,
    openCallback,
    closeOnBackdropClick,
    className,
  } = props;
  const [localShow, setLocalShow] = useState(show || false);

  useEffect(() => {
    setLocalShow(show || false);
    return () => {
      setLocalShow(false);
    };
  }, [show]);

  useEffect((): void => {
    if (localShow && openCallback) {
      openCallback();
    }
  }, [localShow, openCallback]);

  const Tag = ({
    tagName,
    children,
    ...props
  }: {
    tagName: string;
    children: React.ReactNode;
  }) => {
    return React.createElement(tagName, props, children);
  };

  return (
    <>
      {localShow && (
        <div
          className={mergeClassNames([
            "modal-backdrop fade",
            localShow && "show",
          ])}
        ></div>
      )}
      <div
        className={mergeClassNames([
          "modal fade depict--Modal",
          localShow && "show",
          className,
        ])}
        style={{ display: localShow ? "block" : "none" }}
        id="external-modal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="modal"
        aria-hidden="true"
        onClick={(e) => {
          //@ts-ignore
          if (e.target.id !== "external-modal") {
            return;
          }
          if (closeOnBackdropClick) {
            setLocalShow(false);
            if (closeCallback) {
              closeCallback();
            }
          }
        }}
      >
        <div
          className={mergeClassNames([
            "modal-dialog",
            scrollable && "modal-dialog-scrollable",
          ])}
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <div className="modal-title" id="modal">
                {title}
              </div>
              {closeButton && (
                <button
                  className="btn-close"
                  type="button"
                  onClick={() => {
                    setLocalShow(false);
                    if (closeCallback) {
                      closeCallback();
                    }
                  }}
                  aria-label="Close"
                ></button>
              )}
            </div>
            <div className="modal-body border-bottom-0 pb-0 pt-0">
              {props.children}
            </div>
            <div className="modal-footer border-top-0">
              {buttons &&
                buttons.map((button, index) => {
                  return (
                    <Tag
                      tagName={button.tag || "button"}
                      //@ts-ignore
                      className={button.className}
                      key={index}
                      onClick={() => {
                        button.onClick && button.onClick();
                      }}
                    >
                      {button.icon}
                      {button.label}
                    </Tag>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Modal, reactFastCompare);

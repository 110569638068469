import OnboardingProductOverviewProductCard from "./OnboardingProductOverviewProductCard";
import { useState } from "react";
import RecommendationsVideo from "src/assets/img/recommendations.webm";
import SearchVideo from "src/assets/img/search.webm";
import PlpVideo from "src/assets/img/category_listings.webm";

export enum ProductOverviewStep {
  SEARCH,
  PLP,
  RECOMMENDATIONS,
}

export default function OnboardingProductOverview({
  onContinue,
  productOverviewSteps,
}: {
  onContinue: () => void;
  productOverviewSteps: ProductOverviewStep[];
}) {
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

  const handleNext = () => {
    if (currentStepIndex < productOverviewSteps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    } else {
      onContinue();
    }
  };

  switch (productOverviewSteps[currentStepIndex]) {
    case ProductOverviewStep.PLP:
      return (
        <OnboardingProductOverviewProductCard
          title="Product Listing Pages"
          description="Create your category and collection pages easily, combining AI with full flexibility to curate your pages however you want."
          step={currentStepIndex + 1}
          numberOfSteps={productOverviewSteps.length}
          onNext={handleNext}
          onSkip={() => {
            onContinue();
          }}
          videoSource={PlpVideo}
        />
      );
    case ProductOverviewStep.SEARCH:
      return (
        <OnboardingProductOverviewProductCard
          title="Search"
          description="Present precise fashion-related search results for every shopper, creating an engaging experience that has them stay on-site longer."
          step={currentStepIndex + 1}
          numberOfSteps={productOverviewSteps.length}
          onNext={handleNext}
          onSkip={() => {
            onContinue();
          }}
          videoSource={SearchVideo}
        />
      );
    case ProductOverviewStep.RECOMMENDATIONS:
      return (
        <OnboardingProductOverviewProductCard
          title="Recommendations"
          description="Deliver personalized product recommendations automatically across your entire site, saving you time and manual effort from day one."
          step={currentStepIndex + 1}
          numberOfSteps={productOverviewSteps.length}
          onNext={handleNext}
          onSkip={() => {
            onContinue();
          }}
          videoSource={RecommendationsVideo}
        />
      );
  }

  return null;
}

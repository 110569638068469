import { Button } from "react-bootstrap";
import DottedProgressBar from "../../../DottedProgressBar/DottedProgressBar";
import Card from "../../../Card/Card";

export default function OnboardingProductOverviewProductCard({
  title,
  description,
  step,
  numberOfSteps,
  onSkip,
  onNext,
  videoSource,
}: {
  title: string;
  description: string;
  step: number;
  numberOfSteps: number;
  onSkip: () => void;
  onNext: () => void;
  videoSource: string;
}) {
  return (
    <Card>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{description}</Card.Text>
        <div className={"d-flex justify-content-center"}>
          <video
            key={videoSource}
            style={{
              objectFit: "cover",
              width: 350,
              height: 300,
            }}
            autoPlay
          >
            <source src={videoSource} type="video/webm" />
          </video>
        </div>
        <div className="d-flex justify-content-center my-4">
          <DottedProgressBar currentStep={step} numberOfSteps={numberOfSteps} />
        </div>
        <Button
          className="w-100"
          onClick={() => {
            onNext();
          }}
        >
          {step === numberOfSteps ? "Continue" : "Next"}
        </Button>
        {step !== numberOfSteps && (
          <Button
            onClick={() => onSkip()}
            variant="link"
            className="w-100 mt-1"
          >
            Skip product overview
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}

import { Alert, Button, Col, Row } from "react-bootstrap";
import Card from "src/components/storybook/Card/Card";

import { MetafieldParsingConfig } from "../../Integrations/Shopify/types";
import LoadingBadge from "../../LoadingBadge/LoadingBadge";
import IntegrationStepHeader from "../IntegrationStepHeader/IntegrationStepHeader";

export default function ShopifyCompleteIntegration({
  configuredMetafieldsParsers,
  isLoading,
  errorMessage,
  success,
  onComplete,
  onSubmit,
  onBack,
}: {
  configuredMetafieldsParsers: MetafieldParsingConfig[];
  isLoading: boolean;
  errorMessage: string | null;
  onSubmit: () => void;
  onComplete: () => void;
  success: boolean;
  onBack: () => void;
}) {
  return (
    <Card>
      <Card.Body>
        <IntegrationStepHeader
          title="Complete data integration with Shopify"
          stepNumber={5}
        />
        <Card.Text>
          By accepting we will create the following changes:
          <ul>
            <li>Save your Shopify API credentials to Depict.</li>
            <li>
              Create a Shopify Storefront API token called &quot;Depict
              Integration Token&quot;.
            </li>
            {configuredMetafieldsParsers.length > 0 && (
              <li>
                Give Storefront access to the following metafields on your
                Shopify metafields
                <ul>
                  {configuredMetafieldsParsers.map((mf, index) => (
                    <li key={index}>
                      {mf.key} ({mf.namespace})
                    </li>
                  ))}
                </ul>
              </li>
            )}
          </ul>
          Warning, if you have an existing Shopify integration at Depict, this
          will overwrite the existing configuration.
        </Card.Text>
        {errorMessage && <Alert variant="danger">Error: {errorMessage}</Alert>}
        {isLoading && (
          <LoadingBadge label="Completing Shopify integration..." />
        )}
      </Card.Body>
      <Card.Footer>
        <Row>
          {!success && (
            <Col>
              <Button
                variant="outline-secondary"
                className="w-100"
                onClick={onBack}
                disabled={isLoading}
              >
                Back
              </Button>
            </Col>
          )}
          <Col>
            <Button
              variant="primary"
              className="w-100"
              onClick={success ? onComplete : onSubmit}
              disabled={isLoading || errorMessage !== null}
            >
              {success ? "Continue" : "Accept"}
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
}

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Clock2 = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M15.129 7.25c-0.138-0.239-0.444-0.321-0.683-0.183l-4.92 2.841-3.835-2.685c-0.226-0.158-0.538-0.103-0.696 0.123s-0.103 0.538 0.123 0.696l4.096 2.868c0.001 0.001 0.002 0.001 0.003 0.002 0.008 0.006 0.017 0.011 0.025 0.016 0.003 0.002 0.005 0.003 0.008 0.005 0.008 0.005 0.017 0.009 0.025 0.014 0.003 0.001 0.005 0.003 0.008 0.004 0.010 0.005 0.020 0.009 0.030 0.013 0.007 0.003 0.013 0.005 0.020 0.007 0.004 0.001 0.008 0.003 0.012 0.004 0.007 0.002 0.014 0.004 0.022 0.006 0.004 0.001 0.008 0.002 0.011 0.003 0.007 0.002 0.014 0.003 0.022 0.005 0.004 0.001 0.008 0.002 0.012 0.002 0.007 0.001 0.014 0.002 0.021 0.003 0.005 0.001 0.010 0.001 0.015 0.002 0.006 0.001 0.012 0.001 0.018 0.002 0.009 0.001 0.018 0.001 0.027 0.001 0.002 0 0.004 0 0.006 0 0 0 0-0 0-0s0 0 0.001 0c0.019 0 0.037-0.001 0.056-0.003 0.001-0 0.002-0 0.003-0 0.018-0.002 0.036-0.005 0.054-0.010 0.002-0 0.003-0.001 0.005-0.001 0.017-0.004 0.034-0.009 0.051-0.015 0.003-0.001 0.005-0.002 0.008-0.003 0.016-0.006 0.031-0.013 0.047-0.020 0.003-0.002 0.006-0.003 0.010-0.005 0.006-0.003 0.011-0.006 0.017-0.009l5.196-3c0.239-0.138 0.321-0.444 0.183-0.683z" fill={color}></path>
<path d="M16.32 17.113c1.729-1.782 2.68-4.124 2.68-6.613 0-2.37-0.862-4.608-2.438-6.355l0.688-0.688 0.646 0.646c0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146c0.195-0.195 0.195-0.512 0-0.707l-2-2c-0.195-0.195-0.512-0.195-0.707 0s-0.195 0.512 0 0.707l0.646 0.646-0.688 0.688c-1.747-1.576-3.985-2.438-6.355-2.438s-4.608 0.862-6.355 2.438l-0.688-0.688 0.646-0.646c0.195-0.195 0.195-0.512 0-0.707s-0.512-0.195-0.707 0l-2 2c-0.195 0.195-0.195 0.512 0 0.707 0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146l0.646-0.646 0.688 0.688c-1.576 1.747-2.438 3.985-2.438 6.355 0 2.489 0.951 4.831 2.68 6.613l-2.034 2.034c-0.195 0.195-0.195 0.512 0 0.707 0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146l2.060-2.060c1.705 1.428 3.836 2.206 6.087 2.206s4.382-0.778 6.087-2.206l2.059 2.059c0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146c0.195-0.195 0.195-0.512 0-0.707l-2.034-2.034zM10 18.985v-0.485c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v0.485c-4.29-0.25-7.735-3.695-7.985-7.985h0.485c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-0.485c0.25-4.29 3.695-7.735 7.985-7.985v0.485c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-0.485c4.29 0.25 7.735 3.695 7.985 7.985h-0.485c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h0.485c-0.25 4.29-3.695 7.735-7.985 7.985z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Clock2); 
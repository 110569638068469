import {
  BoostAndBuryPreviewCard,
  BoostAndBuryPreviewCardProps,
} from "../BoostAndBuryPreview/BoostAndBuryPreviewCard";
import { Hoodie, ShirtFolded, TShirt } from "@phosphor-icons/react";
import { theme } from "../../designSystemVariables";

const foldedShirtIcon = (
  <ShirtFolded size={32} color={theme.textIcon.subtle.disabled} />
);
const tShirtIcon = <TShirt size={32} color={theme.textIcon.subtle.disabled} />;
const hoodieIcon = <Hoodie size={32} color={theme.textIcon.subtle.disabled} />;

const CARD_ROWS: BoostAndBuryPreviewCardProps[][] = [
  [
    {
      effect: "boost",
      options: ["new_products", "best_selling"],
      icon: foldedShirtIcon,
    },
    { effect: "boost", options: ["new_products"], icon: tShirtIcon },
    { effect: "boost", options: ["best_selling"], icon: foldedShirtIcon },
    { effect: false, options: ["new_products"], icon: hoodieIcon },
  ],
  [
    { effect: "boost", options: [], icon: tShirtIcon },
    { effect: false, options: ["best_selling"], icon: foldedShirtIcon },
    { effect: false, options: [], icon: tShirtIcon },
    { effect: false, options: [], icon: tShirtIcon },
  ],
  [
    { effect: false, options: [], icon: foldedShirtIcon },
    { effect: "bury", options: [], icon: hoodieIcon },
    { effect: false, options: ["out_of_stock"], icon: foldedShirtIcon },
    { effect: "bury", options: ["out_of_stock"], icon: hoodieIcon },
  ],
];

export const BoostAndBuryStaticExplainer = () => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center h-100 relative"
      style={{ minWidth: "500px", minHeight: "550px" }}
    >
      {CARD_ROWS.map((row, rowIndex) => (
        <div
          key={rowIndex}
          className={`d-flex flex-row justify-content-center align-items-center relative h-100 w-100 mb-2`}
        >
          {row.map((cardProps, cardIndex) => (
            <BoostAndBuryPreviewCard
              key={cardIndex}
              {...cardProps}
              showPlaceholder={false}
              squareVersion={true}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default BoostAndBuryStaticExplainer;

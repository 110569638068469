 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Joystick = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M13 3.5c0-1.93-1.57-3.5-3.5-3.5s-3.5 1.57-3.5 3.5c0 1.76 1.306 3.221 3 3.464v4.536c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-4.536c1.694-0.243 3-1.704 3-3.464zM9.5 6c-1.378 0-2.5-1.122-2.5-2.5s1.122-2.5 2.5-2.5 2.5 1.122 2.5 2.5-1.122 2.5-2.5 2.5z" fill={color}></path>
<path d="M9 2.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5z" fill={color}></path>
<path d="M9.5 14c-0.642 0-1.251-0.192-1.714-0.539-0.499-0.375-0.786-0.907-0.786-1.461 0-0.52 0.244-1.012 0.688-1.383 0.212-0.177 0.527-0.15 0.704 0.062s0.149 0.527-0.062 0.704c-0.151 0.126-0.331 0.336-0.331 0.617 0 0.542 0.687 1 1.5 1s1.5-0.458 1.5-1c0-0.281-0.18-0.491-0.331-0.617-0.212-0.177-0.239-0.493-0.062-0.704s0.493-0.239 0.704-0.062c0.444 0.372 0.688 0.863 0.688 1.383 0 0.554-0.286 1.086-0.786 1.461-0.464 0.348-1.073 0.539-1.714 0.539z" fill={color}></path>
<path d="M17.829 11.106l-6.106-3.053c-0.247-0.123-0.547-0.023-0.671 0.224s-0.023 0.547 0.224 0.671l6.106 3.053c0.086 0.043 0.169 0.108 0.246 0.187l-7.494 4.142c-0.331 0.183-0.935 0.183-1.267 0l-7.495-4.142c0.077-0.079 0.16-0.144 0.246-0.187l6.106-3.053c0.247-0.123 0.347-0.424 0.224-0.671s-0.424-0.347-0.671-0.224l-6.106 3.053c-0.657 0.328-1.171 1.16-1.171 1.894v0.5c0 0.734 0.478 1.566 1.112 1.936l7.272 4.242c0.313 0.183 0.714 0.274 1.116 0.274s0.803-0.091 1.116-0.274l7.272-4.242c0.634-0.37 1.112-1.202 1.112-1.936v-0.5c0-0.734-0.514-1.566-1.171-1.894zM17.384 14.572l-7.273 4.242c-0.32 0.187-0.903 0.187-1.224 0l-7.272-4.242c-0.322-0.188-0.616-0.699-0.616-1.072v-0.376l7.383 4.080c0.318 0.176 0.718 0.264 1.117 0.264s0.799-0.088 1.117-0.264l7.383-4.080v0.376c0 0.373-0.293 0.884-0.616 1.072z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Joystick); 
import Cache from "src/helpers/cache";
import { ProductsApiResult } from "src/types/api";
import productsApi from "src/api/products";

type MiddlewareCacheKey = {
  merchant_id: string;
  market_id: string;
};

const productMiddleware = (function () {
  let cache: Cache = new Cache();

  return {
    get: async function (
      idToken: string,
      merchant_id: string,
      market_id: string
    ): Promise<ProductsApiResult> {
      let cacheKey: MiddlewareCacheKey = {
        merchant_id: merchant_id,
        market_id: market_id,
      };

      if (cache.has(cacheKey)) {
        /**
         * TODO: MERCHANT_IT / MARKET_ID are not enough as a cache key in case we save this in some sort of localStorage.
         * What happens if two users log in on same browser and have different permissions?
         * One idea is to scope the whole Cache under a user_id key.
         */
        console.log(
          "[CACHE] Loading %c" +
            merchant_id +
            " for " +
            market_id +
            " %c products.",
          "font-weight: bold",
          "font-weight: normal"
        );
        return cache.get(cacheKey);
      }

      const result = await productsApi({ idToken, merchant_id, market_id });

      if (!result.success) {
        return result;
      }

      cache.set(cacheKey, result);

      return result;
    },
    getCache: function (): Cache {
      return cache;
    },
  };
})();

export default productMiddleware;

import { theme } from "../designSystemVariables";

import React, { useEffect, useState } from "react";
import useMerchant from "src/helpers/hooks/app/useMerchant";
import { Button } from "react-bootstrap";
import { Plus } from "@phosphor-icons/react";
import ContentTable from "./ContentTable";
import { useContentSearch } from "src/helpers/hooks/content-search/useContentSearch";
import useAvailableDimensionKeys from "src/helpers/hooks/catalog/useAvailableDimensionKeys";
import { useLocation, useNavigate } from "react-router-dom";
import { getLocaleLabel } from "src/helpers/misc/locale";
import { CategoryHeaderDropdown } from "../Categories/CategoryHeaderDropdown/CategoryHeaderDropdown";

function ContentSearch() {
  const navigate = useNavigate();
  const location = useLocation();
  const { merchantId } = useMerchant();
  const { localeIds, defaults } = useAvailableDimensionKeys(merchantId);

  const [isAddingContent, setIsAddingContent] = useState<boolean>(false);

  const localeOptions = localeIds.map((localeId) => ({
    value: localeId,
    label: getLocaleLabel(localeId),
  }));

  const [selectedLocaleId, setSelectedLocaleId] = useState<string>("");

  const { content, contentIsLoading, updateContent, deleteContent } =
    useContentSearch(merchantId, selectedLocaleId);

  useEffect(() => {
    if (defaults?.locale && selectedLocaleId.length < 1) {
      setSelectedLocaleId(defaults.locale);
    }
  }, [defaults, selectedLocaleId]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const { locale } = Object.fromEntries(searchParams.entries());
    if (locale) {
      setSelectedLocaleId(locale);
    }
  }, [location.search]);

  return (
    <div>
      <div
        style={{
          background: theme.background.base.default,
          borderRadius: "16px",
          textAlign: "left",
        }}
      >
        <div className="d-flex justify-content-between align-items-end pb-2">
          <div className="d-flex align-items-end">
            <CategoryHeaderDropdown
              label="Language"
              optionGroups={[
                {
                  label: "Available languages",
                  options: localeOptions,
                },
              ]}
              selectedValue={
                localeOptions.find(
                  (option) => option.value === selectedLocaleId
                )?.value || ""
              }
              onSelect={(value) => {
                navigate(
                  value
                    ? `/${merchantId}/search?view=content&locale=${value}`
                    : `/${merchantId}/search?view=content`,
                  { replace: true }
                );
              }}
            />
          </div>
          <div className="d-flex align-items-end">
            <Button
              data-tc="activate-collection"
              variant={"primary"}
              onClick={() => setIsAddingContent(true)}
            >
              <Plus size="14" className="me-2" />
              Add content
            </Button>
          </div>
        </div>
        <div>
          {contentIsLoading && <div>Loading...</div>}
          {!contentIsLoading && content !== undefined && (
            <ContentTable
              items={content}
              localeId={selectedLocaleId}
              updateContent={updateContent}
              deleteContent={deleteContent}
              isAddingContent={isAddingContent}
              setIsAddingContent={setIsAddingContent}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ContentSearch;

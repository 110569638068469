import { useTranslation } from "react-i18next";

interface AxisTooltipsProps {
  children?: any;
  i18nKeys?: {
    bottom?: string;
    left?: string;
  };
}

const AxisTooltips = (props: AxisTooltipsProps) => {
  const { t } = useTranslation();

  const { children, i18nKeys } = props;
  return (
    <div className="depict--AxisTooltipsWrapper">
      {i18nKeys && i18nKeys.left && (
        <div
          className="depict--AxisTooltip__left depict--AxisTooltip"
          data-bs-toggle="tooltip"
          title={t(i18nKeys.left)}
          data-bs-placement="right"
        ></div>
      )}
      {i18nKeys && i18nKeys.bottom && (
        <div
          className="depict--AxisTooltip__bottom depict--AxisTooltip"
          data-bs-toggle="tooltip"
          title={t(i18nKeys.bottom)}
          data-bs-placement="bottom"
        ></div>
      )}
      {children}
    </div>
  );
};

export default AxisTooltips;

import { theme } from "../designSystemVariables";

import React, { useState, useRef, useEffect } from "react";
import { Check, Plus, X } from "@phosphor-icons/react";
import { Button } from "react-bootstrap";
import { css } from "@emotion/react";

export default function ContentKeywords({
  keywords,
  setKeywords,
}: {
  keywords: string[];
  setKeywords: (keywords: string[]) => void;
}) {
  const [isAddingKeyword, setIsAddingKeyword] = useState<boolean>(false);
  const [newKeyword, setNewKeyword] = useState<string>("");
  const keywordInputRef = useRef<HTMLInputElement>(null);

  const deleteKeyword = (keyword: string) => {
    setKeywords(keywords.filter((k) => k !== keyword));
  };

  useEffect(() => {
    if (isAddingKeyword && keywordInputRef.current) {
      keywordInputRef.current.focus();
    }
  }, [isAddingKeyword]);

  const addKeyword = (keyword: string) => {
    if (!keyword) {
      return;
    }

    setKeywords([...keywords, keyword]);
  };

  return (
    <>
      {keywords.length === 0 && !isAddingKeyword && (
        <span
          style={{ color: theme.textIcon.inverse.disabled }}
          className="me-2 d-inline-block"
        >
          No keywords added yet
        </span>
      )}
      {keywords.length > 0 &&
        keywords.map((keyword, index) => (
          <span
            key={`${keyword}-${index}`}
            className="badge me-2 mb-2"
            style={{ fontSize: "1em" }}
            css={css`
              background: ${theme.background.base.hover};
              border: 1px solid ${theme.border.subtle.disabled};
            `}
          >
            {keyword}
            <X
              size={12}
              className="ms-1"
              style={{ cursor: "pointer" }}
              onClick={() => deleteKeyword(keyword)}
            />
          </span>
        ))}

      {isAddingKeyword && (
        <>
          <input
            type="text"
            className="new-keyword-input"
            value={newKeyword}
            ref={keywordInputRef}
            onChange={(event) => setNewKeyword(event.target.value)}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                addKeyword(newKeyword);
                setNewKeyword("");
                setIsAddingKeyword(false);
              }
            }}
          />
          <Button
            variant="outline-secondary"
            style={{ padding: "2px" }}
            className="badge"
            onClick={() => {
              addKeyword(newKeyword);
              setNewKeyword("");
              setIsAddingKeyword(false);
            }}
          >
            <Check size={12} />
          </Button>
          <Button
            variant="outline-secondary"
            style={{
              padding: "2px",
              marginLeft: "0.3em",
            }}
            className="badge"
            onClick={() => {
              setIsAddingKeyword(false);
              setNewKeyword("");
            }}
          >
            <X size={12} />
          </Button>
        </>
      )}
      {!isAddingKeyword && (
        <Button
          variant="outline-secondary"
          style={{ padding: "2px" }}
          className="badge"
          onClick={() => setIsAddingKeyword(true)}
        >
          <Plus size={12} />
        </Button>
      )}
    </>
  );
}

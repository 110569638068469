 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Voicemail = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M15 6c-2.206 0-4 1.794-4 4 0 1.194 0.526 2.266 1.357 3h-5.715c0.832-0.734 1.357-1.806 1.357-3 0-2.206-1.794-4-4-4s-4 1.794-4 4 1.794 4 4 4h11c2.206 0 4-1.794 4-4s-1.794-4-4-4zM1 10c0-1.654 1.346-3 3-3s3 1.346 3 3-1.346 3-3 3-3-1.346-3-3zM15 13c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Voicemail); 
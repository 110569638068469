import React from "react";
import Select, { SingleValue } from "react-select";
import { DropDownSelectorProps } from "src/types/components";

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    fontSize: "0.75rem",
    fontWeight: "400",
  }),
  menu: (provided: any) => ({
    ...provided,
    fontSize: "1rem",
    minWidth: "285px",
    boxShadow: "2px 2px 8px 4px rgba(188,188,190,0.1)",
  }),
  control: (provided: any) => ({
    ...provided,
    background: "transparent",
    border: 0,
    boxShadow: "0 0 0 0px",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: "none",
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23333335' stroke-linecap='round' stroke-linejoin='round' stroke-width='0.5' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "16px 12px",
    color: "#0f0f0f",
  }),
  option: (provided: any, state: any) => {
    let bgColor = state.isFocused ? "#eeeef0" : "transparent";
    if (state.isSelected) {
      bgColor = "#dfecfd";
    }

    return {
      ...provided,
      paddingLeft: "24px",
      paddingRight: "24px",
      color: "#0f0f0f",
      textTransform: "capitalize",
      cursor: "pointer",
      fontWeight: "normal",
      backgroundColor: bgColor,
    };
  },
  singleValue: (provided: any) => ({
    ...provided,
    textTransform: "capitalize",
    marginLeft: 0,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: "2px 0px",
  }),
};

const groupStyles = {
  paddingLeft: "12px",
  paddingRight: "12px",
  fontSize: "0.5rem",
  textTransform: "none",
  color: "#868689",
} as any;

const DropDownSelector = (props: DropDownSelectorProps) => {
  const { selected, options, newSelection } = props;

  const selectNewOption = (
    option: SingleValue<{
      label: string | undefined;
      value: string | undefined;
    }>
  ) => {
    if (selected.value === option?.value) {
      return;
    }
    newSelection(option);
  };

  return (
    <div className="depict--DropDownSelect" style={{ width: "125px" }}>
      {options && options.length > 1 && (
        <Select
          styles={customStyles}
          formatGroupLabel={(data: any) => (
            <div style={groupStyles}>
              <span>{data.label}</span>
            </div>
          )}
          onChange={selectNewOption}
          value={selected}
          options={[
            {
              options: options,
            },
          ]}
        />
      )}
    </div>
  );
};

export default React.memo(DropDownSelector);

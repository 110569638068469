export default function Footer({
  darkMode = false,
  fluidContainer = true,
}: {
  darkMode?: boolean;
  fluidContainer?: boolean;
}) {
  const footerClasses = `footer-admin mt-auto ${
    darkMode ? "footer-dark" : "footer-light"
  }`;
  const containerClasses = `${
    fluidContainer ? "container-fluid" : "container-xl"
  } px-4`;

  return (
    <>
      <footer className={footerClasses}>
        <div className={containerClasses}>
          <div className="row">
            <div className="col-md-12">
              {" "}
              Copyright &copy; Depict {new Date().getFullYear()}
            </div>
            {/* <div className="col-md-6 text-md-end">
              <a href="/">Privacy Policy</a>
              &middot;
              <a href="/">Terms &amp; Conditions </a>
            </div> */}
          </div>
        </div>
      </footer>
    </>
  );
}

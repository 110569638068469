 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const CloudCheck = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M16 16h-9.5c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h9.5c1.654 0 3-1.346 3-3s-1.346-3-3-3c-0.343 0-0.68 0.057-1 0.17-0.217 0.077-0.458-0.005-0.584-0.197s-0.105-0.446 0.051-0.615c0.344-0.371 0.533-0.853 0.533-1.359 0-1.103-0.897-2-2-2-1.055 0-1.931 0.823-1.996 1.875-0.013 0.212-0.159 0.393-0.363 0.45s-0.423-0.020-0.545-0.194c-0.937-1.334-2.468-2.131-4.096-2.131-2.757 0-5 2.243-5 5 0 0.152 0.007 0.305 0.020 0.455 0.025 0.275-0.178 0.518-0.453 0.543s-0.518-0.178-0.543-0.453c-0.016-0.18-0.024-0.363-0.024-0.545 0-3.308 2.692-6 6-6 1.611 0 3.143 0.65 4.261 1.776 0.471-1.050 1.527-1.776 2.739-1.776 1.654 0 3 1.346 3 3 0 0.346-0.059 0.685-0.172 1.004 0.057-0.002 0.115-0.004 0.172-0.004 2.206 0 4 1.794 4 4s-1.794 4-4 4z" fill={color}></path>
<path d="M3.5 15.5c-0.128 0-0.256-0.049-0.354-0.146l-2-2c-0.195-0.195-0.195-0.512 0-0.707s0.512-0.195 0.707 0l1.646 1.646 4.646-4.646c0.195-0.195 0.512-0.195 0.707 0s0.195 0.512 0 0.707l-5 5c-0.098 0.098-0.226 0.146-0.354 0.146z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(CloudCheck); 
import { Product } from "../../types";
import { OnSelect } from "../../BulkEditBar/BulkEditBar.types";
import { noSelectCss } from "../../css.helper";
import { theme } from "../../designSystemVariables";
import { useMemo } from "react";
import useSelectionHandler from "../../../../helpers/hooks/useSelectionHandler";
import { Checkbox } from "../../Checkbox/Checkbox";
import { Image } from "react-bootstrap";
import { css } from "@emotion/react";
import ProductBadge from "../../ProductBadge/ProductBadge";
import { useCatalogMetadata } from "../../../../helpers/hooks/catalog/useCatalogMetadata";
import { ProductMetadata } from "../../../../helpers/catalog/ProductMetadataProvider";
import { useImageResize } from "../../ImageResizeProvider/ImageResizeProvider";
import { Link } from "react-router-dom";
import { Eye, EyeClosed } from "@phosphor-icons/react";

export interface CatalogProductListRowProps {
  index: number | undefined;
  disableIndex: boolean;
  product: Product;
  onSelectClick?: OnSelect | undefined;
  showSelectCheckbox?: boolean;
  isSelected?: boolean;
  highlight: boolean;
  isInSelectMode: boolean;
  linkToProductDetails: string;
  oosAutoHideState: boolean;
}

const collectionRelevantMetadataTypes = [
  "out_of_stock",
  "low_stock",
  "on_sale",
  "new_in",
  "bestseller",
  "trending",
  "slow_mover",
  "inactive",
];

export default function CatalogProductListRow(
  props: CatalogProductListRowProps
) {
  const { getImageUrl } = useImageResize();

  const selectionHandler = useSelectionHandler(
    props.onSelectClick,
    Boolean(props.isSelected),
    props.product.main_product_id
  );

  const borderColor = props.isSelected
    ? theme.border.base.default
    : props.highlight
    ? theme.border.accent.default
    : "#E4E4E5";

  const { productMetadata: allProductMetadata } = useCatalogMetadata();
  const productMetadata: ProductMetadata[] = useMemo(() => {
    return allProductMetadata?.[props.product.main_product_id] ?? [];
  }, [allProductMetadata, props.product.main_product_id]);
  const collectionRelevantMetadata = productMetadata.filter((md) => {
    if (md.type === undefined) return false;
    return collectionRelevantMetadataTypes.includes(md.type);
  });

  const is_product_oos = productMetadata.some((p) => p.type === "out_of_stock");

  return (
    <div
      css={[
        noSelectCss,
        `
          user-select: none;
          display: flex;
          align-items: center;
          padding: 8px;
          background-color: ${theme.background.base.default};
          border: 1px solid ${borderColor};
          ${
            !(props.isSelected || props.highlight) &&
            "border-bottom: 1px solid transparent;"
          }
          
          &:hover {
            background-color: ${theme.background.base.hover};
            
            .details-preview-button {
              display: block;
            }
          }
          
          &:first-child {
            border-radius: 16px 16px 0 0;
          }
          
          &:last-child {
            border-radius: 0 0 16px 16px;
            border-bottom: 1px solid ${borderColor};
          }
        `,
      ]}
      onClick={selectionHandler}
    >
      {props.isInSelectMode && props.showSelectCheckbox && (
        <Checkbox
          checked={Boolean(props?.isSelected)}
          onChange={(alreadyChecked) => {
            props.onSelectClick?.(
              props.product.main_product_id,
              alreadyChecked
            );
          }}
          style={{
            width: 20,
            height: 20,
            marginRight: "8px",
          }}
        />
      )}
      <Image
        src={getImageUrl(props.product.image_url || "", 80)}
        alt={props.product.title}
        style={{
          width: "40px",
          height: "40px",
          objectFit: "cover",
          marginRight: "8px",
        }}
        rounded
      />
      <div
        css={css`
          margin-right: 8px;
        `}
      >
        <h3
          css={css`
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px; /* 142.857% */
            margin: 0;
          `}
        >
          {props.product.title}
        </h3>
        <h5
          css={css`
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            color: #868689;
            line-height: 16px; /* 133.333% */
            margin: 0;
          `}
        >
          {props.product.main_product_id}
        </h5>
      </div>
      <div
        className="d-flex align-items-center gap-1 nowrap"
        css={css`
          margin-left: auto;
        `}
      >
        {collectionRelevantMetadata?.map((md, index) => (
          <ProductBadge
            metadata={md}
            key={`${md.type}-${index}`}
            oos_auto_hide={props.oosAutoHideState}
          />
        ))}
      </div>
      {is_product_oos && props.oosAutoHideState && (
        <div
          css={css`
            display: ${props.isInSelectMode ? "none" : "block"};
            margin-left: 1rem;
          `}
        >
          <EyeClosed />
        </div>
      )}
      <div
        className="details-preview-button"
        css={css`
          margin-left: 16px;
          padding: 8px 16px;
          border-left: 1px solid #cbcbcc;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          display: none;
        `}
      >
        <Link
          to={props.linkToProductDetails}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          Open details{" "}
          <Eye
            size={16}
            css={css`
              margin-left: 8px;
            `}
          />
        </Link>
      </div>
    </div>
  );
}

import { SelectedFilter } from "../../AddProductsModal/Filters";
import { DeepAny, PortalSearchFilter, PortalSortModel } from "src/api/types";
import { css } from "@emotion/react";
import { SearchField } from "../../SearchField/SearchField";
import { Button } from "react-bootstrap";
import { theme } from "../../designSystemVariables";
import { ReactSelectedFilters } from "@depict-ai/portal-components";
import { en } from "@depict-ai/react-ui/locales/latest";
import { CategoryHeaderDropdown } from "../../Categories/CategoryHeaderDropdown/CategoryHeaderDropdown";
import { IconButton } from "../../IconButton/IconButton";
import { ListBullets, SquaresFour } from "@phosphor-icons/react";
import SwitchButton from "../../SwitchButton/SwitchButton";
import useAuthorizedApi, {
  AuthorizedApi,
} from "../../../../helpers/hooks/app/useAuthorizedApi";
import { getData } from "../../../../api/authorizedApi";
import useMerchant from "../../../../helpers/hooks/app/useMerchant";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useMutation } from "@tanstack/react-query";
import queryClient from "../../../../queries/client";
import {
  categoryQueries,
  getQueryKey,
  QueryId,
} from "../../../../queries/queries";

function getSortId(sort: PortalSortModel) {
  return `${sort.field}_${sort.order}`;
}

function getSortLabel(sort: PortalSortModel) {
  if (sort.field === "_relevance") {
    return "By Depict";
  } else if (sort.field === "sale_price") {
    if (sort.order === "desc") {
      return "Price high to low";
    } else {
      return "Price low to high";
    }
  } else if (sort.field === "product_timestamp" && sort.order === "desc") {
    return "Newest";
  } else {
    return `${sort.field} ${sort.order}`;
  }
}

const updateAutoHideStateApi = async (
  api: AuthorizedApi,
  merchantId: string,
  autoHideType: "all_sizes_out_of_stock",
  checked: boolean
) => {
  const response = await api.PUT(
    "/api/v0/catalog/products/{merchant_id}/auto_hide/{auto_hide_type}",
    {
      params: {
        query: {
          activate: checked,
        },
        path: {
          merchant_id: merchantId,
          auto_hide_type: autoHideType,
        },
      },
    }
  );

  const data = getData(response);

  return data;
};

export function CatalogSearchFilterSortBar({
  searchTerm,
  availableFilters,
  selectedFilters,
  onSetFilters,
  availableSorts,
  selectedSort,
  onSetSearchQuery,
  onSetSort,
  currentMode,
  setCurrentMode,
  expandedHierarchicalFilters,
  setExpandedHierarchicalFilters,
  filtersIsOpen,
  setFiltersIsOpen,
  useOosAutoHideToggle,
  oosAutoHideState,
}: {
  searchTerm: string;
  availableFilters?: DeepAny<PortalSearchFilter>[] | null;
  selectedFilters: SelectedFilter[];
  onSetFilters: (filters: SelectedFilter[]) => void;
  availableSorts?: PortalSortModel[] | null;
  selectedSort: PortalSortModel;
  onSetSearchQuery: (searchQuery: string) => void;
  onSetSort: (sort: PortalSortModel) => void;
  currentMode: "grid" | "list";
  setCurrentMode: (mode: "grid" | "list") => void;
  expandedHierarchicalFilters: { value_: string[]; expanded_: boolean }[];
  setExpandedHierarchicalFilters: (
    expandedHierarchicalFilters: { value_: string[]; expanded_: boolean }[]
  ) => void;
  filtersIsOpen: boolean;
  setFiltersIsOpen: (isOpen: boolean) => void;
  useOosAutoHideToggle: boolean;
  oosAutoHideState: boolean;
}) {
  const sortOptions = [
    {
      label: "Sorting options",
      options:
        availableSorts?.map((sort) => ({
          label: getSortLabel(sort),
          value: getSortId(sort),
        })) || [],
    },
  ];

  const { api } = useAuthorizedApi();
  const { merchantId } = useMerchant();

  const tooltip = (
    <Tooltip id="tooltip">
      {
        "All out of stock products will be hidden from product lists and search results"
      }
    </Tooltip>
  );

  const mutationKey = getQueryKey(QueryId.GetAutoHide, merchantId);

  const mutation = useMutation({
    mutationFn: async (checked: boolean) => {
      if (!merchantId || !api) return;
      return await updateAutoHideStateApi(
        api,
        merchantId,
        "all_sizes_out_of_stock",
        checked
      );
    },
    onMutate: (newChecked) => {
      const previousChecked: boolean | undefined =
        queryClient.getQueryData(mutationKey);

      queryClient.setQueryData(mutationKey, () => {
        return newChecked;
      });
      return previousChecked;
    },
    onError: (error, newTodo, context) => {
      queryClient.setQueryData(mutationKey, () => {
        return context;
      });
    },
    onSettled: async (data, error, newTodo, context) => {
      queryClient.invalidateQueries({
        queryKey: mutationKey,
      });
    },
    onSuccess: async () => {
      const promises = categoryQueries.map(async (queryKey) => {
        queryClient.resetQueries({
          queryKey: [queryKey],
        });
      });

      await Promise.all(promises);
    },
  });

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          .title {
            display: none;
          }
        `}
      >
        <SearchField
          searchQuery={searchTerm}
          onSetSearchQuery={onSetSearchQuery}
        />
        <Button
          variant={"link"}
          onClick={() => setFiltersIsOpen(!filtersIsOpen)}
          css={css`
            padding: 0.5rem;
            text-decoration: none !important;

            &:hover {
              background-color: ${theme.background.subtle.hover};
            }
          `}
        >
          Filter:{" "}
          {selectedFilters.length === 0 ? (
            <>
              <span
                css={css`
                  margin-left: 4px;
                `}
              >
                All
              </span>
              <div
                css={css`
                  margin-left: 4px;
                  font-size: 8px;
                `}
              >
                {filtersIsOpen ? <span>&#x25B2;</span> : <span>&#x25BC;</span>}
              </div>
            </>
          ) : null}
        </Button>
        <ReactSelectedFilters
          availableFilters={availableFilters || undefined}
          selectedFilters={selectedFilters}
          setSelectedFilters={onSetFilters}
          setExpandedHierarchicalFilters={setExpandedHierarchicalFilters}
          expandedHierarchicalFilters={expandedHierarchicalFilters}
          i18n={en}
        />
        {selectedFilters.length > 0 && (
          <Button
            variant={"link"}
            onClick={() => setFiltersIsOpen(!filtersIsOpen)}
            css={css`
              padding: 0.75rem 0.5rem;
              margin-left: 4px;
              text-decoration: none !important;
              align-items: center;
              font-size: 8px;

              &:hover {
                background-color: ${theme.background.subtle.hover};
              }
            `}
          >
            {filtersIsOpen ? <span>&#x25B2;</span> : <span>&#x25BC;</span>}
          </Button>
        )}
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 1rem;
        `}
      >
        {useOosAutoHideToggle && (
          <OverlayTrigger placement="top" overlay={tooltip}>
            <span style={{ cursor: "default", display: "flex" }}>
              <div
                css={css`
                  display: flex;
                  gap: 0.25rem;
                  margin: 0.25rem 0;
                  margin-left: 0.33rem;
                `}
              >
                <span
                  css={css`
                    font-size: 14px;
                    line-height: 24px;
                    color: #888; /* Grey color for "Auto-hide:" */
                  `}
                >
                  Auto-hide:
                </span>
                <span
                  css={css`
                    font-size: 14px;
                    line-height: 24px;
                    color: black; /* Use black for "ON" and "OFF" */
                  `}
                >
                  {oosAutoHideState ? " ON" : " OFF"}
                </span>

                <SwitchButton
                  id={"hide"}
                  checked={oosAutoHideState}
                  onChange={mutation.mutate}
                  name={"hide"}
                />
              </div>
            </span>
          </OverlayTrigger>
        )}
        <CategoryHeaderDropdown
          label="Sort"
          optionGroups={sortOptions}
          selectedValue={getSortId(selectedSort)}
          onSelect={(value) => {
            const selectedSort = availableSorts?.find(
              (sort) => getSortId(sort) === value
            );
            if (selectedSort) {
              onSetSort(selectedSort);
            }
          }}
        />
        <IconButton
          onClick={() => {
            setCurrentMode(currentMode === "grid" ? "list" : "grid");
          }}
          icon={currentMode === "grid" ? <ListBullets /> : <SquaresFour />}
        />
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import DraggableItem from "src/components/dragndrop/DraggableItem";
import { UniqueIdentifier } from "@dnd-kit/core";
import React from "react";
import reactFastCompare from "react-fast-compare";

interface SortableItemProps {
  containerId: string;
  id: string;
  index: number;
  handle: boolean;
  disabled?: boolean;
  getIndex(id: UniqueIdentifier): number;
  renderItem?(): React.ReactElement;
  wrapperStyle({ index }: { index: number }): React.CSSProperties;
  children: React.ReactNode;
}

const SortableItem = ({
  disabled,
  id,
  index,
  handle,
  renderItem,
  wrapperStyle,
  children,
}: SortableItemProps) => {
  const {
    setNodeRef,
    listeners,
    isDragging,
    isSorting,
    transform,
    transition,
  } = useSortable({
    id,
  });
  const mounted = useMountStatus();
  const mountedWhileDragging = isDragging && !mounted;

  return (
    <DraggableItem
      ref={disabled ? undefined : setNodeRef}
      value={id}
      dragging={isDragging}
      sorting={isSorting}
      handle={handle}
      index={index}
      wrapperStyle={wrapperStyle({ index })}
      transition={transition}
      transform={transform}
      fadeIn={mountedWhileDragging}
      listeners={listeners}
      renderItem={renderItem}
      customTag="li"
    >
      {children}
    </DraggableItem>
  );
};

function useMountStatus() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);

    return () => clearTimeout(timeout);
  }, []);

  return isMounted;
}

export default React.memo(SortableItem, reactFastCompare);

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Receipt = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M17.5 20c-0.076 0-0.153-0.017-0.224-0.053l-1.776-0.888-1.776 0.888c-0.141 0.070-0.306 0.070-0.447 0l-1.776-0.888-1.776 0.888c-0.141 0.070-0.306 0.070-0.447 0l-1.776-0.888-1.776 0.888c-0.141 0.070-0.306 0.070-0.447 0l-1.776-0.888-1.776 0.888c-0.155 0.077-0.339 0.069-0.486-0.022s-0.237-0.252-0.237-0.425v-18c0-0.189 0.107-0.363 0.276-0.447l2-1c0.141-0.070 0.306-0.070 0.447 0l1.776 0.888 1.776-0.888c0.141-0.070 0.306-0.070 0.447 0l1.776 0.888 1.776-0.888c0.141-0.070 0.306-0.070 0.447 0l1.776 0.888 1.776-0.888c0.141-0.070 0.306-0.070 0.447 0l2 1c0.169 0.085 0.276 0.258 0.276 0.447v18c0 0.173-0.090 0.334-0.237 0.425-0.080 0.050-0.171 0.075-0.263 0.075zM11.5 18c0.077 0 0.153 0.018 0.224 0.053l1.776 0.888 1.776-0.888c0.141-0.070 0.306-0.070 0.447 0l1.276 0.638v-16.882l-1.5-0.75-1.776 0.888c-0.141 0.070-0.306 0.070-0.447 0l-1.776-0.888-1.776 0.888c-0.141 0.070-0.306 0.070-0.447 0l-1.776-0.888-1.776 0.888c-0.141 0.070-0.306 0.070-0.447 0l-1.776-0.888-1.5 0.75v16.882l1.276-0.638c0.141-0.070 0.306-0.070 0.447 0l1.776 0.888 1.776-0.888c0.141-0.070 0.306-0.070 0.447 0l1.776 0.888 1.776-0.888c0.070-0.035 0.147-0.053 0.224-0.053z" fill={color}></path>
<path d="M11.5 13h-3.5v-1h3.5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-1.5v-0.5c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v0.5h-1.5c-0.276 0-0.5 0.224-0.5 0.5v2c0 0.276 0.224 0.5 0.5 0.5h3.5v1h-3.5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h1.5v0.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-0.5h1.5c0.276 0 0.5-0.224 0.5-0.5v-2c0-0.276-0.224-0.5-0.5-0.5z" fill={color}></path>
<path d="M12.5 5h-6c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h6c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z" fill={color}></path>
<path d="M14.5 7h-10c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h10c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z" fill={color}></path>
<path d="M14.5 9h-10c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h10c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Receipt); 
import { useCallback, useEffect, useMemo, useState } from "react";
import { deepCopy } from "src/helpers";
import { ConfiguredProductGroup } from "src/types/configuration";

type UseRelationshipCollapseStatus = [
  fromId: string | null,
  includeId: string | null,
  excludeId: string | null,
  isPanelExpanded: (id: string | null) => boolean | null,
  isLastExpandedPanel: (id: string | null) => boolean | null,
  collapseCallback: (id: string, isExpanded: boolean) => void
];

export default function useRelationshipCollapseStatus(
  fromGroup: ConfiguredProductGroup | null,
  includeGroup: ConfiguredProductGroup | null,
  excludeGroup: ConfiguredProductGroup | null,
  isFromDisabled: boolean
): UseRelationshipCollapseStatus {
  const [expandedStatus, setExpandedStatus] = useState<{
    [id: string]: boolean;
  } | null>(null);

  const fromId = useMemo((): string | null => {
    return fromGroup ? fromGroup?.group_id : null;
  }, [fromGroup]);

  const includeId = useMemo((): string | null => {
    return includeGroup ? includeGroup?.group_id : null;
  }, [includeGroup]);

  const excludeId = useMemo((): string | null => {
    return excludeGroup ? excludeGroup?.group_id : null;
  }, [excludeGroup]);

  const initialStatus = useMemo(
    () => [isFromDisabled ? false : true, true, true],
    [isFromDisabled]
  );

  useEffect(() => {
    if (fromId === null || includeId === null || excludeId === null) {
      setExpandedStatus(null);
      return;
    }

    const ids = [fromId, includeId, excludeId];

    let status = {} as { [id: string]: boolean };
    ids.forEach((id, key) => {
      if (id) {
        status[id] = initialStatus[key];
      }
    });

    setExpandedStatus(status);
  }, [fromId, includeId, excludeId, initialStatus]);

  const collapseCallback = useCallback(
    (id: string, isExpanded: boolean) => {
      if (expandedStatus === null) {
        return;
      }
      let proxy = deepCopy(expandedStatus);
      let entries = Object.values(expandedStatus);
      let isLast = entries.filter((e) => e === true).length === 1;
      if (isLast && isExpanded === false) {
        return;
      }
      proxy[id] = isExpanded;
      setExpandedStatus(proxy);
    },
    [expandedStatus]
  );

  const isPanelExpanded = useCallback(
    (id: string | null): boolean | null => {
      if (id === null || expandedStatus === null) {
        return null;
      }
      if (typeof expandedStatus[id] === "undefined") {
        return null;
      }
      return expandedStatus[id] === true;
    },
    [expandedStatus]
  );

  const isLastExpandedPanel = useCallback(
    (id: string | null): boolean => {
      if (
        id === null ||
        isPanelExpanded(id) === null ||
        expandedStatus === null
      ) {
        return false;
      }

      if (typeof expandedStatus[id] === "undefined") {
        return false;
      }

      let isLast = true;
      for (const key in expandedStatus) {
        let value = expandedStatus[key];
        if (value === true && key !== id) {
          isLast = false;
        }
      }
      return isLast;
    },
    [expandedStatus, isPanelExpanded]
  );

  return [
    fromId,
    includeId,
    excludeId,
    isPanelExpanded,
    isLastExpandedPanel,
    collapseCallback,
  ];
}

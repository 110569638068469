import DepictLogo from "src/assets/img/logo.svg";
import OnboardingHeaderStepLabel from "./OnboardingHeaderStepLabel";
import { OnboardingStep } from "../types";
import { css } from "@emotion/css";
import { memo } from "react";

interface IntegrationHeaderProps {
  currentStep: OnboardingStep;
}

function OnboardingHeader({ currentStep }: IntegrationHeaderProps) {
  return (
    // if you change the p-4 class, make sure to also change `headerHeight` in Onboarding.tsx
    <div
      className={
        "bg=white p-4 d-flex w-100 align-items-center bg-white justify-content-between border-bottom " +
        css`
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
        `
      }
    >
      <img src={DepictLogo} alt="Depict Logo" width={90} />
      <div className="d-flex align-items-center">
        <OnboardingHeaderStepLabel
          isCurrentStep={currentStep === OnboardingStep.WELCOME}
          number={1}
          label={"Welcome"}
        />
        <OnboardingHeaderStepLabel
          isCurrentStep={currentStep === OnboardingStep.CONNECT_DATA}
          number={2}
          label={"Connect data"}
        />
        <OnboardingHeaderStepLabel
          isCurrentStep={currentStep === OnboardingStep.SET_UP_SITE}
          number={3}
          label={"Set up site"}
        />
      </div>
      <div />
    </div>
  );
}

export default memo(OnboardingHeader);

import React, { useEffect, useMemo, useRef } from "react";
import mergeClassNames from "merge-class-names";
import { DropDownProps } from "src/types/components";
import Dropdown from "bootstrap/js/dist/dropdown";

const DropDown = (props: DropDownProps) => {
  const dropdownTrigger = useRef(null);

  const dropdown = useRef<Dropdown>();
  const {
    id,
    className,
    animation,
    position,
    trigger,
    triggerTag,
    triggerClassName,
    children,
  } = props;

  const CustomTriggerTag = triggerTag ? `${triggerTag}` : "div";
  const randomId = useMemo(
    () => (Math.random() + 1).toString(36).substring(7),
    []
  );
  const dropdownId = useMemo(
    () => (id ? `dropdown-${id}` : randomId),
    [id, randomId]
  );
  const customTriggerClasses = useMemo(
    () => (triggerClassName ? `${triggerClassName}` : ""),
    [triggerClassName]
  );

  useEffect(() => {
    if (!dropdownTrigger.current) {
      return;
    }

    dropdown.current = new Dropdown(dropdownTrigger.current);

    return () => {
      dropdown.current?.dispose();
    };
  }, [dropdownTrigger]);

  return (
    <div className={mergeClassNames([className, "dropdown"])}>
      {/* @ts-ignore */}
      <CustomTriggerTag
        ref={dropdownTrigger}
        id={dropdownId}
        className={customTriggerClasses}
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={(e: Event) => e.stopPropagation()}
      >
        {trigger}
      </CustomTriggerTag>
      <div
        className={mergeClassNames([
          "dropdown-menu",
          animation && animation !== "none" ? `animated--${animation}` : "",
          position && position === "end" ? `dropdown-menu-${position}` : "",
        ])}
        aria-labelledby={dropdownId}
      >
        {children}
      </div>
    </div>
  );
};

export default React.memo(DropDown);

import { CreateRelationshipsProps } from "src/types/components";
import React from "react";
import Button from "../storybook/Button/Button";

const CreateRelationships = (props: CreateRelationshipsProps) => {
  const { disabled, continueTriggerCB } = props;

  return (
    <div className="col depict--CreateRelationships">
      <p>You can make changes to this later</p>
      <Button
        disabled={disabled}
        onClick={() => {
          continueTriggerCB();
        }}
        data-tc="continue-new-configuration"
        text={"Continue"}
      />
    </div>
  );
};

export default React.memo(CreateRelationships);

import reactFastCompare from "react-fast-compare";
import React from "react";
import { ListItemProps } from "src/types/components";

const Item = (props: ListItemProps) => {
  const { className, item, itemOnClickCB, style, children } = props;
  return (
    <div
      style={style}
      className={className}
      title={item.title}
      onClick={() => {
        if (!itemOnClickCB) {
          return;
        }
        itemOnClickCB(item);
      }}
    >
      {children}
    </div>
  );
};

export default React.memo(Item, reactFastCompare);

import React from "react";
import "./HoverTitle.scss";

interface HoverTitleProps {
  content: string | JSX.Element;
  trianglePosition?: "bottom" | "left";
}

export function HoverTitle({
  content,
  trianglePosition = "bottom",
}: HoverTitleProps) {
  return (
    <div className="depict--HoverTitle">
      <div className="title-text">{content}</div>
      <div
        className={`title-triangle title-triangle--${trianglePosition}`}
      ></div>
    </div>
  );
}

import { Button, Col, Row } from "react-bootstrap";
import Card from "src/components/storybook/Card/Card";
import { CentraStore } from "../../Integrations/Centra/types";
import { useState } from "react";
import IntegrationStepHeader from "../IntegrationStepHeader/IntegrationStepHeader";
import CentraMarketsConfigForm from "./CentraMarketsConfigForm";

export default function CentraMarketsConfig({
  error,
  isLoading,
  availableStores,
  chosenMarketIds,
  onBack,
  onSubmit,
}: {
  error: string | null;
  isLoading: boolean;
  availableStores: CentraStore[];
  chosenMarketIds: string[];
  onBack: () => void;
  onSubmit: (storeId: string, marketIds: string[]) => void;
}) {
  const [viewedStoreId, setViewedStoreId] = useState<string | null>(null);
  const [newChosenMarketIds, onSetNewChosenMarketIds] =
    useState<string[]>(chosenMarketIds);
  const chosenStoreId = availableStores.find((s) =>
    s.markets.some((m) => newChosenMarketIds.includes(m.id))
  )?.id;

  return (
    <Card>
      <Card.Body>
        <IntegrationStepHeader
          title="Review markets you want to import"
          stepNumber={3}
        />

        <Card.Text>
          We have retrieved the following markets and corresponding URLs. Please
          review and correct where necessary.
        </Card.Text>
        <CentraMarketsConfigForm
          error={error}
          isLoading={isLoading}
          availableStores={availableStores}
          chosenMarketIds={newChosenMarketIds}
          viewedStoreId={viewedStoreId}
          onSetChosenMarketIds={onSetNewChosenMarketIds}
          setViewedStoreId={setViewedStoreId}
        />
      </Card.Body>
      <Card.Footer>
        <Row>
          <Col>
            <Button
              variant="outline-secondary"
              className="w-100"
              onClick={() => onBack()}
            >
              Back
            </Button>
          </Col>
          <Col>
            <Button
              variant="primary"
              className="w-100"
              disabled={
                isLoading ||
                error !== null ||
                newChosenMarketIds.length === 0 ||
                !chosenStoreId
              }
              onClick={() => {
                if (chosenStoreId) {
                  onSubmit(chosenStoreId, newChosenMarketIds);
                }
              }}
            >
              Continue
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
}

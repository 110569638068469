 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const BowlingPins = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M6.414 18.068c-0.239-0.139-0.545-0.058-0.684 0.181-0.194 0.333-0.368 0.591-0.482 0.751h-2.496c-0.436-0.616-1.752-2.668-1.752-5 0-1.507 0.692-2.6 1.361-3.657 0.49-0.774 0.957-1.512 1.096-2.343h1.083c0.071 0.453 0.236 0.952 0.506 1.533 0.116 0.251 0.414 0.359 0.664 0.243s0.359-0.414 0.243-0.664c-0.309-0.666-0.453-1.179-0.453-1.612 0-0.482 0.098-1.116 0.202-1.787 0.246-1.584 0.499-3.223-0.273-4.124-0.335-0.391-0.816-0.589-1.429-0.589s-1.094 0.198-1.429 0.589c-0.772 0.901-0.518 2.54-0.273 4.124 0.104 0.671 0.202 1.304 0.202 1.787 0 0.754-0.456 1.474-0.984 2.308-0.711 1.123-1.516 2.395-1.516 4.192 0 3.139 2.023 5.705 2.11 5.812 0.095 0.119 0.239 0.188 0.39 0.188h3c0.152 0 0.296-0.069 0.39-0.188 0.013-0.016 0.325-0.408 0.704-1.061 0.139-0.239 0.058-0.545-0.181-0.684zM3.353 6h1.293c-0.052 0.351-0.098 0.687-0.124 1h-1.046c-0.026-0.313-0.071-0.649-0.124-1zM3.33 2.24c0.088-0.103 0.254-0.24 0.67-0.24s0.582 0.137 0.67 0.24c0.403 0.471 0.29 1.656 0.129 2.76h-1.598c-0.161-1.104-0.274-2.289 0.129-2.76z" fill={color}></path>
<path d="M18.484 9.808c-0.528-0.834-0.984-1.554-0.984-2.308 0-0.482 0.098-1.116 0.202-1.787 0.246-1.584 0.499-3.223-0.273-4.124-0.335-0.391-0.816-0.589-1.429-0.589s-1.094 0.198-1.429 0.589c-0.772 0.901-0.518 2.54-0.273 4.124 0.104 0.671 0.202 1.304 0.202 1.787 0 0.433-0.144 0.946-0.453 1.612-0.116 0.25-0.007 0.548 0.243 0.664s0.548 0.007 0.664-0.243c0.27-0.581 0.435-1.080 0.506-1.533h1.083c0.14 0.831 0.607 1.569 1.096 2.343 0.669 1.057 1.361 2.15 1.361 3.657 0 2.332-1.316 4.384-1.752 5h-2.496c-0.114-0.161-0.288-0.418-0.482-0.751-0.139-0.239-0.445-0.32-0.684-0.181s-0.32 0.445-0.181 0.684c0.38 0.653 0.691 1.045 0.704 1.061 0.095 0.119 0.239 0.188 0.39 0.188h3c0.152 0 0.296-0.069 0.39-0.188 0.086-0.108 2.11-2.673 2.11-5.812 0-1.796-0.806-3.069-1.516-4.192zM15.33 2.24c0.088-0.103 0.254-0.24 0.67-0.24s0.582 0.137 0.67 0.24c0.403 0.471 0.29 1.656 0.129 2.76h-1.598c-0.161-1.104-0.274-2.289 0.129-2.76zM15.353 6h1.293c-0.052 0.351-0.098 0.687-0.124 1h-1.046c-0.026-0.313-0.071-0.649-0.124-1z" fill={color}></path>
<path d="M12.497 9.043c-0.535-0.952-0.997-1.775-0.997-2.543 0-0.482 0.098-1.116 0.202-1.787 0.246-1.584 0.499-3.223-0.273-4.124-0.335-0.391-0.816-0.589-1.429-0.589s-1.094 0.198-1.429 0.589c-0.772 0.901-0.518 2.54-0.273 4.124 0.104 0.671 0.202 1.304 0.202 1.787 0 0.768-0.462 1.591-0.997 2.543-0.704 1.254-1.503 2.675-1.503 4.457 0 3.116 1.999 6.15 2.084 6.277 0.093 0.139 0.249 0.223 0.416 0.223h3c0.167 0 0.323-0.084 0.416-0.223 0.085-0.128 2.084-3.162 2.084-6.277 0-1.782-0.798-3.203-1.503-4.457zM10.523 6h-1.046c-0.026-0.313-0.071-0.649-0.124-1h1.293c-0.052 0.351-0.098 0.687-0.124 1zM9.33 1.24c0.088-0.103 0.254-0.24 0.67-0.24s0.582 0.137 0.67 0.24c0.403 0.471 0.29 1.656 0.129 2.76h-1.598c-0.161-1.104-0.274-2.289 0.129-2.76zM11.223 19h-2.446c-0.441-0.731-1.777-3.143-1.777-5.5 0-1.521 0.699-2.764 1.375-3.967 0.484-0.862 0.946-1.684 1.084-2.533h1.083c0.138 0.849 0.599 1.671 1.084 2.533 0.676 1.203 1.375 2.447 1.375 3.967 0 2.357-1.337 4.769-1.777 5.5z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(BowlingPins); 
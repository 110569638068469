import { Button, Col, Row, Stack } from "react-bootstrap";
import Card from "src/components/storybook/Card/Card";
import LoadingBadge from "../../LoadingBadge/LoadingBadge";
import {
  CentraMappedAttributeDefinition,
  CentraOptionalAttributeParsingConfigSchema,
  CentraOptionalParsingConfig,
  CentraParsingConfig,
  CentraParsingConfigSchema,
  PartialCentraParsingConfig,
  PartialOptionalCentraParsingConfig,
} from "../../Integrations/Centra/types";
import { CentraAttributeParsingSelector } from "../../Integrations/Centra/CentraAttributeParsingSelector";
import { useState } from "react";
import { AGE_CONSTANTS, GENDER_CONSTANTS } from "../constants";
import { z } from "zod";
import IntegrationStepHeader from "../IntegrationStepHeader/IntegrationStepHeader";
import { ParsingMethod } from "../ParsingMethodSelector/types";
import AddOptionalAttribute from "../Onboarding/AddOptionalAttribute/AddOptionalAttribute";
import { attributeIdToName } from "../util";

export default function CentraAttributesConfig({
  isLoading,
  error,
  availableOptionalAttributeIds,
  onBack,
  onSubmit,
  colorParsingConfig,
  genderParsingConfig,
  ageParsingConfig,
  optionalAttributeParsingConfigs,
  availableAttributes,
}: {
  availableAttributes: CentraMappedAttributeDefinition[];
  availableOptionalAttributeIds: string[];
  isLoading: boolean;
  error: string | null;
  onBack: () => void;
  colorParsingConfig?: PartialCentraParsingConfig;
  genderParsingConfig?: PartialCentraParsingConfig;
  ageParsingConfig?: PartialCentraParsingConfig;
  optionalAttributeParsingConfigs?: PartialOptionalCentraParsingConfig[];
  onSubmit: (
    colorParsingConfig: CentraParsingConfig,
    genderParsingConfig: CentraParsingConfig,
    ageParsingConfig: CentraParsingConfig,
    optionalParsingConfigs: CentraOptionalParsingConfig[]
  ) => void;
}) {
  const [newColorParsingConfig, setNewColorParsingConfig] = useState<
    PartialCentraParsingConfig | undefined
  >(colorParsingConfig);
  const [newGenderParsingConfig, setNewGenderParsingConfig] = useState<
    PartialCentraParsingConfig | undefined
  >(genderParsingConfig);
  const [newAgeParsingConfig, setNewAgeParsingConfig] = useState<
    PartialCentraParsingConfig | undefined
  >(ageParsingConfig);

  const [
    newOptionalAttributeParsingConfigs,
    setNewOptionalAttributeParsingConfigs,
  ] = useState<PartialOptionalCentraParsingConfig[]>(
    optionalAttributeParsingConfigs ?? []
  );

  const parsingConfigsAreValid = z
    .tuple([
      CentraParsingConfigSchema,
      CentraParsingConfigSchema,
      CentraParsingConfigSchema,
      z.array(CentraOptionalAttributeParsingConfigSchema),
    ])
    .safeParse([
      newColorParsingConfig,
      newGenderParsingConfig,
      newAgeParsingConfig,
      newOptionalAttributeParsingConfigs,
    ]).success;

  return (
    <Card>
      <Card.Body>
        <IntegrationStepHeader title="Map attributes" stepNumber={4} />
        <Card.Text>
          Please select the attribute type and corresponding element key you
          would like to use.
        </Card.Text>
        {isLoading ? (
          <LoadingBadge label="Fetching available attributes from Centra" />
        ) : (
          <Stack gap={4} direction="vertical">
            <CentraAttributeParsingSelector
              attributeName="Color"
              parsingConfig={newColorParsingConfig}
              onSetParsingConfig={(config) => setNewColorParsingConfig(config)}
              availableAttributes={availableAttributes}
              availableConstantValues={[
                { label: "Not applicable", value: null },
              ]}
              required
            />
            <CentraAttributeParsingSelector
              attributeName="Gender"
              parsingConfig={newGenderParsingConfig}
              onSetParsingConfig={(config) => setNewGenderParsingConfig(config)}
              availableAttributes={availableAttributes}
              availableConstantValues={GENDER_CONSTANTS}
              required
            />
            <CentraAttributeParsingSelector
              attributeName="Age"
              parsingConfig={newAgeParsingConfig}
              onSetParsingConfig={(config) => setNewAgeParsingConfig(config)}
              availableAttributes={availableAttributes}
              availableConstantValues={AGE_CONSTANTS}
              required
            />
            {newOptionalAttributeParsingConfigs.map((config, index) => (
              <CentraAttributeParsingSelector
                attributeName={attributeIdToName(config.attributeId)}
                parsingConfig={config}
                onSetParsingConfig={(newConfig) => {
                  const newConfigs = [...newOptionalAttributeParsingConfigs];
                  newConfigs[index] = {
                    ...newConfig,
                    attributeId: config.attributeId,
                    isOptional: true,
                  };
                  setNewOptionalAttributeParsingConfigs(newConfigs);
                }}
                onDelete={() => {
                  const newConfigs = [...newOptionalAttributeParsingConfigs];
                  newConfigs.splice(index, 1);
                  setNewOptionalAttributeParsingConfigs(newConfigs);
                }}
                availableAttributes={availableAttributes}
              />
            ))}
          </Stack>
        )}
        <Card.Text className="mt-3">
          If you would like to map additional attributes, please select them
          below.
        </Card.Text>
        <AddOptionalAttribute
          availableOptionalAttributeIds={availableOptionalAttributeIds}
          alreadyUsedOptionalAttributeIds={newOptionalAttributeParsingConfigs.map(
            (config) => config.attributeId
          )}
          onAdd={(attributeId) => {
            const newConfigs = [...newOptionalAttributeParsingConfigs];
            newConfigs.push({
              attributeId,
              isOptional: true,
              type: ParsingMethod.Map,
            });
            setNewOptionalAttributeParsingConfigs(newConfigs);
          }}
        />
      </Card.Body>
      <Card.Footer>
        <Row>
          <Col>
            <Button
              variant="outline-secondary"
              className="w-100"
              onClick={() => {
                onBack();
              }}
            >
              Back
            </Button>
          </Col>
          <Col>
            <Button
              variant="primary"
              className="w-100"
              disabled={isLoading || error !== null || !parsingConfigsAreValid}
              onClick={() => {
                // Use zod to ensure typescript types
                const [
                  colorParsingConfig,
                  genderParsingConfig,
                  ageParsingConfig,
                  optionalAttributeParsingConfigs,
                ] = z
                  .tuple([
                    CentraParsingConfigSchema,
                    CentraParsingConfigSchema,
                    CentraParsingConfigSchema,
                    z.array(CentraOptionalAttributeParsingConfigSchema),
                  ])
                  .parse([
                    newColorParsingConfig,
                    newGenderParsingConfig,
                    newAgeParsingConfig,
                    newOptionalAttributeParsingConfigs,
                  ]);

                onSubmit(
                  colorParsingConfig,
                  genderParsingConfig,
                  ageParsingConfig,
                  optionalAttributeParsingConfigs
                );
              }}
            >
              Continue
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
}

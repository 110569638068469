 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Brush = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M0.5 20c-0.24 0-0.446-0.17-0.491-0.405s0.083-0.47 0.305-0.559c0.821-0.328 1.208-1.434 1.618-2.605 0.277-0.79 0.563-1.608 1.015-2.244 0.554-0.78 1.296-1.176 2.204-1.176 0.301 0 0.627 0.043 0.969 0.129 0.607 0.152 1.13 0.534 1.472 1.075 0.335 0.529 0.475 1.179 0.395 1.829-0.235 1.912-2.326 3.956-7.489 3.956zM5.152 14.011c-1.224 0-1.676 1.039-2.276 2.751-0.27 0.771-0.546 1.558-0.972 2.18 1.481-0.13 2.707-0.473 3.593-1.011 0.872-0.529 1.404-1.242 1.498-2.008 0.106-0.862-0.364-1.624-1.117-1.812-0.263-0.066-0.508-0.099-0.727-0.099z" fill={color}></path>
<path d="M9.544 12.977c-0 0-0 0-0 0-0.848-0-1.596-0.363-2.052-0.995-0.504-0.699-0.605-1.661-0.279-2.64 0.277-0.829 0.898-1.59 1.955-2.394 0.904-0.687 2.058-1.354 3.279-2.059 2.171-1.254 4.632-2.675 6.699-4.743 0.195-0.195 0.512-0.195 0.707 0 0.098 0.098 0.146 0.226 0.146 0.354s-0.049 0.256-0.146 0.354c-2.067 2.067-3.489 4.528-4.743 6.699-0.705 1.221-1.372 2.375-2.059 3.279-0.804 1.057-1.564 1.678-2.394 1.955-0.378 0.126-0.753 0.19-1.114 0.19zM16.252 3.748c-1.121 0.746-2.246 1.396-3.304 2.007-2.316 1.337-4.315 2.492-4.786 3.903-0.227 0.68-0.176 1.298 0.142 1.739 0.269 0.374 0.71 0.58 1.241 0.58 0 0 0 0 0 0 0.253 0 0.521-0.047 0.797-0.139 1.411-0.47 2.566-2.47 3.903-4.786 0.611-1.058 1.261-2.183 2.007-3.304z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Brush); 
 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const ToiletPaper = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M5.5 10c-0.281 0-0.807-0.136-1.171-1.047-0.212-0.53-0.329-1.224-0.329-1.953s0.117-1.424 0.329-1.953c0.364-0.911 0.89-1.047 1.171-1.047s0.807 0.136 1.171 1.047c0.212 0.53 0.329 1.224 0.329 1.953s-0.117 1.424-0.329 1.953c-0.364 0.911-0.89 1.047-1.171 1.047zM5.5 5.012c-0.164 0.129-0.5 0.814-0.5 1.988s0.336 1.859 0.5 1.988c0.164-0.129 0.5-0.814 0.5-1.988s-0.336-1.859-0.5-1.988z" fill={color}></path>
<path d="M14.5 1h-9c-1.963 0-3.5 2.636-3.5 6 0 0.167 0.004 0.333 0.011 0.496l-0.011 11.003c-0 0.173 0.089 0.334 0.237 0.426 0.080 0.050 0.172 0.075 0.263 0.075 0.076 0 0.153-0.018 0.224-0.053l1.776-0.888 1.776 0.888c0.141 0.070 0.306 0.070 0.447 0l1.776-0.888 1.776 0.888c0.141 0.070 0.306 0.070 0.447 0l1.776-0.888 1.776 0.888c0.155 0.078 0.339 0.069 0.486-0.022s0.237-0.252 0.237-0.425v-5.558c1.718-0.404 3-2.869 3-5.942 0-3.364-1.537-6-3.5-6zM3.818 3.35c0.468-0.858 1.081-1.35 1.682-1.35s1.214 0.492 1.682 1.35c0.527 0.967 0.818 2.263 0.818 3.65s-0.29 2.683-0.818 3.65c-0.468 0.858-1.081 1.35-1.682 1.35s-1.214-0.492-1.682-1.35c-0.467-0.856-0.748-1.971-0.806-3.179l0.001-0.964c0.060-1.199 0.341-2.306 0.805-3.157zM12.724 17.053c-0.141-0.070-0.306-0.070-0.447 0l-1.776 0.888-1.776-0.888c-0.141-0.070-0.306-0.070-0.447 0l-1.776 0.888-1.776-0.888c-0.141-0.070-0.306-0.070-0.447 0l-1.276 0.638 0.007-6.44c0.63 1.086 1.508 1.75 2.493 1.75h8.5v4.691l-1.276-0.638zM16.182 10.65c-0.468 0.858-1.081 1.35-1.682 1.35h-7.040c0.935-1.066 1.54-2.89 1.54-5s-0.605-3.934-1.54-5h7.040c0.601 0 1.214 0.492 1.682 1.35 0.527 0.967 0.818 2.263 0.818 3.65s-0.29 2.683-0.818 3.65z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(ToiletPaper); 
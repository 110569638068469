// @ts-ignore
import {
  ConsoleInstrumentation,
  ConsoleTransport,
  ErrorsInstrumentation,
  Faro,
  FetchTransport,
  initializeFaro,
  LogLevel,
  WebVitalsInstrumentation,
  SessionInstrumentation,
} from "@grafana/faro-web-sdk";

//Only initialize faro if we are not on localhost, or we override it in the .env file
export const monitoringEnabled =
  (window.location.hostname !== "localhost" &&
    process.env.NODE_ENV !== "development") ||
  process.env.FORCE_MONITORING === "true";

// Using custom log levels to reduce imports of Faro SDK
export type MonitoringLogLevel = "debug" | "info" | "warn" | "error";
const logLevelMap: { [key in MonitoringLogLevel]: LogLevel } = {
  debug: LogLevel.DEBUG,
  info: LogLevel.INFO,
  warn: LogLevel.WARN,
  error: LogLevel.ERROR,
};

export const initializeMonitoring = () => {
  if (!monitoringEnabled) {
    return;
  }
  initializeFaro({
    instrumentations: [
      new ErrorsInstrumentation(),
      new WebVitalsInstrumentation(),
      new ConsoleInstrumentation({
        disabledLevels: [
          LogLevel.DEBUG,
          LogLevel.WARN,
          LogLevel.LOG,
          LogLevel.INFO,
        ],
      }),
      new SessionInstrumentation(),
    ],
    transports: [
      new FetchTransport({
        url: "https://collect.custom.depict.ai/collect",
        apiKey: "m!1!95wYr*9F",
      }),
      new ConsoleTransport(),
    ],
    app: {
      name: "portal-frontend",
      version: "1.0.0",
    },
  });
};

export const setLoggedInUser = (
  email: string,
  merchant: string,
  superUser: boolean
) => {
  if (!monitoringEnabled) {
    return;
  }
  console.log("Setting logged in user for monitoring");
  // @ts-ignore
  const faro = window.faro as Faro;

  faro.api.setUser({
    attributes: {
      merchant,
      superUser: superUser ? "true" : "false",
    },
  });
};

export const sendLog = (
  context: { [key: string]: string },
  message?: any,
  level: MonitoringLogLevel = "debug"
) => {
  if (!monitoringEnabled) {
    return;
  }
  // @ts-ignore
  const faro = window.faro as Faro;

  faro.api.pushLog([message], {
    context: context,
    level: logLevelMap[level],
  });
};

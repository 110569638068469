import {
  startOfYear,
  endOfYear,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  endOfQuarter,
} from "date-fns";
import { PeriodFunction } from "src/types/components";
import { getExactSecondInUTCWithoutConversion, toUtc } from "src/helpers/dates";

const toUtcWOConversion = getExactSecondInUTCWithoutConversion;

export const sinceStart: PeriodFunction = (merchantStartDate) => {
  const end = toUtc(new Date());
  return {
    start: merchantStartDate?.start || 0,
    end: Math.floor(+end / 1000),
  };
};

export const customSpan: PeriodFunction = (userInput) => {
  return (
    userInput || {
      start: NaN,
      end: NaN,
    }
  );
};

export const monthSpan: PeriodFunction = (userInput) => {
  const inputtedDate = userInput?.start
    ? new Date(userInput.start * 1000)
    : new Date();
  const start = toUtcWOConversion(startOfMonth(inputtedDate));
  const end = toUtcWOConversion(endOfMonth(inputtedDate));
  return {
    start: Math.floor(+start / 1000),
    end: Math.floor(+end / 1000),
  };
};

export const quarterSpan: PeriodFunction = (userInput) => {
  const inputtedDate = userInput?.start
    ? new Date(userInput.start * 1000)
    : new Date();
  const start = toUtcWOConversion(startOfQuarter(inputtedDate));
  const end = toUtcWOConversion(endOfQuarter(inputtedDate));
  return {
    start: Math.floor(+start / 1000),
    end: Math.floor(+end / 1000),
  };
};

export const fullYearSpan: PeriodFunction = (userInput) => {
  const dateToUse = userInput?.start
    ? new Date(userInput?.start * 1000)
    : new Date();
  const start = toUtcWOConversion(startOfYear(toUtc(dateToUse)));
  const end = toUtcWOConversion(endOfYear(toUtc(dateToUse)));
  return {
    start: Math.floor(+start / 1000),
    end: Math.floor(+end / 1000),
  };
};

import { useState, useEffect } from "react";
import { Configuration } from "src/types/configuration";

type UseMarketConfigurations = [marketConfigurations: Configuration[] | null];

export default function useMarketConfigurations(
  configurations: Configuration[] | null,
  market: string | null
) {
  const [marketConfigurations, setMarketConfigurations] = useState<
    Configuration[] | null
  >(null);

  useEffect(() => {
    if (!configurations || !market) {
      setMarketConfigurations(null);
      return;
    }

    let marketConf = configurations.filter((configuration: Configuration) => {
      return configuration.markets?.includes(market as string);
    });

    if (!marketConf.length) {
      setMarketConfigurations(null);
      return;
    }

    console.log(
      "%c[CONFIGURATION] Setting configurations for market " + market,
      "color: crimson"
    );

    setMarketConfigurations(marketConf);

    return () => {
      setMarketConfigurations(null);
    };
  }, [configurations, market]);

  return [marketConfigurations] as UseMarketConfigurations;
}

import Card from "src/components/storybook/Card/Card";

export default function CustomFeed() {
  return (
    <Card>
      <Card.Body>
        <h1 className="fw-bolder">Custom feed</h1>
        <Card.Text>
          Custom feeds require a Depict engineer to configure. Please contact us
          at <a href="mailto:cs@depict.ai">cs@depict.ai</a> and provide your
          product feeds to us.
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

import { css } from "@emotion/react";
import { ArrowLeft, ArrowRight, LinkBreak, Trash } from "@phosphor-icons/react";
import classnames from "classnames";
import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import BootstrapCard from "react-bootstrap/Card";
import { MetadataInner } from "src/api/types";
import ProductEmptyImage from "src/assets/img/product-empty.svg";
import { ProductMetadata } from "src/helpers/catalog/ProductMetadataProvider";
import useSelectionHandler from "src/helpers/hooks/useSelectionHandler";
import { metadataTypeBadgePositionMap } from "../../../helpers/catalog/productMetadataToTextTransformers";
import { useCatalogMetadata } from "../../../helpers/hooks/catalog/useCatalogMetadata";
import { OnSelect } from "../BulkEditBar/BulkEditBar.types";
import { Checkbox } from "../Checkbox/Checkbox";
import { ColorDot } from "../ColorDot/ColorDot";
import { CurvyCorner } from "../CurvyCorner/CurvyCorner";
import Dropdown from "../Dropdown/Dropdown";
import { useImageResize } from "../ImageResizeProvider/ImageResizeProvider";
import ProductBadges from "../ProductBadges/ProductBadges";
import {
  DELETE_TEXT,
  MOVE_TO_BEGINNING_TEXT,
  MOVE_TO_END_TEXT,
  PairTextProps,
} from "../SortableComponents/pairText";
import { common } from "../common";
import { noSelectCss } from "../css.helper";
import { theme } from "../designSystemVariables";
import { Product } from "../types";
import "./collectionproductgridcard.scss";

const MAX_IMAGE_SIZE = "375px"; // Showing 2 columns on desktop will produce massive images, this keeps things reasonable
export const IMG_ASPECT_RATIO = 238 / 220;

type ActionCB = (mainProductId: string) => Promise<void> | void;

interface CollectionProductGridCardProps extends PairTextProps {
  index: number;
  disableIndex: boolean;
  onDeleteClick: ActionCB;
  onPushToBeginning: ActionCB | undefined;
  onPushToEnd: ActionCB | undefined;
  onUnpair: ActionCB | undefined;
  product: Product;
  extraStyle: React.CSSProperties | undefined;
  showSelectCheckbox?: boolean;
  selected?: boolean;
  onSelectClick?: OnSelect;
  isInSelectMode: boolean;
}

type MetadataType = MetadataInner[number]["type"];

const collectionRelevantMetadataTypes: MetadataType[] = [
  "out_of_stock",
  "low_stock",
  "on_sale",
  "new_in",
  "bestseller",
  "inactive",
  "trending",
  "slow_mover",
];

export function CollectionProductGridCard(
  props: CollectionProductGridCardProps
) {
  const selectionHandler = useSelectionHandler(
    props.onSelectClick,
    Boolean(props.selected),
    props.product.main_product_id
  );
  const { productMetadata: allProductMetadata } = useCatalogMetadata();
  const productMetadata: ProductMetadata[] = useMemo(() => {
    return allProductMetadata?.[props.product.main_product_id] ?? [];
  }, [allProductMetadata, props.product.main_product_id]);

  const collectionRelevantMetadata = productMetadata.filter((md) =>
    collectionRelevantMetadataTypes.includes(md.type)
  );
  const topRowBadgeMetadata = collectionRelevantMetadata.filter((md) => {
    if (!md.type) return false;
    return metadataTypeBadgePositionMap[md.type] === "top";
  });
  const bottomRowBadgeMetadata = collectionRelevantMetadata.filter((md) => {
    if (!md.type) return false;
    return metadataTypeBadgePositionMap[md.type] === "bottom";
  });

  const oneBasedIndex = useMemo(() => (props.index ?? 0) + 1, [props.index]);
  const index = oneBasedIndex.toString().padStart(2, "0");

  const dropdownItems = useMemo(() => {
    const items = [];
    if (typeof props.onPushToBeginning !== "undefined") {
      items.push({
        text: props.moveToBeginningText || MOVE_TO_BEGINNING_TEXT,
        onClick: () => props.onPushToBeginning?.(props.product.main_product_id),
        icon: <ArrowLeft />,
      });
    }
    if (typeof props.onPushToEnd !== "undefined") {
      items.push({
        text: props.moveToEndText || MOVE_TO_END_TEXT,
        onClick: () => props.onPushToEnd?.(props.product.main_product_id),
        icon: <ArrowRight />,
      });
    }
    if (typeof props.onDeleteClick !== "undefined") {
      items.push({
        text: props.deleteText || DELETE_TEXT,
        onClick: () => props.onDeleteClick(props.product.main_product_id),
        icon: <Trash />,
        color: "red",
      });
    }
    if (typeof props.onUnpair !== "undefined") {
      items.push({
        text: "Unpair",
        onClick: () => props.onUnpair?.(props.product.main_product_id),
        icon: <LinkBreak />,
      });
    }
    return items;
  }, [props]);

  const image = Image(props);

  return (
    <div
      className="depict--ProductGridCard"
      css={[
        noSelectCss,
        {
          display: "flex",
          flexDirection: "column",
          padding: "0.75rem",
          gap: "0.75rem",
          borderRadius: common.cardBorderRadius,
          backgroundColor: theme.background.base.default,
          outline: props.selected
            ? `2px solid ${theme.border.base.default}`
            : "none",
          ...props.extraStyle,
        },
      ]}
      onClick={selectionHandler}
    >
      <div className="image-container">
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            zIndex: 2,
            gap: "0.5rem",
            margin: "0.5rem",
          }}
        ></div>
        {props.showSelectCheckbox && <SelectionCorner {...props} />}
        {image}
        {props.product.color_name && props.product.color_hex && (
          <ColorDot
            hexCode={props.product.color_hex}
            name={props.product.color_name}
          />
        )}
        {!(
          bottomRowBadgeMetadata.length || topRowBadgeMetadata.length
        ) ? null : (
          <CurvyCorner corner="bottom-left">
            <div
              style={{
                marginTop: "0.32em",
                marginRight: "0.32em",
                display: "flex",
                flexDirection: "column",
                gap: "0.32em",
              }}
            >
              <ProductBadges
                metadata={[...bottomRowBadgeMetadata, ...topRowBadgeMetadata]}
              />
            </div>
          </CurvyCorner>
        )}
      </div>
      <div>
        <Row container={"true"}>
          <Col sm={dropdownItems.length > 0 ? 10 : 12}>
            <BootstrapCard.Title
              title={props.product.title}
              className={"text-sm"}
              css={css`
                display: block; /* Fallback for non-webkit */
                display: -webkit-box;
                height: 1.3em; /* Fallback for non-webkit, line-height * 2 */
                line-height: 1.3em;
                -webkit-line-clamp: 1; /* if you change this, make sure to change the fallback line-height and height */
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 600;
              `}
            >
              {`${!props?.disableIndex ? `${index} ` : ""}${
                props.product.title
              }`}
            </BootstrapCard.Title>
            <BootstrapCard.Subtitle
              className={"text-xs text-muted"}
              title={props.product.main_product_id}
            >
              {props.product.main_product_id}
            </BootstrapCard.Subtitle>
          </Col>
          {dropdownItems.length > 0 && (
            <Col sm={2} data-no-dnd="true">
              <div data-no-dnd="true">
                <Dropdown
                  closeWhenOtherDropdownOpens={true}
                  key_={props.product.main_product_id}
                  items={dropdownItems}
                  disabled={props.isInSelectMode}
                />
              </div>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
}
function Image(props: CollectionProductGridCardProps) {
  const { getImageUrl } = useImageResize();

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        paddingTop: `calc(min(100% / ${IMG_ASPECT_RATIO}, ${MAX_IMAGE_SIZE}))`,
        borderRadius: common.cardContentBorderRadius,
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <BootstrapCard.Img
        variant="top"
        draggable={false}
        src={
          props.product.image_url
            ? getImageUrl(props.product.image_url, 500)
            : ProductEmptyImage
        }
        style={{
          objectFit: "cover",
          position: "absolute",
          top: 0,
          width: "100%",
          height: "100%",
          maxHeight: MAX_IMAGE_SIZE,
          maxWidth: MAX_IMAGE_SIZE,
        }}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = ProductEmptyImage;
        }}
        loading="lazy"
      />
    </div>
  );
}

function SelectionCorner(props: CollectionProductGridCardProps) {
  return (
    <div
      className={classnames("selection-corner", {
        show: props.isInSelectMode,
      })}
    >
      <CurvyCorner
        corner="top-left"
        borderRadius="0.75em"
        extraStyles={{ zIndex: 10 }}
      >
        <div
          style={{
            marginTop: "0.25em",
            marginLeft: "0.25em",
            marginRight: "0.42em",
            marginBottom: "0.42em",
          }}
        >
          <Checkbox
            checked={Boolean(props?.selected)}
            onChange={(c) =>
              props?.onSelectClick?.(props.product.main_product_id, c)
            }
          />
        </div>
      </CurvyCorner>
    </div>
  );
}

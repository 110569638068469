import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useMemo } from "react";
import { getData } from "src/api/authorizedApi";
import { BoostBuryEffect, BoostRule } from "src/api/types";
import {
  ActionType,
  BoostBuryDispatchContext,
  BoostBurySelectorsContext,
} from "src/components/boostbury/BoostBury.provider";
import { useAlerts } from "src/components/storybook/Alert/useAlerts";
import { QueryId, getQueryKey } from "src/queries/queries";
import useAuthorizedApi from "../app/useAuthorizedApi";

export const useBoostAndBury = (merchantId: string) => {
  const { api } = useAuthorizedApi();
  const queryClient = useQueryClient();
  const { addAlert } = useAlerts();
  const boostBuryDispatch = useContext(BoostBuryDispatchContext);
  const { allRules } = useContext(BoostBurySelectorsContext);
  const { data: rules, isLoading: getRulesIsLoading } = useQuery({
    queryKey: getQueryKey(QueryId.GetBoostAndBuryRules, merchantId),
    queryFn: async () => {
      if (api) {
        const response = await api.GET("/api/v0/boost/{merchant_id}/rules", {
          params: {
            path: {
              merchant_id: merchantId,
            },
          },
        });

        const data = getData(response);
        return data.boost_rules as BoostRule[];
      }
    },

    enabled: !!api,
    staleTime: 1000 * 60 * 5,
  });

  useEffect(() => {
    if (rules) {
      boostBuryDispatch({
        type: ActionType.INIT_FROM_SERVER,
        payload: { rules: rules },
      });
    }
  }, [boostBuryDispatch, rules]);

  const {
    mutateAsync: updateRules,
    isPending: updateRulesIsLoading,
    isError: updateRulesIsError,
  } = useMutation({
    mutationFn: async () => {
      if (api) {
        const response = await api.POST(
          "/api/v0/boost/{merchant_id}/set-rules",
          {
            params: {
              path: {
                merchant_id: merchantId,
              },
            },
            body: {
              boost_rules: allRules,
            },
          }
        );

        return { statusCode: response.response.status };
      }
    },

    onSuccess: async (res) => {
      if (res?.statusCode === 202) {
        addAlert({
          id: "boost-bury-rule-instant-update-failed",
          title: `Rules saved`,
          message: `Rules were saved but the changes are still processing.\nIt can take up to 30min to take effect.`,
          type: "info",
        });
      }
      await queryClient.invalidateQueries({
        queryKey: getQueryKey(QueryId.GetBoostAndBuryRules, merchantId),
      });
      boostBuryDispatch({
        type: ActionType.SET_SERVER_TO_LOCAL,
        payload: {},
      });
    },
    onError: async () => {
      addAlert({
        id: "boost-bury-rule-updated",
        message: `Failed to update rules`,
        type: "danger",
      });
    },
  });

  const collectionIdToBoostRuleEffect = useMemo(() => {
    if (allRules) {
      return allRules.reduce((map, rule) => {
        if (rule.type === "scollection") {
          map.set(rule.collection_id, rule.effect);
        }
        return map;
      }, new Map<string, BoostBuryEffect>());
    }
    return new Map<string, BoostBuryEffect>();
  }, [allRules]);

  return {
    collectionIdToBoostRuleEffect,
    getRulesIsLoading,
    updateRules,
    updateRulesIsLoading,
    updateRulesIsError,
  };
};

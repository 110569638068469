/** Returns an x value that is snapped to the grid (not anywhere between grid lines) */
export function getGridSnappedX(
  x: number,
  gridWidth: number,
  cardWidth: number
): number {
  const columnAmount = Math.round(gridWidth / cardWidth);
  const incrementAmount = gridWidth / columnAmount;
  const xSnappedToIncrement = Math.round(x / incrementAmount) * incrementAmount;
  return xSnappedToIncrement;
}

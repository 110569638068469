import { useCallback } from "react";
import configurationsMiddleware from "src/api/configurationsMiddleware";
import { getAlertMessage, getErrorMessage } from "src/helpers/errors";

import {
  ControlLoadingQueueCB,
  DeleteConfigurationCB,
} from "src/types/components";
import { useAuth0 } from "@auth0/auth0-react";
import useAuthentication from "../app/useAuthentication";
import { useAlerts } from "../../../components/storybook/Alert/useAlerts";

export default function useDeleteConfiguration(
  merchant: string | null,
  refreshConfigurations: Function,
  controlLoadingQueueCB: ControlLoadingQueueCB
): [DeleteConfigurationCB] {
  const { user } = useAuth0();
  const { addAlert } = useAlerts();
  const [getAccessToken] = useAuthentication();
  const deleteConfiguration = useCallback(
    async (configuration_id: number | null) => {
      if (!merchant || !configuration_id) {
        const message = getErrorMessage("delete-configuration");
        if (message) {
          addAlert(message);
        }
        return;
      }

      controlLoadingQueueCB("delete-configuration", "push");

      const id_token = await getAccessToken();

      let result = await configurationsMiddleware.delete(
        id_token,
        merchant,
        configuration_id,
        user?.email
      );

      if (!result.success) {
        let message = await getAlertMessage(result.error);
        addAlert(message);
        console.trace("[CONFIGURATION] Delete configuration failed.");
        controlLoadingQueueCB("delete-configuration", "remove");
        return;
      }

      console.log(
        `%c[CONFIGURATION] Deleted configuration with id ${configuration_id}`,
        "color: purple"
      );

      refreshConfigurations();
      controlLoadingQueueCB("delete-configuration", "remove");
    },
    [
      merchant,
      controlLoadingQueueCB,
      getAccessToken,
      user?.email,
      refreshConfigurations,
      addAlert,
    ]
  );

  return [deleteConfiguration];
}

export const GENDER_CONSTANTS = [
  {
    label: "Men",
    value: "men",
  },
  {
    label: "Women",
    value: "women",
  },
  {
    label: "Unisex",
    value: "unisex",
  },
  {
    label: "Not applicable",
    value: null,
  },
];

export const AGE_CONSTANTS = [
  {
    label: "Adult",
    value: "adult",
  },
  {
    label: "Teen",
    value: "teen",
  },
  {
    label: "Kids",
    value: "kids",
  },
  {
    label: "Baby",
    value: "baby",
  },
  {
    label: "Not applicable",
    value: null,
  },
];

import { useQuery } from "@tanstack/react-query";
import { getData } from "src/api/authorizedApi";
import useMerchant from "../../../helpers/hooks/app/useMerchant";
import { QueryId, getQueryKey } from "../../../queries/queries";
import useAuthorizedApi from "../app/useAuthorizedApi";
import { DeepAny, PortalSearchFilter } from "src/api/types";

export const useProductFilters = () => {
  const { api } = useAuthorizedApi();
  const { merchantId } = useMerchant();

  const filtersQuery = useQuery({
    queryKey: getQueryKey(QueryId.GetCatalogProductFilters, merchantId || ""),
    queryFn: async () => {
      if (!api || !merchantId) return;
      const response = await api.POST(
        "/api/v0/catalog/products/{merchant_id}/search",
        {
          params: {
            path: {
              merchant_id: merchantId,
            },
          },
          body: {
            query: "",
            limit: 1,
          },
        }
      );

      const data = getData(response);

      const filters: DeepAny<PortalSearchFilter>[] = data?.filters || [];

      return filters;
    },

    enabled: !!merchantId && !!api,
  });

  return filtersQuery;
};

export default useProductFilters;

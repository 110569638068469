 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Enter = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M17.5 2h-9c-0.827 0-1.5 0.673-1.5 1.5v3c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-3c0-0.276 0.224-0.5 0.5-0.5h7.564l-3.842 1.647c-0.685 0.294-1.222 1.108-1.222 1.853v9.5h-2.5c-0.276 0-0.5-0.224-0.5-0.5v-3c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v3c0 0.827 0.673 1.5 1.5 1.5h2.5v1.5c0 0.433 0.18 0.801 0.495 1.008 0.174 0.114 0.376 0.172 0.589 0.172 0.173 0 0.354-0.038 0.532-0.114l5.162-2.212c0.685-0.294 1.222-1.108 1.222-1.854v-12c0-0.827-0.673-1.5-1.5-1.5zM18 15.5c0 0.351-0.294 0.796-0.616 0.934l-5.162 2.212c-0.096 0.041-0.159 0.038-0.177 0.027s-0.045-0.069-0.045-0.173v-12c0-0.351 0.294-0.796 0.616-0.934l5.333-2.286c0.033 0.066 0.051 0.141 0.051 0.22v12z" fill={color}></path>
<path d="M8.354 9.146l-3-3c-0.195-0.195-0.512-0.195-0.707 0s-0.195 0.512 0 0.707l2.146 2.146h-6.293c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h6.293l-2.146 2.146c-0.195 0.195-0.195 0.512 0 0.707 0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146l3-3c0.195-0.195 0.195-0.512 0-0.707z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Enter); 
import React, { useState, useCallback } from "react";

export function AutoSaveInput({
  placeholder,
  saveOnChange = false,
  initialValue,
  style,
  onSave,
}: {
  placeholder?: string;
  saveOnChange?: boolean;
  initialValue: string | undefined;
  style?: React.CSSProperties;
  onSave: (value: string) => void;
}) {
  const [focusedOnField, setFocusedOnField] = useState(false);
  const [currentValue, setCurrentValue] = useState<string | null>(null);

  const updateCurrentValue = useCallback(
    (value: string | null) => {
      if (!value || value === initialValue) {
        return;
      }
      onSave(value);
    },
    [initialValue, onSave]
  );

  const activeInputStyle = {
    outline: "2px solid #8b8fd9",
    outlineOffset: "3px",
    zIndex: "1000",
  };

  return (
    <input
      type="text"
      placeholder={placeholder}
      value={currentValue !== null ? currentValue : initialValue}
      style={{
        position: "relative",
        display: "inline",
        width: "100%",
        border: "0",
        padding: "0px",
        borderRadius: "1px",
        marginTop: "0.1em",
        ...style,
        ...(focusedOnField ? { ...activeInputStyle } : {}),
      }}
      onChange={(event) => {
        setCurrentValue(event.target.value);

        if (saveOnChange) {
          onSave(event.target.value);
        }
      }}
      onFocus={() => {
        setFocusedOnField(true);

        if (typeof initialValue !== "undefined" && currentValue === null) {
          setCurrentValue(initialValue);
        }
      }}
      onBlur={() => {
        setFocusedOnField(false);
        updateCurrentValue(currentValue);
      }}
      onKeyUp={(event) => {
        if (event.key === "Enter") {
          event.currentTarget.blur();
        } else if (event.key === "Escape") {
          event.preventDefault();
        }
      }}
    />
  );
}

import { OpenDropdownProvider } from "../Dropdown/WhichDropdownOpenContext";
import { css } from "@emotion/react";
import ProductGridViewPlaceholder from "../SortableComponents/ProductGridViewSortable/ProductGridViewPlaceholder/ProductGridViewPlaceholder";
import { depictCardClassName } from "../common";
import { getGridContainerCss, getGridElementCss } from "./css";
import { useRef } from "react";
import useOnValueChange from "src/helpers/hooks/useOnValueChange";
import { motion, useInView } from "framer-motion";

type StringOrNumberKeys<T> = {
  [K in keyof T]: T[K] extends string | number ? K : never;
}[keyof T];

export type RenderCardCB<T> = (
  item: T,
  index: number,
  extraStyles?: React.CSSProperties
) => React.ReactNode;

interface ProductGridViewProps<T> {
  items: T[];
  itemIdKey: StringOrNumberKeys<T>;
  renderCard: RenderCardCB<T>;
  loading: boolean;
  columns: number;
  onScrollAtBottom?: () => void;
  isLoadingMore: boolean;
}

export default function ProductGridView<T extends Record<string, any>>(
  props: ProductGridViewProps<T>
) {
  const lastElementRef = useRef<HTMLDivElement>(null);

  const isInView = useInView(lastElementRef);

  useOnValueChange(isInView, (isVisible) => {
    if (isVisible && props.onScrollAtBottom) {
      props.onScrollAtBottom();
    }
  });

  return (
    <OpenDropdownProvider>
      {props.loading && <ProductGridViewPlaceholder columns={props.columns} />}
      {!props.loading && (
        <div css={getGridContainerCss(props.columns)}>
          {props.items.map((item, index) => {
            const key = item[props.itemIdKey];

            return (
              <motion.div
                layout
                className={`${depictCardClassName}`}
                key={key}
                css={getGridElementCss()}
              >
                {props.renderCard(item, index)}
              </motion.div>
            );
          })}
        </div>
      )}
      {props.isLoadingMore && (
        <ProductGridViewPlaceholder columns={props.columns} />
      )}
      <div
        ref={lastElementRef}
        css={css`
          height: 1px;
        `}
      />
    </OpenDropdownProvider>
  );
}

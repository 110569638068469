import { UniqueIdentifier } from "@dnd-kit/core";
import { useCallback } from "react";
import { deepCopy, deepEqual } from "src/helpers";
import {
  fromListItemToIdObj,
  getUpdatedGroup,
} from "src/helpers/configuration";
import { GroupModifyCB, ListItem } from "src/types/components";
import {
  ConfiguredProductGroupDefinition,
  ConfiguredProductGroup,
} from "src/types/configuration";
import { ContainersItems } from "src/types/dnd";

type UseDragHandlers = [
  handleDragOver: (items: ContainersItems | null) => any,
  handleDragEnd: (container: UniqueIdentifier, items: ListItem[]) => any
];

export default function useDragHandlers(
  fromGroup: ConfiguredProductGroup | null,
  includeGroup: ConfiguredProductGroup | null,
  excludeGroup: ConfiguredProductGroup | null,
  groupModifyCB: GroupModifyCB
): UseDragHandlers {
  const handleDragOver = useCallback(
    (items: ContainersItems | null) => {
      if (!items) {
        return;
      }

      const keys = Object.keys(items);
      keys.forEach((key, index) => {
        const newSet = items[key];
        const targetGroup =
          key === "from"
            ? fromGroup
            : key === "include"
            ? includeGroup
            : excludeGroup;

        if (newSet === null) {
          return;
        }

        const newGroupDefinition: ConfiguredProductGroupDefinition = newSet.map(
          (item: ListItem) => fromListItemToIdObj(item)
        );

        if (deepEqual(targetGroup?.group_definition, newGroupDefinition)) {
          return;
        }

        const updatedGroup = getUpdatedGroup(
          targetGroup?.group_id as string,
          newGroupDefinition,
          [targetGroup as ConfiguredProductGroup],
          "replace"
        );

        if (!updatedGroup) {
          return;
        }

        groupModifyCB(updatedGroup);
      });
    },
    [fromGroup, includeGroup, excludeGroup, groupModifyCB]
  );

  const handleDragEnd = useCallback(
    (container: UniqueIdentifier, items: ListItem[]) => {
      const targetGroup =
        container === "from"
          ? deepCopy(fromGroup)
          : container === "include"
          ? deepCopy(includeGroup)
          : deepCopy(excludeGroup);

      if (!targetGroup) {
        return;
      }

      const newGroupDefinition: ConfiguredProductGroupDefinition = items.map(
        (item: ListItem) => fromListItemToIdObj(item)
      );

      targetGroup.group_definition = newGroupDefinition;
      groupModifyCB(targetGroup);
    },
    [excludeGroup, fromGroup, groupModifyCB, includeGroup]
  );

  return [handleDragOver, handleDragEnd];
}

import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import config from "src/config";
import { useTrackPageView } from "../helpers/hooks/mix/useSegment";

export const NoMerchants = () => {
  useTrackPageView("NoMerchants");

  const { logout } = useAuth0();

  return (
    <>
      <div className="container-xl px-4 animated--fade-in-up">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="">
              <h1
                className=""
                style={{
                  color: "rgba(41,59,155,.03)",
                  fontSize: "240px",
                }}
              >
                Access denied
              </h1>
              <h2 className="mb-5" style={{ fontSize: "1.7rem" }}>
                You don't have access to Depict
              </h2>
              <p className="fs-5">
                <a
                  onClick={() => {
                    logout({
                      logoutParams: {
                        returnTo: window.location.origin + config.publicUrl,
                      },
                    });
                  }}
                  className="link-dark text-decoration-underline"
                >
                  Try another account
                </a>{" "}
                or{" "}
                <a
                  className="link-dark text-decoration-underline"
                  href="mailto:support@depict.ai"
                >
                  get in contact
                </a>{" "}
                with us if you experienced any problem with our Portal.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoMerchants;

export interface PairTextProps {
  moveToBeginningText: string;
  moveToEndText: string;
  deleteText: string;
}

export const MOVE_TO_BEGINNING_TEXT = "Move to beginning";
export const MOVE_TO_END_TEXT = "Move to end";
export const DELETE_TEXT = "Delete";

export const MOVE_TO_BEGINNING_PAIR_TEXT = "Move pair to beginning";
export const MOVE_TO_END_PAIR_TEXT = "Move pair to end";
export const DELETE_PAIR_TEXT = "Delete pair";

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Lighter = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M17.5 9h-0.69l-0.855-1.722c-0.084-0.17-0.258-0.278-0.448-0.278h-4.007c-0.276 0-0.5 0.224-0.5 0.5v1.5h-1.211l-3.856-6.678c-0.138-0.239-0.444-0.321-0.683-0.183l-3.464 2c-0.239 0.138-0.321 0.444-0.183 0.683l4 6.928c0.093 0.16 0.261 0.25 0.433 0.25 0.085 0 0.171-0.022 0.25-0.067l2.714-1.567v8.134c0 0.276 0.224 0.5 0.5 0.5h8c0.276 0 0.5-0.224 0.5-0.5v-9c0-0.276-0.224-0.5-0.5-0.5zM17 15h-1v-3h1v3zM12 8h3.197l0.497 1h-3.694v-1zM6.219 10.817l-3.5-6.062 2.598-1.5 3.5 6.062-2.598 1.5zM10 18v-8h6.497c0.001 0 0.002 0 0.004 0s0.001-0 0.002-0h0.497v1h-1.5c-0.276 0-0.5 0.224-0.5 0.5v4c0 0.276 0.224 0.5 0.5 0.5h1.5v2h-7z" fill={color}></path>
<path d="M15.5 6c-0.75 0-1.115-0.354-1.289-0.652-0.331-0.565-0.275-1.418 0.169-2.606 0.299-0.799 0.668-1.459 0.684-1.487 0.089-0.157 0.255-0.255 0.436-0.255s0.347 0.097 0.436 0.255c0.016 0.028 0.385 0.688 0.684 1.487 0.444 1.189 0.5 2.041 0.169 2.606-0.174 0.297-0.539 0.652-1.289 0.652zM15.5 2.64c-0.063 0.146-0.127 0.302-0.187 0.463-0.453 1.218-0.297 1.64-0.238 1.741 0.025 0.043 0.092 0.156 0.425 0.156s0.401-0.114 0.426-0.156c0.059-0.101 0.216-0.525-0.243-1.751-0.059-0.158-0.121-0.31-0.183-0.453z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Lighter); 
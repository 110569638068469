import React from "react";
import { CloseButton, Modal as BootstrapModal } from "react-bootstrap";

interface ModalProps extends Partial<Parameters<typeof BootstrapModal>["0"]> {
  show: boolean;
  variant?: "wide" | "normal" | "narrow" | "tiny";
  onClose?: () => Promise<void> | void;
  children?: React.ReactNode;
  title?: string;
  style?: React.CSSProperties;
  dialogClassName?: string;
  contentClassName?: string;
}
export const Modal = (props: ModalProps) => {
  const handleClose = () => {
    props?.onClose?.();
  };

  return (
    <BootstrapModal
      {...props}
      className={`depict--Modal${props.variant ? ` --${props.variant}` : ""}`}
      show={props.show}
      keyboard={true}
      onEscapeKeyDown={handleClose}
      onHide={handleClose}
      dialogClassName={props.dialogClassName}
      contentClassName={props.contentClassName}
    >
      {props.title ? (
        <BootstrapModal.Header
          closeButton={typeof props.onClose !== "undefined"}
        >
          <BootstrapModal.Title>{props.title}</BootstrapModal.Title>
        </BootstrapModal.Header>
      ) : (
        <div className={`mt-4-5`}>
          <CloseButton
            onClick={handleClose}
            style={{
              zIndex: 10,
              display:
                typeof props.onClose === "undefined" ? "none" : undefined,
            }}
          />
        </div>
      )}
      {props.children}
    </BootstrapModal>
  );
};

export default Modal;

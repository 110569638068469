import ParsingMethodSelector from "../../Integrations/ParsingMethodSelector/ParsingMethodSelector";
import {
  CentraMappedAttributeDefinition,
  PartialCentraParsingConfig,
} from "../../Integrations/Centra/types";
import { ParsingMethod } from "../../Integrations/ParsingMethodSelector/types";
import Select from "react-select";
import { Button, Col, Row } from "react-bootstrap";

export function CentraAttributeParsingSelector({
  attributeName,
  parsingConfig,
  onSetParsingConfig,
  availableAttributes,
  availableConstantValues,
  required,
  onDelete,
}: {
  attributeName: string;
  parsingConfig?: PartialCentraParsingConfig;
  onSetParsingConfig: (config: PartialCentraParsingConfig) => void;
  availableAttributes: CentraMappedAttributeDefinition[];
  availableConstantValues?: { label: string; value: string | null }[];
  required?: boolean;
  onDelete?: () => void;
}) {
  const constantValueOptions = (availableConstantValues || []).map((v) => v);

  const elementKeyOptions =
    parsingConfig?.type === ParsingMethod.Map && parsingConfig?.attributeType
      ? availableAttributes
          .find((attr) => attr.attributeType === parsingConfig?.attributeType)
          ?.elementKeys.map((key) => ({
            label: key,
            value: key,
          })) ?? []
      : [];

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        {required ? (
          <h6>
            {attributeName}
            <span className="text-danger">*</span>
            <span className="text-gray-600"> (required)</span>
          </h6>
        ) : (
          <h6>
            {attributeName}
            <span className="text-gray-600"> (optional)</span>
          </h6>
        )}
        {!required && onDelete && (
          <Button
            onClick={onDelete}
            variant="link"
            className="text-gray-800 p-0"
          >
            Remove
          </Button>
        )}
      </div>

      {required && (
        <ParsingMethodSelector
          selectedAttributeParsingMethod={parsingConfig?.type}
          mappingIsDisabled={availableAttributes.length === 0}
          onSetAttributeParsingMethod={(method) => {
            if (
              method === ParsingMethod.Map &&
              availableAttributes.length > 0
            ) {
              onSetParsingConfig({
                type: ParsingMethod.Map,
              });
            } else if (method === "constant") {
              onSetParsingConfig({
                type: ParsingMethod.Constant,
              });
            }
          }}
        />
      )}
      {parsingConfig?.type === ParsingMethod.Map && (
        <div>
          <div className="text-gray-800 my-2">Attribute maps to</div>
          <Row>
            <Col>
              <Select
                key={parsingConfig?.attributeType}
                options={availableAttributes.map((def) => ({
                  label: def.attributeType,
                  value: def.attributeType,
                }))}
                value={
                  parsingConfig?.attributeType
                    ? {
                        label: parsingConfig.attributeType,
                        value: parsingConfig.attributeType,
                      }
                    : undefined
                }
                onChange={(option) => {
                  if (option) {
                    onSetParsingConfig({
                      type: ParsingMethod.Map,
                      attributeType: option.value ?? undefined,
                      elementKey: undefined,
                    });
                  }
                }}
                placeholder={`Select attribute type for ${attributeName.toLowerCase()}`}
              />
            </Col>
            <Col>
              <Select
                key={parsingConfig?.attributeType}
                options={elementKeyOptions}
                value={
                  parsingConfig?.elementKey
                    ? {
                        label: parsingConfig.elementKey,
                        value: parsingConfig.elementKey,
                      }
                    : undefined
                }
                onChange={(option) => {
                  if (option) {
                    onSetParsingConfig({
                      type: ParsingMethod.Map,
                      attributeType: parsingConfig?.attributeType,
                      elementKey: option.value,
                    });
                  }
                }}
                placeholder={`Select element key for ${attributeName.toLowerCase()}`}
              />
            </Col>
          </Row>
        </div>
      )}
      {parsingConfig?.type === "constant" && (
        <div>
          <div className="text-gray-800 my-2">Attribute maps to</div>
          <Select
            onChange={(option) => {
              if (option) {
                onSetParsingConfig({
                  type: ParsingMethod.Constant,
                  value: option.value ?? undefined,
                });
              }
            }}
            options={constantValueOptions}
            placeholder={`Select value for ${attributeName.toLowerCase()}`}
          />
        </div>
      )}
    </div>
  );
}

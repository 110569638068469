import { PointTooltipProps } from "@nivo/line";
import React from "react";
import reactFastCompare from "react-fast-compare";

const TooltipPlot = (props: {
  value: PointTooltipProps;
  xLabel: string;
  xValue: string;
  yLabel: string | undefined;
  yValue: string;
  title: string;
}) => {
  const { value, xLabel, xValue, yLabel, yValue, title } = props;
  return (
    <div
      style={{
        background: "#FFF",
        padding: "0.5rem",
        fontSize: "0.75rem",
        border: "1px solid #868689",
      }}
    >
      <ul
        style={{
          marginBottom: 0,
          listStyle: "none",
          padding: 0,
        }}
      >
        <li>
          <i
            style={{
              display: "inline-block",
              width: "12px",
              height: "12px",
              backgroundColor: value.point.serieColor,
              marginRight: "5px",
            }}
          ></i>
          <span>{title}</span>
        </li>
        <li>
          {xLabel}: {xValue}
        </li>
        <li>
          {yLabel}: {yValue}
        </li>
      </ul>
    </div>
  );
};

export default React.memo(TooltipPlot, reactFastCompare);

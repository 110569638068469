 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Underline = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M14.5 18h-9c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h9c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z" fill={color}></path>
<path d="M10 15c-2.757 0-5-2.243-5-5v-7.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5v7.5c0 2.206 1.794 4 4 4s4-1.794 4-4v-7.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5v7.5c0 2.757-2.243 5-5 5z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Underline); 
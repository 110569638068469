import { useState, useEffect } from "react";
import { DataPeriod, EnhancedSurface } from "src/types/components";
import { DepictMetricsData } from "src/types/metrics";
import { CsvData } from "src/types/csv";
import { getTableRows } from "src/helpers/table";
import { csvHeaders, getCsvData } from "src/helpers/csv";

type UseCsvData = [tableData: CsvData | null];

export default function useCsvData(
  metrics: {
    [metric: string]: DepictMetricsData | null;
  },
  merchant: string | null,
  market: string | null,
  splitBySurface: boolean,
  period: DataPeriod | undefined,
  enabledSurfaces: EnhancedSurface[] | null
): UseCsvData {
  const [csvData, setCsvData] = useState<CsvData | null>(null);

  useEffect(() => {
    if (!metrics || !market) {
      setCsvData(null);
      return;
    }

    let rows = getTableRows(metrics, enabledSurfaces);
    let data = getCsvData(
      metrics,
      rows,
      csvHeaders,
      market,
      splitBySurface,
      period
    );

    if (!data.length) {
      setCsvData(null);
      return;
    }

    setCsvData(data);
  }, [metrics, splitBySurface, period, market, merchant, enabledSurfaces]);

  return [csvData];
}

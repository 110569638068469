import { useEffect, useState } from "react";
import { getAuthorizedApi } from "src/api/authorizedApi";
import useAuthentication from "src/helpers/hooks/app/useAuthentication";

export type AuthorizedApi = ReturnType<typeof getAuthorizedApi>;

export function useAuthorizedApi() {
  const [api, setApi] = useState<null | AuthorizedApi>(null);
  const [getAccessToken] = useAuthentication();

  useEffect(() => {
    const initApi = async () => {
      const token = await getAccessToken();

      const api = getAuthorizedApi(token);

      setApi(api);
    };

    initApi();

    return () => {
      setApi(null);
    };
  }, [getAccessToken]);

  return { api };
}

export default useAuthorizedApi;

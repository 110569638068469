import React, { useEffect, useState } from "react";
import { Dropdown as DropdownBootstrap } from "react-bootstrap";
import { DotsThreeVertical } from "@phosphor-icons/react";
import { useWhichDropdownOpen } from "./useWhichDropdownOpen";
import { theme } from "../designSystemVariables";
import { Link } from "react-router-dom";

export interface Item {
  text: string;
  onClick?: () => void;
  link?: string;
  icon: JSX.Element;
  color?: string;
  disabled?: boolean;
}

interface Props {
  disabled?: boolean;
  items: Item[];
  toggle?: JSX.Element;
  toggleStyleOverride?: React.CSSProperties;
  key_: string | number;
  closeWhenOtherDropdownOpens?: boolean;
  dotSize?: number;
}

export function Dropdown(props: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const { registerOpenDropdown, isOtherDropdownOpen } = useWhichDropdownOpen(
    props.key_
  );

  const handleToggleClick = (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e?.stopPropagation();
    if (!isOpen) {
      setIsOpen(true);
      registerOpenDropdown();
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOtherDropdownOpen && isOpen) {
      setIsOpen(false);
    }
  }, [isOpen, isOtherDropdownOpen]);

  return (
    <DropdownBootstrap
      autoClose={true}
      show={isOpen}
      onToggle={() => handleToggleClick()}
      data-no-dnd="true"
    >
      <DropdownBootstrap.Toggle
        variant="link"
        id="dropdown-basic"
        // removes the arrow from the dropdown
        bsPrefix="p-0"
        onClick={handleToggleClick}
        disabled={props.disabled}
        style={{
          background: isOpen ? theme.background.base.pressed : undefined,
          borderRadius: "50%",
          width: "1.5rem",
          height: "1.5rem",
          textDecoration: "none",
          ...props.toggleStyleOverride,
        }}
      >
        {props.toggle ? (
          props.toggle
        ) : (
          <DotsThreeVertical size={props.dotSize || 20} color="black" />
        )}
      </DropdownBootstrap.Toggle>

      <DropdownBootstrap.Menu>
        {props.items.map((item) => (
          <DropdownBootstrap.Item
            as={item.link ? Link : undefined}
            to={item.link || "#"}
            key={item.text}
            onClick={(e) => {
              setIsOpen(false);
              e.stopPropagation();
              if (item.onClick) {
                item.onClick();
              }
            }}
            style={{
              color: item.disabled ? "grey" : item.color || "black",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
            disabled={item.disabled}
          >
            {item.icon}
            {item.text}
          </DropdownBootstrap.Item>
        ))}
      </DropdownBootstrap.Menu>
    </DropdownBootstrap>
  );
}

export default Dropdown;

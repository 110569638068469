import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { useMemo } from "react";
import { SortableProduct } from "../useSortablePairedProducts";
import "./productlistview.scss";
import {
  useCreateCardDraggingExtraStyles,
  DragIndicatorElement,
  ProductCardDraggingStack,
} from "../ProductCardDraggingStack";

interface DraggableCardProps {
  style?: React.CSSProperties;
  className?: string;
  attributes?: DraggableAttributes;
  listeners?: SyntheticListenerMap;
}

interface ProductListCardRefProps extends DraggableCardProps {
  products: SortableProduct[];
  isOverlayItem?: boolean;
  isPair: boolean;
  groupId?: string;
  renderCard: (
    product: SortableProduct,
    index: number,
    extraStyle: React.CSSProperties,
    isPair: boolean
  ) => React.ReactNode;
}

// We use this presentation component to allow the parent to
// render an overlay item identical to the one in the list.
export const ProductListCardRef = React.forwardRef<
  HTMLDivElement,
  ProductListCardRefProps
>(function ProductListCardRef(props: ProductListCardRefProps, ref) {
  const { products: items } = props;

  const isOverlayItem = props.isOverlayItem || false;
  const style = props.style || {};
  const attributes = props.attributes || {};
  const listeners = props.listeners || {};

  const extraStyles = useCreateCardDraggingExtraStyles(
    items,
    isOverlayItem,
    4,
    6
  );
  const halfWidth = isOverlayItem;
  const renderedItems = items.slice(0, 3).map((p, i) => {
    return (
      <div
        key={p.main_product_id}
        className={`depict-row index_${i}`}
        style={{ ...extraStyles[i], width: halfWidth ? "50%" : "100%" }}
      >
        {props.renderCard(p, i, {}, props.isPair)}
      </div>
    );
  });

  return (
    <ProductCardDraggingStack
      items={renderedItems}
      amount={items.length}
      badgeRight={halfWidth ? "50%" : 0}
      isOverlayItem={isOverlayItem}
      containerStyle={style}
      containerListeners={listeners}
      containerRef={ref}
      containerAttributes={attributes}
    />
  );
});

export function ProductListSortableCard(props: {
  id: string;
  products: SortableProduct[];
  beingDragged?: boolean;
  className?: string;
  shouldBeIndicated?: boolean;
  pairsLength?: number;
  disableDrag?: boolean;
  isPair: boolean;
  groupId?: string;
  renderCard: (
    product: SortableProduct,
    index: number,
    extraStyle: React.CSSProperties,
    isPair: boolean
  ) => React.ReactNode;
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    index,
    activeIndex,
  } = useSortable({ id: props.id, disabled: props.disableDrag });

  const style = useMemo(
    () => ({
      transform: CSS.Transform.toString(transform),
      transition,
      zIndex: index === activeIndex ? 100 : undefined,
    }),
    [transform, transition, index, activeIndex]
  );

  // If we are being dragged, the OverlayItem above us with render the ProductListCard
  // We should render an indicator instead if that is the case.
  return props.beingDragged ? (
    <div style={{ height: "3.75em" }}>
      <DragIndicatorElement
        ref={setNodeRef}
        style={style}
        attributes={attributes}
        listeners={listeners}
        pairsLength={props.products.length}
        indicatorNumberAlign={"start"}
        indicatorNumberFontSize={"1.25em"}
      />
    </div>
  ) : (
    <ProductListCardRef
      ref={setNodeRef}
      style={style}
      products={props.products}
      attributes={attributes}
      listeners={listeners}
      className={props.className}
      isPair={props.isPair}
      groupId={props.groupId}
      renderCard={props.renderCard}
    />
  );
}

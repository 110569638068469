 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Umbrella = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M15.51 3.49c-1.605-1.605-3.74-2.49-6.010-2.49s-4.405 0.884-6.010 2.49-2.49 3.74-2.49 6.010c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5c0-0.827 0.673-1.5 1.5-1.5s1.5 0.673 1.5 1.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5c0-0.827 0.673-1.5 1.5-1.5s1.5 0.673 1.5 1.5v8c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5c0 0.827 0.673 1.5 1.5 1.5s1.5-0.673 1.5-1.5v-8c0-0.827 0.673-1.5 1.5-1.5s1.5 0.673 1.5 1.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5c0-0.827 0.673-1.5 1.5-1.5s1.5 0.673 1.5 1.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5c0-2.27-0.884-4.405-2.49-6.010zM15.5 7c-0.817 0-1.544 0.394-2 1.002-0.456-0.608-1.183-1.002-2-1.002s-1.544 0.394-2 1.002c-0.456-0.608-1.183-1.002-2-1.002s-1.544 0.394-2 1.002c-0.456-0.608-1.183-1.002-2-1.002-0.422 0-0.819 0.105-1.168 0.29 0.945-3.060 3.801-5.29 7.168-5.29s6.222 2.23 7.168 5.29c-0.349-0.185-0.746-0.29-1.168-0.29z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Umbrella); 
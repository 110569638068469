import Cache from "src/helpers/cache";
import {
  DeleteConfigurationApiResult,
  GetConfigurationsApiResult,
  PostConfigurationsApiParams,
  PostPutConfigurationsApiResult,
  PutConfigurationsApiParams,
} from "src/types/api";
import configurationsApi from "src/api/configurations";
import { ConfigurationsCacheKey } from "src/types/cache";
import { Configuration } from "src/types/configuration";
import cache, { clearCache } from "src/api/cache/configurations";

const configurationsMiddleware = (function () {
  return {
    get: async function (
      idToken: string,
      merchant_id: string,
      user_id: string | undefined
    ): Promise<GetConfigurationsApiResult> {
      const cacheKey: ConfigurationsCacheKey = {
        merchant_id: merchant_id,
        user_id: user_id ?? "no-user",
      };

      if (cache.has(cacheKey)) {
        console.log(
          "[CACHE] Loading %c" + merchant_id + " %c configurations.",
          "font-weight: bold",
          "font-weight: normal"
        );
        return { success: true, data: cache.get(cacheKey) };
      }

      const result = await configurationsApi({ merchant_id, idToken });

      if (!result.success) {
        return result;
      }

      cache.set(cacheKey, result.data);

      return result as GetConfigurationsApiResult;
    },
    edit: async (
      idToken: string,
      merchant_id: string,
      configuration_id: number,
      user_id: string | undefined,
      params: PutConfigurationsApiParams
    ): Promise<PostPutConfigurationsApiResult> => {
      const result = await configurationsApi({
        idToken,
        merchant_id,
        body: params,
        configuration_id,
        method: "PUT",
      });

      if (result.success) {
        const cacheKey: ConfigurationsCacheKey = {
          merchant_id: merchant_id,
          user_id: user_id ?? "no-user",
        };
        if (cache.has(cacheKey)) {
          const cached = cache.get(cacheKey);
          const index = cached.findIndex((configuration: Configuration) => {
            return configuration.id === configuration_id;
          });
          if (index !== -1) {
            const updated = [
              ...cached.slice(0, index),
              result.data,
              ...cached.slice(index + 1),
            ];
            cache.delete(cacheKey);
            cache.set(cacheKey, updated);
          }
        }
      }

      return result as PostPutConfigurationsApiResult;
    },
    create: async (
      idToken: string,
      merchant_id: string,
      user_id: string | undefined,
      params: PostConfigurationsApiParams
    ): Promise<PostPutConfigurationsApiResult> => {
      const result = await configurationsApi({
        idToken,
        merchant_id,
        body: params,
        method: "POST",
      });

      if (result.success) {
        const cacheKey: ConfigurationsCacheKey = {
          merchant_id: merchant_id,
          user_id: user_id ?? "no-user",
        };
        if (cache.has(cacheKey)) {
          const cached = cache.get(cacheKey);
          const updated = cached.concat([result.data]);
          cache.delete(cacheKey);
          cache.set(cacheKey, updated);
        }
      }

      return result as PostPutConfigurationsApiResult;
    },
    delete: async (
      idToken: string,
      merchant_id: string,
      configuration_id: number,
      user_id: string | undefined
    ): Promise<DeleteConfigurationApiResult> => {
      const result = await configurationsApi({
        idToken,
        merchant_id,
        configuration_id,
        method: "DELETE",
      });

      if (result.success) {
        const cacheKey: ConfigurationsCacheKey = {
          merchant_id: merchant_id,
          user_id: user_id ?? "no-user",
        };
        if (cache.has(cacheKey)) {
          const cached = cache.get(cacheKey);
          const removed = cached.filter((configuration: Configuration) => {
            return configuration.id !== configuration_id;
          });
          cache.delete(cacheKey);
          cache.set(cacheKey, removed);
        }
      }

      return result as DeleteConfigurationApiResult;
    },
    getCache: function (): Cache {
      return cache;
    },
    clearCache: function (): void {
      console.log("[CACHE] Clearing configurations cache.");
      clearCache();
    },
  };
})();

export default configurationsMiddleware;

import { Market, Merchant } from "src/api/types";

export enum ROUTES {
  HOME = "/",
  LOGIN = "/login",
  DASHBOARD = "/:merchantId/dashboard",
  CONFIGURE = "/:merchantId/configure",
  BOOST_AND_BURY = "/:merchantId/boost-and-bury",
  CATALOG = "/:merchantId/catalog",
  CATEGORY_V3 = "/:merchantId/categories-v3/:categoryId",
  SEARCH = "/:merchantId/search",
  LONG_TAIL_COLLECTIONS = "/:merchantId/long-tail-collections",
  INTEGRATIONS = "/:merchantId/integrations",
  NOT_FOUND = "/404",
  CATCH_ALL = "/*",
  NO_MERCHANTS = "/no-merchants",
  ONBOARDING = "/:merchantId/onboarding",
  MAINTENANCE = "/maintenance",
}

export interface RouteConfig {
  path: ROUTES;
  /* 
    This is the path we want to redirect to when the merchant changes
    In some cases, we want to redirect to the same page, but in other cases, we want to redirect to a different page.
    For example, when we are on the collection page, we want to redirect to the collections page, because we don't have a collectionId for new merchant
  */
  pathOnMerchantChange: ROUTES | ((merchant: Merchant) => ROUTES);
  /*
    This is the name that is appended to the view's class name
    (depict--View${pageCssClassName}) and that's then targeted by the CSS (old way of doing things)
  */
  pageCssClassName: string;

  /*
    This is a function that determines whether a market is available for the route (useful for filtering out markets in the MarketSelector)
  */
  marketAvailableFilter: ((market: Market) => boolean) | null;
}

export const routeConfigs: Record<ROUTES, RouteConfig> = {
  [ROUTES.HOME]: {
    path: ROUTES.HOME,
    pathOnMerchantChange: ROUTES.HOME,
    pageCssClassName: "Home",
    marketAvailableFilter: null,
  },
  [ROUTES.LOGIN]: {
    path: ROUTES.LOGIN,
    pathOnMerchantChange: ROUTES.LOGIN,
    pageCssClassName: "Login",
    marketAvailableFilter: null,
  },
  [ROUTES.CATCH_ALL]: {
    path: ROUTES.CATCH_ALL,
    pathOnMerchantChange: ROUTES.DASHBOARD,
    pageCssClassName: "CatchAll",
    marketAvailableFilter: null,
  },
  [ROUTES.DASHBOARD]: {
    path: ROUTES.DASHBOARD,
    pathOnMerchantChange: ROUTES.DASHBOARD,
    pageCssClassName: "Dashboard",
    marketAvailableFilter: (market) => market.show_in_dashboard,
  },
  [ROUTES.BOOST_AND_BURY]: {
    path: ROUTES.BOOST_AND_BURY,
    pathOnMerchantChange: ROUTES.BOOST_AND_BURY,
    pageCssClassName: "RuleCard",
    marketAvailableFilter: null,
  },
  [ROUTES.CONFIGURE]: {
    path: ROUTES.CONFIGURE,
    pathOnMerchantChange: ROUTES.CONFIGURE,
    pageCssClassName: "Configure",
    marketAvailableFilter: (market) => market.show_in_config_interface,
  },
  [ROUTES.CATALOG]: {
    path: ROUTES.CATALOG,
    pathOnMerchantChange: ROUTES.CATALOG,
    pageCssClassName: "Catalog",
    marketAvailableFilter: null,
  },
  [ROUTES.CATEGORY_V3]: {
    path: ROUTES.CATEGORY_V3,
    pathOnMerchantChange: ROUTES.CATALOG, // Switching to Home instead breaks the app
    pageCssClassName: "CategoryV3",
    marketAvailableFilter: null,
  },
  [ROUTES.SEARCH]: {
    path: ROUTES.SEARCH,
    pathOnMerchantChange: ROUTES.SEARCH,
    pageCssClassName: "Search",
    marketAvailableFilter: null,
  },
  [ROUTES.LONG_TAIL_COLLECTIONS]: {
    path: ROUTES.LONG_TAIL_COLLECTIONS,
    pathOnMerchantChange: ROUTES.LONG_TAIL_COLLECTIONS,
    pageCssClassName: "LongTailCollections",
    marketAvailableFilter: (market) => market.show_in_long_tail_collection,
  },
  [ROUTES.INTEGRATIONS]: {
    path: ROUTES.INTEGRATIONS,
    pathOnMerchantChange: ROUTES.INTEGRATIONS,
    pageCssClassName: "Integrations",
    marketAvailableFilter: null,
  },
  [ROUTES.ONBOARDING]: {
    path: ROUTES.ONBOARDING,
    pathOnMerchantChange: ROUTES.ONBOARDING,
    pageCssClassName: "Onboarding",
    marketAvailableFilter: null,
  },
  [ROUTES.NOT_FOUND]: {
    path: ROUTES.NOT_FOUND,
    pathOnMerchantChange: ROUTES.NOT_FOUND,
    pageCssClassName: "NotFound",
    marketAvailableFilter: null,
  },
  [ROUTES.NO_MERCHANTS]: {
    path: ROUTES.NO_MERCHANTS,
    pathOnMerchantChange: ROUTES.NO_MERCHANTS,
    pageCssClassName: "NoMerchants",
    marketAvailableFilter: null,
  },
  [ROUTES.MAINTENANCE]: {
    path: ROUTES.MAINTENANCE,
    pathOnMerchantChange: ROUTES.MAINTENANCE,
    pageCssClassName: "Maintenance",
    marketAvailableFilter: null,
  },
};

export const getPath = (path: ROUTES) => {
  return routeConfigs[path].path;
};

/*
  This is a helper function to build a path with a merchantId
  For example, if we want to build the path for the dashboard page, we would call this function with the path and the merchantId. 
  This function will replace the :merchantId in the path with the merchantId
*/
export const buildPathWithMerchantId = (path: ROUTES, merchantId: string) => {
  return getPath(path).replace(":merchantId", merchantId);
};

/* 
  This is a helper function to get the path we want to redirect to when the merchant changes
  @example: getPathOnMerchantChange(ROUTES.COLLECTION, "1234") => "/1234/collections"
*/
export const getPathOnMerchantChange = (path: ROUTES, merchant: Merchant) => {
  const newPath = routeConfigs[path].pathOnMerchantChange;
  const merchantId = merchant.id;

  if (typeof newPath === "function")
    return buildPathWithMerchantId(newPath(merchant), merchantId);

  return buildPathWithMerchantId(newPath, merchantId);
};

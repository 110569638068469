import { CaretDown, CaretUp, Fire, Plus, Skull } from "@phosphor-icons/react";
import React, { useCallback, useContext, useState } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { RuleCardPlaceholder } from "src/components/storybook/BoostAndBury/RuleCardPlaceholder.tsx/RuleCardPlaceholder";
import { BoostBuryEffect } from "../api/types";
import { BoostRuleType } from "src/helpers/boostbury/availableOptions";
import RuleCard from "../components/storybook/BoostAndBury/RuleCard/RuleCard";
import LoadingBadge from "../components/storybook/LoadingBadge/LoadingBadge";
import useMerchant from "../helpers/hooks/app/useMerchant";
import { useBoostAndBury } from "../helpers/hooks/boostbury/useBoostAndBury";
import { useCachedState } from "../helpers/hooks/useCachedState";
import ProtectedRoute from "../router/ProtectedRoute";
import Button from "src/components/storybook/Button/Button";
import { getBoostRuleMetadata } from "../helpers/boostbury/boostbury.helper";
import BoostAndBuryTopBar from "../components/boostbury/BoostAndBuryTopBar";
import {
  ActionType,
  BoostBuryDispatchContext,
  BoostBuryProvider,
  BoostBurySelectorsContext,
} from "../components/boostbury/BoostBury.provider";
import BoostBuryModal from "../components/storybook/BoostAndBury/BoostAndBuryModal/BoostBuryModal";
import BoostBuryPreviewProvider from "../components/boostbury/BoostBuryPreview.provider";
import useAuthorizedApi from "../helpers/hooks/app/useAuthorizedApi";
import BoostAndBuryStaticExplainer from "../components/storybook/BoostAndBury/StaticExplainer/BoostAndBuryStaticExplainer";
import { theme } from "../components/storybook/designSystemVariables";
import { useCampaigns } from "src/helpers/hooks/useCampaigns";
import { ImageResizeProvider } from "src/components/storybook/ImageResizeProvider/ImageResizeProvider";
import { getData } from "src/api/authorizedApi";
import useUser from "src/helpers/hooks/useUser";
import { useDepictBlocker } from "src/helpers/hooks/app/useDepictBlocker";

const rulesPlaceholder = (nb: number) =>
  [...Array(nb)].map((_, i) => <RuleCardPlaceholder key={i} />);

const BoostAndBuryView = () => {
  const { user } = useUser();
  const { merchant, merchantId } = useMerchant();

  const [explanationToggle, setExplanationToggle] = useCachedState(
    "boostbury-explanation-toggle",
    true
  );

  const [showModal, setShowModal] = useState<BoostBuryEffect | false>(false);
  const { collections } = useCampaigns(merchant);

  const collectionBoostRuleType: BoostRuleType = "scollection";

  const { allRules, boostRules, buryRules, isLocalStateDifferentThanServer } =
    useContext(BoostBurySelectorsContext);

  useDepictBlocker(isLocalStateDifferentThanServer);

  const boostBuryDispatch = useContext(BoostBuryDispatchContext);

  const allRulesFiltered = allRules?.filter((r) => {
    if (r.type === "scollection" && collectionBoostRuleType !== "scollection") {
      return false;
    }

    return true;
  });

  const { api: boostAndBuryApi } = useAuthorizedApi();
  const getPreviewImages = useCallback(
    async (ruleType: BoostRuleType, collectionId?: string) => {
      if (
        !boostAndBuryApi ||
        (ruleType === collectionBoostRuleType && !collectionId)
      ) {
        return [];
      }

      if (ruleType === undefined) {
        return [];
      }

      const productsInPreview = await boostAndBuryApi.GET(
        "/api/v0/boost/{merchant_id}/preview/{rule_type}",
        {
          params: {
            path: {
              merchant_id: merchantId,
              rule_type: ruleType,
            },
            query: {
              collection_id: collectionId,
            },
          },
        }
      );

      const productsInPreviewData = getData(productsInPreview);

      return productsInPreviewData
        .filter((p) => p.image_url)
        .map((p) => p.image_url) as string[];
    },
    [boostAndBuryApi, collectionBoostRuleType, merchantId]
  );

  const {
    getRulesIsLoading,
    updateRules,
    updateRulesIsLoading,
    updateRulesIsError,
  } = useBoostAndBury(merchantId);

  const showBoostedRules =
    boostRules.length > 0 && !updateRulesIsError && !getRulesIsLoading;

  const showEmptyBoostedRules =
    boostRules.length === 0 && !updateRulesIsError && !getRulesIsLoading;

  const showLoadingBoostedRules = getRulesIsLoading;

  const showBuriedRules =
    buryRules.length > 0 && !getRulesIsLoading && !updateRulesIsError;

  const showEmptyBuriedRules =
    buryRules.length === 0 && !getRulesIsLoading && !updateRulesIsError;

  const showLoadingBuriedRules = getRulesIsLoading;

  return (
    <ImageResizeProvider merchantId={merchantId}>
      <ProtectedRoute
        user={user}
        merchant={merchant}
        accessValidator={(u, m) =>
          u.is_superuser ||
          (!!m.no_code_config &&
            (m.product_status.search_state !== "inactive" ||
              m.product_status.plp_state !== "inactive"))
        }
      >
        <div className="depict--MainContent container mt-3 px-5 d-flex flex-column position-relative h-100">
          <BoostAndBuryTopBar
            hasLocalChanges={isLocalStateDifferentThanServer}
            onRevert={() =>
              boostBuryDispatch({
                type: ActionType.SET_LOCAL_TO_SERVER,
                payload: {},
              })
            }
            onSave={() => updateRules().then()} // Prob some better way of type-ing this
            saveIsLoading={updateRulesIsLoading}
          />
          <div className="depict--Body d-flex flex-column h-100">
            {getRulesIsLoading ? (
              <Container>
                <LoadingBadge label={"Loading rules..."} />
              </Container>
            ) : (
              <Container>
                <Row className="bg-white rounded-xl p-3">
                  <Col>
                    <div className="d-flex align-items-center mb-3">
                      <h1
                        className="mb-0 text-xl ms-2-5"
                        style={{ fontWeight: 700 }}
                      >
                        Boost
                      </h1>
                      <Button
                        variant="link"
                        style={{
                          padding: "0.5rem",
                        }}
                        onClick={() => setShowModal("boost")}
                        icon={<Plus size={16} color="black" />}
                        text=""
                      />
                    </div>
                    <Stack gap={3}>
                      {showBoostedRules &&
                        boostRules.length > 0 &&
                        boostRules.map((r, i) => (
                          <RuleCard
                            key={i}
                            title={getBoostRuleMetadata(r).title}
                            boostTypeLabel={
                              getBoostRuleMetadata(r).boostTypeLabel
                            }
                            unsaved={typeof r.id === "undefined"}
                            icon={
                              <Fire color="black" weight="bold" size={16} />
                            }
                            onDelete={() => {
                              boostBuryDispatch({
                                type: ActionType.REMOVE_RULE,
                                payload: { rule: r },
                              });
                            }}
                          />
                        ))}
                      {showEmptyBoostedRules && (
                        <div className="text-gray-600 text-center mt-3">
                          No rules set
                        </div>
                      )}
                      {showLoadingBoostedRules &&
                        rulesPlaceholder(boostRules.length + 1)}
                    </Stack>
                  </Col>
                  <Col>
                    <div className="d-flex align-items-center mb-3">
                      <h1
                        className="mb-0 text-xl ms-2-5"
                        style={{ fontWeight: 700 }}
                      >
                        Bury
                      </h1>
                      <Button
                        style={{
                          padding: "0.5rem",
                        }}
                        onClick={() => setShowModal("bury")}
                        variant="link"
                        icon={<Plus size={16} color="black" />}
                        text=""
                      />
                    </div>
                    <Stack gap={3}>
                      {showBuriedRules &&
                        buryRules.length > 0 &&
                        buryRules.map((r, i) => (
                          <RuleCard
                            key={i}
                            title={getBoostRuleMetadata(r).title}
                            boostTypeLabel={
                              getBoostRuleMetadata(r).boostTypeLabel
                            }
                            unsaved={typeof r.id === "undefined"}
                            icon={
                              <Skull color="black" weight="bold" size={16} />
                            }
                            onDelete={() => {
                              boostBuryDispatch({
                                type: ActionType.REMOVE_RULE,
                                payload: { rule: r },
                              });
                            }}
                          />
                        ))}
                      {showEmptyBuriedRules && (
                        <div className="text-gray-600 text-center mt-3 mb-3">
                          No rules set.
                        </div>
                      )}
                      {showLoadingBuriedRules &&
                        rulesPlaceholder(buryRules.length + 1)}
                    </Stack>
                  </Col>
                </Row>
              </Container>
            )}
            <div
              className="w-100 d-flex flex-column relative mt-3 p-3 rounded-xl border-1 dpt-color-border-subtle-disabled"
              style={{
                borderStyle: "solid",
                maxHeight: "800px",
                background: explanationToggle
                  ? "none"
                  : theme.border.subtle.disabled,
                transition: "background 0.3s ease-in-out",
              }}
            >
              <div
                onClick={() => setExplanationToggle(!explanationToggle)}
                className="pointer d-flex align-items-center justify-content-between w-100 px-3"
              >
                <div>{/* TODO - Add info button from design here */}</div>
                <h1
                  className={`${
                    explanationToggle ? "text-xl" : "mb-0 text-sm"
                  }`}
                  style={{
                    fontWeight: 700,
                    color: explanationToggle
                      ? "black"
                      : theme.textIcon.subtle.default,
                    transition: "all 0.3s ease-in-out",
                  }}
                >
                  Understanding Boost & Bury
                </h1>
                <div>
                  {explanationToggle ? (
                    <CaretUp size={16} color="black" />
                  ) : (
                    <CaretDown size={16} color="black" />
                  )}
                </div>
              </div>
              <p
                className="text-center overflow-hidden text-xs"
                style={{
                  maxHeight: explanationToggle ? "100%" : "0",
                  maxWidth: "650px",
                  margin: "0 auto",
                  transition: "max-height 0.3s ease-in-out",
                }}
              >
                The recommended sorting in categories and search is determined
                by our algorithm and your boost and bury rules. The algorithm
                will push boosted products to the top, and push buried products
                to the bottom. Here's a preview of what might happen:
              </p>
              <div
                className="flex-grow-1 overflow-hidden"
                style={{
                  marginTop: explanationToggle ? "1rem" : "0",
                  maxHeight: explanationToggle ? "100%" : "0",
                  transition: "max-height 0.3s ease-in-out",
                }}
              >
                <BoostAndBuryStaticExplainer />
              </div>
            </div>
          </div>
        </div>
        <BoostBuryPreviewProvider fetchPreviewImages={getPreviewImages}>
          <BoostBuryModal
            showVariant={showModal}
            onClose={() => {
              setShowModal(false);
            }}
            onCreateRule={(rule) => {
              boostBuryDispatch({
                type: ActionType.ADD_RULE,
                payload: { rule },
              });
            }}
            existingRules={allRulesFiltered ?? []}
            collections={collections ?? undefined}
            collectionBoostRuleType={collectionBoostRuleType}
          />
        </BoostBuryPreviewProvider>
      </ProtectedRoute>
    </ImageResizeProvider>
  );
};

const Wrapper = () => {
  return (
    <BoostBuryProvider>
      <BoostAndBuryView />
    </BoostBuryProvider>
  );
};

export default React.memo(Wrapper);

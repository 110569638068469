import { useState, useEffect } from "react";

import { Relationship, ConfiguredProductGroup } from "src/types/configuration";
import { ControlLoadingQueueCB } from "src/types/components";
import useLoadGroups from "./useLoadGroups";

type UseOnViewGroups = [onViewGroups: ConfiguredProductGroup[] | null];

export default function useOnViewGroups(
  relationships: Relationship[] | null,
  merchant: string | null,
  market: string | null,
  controlLoadingQueueCB: ControlLoadingQueueCB
): UseOnViewGroups {
  const [onViewGroups, setOnViewGroups] = useState<
    ConfiguredProductGroup[] | null
  >(null);
  const [loadGroups] = useLoadGroups(controlLoadingQueueCB);

  useEffect(() => {
    _loadOnViewGroups(relationships, merchant, market);

    return () => {
      setOnViewGroups(null);
    };

    async function _loadOnViewGroups(
      relationships: Relationship[] | null,
      merchant: string | null,
      market: string | null
    ) {
      let result = await loadGroups(relationships, merchant, market);
      setOnViewGroups(result);
    }
    /**
     * Here we deliberately ignore the full dependency array, because we only want to recompute the groups when the relationships changes.
     * Doing so even when market or merchant changes, will cause multiple requests to the server which could return 400 when group_id/market/merchant missmatches.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationships]);

  return [onViewGroups];
}

import {
  ProductMetadata,
  ProductMetadataContext,
} from "../../catalog/ProductMetadataProvider";
import { useContext } from "react";

export const sortMetadata = (a: ProductMetadata, b: ProductMetadata) => {
  if (a.type === "out_of_stock" && b.type !== "out_of_stock") {
    return 1;
  } else if (a.type !== "out_of_stock" && b.type === "out_of_stock") {
    return -1;
  } else {
    return 0;
  }
};

export const useCatalogMetadata = () => {
  const { metadata: productMetadata } =
    useContext(ProductMetadataContext) || {};
  // Function to sort items based on the type, putting "out_of_stock" first

  // Sort such that out_of_stock is always at the same place
  for (const key in productMetadata) {
    if (Object.prototype.hasOwnProperty.call(productMetadata, key)) {
      const dataArray = productMetadata[key];
      const sortedMetadata = [...dataArray].sort(sortMetadata);
      productMetadata[key] = sortedMetadata;
    }
  }

  return {
    productMetadata: productMetadata || {},
  };
};

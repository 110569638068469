import { useQuery } from "@tanstack/react-query";
import { BasePortalProductDto, HiddenPortalProductDto } from "src/api/types";
import { QueryId, getQueryKey } from "../../../queries/queries";

export type GetCuratedProductsCallback = (
  merchantId: string,
  categoryId: string
) => Promise<{
  pinned: string[];
  hidden: string[];
  curatedProducts: Record<string, BasePortalProductDto>;
  totalProductCount: number;
}>;

export type GetCuratedProductsConfig = {
  enabled: boolean;
  querySalt: string;
  callback: GetCuratedProductsCallback;
};

export function useCategoryCuratedProducts(
  merchantId: string | null,
  categoryId: string | null,
  getCuratedProducts: GetCuratedProductsConfig
) {
  const query = useQuery({
    queryKey: getQueryKey(
      QueryId.GetCategoryProducts,
      merchantId || "",
      categoryId || "",
      getCuratedProducts.querySalt
    ),
    queryFn: () => {
      if (merchantId && categoryId) {
        return getCuratedProducts.callback(merchantId, categoryId);
      }
    },
    enabled: !!merchantId && !!categoryId && !!getCuratedProducts.enabled,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return {
    curatedProductsDto: query.data,
    isLoading: query.isLoading,
    isError: query.isError,
    refetch: query.refetch,
  };
}

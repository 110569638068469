import { Plus } from "@phosphor-icons/react";
import React from "react";
import { Button as BootstrapButton } from "react-bootstrap";

export interface ButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  text: string;
  variant?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark"
    | "link"
    | "outline-primary"
    | "outline-secondary"
    | "outline-success"
    | "outline-danger"
    | "outline-warning"
    | "outline-info"
    | "outline-light"
    | "outline-dark";

  icon?: React.ReactNode | null;
  hoverIcon?: React.ReactNode;
  style?: React.CSSProperties;
  hoverStyle?: React.CSSProperties;
  endIcon?: React.ReactNode;
  disabled?: boolean;
}

export const Button = (props: ButtonProps) => {
  const [hover, setHover] = React.useState(false);

  const style: React.CSSProperties = {
    ...props.style,
    ...(hover ? props.hoverStyle : {}),
  };

  const iconToUse = props.icon !== null ? props.icon || <Plus /> : undefined;
  const hoverIconToUse = props.hoverIcon || iconToUse;

  return (
    <BootstrapButton
      style={style}
      variant={props.variant || "primary"}
      className="d-flex align-items-center gap-2"
      onClick={props.onClick}
      disabled={props.disabled}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {hover ? hoverIconToUse : iconToUse}
      {props.text}
      {props.endIcon}
    </BootstrapButton>
  );
};

export default Button;

import { Warning } from "src/components/linearicons";
import { AlertMessage } from "../../components/storybook/Alert/types";
export const errorMessages: AlertMessage[] = [
  {
    id: "save-configuration",
    title: "Saving configuration",
    message:
      "We experienced a problem while saving the selected configuration. Please contact us if the problem persists.",
    type: "info",
  },
  {
    id: "delete-configuration",
    title: "Delete configuration",
    message:
      "We experienced a problem while deleting the selected configuration. Please contact us if the problem persists.",
    type: "info",
  },
  {
    id: "duplicate-groups",
    title: "Copying configuration",
    message:
      "We experienced a problem while making a copy of the selected configuration. Please try again.",
    type: "info",
  },
  {
    id: "create-relationships",
    title: "Continue",
    message:
      "We experienced a problem while setting the current configuration. Please contact us if the problem persists.",
    type: "info",
  },
  {
    id: "session-expired",
    title: "Session expired",
    message: "You will be logged out soon.",
    autohide: true,
    type: "info",
    icon: <Warning size="12" />,
  },
  {
    id: "3xx",
    title: "Important",
    message: (
      <>
        We have encountered an error while communicating with our servers.
        <br />
        Please reload the page and try again.
      </>
    ),
    type: "warning",
    icon: <Warning size="12" />,
  },
  {
    id: "4xx",
    title: "Important",
    message: (
      <>
        We have encountered an error while communicating with our servers.
        <br />
        Please reload the page and try again.
      </>
    ),
    type: "danger",
    icon: <Warning size="12" />,
  },
  {
    id: "5xx",
    title: "Important",
    message: (
      <>
        We have encountered an error while communicating with our servers.
        <br />
        Please reload the page and try again.
      </>
    ),
    type: "danger",
    icon: <Warning size="12" />,
  },
  {
    id: "400",
    message: "Please check the submitted data and try again.",
    title: "Bad request",
    type: "warning",
    icon: <Warning size="12" />,
  },
  {
    id: "401",
    message: (
      <>
        We have encountered an authentication problem. Please try{" "}
        <a className="link-dark text-decoration-underline" href="/login">
          login
        </a>{" "}
        again.
      </>
    ),
    title: "Authentication",
    type: "warning",
    icon: <Warning size="12" />,
  },
  {
    id: "unknown",
    message: "Something unexpected happened. Please try again.",
    title: "Unknown error",
    type: "warning",
    icon: <Warning size="12" />,
  },
  {
    id: "no-merchant",
    message:
      "We could not find a merchant associated with your account. Please get in contact with us at support@depict.ai, we will solve this issue as soon as possible.",
    title: "No merchant",
    type: "warning",
    icon: <Warning size="12" />,
  },
  {
    id: "drag-no-id",
    message:
      "We have encountered an error while sorting the items. Please reload and try again.",
    title: "Ooops...",
    type: "warning",
    icon: <Warning size="12" />,
  },
];

export const getErrorMessage = (id: string) => {
  let alert = errorMessages.find((error) => error.id === id);
  return alert;
};

export const getAlertMessage = async (
  response?: Response
): Promise<AlertMessage> => {
  const status = response?.status ?? 0;

  console.log("%c[ERROR HANDLER]", "color: red", status);

  let message: AlertMessage | undefined;
  try {
    //THIS .JSON() CALL COULD FAIL. that's why we it is wrapped in try catch
    const detail: { [detail: string]: string } | undefined = (response ? await response.json() : undefined);

    if (status === 400) {
      message = getErrorMessage("400");
    } else if (status === 401 || status === 403) {
      message = getErrorMessage("401");
    } else if (status >= 300 && status < 400) {
      message = getErrorMessage("3xx");
    } else if (status >= 400 && status < 500) {
      message = getErrorMessage("4xx");
    } else if (status >= 500) {
      message = getErrorMessage("5xx");
    } else {
      message = getErrorMessage("unknown");
    }

    if (detail?.detail) {
      //@ts-ignore
      message.detail =
        typeof detail.detail === "string"
          ? detail.detail
          : JSON.stringify(detail.detail);
    }
  } catch (e) {
    message = getErrorMessage("unknown");
  }

  return message as AlertMessage;
};

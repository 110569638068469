import { useCallback } from "react";
import {
  Theme,
  toast,
  ToastOptions,
  TypeOptions,
  UpdateOptions,
} from "react-toastify";
import { theme } from "../designSystemVariables";
import DangerAlert from "./Alerts/DangerAlert";
import InfoAlert from "./Alerts/InfoAlert";
import SuccessAlert from "./Alerts/SuccessAlert";
import WarningAlert from "./Alerts/WarningAlert";
import { AlertMessage } from "./types";
import { X } from "@phosphor-icons/react";

export type AlertId = AlertMessage["id"];

const typeMapping = (type: AlertMessage["type"]): TypeOptions => {
  if (type === "danger") return "error";
  else {
    return type as TypeOptions;
  }
};

const bodyMapping = (message: AlertMessage) => {
  const tmpProps = {
    title: message.title || "",
    message: message.message,
  };

  if (message.type === "danger") {
    return <DangerAlert {...tmpProps} />;
  } else if (message.type === "info") {
    return <InfoAlert {...tmpProps} />;
  } else if (message.type === "success") {
    return <SuccessAlert {...tmpProps} />;
  } else {
    return <WarningAlert {...tmpProps} />;
  }
};

const themeMapping = (type: AlertMessage["type"]): Theme => {
  if (type === "default") {
    return "light";
  } else {
    return "colored";
  }
};

const backgroundColorMapping = (type: AlertMessage["type"]) => {
  if (type === "default") {
    return theme.background.base.default;
  } else if (type === "danger") {
    return theme.background.danger.default;
  } else if (type === "info") {
    return theme.background.info.hover;
  } else if (type === "success") {
    return theme.background.success.default;
  } else if (type === "warning") {
    return theme.background.warning.default;
  } else {
    return theme.background.base.default;
  }
};

const progressBarColorMapping = (type: AlertMessage["type"]) => {
  if (type === "default") {
    return theme.textIcon.base.default;
  } else if (type === "danger") {
    return theme.textIcon.danger.default;
  } else if (type === "info") {
    return theme.textIcon.accent.default;
  } else if (type === "success") {
    return theme.textIcon.success.default;
  } else if (type === "warning") {
    return theme.textIcon.warning.default;
  } else {
    return theme.textIcon.base.default;
  }
};

export function useAlerts() {
  const addAlert = useCallback((alert: AlertMessage) => {
    const options: ToastOptions = {
      type: typeMapping(alert.type),
      autoClose: alert.autohide ? 5000 : false,
      theme: themeMapping(alert.type),
      style: {
        backgroundColor: backgroundColorMapping(alert.type),
      },
      bodyStyle: {
        backgroundColor: backgroundColorMapping(alert.type),
        width: "500px",
      },
      icon: false,
      closeButton: <X size={32} color={theme.textIcon.base.default} />,
      progressStyle: {
        backgroundColor: progressBarColorMapping(alert.type),
      },
      toastId: alert.exclusive ? alert.id : undefined,
    };
    if (alert.exclusive && toast.isActive(alert.id)) {
      const updateOptions: UpdateOptions = {
        ...options,
        render: bodyMapping(alert),
      };

      toast.update(alert.id, updateOptions);
    } else {
      toast(bodyMapping(alert), options);
    }
  }, []);

  const removeAlert = useCallback((alert: AlertMessage | AlertId) => {
    // toast.dismiss(alert.id);
    throw new Error("Not implemented");
  }, []);

  return {
    addAlert,
    removeAlert,
  };
}

import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import Card from "src/components/storybook/Card/Card";
import { useState } from "react";

import LoadingBadge from "../../LoadingBadge/LoadingBadge";
import IntegrationStepHeader from "../IntegrationStepHeader/IntegrationStepHeader";
import { ShopifyConfigApiResponse } from "src/api/types";

function isValidUrl(url: string) {
  // use regex to validate url
  if (!url.includes("myshopify.com")) {
    return false;
  }
  const urlRegex = new RegExp(
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/
  );
  return urlRegex.test(url);
}

function isValidApiKey(apiKey: string) {
  // use regex to validate api key
  if (!apiKey.startsWith("shpat_")) {
    return false;
  }
  const apiKeyRegex = new RegExp("^[a-zA-Z0-9_]{38}$");
  return apiKeyRegex.test(apiKey);
}

export default function ShopifyApiConfig({
  verifyCredentialsResponse,
  isLoading,
  errorMessage,
  adminApiUrl,
  adminApiKey,
  onSubmit,
  onBack,
  onVerifyShopifyCredentials,
}: {
  verifyCredentialsResponse: ShopifyConfigApiResponse | null;
  errorMessage: string | null;
  isLoading: boolean;
  adminApiUrl: string;
  adminApiKey: string;
  onSubmit: (adminApiUrl: string, adminApiKey: string) => void;
  onBack: () => void;
  onVerifyShopifyCredentials: (
    adminApiUrl: string,
    adminApiKey: string
  ) => void;
}) {
  const [newAdminApiUrl, setNewAdminApiUrl] = useState<string>(adminApiUrl);
  const [newAdminApiKey, setNewAdminApiKey] = useState<string>(adminApiKey);

  return (
    <Card>
      <Card.Body>
        <IntegrationStepHeader
          title="Connect Shopify to Depict"
          stepNumber={2}
        />
        <Card.Text>
          In order to connect you must provide your Shopify Admin URL and token
          with sufficient read permissions. See our{" "}
          <a
            href="https://docs.depict.ai/data-ingestion-guide/shopify-setup-guide"
            target="_blank"
            rel="noreferrer"
          >
            documentation
          </a>{" "}
          for more information.
        </Card.Text>
        <Form>
          <Form.Group controlId="apiURL">
            <Form.Label>Shopify Admin URL</Form.Label>
            <Form.Control
              type="text"
              placeholder="https://storename.myshopify.com/admin"
              isValid={verifyCredentialsResponse?.status === "success"}
              isInvalid={
                !!verifyCredentialsResponse?.api_url_error ||
                (newAdminApiUrl.length > 0 && !isValidUrl(newAdminApiUrl))
              }
              value={newAdminApiUrl}
              onChange={(e) => setNewAdminApiUrl(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="apiKey" className="mt-2">
            <Form.Label>Shopify Admin API Key</Form.Label>
            <Form.Control
              type="text"
              placeholder="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"
              isValid={verifyCredentialsResponse?.status === "success"}
              isInvalid={
                !!verifyCredentialsResponse?.api_key_error ||
                (newAdminApiKey.length > 0 && !isValidApiKey(newAdminApiKey))
              }
              value={newAdminApiKey}
              onChange={(e) => setNewAdminApiKey(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="alertBanner" className="mt-3">
            {isLoading && (
              <div>
                <LoadingBadge label={"Checking permissions"} />
                <div className="mt-2">
                  We’re checking if we have the right read permissions to
                  connect to Shopify before we are able to continue.
                </div>
              </div>
            )}
            {verifyCredentialsResponse?.api_url_error && (
              <Alert variant="danger">
                {verifyCredentialsResponse?.api_url_error}
              </Alert>
            )}
            {verifyCredentialsResponse?.api_key_error && (
              <Alert variant="danger">
                {verifyCredentialsResponse?.api_key_error}
                {verifyCredentialsResponse?.missing_admin_scopes && (
                  <div className="mt-2">
                    You are missing the following Admin API scopes:{" "}
                    <ul className="mb-0">
                      {verifyCredentialsResponse?.missing_admin_scopes.map(
                        (scope) => (
                          <li>{scope}</li>
                        )
                      )}
                    </ul>
                  </div>
                )}
                {verifyCredentialsResponse?.missing_storefront_scopes && (
                  <div className="mt-2">
                    You are missing the following Storefront API scopes:{" "}
                    <ul className="mb-0">
                      {verifyCredentialsResponse?.missing_storefront_scopes.map(
                        (scope) => (
                          <li>{scope}</li>
                        )
                      )}
                    </ul>
                  </div>
                )}
              </Alert>
            )}
            {errorMessage && (
              <Alert variant="danger">Error: {errorMessage}</Alert>
            )}
            {verifyCredentialsResponse?.status === "success" && (
              <Alert variant="success">
                Success! We have the right permissions to connect to Shopify.
              </Alert>
            )}
          </Form.Group>
        </Form>
      </Card.Body>
      <Card.Footer>
        <Row>
          <Col>
            <Button
              variant="outline-secondary"
              className="w-100"
              onClick={onBack}
            >
              Back
            </Button>
          </Col>
          <Col>
            {verifyCredentialsResponse?.status === "success" ? (
              <Button
                variant="primary"
                className="w-100"
                onClick={() => onSubmit(newAdminApiUrl, newAdminApiKey)}
              >
                Continue
              </Button>
            ) : (
              <Button
                variant="primary"
                className="w-100"
                disabled={
                  isLoading || newAdminApiUrl === "" || newAdminApiKey === ""
                }
                onClick={() =>
                  onVerifyShopifyCredentials(newAdminApiUrl, newAdminApiKey)
                }
              >
                Verify
              </Button>
            )}
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
}

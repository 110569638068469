import { css } from "@emotion/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { GetCollectionDto } from "src/api/types";
import CategoryBreadcrumbs from "src/assets/img/CategoryBreadcrumbs.svg";
import CategoryFilters from "src/assets/img/CategoryFilters.svg";
import CategoryQuicklinks from "src/assets/img/CategoryQuicklinks.svg";
import {
  CollectionMetadataSchema,
  collectionMetadataSchema,
} from "src/components/storybook/Categories/categories.schemas";
import DepictModal from "src/components/storybook/Modal/Modal";
import { TitleEditor, Titles } from "../TitleEditor/TitleEditor";
import { CategoryConfigField } from "./CategoryConfigField";
import "./CategoryConfigModal.scss";
import { CategoryConfigOption } from "./CategoryConfigOption";
import useOnValueChange from "src/helpers/hooks/useOnValueChange";
import { CategoryConfig } from "./CategoryConfigModal.types";

export type onSaveCB = (
  titles: Titles | null,
  queryId: string | null,
  config: CategoryConfig
) => void;
export interface CategoryConfigModalProps {
  show: boolean;
  canEditMetaData: boolean | undefined;
  isAbleToDelete: boolean | undefined;
  isAbleToChangeVisibility: boolean | undefined;
  serverCategory: GetCollectionDto | null;
  collectionDescription: string;
  onSave: onSaveCB;
  onClose: () => void;
  onDelete: (() => void) | undefined;
  defaultLocale: string;
}

interface SaveProps {
  saveCategoryConfig: () => void;
}

function Save(props: SaveProps) {
  const form = useFormContext<CollectionMetadataSchema>();
  const hasErrors = Object.keys(form.formState.errors).length > 0;
  const hasChanges = form.formState.isDirty;

  const disabled = hasErrors || !hasChanges;

  return (
    <Button onClick={props.saveCategoryConfig} disabled={disabled}>
      Save
    </Button>
  );
}

export const CategoryConfigModal: FC<CategoryConfigModalProps> = ({
  serverCategory,
  show,
  canEditMetaData,
  isAbleToDelete,
  isAbleToChangeVisibility,
  collectionDescription,
  onSave,
  onClose,
  onDelete,
  defaultLocale,
}) => {
  const values = useMemo<CollectionMetadataSchema>(() => {
    return {
      queryId: serverCategory?.query_id || "",
      show_in_filter: serverCategory?.show_in_filter || false,
      display_breadcrumb: serverCategory?.display_breadcrumb || false,
      appear_in_quicklinks: serverCategory?.appear_in_quicklinks || false,
      titles: serverCategory?.localized_titles || {},
    };
  }, [serverCategory]);

  const form = useForm<CollectionMetadataSchema>({
    defaultValues: values,
    resolver: zodResolver(collectionMetadataSchema),
    mode: "onChange",
  });

  useOnValueChange(show, () => {
    form.reset(values);
  });

  const [confirmDelete, setConfirmDelete] = useState(false);

  const saveCategoryConfig = async () => {
    const currentQueryId = form.getValues("queryId");
    const queryIdHasChanged = !!form.formState.dirtyFields.queryId;
    const queryId =
      canEditMetaData && queryIdHasChanged ? currentQueryId : null;

    const currentConfig: CategoryConfig = {
      show_in_filter: form.getValues("show_in_filter"),
      display_breadcrumb: form.getValues("display_breadcrumb"),
      appear_in_quicklinks: form.getValues("appear_in_quicklinks"),
    };

    const currentTitles = form.getValues("titles");
    const titlesHasChanged = form.formState.dirtyFields.titles;
    const titles = titlesHasChanged ? currentTitles : null;

    onSave(titles, queryId, currentConfig);
    onClose();
  };

  const deleteCategory = () => {
    if (confirmDelete) {
      onDelete?.();
    } else {
      setConfirmDelete(true);
    }
  };

  const nbOfLocales = Object.keys(values.titles).length;
  const nbOfLocalesDifferentThanDefault = nbOfLocales - 1;

  return (
    <FormProvider {...form}>
      <DepictModal
        dialogClassName={"category-config-modal"}
        contentClassName="category-config-modal-content"
        className="depict--Modal"
        show={show}
        title={`Edit ${collectionDescription}`}
      >
        <Modal.Body
          css={css`
            display: flex;
            flex-direction: column;
            gap: 1rem;
            overflow-y: auto;
          `}
        >
          {canEditMetaData && (
            <div
              css={css`
                display: flex;
                gap: 1rem;
                flex: 1;
                justify-content: space-between;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  flex: 1;
                  gap: 1rem;
                `}
              >
                <div
                  css={css`
                    flex-grow: 1;
                  `}
                >
                  <CategoryConfigField
                    name={`titles.${defaultLocale}` as const}
                    label={`Title (${defaultLocale})`}
                    getErrorMessage={() => {
                      return form.formState.errors.titles?.[defaultLocale]
                        ?.message;
                    }}
                  />
                </div>
                {nbOfLocalesDifferentThanDefault > 0 && (
                  <TitleEditor
                    defaultLocale={defaultLocale}
                    getErrorMessage={(errors, locale) => {
                      return errors.titles?.[locale]?.message;
                    }}
                  />
                )}
              </div>
              <div
                css={css`
                  min-width: 240px;
                `}
              >
                <CategoryConfigField
                  name="queryId"
                  label="ID"
                  copyToClipboard
                  getErrorMessage={() => form.formState.errors.queryId?.message}
                />
              </div>
            </div>
          )}
          {isAbleToChangeVisibility && (
            <div>
              <strong
                css={css`
                  display: block;
                  margin-bottom: 0.25rem;
                `}
              >
                Visibility options
              </strong>
              <div
                css={css`
                  display: flex;
                  gap: 2rem;
                `}
              >
                <CategoryConfigOption
                  label="Category filters"
                  description="Shows inside the filters on the search page"
                  image={CategoryFilters}
                  name={"show_in_filter"}
                />
                <CategoryConfigOption
                  label="Breadcrumbs"
                  description="Shows at the top of the page for quick navigation"
                  image={CategoryBreadcrumbs}
                  name={"display_breadcrumb"}
                />
                <CategoryConfigOption
                  label="Quicklinks"
                  description="Shows below the search field as category suggestions"
                  image={CategoryQuicklinks}
                  name={"appear_in_quicklinks"}
                />
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer
          css={css`
            display: flex;
            justify-content: space-between !important;
            align-items: flex-start !important;

            > * {
              margin: 0 !important;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              gap: 2rem;
            `}
          >
            {isAbleToDelete && (
              <>
                {confirmDelete && (
                  <div
                    css={css`
                      color: #92363e;
                      font-size: 12px;
                      max-width: 240px;
                    `}
                  >
                    <span
                      css={css`
                        display: block;
                        font-weight: bold;
                      `}
                    >
                      Are you sure?
                    </span>
                    <span>
                      This will delete the merchandising data associated with
                      this list (e.g. pins).
                    </span>
                  </div>
                )}
                <div>
                  <Button onClick={deleteCategory} variant="danger">
                    {confirmDelete
                      ? "Yes, Delete anyway"
                      : `Delete ${collectionDescription}`}
                  </Button>
                </div>
              </>
            )}
          </div>
          <div
            css={css`
              display: flex;
              gap: 1rem;
            `}
          >
            <Button onClick={onClose} variant="outline-primary">
              Cancel
            </Button>
            <Save saveCategoryConfig={saveCategoryConfig} />
          </div>
        </Modal.Footer>
      </DepictModal>
    </FormProvider>
  );
};

import { useState, useCallback } from "react";
import { Compare } from "src/types/components";

export default function useParam(): [
  parameter: Compare,
  setCallback: (value: Compare) => void
] {
  const [parameter, setParameter] = useState<Compare>("none");

  const setCallback = useCallback((value: Compare) => {
    console.log(`[FILTERS] compare is now ${JSON.stringify(value)}`);
    setParameter(value);
  }, []);

  return [parameter, setCallback];
}

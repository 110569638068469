import { Alert, Button, Col, Form, Row, Spinner, Stack } from "react-bootstrap";
import { useState } from "react";
import Card from "src/components/storybook/Card/Card";
import IntegrationStepHeader from "../IntegrationStepHeader/IntegrationStepHeader";

export default function ShopifyMarketConfig({
  availableMarkets,
  isLoading,
  errorMessage,
  onSubmit,
  onBack,
}: {
  availableMarkets: { id: string; name: string }[];
  isLoading: boolean;
  errorMessage: string | null;
  onSubmit: (selectedMarketIds: string[]) => void;
  onBack: () => void;
}) {
  const [selectedMarketIds, setSelectedMarketIds] = useState<string[]>([]);
  return (
    <Card>
      <Card.Body>
        <IntegrationStepHeader title="Review markets" stepNumber={3} />
        <Card.Text>
          Select the markets you would like to import to Depict. Please leave
          out markets that are used for testing purposes.
        </Card.Text>
        {isLoading && (
          <div className="d-flex justify-content-center py-4">
            <Spinner />
          </div>
        )}
        {errorMessage && (
          <Alert variant="danger">
            Error fetching your markets: {errorMessage}
          </Alert>
        )}
        {!isLoading && !errorMessage && availableMarkets.length === 0 && (
          <Alert variant="danger">
            No markets found in your Shopify store.
          </Alert>
        )}
        <h5 className="fw-bold mb-3 mt-5">Markets</h5>
        <Stack direction={"vertical"} gap={2}>
          {availableMarkets.map((market) => (
            <Form.Group key={market.id}>
              <Form.Check
                type="checkbox"
                label={market.name}
                checked={selectedMarketIds.includes(market.id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedMarketIds([...selectedMarketIds, market.id]);
                  } else {
                    setSelectedMarketIds(
                      selectedMarketIds.filter((id) => id !== market.id)
                    );
                  }
                }}
              />
            </Form.Group>
          ))}
        </Stack>
      </Card.Body>
      <Card.Footer>
        <Row>
          <Col>
            <Button
              variant="outline-secondary"
              className="w-100"
              onClick={onBack}
            >
              Back
            </Button>
          </Col>
          <Col>
            <Button
              variant="primary"
              className="w-100"
              onClick={() => onSubmit(selectedMarketIds)}
              disabled={selectedMarketIds.length === 0}
            >
              Continue
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
}

import { getColorLuminanceFromHex } from "../../../helpers/colors/luminance";
import { CurvyCorner } from "../CurvyCorner/CurvyCorner";
import { theme } from "../designSystemVariables";
import { HoverTitle } from "../HoverTitle/HoverTitle";
import { css } from "@emotion/react";

const smoothTriangle = (backgroundColor: string) => css`
  overflow: hidden;
  position: absolute;
  width: 2em;
  height: 2em;
  &::before {
    content: "";
    display: block;
    height: 100%;
    box-shadow: 0 0 0 1em ${backgroundColor};
    border-radius: 0 0 35% 0;
  }
`;

interface ColorDotProps {
  hexCode: string;
  name: string;
  backgroundColor?: string;
}

export function ColorDot(props: ColorDotProps) {
  const isVeryLight = getColorLuminanceFromHex(props.hexCode) > 0.85;
  const dot = (
    <div
      css={css`
        width: 1em;
        height: 1em;
        border-radius: 50%;
      `}
      style={{
        background: props.hexCode,
        outline: !isVeryLight
          ? ""
          : `${theme.textIcon.subtle.default} 0.15rem solid`,
      }}
    ></div>
  );

  return (
    <CurvyCorner
      corner="bottom-right"
      borderRadius="0.5em"
      backgroundColor={props.backgroundColor}
      extraStyles={{ zIndex: 10 }}
    >
      <div
        className="depict--HoverTitle-parent"
        css={css`
          z-index: 10;
          position: relative;
        `}
      >
        <div
          css={css`
            position: absolute;
            top: -40px;
            right: 0;
          `}
        >
          <HoverTitle content={props.name} />
        </div>
        <div
          css={css`
            padding: 4px 0 0 4px;
          `}
        >
          {dot}
        </div>
      </div>
    </CurvyCorner>
  );
}

import { Suspense, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import AppLoader from "src/components/mix/AppLoader";
import SplashScreen from "src/components/mix/SplashScreen";
import { useAuthorizedMerchantsContext } from "src/helpers/hooks/app/useAuthorizedMerchantsContext";
import { ROUTES } from "./Routes";
import { useIntercom } from "react-use-intercom";
import { useAlerts } from "src/components/storybook/Alert/useAlerts";
import { AuthorizedMerchantsContext } from "src/contexts/AuthorizedMerchants.context";
import { getErrorMessage } from "src/helpers/errors";
import useAuthorizedMerchants from "src/helpers/hooks/app/useAuthorizedMerchants";
import useUser from "src/helpers/hooks/useUser";
import {
  monitoringEnabled,
  setLoggedInUser,
} from "src/helpers/integrations/monitoring";
import { useAnalytics } from "src/helpers/integrations/segment";
import useHotjar from "src/helpers/hooks/mix/useHotjar";
import { ScrollToTop } from "src/helpers/misc/ScrollToTop";
import { useMaintenance } from "../helpers/hooks/app/useMaintenance";
import {LEGACY_MERCHANTS} from "../constants/legacyMerchants";

export function AppShell() {
  const { addAlert } = useAlerts();
  const navigate = useNavigate();

  const analytics = useAnalytics();

  const { update } = useIntercom();

  const [authorizedMerchants, loadingMerchants] = useAuthorizedMerchants();

  const { user } = useUser();

  const fallbackMerchant =
    useAuthorizedMerchantsContext()?.authorizedMerchants?.[0];

  const { maintenanceInfo, isLoading: maintenanceLoading } = useMaintenance();

  const isLoading = loadingMerchants || (!user && !fallbackMerchant);

  useHotjar(
    authorizedMerchants,
    user?.is_superuser ?? false,
    user ? (user.full_name ? user.full_name : user.email) : undefined,
    user?.email
  );

  useEffect(() => {
    if (!loadingMerchants && authorizedMerchants?.length > 0 && user) {
      setLoggedInUser(
        user.email!,
        authorizedMerchants[0].id,
        user.is_superuser
      );
    }
  }, [authorizedMerchants, loadingMerchants, user]);

  useEffect(() => {
    if (!loadingMerchants && authorizedMerchants?.length > 0 && user) {
      analytics.identify(user.email, {
        name: user.full_name,
        email: user.email,
        company: { id: authorizedMerchants[0].id },
      });
    }
  }, [analytics, authorizedMerchants, loadingMerchants, user]);

  useEffect(() => {
    if (
      !loadingMerchants &&
      authorizedMerchants?.length > 0 &&
      user &&
      monitoringEnabled
    ) {
      update({
        name: user?.full_name,
        email: user?.email,
        userHash: user.intercom_hash,
        company: {
          companyId: authorizedMerchants[0].id,
          name: authorizedMerchants[0].display_name,
        },
      });
    }
  }, [authorizedMerchants, loadingMerchants, update, user]);

  useEffect(() => {
    if (!authorizedMerchants || loadingMerchants) {
      return;
    }

    if (authorizedMerchants?.length === 0) {
      const message = getErrorMessage("no-merchant");
      addAlert(message!);
      navigate(ROUTES.NO_MERCHANTS);
      return;
    }
  }, [loadingMerchants, authorizedMerchants, navigate, addAlert]);

  useEffect(() => {
    if (!maintenanceInfo || maintenanceLoading) {
      return;
    }

    if (
      maintenanceInfo.status === "MAINTENANCE" &&
      window.location.pathname !== ROUTES.MAINTENANCE
    ) {
      navigate(ROUTES.MAINTENANCE);
      return;
    }
  }, [addAlert, maintenanceInfo, maintenanceLoading, navigate]);

  useEffect(() => {
    if (
      !authorizedMerchants ||
      loadingMerchants ||
      !user ||
      user?.is_superuser
    ) {
      return;
    }

    for (const merchant of authorizedMerchants) {
      if (!merchant.has_completed_onboarding) {
        navigate(ROUTES.ONBOARDING.replace(":merchantId", merchant.id));
        return;
      }
    }
  }, [
    loadingMerchants,
    authorizedMerchants,
    navigate,
    user?.is_superuser,
    user,
  ]);

  return (
    <AuthorizedMerchantsContext.Provider
      value={{
        authorizedMerchants,
        loading: loadingMerchants,
        error: false,
      }}
    >
      <AppLoader
        isLoading={isLoading}
        mandatoryProcesses={[]}
        loadingComponent={<SplashScreen />}
        minimumLoadingTime={0}
      >
        <Suspense fallback={<SplashScreen />}>
          <ScrollToTop />
          <Outlet />
        </Suspense>
      </AppLoader>
    </AuthorizedMerchantsContext.Provider>
  );
}

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const AlarmRinging = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M16.8 15.101c-1.144-0.859-1.8-2.172-1.8-3.601v-3c0-2.513-1.694-4.638-4-5.292l-0-0.708c0-0.827-0.673-1.5-1.5-1.5s-1.5 0.673-1.5 1.5v0.708c-2.306 0.655-4 2.779-4 5.292v3c0 1.429-0.656 2.741-1.8 3.601-0.172 0.129-0.242 0.354-0.174 0.558s0.259 0.342 0.474 0.342h4.55c-0.033 0.164-0.051 0.331-0.051 0.5 0 1.378 1.122 2.5 2.5 2.5s2.5-1.122 2.5-2.5c0-0.168-0.017-0.336-0.050-0.5h4.55c0.215 0 0.406-0.138 0.474-0.342s-0.002-0.429-0.174-0.558zM9 2.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5v0.523c-0.165-0.015-0.331-0.023-0.5-0.023s-0.335 0.008-0.5 0.023v-0.523zM11 16.5c0 0.827-0.673 1.5-1.5 1.5s-1.5-0.673-1.5-1.5c0-0.171 0.030-0.34 0.086-0.5h2.828c0.056 0.16 0.086 0.329 0.086 0.5zM3.742 15c0.255-0.309 0.477-0.646 0.659-1.001 0.398-0.778 0.599-1.619 0.599-2.499v-3c0-2.481 2.019-4.5 4.5-4.5s4.5 2.019 4.5 4.5v3c0 0.88 0.202 1.721 0.599 2.499 0.182 0.356 0.404 0.692 0.659 1.001h-11.517z" fill={color}></path>
<path d="M17.248 7c-0.223 0-0.426-0.15-0.484-0.376-0.375-1.457-1.174-2.756-2.311-3.757-0.207-0.182-0.227-0.498-0.045-0.706s0.498-0.227 0.706-0.045c1.288 1.134 2.194 2.606 2.619 4.258 0.069 0.267-0.092 0.54-0.36 0.609-0.042 0.011-0.084 0.016-0.125 0.016z" fill={color}></path>
<path d="M18.501 4.636c-0.185 0-0.364-0.104-0.45-0.282-0.265-0.545-0.584-1.068-0.95-1.554-0.166-0.221-0.121-0.534 0.099-0.7s0.534-0.121 0.7 0.099c0.404 0.537 0.757 1.115 1.049 1.718 0.121 0.248 0.017 0.548-0.231 0.668-0.070 0.034-0.145 0.050-0.218 0.050z" fill={color}></path>
<path d="M1.752 7c-0.041 0-0.083-0.005-0.125-0.016-0.267-0.069-0.428-0.341-0.36-0.609 0.425-1.652 1.331-3.125 2.619-4.258 0.207-0.182 0.523-0.162 0.706 0.045s0.162 0.523-0.045 0.706c-1.137 1.001-1.936 2.3-2.311 3.757-0.058 0.226-0.261 0.376-0.484 0.376z" fill={color}></path>
<path d="M0.499 4.636c-0.073 0-0.148-0.016-0.218-0.050-0.248-0.121-0.352-0.42-0.231-0.668 0.293-0.603 0.646-1.181 1.049-1.718 0.166-0.221 0.479-0.265 0.7-0.099s0.265 0.479 0.099 0.7c-0.365 0.486-0.685 1.009-0.95 1.554-0.086 0.178-0.265 0.282-0.45 0.282z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(AlarmRinging); 
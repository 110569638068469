import { css } from "@emotion/react";
import { useState } from "react";
import styled from "@emotion/styled";
import { Button } from "react-bootstrap";
import { ChevronLeft } from "../../../linearicons";
import { CurvyCorner } from "../../CurvyCorner/CurvyCorner";
import { useImageResize } from "../../ImageResizeProvider/ImageResizeProvider";

type MetricProduct = {
  title: string;
  mainProductId: string;
  imageUrl?: string | null;
  metricValue: number;
};

export enum MetricType {
  Bestsellers = "bestsellers",
  MostViewed = "most-viewed",
  Trending = "trending",
}

type Metric = {
  type: MetricType;
  title: string;
  timeRange: string;
  products: MetricProduct[];
};

const ProductCardWrapper = styled.div`
  border-radius: 8px;
  position: absolute;
  width: 100%;
  height: 240px;
`;

const MetricTitle = styled.h3`
  padding: 16px 0;
  color: #0f0f0f;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin: 0;
`;

const MetricDescription = styled.h5`
  color: #3018c1;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  margin: 0;
`;

function ProductCard(props: { product: MetricProduct; rank?: number }) {
  const { getImageUrl } = useImageResize();
  return (
    <div
      css={css`
        background-size: cover;
        background-image: url(${getImageUrl(props.product.imageUrl ?? "")});
        width: 100%;
        height: 100%;
        border-radius: 8px;
        position: relative;
        border: 1px solid #3018c1;
      `}
    >
      {props.rank && (
        <CurvyCorner
          corner="top-right"
          borderRadius="8px"
          backgroundColor={"#3018c1"}
          contentContainerStyles={{
            borderRadius: "0 6px 0 8px",
          }}
        >
          <div
            css={css`
              color: #fff;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              padding: 8px;
            `}
          >
            # {props.rank}
          </div>
        </CurvyCorner>
      )}
    </div>
  );
}

function ProductSpotlightCard(props: {
  title: string;
  metric: Metric;
  onClick?: () => void;
}) {
  const { getImageUrl } = useImageResize();

  return (
    <div
      onClick={props.onClick}
      css={css`
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1 0 0;
        transition: flex 0.6s cubic-bezier(0.03, 1.14, 0.77, 1.06);
        min-width: 100px;

        .spotlight-card {
          transition: transform 0.6s ease-in-out;
        }
        &:hover {
          flex: 2 0 0;

          .spotlight-card:nth-of-type(3) {
            transform: translateX(-54px) scale(1.04);
          }
          .spotlight-card:nth-of-type(2) {
            transform: translateX(0px) rotate(9deg);
          }
          .spotlight-card:nth-of-type(1) {
            transform: translateX(35px) rotate(16deg);
          }
        }
      `}
    >
      <MetricTitle>{props.title}</MetricTitle>
      <div
        css={css`
          border-radius: 16px;
          background: #ffffff;
          margin: 8px 8px 0 8px;
          padding: 24px;
          width: 100%;
          height: 350px;
          display: flex;
          align-items: center;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            width: 100%;
            max-width: 240px;
            position: relative;
            height: 100%;
            margin: 16px;
          `}
        >
          {props.metric.products[2] && (
            <ProductCardWrapper
              className="spotlight-card"
              css={css`
                transform: rotate(8deg);
                opacity: 0.5;
                box-shadow: 0 2px 4px 0 rgba(15, 15, 15, 0.1);
              `}
            >
              <ProductCard product={props.metric.products[2]} />
            </ProductCardWrapper>
          )}

          {props.metric.products[1] && (
            <ProductCardWrapper
              className="spotlight-card"
              css={css`
                transform: rotate(4deg);
                opacity: 0.75;
                box-shadow: 0 4px 12px 0 rgba(15, 15, 15, 0.16);
                background-size: cover;
                background-image: url(${getImageUrl(
                  props.metric.products[1].imageUrl ?? ""
                )});
              `}
            >
              <ProductCard product={props.metric.products[1]} />
            </ProductCardWrapper>
          )}

          {props.metric.products[0] && (
            <ProductCardWrapper
              className="spotlight-card"
              css={css`
                box-shadow: 0 8px 32px 0 rgba(15, 15, 15, 0.16);
                background-size: cover;
                background-image: url(${getImageUrl(
                  props.metric.products[0].imageUrl ?? ""
                )});
              `}
            >
              <ProductCard product={props.metric.products[0]} rank={1} />
            </ProductCardWrapper>
          )}
        </div>
        {props.metric.products[0] && (
          <MetricDescription
            css={css`
              margin-top: 12px;
            `}
          >
            {getMetricProductDescription(
              props.metric.type,
              props.metric.products[0]
            )}
          </MetricDescription>
        )}
      </div>
    </div>
  );
}

function getMetricProductDescription(
  metricType: MetricType,
  metricProduct: MetricProduct
) {
  switch (metricType) {
    case MetricType.Bestsellers:
      return (
        <>
          Sold <strong>{metricProduct.metricValue}</strong> times
        </>
      );
    case MetricType.MostViewed:
      return (
        <>
          Viewed <strong>{metricProduct.metricValue}</strong> times
        </>
      );
    case MetricType.Trending:
      return (
        <>
          Moved <strong>{metricProduct.metricValue}</strong> positions
        </>
      );
  }
}

export default function CatalogProductSpotlight({
  metrics,
}: {
  metrics: Metric[];
}) {
  const [selectedMetricType, setSelectedMetricType] =
    useState<MetricType | null>(null);

  const selectedMetric = metrics.find((m) => m.type === selectedMetricType);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 24px;
        gap: 16px;
        padding: 16px;
        width: 100%;
        background: linear-gradient(94deg, #e4e6fa 0%, #f1e7ff 100%);
      `}
    >
      {selectedMetricType === null ? (
        metrics
          .filter((m) => m.products.length > 0)
          .map((metric) => (
            <ProductSpotlightCard
              title={metric.title}
              metric={metric}
              onClick={() => setSelectedMetricType(metric.type)}
            />
          ))
      ) : (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1 0 0;
            overflow: hidden;
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            `}
          >
            <div
              css={css`
                flex: 1;
                text-align: left;
              `}
            >
              <Button
                variant="link"
                css={css`
                  padding: 0;
                `}
                onClick={() => setSelectedMetricType(null)}
              >
                <ChevronLeft
                  size={16}
                  css={css`
                    margin-right: 4px;
                  `}
                />
                Back
              </Button>
            </div>
            <MetricTitle
              css={css`
                flex: 1;
                text-align: center;
              `}
            >
              {selectedMetric?.title}
            </MetricTitle>
            <div
              css={css`
                flex: 1;
                text-align: right;
                color: #3018c1;
              `}
            >
              <span
                css={css`
                  padding: 8px;
                  margin-right: 8px;
                `}
              >
                Top {selectedMetric?.products.length}
              </span>
              <span
                css={css`
                  padding: 8px;
                `}
              >
                {selectedMetric?.timeRange}
              </span>
            </div>
          </div>
          <div
            css={css`
              border-radius: 16px;
              background: #ffffff;
              margin: 8px 8px 0 8px;
              padding: 24px;
              width: 100%;
              height: 350px;
              display: flex;
              gap: 16px;
              overflow: scroll;

              animation: 0.2s ease-in-out 0s 1 opacity;
              @keyframes opacity {
                0% {
                  opacity: 0;
                }
                100% {
                  opacity: 1;
                }
              }
            `}
            onWheel={(e) => {
              e.stopPropagation();
            }}
          >
            {selectedMetric?.products.map((product, i) => (
              <div
                css={css`
                  display: flex;
                  height: 100%;
                  flex-direction: column;
                  align-items: center;
                  flex: 1 0 0;
                  min-width: 180px;
                `}
              >
                <div
                  key={i}
                  css={css`
                    border-radius: 8px;
                    width: 100%;
                    height: 100%;

                    animation: 0.6s ease-in-out 0s 1 slideInFromLeft;
                    @keyframes slideInFromLeft {
                      0% {
                        transform: translateX(-200%);
                      }
                      60% {
                        transform: translateX(5%);
                      }
                      100% {
                        transform: translateX(0);
                      }
                    }
                  `}
                >
                  <ProductCard product={product} rank={i + 1} />
                </div>
                <h3
                  css={css`
                    padding: 16px 0 4px 0;
                    margin: 0;
                    width: 100%;
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  `}
                >
                  {product.title}
                </h3>
                <MetricDescription>
                  {getMetricProductDescription(selectedMetricType, product)}
                </MetricDescription>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import { memo, useEffect, useState } from "react";
import Card from "../../storybook/Card/Card";
import { useMutation, useQuery } from "@tanstack/react-query";
import { QueryId, getQueryKey } from "../../../queries/queries";
import { getAuthorizedApi, getData } from "../../../api/authorizedApi";
import useAuthentication from "../../../helpers/hooks/app/useAuthentication";
import CentraMarketsConfigForm from "../../storybook/Integrations/Centra/CentraMarketsConfigForm";
import LoadingBadge from "../../storybook/LoadingBadge/LoadingBadge";

function CentraIntegrationEditor({
  merchant,
  onClose,
}: {
  merchant: string;
  onClose: () => void;
}) {
  const [getAccessToken] = useAuthentication();
  const [viewedStoreId, setViewedStoreId] = useState<string | null>(null);
  const [newChosenMarketIds, onSetNewChosenMarketIds] = useState<string[]>([]);

  const {
    data: centraConfig,
    isLoading,
    error,
    isSuccess,
  } = useQuery({
    queryKey: getQueryKey(QueryId.GetCentraConfiguredMarkets, merchant),
    queryFn: async () => {
      const token = await getAccessToken();
      const api = getAuthorizedApi(token);
      if (!merchant || !api) return;

      const response = await api.GET(
        "/api/v0/integrations/centra/config/{merchant_id}",
        {
          params: {
            path: {
              merchant_id: merchant,
            },
          },
        }
      );

      const data = getData(response);

      return data;
    },
    enabled: !!merchant,
  });

  useEffect(() => {
    if (isSuccess && centraConfig) {
      onSetNewChosenMarketIds(centraConfig.centra_markets);
      setViewedStoreId(centraConfig.store_id);
    }
  }, [centraConfig, isSuccess]);

  const {
    data: availableStores,
    isLoading: centraStoresIsLoading,
    error: centraStoreFetchingError,
  } = useQuery({
    queryKey: getQueryKey(QueryId.GetCentraStores, merchant),
    queryFn: async () => {
      const api = getAuthorizedApi(await getAccessToken());

      if (!merchant || !api || !centraConfig) return;

      const response = await api.POST("/api/v0/integrations/centra/stores", {
        body: {
          api_key: centraConfig.api_key,
          api_url: centraConfig.api_url,
        },
      });

      const data = getData(response);

      return data;
    },
    enabled: !!merchant && !!centraConfig,
  });

  const chosenStoreId = (availableStores || []).find((s) =>
    s.markets.some((m) => newChosenMarketIds.includes(m.id))
  )?.id;

  const {
    mutate,
    isPending: loadingUpdateConfig,
    error: updateError,
  } = useMutation({
    mutationFn: async () => {
      const api = getAuthorizedApi(await getAccessToken());
      if (!merchant || !api || !centraConfig || !chosenStoreId) return;

      const response = await api.PUT(
        "/api/v0/integrations/centra/config/{merchant_id}",
        {
          body: {
            ...centraConfig,
            centra_markets: newChosenMarketIds,
          },
          params: {
            path: {
              merchant_id: merchant,
            },
          },
        }
      );

      const data = getData(response);

      return data;
    },
    onSuccess: () => {
      onClose();
    },
  });

  return (
    <Container>
      <Row className="justify-content-md-center align-items-center min-vh-100">
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>Centra Onboarding</Card.Title>
              <Card.Text>
                These are the Centra markets you have configured to be imported
                into Depict.
              </Card.Text>
              <CentraMarketsConfigForm
                error={
                  (error as unknown as string) ||
                  (centraStoreFetchingError as unknown as string)
                }
                isLoading={isLoading || centraStoresIsLoading}
                availableStores={availableStores || []}
                chosenMarketIds={newChosenMarketIds}
                viewedStoreId={viewedStoreId}
                onSetChosenMarketIds={(marketIds) =>
                  onSetNewChosenMarketIds(marketIds)
                }
                setViewedStoreId={setViewedStoreId}
              />
              {loadingUpdateConfig && (
                <LoadingBadge label={"Updating Centra configuration..."} />
              )}
              {(updateError as Error | null) && (
                <Alert variant="danger">
                  <Alert.Heading>Failed to update Centra config</Alert.Heading>
                  <p>{(updateError as Error).message}</p>
                  <p>Did you include an unsupported market?</p>
                </Alert>
              )}
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col>
                  <Button
                    variant="outline-secondary"
                    className="w-100"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant="primary"
                    className="w-100"
                    disabled={
                      isLoading ||
                      error !== null ||
                      newChosenMarketIds.length === 0 ||
                      !chosenStoreId
                    }
                    onClick={() => {
                      if (chosenStoreId) {
                        mutate();
                      }
                    }}
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default memo(CentraIntegrationEditor);

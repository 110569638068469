import { ActionType } from "./Category.provider";
import { useCallback, useState } from "react";
import { Action } from "./Category.reducer.types";

export interface RevertableChangesProps {
  dispatch: React.Dispatch<Action>;
  unselectAll: () => void;
}

export const useRevertableChanges = (props: RevertableChangesProps) => {
  const { dispatch, unselectAll } = props

  const [showRevertConfirmation, setShowRevertConfirmation] =
    useState<boolean>(false);

  const onRevert = useCallback(() => {
    setShowRevertConfirmation(true);
  }, []);

  const onRevertConfirmation = useCallback(() => {
    setShowRevertConfirmation(false);

    dispatch({
      type: ActionType.REVERT_CHANGES,
      payload: {},
    });

    unselectAll();
  }, [dispatch, unselectAll]);

  const onCloseRevertConfirmation = useCallback(() => {
    setShowRevertConfirmation(false);
  }, []);

  return {
    showRevertConfirmation,
    onRevert,
    onRevertConfirmation,
    onCloseRevertConfirmation,
  };
};

export default useRevertableChanges;

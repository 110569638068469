import { formatNumber } from "src/helpers";
import { Comparison } from "src/types/components";

export const getComparisonStr = (comparison: Comparison) => {
  return (
    (comparison.diff === 0 ? "" : "") +
    (comparison.diff > 0 ? "+ " : "") +
    (comparison.diff < 0 ? "- " : "") +
    (comparison.type === "PERCENTAGE"
      ? formatNumber({
          number: Math.abs(comparison.diff) * 100,
          suffix: "%",
          digitsAfterComma: 1,
        })
      : formatNumber({
          number: Math.abs(comparison.diff),
          digitsAfterComma: 1,
        }))
  );
};

export const MetricComparison = ({
  comparison,
}: {
  comparison?: Comparison;
}) => {
  if (!comparison) {
    return <div className="depict--MetricComparison">&nbsp;</div>;
  }
  return (
    <div
      className={`depict--MetricComparison ${
        comparison.diff < 0 ? "negative" : ""
      }`}
    >
      {getComparisonStr(comparison)}
    </div>
  );
};

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const TrafficLights = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M10 7c-1.103 0-2-0.897-2-2s0.897-2 2-2c1.103 0 2 0.897 2 2s-0.897 2-2 2zM10 4c-0.551 0-1 0.449-1 1s0.449 1 1 1c0.551 0 1-0.449 1-1s-0.449-1-1-1z" fill={color}></path>
<path d="M10 12c-1.103 0-2-0.897-2-2s0.897-2 2-2c1.103 0 2 0.897 2 2s-0.897 2-2 2zM10 9c-0.551 0-1 0.449-1 1s0.449 1 1 1c0.551 0 1-0.449 1-1s-0.449-1-1-1z" fill={color}></path>
<path d="M10 17c-1.103 0-2-0.897-2-2s0.897-2 2-2c1.103 0 2 0.897 2 2s-0.897 2-2 2zM10 14c-0.551 0-1 0.449-1 1s0.449 1 1 1c0.551 0 1-0.449 1-1s-0.449-1-1-1z" fill={color}></path>
<path d="M16.224 11.572c0.81-0.405 1.776-1.267 1.776-3.072 0-0.276-0.224-0.5-0.5-0.5h-2.5v-1.046c0.327-0.051 0.775-0.157 1.224-0.382 0.81-0.405 1.776-1.267 1.776-3.072 0-0.276-0.224-0.5-0.5-0.5h-2.55c-0.232-1.14-1.242-2-2.45-2h-5c-1.207 0-2.217 0.86-2.45 2h-2.55c-0.276 0-0.5 0.224-0.5 0.5 0 1.805 0.966 2.667 1.776 3.072 0.449 0.224 0.897 0.331 1.224 0.382v1.046h-2.5c-0.276 0-0.5 0.224-0.5 0.5 0 1.805 0.966 2.667 1.776 3.072 0.449 0.224 0.897 0.331 1.224 0.382v1.046h-2.5c-0.276 0-0.5 0.224-0.5 0.5 0 1.805 0.966 2.667 1.776 3.072 0.469 0.235 0.937 0.34 1.267 0.388 0.217 1.159 1.236 2.039 2.457 2.039h5c1.221 0 2.24-0.88 2.457-2.039 0.33-0.048 0.798-0.154 1.267-0.388 0.81-0.405 1.776-1.267 1.776-3.072 0-0.276-0.224-0.5-0.5-0.5h-2.5v-1.046c0.327-0.051 0.775-0.157 1.224-0.382zM4.196 5.664c-0.658-0.339-1.047-0.897-1.161-1.664h1.965v1.939c-0.233-0.047-0.521-0.129-0.804-0.275zM4.196 10.664c-0.658-0.339-1.047-0.897-1.161-1.664h1.965v1.939c-0.233-0.047-0.521-0.129-0.804-0.275zM3.035 14h1.965v1.939c-0.233-0.047-0.521-0.129-0.804-0.275-0.658-0.339-1.047-0.897-1.161-1.664zM14 16.5c0 0.827-0.673 1.5-1.5 1.5h-5c-0.827 0-1.5-0.673-1.5-1.5v-13c0-0.827 0.673-1.5 1.5-1.5h5c0.827 0 1.5 0.673 1.5 1.5v13zM16.965 9c-0.114 0.767-0.503 1.325-1.161 1.664-0.283 0.146-0.571 0.228-0.804 0.275v-1.939h1.965zM15.804 5.664c-0.283 0.146-0.571 0.228-0.804 0.275v-1.939h1.965c-0.114 0.767-0.503 1.325-1.161 1.664zM16.965 14c-0.114 0.767-0.503 1.325-1.161 1.664-0.283 0.146-0.571 0.228-0.804 0.275v-1.939h1.965z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(TrafficLights); 
// BoostBuryProvider.tsx

import { createContext, useCallback, useMemo } from "react";
import { BoostRuleType } from "src/helpers/boostbury/availableOptions";
import { useMutation } from "@tanstack/react-query";
import { useImageResize } from "../storybook/ImageResizeProvider/ImageResizeProvider";

export interface BoostBuryContextProps {
  previewImages: string[];
  previewImagesLoading: boolean;
  setPreviewOption: (ruleType: BoostRuleType, collectionId?: string) => void;
}

export const BoostBuryPreviewContext = createContext<BoostBuryContextProps>({
  previewImages: [],
  previewImagesLoading: false,
  setPreviewOption: () => {},
});

interface UseBoostBuryContextProps {
  fetchPreviewImages: (
    ruleType: BoostRuleType,
    collectionId?: string
  ) => Promise<string[]>;
  children: React.ReactNode;
}

export const BoostBuryPreviewProvider = ({
  fetchPreviewImages,
  children,
}: UseBoostBuryContextProps) => {
  const { getImageUrl } = useImageResize();
  const previewOptionMutation = useMutation({
    mutationFn: async (args: {
      ruleType: BoostRuleType;
      collectionId?: string;
    }) => {
      return await fetchPreviewImages(args.ruleType, args.collectionId);
    },
  });

  const setPreviewOption = useCallback(
    (ruleType: BoostRuleType, collectionId?: string) => {
      previewOptionMutation.mutate({ ruleType, collectionId });
    },
    [previewOptionMutation]
  );

  const state = useMemo(
    () => ({
      previewImages:
        previewOptionMutation.data?.map((i) => getImageUrl(i)) ?? [],
      previewImagesLoading: previewOptionMutation.isPending,
      setPreviewOption: setPreviewOption,
    }),
    [
      getImageUrl,
      previewOptionMutation.data,
      previewOptionMutation.isPending,
      setPreviewOption,
    ]
  );

  return (
    <BoostBuryPreviewContext.Provider value={state}>
      {children}
    </BoostBuryPreviewContext.Provider>
  );
};

export default BoostBuryPreviewProvider;

import { LottieOptions, useLottie } from "lottie-react";
import flameAnimation from "./flameAnimation.json";

const style = {
  height: 240,
};

const BoostSaveAnimation = ({
  onFinish,
  loop = false,
}: {
  onFinish: () => void;
  loop?: boolean;
}) => {
  const options: LottieOptions = {
    animationData: flameAnimation,
    loop: loop,
    autoplay: true,
    onComplete: onFinish,
  };

  const { View } = useLottie(options, style);

  return View;
};

export default BoostSaveAnimation;

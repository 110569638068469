import { css } from "@emotion/react";
import { IconContainer } from "./IconContainer";
import styled from "@emotion/styled";

export const HoverableContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface HoverableIconProps {
  iconWhenHovered: React.ReactNode;
  iconWhenNotHovered: React.ReactNode;
  onClick?: () => void;
}
export function HoverableIcon(props: HoverableIconProps) {
  return (
    <HoverableContainer>
      <IconContainer onClick={props.onClick}>
        <div
          css={css`
            display: none;
            ${HoverableContainer}:hover & {
              display: flex;
            }
          `}
        >
          {props.iconWhenHovered}
        </div>
        <div
          css={css`
            display: flex;
            ${HoverableContainer}:hover & {
              display: none;
            }
          `}
        >
          {props.iconWhenNotHovered}
        </div>
      </IconContainer>
    </HoverableContainer>
  );
}

import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "src/components/storybook/designSystemVariables";
import { useElementSize } from "usehooks-ts";
import left from "./left.svg";
import right from "./right.svg";

const iconSize = 8;
const borderWeight = 1;

interface IconContainerProps {
  selected: boolean;
}
const IconContainer = styled.div<IconContainerProps>`
  width: ${iconSize}px;
  height: ${iconSize}px;
  line-height: ${iconSize}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ContentProps {
  selected: boolean;
  borderToIntegrateWithStyle: BorderToIntegrateWithStyle;
}
const Content = styled.div<ContentProps>`
  padding: 4px 4px 0px 4px;
  border-radius: 8px 8px 0px 0px;
  border-top: ${({ selected, borderToIntegrateWithStyle }) =>
    `${borderToIntegrateWithStyle.borderWeight}px solid ${
      selected ? borderToIntegrateWithStyle.borderColor : "transparent"
    };`};
  border-right: ${({ selected, borderToIntegrateWithStyle }) =>
    `${borderToIntegrateWithStyle.borderWeight}px solid ${
      selected ? borderToIntegrateWithStyle.borderColor : "transparent"
    };`};
  border-left: ${({ selected, borderToIntegrateWithStyle }) =>
    `${borderToIntegrateWithStyle.borderWeight}px solid ${
      selected ? borderToIntegrateWithStyle.borderColor : "transparent"
    };`};
  border-bottom: ${({ selected, borderToIntegrateWithStyle }) =>
    selected
      ? `${borderToIntegrateWithStyle.borderWeight}px solid transparent`
      : ""};
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export interface BorderToIntegrateWithStyle {
  borderWeight: number;
  borderColor: string;
}

interface MenuItemProps {
  children: React.ReactNode;
  menuItemCss?: SerializedStyles;
  selected: boolean;
  onClick?: () => void;
  borderToIntegrateWithStyle: BorderToIntegrateWithStyle;
}
export const MenuItem = ({
  children,
  menuItemCss,
  selected,
  onClick,
  borderToIntegrateWithStyle,
}: MenuItemProps) => {
  const [contentRef, { width }] = useElementSize();

  return (
    <div
      css={[
        css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;
          align-self: stretch;
        `,
        menuItemCss,
      ]}
      onClick={onClick}
    >
      <Content
        selected={selected}
        ref={contentRef}
        borderToIntegrateWithStyle={borderToIntegrateWithStyle}
      >
        {children}
      </Content>
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          border-bottom: ${selected
            ? ""
            : `${borderToIntegrateWithStyle.borderWeight}px solid ${theme.border.subtle.disabled}`};
        `}
      >
        <IconContainer selected={selected}>
          <img
            style={{
              opacity: selected ? 1 : 0,
            }}
            src={left}
            alt="left"
          />
        </IconContainer>
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            width: ${width * borderToIntegrateWithStyle.borderWeight - 2}px;
            height: ${iconSize}px;
          `}
        />
        <IconContainer selected={selected}>
          <img
            src={right}
            alt="right"
            style={{
              opacity: selected ? 1 : 0,
            }}
          />
        </IconContainer>
      </div>
    </div>
  );
};

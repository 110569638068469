import { css } from "@emotion/react";
import { FC, PropsWithChildren } from "react";

interface EmptyProductCardProps extends PropsWithChildren {
  icon: JSX.Element;
  color: "purple" | "gray";
}

export const EmptyProductCard: FC<EmptyProductCardProps> = ({
  icon,
  color,
  children,
}) => (
  <div
    css={css`
      display: flex;
      width: 260px;
      min-height: 284px;
      padding: 8px;
      flex-direction: column;
      align-items: center;
      gap: 12px;

      border-radius: 16px;
      border: 1px solid #e4e4e5;
      background: #fff;
      box-shadow: ${color === "purple"
        ? "0px 8px 32px 0px rgba(48, 24, 193, 0.16)"
        : "0px 8px 32px 0px rgba(15, 15, 15, 0.16)"};
    `}
  >
    <div
      css={css`
        display: flex;
        padding: 14px 1px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        align-self: stretch;

        border-radius: 8px;
        background: ${color === "purple"
          ? "linear-gradient(94deg, #e4e6fa 0%, #f1e7ff 100%)"
          : "#f3f3f5"};
      `}
    >
      {icon}
    </div>
    {children}
  </div>
);

import React from "react";
import { Button } from "../Button/Button";
import { FunnelSimple } from "@phosphor-icons/react";

interface OpenFilterButtonProps {
  onClick: () => void;
  active: boolean;
}

export function OpenFilterButton(props: OpenFilterButtonProps) {
  return (
    <Button
      onClick={props.onClick}
      style={{
        background: props.active ? "#ececec" : "#CBCBCC",
        border: "none",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        padding: "0px",
        marginRight: "4px",
      }}
      icon={<FunnelSimple fill={"black"} />}
      text={""}
    ></Button>
  );
}

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Fire = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M14.127 0.606c-0.085-0.178-0.16-0.313-0.222-0.399-0.154-0.214-0.449-0.271-0.672-0.131s-0.299 0.431-0.172 0.663c0.059 0.108 0.117 0.215 0.174 0.32 0.442 0.939 1.201 3.171 1.243 5.551-0.461-1-1.17-1.869-1.815-2.662-0.982-1.205-1.829-2.245-1.668-3.378 0.021-0.143-0.022-0.289-0.117-0.398s-0.233-0.172-0.378-0.172c-0 0-0 0-0 0-1.018 0-2.099 0.797-2.827 1.466-0.928 0.852-1.815 1.974-2.499 3.159-0.099 0.172-0.193 0.343-0.281 0.514 0.232-1.615 0.685-3.294 1.073-4.459 0.058-0.175 0.038-0.334-0.062-0.472-0.094-0.13-0.245-0.208-0.406-0.208-0.185 0-0.353 0.102-0.44 0.263-3.008 5.514-4.060 7.795-4.060 11.737 0 2.149 0.807 4.139 2.271 5.604 0.755 0.755 1.658 1.345 2.685 1.752 1.078 0.428 2.27 0.645 3.544 0.645s2.466-0.217 3.544-0.645c1.026-0.407 1.93-0.997 2.685-1.752 1.465-1.465 2.271-3.455 2.271-5.604 0-3.902-1.062-6.226-3.873-11.394zM6.041 5.125c0.625-1.084 1.467-2.149 2.309-2.923 0.593-0.545 1.162-0.924 1.634-1.097 0.101 1.264 0.981 2.343 1.904 3.475 1.636 2.008 3.182 3.905 1.073 7.111-0.451 0.558-1.001 1.039-1.649 1.442 0.631-1.15 0.844-2.497 0.561-3.83-0.212-1.001-0.694-1.92-1.392-2.657-0.747-0.787-1.735-1.352-2.858-1.633-0.236-0.059-0.48 0.060-0.579 0.283s-0.022 0.483 0.18 0.618c0.648 0.432 0.965 1.911 0.64 2.986-0.223 0.736-0.664 1.11-1.312 1.11-0.115 0-0.238-0.012-0.367-0.035-0.44-0.081-0.769-0.313-1.004-0.71-0.031-0.053-0.060-0.108-0.088-0.165h0.005c-0.36-0.848-0.016-2.314 0.944-3.977zM9.5 19c-4.924 0-7.5-3.521-7.5-7 0-2.746 0.523-4.597 1.99-7.566-0.424 2.458-0.313 4.255 0.33 5.342 0.386 0.652 0.952 1.051 1.684 1.184 0.187 0.034 0.371 0.052 0.546 0.052 1.092-0 1.919-0.664 2.269-1.82 0.209-0.691 0.229-1.526 0.053-2.291-0.027-0.118-0.058-0.232-0.093-0.342 1.088 0.652 1.847 1.69 2.114 2.953 0.366 1.729-0.285 3.489-1.701 4.594-0.191 0.149-0.248 0.413-0.134 0.628s0.363 0.317 0.594 0.243c2.478-0.792 4.193-2.249 5.098-4.332 0.667-1.537 0.879-3.366 0.63-5.443 1.168 2.501 1.62 4.286 1.62 6.799 0 3.479-2.576 7-7.5 7z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Fire); 
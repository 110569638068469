import { useCallback } from "react";
import mergeClassNames from "merge-class-names";
import {
  ListItem,
  MainProductListItem,
  ProductListItem,
} from "src/types/components";
import Item from "src/components/lists/Item";
import ItemContent from "src/components/lists/ItemContent";

export default function usePanelGetItem(
  deleteItem: (target: string, item: ListItem) => void,
  panelId: string
) {
  const getItemContent = useCallback(
    (item: ListItem) => {
      return (
        <ItemContent
          item={item}
          itemRightIconOnClickCB={(item: ListItem) => {
            deleteItem(panelId as string, item);
          }}
          imageLazyLoad={true}
        />
      );
    },
    [deleteItem, panelId]
  );

  const getItem = useCallback(
    (item: ListItem) => {
      return (
        <Item
          className={mergeClassNames([
            "depict--ItemsList__item",
            "depict--ItemsList__item__type__" + item.type,
            ["product", "main_product"].includes(item.type) &&
              (item as ProductListItem | MainProductListItem).inStock ===
                false &&
              "depict--ItemsList__item__outOfStock",
            ["product", "main_product"].includes(item.type) &&
              (item as ProductListItem | MainProductListItem).deleted ===
                true &&
              "depict--ItemsList__item__deleted",
          ])}
          item={item}
        >
          {getItemContent(item)}
        </Item>
      );
    },
    [getItemContent]
  );

  return [getItem];
}

import { useMemo } from "react";
import {
  Archery,
  QuestionCircle,
  ThumbsUp,
  ThumbsDown,
} from "src/components/linearicons";

import useOpenHelpToSection from "src/helpers/hooks/mix/useOpenHelpToSection";

export default function usePanelsFeatures(toggleHelpModalCB: () => void) {
  const helpSection = useOpenHelpToSection(toggleHelpModalCB);

  const fromIcon = useMemo(() => {
    return (
      <div className="depict--setPanel__backgroundIcon">
        <Archery size={80} />
      </div>
    );
  }, []);

  const includeIcon = useMemo(() => {
    return (
      <div className="depict--setPanel__backgroundIcon">
        <ThumbsUp size={80} />
      </div>
    );
  }, []);

  const excludeIcon = useMemo(() => {
    return (
      <div className="depict--setPanel__backgroundIcon">
        <ThumbsDown size={80} />
      </div>
    );
  }, []);

  const noListComponent = useMemo(() => {
    return (
      <p
        className="text-center text-depict-brown"
        style={{
          maxWidth: "230px",
          fontSize: ".688rem",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        Add products or categories to affect product dependant surfaces.
        <br />
        <button
          onClick={() => {
            helpSection("for-these-products-categories");
          }}
          className="link-depict-brown text-decoration-underline btn btn-link"
          style={{ fontSize: ".688rem" }}
        >
          Learn more
        </button>
      </p>
    );
  }, [helpSection]);

  const fromTitle = useMemo(
    () => (
      <>
        <span>For these categories / products</span>
        <span
          className="depict--PanelTitle__icon"
          onClick={() => {
            helpSection("for-these-products-categories");
          }}
        >
          <QuestionCircle size={12} />
        </span>
      </>
    ),
    [helpSection]
  );

  const includeTitle = useMemo(
    () => (
      <>
        <span>Always show</span>
        <span
          className="depict--PanelTitle__icon"
          onClick={() => {
            helpSection("always-show");
          }}
        >
          <QuestionCircle size={12} />
        </span>
      </>
    ),
    [helpSection]
  );
  const excludeTitle = useMemo(
    () => (
      <>
        <span>Never show</span>
        <span
          className="depict--PanelTitle__icon"
          onClick={() => {
            helpSection("never-show");
          }}
        >
          <QuestionCircle size={12} />
        </span>
      </>
    ),
    [helpSection]
  );

  return [
    fromIcon,
    includeIcon,
    excludeIcon,
    noListComponent,
    fromTitle,
    includeTitle,
    excludeTitle,
  ];
}

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Hearing = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M13 18c-0.943 0-1.727-0.371-2.268-1.074-0.479-0.622-0.732-1.462-0.732-2.426 0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5c0 1.209 0.525 2.5 2 2.5 0.436 0 0.805-0.327 1.037-0.602 0.395-0.467 0.735-1.171 0.982-2.036 0.473-1.654 1.153-2.661 1.753-3.55 0.686-1.015 1.228-1.818 1.228-3.313 0-2.607-1.893-4.5-4.5-4.5-2.481 0-4.5 2.019-4.5 4.5 0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5c0-3.033 2.467-5.5 5.5-5.5 1.493 0 2.872 0.538 3.883 1.516 1.043 1.008 1.617 2.423 1.617 3.984 0 1.801-0.679 2.807-1.399 3.872-0.586 0.867-1.192 1.764-1.62 3.265-0.29 1.014-0.687 1.824-1.18 2.407-0.529 0.625-1.152 0.956-1.801 0.956z" fill={color}></path>
<path d="M8.066 17.189c-0.052 0-0.106-0.008-0.158-0.026-0.262-0.087-0.404-0.37-0.316-0.632 0.271-0.813 0.408-1.665 0.408-2.531 0-2.346-1.025-4.566-2.812-6.090-0.21-0.179-0.235-0.495-0.056-0.705s0.495-0.235 0.705-0.056c2.010 1.714 3.163 4.211 3.163 6.851 0 0.973-0.155 1.931-0.46 2.847-0.070 0.21-0.265 0.342-0.474 0.342z" fill={color}></path>
<path d="M5.219 16.24c-0.052 0-0.106-0.008-0.158-0.026-0.262-0.087-0.404-0.37-0.316-0.632 0.169-0.508 0.255-1.040 0.255-1.582 0-1.467-0.641-2.854-1.758-3.806-0.21-0.179-0.235-0.495-0.056-0.705s0.495-0.235 0.705-0.056c1.34 1.143 2.109 2.808 2.109 4.567 0 0.649-0.103 1.287-0.307 1.898-0.070 0.21-0.265 0.342-0.474 0.342z" fill={color}></path>
<path d="M2.372 15.291c-0.052 0-0.106-0.008-0.158-0.026-0.262-0.087-0.404-0.37-0.316-0.632 0.068-0.203 0.102-0.416 0.102-0.633 0-0.587-0.256-1.142-0.703-1.523-0.21-0.179-0.235-0.495-0.056-0.705s0.495-0.235 0.705-0.056c0.67 0.571 1.054 1.404 1.054 2.283 0 0.324-0.052 0.644-0.153 0.949-0.070 0.21-0.265 0.342-0.474 0.342z" fill={color}></path>
<path d="M13.5 4c-1.93 0-3.5 1.57-3.5 3.5 0 0.827 0.673 1.5 1.5 1.5 0.498 0 0.75-0.365 0.916-0.606 0.189-0.273 0.285-0.394 0.584-0.394 0.928 0 1 1.148 1 1.5 0 0.827-0.673 1.5-1.5 1.5-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5c1.378 0 2.5-1.122 2.5-2.5 0-0.672-0.16-1.259-0.464-1.698-0.357-0.517-0.903-0.802-1.536-0.802-0.836 0-1.193 0.517-1.406 0.826-0.038 0.056-0.093 0.135-0.129 0.173-0.26-0.018-0.465-0.235-0.465-0.499 0-1.378 1.122-2.5 2.5-2.5s2.5 1.122 2.5 2.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5c0-1.93-1.57-3.5-3.5-3.5z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Hearing); 
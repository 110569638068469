import { Button } from "react-bootstrap";
import Logo from "src/assets/img/logo-symbol.svg";
import { useState, memo } from "react";
import OnboardingProductOverview, {
  ProductOverviewStep,
} from "./OnboardingProductOverview";
import Card from "../../../Card/Card";

enum WelcomeStep {
  WELCOME,
  PRODUCT_OVERVIEW,
}

function OnboardingWelcomeStep({
  onContinue,
  productOverviewSteps,
}: {
  onContinue: () => void;
  productOverviewSteps: ProductOverviewStep[];
}) {
  const [currentStep, setCurrentStep] = useState<WelcomeStep>(
    WelcomeStep.WELCOME
  );
  switch (currentStep) {
    case WelcomeStep.WELCOME:
      return (
        <Card>
          <Card.Body>
            <img
              src={Logo}
              width={64}
              style={{ marginBottom: 32 }}
              alt="Depict Logo"
            />
            <Card.Title>Welcome to Depict</Card.Title>
            <Card.Text>
              Get ready to depict your brand with world-leading personalized
              recommendations, site search, and visual merchandising.
            </Card.Text>
            <Button
              className="w-100 mt-3"
              onClick={() => {
                if (productOverviewSteps.length === 0) {
                  onContinue();
                } else {
                  setCurrentStep(WelcomeStep.PRODUCT_OVERVIEW);
                }
              }}
            >
              Get Started
            </Button>
          </Card.Body>
        </Card>
      );
    case WelcomeStep.PRODUCT_OVERVIEW:
      return (
        <OnboardingProductOverview
          onContinue={onContinue}
          productOverviewSteps={productOverviewSteps}
        />
      );
  }
  return null;
}

export default memo(OnboardingWelcomeStep);

import { useMutation } from "@tanstack/react-query";
import { useMemo } from "react";
import { getData } from "src/api/authorizedApi";
import { ChunkType } from "src/api/types";
import {
  CategoryRankingOrderOptionType,
  RankingOption,
} from "src/components/storybook/Categories/CategoryRankingOrder/CategoryRankingOrderOption";
import useMerchantId from "src/helpers/hooks/app/useMerchantId";
import { AuthorizedApi } from "../app/useAuthorizedApi";
import { CollectionListDto } from "src/api/types";

export const usePLPRanking = (
  api: AuthorizedApi | null,
  activePLP: CollectionListDto | null
) => {
  const { merchantId } = useMerchantId();

  const changeRankingType = useMutation({
    mutationFn: async (type: CategoryRankingOrderOptionType) => {
      if (!api || !activePLP) return;

      let chunkType: ChunkType | undefined = undefined;
      switch (type) {
        case CategoryRankingOrderOptionType.Similar:
          chunkType = "similar";
          break;
        case CategoryRankingOrderOptionType.Complementary:
          chunkType = "complementary";
          break;
        case CategoryRankingOrderOptionType.Top:
          chunkType = undefined;
          break;
      }

      const response = await api.PUT(
        "/api/v0/listing/{merchant_id}/scollections/{collection_id}/update-chunk-type",
        {
          params: {
            path: {
              merchant_id: merchantId,
              collection_id: activePLP.collection_id,
            },
            query: {
              chunk_type: chunkType,
            },
          },
        }
      );

      const data = getData(response);

      return data;
    },
  });

  const availableRankingOptions = useMemo<RankingOption[]>(() => {
    const ranking = [
      CategoryRankingOrderOptionType.Top,
      CategoryRankingOrderOptionType.Similar,
      CategoryRankingOrderOptionType.Complementary,
    ];

    // TODO - Use feature flag on Merchant
    const tempFullyEnabledMerchants = [
      "stronger",
      "singularsociety",
      "bjornborg",
    ];
    const tempGroupedEnabledMerchants = [
      "chimi",
      "eytys",
      "equestrian",
      "mayadelorez",
      "grandpastore",
      "hope",
      "suserisivet",
      "follestad",
      "lexington",
      "thecirkel",
    ];

    const allowedMerchantsOverride =
      localStorage.getItem(`temp_chunking_allowed_merchants`)?.split(",") ?? [];
    tempFullyEnabledMerchants.push(...allowedMerchantsOverride);

    const getAvailable = (ranking: CategoryRankingOrderOptionType) => {
      if (ranking === CategoryRankingOrderOptionType.Top) return true;

      if (tempFullyEnabledMerchants.includes(merchantId)) {
        return true;
      }

      if (merchantId.endsWith("demo")) {
        return true;
      }

      if (
        ranking === CategoryRankingOrderOptionType.Similar &&
        tempGroupedEnabledMerchants.includes(merchantId)
      ) {
        return true;
      }

      return false;
    };

    return ranking.map((type) => ({
      type,
      available: getAvailable(type),
    }));
  }, [merchantId]);

  const currentRankingOption = useMemo(() => {
    if (!activePLP) return CategoryRankingOrderOptionType.Top;
    switch (activePLP.chunk_type) {
      case "similar":
        return CategoryRankingOrderOptionType.Similar;
      case "complementary":
        return CategoryRankingOrderOptionType.Complementary;
      default:
        return CategoryRankingOrderOptionType.Top;
    }
  }, [activePLP]);

  const rankingOptionToChunkType = (
    type: CategoryRankingOrderOptionType
  ): ChunkType | undefined => {
    switch (type) {
      case CategoryRankingOrderOptionType.Similar:
        return "similar";
      case CategoryRankingOrderOptionType.Complementary:
        return "complementary";
      default:
        return undefined;
    }
  };

  return {
    availableRankingOptions,
    currentRankingOption,
    changeRankingType: changeRankingType.mutateAsync,
    requestLoading: changeRankingType.isPending,
    rankingOptionToChunkType,
  };
};

import { ListConfigurationsProps, ListItem } from "src/types/components";
import mergeClassNames from "merge-class-names";
import config from "src/config";
import { useEffect, useState } from "react";

const Configurations = (props: ListConfigurationsProps) => {
  const { items, selectedItem, listItemOnClickCB } = props;

  const [localSelected, setLocalSelected] = useState<ListItem | null>(null);

  const clickOnItem = (item: ListItem) => {
    setLocalSelected(item);
    if (listItemOnClickCB) {
      listItemOnClickCB(item.id as number);
    }
  };

  useEffect(() => {
    setLocalSelected(selectedItem);

    return () => {
      setLocalSelected(null);
    };
  }, [selectedItem]);

  return (
    <div className="depict--ListConfigurations" data-tc="configurations-list">
      {items && (
        <ul>
          {items.map((item: ListItem) => {
            return (
              <li
                className={mergeClassNames([
                  localSelected && item.id === localSelected.id
                    ? "depict--ListConfigurations__itemSelected"
                    : "",
                  "depict--ListConfigurations__item__" + item.id,
                ])}
                onClick={() => {
                  clickOnItem(item);
                }}
                title={item.title}
                key={item.id}
              >
                <div className="depict--ListConfigurations__name">
                  {item.title ? (
                    item.title
                  ) : (
                    <i>{config.const.defaultConfigurationDisplayName}</i>
                  )}
                </div>
                {item.rightIcon && (
                  <div className="depict--ListConfigurations__iconRight">
                    {item.rightIcon}
                  </div>
                )}
              </li>
            );
          })}
          {items.length < 1 && <li>No configurations found.</li>}
        </ul>
      )}
    </div>
  );
};

export default Configurations;

import { css } from "@emotion/react";
import { forwardRef, useEffect, useRef, useState } from "react";
import CategoryRankingOrder from "src/components/storybook/Categories/CategoryRankingOrder/CategoryRankingOrder";
import {
  CategoryRankingOrderOptionType,
  RankingOption,
} from "src/components/storybook/Categories/CategoryRankingOrder/CategoryRankingOrderOption";
import { useOnClickOutside } from "usehooks-ts";

interface RankingOptionsProps {
  elementToHookToRef: React.RefObject<HTMLDivElement>;
  availableRankingOptions: RankingOption[];
  showRankingOrderOptions: boolean;
  rankingType: CategoryRankingOrderOptionType;
  onSetRankingType: (rankingType: CategoryRankingOrderOptionType) => void;
  headerHeight: number;
  setShowRankingOrderOptions: (show: boolean) => void;
}
const RankingOptions = (props: RankingOptionsProps) => {
  const {
    elementToHookToRef,
    availableRankingOptions,
    showRankingOrderOptions,
    rankingType,
    onSetRankingType,
    headerHeight,
  } = props;

  const [rect, setRect] = useState<{
    width: number | string;
    x: number;
    height: number | string;
    y: number;
  }>({
    width: 0,
    x: 0,
    height: 0,
    y: 0,
  });

  const rankingOptionRef = useRef(null);

  useOnClickOutside(rankingOptionRef, () => {
    props.setShowRankingOrderOptions(false);
  });

  // Set bar to same width as container, even on resize
  useEffect(() => {
    const resizeListener = () => {
      if (elementToHookToRef.current) {
        const boundingRect = elementToHookToRef.current.getBoundingClientRect();

        setRect((prev) => {
          if (
            prev.width === boundingRect.width &&
            prev.x === boundingRect.x &&
            prev.height === boundingRect.height &&
            prev.y === boundingRect.y
          ) {
            return prev;
          }

          return {
            width: boundingRect.width,
            x: boundingRect.x,
            height: `calc(100vh - ${headerHeight}px)`,
            y: headerHeight,
          };
        });
      }
    };
    resizeListener();
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [elementToHookToRef, headerHeight]);
  return (
    <div
      css={css`
        position: fixed;
        top: ${rect.y}px;
        left: ${rect.x}px;
        width: ${rect.width}px;
        height: ${rect.height};
        background: linear-gradient(
          180deg,
          #fff 0%,
          rgba(255, 255, 255, 0.36) 100%
        );
        backdrop-filter: blur(8px);
        padding: 16px;
      `}
    >
      {availableRankingOptions.length > 0 && (
        <div
          ref={rankingOptionRef}
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;
            max-height: ${showRankingOrderOptions ? "290px" : "0px"};
            margin-top: 32px;
          `}
        >
          <CategoryRankingOrder
            selected={rankingType}
            availableOptions={availableRankingOptions}
            onClick={onSetRankingType}
          />
        </div>
      )}
    </div>
  );
};

export default RankingOptions;

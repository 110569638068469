import { useCallback } from "react";
import {
  fromListItemToIdObj,
  getUpdatedGroup,
} from "src/helpers/configuration";
import { ListItem } from "src/types/components";
import {
  ConfiguredProductGroup,
  ConfiguredProductGroupDefinition,
  GroupIdObj,
  MainProductIdObj,
  ProductIdObj,
} from "src/types/configuration";
import { GroupModifyCB } from "src/types/components";

export default function useDeleteItemCallback(props: {
  configurationGroups: ConfiguredProductGroup[] | null;
  groupModifyCB: GroupModifyCB;
}) {
  const { configurationGroups, groupModifyCB } = props;
  const deleteItem = useCallback(
    (target: string, item: ListItem): void => {
      if (!groupModifyCB || !configurationGroups) {
        return;
      }

      let data: ConfiguredProductGroupDefinition = [];
      data.push(
        fromListItemToIdObj(item) as
          | MainProductIdObj
          | ProductIdObj
          | GroupIdObj
      );

      let updatedGroup = getUpdatedGroup(
        target,
        data,
        configurationGroups,
        "remove"
      );

      if (!updatedGroup) {
        return;
      }

      console.log(
        `%c[CONFIGURATION] Performing operation "${"remove"}" on group "${target}"`,
        "color: purple"
      );

      groupModifyCB(updatedGroup);
    },
    [configurationGroups, groupModifyCB]
  );

  return { deleteItem };
}

import { BoostRuleType } from "../../../../helpers/boostbury/availableOptions";
import { theme } from "../../designSystemVariables";
import { BoostBuryEffect } from "src/api/types";
import { Fire, Skull } from "@phosphor-icons/react";
import "./BoostAndBuryPreview.scss";
import DepictLogo from "../../../DepictLogo";

type NonNullableBoostRuleType = NonNullable<BoostRuleType>;

const TYPE_TO_BADGE_TEXT: Record<NonNullableBoostRuleType, string | null> = {
  best_selling: "Bestseller",
  few_sizes_available: "Few sizes",
  new_products: "New in",
  on_sale: "On sale",
  out_of_stock: "Out of stock",
  scollection: null,
  new_products_proportional: "New in",
};

const TYPE_TO_BADGE_COLORS: Record<NonNullableBoostRuleType, string[] | null> =
  {
    best_selling: ["#DC9100", theme.textIcon.inverse.default],
    few_sizes_available: [
      theme.background.warning.default,
      theme.textIcon.warning.default,
    ],
    new_products: ["#3018C1", theme.textIcon.inverse.default],
    on_sale: [theme.background.danger.pressed, theme.background.base.default],
    out_of_stock: [theme.background.base.hover, theme.textIcon.subtle.default],
    scollection: null,
    new_products_proportional: ["#3018C1", theme.textIcon.inverse.default],
  };

export interface BoostAndBuryPreviewCardProps {
  image?: string | null;
  effect: BoostBuryEffect | false;
  options: BoostRuleType[];
  icon?: React.JSX.Element;
  squareVersion?: boolean;
  showPlaceholder?: boolean;
}

export const BoostAndBuryPreviewCard = ({
  image,
  effect,
  options,
  icon,
  squareVersion,
  showPlaceholder = true,
}: BoostAndBuryPreviewCardProps) => {
  const iconToUse = icon ?? <DepictLogo width={32} height={32} />;
  return (
    <div
      className={`depict--BoostAndBuryPreview__card me-2${
        squareVersion ? " --square" : ""
      }`}
    >
      {image && <img src={image} />}
      {!image && showPlaceholder && (
        <div
          className="depict--placeholder"
          style={{ width: "100%", height: "100%" }}
        ></div>
      )}
      {!image && !showPlaceholder && (
        <div
          className="w-100 h-100 d-flex justify-content-center align-items-center"
          style={{
            background: theme.background.subtle.default,
          }}
        >
          {iconToUse}
        </div>
      )}
      <div className="depict--BoostAndBuryPreview__badges">
        {effect && (
          <div className="depict--BoostAndBuryPreview__icon">
            {effect === "boost" ? (
              <Fire size={16} color={theme.background.danger.pressed} />
            ) : (
              <Skull size={16} color={theme.textIcon.base.pressed} />
            )}
          </div>
        )}
        {options &&
          options
            .map((option) => {
              if (!option) return null;
              if (!TYPE_TO_BADGE_TEXT[option]) return null;
              return (
                <div className="depict--BoostAndBuryPreview__badge">
                  <p
                    style={{
                      background:
                        TYPE_TO_BADGE_COLORS[option]?.[0] ?? undefined,
                      color: TYPE_TO_BADGE_COLORS[option]?.[1] ?? undefined,
                    }}
                  >
                    {TYPE_TO_BADGE_TEXT[option]}
                  </p>
                </div>
              );
            })
            .filter((c) => c)}
      </div>
    </div>
  );
};

export default BoostAndBuryPreviewCard;

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Cherry = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M7.501 8c-0.033 0-0.066-0.003-0.099-0.010-1.339-0.268-2.694-1.096-3.717-2.273-1.087-1.25-1.685-2.748-1.685-4.217 0-0.184 0.102-0.354 0.264-0.441s0.36-0.077 0.513 0.025c1.374 0.916 2.952 0.916 4.478 0.916 1.608 0 3.271 0 4.571 1.121 0.209 0.18 0.233 0.496 0.052 0.705s-0.496 0.232-0.705 0.052c-1.019-0.879-2.363-0.879-3.918-0.879-1.319 0-2.783 0-4.169-0.611 0.45 2.32 2.579 4.234 4.512 4.62 0.271 0.054 0.446 0.318 0.392 0.588-0.048 0.238-0.256 0.402-0.49 0.402z" fill={color}></path>
<path d="M9.5 6c-0.128 0-0.256-0.049-0.354-0.146-0.839-0.839-2.629-0.854-2.647-0.854-0.276-0.001-0.499-0.225-0.499-0.501s0.224-0.499 0.5-0.499c0.090 0 2.22 0.013 3.354 1.146 0.195 0.195 0.195 0.512 0 0.707-0.098 0.098-0.226 0.146-0.354 0.146z" fill={color}></path>
<path d="M3.5 16c-0.276 0-0.5-0.224-0.5-0.5 0-1.378 1.122-2.5 2.5-2.5 0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5c-0.827 0-1.5 0.673-1.5 1.5 0 0.276-0.224 0.5-0.5 0.5z" fill={color}></path>
<path d="M13.466 6.109c0.568-0.875 1.167-1.54 1.527-1.908l0.153 0.153c0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146l2-2c0.195-0.195 0.195-0.512 0-0.707l-1-1c-0.195-0.195-0.512-0.195-0.707 0l-2 2c-0.195 0.195-0.195 0.512 0 0.707l0.043 0.043c-0.572 0.352-1.523 0.976-2.626 1.852-1.567 1.246-3.732 3.249-5.309 5.814-0.246-0.042-0.498-0.064-0.755-0.064-2.481 0-4.5 2.019-4.5 4.5s2.019 4.5 4.5 4.5 4.5-2.019 4.5-4.5c0-1.86-1.134-3.46-2.748-4.145 1.483-2.325 3.467-4.155 4.917-5.309 0.103-0.082 0.205-0.162 0.305-0.239-0.486 0.788-0.861 1.592-1.115 2.394-0.302 0.951-0.433 1.901-0.395 2.832-0.603 0.072-1.188 0.265-1.714 0.57-0.239 0.138-0.321 0.444-0.182 0.683s0.444 0.321 0.683 0.182c0.529-0.306 1.134-0.468 1.75-0.468 1.93 0 3.5 1.57 3.5 3.5s-1.57 3.5-3.5 3.5c-0.616 0-1.221-0.162-1.75-0.468-0.239-0.138-0.545-0.057-0.683 0.182s-0.057 0.545 0.182 0.683c0.681 0.394 1.459 0.602 2.251 0.602 2.481 0 4.5-2.019 4.5-4.5 0-2.325-1.772-4.243-4.036-4.476-0.090-1.995 0.751-3.758 1.502-4.915zM16.5 1.707l0.293 0.293-1.293 1.293-0.293-0.293 1.293-1.293zM9 15.5c0 1.93-1.57 3.5-3.5 3.5s-3.5-1.57-3.5-3.5 1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5z" fill={color}></path>
<path d="M10.5 14.268c-0.173 0-0.341-0.089-0.433-0.25-0.138-0.239-0.057-0.545 0.182-0.683 0.379-0.219 0.811-0.335 1.25-0.335 0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5c-0.264 0-0.523 0.069-0.75 0.2-0.079 0.046-0.165 0.067-0.25 0.067z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Cherry); 
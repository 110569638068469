import { useSortable } from "@dnd-kit/sortable";
import React from "react";
import reactFastCompare from "react-fast-compare";
import Container from "src/components/dragndrop/Container";
import { ListItem } from "src/types/components";
import { ContainersProps } from "src/types/dnd";

const DroppableContainer = ({
  children,
  columns = 1,
  disabled,
  id,
  items,
  style,
  ...props
}: ContainersProps & {
  disabled?: boolean;
  id: string;
  items: ListItem[] | null;
  style?: React.CSSProperties;
}) => {
  const { setNodeRef } = useSortable({
    id,
  });

  return (
    <Container
      ref={disabled ? undefined : setNodeRef}
      style={style}
      columns={columns}
      {...props}
    >
      {children}
    </Container>
  );
};

export default React.memo(DroppableContainer, reactFastCompare);

export default function OnboardingHeaderStepLabel({
  isCurrentStep,
  number,
  label,
}: {
  isCurrentStep: boolean;
  number: number;
  label: string;
}) {
  return (
    <div className="d-flex align-items-center mx-4">
      <div
        className={`rounded-circle p-2 d-flex align-items-center justify-content-center me-3
        ${
          isCurrentStep ? "bg-primary text-white" : "bg-gray-200 text-primary"
        }`}
        style={{ width: 32, height: 32 }} // if you change this height, please make sure to also change `headerHeight` in Onboarding.tsx
      >
        {number}
      </div>
      <div className={`${isCurrentStep && "text-primary fw-bold"}`}>
        {label}
      </div>
    </div>
  );
}

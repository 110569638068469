import { useEffect, useRef, useState } from "react";
import {
  getPeriodFromUi,
  getAggregationValue,
  getCompareDataset,
} from "src/helpers/compare";

import config from "src/config";
import { DepictMetricsData, MetricsMarketData } from "src/types/metrics";
import { SavedUiState } from "src/types/user";
import {
  GetMetricApiV0MetricsTimeSeriesMetricNameGetRequest,
  deepEqual,
  mockMetric,
} from "src/helpers";
import { Compare, ControlLoadingQueueCB, Period } from "src/types/components";

import { useAlerts } from "../../../components/storybook/Alert/useAlerts";
import useAuthorizedApi from "../app/useAuthorizedApi";
import { MetricName } from "src/api/types";
import { getData as getDataFromResponse } from "src/api/authorizedApi";
import { components } from "src/api/generated/openapi";

type UseMetric = [
  metric: DepictMetricsData | null,
  compare: DepictMetricsData | null
];

type AttributedTimeSeries = components["schemas"]["AttributedTimeSeries"];

export default function useMetric(
  metricName: MetricName,
  merchant: string | null,
  market: string | null,
  period: Period,
  compare: Compare,
  splitBySurface: boolean,
  controlLoadingQueueCB: ControlLoadingQueueCB
): UseMetric {
  const { addAlert } = useAlerts();
  const { api } = useAuthorizedApi();

  const [metric, setMetric] = useState<DepictMetricsData | null>(null);
  const [compareMetric, setCompareMetric] = useState<DepictMetricsData | null>(
    null
  );
  const id = Math.floor(Math.random() * 99999);

  const savedUiState = useRef<{
    data: SavedUiState;
    compare: SavedUiState;
  }>({
    data: { merchant, market, period, compare, splitBySurface },
    compare: { merchant, market, period, compare, splitBySurface },
  });

  const getData = async function (
    mtrcName: MetricName,
    type: "data" | "compare",
    success: (data: DepictMetricsData | null) => void,
    error?: Function
  ): Promise<void> {
    // TODO: Refactor this whole thing and get rid of even coming this far without a merchant ID
    if (!merchant || !api) {
      success(null);
      return;
    }

    const markets = market ? [market] : undefined;

    let periodData = getPeriodFromUi(period, compare, type);
    let aggregation = getAggregationValue(period);

    const startTimestamp = periodData?.start;
    const endTimestamp = periodData?.end;
    const aggregationDuration = aggregation;

    savedUiState.current[type] = {
      merchant,
      market,
      period,
      compare,
      splitBySurface,
    };

    /**
     * Uncomment to effectively test fast switching between periods
     */
    // if (period === "90-days") {
    //   await new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //       resolve(true);
    //     }, 1000);
    //   });
    // }

    const params: GetMetricApiV0MetricsTimeSeriesMetricNameGetRequest = {
      merchant_id: merchant,
      start_timestamp: startTimestamp,
      end_timestamp: endTimestamp,
      aggregation_duration: aggregationDuration,
      markets,
    };

    if (config.dataMock) {
      const response = await mockMetric(mtrcName, params);
      success(response);
      return;
    }

    const response = await api.GET(
      "/api/v0/metrics/time-series/{metric_name}",
      {
        params: {
          path: {
            metric_name: mtrcName,
          },

          query: {
            aggregation_duration: params.aggregation_duration,
            end_timestamp: params.end_timestamp,
            merchant_id: params.merchant_id,
            start_timestamp: params.start_timestamp,
          },
        },
      }
    );

    const data: Record<string, AttributedTimeSeries> =
      getDataFromResponse(response);

    if (response.error) {
      addAlert({
        message: JSON.stringify(response.error),
        id: "metric-error",
      });
      setMetric({});
      if (error) {
        error(null);
      }
    }

    if (
      !deepEqual(
        { merchant, market, period, compare, splitBySurface },
        savedUiState.current[type]
      )
    ) {
      console.log("%c[UI HAS CHANGED] Skip update...", "color: indigo");
      if (error) {
        error();
      }
      return;
    }

    success(data);
  };

  useEffect(() => {
    if (!merchant || !market) {
      return;
    }

    controlLoadingQueueCB(metricName + "-" + id, "push");
    getData(
      metricName,
      "data",
      (data: DepictMetricsData | null) => {
        setMetric(data);
        controlLoadingQueueCB(metricName + "-" + id, "remove");
      },
      () => {
        controlLoadingQueueCB(metricName + "-" + id, "remove");
      }
    );
    //eslint-disable-next-line
  }, [
    api,
    merchant,
    market,
    period,
    controlLoadingQueueCB,
    //metricName, //Intentional
    //id, //Intentional
    //getData, //Intentional
  ]);

  useEffect(() => {
    if (compare === "none") {
      setCompareMetric(null);
      return;
    }

    controlLoadingQueueCB(metricName + "-" + id, "push");
    getData(
      metricName,
      "compare",
      (data: DepictMetricsData | null) => {
        setCompareMetric(getCompareDataset(metric, data));
        controlLoadingQueueCB(metricName + "-" + id, "remove");
      },
      () => {
        controlLoadingQueueCB(metricName + "-" + id, "remove");
      }
    );
    //eslint-disable-next-line
  }, [
    api,
    compare,
    merchant,
    market,
    period,
    metric,
    controlLoadingQueueCB,
    //metricName, //Intentional
    //id, //Intentional
    //getData, //Intentional
  ]);

  return [metric, compareMetric];
}

import config from "src/config";

import createClient, { FetchResponse } from "openapi-fetch";

import { paths } from "./generated/openapi"; // generated from openapi-typescript

export const getAuthorizedApi = (accessToken: string) =>
  createClient<paths>({
    baseUrl: config.apiClient.baseUrl,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const getData = <T>(
  response: FetchResponse<T>
): Required<FetchResponse<T>>["data"] => {
  if (response.data === undefined) {
    if (response.error) {
      throw new Error(JSON.stringify(response.error));
    }
    throw new Error("No data");
  }
  return response.data;
};

export const throwErrorIfAny = <T>(response: FetchResponse<T>) => {
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
};

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const FlipFlops = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M19.704 8.76c0.157-0.906 0.296-1.933 0.296-3.26 0-2.194-1.133-3.502-2.083-4.213-1.077-0.806-2.542-1.287-3.917-1.287-0.785 0-1.853 0.313-2.481 1.806-0.344 0.818-0.519 1.893-0.519 3.194 0 1.092 0.184 2.005 0.37 2.783-0.226 0.55-0.37 1.131-0.37 1.717 0 1.286 0.472 2.325 0.98 3.070-0.011 0.319-0.024 0.637-0.037 0.946-0.096 2.232-0.187 4.341 0.949 5.527 0.597 0.623 1.45 0.926 2.608 0.926 0.812 0 1.495-0.199 2.030-0.592 0.467-0.343 0.824-0.836 1.062-1.466 0.407-1.078 0.407-2.372 0.407-3.412 0-0.594 0.022-1.157 0.068-1.714 0.462-0.597 0.932-1.517 0.932-2.786 0-0.394-0.099-0.808-0.296-1.24zM14 1c1.933 0 5 1.222 5 4.5 0 0.749-0.047 1.399-0.117 1.985-0.409-0.499-0.928-1.017-1.559-1.553-0.952-0.809-1.893-1.435-2.324-1.709v-0.722c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v0.773c-0.362 0.325-1.191 1.119-1.886 2.147-0.068-0.437-0.114-0.91-0.114-1.42 0-1.494 0.26-4 2-4zM15.5 18.97c-0.871 0-1.488-0.202-1.886-0.618-0.824-0.861-0.762-2.705-0.677-4.675 0.147 0.134 0.246 0.209 0.264 0.223 0.22 0.165 0.533 0.121 0.699-0.099s0.122-0.533-0.098-0.7c-0.018-0.014-1.801-1.392-1.801-3.601 0-1.818 1.849-3.719 2.553-4.376 0.44 0.284 1.284 0.856 2.123 1.569 1.499 1.274 2.324 2.448 2.324 3.307 0 0.897-0.285 1.679-0.848 2.326-0.431 0.496-0.873 0.725-0.876 0.727-0.247 0.123-0.347 0.424-0.224 0.671 0.088 0.175 0.264 0.277 0.448 0.277 0.075-0 0.151-0.017 0.223-0.053 0.010-0.005 0.121-0.061 0.288-0.173-0.007 0.238-0.011 0.479-0.011 0.726 0 2.446-0.188 4.47-2.5 4.47z" fill={color}></path>
<path d="M8.63 7.753c0.186-0.777 0.37-1.69 0.37-2.783 0-1.301-0.175-2.376-0.519-3.194-0.628-1.493-1.696-1.806-2.481-1.806-1.376 0-2.84 0.481-3.917 1.287-0.95 0.711-2.083 2.019-2.083 4.213 0 1.327 0.139 2.353 0.296 3.259-0.197 0.432-0.296 0.846-0.296 1.241 0 1.269 0.47 2.188 0.932 2.786 0.045 0.558 0.068 1.12 0.068 1.714 0 1.040 0 2.334 0.407 3.412 0.238 0.63 0.596 1.123 1.062 1.466 0.535 0.393 1.218 0.592 2.030 0.592 1.158 0 2.011-0.303 2.608-0.926 1.136-1.186 1.045-3.295 0.949-5.527-0.013-0.309-0.027-0.628-0.037-0.946 0.507-0.745 0.98-1.784 0.98-3.070 0-0.587-0.144-1.167-0.37-1.717zM6.1 13.77c0.166 0.221 0.479 0.266 0.7 0.1 0.018-0.013 0.117-0.089 0.264-0.223 0.084 1.969 0.147 3.814-0.677 4.675-0.398 0.416-1.015 0.618-1.886 0.618-2.312 0-2.5-2.024-2.5-4.47 0-0.247-0.004-0.488-0.011-0.726 0.167 0.112 0.277 0.168 0.288 0.173 0.072 0.036 0.148 0.053 0.223 0.053 0.183-0 0.36-0.101 0.448-0.277 0.123-0.247 0.023-0.547-0.224-0.671-0.017-0.009-1.724-0.887-1.724-3.053 0-0.857 0.822-2.028 2.314-3.298 0.84-0.715 1.691-1.292 2.133-1.577 0.704 0.658 2.553 2.558 2.553 4.376 0 2.209-1.783 3.587-1.801 3.601-0.22 0.166-0.264 0.479-0.099 0.699zM7.886 6.39c-0.695-1.028-1.524-1.822-1.886-2.147v-0.773c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v0.722c-0.431 0.274-1.372 0.9-2.324 1.709-0.631 0.536-1.15 1.054-1.559 1.553-0.070-0.586-0.117-1.236-0.117-1.985 0-3.278 3.067-4.5 5-4.5 1.74 0 2 2.506 2 4 0 0.511-0.046 0.983-0.114 1.42z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(FlipFlops); 
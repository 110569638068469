export function getLocaleLabel(locale: string): string {
  const language = new Intl.DisplayNames(["en"], { type: "language" });
  const region = new Intl.DisplayNames(["en"], { type: "region" });

  const [langCode, regionCode] = locale.split("_");
  const languageLabel = language.of(langCode);
  const regionLabel = regionCode ? `(${region.of(regionCode)})` : "";

  return `${languageLabel} ${regionLabel}`;
}

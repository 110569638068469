import mergeClassNames from "merge-class-names";

import {
  Trash,
  FloppyDisk,
  PencilLine,
  Undo2,
} from "src/components/linearicons";

import { ConfigurationToolbarProps } from "src/types/components";
import React, {
  FormEvent,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import Modal from "src/components/mix/Modal";
import { getModalContent } from "src/helpers/modals";
import { useTranslation } from "react-i18next";
import Tooltip from "bootstrap/js/dist/tooltip";
import config from "src/config";
import {
  getConfigurationStateColor,
  getConfigurationStateIcon,
  getConfigurationStateLabel,
  getSaveModalId,
} from "src/helpers/configuration";
import { getTwoDigitsDay, getTwoDigitsMonth } from "src/helpers/dates";
import reactFastCompare from "react-fast-compare";

const SectionConfigurationToolbar = (props: ConfigurationToolbarProps) => {
  const { t } = useTranslation();

  const {
    displayName,
    hasUnsavedChanges,
    hasLoadingProcesses,
    showSaveButton,
    startTimestamp,
    endTimestamp,
    savedAsState,
    savedAsEmpty,
    state,
    saveTriggerCB,
    configurationModifyCB,
    configurationDeleteCB,
    configurationRestoreCB,
  } = props;

  const [displayNameInput, setDisplayNameInput] = useState<string>(displayName);
  const customTimeout = useRef<ReturnType<typeof setTimeout>>();
  const tooltip = useRef<Tooltip | undefined>();

  const debounceMS = 200;

  const onFormSubmit = useCallback(
    (name: string) => {
      configurationModifyCB({
        display_name: name,
      });
    },
    [configurationModifyCB]
  );

  useEffect(() => {
    clearTimeout(customTimeout.current as ReturnType<typeof setTimeout>);

    if (displayNameInput === displayName) {
      return;
    }

    customTimeout.current = setTimeout(() => {
      onFormSubmit(displayNameInput);
    }, debounceMS);

    return () => {
      clearTimeout(customTimeout.current as ReturnType<typeof setTimeout>);
    };
  }, [displayNameInput, displayName, onFormSubmit]);

  useEffect(() => {
    setDisplayNameInput(displayName);
  }, [displayName]);

  const [saveModal, setSaveModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const closeCallback = useCallback(() => {
    setSaveModal(false);
    setDeleteModal(false);
  }, []);

  const saveCB = useCallback(() => {
    closeCallback();
    saveTriggerCB();
  }, [closeCallback, saveTriggerCB]);

  const deleteCB = useCallback(() => {
    closeCallback();
    configurationDeleteCB();
  }, [closeCallback, configurationDeleteCB]);

  const saveModalId = useMemo(
    () => getSaveModalId(state, startTimestamp, endTimestamp),
    [endTimestamp, startTimestamp, state]
  );

  useEffect(() => {
    const title = t(`tooltips.configuration.toolbar.save.${savedAsState}`);

    let els = document.getElementsByClassName("depict--Action__save");
    if (!els[0]) {
      return;
    }
    let el = els[0];

    tooltip.current = new Tooltip(el, {
      delay: config.const.bootstrapTooltipDelay,
      placement: "top",
      title: title,
    });

    return () => {
      if (tooltip.current) {
        tooltip.current.dispose();
      }
    };
  }, [startTimestamp, endTimestamp, savedAsState, t]);

  const deleteModalContent = useMemo(() => {
    return getModalContent({
      id: "delete",
      buttonCallbacks: { cancel: closeCallback, delete: deleteCB },
      stringInterpolations: [
        { placeholder: "configuration_name", value: displayNameInput },
      ],
    });
  }, [closeCallback, deleteCB, displayNameInput]);

  const saveModalContent = useMemo(() => {
    return getModalContent({
      id: saveModalId,
      buttonCallbacks: { cancel: closeCallback, save: saveCB },
      stringInterpolations: [
        { placeholder: "configuration_name", value: displayNameInput },
        {
          placeholder: "day",
          value: startTimestamp ? getTwoDigitsDay(startTimestamp) : "",
        },
        {
          placeholder: "month",
          value: startTimestamp ? getTwoDigitsMonth(startTimestamp) : "",
        },
      ],
    });
  }, [saveModalId, closeCallback, saveCB, displayNameInput, startTimestamp]);

  return (
    <>
      <div className="depict--ConfigurationToolbar mb-3">
        <div className="depict--ConfigurationToolbar__configurationName">
          <form
            onSubmit={(e: FormEvent) => {
              e.preventDefault();
              if (!displayNameInput) {
                return;
              }
              onFormSubmit(displayNameInput);
            }}
          >
            <input
              data-tc="configuration-name-input"
              style={{
                border: "none",
              }}
              type="text"
              className="form-control form-control-lg"
              placeholder="Configuration name"
              value={displayNameInput}
              onChange={(e) => {
                setDisplayNameInput(e.target.value);
              }}
            />
          </form>
        </div>
        <div className="depict--ConfigurationToolbar__actions">
          {savedAsEmpty === false && (
            <label
              data-tc="label-configuration-status"
              className={mergeClassNames("me-3 depict--Label__status")}
              style={{
                color: getConfigurationStateColor(
                  savedAsState,
                  startTimestamp,
                  endTimestamp
                ),
              }}
            >
              <span className="me-2">
                {getConfigurationStateIcon(
                  savedAsState,
                  startTimestamp,
                  endTimestamp
                )}
              </span>
              {getConfigurationStateLabel(
                savedAsState,
                startTimestamp,
                endTimestamp
              )}
            </label>
          )}
          {hasUnsavedChanges && (
            <div className="depict--Label__restore">
              <label
                className="me-3 text-xs"
                data-tc="restore-configuration-label"
              >
                <PencilLine className="me-2" size="12" /> Unsaved changes
              </label>
              <button
                data-tc="restore-configuration"
                type="button"
                className="btn btn-sm btn-outline-depict-brown btn-icon me-2"
                data-bs-toggle="tooltip"
                title={t("tooltips.configuration.toolbar.restore")}
                onClick={
                  configurationRestoreCB as MouseEventHandler<HTMLButtonElement>
                }
              >
                <Undo2 size="12" />
              </button>
            </div>
          )}
          <button
            data-tc="delete-configuration"
            type="button"
            className="btn btn-sm btn-icon btn-depict-red me-2 depict--Action__delete"
            data-bs-toggle="tooltip"
            title={t("tooltips.configuration.toolbar.delete")}
            onClick={() => {
              setDeleteModal(true);
            }}
          >
            <Trash size="12" />
          </button>
          {showSaveButton && (
            <button
              data-tc="save-configuration"
              type="button"
              className="btn btn-sm btn-depict-blue rounded-pill me-2 depict--Action__save"
              disabled={!hasUnsavedChanges || hasLoadingProcesses}
              onClick={() => {
                setSaveModal(true);
              }}
              data-dp-toggle="tooltip"
            >
              <FloppyDisk size="12" className="me-2" /> Save
            </button>
          )}
        </div>
      </div>
      <Modal
        title={
          <>
            {saveModalContent?.title}
            {saveModalContent?.subtitle}
          </>
        }
        show={saveModal}
        closeButton={true}
        closeCallback={closeCallback}
        buttons={saveModalContent?.buttons}
      >
        {saveModalContent?.content}
      </Modal>
      <Modal
        title={
          <>
            {deleteModalContent?.title}
            {deleteModalContent?.subtitle}
          </>
        }
        show={deleteModal}
        closeButton={true}
        closeCallback={closeCallback}
        buttons={deleteModalContent?.buttons}
      >
        {deleteModalContent?.content}
      </Modal>
    </>
  );
};

//6 no deep.
export default React.memo(SectionConfigurationToolbar, reactFastCompare);

import { useAuth0 } from "@auth0/auth0-react";
import { css } from "@emotion/react";
import {
  Article,
  Book,
  Heart,
  Plugs,
  Question,
  SignOut,
  Stethoscope,
  Fire,
  Gauge,
  MagnifyingGlass,
} from "@phosphor-icons/react";
import React, { useContext } from "react";
import { Collapse, Dropdown } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { ChevronDown, ChevronUp, Magnifier } from "src/components/linearicons";
import config from "src/config";
import { useMerchant } from "src/helpers/hooks/app/useMerchant";
import { ROUTES, buildPathWithMerchantId } from "src/router/Routes";
import { SidebarNavProps } from "src/types/components";
import MerchantSelector from "../mix/MerchantSelector";
import { theme } from "../storybook/designSystemVariables";
import Cog from "../linearicons/icons/Cog";
import DepictLogo from "src/assets/img/logo-symbol.svg";
import ListingTree from "../ListingTree/ListingTree";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { SidebarContext } from "../../contexts/SidebarContext";

export const SmallScreenCutOff = 1000;

const LinkWrapper = (props: {
  path: string;
  children?: React.ReactNode;
  newTab?: boolean;
}) => {
  return (
    <NavLink
      className="p-2 d-flex align-items-center rounded"
      css={css`
        text-decoration: none !important;
        color: #868689;
        font-weight: bold;
        &:hover {
          background-color: ${theme.background.subtle.hover};
        }
        &[aria-current="page"] {
          background-color: white;
          color: #0f0f0f;
        }
      `}
      to={props.path}
      target={props.newTab ? "_blank" : "_self"}
    >
      {props.children}
    </NavLink>
  );
};

const SidebarNav = (props: SidebarNavProps) => {
  const { inboxCount, user } = props;
  const { merchantId, merchant, merchantOptions } = useMerchant();

  const productStatus = merchant?.product_status;
  const useCategoriesV2 = merchant?.use_categories_v2;
  const isNoCodeMerchant = merchant?.no_code_config;

  const { isCollapsed, setIsCollapsed } = useContext(SidebarContext);

  const getPath = (path: ROUTES) => {
    if (!merchantId) return path;
    return buildPathWithMerchantId(path, merchantId);
  };

  const { logout } = useAuth0();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex: 0 0 420px;
        overflow-y: auto;
        @media (max-width: ${SmallScreenCutOff}px) {
          flex: 0 0 100vw;
        }
      `}
    >
      <div className="m-2 p-2 rounded border border-secondary text-sm">
        <div
          className="p-2 d-flex align-items-center rounded w-100"
          css={css`
            color: #868689;
            font-weight: bold;
            cursor: pointer;
            &:hover {
              background-color: ${theme.background.subtle.hover};
            }
            &[aria-current="page"] {
              background-color: white;
              color: #0f0f0f;
            }
          `}
          onClick={() => {
            setIsCollapsed(!isCollapsed);
          }}
        >
          <div className="me-2 d-flex align-items-center justify-content-center">
            <img
              alt="logo"
              src={DepictLogo}
              css={css`
                width: 12px;
                height: 12px;
                margin: 2px;
                opacity: 0.5;
              `}
            />
          </div>
          Depict
          <div className="ms-auto me-1">
            {isCollapsed ? <ChevronDown /> : <ChevronUp />}
          </div>
        </div>
        <Collapse in={!isCollapsed}>
          <div>
            <LinkWrapper path={getPath(ROUTES.DASHBOARD)}>
              <Gauge size={16} className="me-2" />
              Dashboard
            </LinkWrapper>
            {productStatus?.recommendations_state !== "inactive" && (
              <LinkWrapper path={getPath(ROUTES.CONFIGURE)}>
                <Heart size={16} className="me-2" />
                Recommend
              </LinkWrapper>
            )}
            {(productStatus?.search_state !== "inactive" ||
              merchantId === "artilleriet" ||
              user?.is_superuser) && (
              <LinkWrapper path={getPath(ROUTES.SEARCH)}>
                <MagnifyingGlass size={16} className="me-2" />
                Search
              </LinkWrapper>
            )}
            {(productStatus?.plp_state !== "inactive" ||
              productStatus?.search_state !== "inactive") && (
              <LinkWrapper path={getPath(ROUTES.BOOST_AND_BURY)}>
                <Fire size={16} className="me-2" />
                Boost & bury
              </LinkWrapper>
            )}
          </div>
        </Collapse>
        {isNoCodeMerchant &&
          (productStatus?.plp_state !== "inactive" ||
            productStatus?.search_state !== "inactive") && (
            <LinkWrapper
              path={
                getPath(ROUTES.CATALOG) +
                (inboxCount && inboxCount > 0 ? "?view=inbox" : "")
              }
            >
              <div className="d-flex align-items-center justify-content-between w-100">
                <div>
                  <Book size={16} className="me-2" />
                  Product catalog
                </div>
                {inboxCount !== null && inboxCount > 0 && (
                  <div
                    className="d-flex p-1 align-items-center justify-content-center rounded-pill text-white"
                    css={css`
                      background-color: #3018c1;
                      height: 24px;
                      min-width: 24px;
                      font-weight: normal;
                    `}
                  >
                    {inboxCount}
                  </div>
                )}
              </div>
            </LinkWrapper>
          )}
      </div>
      {(productStatus?.plp_state === "live" ||
        productStatus?.plp_state === "in_development") &&
        (useCategoriesV2 || merchant?.access_categories_v3) && (
          <DndProvider backend={HTML5Backend}>
            <ListingTree merchant={merchant} />
          </DndProvider>
        )}
      {(!productStatus?.plp_state ||
        productStatus?.plp_state === "inactive" ||
        productStatus?.plp_state === "legacy") && (
        <div className="flex-grow-1" />
      )}
      <div className="m-2 mt-0 p-2 rounded border border-secondary text-sm">
        {merchantOptions.length > 1 && (
          <MerchantSelector selected={merchantId ?? undefined} />
        )}
        <Dropdown id="user-dropdown" drop="up">
          <Dropdown.Toggle
            variant={"link"}
            className="p-2 d-flex align-items-center rounded w-100"
            css={css`
              text-decoration: none !important;
              justify-content: flex-start !important;
              color: #868689 !important;
              font-weight: bold;
              &:after {
                display: none !important;
              }
              &:hover {
                background-color: ${theme.background.subtle.hover};
              }
            `}
          >
            <Cog size={16} className="me-2" />
            <span>Settings</span>
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="p-2"
            css={css`
              min-width: 200px;
            `}
          >
            <div className="p-2 rounded mb-2 bg-gray-200">
              Logged in as:
              <br />
              <strong>{user?.email}</strong>
            </div>
            {(isNoCodeMerchant || user?.is_superuser) && (
              <Dropdown.Item
                as={Link}
                to={getPath(ROUTES.INTEGRATIONS)}
                target="_self"
                css={css`
                  text-decoration: none !important;
                `}
              >
                <Plugs size={16} className="me-2" />
                Integrations
              </Dropdown.Item>
            )}
            <Dropdown.Item
              href="https://help.depict.ai/en/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Question size={16} className="me-2" />
              Help center
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                logout({
                  logoutParams: {
                    returnTo: window.location.origin + config.publicUrl,
                  },
                });
              }}
            >
              <SignOut size={16} className="me-2" />
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default React.memo(SidebarNav);

import { DataPeriod } from "src/types/components";
import { DepictMetricsData } from "src/types/metrics";
import { CsvData, CsvRow } from "src/types/csv";
import { formatNumber } from "src/helpers";
import { formatDate } from "src/helpers/dates";
import {
  getMarketDataFromMetric,
  getSurfaceConversionRate,
  getSurfacePercentageValue,
  getSurfaceAggregatedValue,
} from "src/helpers/metrics";

export const csvHeaders: Array<{ key: string; label: string }> = [
  { key: "market", label: "Market" },
  { key: "period-start", label: "Start" },
  { key: "period-end", label: "End" },
  { key: "surface", label: "Surface" },
  { key: "clicks", label: "Clicks" },
  { key: "addsToCart", label: "Adds to cart" },
  { key: "purchases", label: "Unique Purchases" },
  { key: "conversionRate", label: "Conversion rate (%)" },
  { key: "revenue", label: "Rec. revenue" },
  { key: "percentageRevenue", label: "Rec. revenue (%)" },
];

export const getCsvHeaders = (currencySymbol: string): string[] => {
  return csvHeaders.map((header: { key: string; label: string }) => {
    if (header.key === "revenue") {
      return `${header.label} (${currencySymbol})`;
    }
    return header.label;
  });
};

export const getCsvData = (
  m: {
    [metric: string]: DepictMetricsData | null;
  },
  rows: string[],
  headers: Array<{ key: string; label: string }>,
  market: string,
  splitBySurface: boolean | undefined,
  period: DataPeriod | undefined
): CsvData => {
  let data = [];
  let i = 0;
  let emptyDefault = "-";
  for (const row of rows) {
    if (!splitBySurface && row !== "totalFromRecommendations") {
      continue;
    }

    let surface = row;
    let rowData: CsvRow = [];
    for (const column of headers) {
      switch (column.key) {
        case "market":
          const label = market !== "EMPTY_KEY" ? market : "market";
          rowData.push(label);
          break;
        case "period-start":
          if (period) {
            rowData.push(formatDate(period.start));
          } else {
            rowData.push(emptyDefault);
          }
          break;
        case "period-end":
          if (period) {
            rowData.push(formatDate(period.end));
          } else {
            rowData.push(emptyDefault);
          }
          break;
        case "surface":
          rowData.push(
            surface !== "totalFromRecommendations" ? surface : "Total"
          );
          break;
        case "conversionRate":
          let clicks = getMarketDataFromMetric(m.clicks, market);
          let purchases = getMarketDataFromMetric(m.purchases, market);

          if (!clicks || !purchases) {
            rowData.push(emptyDefault);
            break;
          }

          let conversionRate = getSurfaceConversionRate(clicks, purchases, [
            row,
          ]);

          rowData.push(
            conversionRate
              ? formatNumber({
                  number: conversionRate,
                  suffix: "",
                  digitsAfterComma: 2,
                })
              : emptyDefault
          );
          break;
        case "percentageRevenue":
          let marketDataset = getMarketDataFromMetric(m.revenue, market);

          if (!marketDataset) {
            rowData.push(emptyDefault);
            break;
          }

          let value = getSurfacePercentageValue(marketDataset, [row]);
          rowData.push(
            formatNumber({
              number: value as number,
              suffix: "",
              digitsAfterComma: 2,
              noSpaces: true,
            })
          );
          break;
        default:
          if (!m[column.key]) {
            rowData.push(emptyDefault);
            break;
          }

          let metricMarketData = getMarketDataFromMetric(m[column.key], market);

          if (!metricMarketData) {
            rowData.push(emptyDefault);
            break;
          }

          let aggregated = getSurfaceAggregatedValue(metricMarketData, surface);

          rowData.push(
            formatNumber({
              number: aggregated,
              digitsAfterComma: 2,
              noSpaces: true,
            })
          );
          break;
      }
    }
    data[i] = rowData;
    i++;
  }

  return data;
};

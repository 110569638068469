import { useState, useCallback, useEffect } from "react";

import { Configuration } from "src/types/configuration";
import configurationsMiddleware from "src/api/configurationsMiddleware";
import { getAlertMessage } from "src/helpers/errors";
import { ControlLoadingQueueCB } from "src/types/components";
import { useAuth0 } from "@auth0/auth0-react";
import useAuthentication from "../app/useAuthentication";
import { useAlerts } from "../../../components/storybook/Alert/useAlerts";

type UseConfigurations = [configurations: Configuration[], refresh: Function];

export default function useConfigurations(
  merchant: string | null,
  controlLoadingQueueCB: ControlLoadingQueueCB
) {
  const { addAlert } = useAlerts();
  const [configurations, setConfigurations] = useState<Configuration[] | null>(
    null
  );
  const { user } = useAuth0();
  const [getAccessToken] = useAuthentication();

  const loadMerchantData = useCallback(
    async (merchant: string | null) => {
      if (!merchant) {
        setConfigurations(null);
        return;
      }

      controlLoadingQueueCB("load-merchant-configurations", "push");
      const id_token = await getAccessToken();

      const conf = await configurationsMiddleware.get(
        id_token,
        merchant,
        user?.email
      );

      if (!conf.success) {
        let message = await getAlertMessage(conf.error);
        addAlert(message);
        setConfigurations(null);
        controlLoadingQueueCB("load-merchant-configurations", "remove");
        return;
      }

      setConfigurations(conf?.success ? conf.data : null);
      controlLoadingQueueCB("load-merchant-configurations", "remove");
    },
    [controlLoadingQueueCB, getAccessToken, user?.email, addAlert]
  );

  useEffect(() => {
    loadMerchantData(merchant);
    return () => {
      setConfigurations(null);
    };
  }, [loadMerchantData, merchant]);

  const refresh = useCallback(() => {
    loadMerchantData(merchant);
  }, [loadMerchantData, merchant]);

  return [configurations, refresh] as UseConfigurations;
}

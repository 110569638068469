 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Wheelchair = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M17.854 13.146c-0.195-0.195-0.512-0.195-0.707 0l-0.651 0.651-2.729-2.656c-0.002-0.002-0.003-0.003-0.005-0.004-0.007-0.007-0.015-0.013-0.022-0.019-0.006-0.005-0.011-0.009-0.017-0.014s-0.013-0.010-0.020-0.014c-0.007-0.005-0.014-0.010-0.021-0.014-0.006-0.003-0.011-0.007-0.017-0.010-0.008-0.005-0.017-0.010-0.026-0.014-0.006-0.003-0.011-0.005-0.017-0.008-0.009-0.004-0.018-0.008-0.027-0.012-0.007-0.003-0.014-0.005-0.021-0.007-0.008-0.003-0.016-0.006-0.024-0.008s-0.018-0.004-0.027-0.006c-0.007-0.001-0.013-0.003-0.020-0.004-0.010-0.002-0.020-0.003-0.031-0.004-0.006-0.001-0.011-0.002-0.017-0.002-0.010-0.001-0.020-0.001-0.030-0.001-0.006-0-0.012-0-0.019-0-0.009 0-0.018 0.001-0.026 0.002s-0.016 0.001-0.023 0.002c-0.007 0.001-0.015 0.002-0.022 0.003-0.009 0.002-0.019 0.003-0.028 0.005-0.002 0.001-0.004 0.001-0.007 0.001l-3.945 0.953c-0.106 0.026-0.199 0.013-0.26-0.035s-0.095-0.135-0.095-0.244v-1.688h3.5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-3.5v-3.050c0.162 0.033 0.329 0.050 0.5 0.050 1.378 0 2.5-1.122 2.5-2.5s-1.122-2.5-2.5-2.5-2.5 1.122-2.5 2.5c0 0.816 0.393 1.542 1 1.999v6.189c0 0.416 0.174 0.792 0.476 1.030 0.218 0.172 0.49 0.262 0.777 0.262 0.111 0 0.223-0.013 0.336-0.041l3.677-0.888 2.885 2.808c0.097 0.094 0.223 0.142 0.349 0.142 0.128 0 0.256-0.049 0.354-0.146l1-1c0.195-0.195 0.195-0.512 0-0.707zM9.5 2c0.827 0 1.5 0.673 1.5 1.5s-0.673 1.5-1.5 1.5c-0.827 0-1.5-0.673-1.5-1.5s0.673-1.5 1.5-1.5z" fill={color}></path>
<path d="M7.5 20c-3.584 0-6.5-2.916-6.5-6.5 0-1.547 0.552-3.045 1.556-4.22 0.992-1.161 2.363-1.939 3.862-2.191 0.272-0.046 0.53 0.138 0.576 0.41s-0.138 0.53-0.41 0.576c-2.655 0.446-4.583 2.727-4.583 5.424 0 3.033 2.467 5.5 5.5 5.5 2.697 0 4.978-1.927 5.424-4.583 0.046-0.272 0.304-0.456 0.576-0.41s0.456 0.303 0.41 0.576c-0.251 1.498-1.029 2.87-2.191 3.862-1.174 1.003-2.673 1.556-4.22 1.556z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Wheelchair); 
 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Gun = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M19.5 4h-0.5v-0.5c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v0.5h-14.5c-0.189 0-0.363 0.107-0.447 0.276l-1 2c-0.061 0.122-0.069 0.264-0.023 0.392 0.086 0.24 0.207 0.5 0.334 0.774 0.175 0.376 0.364 0.782 0.49 1.192 0.002 0.008 0.005 0.016 0.007 0.024 0.085 0.281 0.139 0.564 0.139 0.841 0 0.746-0.551 1.563-1.188 2.508-0.849 1.26-1.812 2.687-1.812 4.492 0 0.276 0.224 0.5 0.5 0.5h7c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-0.473c0.124-1.215 0.661-2.695 1.239-4h3.233c0.763 0 1.549-0.567 1.791-1.291l0.57-1.709h3.64c0.827 0 1.5-0.673 1.5-1.5v-0.5h0.5c0.276 0 0.5-0.224 0.5-0.5v-2c0-0.276-0.224-0.5-0.5-0.5zM8 5h5v1h-5v-1zM2.641 12.567c0.699-1.036 1.359-2.015 1.359-3.067 0-0.168-0.014-0.335-0.039-0.5h4.678c-0.792 1.438-2.404 4.616-2.616 7h-4.992c0.158-1.279 0.895-2.372 1.61-3.433zM12.342 10.393c-0.106 0.318-0.507 0.607-0.842 0.607h-2.768c0.441-0.906 0.849-1.639 1.055-2h0.223c0.040 0.413 0.173 0.967 0.569 1.34 0.097 0.091 0.22 0.136 0.343 0.136 0.133 0 0.265-0.053 0.364-0.157 0.189-0.201 0.18-0.517-0.021-0.707-0.145-0.137-0.215-0.384-0.247-0.613h1.789l-0.464 1.393zM19 6h-0.5c-0.276 0-0.5 0.224-0.5 0.5v1c0 0.276-0.224 0.5-0.5 0.5h-3.989c-0.001 0-0.001 0-0.002 0h-9.816c-0.13-0.35-0.283-0.68-0.422-0.978-0.082-0.175-0.16-0.344-0.224-0.497l0.762-1.525h3.191v1.5c0 0.276 0.224 0.5 0.5 0.5h6c0.276 0 0.5-0.224 0.5-0.5v-1.5h5v1z" fill={color}></path>
<path d="M4.5 7c-0.132 0-0.26-0.053-0.353-0.147s-0.147-0.222-0.147-0.353 0.053-0.261 0.147-0.353c0.093-0.093 0.222-0.147 0.353-0.147s0.261 0.053 0.353 0.147c0.093 0.093 0.147 0.222 0.147 0.353s-0.053 0.26-0.147 0.353c-0.093 0.093-0.222 0.147-0.353 0.147z" fill={color}></path>
<path d="M3.5 15c-0.132 0-0.26-0.053-0.353-0.147s-0.147-0.222-0.147-0.353 0.053-0.261 0.147-0.353c0.093-0.093 0.222-0.147 0.353-0.147s0.261 0.053 0.353 0.147c0.093 0.093 0.147 0.222 0.147 0.353s-0.053 0.26-0.147 0.353c-0.093 0.093-0.222 0.147-0.353 0.147z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Gun); 
import { createContext, useState } from "react";

interface OpenDropdownProviderProps {
  children: React.ReactNode;
}

interface OpenDropdownContext {
  openDropdownId: string | null | number;
  setOpenDropdownId: React.Dispatch<
    React.SetStateAction<string | null | number>
  >;
}

export const OpenDropdownContext = createContext<OpenDropdownContext>({
  openDropdownId: null,
  setOpenDropdownId: () => {},
});

export const OpenDropdownProvider = ({
  children,
}: OpenDropdownProviderProps) => {
  const [openDropdownId, setOpenDropdownId] = useState<string | null | number>(
    null
  );

  return (
    <OpenDropdownContext.Provider value={{ openDropdownId, setOpenDropdownId }}>
      {children}
    </OpenDropdownContext.Provider>
  );
};

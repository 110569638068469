import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { Merchant } from "src/api/types";
import useAuthorizedApi, {
  AuthorizedApi,
} from "src/helpers/hooks/app/useAuthorizedApi";
import { QueryId, getQueryKey } from "src/queries/queries";

import { getData } from "src/api/authorizedApi";
import { CollectionListDto } from "src/api/types";
import { SortStateSimple } from "../../components/storybook/types";

const getCampaigns = async (api: AuthorizedApi, merchantId: string) => {
  const response = await api.GET(
    "/api/v0/listing/{merchant_id}/scollections/type/{collection_type}",
    {
      params: {
        path: {
          merchant_id: merchantId,
          collection_type: "campaign",
        },
      },
    }
  );

  const data = getData(response);

  return data;
};

export function useCampaigns(
  merchant: Merchant | null,
  sorting: SortStateSimple = SortStateSimple.LATEST
): {
  collections: CollectionListDto[] | null;
  isLoading: boolean;
  isError: boolean;
} {
  const { api } = useAuthorizedApi();
  const query = useQuery({
    queryKey: getQueryKey(QueryId.GetCampaigns, merchant?.id || ""),
    queryFn: async () => {
      if (!merchant || !merchant.id || !api) return;

      const campaigns = await getCampaigns(api, merchant.id);

      return campaigns;
    },
    enabled: !!merchant && !!merchant.id && !!api,
  });

  const sortedCollections = useMemo(() => {
    if (query.data) {
      return [...query.data].sort((a, b) => {
        if (sorting === SortStateSimple.LATEST) {
          const timeB = b.updated_at ? new Date(b.updated_at).getTime() : 0;
          const timeA = a.updated_at ? new Date(a.updated_at).getTime() : 0;

          return timeB - timeA;
        }
        if (sorting === SortStateSimple.ABC) {
          return a.title.localeCompare(b.title);
        }
        return 0;
      });
    }
    return query.data;
  }, [query.data, sorting]);

  return {
    collections: sortedCollections ?? null,
    isLoading: query.isLoading,
    isError: query.isError,
  };
}

import { Product } from "../../types";
import { OnSelect } from "../../BulkEditBar/BulkEditBar.types";
import { MetadataInner } from "src/api/types";
import useSelectionHandler from "../../../../helpers/hooks/useSelectionHandler";
import { useCatalogMetadata } from "../../../../helpers/hooks/catalog/useCatalogMetadata";
import { ProductMetadata } from "../../../../helpers/catalog/ProductMetadataProvider";
import { metadataTypeBadgePositionMap } from "../../../../helpers/catalog/productMetadataToTextTransformers";
import { theme } from "../../designSystemVariables";
import { noSelectCss, noWrapCss } from "../../css.helper";
import { common } from "../../common";
import { ColorDot } from "../../ColorDot/ColorDot";
import { CurvyCorner } from "../../CurvyCorner/CurvyCorner";
import ProductBadges from "../../ProductBadges/ProductBadges";
import {
  Image,
  SelectionCorner,
} from "src/components/storybook/Categories/CategoryProductGridCard/CategoryProductGridCard";
import { useCallback, useMemo } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useImageResize } from "../../ImageResizeProvider/ImageResizeProvider";
import Dropdown from "../../Dropdown/Dropdown";
import { Eye, EyeClosed } from "@phosphor-icons/react";

export const ImageContainer = styled.div`
  position: relative;
`;

export interface CatalogProductGridCardProps {
  linkToProductDetails: string;
  index: number | undefined;
  product: Product;
  onSelectClick?: OnSelect | undefined;
  showSelectCheckbox?: boolean;
  isSelected?: boolean;
  highlight: boolean;
  isInSelectMode: boolean;
  oosAutoHideState: boolean;
}

type MetadataType = MetadataInner[number]["type"];

const CatalogRelevantMetadataTypes: MetadataType[] = [
  "out_of_stock",
  "low_stock",
  "on_sale",
  "new_in",
  "bestseller",
  "trending",
  "slow_mover",
  "inactive",
];

export function CatalogProductGridCard(props: CatalogProductGridCardProps) {
  const { getImageUrl } = useImageResize();
  const selectionHandler = useSelectionHandler(
    props.onSelectClick,
    Boolean(props.isSelected),
    props.product.main_product_id
  );
  const { productMetadata: allProductMetadata } = useCatalogMetadata();
  const productMetadata: ProductMetadata[] = useMemo(() => {
    return allProductMetadata?.[props.product.main_product_id] ?? [];
  }, [allProductMetadata, props.product.main_product_id]);

  const CatalogRelevantMetadata = productMetadata.filter((md) =>
    CatalogRelevantMetadataTypes.includes(md.type)
  );
  const topRowBadgeMetadata = CatalogRelevantMetadata.filter((md) => {
    if (!md.type) return false;

    return metadataTypeBadgePositionMap[md.type] === "top";
  });
  const bottomRowBadgeMetadata = CatalogRelevantMetadata.filter((md) => {
    if (!md.type) return false;

    return metadataTypeBadgePositionMap[md.type] === "bottom";
  });

  const badgeContainerBaseStyle: React.CSSProperties = {
    position: "absolute",
    display: "flex",
    zIndex: 2,
    gap: "0.5rem",
    margin: "0.5rem",
    flexWrap: "wrap",
  };

  const image = Image({
    imageUrl: getImageUrl(props.product.image_url || "", 600),
  });

  const getOutline = useCallback(() => {
    if (props.isSelected) {
      return `2px solid ${theme.border.base.default}`;
    } else if (props.highlight) {
      return `2px solid ${theme.border.accent.default}`;
    } else {
      return ``;
    }
  }, [props.highlight, props.isSelected]);

  const is_product_oos = productMetadata.some((p) => p.type === "out_of_stock");

  return (
    <div
      css={[
        noSelectCss,
        `
          user-select: none;
          display: flex;
          flex-direction: column;
          padding: 0.5rem;
          gap: 12px;
          position: relative;
          border-radius: ${common.cardBorderRadius};
          background-color: ${theme.background.base.default};
          border: 1px solid #E4E4E5;
          outline: ${getOutline()};
          margin: 4px;
        `,
      ]}
      onClick={selectionHandler}
    >
      <ImageContainer>
        {image}
        {props.showSelectCheckbox && (
          <SelectionCorner
            {...props}
            actionCBs={{ onSelectClick: props.onSelectClick }}
          />
        )}
        {props.product.color_name && props.product.color_hex && (
          <ColorDot
            hexCode={props.product.color_hex}
            name={props.product.color_name}
            backgroundColor={theme.background.base.default}
          />
        )}
        {is_product_oos && props.oosAutoHideState && (
          <CurvyCorner
            corner={"top-right"}
            borderRadius={"0.75em"}
            contentContainerStyles={{
              borderTopRightRadius: "0.5em",
            }}
            backgroundColor="white"
          >
            <div
              css={css`
                display: ${props.isInSelectMode ? "none" : "block"};
                padding-left: 0.4rem;
                padding-bottom: 0.3rem;
              `}
            >
              <EyeClosed />
            </div>
          </CurvyCorner>
        )}
        <div
          style={{
            ...badgeContainerBaseStyle,
            bottom: 0,
            left: 0,
          }}
        ></div>
        {!(
          bottomRowBadgeMetadata.length || topRowBadgeMetadata.length
        ) ? null : (
          <CurvyCorner
            corner="bottom-left"
            backgroundColor={theme.background.base.default}
          >
            <div
              style={{
                marginTop: "0.32em",
                marginRight: "0.32em",
                display: "flex",
                flexDirection: "column",
                gap: "0.32em",
              }}
            >
              <ProductBadges
                metadata={[...bottomRowBadgeMetadata, ...topRowBadgeMetadata]}
                oos_auto_hide={props.oosAutoHideState}
              />
            </div>
          </CurvyCorner>
        )}
      </ImageContainer>
      <div
        css={[
          css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
          `,
          css`
            flex-grow: 1;
            white-space: nowrap;
          `,
        ]}
      >
        <div css={noWrapCss}>
          <div
            title={props.product.title}
            css={css(
              theme.typography.paragraph[200].heavy,
              `
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: ${theme.textIcon.base.default}
              `
            )}
          >
            {props.product.title}
          </div>
          <div
            title={props.product.main_product_id}
            css={css(
              theme.typography.paragraph[300].light,
              css`
                color: ${theme.textIcon.subtle.default};
              `
            )}
          >
            {props.product.main_product_id}
          </div>
        </div>
        <Dropdown
          closeWhenOtherDropdownOpens={true}
          key_={props.product.main_product_id}
          items={[
            {
              text: "View details",
              link: props.linkToProductDetails,
              icon: <Eye />,
            },
          ]}
          dotSize={24}
          toggleStyleOverride={{
            color: theme.textIcon.base.default,
          }}
        />
      </div>
    </div>
  );
}

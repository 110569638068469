 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const GestureZoom = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M18 8c-0.412 0-0.794 0.125-1.113 0.339-0.274-0.779-1.016-1.339-1.887-1.339-0.412 0-0.794 0.125-1.113 0.339-0.274-0.779-1.016-1.339-1.887-1.339-0.364 0-0.706 0.098-1 0.269v-3.269c0-1.103-0.897-2-2-2s-2 0.897-2 2v7.373l-1.346-2.333c-0.261-0.475-0.687-0.813-1.199-0.953-0.499-0.136-1.018-0.064-1.462 0.202-0.907 0.544-1.253 1.774-0.77 2.742 0.030 0.061 0.668 1.368 2.66 5.35 0.938 1.875 1.967 3.216 3.059 3.984 0.857 0.603 1.449 0.634 1.559 0.634h5c0.848 0 1.632-0.245 2.331-0.73 0.676-0.468 1.259-1.152 1.734-2.033 0.939-1.743 1.435-4.246 1.435-7.237 0-1.103-0.897-2-2-2zM17.685 16.763c-0.549 1.021-1.548 2.237-3.185 2.237h-4.99c-0.039-0.003-0.46-0.050-1.095-0.525-0.633-0.474-1.605-1.472-2.638-3.54-2.027-4.054-2.65-5.331-2.656-5.343-0.001-0.002-0.001-0.003-0.002-0.004-0.251-0.503-0.073-1.162 0.389-1.439 0.208-0.125 0.451-0.158 0.685-0.095 0.249 0.068 0.458 0.236 0.587 0.472 0.002 0.003 0.004 0.007 0.006 0.010l1.561 2.705c0.319 0.583 0.678 0.828 1.067 0.729 0.39-0.099 0.587-0.489 0.587-1.157v-7.812c0-0.551 0.449-1 1-1s1 0.449 1 1v6.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-1.5c0-0.551 0.449-1 1-1s1 0.449 1 1v1.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-0.5c0-0.551 0.449-1 1-1s1 0.449 1 1v1.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-0.5c0-0.551 0.449-1 1-1s1 0.449 1 1c0 2.826-0.455 5.165-1.315 6.763z" fill={color}></path>
<path d="M5.5 0h-2c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h0.793l-3.293 3.293v-0.793c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v2c0 0.276 0.224 0.5 0.5 0.5h2c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-0.793l3.293-3.293v0.793c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-2c0-0.276-0.224-0.5-0.5-0.5z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(GestureZoom); 
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { components } from "src/api/generated/openapi";
import { QueryId, getQueryKey } from "src/queries/queries";
import { useDebouncedCallback } from "use-debounce";
import { SortModel } from "src/api/types";

export type GetCategorySearchResultParams = {
  merchantId: string;
  categoryId: string;
  query: string;
  cursor: string | undefined | null;
  productsToExclude: string[] | undefined;
  productsToInclude: string[];
  sortBy: SortModel | undefined;
};

type Response = components["schemas"]["SearchProductsResponse"];

export type GetCategorySearchResult = ({
  query,
  cursor,
  sortBy,
}: GetCategorySearchResultParams) => Promise<Response | undefined>;

const DEBOUNCE_TIME = 350; // ms

interface SearchProps {
  cursor: string | undefined | null;
  query: string | undefined | null;
}

export function useCategorySearch(
  merchantId: string | null,
  categoryId: string | null,
  getCategorySearchResult: GetCategorySearchResult,
  productsToExclude: string[] | undefined,
  productsToInclude: string[],
  sortBy: SortModel | undefined
) {
  const [searchState, setSearchState] = useState<SearchProps>({
    cursor: undefined,
    query: undefined,
  });

  const _getCategorySearchResult = useCallback(async () => {
    if (!merchantId || !categoryId) return;

    const response = await getCategorySearchResult({
      query: searchState.query || "",
      cursor: searchState.cursor,
      merchantId,
      categoryId,
      productsToExclude,
      productsToInclude,
      sortBy,
    });

    return response;
  }, [
    categoryId,
    getCategorySearchResult,
    merchantId,
    productsToExclude,
    productsToInclude,
    searchState.cursor,
    searchState.query,
    sortBy,
  ]);

  const { data, isLoading, refetch, isFetching } = useQuery({
    queryKey: getQueryKey(
      QueryId.GetCategorySearchResults,
      merchantId || "",
      categoryId || ""
    ),
    queryFn: _getCategorySearchResult,
    enabled: false, // Turn off automatic refetching, control it manually with refetch
    gcTime: 0, // Don't cache the results
  });

  useEffect(() => {
    if (!searchState.query) {
      return;
    }

    refetch();
  }, [refetch, searchState.query]);

  const setSearchQuery = useCallback(async (query: string) => {
    setSearchState((prev) => {
      return {
        query,
        cursor: undefined,
      };
    });
  }, []);

  const loadMoreSearchResults = async () => {
    if (searchState.cursor && searchState.query) {
      const { data: results } = await refetch();

      setSearchState((prev) => {
        return {
          query: prev.query,
          cursor: results?.cursor,
        };
      });
    }
  };

  const hasMoreSearchResults = useMemo(() => {
    return (
      searchState.query && searchState.query.length > 0 && searchState.cursor
    );
  }, [searchState.query, searchState.cursor]);

  const debounced = useDebouncedCallback((value: string) => {
    return setSearchQuery(value);
  }, DEBOUNCE_TIME);

  const resetSearchQuery = useCallback(() => {
    debounced.cancel();
    setSearchState({
      cursor: undefined,
      query: undefined,
    });
  }, [debounced]);

  const searchedProducts =
    searchState.query === undefined ? null : data?.products;
  const searchedProductsHits =
    searchState.query === undefined ? null : data?.hits;

  return {
    setSearchQuery: debounced,
    loadMoreSearchResults,
    hasMoreSearchResults,
    resetSearchQuery,
    searchedCategoryProducts: searchedProducts,
    searchedCategoryProductsHits: searchedProductsHits,
    isLoading: isLoading || isFetching,
    isFetchingMore: searchState.cursor !== undefined && isFetching,
  };
}

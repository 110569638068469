import { css } from "@emotion/react";
import {
  Circle,
  MagnifyingGlass,
  Plus,
  Square,
  SquaresFour,
  X,
} from "@phosphor-icons/react";
import { useRef, useState } from "react";
import { Badge, Button, Dropdown, Spinner } from "react-bootstrap";
import { OnAddListingItem } from "src/components/navs/types";
import useOwnCategories from "src/helpers/hooks/categories_v2_v3_common/useOwnCategories";
import { CollectionType } from "src/api/types";
import { theme } from "../designSystemVariables";
import { ListingSorting } from "./types";
import ListingTreeTypeButton, { getFolderName } from "./ListingTreeTypeButton";
import { useDragLayer } from "react-dnd";
import { ItemTypes } from "./ListingTreeItem";

export default function ListingTreeMenuHeader({
  searchQuery,
  onSetSearchQuery,
  sorting,
  onSetSorting,
  onAddListingItem,
  filteredType,
  onSetFilteredType,
  availableFilterTypes,
  isLoading,
  onSetType,
}: {
  searchQuery: string;
  onSetSearchQuery: (value: string) => void;
  sorting: ListingSorting;
  onSetSorting: (value: ListingSorting) => void;
  onAddListingItem: OnAddListingItem;
  availableFilterTypes: CollectionType[];
  filteredType: CollectionType | null;
  onSetFilteredType: (value: CollectionType | null) => void;
  isLoading?: boolean;
  onSetType: (value: CollectionType, listingItemId: string) => void;
}) {
  const [searchIsFocused, setSearchIsFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const ownCategories = useOwnCategories();

  const { isDragging } = useDragLayer((monitor) => ({
    isDragging:
      monitor.isDragging() && monitor.getItemType() === ItemTypes.LISTING_ITEM,
  }));

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center p-2">
        <div className="d-flex align-items-center">
          <SquaresFour size={16} className="me-2" />
          <span
            css={css`
              color: #868689;
              font-weight: bold;
            `}
          >
            Product Lists
          </span>
        </div>
        {isLoading ? (
          <Spinner
            css={css`
              width: 26px;
              height: 26px;
              margin: 0;
              padding: 0;
            `}
          />
        ) : (
          <Dropdown id="add-listing-item">
            <Dropdown.Toggle
              className="rounded-circle p-1"
              css={css`
                text-decoration: none !important;
                justify-content: flex-start !important;
                &:after {
                  display: none !important;
                }
              `}
            >
              <Plus size={16} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                disabled={!ownCategories}
                onClick={() => {
                  onAddListingItem("category");
                  onSetFilteredType(
                    filteredType !== "category" ? null : "category"
                  );
                }}
              >
                <Square className="me-2" />
                Add category
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  onAddListingItem("campaign");
                  onSetFilteredType(
                    filteredType !== "campaign" ? null : "campaign"
                  );
                }}
              >
                <Circle className="me-2" />
                Add campaign
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
      <div className="px-2 d-flex align-items-center">
        {filteredType === null || isDragging ? (
          <>
            {availableFilterTypes
              .sort((a, b) => {
                if (a === "smart_pick") return -1; // "SmartPick" comes first
                if (b === "smart_pick") return 1; // "SmartPick" comes first
                return a.localeCompare(b); // Sort the other types alphabetically
              })
              .map((type) => (
                <ListingTreeTypeButton
                  onSetType={onSetType}
                  type={type}
                  filteredType={filteredType}
                  onSetFilteredType={onSetFilteredType}
                />
              ))}
          </>
        ) : (
          <>
            <Button
              variant="outline-secondary"
              className="p-1 m-0 rounded-circle me-1"
              onClick={() => {
                onSetFilteredType(null);
              }}
              style={{
                width: "24px",
                height: "24px",
              }}
            >
              <X />
            </Button>
            <Badge
              bg="primary"
              className="p-1 m-0 rounded-pill me-1 text-white"
              style={{ fontSize: "10px", height: "24px", lineHeight: "16px" }}
            >
              {getFolderName(filteredType)}
            </Badge>
          </>
        )}
      </div>
      <div className="p-2 d-flex justify-content-between align-items-center">
        <div
          className={`d-flex p-1 align-items-center rounded text-xs ${
            searchIsFocused ? "bg-gray-300" : ""
          }`}
          style={{ marginRight: "8px" }}
        >
          {" "}
          <MagnifyingGlass
            width="16px"
            height="16px"
            color={
              searchIsFocused
                ? theme.textIcon.subtle.default
                : theme.textIcon.base.default
            }
            className="me-1"
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }}
          />
          <input
            ref={inputRef}
            className={`border-0 w-100 p-0 ${
              searchIsFocused ? "text-gray-600" : "text-gray-900"
            }`}
            style={{
              minWidth: "0",
              background: "transparent",
              outline: "none",
            }}
            type="text"
            value={searchQuery}
            onChange={(e) => onSetSearchQuery(e.target.value)}
            onFocus={() => setSearchIsFocused(true)}
            onBlur={() => setSearchIsFocused(false)}
            placeholder={!searchIsFocused ? "" : "Search in your product lists"}
          />{" "}
        </div>
        <Dropdown id={`dropdown-sorting`} className="text-xs">
          <Dropdown.Toggle
            variant="link"
            className="p-0"
            style={{ textDecoration: "none" }}
          >
            {sorting.field === "title" && "Alphabetical"}
            {sorting.field === "nProducts" && "Number of products"}
            {sorting.field === "updatedAt" &&
              sorting.direction === "desc" &&
              "Recent"}
            {sorting.field === "updatedAt" &&
              sorting.direction === "asc" &&
              "Oldest"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                onSetSorting({ field: "title", direction: "asc" });
              }}
            >
              Alphabetical (A-Z)
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                onSetSorting({ field: "title", direction: "desc" });
              }}
            >
              Alphabetical (Z-A)
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                onSetSorting({ field: "updatedAt", direction: "desc" });
              }}
            >
              Recent
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                onSetSorting({ field: "updatedAt", direction: "asc" });
              }}
            >
              Oldest
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                onSetSorting({ field: "nProducts", direction: "asc" });
              }}
            >
              Number of products (ascending)
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                onSetSorting({ field: "nProducts", direction: "desc" });
              }}
            >
              Number of products (descending)
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Citrus = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M19.176 4.517c-0.566-1.396-1.398-2.648-2.471-3.722-0.094-0.094-0.221-0.146-0.354-0.146s-0.26 0.053-0.354 0.146l-15.203 15.203c-0.094 0.094-0.146 0.221-0.146 0.354s0.053 0.26 0.146 0.354c1.074 1.074 2.326 1.905 3.722 2.471 1.348 0.547 2.772 0.824 4.233 0.824s2.885-0.277 4.233-0.824c1.396-0.566 2.648-1.397 3.722-2.471s1.905-2.326 2.471-3.722c0.547-1.348 0.824-2.772 0.824-4.233s-0.277-2.885-0.824-4.233zM6.833 11.374c0.356 0.261 0.754 0.439 1.167 0.537v4.046c-1.444-0.149-2.851-0.729-4.011-1.74l2.843-2.843zM10.654 7.553c0.546 0.869 0.442 2.032-0.313 2.788-0 0-0 0-0 0s-0 0-0 0c-0.756 0.756-1.919 0.86-2.788 0.313l3.102-3.102zM9 11.989c0.59-0.045 1.169-0.25 1.667-0.614l2.843 2.843c-1.293 1.128-2.894 1.719-4.511 1.774v-4.003zM14.218 13.511l-2.843-2.843c0.365-0.498 0.569-1.078 0.614-1.667h4.007c-0.056 1.676-0.679 3.253-1.777 4.511zM11.911 8c-0.097-0.413-0.276-0.811-0.537-1.167l2.843-2.843c0.988 1.132 1.592 2.522 1.744 4.011h-4.050zM15.998 15.998c-1.936 1.936-4.51 3.002-7.248 3.002-2.571 0-4.997-0.94-6.886-2.657l1.417-1.417c1.51 1.341 3.433 2.074 5.469 2.074 2.204 0 4.275-0.858 5.834-2.416s2.416-3.63 2.416-5.834c0-0.083-0.002-0.167-0.004-0.25 0-0 0-0 0-0s-0-0.001-0-0.002c-0.058-1.943-0.784-3.77-2.070-5.218l1.417-1.417c1.717 1.889 2.657 4.315 2.657 6.886 0 2.738-1.066 5.312-3.002 7.248z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Citrus); 
import React from "react";
import Spinner from "../Spinner/Spinner";
import { Table } from "react-bootstrap";
import { css } from "@emotion/react";
import { theme } from "../designSystemVariables";

type SearchStatRow = {
  query: string;
  count: number;
};

interface SearchStatsProps {
  loading: boolean;
  mostCommonQueries: SearchStatRow[];
  mostCommonQueriesWithoutResults: SearchStatRow[];
}

// Most common queries are filtered late to allow the count to be summed into the total market first
const MIN_SEARCHES_COUNT = 3;
export const MAX_QUERIES = 15;

function SearchStatsTable({
  stats,
  title,
  loading,
}: {
  stats: SearchStatRow[];
  title: string;
  loading: boolean;
}) {
  const loadingContainerCSS = css`
    height: 17.5em;
    background: linear-gradient(white 0%, white 70%, transparent 100%);
    border-bottom: none !important;
  `;
  return (
    <div
      className="depict--hightlightCard h-100"
      css={loading && loadingContainerCSS}
    >
      <div className="h-100">
        <Table
          className="m-0"
          style={{ height: "1px" /* https://stackoverflow.com/a/56913789 */ }}
        >
          <thead
            css={css`
              color: ${theme.textIcon.subtle.default};
              position: relative;
              background: ${theme.background.subtle.default};
              border-top: 1px solid ${theme.border.subtle.disabled};
            `}
          >
            <tr>
              <th className="px-3 flex-column" style={{ position: "relative" }}>
                <div className="d-flex ps-0 align-items-center justify-content-between w-100">
                  {title}
                </div>
              </th>
              <th className="px-3 flex-column" style={{ position: "relative" }}>
                <div className="d-flex ps-0 align-items-center justify-content-end w-100">
                  Volume
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              stats
                .filter((r) => r.count >= MIN_SEARCHES_COUNT)
                .map((query) => (
                  <tr key={query.query}>
                    <td className="px-3 text-start w-100">{query.query}</td>
                    <td className="px-3 text-start w-100">
                      {query.count.toLocaleString()}
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
        {loading && (
          <div
            css={css`
              width: 100%;
              height: 17.5em;
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          >
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
}

export function SearchStats({
  loading,
  mostCommonQueries,
  mostCommonQueriesWithoutResults,
}: SearchStatsProps) {
  return (
    <div className="row">
      <div className="col-lg-6 mb-2b mb-xxl-3">
        <h4 className="text-gray-700 mb-3">Most common searches</h4>
        <SearchStatsTable
          stats={mostCommonQueries}
          loading={loading}
          title="Search"
        />
      </div>
      <div className="col-lg-6 mb-2b mb-xxl-3">
        <h4 className="text-gray-700 mb-3">
          Most common searches without results
        </h4>
        <SearchStatsTable
          loading={loading}
          stats={mostCommonQueriesWithoutResults}
          title="Search"
        />
      </div>
    </div>
  );
}

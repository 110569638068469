import { css } from "@emotion/react";
import { Check, Copy } from "@phosphor-icons/react";
import { FC, useState } from "react";
import { FieldErrors, FieldPath, useFormContext } from "react-hook-form";
import { CollectionMetadataSchema } from "src/components/storybook/Categories/categories.schemas";
import { theme } from "../../storybook/designSystemVariables";

interface CategoryConfigFieldProps {
  name: FieldPath<CollectionMetadataSchema>;
  label: string;
  copyToClipboard?: boolean;
  getErrorMessage: (
    errors: FieldErrors<CollectionMetadataSchema>
  ) => string | undefined;
}

export const CategoryConfigField: FC<CategoryConfigFieldProps> = ({
  name,
  label,
  copyToClipboard,
  getErrorMessage,
}) => {
  const form = useFormContext<CollectionMetadataSchema>();

  const [showCheckIcon, setShowCheckIcon] = useState(false);

  const handleCopyClick = () => {
    const value = form.getValues(name);
    if (typeof value === "string") {
      navigator.clipboard.writeText(value);
    }
    setShowCheckIcon(true);
    setTimeout(() => setShowCheckIcon(false), 1000);
  };

  form.watch(name);

  const error = getErrorMessage(form.formState.errors);

  return (
    <>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.25rem;
        `}
      >
        <label
          css={css`
            font-weight: 700;
          `}
        >
          {label}
        </label>
        {copyToClipboard && (
          <>
            {showCheckIcon ? (
              <Check />
            ) : (
              <Copy
                onClick={handleCopyClick}
                css={css`
                  cursor: pointer;
                `}
              />
            )}
          </>
        )}
      </div>
      <input
        css={css`
          display: flex;
          padding: 0.75rem 0.75rem;
          border: 1px solid ${theme.border.subtle.default};
          border-radius: 6px;
          width: 100%;
          :focus-within {
            border-color: ${theme.border.neutral.default};
          }
        `}
        {...form.register(name)}
      />
      {error && (
        <div
          css={css`
            color: red;
            margin-top: 4px;
            font-size: 0.8rem; // Adjust as needed
          `}
        >
          {error}
        </div>
      )}
    </>
  );
};

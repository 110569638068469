 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const CloudDatabase = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M16 16h-1.5c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1.5c1.654 0 3-1.346 3-3s-1.346-3-3-3c-0.343 0-0.68 0.057-1 0.17-0.217 0.077-0.458-0.005-0.584-0.197s-0.105-0.446 0.051-0.614c0.344-0.371 0.533-0.854 0.533-1.359 0-1.103-0.897-2-2-2-1.055 0-1.931 0.823-1.996 1.875-0.013 0.212-0.159 0.393-0.363 0.45s-0.423-0.020-0.545-0.194c-0.937-1.334-2.468-2.131-4.096-2.131-2.757 0-5 2.243-5 5 0 2.605 1.954 4.746 4.544 4.98 0.275 0.025 0.478 0.268 0.453 0.543s-0.268 0.478-0.543 0.453c-1.486-0.134-2.861-0.815-3.874-1.917-1.019-1.109-1.58-2.551-1.58-4.058 0-3.308 2.692-6 6-6 1.611 0 3.143 0.65 4.261 1.776 0.471-1.050 1.527-1.776 2.739-1.776 1.654 0 3 1.346 3 3 0 0.346-0.059 0.685-0.172 1.004 0.057-0.002 0.115-0.004 0.172-0.004 2.206 0 4 1.794 4 4s-1.794 4-4 4z" fill={color}></path>
<path d="M11.953 9.329c-0.53-0.212-1.224-0.329-1.953-0.329s-1.424 0.117-1.953 0.329c-0.911 0.364-1.047 0.89-1.047 1.171v4c0 0.281 0.136 0.807 1.047 1.171 0.53 0.212 1.224 0.329 1.953 0.329s1.424-0.117 1.953-0.329c0.911-0.364 1.047-0.89 1.047-1.171v-4c0-0.281-0.136-0.807-1.047-1.171zM12 12.481c-0.087 0.155-0.779 0.519-2 0.519s-1.913-0.364-2-0.519v-0.829c0.015 0.006 0.031 0.013 0.047 0.019 0.53 0.212 1.224 0.329 1.953 0.329s1.424-0.117 1.953-0.329c0.016-0.006 0.031-0.013 0.047-0.019v0.829zM10 10c1.174 0 1.859 0.336 1.988 0.5-0.129 0.164-0.814 0.5-1.988 0.5s-1.859-0.336-1.987-0.5c0.129-0.164 0.814-0.5 1.987-0.5zM10 15c-1.219 0-1.911-0.363-2-0.518v-0.83c0.015 0.006 0.031 0.013 0.047 0.019 0.53 0.212 1.224 0.329 1.953 0.329s1.424-0.117 1.953-0.329c0.016-0.006 0.031-0.013 0.047-0.019v0.83c-0.089 0.155-0.781 0.518-2 0.518z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(CloudDatabase); 
import React, { useEffect, useState } from "react";
import { Magnifier, Cross } from "src/components/linearicons";
import ListConfigurations from "src/components/lists/Configurations";
import { ListItem, ListPanelProps } from "src/types/components";
import { getSearchHits, refactorForList } from "src/helpers/configuration";
import reactFastCompare from "react-fast-compare";

const ConfigurationsSearch = (props: ListPanelProps) => {
  const { items, selectedItem, listItemOnClickCB } = props;
  const [keyword, setKeyword] = useState<string>("");
  const [filteredItems, setFilteredItems] = useState<ListItem[] | null>(null);
  const [selectedListItem, setSelectedListItem] = useState<ListItem | null>(
    () => {
      return selectedItem
        ? (refactorForList([selectedItem]) as ListItem[])[0]
        : null;
    }
  );

  useEffect(() => {
    if (!selectedItem) {
      return;
    }

    setSelectedListItem((refactorForList([selectedItem]) as ListItem[])[0]);

    return () => {
      setSelectedListItem(null);
    };
  }, [selectedItem]);

  useEffect(() => {
    if (!items || !keyword) {
      setFilteredItems(refactorForList(items));
      return;
    }

    let results = getSearchHits(
      "configurations",
      "id",
      "display_name",
      items,
      keyword
    );

    let forList = refactorForList(results);
    setFilteredItems(forList);
  }, [items, keyword]);

  return (
    <>
      <div className="depict--ConfigurationsToolbar">
        <div className="row">
          <div className="col">
            <div className="input-group input-group-sm">
              <input
                data-tc="configurations-search-input"
                type="text"
                className="form-control"
                placeholder="Search"
                aria-label="Search"
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                style={{
                  border: "none",
                }}
              />
              <span className="input-group-text">
                {keyword ? (
                  <Cross
                    size="12"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setKeyword("");
                    }}
                  />
                ) : (
                  <Magnifier size={12} />
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
      <ListConfigurations
        items={filteredItems}
        listItemOnClickCB={listItemOnClickCB}
        selectedItem={selectedListItem}
      />
    </>
  );
};

export default React.memo(ConfigurationsSearch, reactFastCompare);

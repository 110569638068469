import { Product } from "../../types";

interface ProductGroup {
  id: string;
  mainProductIds: string[];
}

export type ProductsById = Record<string, Product>;
export type Products = {
  byId: ProductsById;
  allIds: Array<string>;
};
export type ProductGroupsById = Record<string, ProductGroup>;
export type ProductGroups = {
  byId: ProductGroupsById;
  allIds: Array<string>;
};
export type ProductGroupIds = string[];

export interface State {
  /*
   * The products state is an object with two properties:
   * byId: a record of products, keyed by their id
   * allIds: an array of product ids
   */
  products: Products;
  /*
   * The productGroups state is an object with two properties:
   * byId: a record of product groups, keyed by their id
   * allIds: an array of product group ids
   */
  productGroups: ProductGroups;
  /*
   * The productGroupIds state is an array of product group ids.
   * This is used to keep track of the order of the product groups.
   */
  productGroupIds: ProductGroupIds;
  /*
   * The loading state is a boolean that indicates whether the products are loading.
   * This is used to signal to the selectors that they should return the loading state.
   */
  loading: boolean;
}

export enum ActionType {
  DELETE_PRODUCTS_AND_RELATED_GROUPS = "DELETE_PRODUCTS_AND_RELATED_GROUPS",
  UPDATE_PRODUCTS = "UPDATE_PRODUCTS",
  ADD_PRODUCT_TO_GROUP = "ADD_PRODUCT_TO_GROUP",
  DISSOLVE_GROUP = "DISSOLVE_GROUP",
  REMOVE_PRODUCT_FROM_GROUP = "REMOVE_PRODUCT_FROM_GROUP",
  MOVE_PRODUCT_GROUP = "MOVE_PRODUCT_GROUP",
  UPDATE_PRODUCTS_AND_GROUPS = "UPDATE_PRODUCTS_AND_GROUPS",
  UPDATE_PRODUCTS_GROUPS = "UPDATE_PRODUCTS_GROUPS",
  MOVE_PRODUCT_GROUPS_TO_BEGINNING = "MOVE_PRODUCT_GROUPS_TO_BEGINNING",
  MOVE_PRODUCT_GROUPS_TO_END = "MOVE_PRODUCT_GROUPS_TO_END",
  UNDO_ALL_GROUPS = "UNDO_ALL_GROUPS",
}

export interface DeleteProductsAction {
  type: ActionType.DELETE_PRODUCTS_AND_RELATED_GROUPS;
  payload: string[];
}

export interface UpdateProductsAction {
  type: ActionType.UPDATE_PRODUCTS;
  payload: {
    productsToAdd: Product[];
    productsToDelete: string[];
  };
}

export interface AddProductToGroupAction {
  type: ActionType.ADD_PRODUCT_TO_GROUP;
  payload: {
    groupId: string;
    productId: string;
  };
}

export interface DissolveGroupAction {
  type: ActionType.DISSOLVE_GROUP;
  payload: {
    groupId: string;
  };
}

export interface RemoveProductFromGroupAction {
  type: ActionType.REMOVE_PRODUCT_FROM_GROUP;
  payload: {
    groupId: string;
    productId: string;
  };
}

export interface MoveProductGroupAction {
  type: ActionType.MOVE_PRODUCT_GROUP;
  payload: {
    oldIndex: number;
    newIndex: number;
  };
}

export interface UpdateProductsAndGroupsAction {
  type: ActionType.UPDATE_PRODUCTS_AND_GROUPS;
  payload: {
    products: Record<string, Product>;
    groups?: string[][];
  };
}

export interface UpdateProductsGroupsAction {
  type: ActionType.UPDATE_PRODUCTS_GROUPS;
  payload: {
    groups: string[][];
  };
}

export interface MoveProductGroupToBeginningAction {
  type: ActionType.MOVE_PRODUCT_GROUPS_TO_BEGINNING;
  payload: {
    mainProductIds: string[];
  };
}

export interface MoveProductGroupToEndAction {
  type: ActionType.MOVE_PRODUCT_GROUPS_TO_END;
  payload: {
    mainProductIds: string[];
  };
}

export interface UndoAllGroupsAction {
  type: ActionType.UNDO_ALL_GROUPS;
  payload: {};
}

export type Action =
  | DeleteProductsAction
  | UpdateProductsAction
  | AddProductToGroupAction
  | DissolveGroupAction
  | RemoveProductFromGroupAction
  | MoveProductGroupAction
  | UpdateProductsAndGroupsAction
  | UpdateProductsGroupsAction
  | MoveProductGroupToBeginningAction
  | MoveProductGroupToEndAction
  | UndoAllGroupsAction;

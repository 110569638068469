 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Eraser = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M19.715 6.913c-0.026-0.155-0.077-0.305-0.155-0.446-0.114-0.209-0.276-0.38-0.472-0.506-0.002-0.001-0.003-0.002-0.005-0.003-0.010-0.007-0.021-0.013-0.032-0.020-0.096-0.059-0.205-0.108-0.327-0.145-0.024-0.007-0.049-0.014-0.074-0.021l-6.060-1.515c-0.162-0.040-0.34-0.061-0.53-0.061-0.599 0-1.25 0.205-1.658 0.523l-7.421 5.772c-0.51 0.396-1.069 1.187-1.273 1.799l-1.368 4.103c-0.129 0.386-0.094 0.791 0.098 1.14s0.515 0.596 0.909 0.694l6.060 1.515c0.162 0.040 0.34 0.061 0.53 0.061 0.474 0 0.981-0.129 1.374-0.341 0.091-0.046 0.186-0.106 0.284-0.182l7.421-5.772c0.51-0.396 1.069-1.187 1.273-1.799l1.368-4.103c0.092-0.275 0.111-0.504 0.057-0.694zM12.062 5.197c0.107 0 0.206 0.011 0.287 0.031l6.033 1.508-7.4 5.755c-0.236 0.184-0.666 0.312-1.044 0.312-0.107 0-0.206-0.011-0.287-0.031l-6.033-1.508 7.4-5.755c0.236-0.184 0.666-0.312 1.044-0.312zM1.316 17.052c-0.056-0.102-0.065-0.224-0.026-0.343l1.368-4.103c0.092-0.276 0.408-0.45 0.691-0.379 0 0 0 0 0 0l6.060 1.515c0.121 0.030 0.219 0.103 0.275 0.205s0.065 0.224 0.026 0.343l-1.368 4.103c-0.092 0.276-0.409 0.45-0.691 0.379l-1.030-0.257c-0 0-0-0-0-0l-5.030-1.257c-0.121-0.030-0.219-0.103-0.275-0.206zM16.404 12.719l-6.907 5.372 1.162-3.485c0.143-0.429 0.581-1.049 0.938-1.326l6.907-5.372-1.162 3.485c-0.143 0.429-0.581 1.048-0.938 1.326z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Eraser); 
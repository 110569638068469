import { useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import Card from "src/components/storybook/Card/Card";
import { CentraCredentialsVerifcationResponse } from "../../Integrations/Centra/types";
import LoadingBadge from "../../LoadingBadge/LoadingBadge";
import IntegrationStepHeader from "../IntegrationStepHeader/IntegrationStepHeader";

export default function CentraApiConfig({
  isLoading,
  verifyCredentialsResponse,
  errorMessage,
  apiUrl,
  apiKey,
  onVerify,
  onSubmit,
  onBack,
}: {
  isLoading: boolean;
  verifyCredentialsResponse: CentraCredentialsVerifcationResponse | null;
  apiUrl: string;
  apiKey: string;
  errorMessage: string | null;
  onVerify: (apiUrl: string, apiKey: string) => void;
  onSubmit: (apiUrl: string, apiKey: string) => void;
  onBack: () => void;
}) {
  const [newApiUrl, setNewApiUrl] = useState<string>(apiUrl);
  const [newApiKey, setNewApiKey] = useState<string>(apiKey);

  return (
    <Card>
      <Card.Body>
        <IntegrationStepHeader
          title="Connect Centra to Depict"
          stepNumber={2}
        />
        <Card.Text>
          In order to connect you must provide the following URL and tokens with
          sufficient read permissions. View the docs{" "}
          <a href="https://docs.depict.ai/docs/centra-setup-guide">here</a>.
        </Card.Text>
        <Form.Group controlId="apiURL">
          <Form.Label>API URL</Form.Label>
          <Form.Control
            type="text"
            placeholder="https://example-store.centra.com/graphql"
            isValid={verifyCredentialsResponse?.status === "success"}
            isInvalid={!!verifyCredentialsResponse?.errors.apiUrlError}
            value={newApiUrl}
            onChange={(e) => setNewApiUrl(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="apiKey" className="mt-2">
          <Form.Label>API Key</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter API key"
            isValid={verifyCredentialsResponse?.status === "success"}
            isInvalid={!!verifyCredentialsResponse?.errors.apiKeyError}
            value={newApiKey}
            onChange={(e) => setNewApiKey(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="alertBanner" className="mt-3">
          {isLoading && <LoadingBadge label="Checking credentials" />}
          {verifyCredentialsResponse?.errors.apiUrlError && (
            <Alert variant="danger">
              {verifyCredentialsResponse.errors.apiUrlError}
            </Alert>
          )}
          {verifyCredentialsResponse?.errors.apiKeyError && (
            <Alert variant="danger">
              {verifyCredentialsResponse.errors.apiKeyError}
            </Alert>
          )}
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          {verifyCredentialsResponse?.status === "success" && (
            <Alert variant="success">
              Success! Your credentials are valid.
            </Alert>
          )}
        </Form.Group>
      </Card.Body>
      <Card.Footer>
        <Row>
          <Col>
            <Button
              variant="outline-secondary"
              className="w-100"
              onClick={onBack}
            >
              Back
            </Button>
          </Col>
          <Col>
            {verifyCredentialsResponse?.status === "success" ? (
              <Button
                variant="primary"
                className="w-100"
                onClick={() => {
                  onSubmit(newApiUrl, newApiKey);
                }}
              >
                Continue
              </Button>
            ) : (
              <Button
                variant="primary"
                className="w-100"
                disabled={newApiUrl === "" || newApiKey === ""}
                onClick={() => {
                  onVerify(newApiUrl, newApiKey);
                }}
              >
                Verify
              </Button>
            )}
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
}

import { DepictDataPoint, DepictMetricsData } from "src/types/metrics";
import { Configuration, ConfiguredProductGroup } from "src/types/configuration";
import { Product } from "../types/products";
import { Surface } from "src/types/surfaces";
import config from "src/config";
import { UniqueIdentifier } from "@dnd-kit/core";
import { MetricName } from "src/api/types";
import { Merchant } from "src/api/types";
import { paths } from "src/api/generated/openapi";

export type GetMetricApiV0MetricsTimeSeriesMetricNameGetRequest =
  paths["/api/v0/metrics/time-series/{metric_name}"]["get"]["parameters"]["query"];

export const capitalize = (
  [first, ...rest]: string,
  locale = navigator.language
) => {
  return first === undefined
    ? ""
    : first.toLocaleUpperCase(locale) + rest.join("");
};

export const getTotalOf = (
  dataset: Array<DepictDataPoint>,
  property: keyof DepictDataPoint
): number => {
  let total: number = 0;

  if (!dataset) {
    return total;
  }

  for (var i = 0; i < dataset.length; i++) {
    const record = dataset[i];
    const single = record[property];
    if (single) {
      total += single as number;
    }
  }

  return total;
};

//https://stackoverflow.com/questions/26795643/how-to-convert-object-containing-objects-into-array-of-objects
export const refactorToArrayOfObjects = (data: object): object[] => {
  return Object.entries(data).map((e) => ({
    [e[0]]: e[1],
  }));
};

export const formatGraphLeftAxis = (num: number): string => {
  if (num > 999 && num < 1000000) {
    const digitsToShow = num % 1000 === 0 ? 0 : 1; // we want 1000 => 1K but 1100 => 1.1K
    return (num / 1000).toFixed(digitsToShow) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num >= 1000000) {
    const digitsToShow = num % 1000000 === 0 ? 0 : 1; // we want 1 000 000 => 1M but 1 100 000 => 1.1M
    return (num / 1000000).toFixed(digitsToShow) + "M"; // convert to M for number from > 1 million
  } else {
    return num + ""; // if value < 1000, nothing to do
  }
};

export const formatNumber = ({
  number,
  prefix,
  suffix,
  digitsAfterComma,
  noSpaces,
}: {
  number: number;
  prefix?: string;
  suffix?: string;
  digitsAfterComma?: number;
  noSpaces?: boolean;
}): string => {
  let _number = number;

  if (digitsAfterComma !== undefined) {
    const fixer = Math.pow(10, digitsAfterComma);
    _number = Math.round((_number + Number.EPSILON) * fixer) / fixer;
  }

  let converted = noSpaces
    ? _number.toString()
    : _number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  if (prefix) {
    converted = prefix + converted;
  }

  if (suffix) {
    converted += suffix;
  }

  return converted;
};

/**
 * Json comparison is fast and enough for our needs.
 * https://www.mattzeunert.com/2016/01/28/javascript-deep-equal.html
 * This might also be an option if we need to modify: https://github.com/epoberezkin/fast-deep-equal
 */
export const deepEqual = (a: any, b: any) => {
  //When object are compared, it seems that the order of the properties actually matters.
  return JSON.stringify(a) === JSON.stringify(b);
};

const catchError = (error: any) => {
  throw new Error(error);
};

export const mockMetric = async (
  metric: MetricName,
  params?: GetMetricApiV0MetricsTimeSeriesMetricNameGetRequest
): Promise<DepictMetricsData> => {
  /**
   * Unfortunately importing files with paths obtained from computed properties (src/mock/metrics/ + metric + .js) are still not a thing.
   */
  return new Promise((resolve) => {
    switch (metric) {
      case "adds-to-cart":
        if (params?.start_timestamp === config.const.compareMockTrigger.start) {
          import("src/mock/metrics/compare-previous/adds-to-cart")
            .then((data: any) => {
              resolve(data.data);
            })
            .catch(catchError);
          break;
        }
        import("src/mock/metrics/adds-to-cart")
          .then((data: any) => {
            resolve(data.data);
          })
          .catch(catchError);
        break;
      case "checkouts":
        if (params?.start_timestamp === config.const.compareMockTrigger.start) {
          import("src/mock/metrics/compare-previous/checkouts")
            .then((data: any) => {
              resolve(data.data);
            })
            .catch(catchError);
          break;
        }
        import("src/mock/metrics/checkouts")
          .then((data: any) => {
            resolve(data.data);
          })
          .catch(catchError);
        break;
      case "clicks":
        if (params?.start_timestamp === config.const.compareMockTrigger.start) {
          import("src/mock/metrics/compare-previous/clicks")
            .then((data: any) => {
              resolve(data.data);
            })
            .catch(catchError);
          break;
        }
        import("src/mock/metrics/clicks")
          .then((data: any) => {
            resolve(data.data);
          })
          .catch(catchError);
        break;
      case "purchases":
        if (params?.start_timestamp === config.const.compareMockTrigger.start) {
          import("src/mock/metrics/compare-previous/purchases")
            .then((data: any) => {
              resolve(data.data);
            })
            .catch(catchError);
          break;
        }
        import("src/mock/metrics/purchases")
          .then((data: any) => {
            resolve(data.data);
          })
          .catch(catchError);
        break;
      case "revenue":
        if (params?.start_timestamp === config.const.compareMockTrigger.start) {
          import("src/mock/metrics/compare-previous/revenue")
            .then((data: any) => {
              resolve(data.data);
            })
            .catch(catchError);
          break;
        }
        import("src/mock/metrics/revenue")
          .then((data: any) => {
            resolve(data.data);
          })
          .catch(catchError);
        break;
      default:
        resolve({});
        break;
    }
  });
};

export const mockMerchants = async (): Promise<Merchant[]> => {
  return new Promise((resolve, reject) => {
    import("src/mock/merchants")
      .then((data: any) => {
        resolve(data.data);
      })
      .catch(catchError);
  });
};

export const mockProducts = async (): Promise<Product[]> => {
  return new Promise((resolve, reject) => {
    import("src/mock/products/products")
      .then((data: any) => {
        resolve(data.data);
      })
      .catch(catchError);
  });
};

export const mockSurfaces = async (): Promise<Surface[]> => {
  return new Promise((resolve, reject) => {
    import("src/mock/surfaces/surfaces")
      .then((data: any) => {
        resolve(data.data);
      })
      .catch(catchError);
  });
};

export const mockGroups = async (): Promise<ConfiguredProductGroup[]> => {
  return new Promise((resolve, reject) => {
    import("src/mock/groups/groups")
      .then((data: any) => {
        resolve(data.data);
      })
      .catch(catchError);
  });
};

export const mockConfigurations = async (): Promise<Configuration[]> => {
  return new Promise((resolve) => {
    import("src/mock/configurations/configurations")
      .then((data: any) => {
        resolve(data.data);
      })
      .catch(catchError);
  });
};

export const deepCopy = <T,>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj));
};

export const setRightSidebarOverlayClass = (status: boolean): void => {
  if (status) {
    document.body.classList.add("sideoverlayright-toggled");
  } else {
    document.body.classList.remove("sideoverlayright-toggled");
  }
};

export const getIndexByGroupingId = (
  id: UniqueIdentifier,
  items: Product[]
) => {
  return items.findIndex((item) => item.grouping_id === id);
};

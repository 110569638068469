export const common = {
  cardBorderRadius: "1rem",
  cardContentBorderRadius: "0.5rem",
};

export const gridColBreakpoints = {
  xs: 6,
  lg: 4,
  xxl: 3,
};

export const depictCardClassName = "depict-card";

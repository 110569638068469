import { useEffect, useCallback, useState } from "react";
import { deepCopy } from "src/helpers";
import { getRelationshipGroup } from "src/helpers/configuration";
import {
  ConfiguredProductGroup,
  Relationship,
  RelationshipType,
} from "src/types/configuration";
import { GroupModifyCB } from "src/types/components";

export default function useClearGroup(
  relationshipType: RelationshipType,
  side: "from_group" | "to_group",
  relationships: Relationship[],
  relationshipsGroups: ConfiguredProductGroup[],
  modifyGroup: GroupModifyCB
) {
  const [groupObject, setGroupObject] = useState<ConfiguredProductGroup | null>(
    null
  );

  useEffect(() => {
    const result = getRelationshipGroup(
      relationshipType,
      side,
      relationships,
      relationshipsGroups
    );

    if (!result) {
      return;
    }

    setGroupObject(result);
  }, [relationshipType, side, relationships, relationshipsGroups]);

  const groupClear = useCallback(() => {
    if (!groupObject) {
      return;
    }
    let clone = deepCopy(groupObject);
    clone.group_definition = [];
    modifyGroup(clone);
  }, [groupObject, modifyGroup]);

  return [groupClear];
}

import { useEffect, useRef, useState } from "react";

type RefState = HTMLElement | null;

const useRefQuerySelector = (selector: string): [RefState, Function] => {
  const [element, setElement] = useState<RefState>(null);
  const observer = useRef<MutationObserver>();

  useEffect(() => {
    observer.current = new MutationObserver(() => {
      const selectedElement = document.querySelector<HTMLElement>(selector);
      if (selectedElement) {
        setElement(selectedElement);
      }
    });

    observer.current.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.current?.disconnect();
    };
  }, [selector]);

  const updateRef = () => {
    const selectedElement = document.querySelector<HTMLElement>(selector);
    setElement(selectedElement);
  };

  return [element, updateRef];
};

export default useRefQuerySelector;

import "react-datepicker/dist/react-datepicker.css";
import mergeClassNames from "merge-class-names";
import DatePicker from "react-datepicker";
import { CalendarEmpty } from "src/components/linearicons";
import CustomInput from "src/components/mix/CustomInput";
import React, { useCallback, useMemo, useRef } from "react";
import { getConfigurationStartDate } from "src/helpers/configuration";

import { toUtc } from "src/helpers/dates";
import { useTranslation } from "react-i18next";
import { ConfigurationState } from "src/types/configuration";
import { ConfigurationModifyCB } from "src/types/components";
import reactFastCompare from "react-fast-compare";
import config from "src/config";

export const Schedule = (props: {
  startTimestamp: number | null;
  endTimestamp: number | null;
  state: ConfigurationState;
  configurationModifyCB: ConfigurationModifyCB;
}) => {
  const { startTimestamp, endTimestamp, state, configurationModifyCB } = props;
  const { t } = useTranslation();
  const startDatePickerRef = useRef<any>(null);
  const endDatePickerRef = useRef<any>(null);
  const minDate = useMemo(() => getConfigurationStartDate(), []);

  const modifyEnd = useCallback(
    (date: Date | null) => {
      configurationModifyCB({
        validity_end_timestamp: date ? Math.floor(+date / 1000) : null,
      });
    },
    [configurationModifyCB]
  );

  const modifyStart = useCallback(
    (date: Date | null) => {
      configurationModifyCB({
        validity_start_timestamp: date ? Math.floor(+date / 1000) : null,
      });
    },
    [configurationModifyCB]
  );

  const setState = useCallback(
    (state: ConfigurationState | null) => {
      if (!configurationModifyCB) {
        return;
      }
      configurationModifyCB({ state: state || undefined });
    },
    [configurationModifyCB]
  );

  const openStartDatePicker = useCallback(() => {
    const datepickerElement = startDatePickerRef.current;
    datepickerElement.setOpen(true);
  }, []);

  const openEndDatePicker = useCallback(() => {
    const datepickerElement = endDatePickerRef.current;
    datepickerElement.setOpen(true);
  }, []);

  const startDatepickerOnChange = useCallback(
    (date: Date | null) => {
      if (date) {
        date.setHours(0, 0, 0);
        modifyStart(toUtc(date));
        if (endTimestamp && Math.floor(+date / 1000) > endTimestamp) {
          modifyEnd(null);
        }
        setTimeout(() => {
          if (!endTimestamp) {
            openEndDatePicker();
          }
        }, 200);
      } else {
        if (!endTimestamp) {
          setState("inactive");
        }
        modifyStart(null);
      }
    },
    [endTimestamp, modifyEnd, modifyStart, openEndDatePicker, setState]
  );

  const endDatePickerOnChange = useCallback(
    (date: Date | null) => {
      if (date) {
        date.setHours(23, 59, 59);
        modifyEnd(date as Date);
        if (startTimestamp && Math.floor(+date / 1000) < startTimestamp) {
          modifyStart(null);
        }
      } else {
        if (!startTimestamp) {
          setState("inactive");
        }
        modifyEnd(null);
      }
    },
    [modifyEnd, modifyStart, setState, startTimestamp]
  );

  const clickOnSchedule = useCallback(() => {
    setState("scheduled");
    if (!startTimestamp) {
      modifyStart(toUtc(new Date()));
    }
    openStartDatePicker();
  }, [modifyStart, openStartDatePicker, setState, startTimestamp]);

  return (
    <>
      <div>
        <div className="depict--ConfigurationSettings__title">Schedule</div>
        <div className="depict--ConfigurationSettings__subtitle mb-2">
          Set for how long your configuration will be active.
        </div>
      </div>
      <div className="depict--ConfigurationSettings__form">
        <div
          className="form-check form-check-inline mb-2b me-4 d-xl-inline-block"
          data-bs-toggle="tooltip"
          title={t("tooltips.configuration.schedule.inactive")}
        >
          <input
            data-tc="radio-input-schedule-inactive"
            className="form-check-input"
            type="radio"
            id="radio-inactive"
            checked={state === "inactive"}
            onChange={() => {
              setState("inactive");
            }}
          />
          <label
            className="form-check-label"
            htmlFor="radio-inactive"
            data-tc="radio-label-schedule-inactive"
          >
            Inactive
          </label>
        </div>
        <div
          className="form-check form-check-inline mb-2b me-4 d-xl-inline-block"
          data-bs-toggle="tooltip"
          title={t("tooltips.configuration.schedule.indefinite")}
        >
          <input
            data-tc="radio-input-schedule-indefinite"
            className="form-check-input"
            type="radio"
            id="radio-indefinite"
            checked={state === "indefinite"}
            onChange={() => {
              setState("indefinite");
            }}
          />
          <label
            className="form-check-label"
            htmlFor="radio-indefinite"
            data-tc="radio-label-schedule-indefinite"
          >
            Indefinite
          </label>
        </div>
        <div className="form-check form-check-inline mb-2b me-4 d-xl-inline-block">
          <input
            data-tc="radio-input-schedule-schedule"
            className="form-check-input"
            type="radio"
            id="radio-scheduled"
            checked={state === "scheduled"}
            onChange={clickOnSchedule}
          />
          <label
            className="form-check-label"
            htmlFor="radio-scheduled"
            data-tc="radio-label-schedule-schedule"
          >
            Scheduled
          </label>
        </div>
        <div className="row">
          <div
            data-tc="start-date-datepicker"
            className={mergeClassNames([
              "col-xl-6 depict--datepickerWrapper",
              state !== "scheduled" && "depict--datepickerWrapper__disabled",
              startTimestamp && "depict--datepickerWrapper__hasValue",
            ])}
          >
            <div className="input-group input-group-sm rounded-pill">
              <DatePicker
                ref={startDatePickerRef}
                dateFormat={config.const.dateFormat}
                selected={
                  startTimestamp ? toUtc(new Date(startTimestamp * 1000)) : null
                }
                onChange={startDatepickerOnChange}
                selectsStart
                startDate={
                  startTimestamp ? toUtc(new Date(startTimestamp * 1000)) : null
                }
                minDate={minDate}
                endDate={
                  endTimestamp ? toUtc(new Date(endTimestamp * 1000)) : null
                }
                customInput={<CustomInput />}
                placeholderText="Start date"
                disabled={state !== "scheduled"}
              >
                {" "}
                <h6
                  className="text-center pt-1 pb-1 m-0 fw-bolder"
                  style={{ backgroundColor: "#f0f0f0", fontSize: "0.75rem" }}
                >
                  Start Date
                </h6>
              </DatePicker>
              <span className="input-group-text" onClick={openStartDatePicker}>
                <CalendarEmpty size={10} />
              </span>
            </div>
          </div>
          <div
            data-tc="end-date-datepicker"
            className={mergeClassNames([
              "col-xl-6 depict--datepickerWrapper",
              state !== "scheduled" && "depict--datepickerWrapper__disabled",
              endTimestamp && "depict--datepickerWrapper__hasValue",
            ])}
          >
            <div className="input-group input-group-sm rounded-pill">
              <DatePicker
                ref={endDatePickerRef}
                dateFormat={config.const.dateFormat}
                selected={
                  endTimestamp ? toUtc(new Date(endTimestamp * 1000)) : null
                }
                onChange={endDatePickerOnChange}
                selectsEnd
                startDate={
                  startTimestamp ? toUtc(new Date(startTimestamp * 1000)) : null
                }
                minDate={minDate}
                endDate={
                  endTimestamp ? toUtc(new Date(endTimestamp * 1000)) : null
                }
                customInput={<CustomInput />}
                placeholderText="End date"
                disabled={state !== "scheduled"}
              >
                <h6
                  className="text-center pt-1 pb-1 m-0 fw-bolder"
                  style={{ backgroundColor: "#f0f0f0", fontSize: "0.75rem" }}
                >
                  End Date
                </h6>
              </DatePicker>
              <span className="input-group-text" onClick={openEndDatePicker}>
                <CalendarEmpty size={10} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Schedule, reactFastCompare);

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const ThumbsUp = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M18.916 11.208c0.443-0.511 0.695-1.355 0.695-2.159 0-0.531-0.115-0.996-0.333-1.345-0.284-0.454-0.738-0.704-1.278-0.704h-2.618c1.425-2.591 1.785-4.543 1.070-5.807-0.499-0.881-1.413-1.193-2.045-1.193-0.25 0-0.462 0.185-0.495 0.433-0.179 1.319-1.188 2.893-2.768 4.318-1.514 1.365-3.374 2.456-5.286 3.11-0.241-0.508-0.758-0.86-1.356-0.86h-3c-0.827 0-1.5 0.673-1.5 1.5v9c0 0.827 0.673 1.5 1.5 1.5h3c0.634 0 1.176-0.395 1.396-0.952 1.961 0.246 2.699 0.64 3.414 1.022 0.895 0.478 1.739 0.93 4.503 0.93 0.72 0 1.398-0.188 1.91-0.529 0.5-0.333 0.82-0.801 0.926-1.343 0.399-0.162 0.753-0.536 1.024-1.092 0.264-0.541 0.435-1.232 0.435-1.761 0-0.099-0.006-0.19-0.017-0.274 0.253-0.186 0.48-0.473 0.667-0.851 0.27-0.545 0.432-1.228 0.432-1.826 0-0.424-0.079-0.777-0.234-1.051-0.013-0.022-0.026-0.044-0.039-0.065zM4.5 18h-3c-0.276 0-0.5-0.224-0.5-0.5v-9c0-0.276 0.224-0.5 0.5-0.5h3c0.276 0 0.5 0.224 0.5 0.5v8.999c0 0 0 0.001 0 0.001-0 0.276-0.224 0.5-0.5 0.5zM18.339 10.274c-0.151 0.304-0.304 0.414-0.37 0.414-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5c0.042 0 0.072 0 0.117 0.078 0.066 0.117 0.104 0.32 0.104 0.558 0 0.445-0.126 0.974-0.328 1.382-0.198 0.399-0.399 0.544-0.487 0.544-0.276 0-0.5 0.224-0.5 0.5 0 0.177 0.092 0.333 0.231 0.422 0.031 0.317-0.117 1.165-0.501 1.718-0.145 0.209-0.298 0.329-0.418 0.329-0.276 0-0.5 0.224-0.5 0.5 0 0.88-0.972 1.281-1.875 1.281-2.513 0-3.217-0.376-4.032-0.812-0.762-0.407-1.618-0.865-3.781-1.134v-8.187c2.101-0.689 4.152-1.877 5.812-3.373 1.593-1.436 2.639-2.988 2.994-4.426 0.272 0.087 0.579 0.271 0.776 0.618 0.334 0.59 0.584 2.096-1.493 5.557-0.093 0.154-0.095 0.347-0.006 0.504s0.255 0.254 0.435 0.254h3.483c0.199 0 0.327 0.070 0.43 0.234 0.117 0.187 0.181 0.477 0.181 0.815 0 0.424-0.102 0.882-0.272 1.225z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(ThumbsUp); 
import { theme } from "../designSystemVariables";

import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { getData } from "src/api/authorizedApi";
import useMerchant from "src/helpers/hooks/app/useMerchant";
import useAuthorizedApi from "../../../helpers/hooks/app/useAuthorizedApi";
import useMarket from "../../../helpers/hooks/app/useMarket";
import { QueryId, getQueryKey } from "../../../queries/queries";
import { DataPeriod } from "../../../types/components";
import { CategoryHeaderDropdown } from "../Categories/CategoryHeaderDropdown/CategoryHeaderDropdown";
import { MAX_QUERIES, SearchStats } from "../SearchStats/SearchStats";

function SearchOverview() {
  const { merchantId } = useMerchant();

  const { market, availableMarkets: markets, setMarket } = useMarket();

  const now = useMemo(() => {
    return new Date().getTime() / 1000;
  }, []);
  const oneDayInSeconds = 24 * 60 * 60;
  const availablePeriods = [
    {
      label: "Time periods",
      options: [
        {
          value: Math.floor(now - 7 * oneDayInSeconds).toString(),
          label: "Last 7 days",
        },
        {
          value: Math.floor(now - 30 * oneDayInSeconds).toString(),
          label: "Last 30 days",
        },
        {
          value: Math.floor(now - 90 * oneDayInSeconds).toString(),
          label: "Last 90 days",
        },
        {
          value: Math.floor(now - 365 * oneDayInSeconds).toString(),
          label: "Last year",
        },
      ],
    },
  ];

  const timestampToPeriod = (timestamp: string): DataPeriod => {
    return {
      start: parseInt(timestamp, 10),
      end: Math.floor(now),
    };
  };

  const [period, setPeriod] = useState<DataPeriod>(
    timestampToPeriod(availablePeriods[0].options[1].value)
  );

  const marketOptions = [
    {
      label: "Markets",
      options: markets
        .filter((m) => m.show_in_dashboard)
        .map((market) => ({
          value: market.id,
          label: market.display_name ?? market.id,
        })),
    },
  ];

  const { api } = useAuthorizedApi();

  const { data: searchQueryStats, isLoading: searchQueryStatsIsLoading } =
    useQuery({
      queryKey: getQueryKey(QueryId.GetSearchStats, merchantId || "", period),
      queryFn: async () => {
        if (!api || !merchantId) return;
        const response = await api.GET("/api/v0/metrics/search-query", {
          params: {
            query: {
              merchant_id: merchantId,
              start_timestamp: period.start,
              end_timestamp: period.end,
              max_queries_per_market: MAX_QUERIES,
            },
          },
        });
        return getData(response);
      },

      enabled: !!api && !!merchantId,
      staleTime: Infinity,
    });

  return (
    <div>
      <div
        style={{
          background: theme.background.base.default,
          borderRadius: "16px",
          textAlign: "left",
        }}
      >
        <div className="d-flex justify-content-start align-items-end pt-2">
          <div className="d-flex align-items-end">
            <CategoryHeaderDropdown
              label="Market"
              optionGroups={marketOptions}
              selectedValue={market ? market : markets[0].id}
              onSelect={(value) => {
                setMarket(value);
              }}
            />
          </div>
          <div className="col-sm-auto ms-3">
            <CategoryHeaderDropdown
              label="Time period"
              optionGroups={availablePeriods}
              selectedValue={period.start.toString()}
              onSelect={(value) => {
                setPeriod(timestampToPeriod(value));
              }}
            />
          </div>
        </div>
        <div className="mt-4">
          <SearchStats
            loading={searchQueryStatsIsLoading}
            mostCommonQueries={
              searchQueryStats && market
                ? searchQueryStats.most_common_queries_by_market[market] ?? []
                : []
            }
            mostCommonQueriesWithoutResults={
              searchQueryStats && market
                ? searchQueryStats
                    .most_common_queries_with_no_results_by_market[market] ?? []
                : []
            }
          />
        </div>
      </div>
    </div>
  );
}

export default SearchOverview;

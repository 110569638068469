import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  ComputedSerie,
  PointTooltipProps,
  ResponsiveLineCanvas,
} from "@nivo/line";
import { LinePlotProps, DepictLineCanvasProps } from "src/types/components";
import { NivoDataObject } from "src/types/metrics";
import usePrevious from "src/helpers/hooks/usePrevious";
import { deepEqual } from "src/helpers";
import {
  filterData,
  filtersAreTheSame,
  getTooltipComponent,
} from "src/helpers/metrics";
import { getLineColor, getLineStyle } from "src/helpers/surfaces";
import config from "src/config";

const Metric = (props: LinePlotProps) => {
  const { dataset, filters, yTooltipLabel, options, colors } = props;
  const [data, setData] = useState<NivoDataObject[] | null>(null);
  const isMounted = useRef(false);
  const plotId = useRef(Math.floor(Math.random() * 99999));
  const previousFilters = usePrevious(filters);
  const previousDataset = usePrevious(dataset);

  const _getTooltipComponent = useCallback(
    (value: PointTooltipProps) => {
      return getTooltipComponent(value, "Date", yTooltipLabel, colors ?? []);
    },
    [colors, yTooltipLabel]
  );

  let opt = Object.assign(
    {},
    {
      isInteractive: true,
      tooltip: _getTooltipComponent,
      axisTop: {
        legend: props.axisLeftLegend || "",
        legendPosition: "start",
        tickSize: 5,
        tickPadding: 5,
        tickValues: 0,
        legendOffset: -5,
      } as any,
      axisBottom: {
        tickSize: 5,
        tickPadding: 5,
        tickValues: 5,
        format: "%b %d",
      } as any,
      axisLeft: {
        tickSize: 5,
        tickPadding: 5,
        tickValues: 5,
      },

      yFormat: ">-.0f",
      margin: { top: 10, bottom: 25, left: 50, right: 20 },
      pointSize: 5,
      pointColor: "#FFF",
      pointBorderWidth: 1,
      enableGridX: true,
      enableGridY: true,
      theme: {
        grid: {
          line: {
            stroke: "#fafafc",
            strokeWidth: 1,
          },
        },
      },
    } as DepictLineCanvasProps,
    options
  );

  const computeLineColor = useCallback(
    (line: { id: string }) => {
      if (!colors) {
        return "rgba(0,0,0,0.1)";
      }

      let found = getLineColor(line.id, colors);

      if (!found) {
        return "rgba(0,0,0,0.1)";
      }

      return found;
    },
    [colors]
  );

  //https://github.com/plouc/nivo/blob/fe388ff3d081ae9f8ab37c5ac3172bd5154fe98e/packages/line/src/LineCanvas.js
  const lineCustomLayer = useCallback(
    ({
      lineGenerator,
      series,
      ctx,
      lineWidth,
    }: {
      lineGenerator: any; //D3Line<Array<ComputedDatum["position"]>>;
      series: ComputedSerie[];
      ctx: CanvasRenderingContext2D;
      lineWidth?: number;
      innerWidth: number;
    }) => {
      lineGenerator.context(ctx);
      series.forEach((serie) => {
        ctx.strokeStyle = serie.color || "rgba(0,0,0,0.1)";
        ctx.lineWidth = lineWidth || 1;

        let style = "solid";
        if (colors) {
          let found = getLineStyle(serie.id as string, colors);
          if (found) {
            style = found;
          }
        }

        if (style === "dashed") {
          ctx.setLineDash([4, 2]);
        }

        ctx.beginPath();
        lineGenerator(serie.data.map((d) => d.position));
        ctx.stroke();
        ctx.setLineDash([]);
      });
    },
    [colors]
  );

  useEffect(() => {
    isMounted.current = true;

    if (!dataset) {
      return;
    }

    if (
      filtersAreTheSame(filters, previousFilters ? previousFilters : []) &&
      deepEqual(dataset, previousDataset)
    ) {
      return;
    }

    if (!isMounted.current) {
      return;
    }

    console.log(
      "[<Metric />]Data provided for %c#" + plotId.current + ".",
      "font-weight: bold"
    );

    let data = filterData(dataset, filters);
    setData(data);

    return () => {
      isMounted.current = false;
    };
    //eslint-disable-next-line
  }, [
    dataset,
    filters,
    //previousDataset, //Intentional
    // previousFilters, //Intentional
  ]);

  /*
   * Fix area below graph: https://github.com/plouc/nivo/issues/829
   * Sometime the painted area goes below the X axis.
   */

  /*
   * Area gradients:
   * https://nivo.rocks/storybook/?path=/story/line--area-gradients (only for SVG version)
   * We could adapt from this (https://nivo.rocks/storybook/?path=/docs/linecanvas--custom-line-style) in order to make it work with canvas.
   */

  /*
   * Axis: it is possible to add markers. In order to paint the axis would be enough to paint 2 markers from the origin of the graph.
   * Unfortunately it "markers" are not available as a layer for ResponsiveLineCanvas, but only for the SVG version ResponsiveLine.
   * See here: https://github.com/plouc/nivo/blob/fe388ff3d081ae9f8ab37c5ac3172bd5154fe98e/packages/line/src/LineCanvas.js
   * And here: https://github.com/plouc/nivo/blob/fe388ff3d081ae9f8ab37c5ac3172bd5154fe98e/packages/line/src/Line.js
   */

  return (
    <div id={plotId.current + ""} style={{ width: "100%", height: "100%" }}>
      {!data && (
        <div className="text-end">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {data && (
        //@ts-ignore
        <ResponsiveLineCanvas
          //pointLabelYOffset={-10}
          isInteractive={opt.isInteractive}
          tooltip={opt.tooltip}
          colors={computeLineColor}
          enableArea={true}
          areaOpacity={0.1}
          data={data}
          margin={opt.margin}
          xFormat={`time:${config.const.dateFormatGraph}`}
          yFormat={opt.yFormat}
          xScale={{
            type: "time",
            format: config.const.dateFormatGraph,
          }}
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
            stacked: false,
            reverse: false,
          }}
          axisRight={null}
          axisBottom={opt.axisBottom}
          axisLeft={opt.axisLeft}
          axisTop={opt.axisTop}
          pointSize={opt.pointSize}
          pointColor={{ from: "color" }}
          pointBorderWidth={opt.pointBorderWidth}
          pointBorderColor={{ from: "serieColor" }}
          theme={opt.theme}
          enableGridX={opt.enableGridX}
          enableGridY={opt.enableGridY}
          lineWidth={opt.lineWidth}
          layers={[
            "grid",
            "areas",
            lineCustomLayer,
            "slices",
            "points",
            "axes",
            "legends",
            "markers",
          ]}
        />
      )}
    </div>
  );
};

export default React.memo(Metric);

import { css } from "@emotion/react";
import { useEffect, useRef } from "react";

interface CheckboxProps {
  checked: boolean;
  onChange: (alreadyChecked: boolean) => void;
  style?: React.CSSProperties;
  indeterminate?: boolean;
  disabled?: boolean;
}

export function Checkbox(props: CheckboxProps) {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.indeterminate = props.indeterminate ?? false;
    }
  }, [props.indeterminate]);

  return (
    <input
      ref={ref}
      className="form-check-input"
      type="checkbox"
      style={{
        width: "22px",
        height: "22px",
        borderWidth: "1.5px",
        cursor: "pointer",
        marginTop: 0,
        ...(props.style || {}),
      }}
      css={css`
        &:active,
        &:focus {
          box-shadow: none !important;
          border-color: #0f0f0f40;
        }
      `}
      checked={props.checked}
      onChange={() => props.onChange(props.checked)}
      disabled={props.disabled}
    />
  );
}

import { useCallback } from "react";
import { getUpdatedGroup } from "src/helpers/configuration";
import {
  ConfiguredProductGroup,
  ConfiguredProductGroupDefinition,
} from "src/types/configuration";
import { GroupModifyCB } from "src/types/components";

export default function useSearchCallback(props: {
  searchTarget: string | null;
  configurationGroups: ConfiguredProductGroup[] | null;
  groupModifyCB: GroupModifyCB;
  toggleRightSidebarOverlayCB: () => void;
}) {
  const {
    searchTarget,
    configurationGroups,
    groupModifyCB,
    toggleRightSidebarOverlayCB,
  } = props;

  const searchCallback = useCallback(
    (data: ConfiguredProductGroupDefinition) => {
      toggleRightSidebarOverlayCB();

      if (!searchTarget || !groupModifyCB || !configurationGroups) {
        return;
      }

      let updatedGroup = getUpdatedGroup(
        searchTarget,
        data,
        configurationGroups,
        "append"
      );

      if (!updatedGroup) {
        return;
      }

      console.log(
        `%c[CONFIGURATION] Performing operation "${"append"}" on group "${searchTarget}"`,
        "color: purple"
      );

      groupModifyCB(updatedGroup);
    },
    [
      toggleRightSidebarOverlayCB,
      searchTarget,
      groupModifyCB,
      configurationGroups,
    ]
  );

  return { searchCallback };
}

import { EnhancedSurface } from "src/types/components";

import { useCallback } from "react";
import { isSurfaceActive } from "src/helpers/surfaces";

const SidebarLegend = (props: {
  items: EnhancedSurface[] | null;
  enabled: EnhancedSurface[] | null;
  setEnabledSurfacesCB: (
    value: EnhancedSurface[] | EnhancedSurface | null,
    action: "enable" | "disable" | "set"
  ) => void;
}) => {
  const { items, enabled, setEnabledSurfacesCB } = props;

  const toggleSurface = useCallback(
    (surface: EnhancedSurface, surfaces: EnhancedSurface[]) => {
      if (!isSurfaceActive(surface, surfaces ?? [])) {
        setEnabledSurfacesCB(surface, "enable");
      } else if (surfaces.length > 1) {
        setEnabledSurfacesCB(surface, "disable");
      }
    },
    [setEnabledSurfacesCB]
  );

  return (
    <>
      <ul className="list-unstyled">
        {items &&
          items.map((surface: EnhancedSurface, index: number) => {
            return (
              <li
                key={index}
                onClick={() => {
                  toggleSurface(surface, enabled || []);
                }}
              >
                {isSurfaceActive(surface, enabled || []) ? (
                  <i
                    className="depict--LegendSidebar__icon"
                    style={{
                      background: surface.color,
                    }}
                  >
                    <i
                      className="material-icons"
                      style={{ color: "white", fontSize: "0.9rem" }}
                    >
                      check
                    </i>
                  </i>
                ) : (
                  <i
                    className="depict--LegendSidebar__icon"
                    style={{
                      borderColor: surface.color,
                      background: "transparent",
                      border: `0.15rem solid ${surface.color}`,
                    }}
                  ></i>
                )}
                <div>
                  {surface.display_name ? surface.display_name : surface.id}
                </div>
              </li>
            );
          })}
      </ul>
    </>
  );
};

export default SidebarLegend;

import React, { useCallback } from "react";
import mergeClassNames from "merge-class-names";
import { ListItem } from "src/types/components";
import { ListItemsProps } from "src/types/components";
import VirtualList from "react-tiny-virtual-list";
import Abstraction from "src/components/lists/Abstraction";
import reactFastCompare from "react-fast-compare";

const Items = (props: ListItemsProps) => {
  const {
    id,
    items,
    getItem,
    emptyListLabel,
    noListComponent,
    isDark,
    isVirtual,
    isSortable,
    virtualListHeight,
    virtualListItemHeight,
    virtualStickyIndices,
  } = props;

  const getIndex = useCallback(
    (id: string) => {
      if (!items) {
        return -1;
      }

      const index = items.findIndex((item) => {
        return item.id === id;
      });

      return index;
    },
    [items]
  );

  const getVirtualListItem = useCallback(
    ({ index, style }: { index: number; style: React.CSSProperties }) => {
      if (!items) {
        return;
      }

      const item = items[index];
      return (
        <div style={style} key={item.id}>
          <Abstraction
            id={id}
            item={item}
            index={index}
            getIndex={getIndex}
            isSortable={isSortable}
            itemTile={getItem(item)}
          />
        </div>
      );
    },
    [getIndex, getItem, id, isSortable, items]
  );

  return (
    <div
      className={mergeClassNames(
        "depict--ItemsList",
        isDark && "depict--ItemsList__dark"
      )}
    >
      {items && (
        <ul id={id}>
          {!items.length && emptyListLabel && (
            <div className="depict--ItemsList__item">
              <div className="depict--ItemsList__content">{emptyListLabel}</div>
            </div>
          )}
          {!isVirtual &&
            items.map((item: ListItem, index: number) => {
              return (
                <Abstraction
                  key={item.id}
                  id={id}
                  item={item}
                  index={index}
                  getIndex={getIndex}
                  isSortable={isSortable}
                  itemTile={getItem(item)}
                />
              );
            })}
          {isVirtual && items && (
            <VirtualList
              width="100%"
              height={virtualListHeight ?? "100%"}
              itemCount={items.length}
              itemSize={virtualListItemHeight ?? 30}
              stickyIndices={virtualStickyIndices}
              renderItem={getVirtualListItem}
            />
          )}
        </ul>
      )}
      {!items && noListComponent}
    </div>
  );
};

//export default Items;
export default React.memo(Items, reactFastCompare);

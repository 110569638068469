import { useMutation } from "@tanstack/react-query";
import useAuthorizedApi from "../../../helpers/hooks/app/useAuthorizedApi";
import { ParsingMethod } from "../../storybook/Integrations/ParsingMethodSelector/types";
import ShopifyCompleteIntegration from "../../storybook/Integrations/Shopify/ShopifyCompleteIntegration";
import {
  MetafieldParsingConfig,
  ParsingConfig,
  ShopifyConfig,
} from "../../storybook/Integrations/Shopify/types";
import { throwErrorIfAny } from "src/api/authorizedApi";

export default function ShopifyCompleteIntegrationStep({
  merchant,
  config,
  onBack,
  onComplete,
}: {
  merchant: string;
  config: ShopifyConfig;
  onBack: () => void;
  onComplete: () => void;
}) {
  const { api } = useAuthorizedApi();
  const {
    mutate: postShopifyConfig,
    data,
    isPending,
    error,
  } = useMutation<boolean, string>({
    mutationFn: async () => {
      try {
        const response = await api?.POST(
          "/api/v0/integrations/shopify/config/{merchant_id}",
          {
            body: {
              admin_api_url: config.admin_api_url,
              admin_api_key: config.admin_api_key,
              markets: config.markets.map((m) => ({
                market_id: m.id,
                name: m.name,
                reference_country_code: m.reference_country_code,
              })),
              parsing_configs: config.parsing_configs,
            },
            params: {
              path: {
                merchant_id: merchant,
              },
            },
          }
        );

        if (!response) {
          throw new Error("No response from API");
        }

        throwErrorIfAny(response);
      } catch (e) {
        throw e;
      }
      return true;
    },
  });

  if (error) {
    debugger;
  }

  return (
    <ShopifyCompleteIntegration
      configuredMetafieldsParsers={config.parsing_configs.filter(
        (config: ParsingConfig): config is MetafieldParsingConfig =>
          config.type === ParsingMethod.Map
      )}
      isLoading={isPending}
      errorMessage={error}
      onSubmit={() => postShopifyConfig()}
      onComplete={onComplete}
      onBack={onBack}
      success={data || false}
    />
  );
}

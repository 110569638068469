import { useContext } from "react";
import { PathContext } from "src/contexts/PathContext";

/**
 * usePathContext is a React hook that provides a convenient way to access the
 * PathContext.
 */
export const usePathContext = () => {
  const context = useContext(PathContext);

  if (!context) throw new Error("PathContext is not defined");

  return context;
};

export default usePathContext;

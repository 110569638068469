 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Trash3 = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M11.5 3c-0.276 0-0.5-0.224-0.5-0.5v-1c0-0.276-0.224-0.5-0.5-0.5h-2c-0.276 0-0.5 0.224-0.5 0.5v1c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5v-1c0-0.827 0.673-1.5 1.5-1.5h2c0.827 0 1.5 0.673 1.5 1.5v1c0 0.276-0.224 0.5-0.5 0.5z" fill={color}></path>
<path d="M17.51 2.69c-0.282-0.327-0.689-0.608-1.212-0.833-0.88-0.38-2.11-0.627-3.759-0.754-0.275-0.021-0.516 0.185-0.537 0.46s0.185 0.516 0.46 0.537c1.516 0.117 2.673 0.344 3.44 0.675 0.168 0.072 0.31 0.147 0.431 0.221-1.167 0.536-3.647 1.005-6.832 1.005-3.213 0-5.686-0.47-6.844-1.009 0.123-0.077 0.269-0.152 0.442-0.227 0.765-0.328 1.922-0.553 3.44-0.667 0.275-0.021 0.482-0.261 0.461-0.536s-0.261-0.482-0.536-0.461c-1.651 0.125-2.88 0.369-3.759 0.746-0.523 0.224-0.931 0.504-1.213 0.83-0.325 0.377-0.491 0.818-0.491 1.31v2.515c0 0.489 0.336 0.916 1 1.277v9.723c0 0.307 0.134 0.763 0.77 1.193 0.396 0.268 0.949 0.504 1.643 0.702 1.366 0.39 3.172 0.605 5.087 0.605s3.722-0.215 5.087-0.605c0.694-0.198 1.246-0.434 1.643-0.702 0.637-0.43 0.77-0.886 0.77-1.193v-9.723c0.664-0.361 1-0.788 1-1.277v-2.5c0-0.492-0.165-0.933-0.49-1.31zM16 17.5c0 0.117-0.31 0.54-1.688 0.933-1.279 0.365-2.988 0.567-4.812 0.567s-3.534-0.201-4.812-0.567c-1.378-0.394-1.688-0.816-1.688-0.933v-9.307c0.222 0.072 0.463 0.141 0.722 0.206 1.55 0.387 3.602 0.601 5.778 0.601s4.229-0.213 5.778-0.601c0.259-0.065 0.499-0.134 0.722-0.206v9.307zM17 6.5c0 0.026-0.065 0.16-0.383 0.348-0.356 0.211-0.903 0.412-1.581 0.581-1.472 0.368-3.438 0.571-5.536 0.571s-4.063-0.203-5.536-0.571c-0.678-0.17-1.225-0.37-1.581-0.581-0.318-0.188-0.383-0.322-0.383-0.348v-2.515c0-0.061 0.004-0.127 0.017-0.197 0.733 0.39 1.747 0.643 2.549 0.796 1.431 0.272 3.138 0.416 4.934 0.416 3.261 0 6.080-0.466 7.48-1.21 0.015 0.075 0.020 0.146 0.020 0.21v2.5z" fill={color}></path>
<path d="M14.5 17.4c-0.276 0-0.5-0.224-0.5-0.5v-7c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5v7c0 0.276-0.224 0.5-0.5 0.5z" fill={color}></path>
<path d="M12.5 17.807c-0.276 0-0.5-0.224-0.5-0.5v-7c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5v7c0 0.276-0.224 0.5-0.5 0.5z" fill={color}></path>
<path d="M10.5 18c-0.276 0-0.5-0.224-0.5-0.5v-7c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5v7c0 0.276-0.224 0.5-0.5 0.5z" fill={color}></path>
<path d="M8.5 18c-0.276 0-0.5-0.224-0.5-0.5v-7c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5v7c0 0.276-0.224 0.5-0.5 0.5z" fill={color}></path>
<path d="M6.5 17.807c-0 0 0 0-0 0-0.276-0-0.5-0.224-0.5-0.5l0.001-7c0-0.276 0.224-0.5 0.5-0.5 0 0 0 0 0 0 0.276 0 0.5 0.224 0.5 0.5l-0.001 7c-0 0.276-0.224 0.5-0.5 0.5z" fill={color}></path>
<path d="M4.5 17.4c-0 0 0 0 0 0-0.276 0-0.5-0.224-0.5-0.5l0-7c0-0.276 0.224-0.5 0.5-0.5 0 0 0 0 0 0 0.276 0 0.5 0.224 0.5 0.5l-0 7c-0 0.276-0.224 0.5-0.5 0.5z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Trash3); 
import React, { useState } from "react";
import useAuthorizedApi from "../../../helpers/hooks/app/useAuthorizedApi";
import ShopifyApiConfig from "../../storybook/Integrations/Shopify/ShopifyApiConfig";
import { getData } from "src/api/authorizedApi";
import { paths } from "src/api/generated/openapi";

export function constructAdminApiUrl(rawUrl: string) {
  const url = new URL(rawUrl);
  const shopifyDomain = url.hostname;
  return `https://${shopifyDomain}/admin/api/2023-04/graphql.json`;
}

type ShopifyResponse =
  paths["/api/v0/integrations/shopify/verify-api-credentials"]["post"]["responses"]["200"]["content"]["application/json"];

export default function ShopifyApiConfigStep({
  adminApiUrl,
  adminApiKey,
  onSubmit,
  onBack,
}: {
  adminApiUrl?: string;
  adminApiKey?: string;
  onSubmit: (adminApiUrl: string, adminApiKey: string) => void;
  onBack: () => void;
}) {
  const { api } = useAuthorizedApi();
  const [verifyCredentialsResponse, setVerifyCredentialsResponse] =
    useState<ShopifyResponse>();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const verifyCredentials = async (
    adminApiUrl: string,
    adminApiKey: string
  ) => {
    if (api) {
      setIsLoading(true);
      try {
        const resp = await api?.POST(
          "/api/v0/integrations/shopify/verify-api-credentials",
          {
            body: {
              admin_api_url: adminApiUrl,
              admin_api_key: adminApiKey,
            },
          }
        );

        const data = getData(resp);

        setVerifyCredentialsResponse(data);
      } catch (e: any & { message: string }) {
        setErrorMessage(e.message);
      }
    }
    setIsLoading(false);
  };

  return (
    <ShopifyApiConfig
      verifyCredentialsResponse={verifyCredentialsResponse ?? null}
      isLoading={isLoading}
      errorMessage={errorMessage}
      adminApiUrl={adminApiUrl ?? ""}
      adminApiKey={adminApiKey ?? ""}
      onSubmit={(adminApiUrl: string, adminApiKey: string) => {
        onSubmit(constructAdminApiUrl(adminApiUrl), adminApiKey);
      }}
      onBack={onBack}
      onVerifyShopifyCredentials={(adminApiUrl: string, adminApiKey: string) =>
        verifyCredentials(constructAdminApiUrl(adminApiUrl), adminApiKey)
      }
    />
  );
}

import { useMutation, useQuery } from "@tanstack/react-query";
import { getData } from "src/api/authorizedApi";
import queryClient from "../../queries/client";
import { QueryId, getQueryKey } from "../../queries/queries";
import useAuthorizedApi from "./app/useAuthorizedApi";

export default function useProductInbox(merchantId: string | null) {
  const { api } = useAuthorizedApi();

  const { data: inboxMainProductIds } = useQuery({
    queryKey: getQueryKey(QueryId.GetInboxMainProductIds, merchantId || ""),
    queryFn: async () => {
      if (api && merchantId) {
        const response = await api.GET(
          "/api/v0/catalog/products/{merchant_id}/inbox",
          {
            params: {
              path: {
                merchant_id: merchantId,
              },
            },
          }
        );

        return getData(response);
      }
    },

    enabled: !!api && !!merchantId,
    staleTime: 1000 * 60 * 60,
  });

  const {
    mutate: dismissProducts,
    isPending: dismissProductsIsLoading,
    error: dismissProductsError,
  } = useMutation({
    mutationFn: async (mainProductIds: string[]) => {
      if (api && merchantId) {
        const response = await api.POST(
          "/api/v0/catalog/products/{merchant_id}/inbox",
          {
            params: {
              path: {
                merchant_id: merchantId,
              },
            },
            body: mainProductIds,
          }
        );

        return getData(response);
      }
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: getQueryKey(QueryId.GetInboxMainProductIds, merchantId || ""),
      });
    },
  });

  return {
    inboxMainProductIds,
    dismissProducts,
    dismissProductsIsLoading,
    dismissProductsError,
  };
}

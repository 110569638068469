import { useCallback } from "react";
import { OnSelect } from "src/components/storybook/BulkEditBar/BulkEditBar.types";
import { CategoryProductGridCard } from "src/components/storybook/Categories/CategoryProductGridCard/CategoryProductGridCard";
import ProductGridViewSortable, {
  RenderCardCB,
} from "src/components/storybook/SortableComponents/ProductGridViewSortable/ProductGridViewSortable";

export interface SortableGridViewProps {
  isLoading: boolean;
  onPinClick: (productId: string) => void;
  onHidingClick: (productId: string) => void;
  onPinToBeginning: (productId: string) => void;
  onAiSortedClick: (productId: string) => void | Promise<void>;
  onDragEnd: (productIds: string[], newIndex: number) => void;
  onSelectClick?: OnSelect;
  selectedIds: Set<string>;
  onRemoveClick?: (productId: string) => void;
  disableDrag: boolean;
  columns: number;
  aiSorted: boolean;
}
export function SortableGridView(props: SortableGridViewProps) {
  const { isLoading } = props;

  const isInSelectMode = props.selectedIds.size > 0;

  const renderCard = useCallback<RenderCardCB>(
    (product, index, extraStyle) => {
      return (
        <CategoryProductGridCard
          id={product.main_product_id}
          key={product.id}
          pinned={true}
          hidden={false}
          pinningAllowed={true}
          hidingAllowed={false}
          product={product}
          index={index}
          disableIndex={false}
          actionCBs={{
            onPinToTop: props.onPinToBeginning,
            onPinClick: props.onPinClick,
            onHideClick: props.onHidingClick,
            onAiSortedClick: props.onAiSortedClick,
            onSelectClick: props.onSelectClick,
            onRemoveClick: props.onRemoveClick,
          }}
          showSelectCheckbox={true}
          isSelected={props.selectedIds.has(product.id)}
          extraStyles={extraStyle}
          highlight={false}
          isInSelectMode={isInSelectMode}
          aiSorted={props.aiSorted}
        />
      );
    },
    [
      props.onPinToBeginning,
      props.onPinClick,
      props.onHidingClick,
      props.onAiSortedClick,
      props.onSelectClick,
      props.onRemoveClick,
      props.selectedIds,
      props.aiSorted,
      isInSelectMode,
    ]
  );

  return (
    <ProductGridViewSortable
      isLoading={isLoading}
      renderCard={renderCard}
      onDragEnd={props.onDragEnd}
      columns={props.columns}
      selectedIds={props.selectedIds}
      disableDrag={props.disableDrag}
    />
  );
}

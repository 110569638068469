import { CheckCircle } from "@phosphor-icons/react";
import { theme } from "../../designSystemVariables";
import Body from "../Body/Body";

interface SuccessAlertProps {
  title?: string;
  message: string | JSX.Element;
}
const SuccessAlert = (props: SuccessAlertProps) => {
  return (
    <Body
      backgroundColor={theme.background.success.default}
      titleColor={theme.textIcon.base.default}
      title={props.title || "Success"}
      message={props.message}
      messageColor={theme.textIcon.base.default}
      icon={<CheckCircle size={24} color={theme.textIcon.success.default} />}
    />
  );
};

export default SuccessAlert;

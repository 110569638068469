const countElementsInView = (selector: string) => {
  const calculateVisibleElements = () => {
    const elements = document.querySelectorAll(selector);
    let count = 0;

    elements.forEach((element) => {
      if (isElementInViewport(element)) {
        count++;
      }
    });

    return count;
  };

  const isElementInViewport = (el: Element) => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  return calculateVisibleElements();
};

export default countElementsInView;

import { useMemo } from "react";
import {
  ConfiguredProductGroup,
  ConfiguredProductGroupDefinition,
} from "src/types/configuration";
import { MainProduct, Product } from "src/types/products";
import { getSet } from "src/helpers/configuration";

export default function useSetItems(props: {
  group: ConfiguredProductGroup | null;
  products: Array<MainProduct | Product> | null;
  groups: ConfiguredProductGroup[] | null;
}) {
  const { group, products, groups } = props;
  const setItems = useMemo(
    () =>
      getSet(
        group?.group_definition as ConfiguredProductGroupDefinition,
        products,
        groups
      ),
    [group?.group_definition, products, groups]
  );

  return [setItems];
}

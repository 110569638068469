import { css } from "@emotion/react";
import { ListBullets, SquaresFour } from "@phosphor-icons/react";
import { FC, useMemo } from "react";
import { Placeholder } from "react-bootstrap";
import { SortModel } from "src/api/types";
import { relevanceSortingField } from "src/components/categories_v2_v3_common/Category/Category";
import { IconButton } from "../../IconButton/IconButton";
import { SearchField } from "../../SearchField/SearchField";
import { theme } from "../../designSystemVariables";
import {
  CategoryHeaderDropdown,
  OptionModel,
} from "../CategoryHeaderDropdown/CategoryHeaderDropdown";
import { CategoryRankingOrderOptionType } from "../CategoryRankingOrder/CategoryRankingOrderOption";

export type CategoryHeaderBottomProps = {
  showPlaceholders: boolean;
  availableFilters: OptionModel[];
  availableSorts: SortModel[];
  rankingType: CategoryRankingOrderOptionType;
  selectedFilter: string;
  selectedSort: SortModel | undefined;
  selectedView: "grid" | "list";
  totalProductCount: number;
  onSelectFilter: (value: string) => void;
  onSelectSort: (sort: SortModel) => void;
  onSetSearchQuery: (searchQuery: string) => void;
  onToggleFiltersOpen: () => void;
  onToggleView: () => void;
  menuItemComponent?: React.ReactNode;
  nbColumnSwitcher?: React.ReactNode;
};

const getSortValue = (sort: SortModel) => `${sort.field}|${sort.order}`;

export const CategoryHeaderBottom: FC<CategoryHeaderBottomProps> = ({
  showPlaceholders,
  availableFilters,
  availableSorts,
  selectedFilter,
  selectedSort,
  selectedView,
  totalProductCount,
  onSelectFilter,
  onSelectSort,
  onSetSearchQuery,
  onToggleFiltersOpen,
  onToggleView,
  menuItemComponent,
  nbColumnSwitcher
}) => {
  const optionGroups = useMemo(
    () =>
      [
        {
          label: "Recommended: (shopper's default)",
          options: availableSorts.filter(
            (option) => option.field === relevanceSortingField
          ),
        },
        {
          label: "Preview:",
          options: availableSorts.filter(
            (option) => option.field !== relevanceSortingField
          ),
        },
      ].map((optionGroup) => ({
        ...optionGroup,
        options: optionGroup.options.map((sort) => ({
          label: sort.meta?.title || "",
          value: getSortValue(sort),
        })),
      })),
    [availableSorts]
  );

  return (
    <div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          /* height: 40px; */
        `}
      >
        {/* TODO: Enable once filters are supported
      <SearchFieldAndFilter
        selectedFilter={selectedFilter}
        onToggleFiltersOpen={onToggleFiltersOpen}
        onSelectFilter={onSelectFilter}
        onSetSearchQuery={onSetSearchQuery}
      /> */}

        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <SearchField
            searchQuery={undefined}
            onSetSearchQuery={onSetSearchQuery}
          />
          <span
            css={css`
              margin-left: 0.5rem;
            `}
          >
            {showPlaceholders ? (
              <Placeholder animation="glow">
                <Placeholder
                  css={css`
                    width: 80px;
                    height: 24px;
                  `}
                />
              </Placeholder>
            ) : (
              <span
                css={css`
                  font-size: 14px;
                  color: ${theme.textIcon.base.default};
                `}
              >
                {totalProductCount} products
              </span>
            )}
          </span>
        </div>

        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 1rem;
          `}
        >
          {showPlaceholders ? (
            <Placeholder animation="glow">
              <Placeholder
                css={css`
                  width: 140px;
                  height: 38px;
                `}
              />
            </Placeholder>
          ) : (
            <>
              {menuItemComponent}
              <CategoryHeaderDropdown
                label="Sort"
                optionGroups={optionGroups}
                selectedValue={selectedSort ? getSortValue(selectedSort) : ""}
                onSelect={(value) => {
                  const split = value.split("|");
                  const sort = availableSorts.find(
                    (sort) => sort.field === split[0] && sort.order === split[1]
                  );

                  if (sort) {
                    onSelectSort(sort);
                  }
                }}
              />
            </>
          )}
          {nbColumnSwitcher}
          <IconButton
            icon={selectedView === "grid" ? <SquaresFour /> : <ListBullets />}
            onClick={onToggleView}
          />
        </div>
      </div>
    </div>
  );
};

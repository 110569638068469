import {
  getDaysInMonth,
  subYears,
  eachYearOfInterval,
  eachMonthOfInterval,
  addMonths,
  subDays,
  startOfYear,
  eachQuarterOfInterval,
  endOfQuarter,
  getQuarter,
  startOfQuarter,
  endOfDay,
} from "date-fns";
import { PeriodOption } from "src/types/components";

const getUTCStartAndEndOfMonth = (som: Date, locale = "en-us") => {
  const twoDigitMonth = som.toLocaleString(locale, { month: "2-digit" });
  const yearAndMonth = `${som.getFullYear()}-${twoDigitMonth}`;
  const start = new Date(`${yearAndMonth}-01T00:00:00.000Z`);
  const end = new Date(`${yearAndMonth}-${getDaysInMonth(som)}T23:59:59.000Z`);
  return {
    start,
    end,
  };
};

const getUTCStartAndEndOfQuarter = (soq: Date, locale = "en-us") => {
  const eoq = endOfQuarter(soq);
  const twoDigitMonthStart = soq.toLocaleString(locale, { month: "2-digit" });
  const twoDigitMonthEnd = eoq.toLocaleString(locale, { month: "2-digit" });
  const yearAndMonthStart = `${soq.getFullYear()}-${twoDigitMonthStart}`;
  const yearAndMonthEnd = `${eoq.getFullYear()}-${twoDigitMonthEnd}`;
  const start = new Date(`${yearAndMonthStart}-01T00:00:00.000Z`);
  const end = new Date(
    `${yearAndMonthEnd}-${getDaysInMonth(eoq)}T23:59:59.000Z`
  );
  return {
    start,
    end,
  };
};

export const monthOptions = (
  dateInThatYear: Date,
  locale = "en-us"
): PeriodOption[] => {
  const months = eachMonthOfInterval({
    start: addMonths(subYears(dateInThatYear, 1), 1),
    end: dateInThatYear,
  }).map((som) => {
    const startAndEnd = getUTCStartAndEndOfMonth(som, locale);
    const yearSuffix =
      som.getFullYear() === dateInThatYear.getFullYear()
        ? ""
        : ` ${som.getFullYear()}`;
    const monthLabel = startAndEnd.start.toLocaleString(locale, {
      month: "long",
    });
    return {
      label: `${monthLabel}${yearSuffix}`,
      value: {
        start: Math.floor(+startAndEnd.start / 1000),
        end: Math.floor(+startAndEnd.end / 1000),
      },
    };
  });
  months.reverse();
  const today = new Date();
  months.unshift({
    label: "Last 30 days",
    value: {
      start: Math.floor(+subDays(today, 30) / 1000),
      end: Math.floor(+endOfDay(subDays(today, 1)) / 1000),
    },
  });
  return months;
};

export const yearOptions = (
  dateInStartYear: Date,
  numberOfYears: number,
  locale = "en-us"
): PeriodOption[] => {
  const years = eachYearOfInterval({
    start: subYears(dateInStartYear, numberOfYears - 1),
    end: dateInStartYear,
  }).map((startOfYear) => {
    const start = new Date(`${startOfYear.getFullYear()}-01-01T00:00:00.000Z`);
    const end = new Date(`${startOfYear.getFullYear()}-12-31T23:59:59.000Z`);

    return {
      label: startOfYear.toLocaleString(locale, { year: "numeric" }),
      value: {
        start: Math.floor(+start / 1000),
        end: Math.floor(+end / 1000),
      },
    };
  });
  years.reverse();
  const today = new Date();
  const start = new Date(`${today.getFullYear()}-01-01T00:00:00.000Z`);
  years.unshift({
    label: "This year",
    value: {
      start: Math.floor(+start / 1000),
      end: Math.floor(+endOfDay(subDays(today, 1)) / 1000),
    },
  });
  return years;
};

export const quarterOptions = (
  dateInThatQuarter: Date,
  locale = "en-us"
): PeriodOption[] => {
  const quarters = eachQuarterOfInterval({
    start: startOfYear(subYears(dateInThatQuarter, 1)),
    end: endOfQuarter(dateInThatQuarter),
  }).map((soq) => {
    const startAndEnd = getUTCStartAndEndOfQuarter(soq, locale);

    return {
      label: `Q${getQuarter(soq)} ${soq.getFullYear()}`,
      value: {
        start: Math.floor(+startAndEnd.start / 1000),
        end: Math.floor(+startAndEnd.end / 1000),
      },
    };
  });
  quarters.reverse();
  const today = new Date();
  const startAndEnd = getUTCStartAndEndOfQuarter(startOfQuarter(today), locale);
  quarters.unshift({
    label: "This quarter",
    value: {
      start: Math.floor(+startAndEnd.start / 1000),
      end: Math.floor(+endOfDay(subDays(today, 1)) / 1000),
    },
  });
  return quarters;
};

import { Check, Cross } from "src/components/linearicons";
import { ModalContent } from "src/types/modals";

const modal: ModalContent = {
  title: "Disable AI recommendations for",
  subtitle: "{configuration_name}",
  content: (
    <p
      style={{
        fontSize: "0.687rem",
        textAlign: "center",
        padding: "0 2.5rem",
        marginBottom: ".625rem",
      }}
    >
      Are you sure you want to disable AI recommendations? Only products in
      ‘Always show’ will be included, AI will not add any even if it means fewer
      recommendations are shown.
    </p>
  ),
  buttons: [
    {
      id: "cancel",
      label: "Cancel",
      icon: <Cross size="12" className="me-2" />,
      className: "btn btn-sm btn-outline-depict-brown rounded-pill me-2",
    },
    {
      id: "proceed",
      label: "Yes, disable",
      icon: <Check size="12" className="me-2" />,
      className: "btn btn-sm btn-depict-red rounded-pill me-2",
    },
  ],
};

export default modal;

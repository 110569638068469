 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Map2 = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M18.763 1.075c-0.147-0.091-0.331-0.099-0.486-0.022l-5.776 2.888-5.776-2.888c-0.141-0.070-0.306-0.070-0.447 0l-6 3c-0.169 0.085-0.276 0.258-0.276 0.447v15c0 0.173 0.090 0.334 0.237 0.425 0.080 0.050 0.171 0.075 0.263 0.075 0.076 0 0.153-0.018 0.224-0.053l5.776-2.888 5.776 2.888c0.141 0.070 0.307 0.070 0.447 0l6-3c0.169-0.085 0.276-0.258 0.276-0.447v-15c-0-0.173-0.090-0.334-0.237-0.425zM6 16.191l-5 2.5v-13.882l5-2.5v13.882zM7 2.309l5 2.5v13.882l-5-2.5v-13.882zM18 16.191l-5 2.5v-13.882l5-2.5v13.882z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Map2); 
import { css } from "@emotion/react";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { CollectionMetadataSchema } from "src/components/storybook/Categories/categories.schemas";
import { FormSwitchButton } from "src/components/storybook/SwitchButton/FormSwitchButton";
import { theme } from "../../storybook/designSystemVariables";

type BooleanKeys<T> = {
  [K in keyof T]: T[K] extends boolean ? K : never;
}[keyof T];

export interface CategoryConfigOptionProps {
  name: BooleanKeys<CollectionMetadataSchema>;
  label: string;
  description: string;
  image: string;
}

export const CategoryConfigOption: FC<CategoryConfigOptionProps> = ({
  label,
  description,
  image,
  name,
}) => {
  const form = useFormContext<CollectionMetadataSchema>();

  form.watch(name);

  return (
    <div
      css={css`
        flex: 1;
      `}
    >
      <div
        css={css`
          border-radius: 0.75rem;
          border: 1px solid ${theme.border.subtle.default};
          overflow: hidden;
        `}
      >
        <img
          css={css`
            height: 290px;
            object-fit: cover;
          `}
          alt={label}
          draggable={false}
          width="100%"
          height="100%"
          src={image}
        />
      </div>
      <div
        css={css`
          display: flex;
          padding: 0.75rem;
        `}
      >
        <div>
          <span
            css={css`
              display: block;
              font-size: 18px;
              margin-bottom: 0.25rem;
            `}
          >
            {label}
          </span>
          <span
            css={css`
              display: block;
              font-weight: ${theme.fontWeight.light};
              font-size: 0.75rem;
              color: ${theme.textIcon.subtle.default};
            `}
          >
            {description}
          </span>
        </div>
        <div
          css={css`
            display: flex;
            gap: 0.25rem;
            margin: 0.25rem 0;
            margin-left: 0.33rem;
          `}
        >
          <span
            css={css`
              font-size: 16px;
              line-height: 24px;
            `}
          >
            {form.getValues(name) ? "ON" : "OFF"}
          </span>
          <FormSwitchButton name={name} disabled={false} />
        </div>
      </div>
    </div>
  );
};

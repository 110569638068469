import { useEffect, useMemo, useState } from "react";
import reactFastCompare from "react-fast-compare";
import MetricPlot from "src/components/plots/Metric";
import { formatGraphLeftAxis, formatNumber } from "src/helpers";
import { MetricsSurfaceData } from "src/types/metrics";

import { CardProps } from "src/types/components";
import {
  getMarketDataFromMetric,
  getSurfaceAggregatedValue,
  getMetricDataset,
} from "src/helpers/metrics";
import { MetricComparison } from "src/components/mix/MetricComparison";
import React from "react";

const CardMetric = (props: CardProps) => {
  const {
    axisLeftLegend,
    title,
    data,
    dataCompare,
    colors,
    iconTopLeft,
    plotOptions,
    yTooltipLabel,
    filters,
    market,
    compare,
    enabledSurfaces,
    splitBySurface,
  } = props;

  const [dataset, setDataset] = useState<Array<MetricsSurfaceData> | null>(
    null
  );

  const totalValue: number | undefined = useMemo(() => {
    let dataPoints = getMarketDataFromMetric(data, market);

    if (!dataPoints) {
      return undefined;
    }

    return getSurfaceAggregatedValue(dataPoints, "totalFromRecommendations");
  }, [data, market]);

  const comparisonValue: number | undefined = useMemo(() => {
    if (!compare) {
      return undefined;
    }

    let dataPoints = getMarketDataFromMetric(dataCompare, market);

    if (!dataPoints) {
      return undefined;
    }

    return getSurfaceAggregatedValue(dataPoints, "totalFromRecommendations");
  }, [dataCompare, market, compare]);

  useEffect(() => {
    if (!!data && Object.keys(data).length === 0) {
      /**
       * Here we intercept early on the case where we get data back, but it is actually an empty object.
       */
      setDataset([]);
      return;
    }

    if (!data || !market) {
      setDataset(null);
      return;
    }

    let marketKey = market !== "EMPTY_KEY" ? market : "";

    if (!data[marketKey]) {
      setDataset(null);
      return;
    }

    const hasCompare =
      dataCompare && dataCompare[marketKey] && compare !== "none";

    const metricDataset = getMetricDataset(
      data,
      dataCompare,
      marketKey,
      enabledSurfaces,
      splitBySurface,
      hasCompare
    );

    setDataset(metricDataset);

    return () => {
      setDataset(null);
    };
  }, [data, dataCompare, market, splitBySurface, compare, enabledSurfaces]);

  return (
    <div className="depict--cardMetric card h-100">
      <div className="card-body">
        <div className="depict--cardColumns">
          <div className="depict--cardMetricLeft">
            <div className="depict--cardMetricLeftInner">
              <div className="depict--cardMetricIconWrapper">{iconTopLeft}</div>
              <h5 className="">{title}</h5>
              <p>
                {totalValue ? formatNumber({ number: totalValue }) : " -- "}
              </p>
              <MetricComparison
                comparison={
                  comparisonValue && totalValue
                    ? {
                        type: "PERCENTAGE",
                        diff: (totalValue - comparisonValue) / comparisonValue,
                      }
                    : undefined
                }
              />
            </div>
          </div>
          <div className="depict--cardMetricRight">
            <div className="chart-area" style={{ width: "100%" }}>
              <MetricPlot
                yTooltipLabel={yTooltipLabel}
                dataset={dataset}
                filters={filters || []}
                axisLeftLegend={axisLeftLegend}
                axisLeftFormat={formatGraphLeftAxis}
                colors={colors}
                options={plotOptions}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CardMetric, reactFastCompare);

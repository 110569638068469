import {
  InfiniteData,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { useMemo } from "react";
import { BasePortalProductDto, SortModel } from "src/api/types";
import { CategoryRankingOrderOptionType } from "src/components/storybook/Categories/CategoryRankingOrder/CategoryRankingOrderOption";
import { QueryId, getQueryKey } from "src/queries/queries";

export type ProductOrderData = InfiniteData<
  GetNotCuratedProductsCallbackReturn | undefined,
  unknown
>;

export type ProductOrderQuery = UseInfiniteQueryResult<ProductOrderData, Error>;

export type GetNotCuratedProductsCallbackReturn = {
  notCuratedOrder: string[];
  notCuratedProducts: { [key: string]: BasePortalProductDto };
  cursor: string | null;
};

export type GetNotCuratedProductsCallback = (
  merchantId: string,
  categoryId: string,
  productsToExclude: string[],
  productsToInclude: string[],
  cursor: string | null,
  sortBy: SortModel | undefined,
  chunkType?: CategoryRankingOrderOptionType
) => Promise<GetNotCuratedProductsCallbackReturn>;

export type GetNotCuratedOrderProductsConfig = {
  enabled: boolean;
  querySalt: string;
  callback: GetNotCuratedProductsCallback;
};

export function useCategoryNotCuratedProducts(
  merchantId: string | null,
  categoryId: string | null,
  productsToExclude: string[] | undefined,
  productsToInclude: string[],
  getNotCuratedProductsConfig: GetNotCuratedOrderProductsConfig,
  sortBy: SortModel | undefined,
  isPreviewingProducts: boolean,
  chunkType: CategoryRankingOrderOptionType | null
) {
  const enabled =
    !!merchantId &&
    !!categoryId &&
    getNotCuratedProductsConfig.enabled &&
    !!productsToExclude &&
    !isPreviewingProducts;

  const { callback } = getNotCuratedProductsConfig;

  const getProductsCB = async ({ pageParam }: { pageParam: string | null }) => {
    const canRun =
      !!merchantId &&
      !!categoryId &&
      productsToExclude !== null &&
      productsToExclude !== undefined;

    if (canRun) {
      const response = await callback(
        merchantId,
        categoryId,
        productsToExclude,
        productsToInclude,
        pageParam,
        sortBy,
        chunkType || undefined
      );

      return response;
    }
  };

  const productOrderQuery: ProductOrderQuery = useInfiniteQuery({
    queryKey: getQueryKey(
      QueryId.GetCategoryProductsOrder,
      merchantId || "",
      categoryId || "",
      getNotCuratedProductsConfig.querySalt,
      sortBy,
      chunkType || undefined
    ),
    queryFn: getProductsCB,
    enabled,
    refetchOnMount: false,
    getNextPageParam: (lastPage) => {
      return lastPage?.cursor || null;
    },
    initialPageParam: null,
  });

  const productOrder = useMemo(() => {
    return getProductOrder(productOrderQuery.data);
  }, [productOrderQuery.data]);

  return {
    notCuratedProducts: productOrder,
    getMoreNotCuratedProducts: productOrderQuery.fetchNextPage,
    hasMoreNotCuratedProducts: !!productOrderQuery.hasNextPage,
    isLoading: productOrderQuery.isLoading,
    isError: productOrderQuery.isError,
    refetch: productOrderQuery.refetch,
    isFetchingNextPage: productOrderQuery.isFetchingNextPage,
  };
}
export function getProductOrder(data: ProductOrderData | undefined) {
  const productDTOMapping: { [key: string]: BasePortalProductDto } = {};
  data?.pages.forEach((page) => {
    Object.values(page?.notCuratedProducts ?? []).forEach((product) => {
      productDTOMapping[product.main_product_id] = product;
    });
  });
  return (
    data?.pages
      .map((page) => {
        return page?.notCuratedOrder ?? [];
      })
      .flat()
      .map((productId) => productDTOMapping[productId]) ?? []
  );
}

import { useCallback, useState } from "react";
import { getAuthorizedApi, getData } from "../../../api/authorizedApi";
import useAuthentication from "../../../helpers/hooks/app/useAuthentication";
import CentraApiConfig from "../../storybook/Integrations/Centra/CentraApiConfig";

const useCentraApiCredentialsVerification = (merchantId: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [verificationSuccess, setVerificationSuccess] =
    useState<boolean>(false);
  const [serverError, setServerError] = useState<string | null>(null);
  const [apiUrlError, setApiUrlError] = useState<string | null>(null);
  const [apiKeyError, setApiKeyError] = useState<string | null>(null);
  const [getAccessToken] = useAuthentication();

  const verifyApiCredentials = useCallback(
    async (apiUrl: string, apiKey: string) => {
      const api = getAuthorizedApi(await getAccessToken());
      setIsLoading(true);
      try {
        const response = await api.POST(
          "/api/v0/integrations/centra/verify-api-credentials",
          {
            body: {
              api_key: apiKey,
              api_url: apiUrl,
            },
          }
        );

        const data = getData(response);

        setIsLoading(false);
        setVerificationSuccess(data.status === "success");
        setApiUrlError(data.api_url_error ?? null);
        setApiKeyError(data.api_key_error ?? null);
        setServerError(null);
      } catch (e: any | { message: string }) {
        setIsLoading(false);
        setVerificationSuccess(false);
        setApiUrlError(null);
        setApiKeyError(null);
        setServerError(e.message);
      }
    },
    [getAccessToken]
  );

  return {
    verificationSuccess,
    isLoading,
    apiUrlError,
    apiKeyError,
    serverError,
    verifyApiCredentials,
  };
};

export default function CentraApiConfigStep({
  merchant,
  apiUrl,
  apiKey,
  onSubmit,
  onBack,
}: {
  merchant: string;
  apiUrl: string;
  apiKey: string;
  onSubmit: (apiUrl: string, apiKey: string) => void;
  onBack: () => void;
}) {
  const {
    verificationSuccess,
    isLoading,
    apiUrlError,
    apiKeyError,
    serverError,
    verifyApiCredentials,
  } = useCentraApiCredentialsVerification(merchant);

  return (
    <CentraApiConfig
      isLoading={isLoading}
      verifyCredentialsResponse={{
        status: verificationSuccess ? "success" : "failed",
        errors: {
          apiUrlError: apiUrlError,
          apiKeyError: apiKeyError,
        },
      }}
      apiUrl={apiUrl}
      apiKey={apiKey}
      errorMessage={serverError}
      onVerify={(apiUrl, apiKey) => {
        verifyApiCredentials(apiUrl, apiKey);
      }}
      onSubmit={(apiUrl, apiKey) => {
        onSubmit(apiUrl, apiKey);
      }}
      onBack={onBack}
    />
  );
}

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Guitar = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M9.5 11c-0.61 0-1.192-0.123-1.638-0.346-0.556-0.278-0.862-0.688-0.862-1.154s0.306-0.876 0.862-1.154c0.446-0.223 1.027-0.346 1.638-0.346s1.192 0.123 1.638 0.346c0.556 0.278 0.862 0.688 0.862 1.154s-0.306 0.876-0.862 1.154c-0.446 0.223-1.027 0.346-1.638 0.346zM9.5 9c-0.451 0-0.885 0.088-1.191 0.24-0.256 0.128-0.309 0.243-0.309 0.26s0.054 0.132 0.309 0.26c0.305 0.153 0.739 0.24 1.191 0.24s0.885-0.088 1.191-0.24c0.256-0.128 0.309-0.243 0.309-0.26s-0.054-0.132-0.309-0.26c-0.305-0.153-0.739-0.24-1.191-0.24z" fill={color}></path>
<path d="M12.499 14h-6c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h6c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z" fill={color}></path>
<path d="M16.17 11.648c-0.521-0.371-1.17-0.833-1.17-1.148 0-0.294 0.038-0.603 0.074-0.903 0.096-0.792 0.205-1.691-0.424-2.401-0.491-0.554-1.366-0.902-2.74-1.072l-0.793-3.638c0.22-0.053 0.383-0.25 0.383-0.486 0-0.065-0.012-0.127-0.035-0.183l-0.492-1.475c-0.068-0.204-0.259-0.342-0.474-0.342h-2c-0.215 0-0.406 0.138-0.474 0.342l-0.5 1.5c-0.051 0.152-0.025 0.32 0.069 0.45 0.071 0.098 0.173 0.166 0.288 0.194l-0.794 3.638c-1.374 0.17-2.248 0.518-2.739 1.072-0.629 0.71-0.52 1.608-0.423 2.4 0.036 0.3 0.074 0.61 0.074 0.904 0 0.315-0.649 0.777-1.17 1.149-0.858 0.611-1.83 1.304-1.83 2.351v3c0 0.519 0.203 0.983 0.603 1.379 0.368 0.364 0.912 0.669 1.616 0.907 1.4 0.474 3.513 0.714 6.28 0.714s4.88-0.24 6.281-0.714c0.704-0.238 1.248-0.544 1.616-0.907 0.4-0.396 0.603-0.86 0.603-1.379v-3c0-1.048-0.972-1.74-1.83-2.352zM8.86 1h1.279l0.167 0.5h-1.613l0.167-0.5zM8.903 2.5h1.195l0.77 3.534c-0.42-0.023-0.875-0.034-1.368-0.034s-0.948 0.011-1.368 0.034l0.771-3.534zM3.41 12.463c0.782-0.557 1.59-1.132 1.59-1.963 0-0.355-0.041-0.695-0.082-1.024-0.094-0.771-0.135-1.261 0.179-1.617 0.505-0.57 1.986-0.859 4.401-0.859s3.897 0.289 4.402 0.859c0.315 0.355 0.274 0.846 0.18 1.617-0.040 0.329-0.081 0.669-0.081 1.024 0 0.831 0.808 1.406 1.59 1.963 0.661 0.471 1.41 1.005 1.41 1.537 0 1.252-2.804 2-7.501 2-4.696 0-7.499-0.748-7.499-2 0-0.532 0.749-1.066 1.41-1.537zM9.5 19c-4.696 0-7.5-0.748-7.5-2v-1.297c0.327 0.224 0.735 0.419 1.219 0.583 1.4 0.474 3.513 0.714 6.28 0.714s4.881-0.24 6.281-0.714c0.484-0.164 0.893-0.359 1.22-0.583v1.297c0 1.252-2.804 2-7.5 2z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Guitar); 
import React, { useMemo } from "react";
import {
  DataPeriod,
  PeriodIdentifiers,
  FilterPeriodProps,
  DropDownSelectorOption,
} from "src/types/components";
import mergeClassNames from "merge-class-names";
import { deepEqual } from "src/helpers";
import {
  compensateDatepickerOutput,
  compensateUTC,
  getDataPeriodSpanText,
  getDefaultEndDate,
  getDefaultStartDate,
} from "src/helpers/dates";
import i18n from "src/i18n";
import { useTranslation } from "react-i18next";
import useMinDate from "src/helpers/hooks/mix/useMinDate";

/**
 * Components
 */
import DatePicker from "react-datepicker";
import { CalendarEmpty } from "src/components/linearicons";
import { useEffect, useRef, useState } from "react";
import CustomInput from "src/components/mix/CustomInput";
import { isPeriodOptionDisabled } from "src/helpers/compare";
import {
  customSpan,
  monthSpan,
  fullYearSpan,
  sinceStart,
  quarterSpan,
} from "src/helpers/filters/periodFilterFunctions";
import {
  monthOptions,
  quarterOptions,
  yearOptions,
} from "src/helpers/filters/periodFilterOptions";
import DropDownSelector from "src/components/mix/DropDownSelector";
import config from "src/config";

const FilterPeriod = (props: FilterPeriodProps) => {
  const { period, startTimestamp, setPeriodCB } = props;

  const buttonActions = {
    month: monthSpan,
    quarter: quarterSpan,
    "one-year": fullYearSpan,
    "since-start": (_: DataPeriod | undefined) =>
      sinceStart(
        startTimestamp ? { start: startTimestamp, end: -1 } : undefined
      ),
    custom: customSpan,
  };
  const buttonOptions = {
    month: monthOptions(new Date()),
    quarter: quarterOptions(new Date()),
    "one-year": yearOptions(new Date(), 5),
    "since-start": null,
    custom: null,
  };
  const { t } = useTranslation();
  const customTimeout = useRef<ReturnType<typeof setTimeout>>();

  const [selectedPeriod, setSelectedPeriod] =
    useState<PeriodIdentifiers>("month");
  const [selectedPeriodOption, setSelectedPeriodOption] =
    useState<DropDownSelectorOption | null>(buttonOptions.month[0]);
  const [startDate, setStartDate] = useState<Date>(getDefaultStartDate());
  const [endDate, setEndDate] = useState<Date>(getDefaultEndDate());

  const startDatePickerRef = useRef<any>(null);
  const endDatePickerRef = useRef<any>(null);

  const [dropdownPanelClasses, setDropdownPanelClasses] = useState("");

  const periodSpanText = useMemo(() => {
    if (typeof period === "string") {
      console.error(
        `We are still having periods that are strings??? ${period}`
      );
      return "";
    }
    return getDataPeriodSpanText(period, true);
  }, [period]);

  const buttonOnClick = (newPeriodId: PeriodIdentifiers) => {
    setDropdownPanelClasses("");
    if (newPeriodId === selectedPeriod) {
      return;
    }
    setSelectedPeriod(newPeriodId);
    const newOptions = buttonOptions[newPeriodId];
    // If there are options, select the first one, otherwise call the button action
    const newPeriod = newOptions
      ? newOptions[0].value
      : buttonActions[newPeriodId](undefined);
    if (deepEqual(newPeriod, period)) {
      return;
    }
    setPeriodCB(newPeriod);
  };

  const customOnClick = () => {
    if (dropdownPanelClasses === "") {
      setDropdownPanelClasses("depict--filterPanel__open");
      console.log(
        "%c[EXPANDABLE PANEL Period] Setting timeout",
        "color: hotpink"
      );
      customTimeout.current = setTimeout(() => {
        console.log("%c[EXPANDABLE PANEL Period] Running", "color: hotpink");
        setDropdownPanelClasses(
          "depict--filterPanel__open depict--filterPanel__allowOverflow"
        );
      }, 300);
    } else {
      setDropdownPanelClasses("");
    }

    setSelectedPeriod("custom" as PeriodIdentifiers);

    let span: DataPeriod = {
      start: Math.floor(+startDate / 1000),
      end: Math.floor(+endDate / 1000),
    };

    if (deepEqual(span, period)) {
      return;
    }

    setPeriodCB(span);
  };

  const periodOptionSelected = (newOption: DropDownSelectorOption) => {
    setSelectedPeriodOption(newOption);
    setPeriodCB(newOption.value);
  };

  useEffect(() => {
    if (selectedPeriod !== "custom") {
      return;
    }

    console.log(`Changed to period ${+startDate} -> ${+endDate}`);

    let span: DataPeriod = {
      start: Math.floor(+startDate / 1000),
      end: Math.floor(+endDate / 1000),
    };

    if (deepEqual(span, period)) {
      return;
    }

    setPeriodCB(span);
  }, [selectedPeriod, period, startDate, endDate, setPeriodCB]);

  useEffect(() => {
    if (buttonOptions[selectedPeriod]) {
      setSelectedPeriodOption(
        buttonOptions[selectedPeriod]?.at(0) as DropDownSelectorOption
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeriod]);

  useEffect(() => {
    return () => {
      console.log(
        "%c[EXPANDABLE PANEL Period] Clearing timeout",
        "color: hotpink"
      );
      clearTimeout(customTimeout.current as ReturnType<typeof setTimeout>);
    };
  }, []);

  const [minDate, minTimestamp] = useMinDate(startTimestamp);

  useEffect(() => {
    if (+startDate < +minDate) {
      setStartDate(minDate);
    }
  }, [minDate, startDate]);

  const openStartDatePicker = () => {
    const datepickerElement = startDatePickerRef.current;
    datepickerElement.setOpen(true);
  };

  const openEndDatePicker = () => {
    const datepickerElement = endDatePickerRef.current;
    datepickerElement.setOpen(true);
  };

  return (
    <div
      className={mergeClassNames(
        dropdownPanelClasses,
        "depict--filterPeriodSection"
      )}
      data-testid="depict--filterPeriodSection"
    >
      <label htmlFor="period" className="form-label">
        Period
      </label>
      <div className="depict--sectionTop">
        <div
          className="btn-group border border-primary-light border-1 rounded-pill"
          role="group"
          aria-label="Period"
          style={{ display: "flex" }}
        >
          {Object.keys(buttonActions).map((label, index) => {
            let isDisabled = isPeriodOptionDisabled(period, minTimestamp);
            let isSelected = selectedPeriod === label;

            const hasCompressed = i18n.exists(
              `filters.period.buttons.${label}.compressed`
            );

            return (
              <button
                key={index}
                data-testid={"button-period-" + label}
                type="button"
                className={mergeClassNames(
                  "btn rounded-pill",
                  isSelected &&
                    "dpt-color-background-neutral-default fw-bolder",
                  isDisabled && "btn-disabled"
                )}
                onClick={() => {
                  if (isDisabled) {
                    return;
                  }
                  if (label !== "custom") {
                    buttonOnClick(label as PeriodIdentifiers);
                  } else {
                    customOnClick();
                  }
                }}
              >
                <span
                  className={mergeClassNames(
                    hasCompressed && "d-none d-xxl-block"
                  )}
                >
                  {t(`filters.period.buttons.${label}.regular`)}
                </span>
                {hasCompressed && (
                  <span
                    className={mergeClassNames(hasCompressed && "d-xxl-none")}
                  >
                    {t(`filters.period.buttons.${label}.compressed`)}
                  </span>
                )}
              </button>
            );
          })}
        </div>
      </div>
      <div className="depict--sectionMiddle">
        <div className="rounded-pill border border-1 border-primary-light d-flex justify-content-around">
          <div className="depict--datepickerWrapper flex-grow-0">
            <div className="input-group input-group-sm rounded-pill">
              <DatePicker
                ref={startDatePickerRef}
                dateFormat={config.const.dateFormat}
                selected={compensateUTC(startDate)}
                onChange={(date) => {
                  setStartDate(compensateDatepickerOutput(date as Date));
                }}
                selectsStart
                minDate={compensateUTC(minDate)}
                startDate={compensateUTC(startDate)}
                endDate={compensateUTC(endDate)}
                customInput={<CustomInput />}
              >
                <h6
                  className="text-center pt-1 pb-1 m-0 fw-bolder"
                  style={{ backgroundColor: "#f0f0f0", fontSize: "0.75rem" }}
                >
                  Start Date
                </h6>
              </DatePicker>
              <span className="input-group-text" onClick={openStartDatePicker}>
                <CalendarEmpty size={10} />
              </span>
            </div>
          </div>
          <div className="depict--datepickerWrapper flex-grow-0">
            <div className="input-group input-group-sm  rounded-pill">
              <DatePicker
                ref={endDatePickerRef}
                dateFormat={config.const.dateFormat}
                selected={compensateUTC(endDate)}
                onChange={(date) => {
                  setEndDate(compensateDatepickerOutput(date as Date));
                }}
                selectsEnd
                startDate={compensateUTC(startDate)}
                endDate={compensateUTC(endDate)}
                minDate={compensateUTC(startDate)}
                maxDate={compensateUTC(getDefaultEndDate())}
                customInput={<CustomInput />}
              >
                {" "}
                <h6
                  className="text-center pt-1 pb-1 m-0 fw-bolder"
                  style={{ backgroundColor: "#f0f0f0", fontSize: "0.75rem" }}
                >
                  End Date
                </h6>
              </DatePicker>
              <span className="input-group-text" onClick={openEndDatePicker}>
                <CalendarEmpty size={10} />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="depict--sectionBottom mt-2">
        <div className="d-flex justify-content-center align-items-center depict--calendarBasedWrapper">
          <div className="depict--calendarBasedSelectorWrapper">
            {buttonOptions[selectedPeriod] && selectedPeriodOption && (
              <DropDownSelector
                selected={selectedPeriodOption}
                options={
                  buttonOptions[selectedPeriod] as DropDownSelectorOption[]
                }
                newSelection={periodOptionSelected}
              />
            )}
          </div>
          {buttonOptions[selectedPeriod] && selectedPeriodOption && (
            <div
              className="h-75"
              style={{
                backgroundColor: "#BCBCBE",
                width: "1px",
                borderRadius: "0.5px",
                margin: "0 16px 0 8px",
              }}
            />
          )}
          <div className="depict--calendarBasedInfoWrapper">
            <label className="form-label mb-0">{periodSpanText}</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(FilterPeriod);

import { ListItemSupported } from "src/types/components";
import { ListItemIdKey } from "src/types/configuration";

interface ApiConfig {
  baseUrl: string;
  version: string;
}

interface ApiClientConfig {
  baseUrl: string;
}

interface UserConfig {
  email: string;
  password: string;
}

interface Config extends Object {
  publicUrl: string;
  api: ApiConfig;
  apiClient: ApiClientConfig;
  user: UserConfig;
  const: {
    [key: string]: any;
  };
  dataMock: boolean;
  publicViews: string[];
  plotColors: string[];
  pointsInMiniGraph: number;
  configurationListItemTypeToId: {
    [key in ListItemSupported]: ListItemIdKey;
  };
  hotjar: {
    id: number;
    version: number;
  };
}

const config: Config = {
  publicUrl: process.env.PUBLIC_URL ?? "/",
  api: {
    baseUrl: process.env.API_BASE_URL ?? `${window.location.origin}/api`,
    version: process.env.API_VERSION ?? "v0",
  },
  // We add this extra config for the storybook API client since the base URL doesn't include /api for this one. Once the old way of calling portal backend is completely removed, we can switch to only having this.
  apiClient: {
    baseUrl: process.env.API_CLIENT_BASE_URL ?? `${window.location.origin}`,
  },
  user: {
    email: process.env.USER_EMAIL ?? "",
    password: process.env.USER_PASSWORD ?? "",
  },
  dataMock: process.env.DATA_MOCK === "true" ?? "false",
  const: {
    defaultCompareOffset: 60 * 60 * 24 * 7, //one week in seconds
    millisecondsInDay: 864e5,
    secondsInDay: 60 * 60 * 24,
    secondsInMonth: 60 * 60 * 24 * (365 / 12),
    secondsInYear: 60 * 60 * 24 * 365,
    bootstrapXsWidth: 576,
    bootstrapSmWidth: 768,
    bootstrapLgWidth: 992,
    bootstrapXlWidth: 1200,
    bootstrapXxlWidth: 1500,
    bootstrapTooltipDelay: { show: 1000, hide: 0 },
    defaultConfigurationDisplayName: "-- No Name --",
    compareSurfaceSuffix: "-depict-compare",
    compareMockTrigger: {
      start: 123456789,
      end: 987654321,
    },
    dateFormatGraph: "%Y-%m-%d",
    dateFormat: "yyyy-MM-dd",
    dateFormatSeparator: "-",
  },
  pointsInMiniGraph: 10,
  publicViews: ["/login", "/recover-password", "/reset-password"],
  plotColors: [
    "#7395D3",
    "#5B9EA6",
    "#9B7CE1",
    "#868689",
    "#333335",
    "#D47C7C",
    "#633EB3",
    "#4695FB",
    "#809E5D",
  ],
  configurationListItemTypeToId: {
    main_product: "main_product_id",
    product: "product_id",
    group: "group_id",
    configuration: "id",
    unknown: "id",
  },
  hotjar: {
    id: process.env.HOTJAR_ID ? parseInt(process.env.HOTJAR_ID) : 3074961,
    version: process.env.HOTJAR_VERSION
      ? parseInt(process.env.HOTJAR_VERSION)
      : 6,
  },
};

export default config;

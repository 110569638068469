import { BasePortalProductDto } from "src/api/types";
import WarningAlert from "src/components/storybook/Alert/Alerts/WarningAlert";
import { OnSelect } from "src/components/storybook/BulkEditBar/BulkEditBar.types";
import { CategoryProductListView } from "src/components/storybook/Categories/CategoryProductListView/CategoryProductListView";
import { CategoryProductGridView } from "src/components/storybook/CategoryProductGridView/CategoryProductGridView";
import { Product } from "src/components/storybook/types";

interface ProductGridViewProduct extends Product {
  pinned: boolean;
  hidden: boolean;
}

interface PinningOptions {
  pinningAllowed: boolean;
  onPinClick: (mainProductId: string) => void;
  onAiSortedClick: (mainProductId: string) => Promise<void> | void;
  onPinToTop: (mainProductId: string) => void;
}

interface HidingOptions {
  hidingAllowed: boolean;
  onHideClick: (mainProductId: string) => void;
  onAiSortedClick: (mainProductId: string) => Promise<void> | void;
}

interface ProductDisplayerProps {
  products: ProductGridViewProduct[];
  onRemoveClick?: ((mainProductId: string) => void) | undefined;
  onPinClick?: (mainProductId: string) => void;
  pinningOptions: Partial<PinningOptions>;
  hidingOptions: Partial<HidingOptions>;
  pinnedProducts: BasePortalProductDto[];
  hiddenProducts: BasePortalProductDto[];
  view: "list" | "grid";
  loading: boolean;
  selectedIds: Set<string>;
  disableDropdown?: boolean;
  onSelectClick?: OnSelect;
  productsToHighlight: string[];
  error: boolean;
  columns: number;
  aiSorted: boolean;
  onScrollAtBottom?: () => void;
  isLoadingMore: boolean;
}
const ProductDisplayer = (props: ProductDisplayerProps) => {
  const { view, ...otherProps } = props;

  if (props.error) {
    return (
      <WarningAlert
        message={
          "Couldn't load products. Please try again later, or contact Depict if the problem persists."
        }
      />
    );
  }

  if (view === "grid") return <CategoryProductGridView {...otherProps} />;
  else
    return (
      <CategoryProductListView
        {...otherProps}
        showSelectCheckbox={!!otherProps.onSelectClick}
      />
    );
};

export default ProductDisplayer;

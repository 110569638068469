import { useCallback } from "react";
import { BasePortalProductDto, HiddenPortalProductDto } from "src/api/types";
import { OnSelect } from "../../BulkEditBar/BulkEditBar.types";
import { RenderCardCB } from "../../ProductGridView/ProductGridView";
import ProductListView from "../../ProductListView/ProductListView";
import { Product } from "../../types";
import CategoryProductListCard from "../CategoryProductListCard/CategoryProductListCard";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface PinningOptions {
  pinningAllowed: boolean;
  onPinClick: (mainProductId: string) => void;
  onAiSortedClick: (mainProductId: string) => void;
  onPinToTop: (mainProductId: string) => void;
}

interface HidingOptions {
  hidingAllowed: boolean;
  onHideClick: (mainProductId: string) => void;
  onAiSortedClick: (mainProductId: string) => void;
}

interface ProductListViewProduct extends Product {
  pinned: boolean;
  hidden: boolean;
}

interface CategoryProductListViewProps {
  products: ProductListViewProduct[];
  onRemoveClick?: ((mainProductId: string) => void) | undefined;
  onPinClick?: (mainProductId: string) => void;
  pinningOptions: Partial<PinningOptions>;
  hidingOptions: Partial<HidingOptions>;
  pinnedProducts: BasePortalProductDto[];
  hiddenProducts: HiddenPortalProductDto[];
  loading: boolean;
  selectedIds: Set<string>;
  productsToHighlight: string[];
  showSelectCheckbox: boolean;
  disableDropdown?: boolean;
  onSelectClick?: OnSelect;
  aiSorted: boolean;
  onScrollAtBottom?: () => void;
  isLoadingMore: boolean;
}

export const CategoryProductListView = (
  props: CategoryProductListViewProps
) => {
  const pinnedProductsIds = props.pinnedProducts?.map((p) => p.main_product_id);
  const hiddenProductsIds = props.hiddenProducts?.map((p) => p.main_product_id);
  const autoHiddenProductsIds = props.hiddenProducts
    ?.filter((p) => p.hidden_type === "auto_oos")
    .map((p) => p.main_product_id);

  const isPinned = useCallback(
    (mainProductId: string): boolean => {
      return pinnedProductsIds.includes(mainProductId) ?? false;
    },
    [pinnedProductsIds]
  );

  const isHidden = useCallback(
    (mainProductId: string): boolean => {
      return hiddenProductsIds.includes(mainProductId) ?? false;
    },
    [hiddenProductsIds]
  );

  const isAutoHidden = useCallback(
    (mainProductId: string): boolean => {
      return autoHiddenProductsIds.includes(mainProductId) ?? false;
    },
    [autoHiddenProductsIds]
  );

  const isInSelectMode = props.selectedIds.size > 0;

  const renderCard = useCallback<RenderCardCB<Product>>(
    (item, index) => {
      const highlighted = props.productsToHighlight.includes(
        item.main_product_id
      );

      const pinningOptions = {
        pinningAllowed: !!props.pinningOptions.pinningAllowed,
        pinned: isPinned(item.main_product_id),
      };

      const hidingOptions = {
        hidingAllowed: !!props.hidingOptions.hidingAllowed,
        hidden: isHidden(item.main_product_id),
      };

      const categoryProductListCard = (
        <CategoryProductListCard
          product={item}
          disableDropdown={props.disableDropdown}
          pinningOptions={pinningOptions}
          hidingOptions={hidingOptions}
          index={index}
          disableIndex={false}
          actionCBs={{
            onPinToTop: props.pinningOptions.onPinToTop,
            onPinClick: props.pinningOptions.onPinClick,
            onHideClick: props.hidingOptions.onHideClick,
            onAiSortedClick: props.hidingOptions.onAiSortedClick,
            onRemoveClick: props.onRemoveClick,
            onSelectClick: props.onSelectClick,
          }}
          highlight={highlighted}
          showSelectCheckbox={props.showSelectCheckbox}
          selected={props.selectedIds.has(item.main_product_id)}
          isInSelectMode={isInSelectMode}
          aiSorted={props.aiSorted}
          isAutoHidden={isAutoHidden(item.main_product_id)}
        />
      );
      const tooltip = (
        <Tooltip id="tooltip">
          The product is auto hidden go to catalog to un hide it
        </Tooltip>
      );
      if (isAutoHidden(item.main_product_id))
        return (
          <OverlayTrigger placement="top" overlay={tooltip}>
            <span>{categoryProductListCard}</span>
          </OverlayTrigger>
        );
      else return categoryProductListCard;
    },
    [
      props.productsToHighlight,
      props.pinningOptions.pinningAllowed,
      props.pinningOptions.onPinToTop,
      props.pinningOptions.onPinClick,
      props.hidingOptions.hidingAllowed,
      props.hidingOptions.onHideClick,
      props.hidingOptions.onAiSortedClick,
      props.disableDropdown,
      props.onRemoveClick,
      props.onSelectClick,
      props.showSelectCheckbox,
      props.selectedIds,
      props.aiSorted,
      isPinned,
      isHidden,
      isInSelectMode,
      isAutoHidden,
    ]
  );

  return (
    <ProductListView
      items={props.products}
      itemIdKey={"main_product_id"}
      renderCard={renderCard}
      loading={props.loading}
      onScrollAtBottom={props.onScrollAtBottom}
      isLoadingMore={props.isLoadingMore}
    />
  );
};

import { useEffect, useState } from "react";
import useRefQuerySelector from "src/helpers/hooks/useRefQuerySelector";
import { useDebounce } from "usehooks-ts";

interface UseComponentHeightProps {
  selector: string;
}
export const useComponentHeight = (props: UseComponentHeightProps) => {
  const [componentRef] = useRefQuerySelector(props.selector);
  const [componentHeight, setComponentHeight] = useState(0);
  const debouncedSetComponentHeight = useDebounce(componentHeight, 500);

  useEffect(() => {
    if (!componentRef) return;

    const observer = new ResizeObserver((entries) => {
      const { height } = entries[0].contentRect;

      setComponentHeight(height);
    });
    observer.observe(componentRef);

    return () => {
      observer.disconnect();
    };
  }, [debouncedSetComponentHeight, componentRef, props.selector]);

  return debouncedSetComponentHeight;
};

import React from "react";
import { theme } from "../../designSystemVariables";
import {
  PiShirtFoldedFill,
  PiStarFill,
  PiStarHalfFill,
  PiTShirtFill,
} from "react-icons/pi";
import { CheckCircle } from "@phosphor-icons/react";
import "./CategoryRankingOrder.scss";

const RankingTile = (props: { background: string; icon: React.ReactNode }) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center flex-grow-1"
      style={{
        height: "120px",
        maxWidth: "80px",
        borderRadius: "0.25rem",
        background: props.background,
      }}
    >
      {props.icon}
    </div>
  );
};

export enum CategoryRankingOrderOptionType {
  Top = "top",
  Similar = "similar",
  Complementary = "complementary",
}

export interface RankingOption {
  type: CategoryRankingOrderOptionType;
  available: boolean;
}

const shirtFoldedIcon = <PiShirtFoldedFill size={20} color={"black"} />;
const shirtIcon = <PiTShirtFill size={20} color={"black"} />;
const starIcon = <PiStarFill size={20} color={"black"} />;
const halfStarIcon = <PiStarHalfFill size={20} color={"black"} />;

export const typeToTitle: Record<CategoryRankingOrderOptionType, string> = {
  top: "Standard",
  similar: "Grouped",
  complementary: "Matched",
};

const typeToDescription: Record<CategoryRankingOrderOptionType, string> = {
  top: "Products are sorted by our smart sorting according to your boost & bury rules",
  similar:
    "Products are paired with similar products and sorted according to your boost & bury rules",
  complementary:
    "Products are paired with complementary products and sorted according to your boost & bury rules",
};

const typeToTileList: Record<
  CategoryRankingOrderOptionType,
  (() => JSX.Element)[]
> = {
  top: [
    () => (
      <RankingTile
        background={theme.background.accent.default}
        icon={
          <span>
            {starIcon}
            {starIcon}
            {starIcon}
          </span>
        }
      />
    ),
    () => (
      <RankingTile
        background={theme.background.info.default}
        icon={
          <span>
            {starIcon}
            {starIcon}
          </span>
        }
      />
    ),
    () => (
      <RankingTile
        background={theme.background.warning.default}
        icon={
          <span>
            {starIcon}
            {halfStarIcon}
          </span>
        }
      />
    ),
    () => (
      <RankingTile
        background={theme.background.danger.default}
        icon={<span>{starIcon}</span>}
      />
    ),
  ],
  similar: [
    () => (
      <RankingTile
        background={theme.background.info.default}
        icon={shirtFoldedIcon}
      />
    ),
    () => (
      <RankingTile
        background={theme.background.info.default}
        icon={shirtFoldedIcon}
      />
    ),
    () => (
      <RankingTile
        background={theme.background.accent.default}
        icon={shirtIcon}
      />
    ),
    () => (
      <RankingTile
        background={theme.background.accent.default}
        icon={shirtIcon}
      />
    ),
  ],
  complementary: [
    () => (
      <RankingTile
        background={theme.background.info.default}
        icon={shirtFoldedIcon}
      />
    ),
    () => (
      <RankingTile
        background={theme.background.accent.default}
        icon={shirtIcon}
      />
    ),
    () => (
      <RankingTile
        background={theme.background.info.default}
        icon={shirtFoldedIcon}
      />
    ),
    () => (
      <RankingTile
        background={theme.background.accent.default}
        icon={shirtIcon}
      />
    ),
  ],
};

export const CategoryRankingOrderOption = (props: {
  type: CategoryRankingOrderOptionType;
  selected: boolean;
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <div
      style={{
        background: theme.background.base.default,
        borderColor: props.selected ? "black" : undefined,
        cursor: props.disabled ? "not-allowed" : "pointer",
        opacity: props.disabled ? 0.5 : 1,
        flexBasis: 0,
        flexGrow: 1,
      }}
      className="d-flex flex-column p-3 depict--CategoryRankingOrder__option"
      onClick={() => {
        if (props.disabled) return;
        props.onClick();
      }}
    >
      {/* Checkmark icon */}
      {props.selected && (
        <div className="depict--CategoryRankingOrder__badges">
          <span className="depict--CategoryRankingOrder__icon">
            <CheckCircle size={16} color="white" />
          </span>
        </div>
      )}
      <h6 className="mb-1 fw-700">{typeToTitle[props.type]}</h6>
      <p className="text-wrap text-xs">{typeToDescription[props.type]}</p>
      <div className="d-flex justify-content-around flex-grow-1 align-items-end">
        {typeToTileList[props.type].map((Element, index) => (
          <Element key={index} />
        ))}
      </div>
    </div>
  );
};

export default CategoryRankingOrderOption;

import React, { useCallback, useMemo, useState } from "react";
import { AiControlProps } from "src/types/components";
import { Warning } from "src/components/linearicons";

import { getModalContent } from "src/helpers/modals";
import Modal from "src/components/mix/Modal";
import { useTranslation } from "react-i18next";

const AiControl = (props: AiControlProps) => {
  const { t } = useTranslation();

  const { disabled, displayName, aiControl, aiControlModifyCB } = props;
  const [modal, setModal] = useState<boolean>(false);

  const closeCallback = useCallback(() => {
    setModal(false);
  }, []);

  const proceedCallback = useCallback(() => {
    aiControlModifyCB(!aiControl.view);
    closeCallback();
  }, [aiControl.view, aiControlModifyCB, closeCallback]);

  const aiModalContent = useMemo(
    () =>
      getModalContent({
        id: "disable-ai",
        buttonCallbacks: { cancel: closeCallback, proceed: proceedCallback },
        stringInterpolations: [
          { placeholder: "configuration_name", value: displayName },
        ],
      }),
    [closeCallback, displayName, proceedCallback]
  );

  return (
    <>
      <div
        data-bs-toggle="tooltip"
        title={t("tooltips.configuration.aicontrol")}
      >
        <div className="depict--ConfigurationSettings__title">AI Control</div>
        <div className="depict--ConfigurationSettings__subtitle mb-2">
          Set if AI should add recommendations.
        </div>
      </div>
      <div className="depict--ConfigurationSettings__form">
        <div
          className="form-check form-switch mb-3 d-block"
          data-bs-toggle="tooltip"
          title={t("tooltips.configuration.aicontrol")}
        >
          <input
            data-tc="checkbox-disable-ai"
            className="form-check-input"
            type="checkbox"
            id="aiControl"
            disabled={disabled}
            checked={aiControl.view === true}
            onChange={() => {
              if (!aiControl.view === true) {
                setModal(true);
                return;
              }

              aiControlModifyCB(!aiControl.view);
            }}
          />
          <label className="form-check-label" htmlFor="aiControl">
            Disable AI recommendations
          </label>
        </div>
        {aiControl.currentConf === true && (
          <label
            className="text-depict-red d-flex align-items-center"
            style={{ fontSize: ".688rem" }}
          >
            <Warning size="12" style={{ marginRight: ".5rem" }} />
            <span> AI will not add recommendations. </span>
          </label>
        )}
      </div>
      <Modal
        title={
          <>
            {aiModalContent?.title} {aiModalContent?.subtitle}
          </>
        }
        show={modal}
        closeButton={true}
        closeCallback={closeCallback}
        buttons={aiModalContent?.buttons}
      >
        {aiModalContent?.content}
      </Modal>
    </>
  );
};

export default React.memo(AiControl);

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const BorderStyle = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M9 2h1v1h-1v-1z" fill={color}></path>
<path d="M17 18h1v1h-1v-1z" fill={color}></path>
<path d="M9 18h1v1h-1v-1z" fill={color}></path>
<path d="M3 2h1v1h-1v-1z" fill={color}></path>
<path d="M15 2h1v1h-1v-1z" fill={color}></path>
<path d="M3 18h1v1h-1v-1z" fill={color}></path>
<path d="M5 18h1v1h-1v-1z" fill={color}></path>
<path d="M7 18h1v1h-1v-1z" fill={color}></path>
<path d="M11 18h1v1h-1v-1z" fill={color}></path>
<path d="M13 18h1v1h-1v-1z" fill={color}></path>
<path d="M15 18h1v1h-1v-1z" fill={color}></path>
<path d="M1.5 19c-0.276 0-0.5-0.224-0.5-0.5v-16c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5v16c0 0.276-0.224 0.5-0.5 0.5z" fill={color}></path>
<path d="M7.5 3h-2c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h2c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z" fill={color}></path>
<path d="M13.5 3h-2c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h2c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z" fill={color}></path>
<path d="M17.5 5c-0.276 0-0.5-0.224-0.5-0.5v-2c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5v2c0 0.276-0.224 0.5-0.5 0.5z" fill={color}></path>
<path d="M17.5 9c-0.276 0-0.5-0.224-0.5-0.5v-2c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5v2c0 0.276-0.224 0.5-0.5 0.5z" fill={color}></path>
<path d="M17.5 13c-0.276 0-0.5-0.224-0.5-0.5v-2c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5v2c0 0.276-0.224 0.5-0.5 0.5z" fill={color}></path>
<path d="M17.5 17c-0.276 0-0.5-0.224-0.5-0.5v-2c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5v2c0 0.276-0.224 0.5-0.5 0.5z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(BorderStyle); 
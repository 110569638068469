import { useCallback } from "react";

export default function useOpenHelpToSection(toggleHelpModalCB: () => void) {
  const helpSection = useCallback(
    (section: string) => {
      toggleHelpModalCB();
      setTimeout(() => {
        document.getElementById(section)?.scrollIntoView();
      }, 50);
    },
    [toggleHelpModalCB]
  );

  return helpSection;
}

import { DragEndEvent, UniqueIdentifier } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { useCallback } from "react";
import { findContainer, getIndexForId } from "src/helpers/dnd";
import { ContainersItems } from "src/types/dnd";
import { ListItem } from "src/types/components";

type UseOnDragEndProps = {
  items: ContainersItems | null;
  onDragEndCB?: (container: UniqueIdentifier, items: ListItem[]) => any;
  setActiveId: React.Dispatch<React.SetStateAction<UniqueIdentifier | null>>;
  setContainers: React.Dispatch<React.SetStateAction<string[]>>;
};

export default function useOnDragEnd(props: UseOnDragEndProps) {
  const { items, onDragEndCB, setActiveId } = props;

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (!items) {
        setActiveId(null);
        return;
      }

      const activeContainer = findContainer(active.id, items);
      if (!activeContainer) {
        setActiveId(null);
        return;
      }

      const overId = over?.id;

      if (!overId) {
        setActiveId(null);
        return;
      }

      const overContainer = findContainer(overId, items);
      if (overContainer) {
        const activeIndex = getIndexForId(active.id, items[activeContainer]);
        const overIndex = getIndexForId(overId, items[overContainer]);
        if (activeIndex !== overIndex) {
          const newSet = arrayMove(
            items?.[overContainer] ?? [],
            activeIndex,
            overIndex
          );
          if (onDragEndCB) {
            onDragEndCB(overContainer, newSet);
          }
        }
      }
      setActiveId(null);
    },
    [items, onDragEndCB, setActiveId]
  );

  return [onDragEnd];
}

import { useQuery } from "@tanstack/react-query";
import { SortModel } from "src/api/types";
import { QueryId, getQueryKey } from "src/queries/queries";

export type GetAvailableSortsCallback = (
  merchantId: string
) => Promise<SortModel[]>;

export type GetAvailableSortsConfig = {
  enabled: boolean;
  querySalt: string;
  callback: GetAvailableSortsCallback;
};

export function useAvailableSorts(
  merchantId: string | null,
  getAvailableSort: GetAvailableSortsConfig | undefined
) {
  const query = useQuery({
    queryKey: getQueryKey(
      QueryId.GetAvailableSorts,
      merchantId || "",
      getAvailableSort?.querySalt || ""
    ),
    queryFn: () => {
      if (!merchantId) return;

      return getAvailableSort?.callback(merchantId) || null;
    },
    enabled: Boolean(merchantId && getAvailableSort?.enabled),
  });

  return {
    data: query.data || null,
    isLoading: query.isLoading,
    isError: query.isError,
    refetch: query.refetch,
  };
}

import React, {
  useContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { BoostBuryEffect, BoostRule, CollectionListDto } from "src/api/types";
import Button from "src/components/storybook/Button/Button";
import { Modal as DepictModal } from "src/components/storybook/Modal/Modal";
import {
  BoostRuleType,
  getBoostOptions,
  getBuryOptions,
} from "src/helpers/boostbury/availableOptions";
import {
  ArrowCounterClockwise,
  Check,
  Fire,
  Skull,
} from "@phosphor-icons/react";
import BoostAndBuryPreview from "../BoostAndBuryPreview/BoostAndBuryPreview";
import { BoostBuryPreviewContext } from "src/components/boostbury/BoostBuryPreview.provider";
import { theme } from "../../designSystemVariables";
import BoostSaveAnimation from "./BoostSaveAnimation";
import BurySaveAnimation from "./BurySaveAnimation";

export interface BoostBuryModalProps {
  showVariant: BoostBuryEffect | false;
  onClose: () => void;
  onCreateRule: (rule: BoostRule) => void;
  existingRules: BoostRule[];
  collections?: CollectionListDto[];
  ruleToEdit?: BoostRule;
  collectionBoostRuleType: "scollection";
}

export const SaveButton = ({
  selectedOption,
  selectedCollection,
  effect,
  collections,
  ruleIdToEdit,
  onCreate,
  collectionBoostRuleType,
}: {
  selectedOption: BoostRuleType | null;
  selectedCollection: string | null;
  effect: BoostBuryEffect;
  collections?: CollectionListDto[];
  ruleIdToEdit?: number | null;
  onCreate: (rule: BoostRule) => void;
  collectionBoostRuleType: "scollection";
}) => (
  <Button
    disabled={
      selectedOption === null ||
      (selectedOption === collectionBoostRuleType &&
        selectedCollection === null)
    }
    onClick={() => {
      if (selectedOption === null) return;
      const rule: BoostRule = {
        type: selectedOption,
        effect,
      } as BoostRule;
      if (
        rule.type === collectionBoostRuleType &&
        selectedCollection !== null
      ) {
        const chosenCollection = collections?.find(
          (c) => c.collection_id === selectedCollection
        );
        if (!chosenCollection) return;
        rule.collection_id = chosenCollection.collection_id;
        rule.collection_title = chosenCollection.title;
      }
      if (ruleIdToEdit) {
        rule.id = ruleIdToEdit;
      }
      onCreate(rule);
    }}
    variant="primary"
    text={effect === "boost" ? "Boost" : "Bury"}
    icon={null}
    endIcon={effect === "boost" ? <Fire size={16} /> : <Skull size={16} />}
  />
);

const BOOST_DESCRIPTION =
  "Boosted products will be prioritized in all product listings they are part of. It will not affect pinned or hidden products.";
const BURY_DESCRIPTION =
  "Buried products will be deprioritized in all products listings they are part of. Buried products will still be visible for your customers, just harder to find.";

export const BoostBuryModal = ({
  showVariant,
  onClose,
  onCreateRule,
  existingRules,
  collections,
  ruleToEdit,
  collectionBoostRuleType,
}: BoostBuryModalProps) => {
  const options = useMemo(
    () =>
      showVariant === "boost"
        ? getBoostOptions(existingRules, collectionBoostRuleType)
        : getBuryOptions(existingRules, collectionBoostRuleType),
    [showVariant, existingRules, collectionBoostRuleType]
  );

  const collectionId =
    (ruleToEdit?.type === collectionBoostRuleType &&
      ruleToEdit?.collection_id) ||
    null;

  const [selected, setSelected] = useState<{
    option: BoostRuleType | null;
    collectionId: string | null;
  }>({
    option: ruleToEdit?.type ?? null,
    collectionId,
  });

  useEffect(() => {
    if (ruleToEdit) {
      setSelected({
        option: ruleToEdit.type,
        collectionId,
      });
    } else {
      setSelected({
        option: null,
        collectionId: null,
      });
    }
  }, [collectionId, ruleToEdit]);

  const previewContext = useContext(BoostBuryPreviewContext);

  const updateOption = useCallback(
    (option: BoostRuleType, collectionId?: string) => {
      setSelected({
        option,
        collectionId: collectionId ?? null,
      });

      previewContext.setPreviewOption(option, collectionId);
    },
    [previewContext]
  );

  const [saveLoading, setSaveLoading] = useState(false);
  const onCreate = useCallback(
    (rule: BoostRule) => {
      setSaveLoading(true);
      onCreateRule(rule);
      setSelected({
        option: null,
        collectionId: null,
      });
    },
    [onCreateRule]
  );

  const collectionOptions = collections?.map((c) => ({
    label: c.title,
    value: c.collection_id,
    isDisabled:
      existingRules?.some(
        (r) =>
          r.type === collectionBoostRuleType &&
          r.collection_id === c.collection_id &&
          r.id !== ruleToEdit?.id
      ) || false,
  }));

  return (
    <DepictModal
      variant={saveLoading ? "tiny" : "wide"}
      show={showVariant !== false}
    >
      <Modal.Body>
        {saveLoading && showVariant === "boost" && (
          <div className="d-flex flex-column justify-content-center">
            <BoostSaveAnimation
              onFinish={() => {
                onClose();
                setTimeout(() => {
                  setSaveLoading(false);
                }, 500);
              }}
            />
            <div className="mt-0 d-flex flex-row align-items-center justify-content-center">
              <p className="mb-0 me-2 text-center">Boost added</p>
              <Check size={20} />
            </div>
          </div>
        )}
        {saveLoading && showVariant === "bury" && (
          <div className="d-flex flex-column justify-content-center">
            <BurySaveAnimation
              onFinish={() => {
                onClose();
                setTimeout(() => {
                  setSaveLoading(false);
                }, 500);
              }}
            />
            <div className="mt-0 d-flex flex-row align-items-center justify-content-center">
              <p className="mb-0 me-2 text-center">Bury added</p>
              <Check size={20} />
            </div>
          </div>
        )}
        {!saveLoading && (
          <div className="d-flex h-100 relative">
            <div className="me-4-5" style={{ width: "40%" }}>
              <div className="d-flex flex-column justify-content-center align-items-start h-100">
                <h1 className="fw-700 mb-2-5">{`Add a ${showVariant}`}</h1>
                <p style={{ color: theme.textIcon.subtle.default }}>
                  {showVariant === "boost"
                    ? BOOST_DESCRIPTION
                    : BURY_DESCRIPTION}
                </p>
                <div className="mt-2-5">
                  {options.map((option, index) => (
                    <div className="mb-3" key={index}>
                      <input
                        type="radio"
                        id={option.value}
                        name="boost-bury"
                        value={option.value}
                        checked={selected.option === option.value}
                        onChange={() => {
                          updateOption(option.value, undefined);
                        }}
                        disabled={
                          option.isDisabled && ruleToEdit?.type !== option.value
                        }
                      />
                      <label
                        htmlFor={option.value}
                        className={`ms-2 ${
                          option.isDisabled && ruleToEdit?.type !== option.value
                            ? "text-muted"
                            : ""
                        }`}
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    visibility:
                      selected.option === collectionBoostRuleType
                        ? "visible"
                        : "hidden",
                  }}
                >
                  <p>Which campaign would you like to {showVariant}?</p>
                  <Select
                    className="mb-3 w-100"
                    isDisabled={selected.option !== collectionBoostRuleType}
                    options={collectionOptions}
                    onChange={(e) => {
                      updateOption(collectionBoostRuleType, e?.value);
                    }}
                    value={
                      selected.collectionId
                        ? {
                            label: collections?.find(
                              (c) => c.collection_id === selected.collectionId
                            )?.title,
                            value: selected.collectionId,
                          }
                        : null
                    }
                  />
                </div>
                <div className="d-flex justify-content-end w-100">
                  <Button
                    style={{ marginRight: "0.75rem" }}
                    text={"Cancel"}
                    variant="outline-secondary"
                    onClick={() => {
                      setSelected({
                        option: null,
                        collectionId: null,
                      });
                      onClose();
                    }}
                    icon={<ArrowCounterClockwise size={16} />}
                  />
                  <SaveButton
                    selectedOption={selected.option}
                    selectedCollection={selected.collectionId}
                    effect={showVariant as BoostBuryEffect}
                    collections={collections}
                    ruleIdToEdit={ruleToEdit?.id}
                    onCreate={onCreate}
                    collectionBoostRuleType={collectionBoostRuleType}
                  />
                </div>
              </div>
            </div>
            <div className="w-100 dpt-color-background-subtle-default rounded-xl">
              <BoostAndBuryPreview
                showVariant={showVariant}
                option={selected.option}
                images={previewContext.previewImages}
                collectionTitle={
                  collections?.find(
                    (c) => c.collection_id === selected.collectionId
                  )?.title
                }
                collectionBoostRuleType={collectionBoostRuleType}
              />
            </div>
          </div>
        )}
      </Modal.Body>
    </DepictModal>
  );
};

export default BoostBuryModal;

import { ResponsiveLineCanvas } from "@nivo/line";
import {
  ArrowsInSimple,
  ArrowsOutSimple,
  ChartLine,
  TrendDown,
  TrendUp,
  X,
} from "@phosphor-icons/react";
import { Dropdown, Spinner } from "react-bootstrap";
import { css } from "@emotion/react";
import AnimatedNumbers from "react-animated-numbers";
import { IconButton } from "../IconButton/IconButton";
import { useState } from "react";

export enum TimePeriod {
  LAST_YEAR = "Last year",
  LAST_QUARTER = "Last quarter",
  LAST_30_DAYS = "Last 30 days",
  LAST_7_DAYS = "Last 7 days",
}

export type PlpMetric = {
  name: string;
  aggValue: number;
  percent?: boolean;
  timeSeries: {
    date: Date;
    value: number;
  }[];
  toolTipText: (value: number | string) => string;
  percentChangeSinceLastPeriod: number | null;
};

interface ListingMetricBannerProps {
  isLoading?: boolean;
  timePeriod: TimePeriod;
  onSetTimePeriod: (timePeriod: TimePeriod) => void;
  metrics: PlpMetric[];
}

export default function ListingMetricBanner(props: ListingMetricBannerProps) {
  const [minimized, setMinimized] = useState(false);
  const [show, setShow] = useState(true);
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        border-radius: 24px;
        padding: 0 16px 16px 16px;
        background: linear-gradient(94deg, #e4e6fa 0%, #f1e7ff 100%);
        display: ${show ? "flex" : "none"};
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin: 0;
          padding: 8px 0;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
          `}
        >
          <ChartLine
            color="#664F94"
            css={css`
              margin-right: 8px;
            `}
          />{" "}
          <span
            css={css`
              color: #664f94;
            `}
          >
            Performance
          </span>
          <Dropdown id={"time-dropdown"}>
            <Dropdown.Toggle
              variant="link"
              id="dropdown-basic"
              css={css`
                padding: 8px;
                margin-left: 8px;
              `}
            >
              {props.timePeriod}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {Object.values(TimePeriod).map((timePeriod) => (
                <Dropdown.Item
                  key={timePeriod}
                  onClick={() => props.onSetTimePeriod(timePeriod)}
                >
                  {timePeriod}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          {minimized ? (
            <IconButton
              icon={<ArrowsOutSimple />}
              onClick={() => setMinimized(false)}
            />
          ) : (
            <IconButton
              icon={<ArrowsInSimple />}
              onClick={() => setMinimized(true)}
            />
          )}
          <IconButton
            icon={<X />}
            onClick={() => {
              setShow(false);
            }}
          />
        </div>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 16px;
        `}
      >
        {props.isLoading && (
          <div
            css={css`
              flex: 1;
              background: white;
              border-radius: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              flex-direction: column;
              padding: 8px 16px;
            `}
          >
            <Spinner />
          </div>
        )}
        {props.metrics.map((metric) => (
          <div
            css={css`
              flex: 1;
              background: white;
              border-radius: 16px;
              display: flex;
              height: 100%;
              flex-direction: column;
              padding: 8px 16px;
              min-width: 128px;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <h2
                css={css`
                  display: flex;
                  align-items: center;
                  font-size: 24px;
                  margin: 0 16px 0 0;
                `}
              >
                <AnimatedNumbers
                  includeComma
                  transitions={(index) => ({
                    type: "spring",
                    duration: index + 0.3,
                  })}
                  animateToNumber={metric.aggValue}
                />
                {metric.percent ? " %" : ""}
              </h2>
              <div>
                <div
                  css={css`
                    color: #0f0f0f;
                  `}
                >
                  {metric.name}
                </div>
                {metric.percentChangeSinceLastPeriod !== null && (
                  <div>
                    {metric.percentChangeSinceLastPeriod &&
                    metric.percentChangeSinceLastPeriod >= 0 ? (
                      <span
                        css={css`
                          color: #5a8e5a;
                        `}
                      >
                        <TrendUp />{" "}
                        {metric.percentChangeSinceLastPeriod.toLocaleString()} %
                      </span>
                    ) : (
                      <span
                        css={css`
                          color: #92363e;
                        `}
                      >
                        <TrendDown />{" "}
                        {metric.percentChangeSinceLastPeriod.toLocaleString()} %
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
            {!minimized && (
              <>
                <div
                  css={css`
                    width: 100%;
                    height: 80px;
                  `}
                >
                  <ResponsiveLineCanvas
                    colors={["#664F94"]}
                    data={[
                      {
                        id: "graph",
                        data: metric.timeSeries.map((timeSeries) => ({
                          x: timeSeries.date,
                          y: timeSeries.value,
                        })),
                      },
                    ]}
                    xScale={{ type: "point" }}
                    yScale={{
                      type: "linear",
                      stacked: true,
                      min: "auto",
                      max: "auto",
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={null}
                    axisLeft={null}
                    enableGridX={false}
                    enableGridY={false}
                    enablePoints={false}
                    tooltip={({ point }) => {
                      return (
                        <div
                          css={css`
                            background: rgba(0, 0, 0, 0.8);
                            padding: 8px;
                            border-radius: 8px;
                            color: white;
                            font-size: 12px;
                          `}
                        >
                          <div
                            css={css`
                              opacity: 0.6;
                            `}
                          >
                            {point.data.x instanceof Date
                              ? point.data.x.toLocaleDateString()
                              : point.data.x}
                          </div>
                          <div>{metric.toolTipText(point.data.yFormatted)}</div>
                        </div>
                      );
                    }}
                    margin={{ top: 16, right: 8, bottom: 16, left: 8 }}
                    enableArea={false}
                    enableCrosshair={false}
                    legends={[]}
                    curve="natural"
                  />
                </div>
                <div
                  css={css`
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                  `}
                >
                  <span>
                    {metric.timeSeries.length > 0
                      ? metric.timeSeries[0].date.toLocaleDateString()
                      : "-"}
                  </span>
                  <span>
                    {metric.timeSeries.length > 0
                      ? metric.timeSeries[
                          metric.timeSeries.length - 1
                        ].date.toLocaleDateString()
                      : "-"}
                  </span>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

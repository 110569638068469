 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const OilPressure = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M18.854 7.146l-1-1c-0-0-0-0-0.001-0.001s-0-0-0-0-0-0-0-0c-0.070-0.070-0.157-0.115-0.25-0.135-0-0-0-0-0.001-0-0.022-0.005-0.045-0.008-0.068-0.009-0 0-0-0-0-0-0.078-0.005-0.158 0.008-0.232 0.040-0 0-0 0-0 0s-0 0-0 0c-0.009 0.004-0.017 0.008-0.026 0.012l-5.676 2.838-0.745-0.745c-0.094-0.094-0.221-0.146-0.354-0.146h-1.5v-1h0.5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-2c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h0.5v1h-3v-0.5c0-0.223-0.148-0.419-0.363-0.481l-3.5-1c-0.137-0.039-0.284-0.018-0.404 0.058s-0.203 0.2-0.226 0.34l-0.5 3c-0.043 0.257 0.119 0.504 0.372 0.567l3.621 0.905v2.61c0 0.276 0.224 0.5 0.5 0.5h9c0.179 0 0.345-0.096 0.434-0.252l3.675-6.432 0.537 0.537c0.195 0.195 0.512 0.195 0.707 0 0.098-0.098 0.146-0.226 0.146-0.354s-0.049-0.256-0.146-0.354zM1.069 9.127l0.332-1.992 2.599 0.743v1.982l-2.931-0.733zM13.21 13h-8.21v-4h5.293l0.854 0.854c0.152 0.152 0.385 0.19 0.577 0.094l4.523-2.262-3.037 5.314z" fill={color}></path>
<path d="M18.5 13c-0.827 0-1.5-0.673-1.5-1.5 0-0.437 0.294-0.87 0.605-1.328 0.141-0.207 0.376-0.554 0.395-0.679 0.003-0.273 0.226-0.494 0.5-0.494s0.497 0.22 0.5 0.494c0.019 0.125 0.254 0.471 0.395 0.679 0.311 0.458 0.605 0.891 0.605 1.328 0 0.827-0.673 1.5-1.5 1.5zM18.5 10.634c-0.022 0.033-0.045 0.066-0.068 0.1-0.159 0.235-0.426 0.627-0.432 0.767 0 0.275 0.224 0.499 0.5 0.499s0.5-0.224 0.5-0.5c-0.007-0.139-0.273-0.532-0.432-0.766-0.023-0.033-0.045-0.067-0.068-0.1z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(OilPressure); 
import { Check } from "@phosphor-icons/react";

export default function IntegrationStepHeader({
  title,
  stepNumber,
  isComplete,
}: {
  title: string;
  stepNumber: number;
  isComplete?: boolean;
}) {
  return (
    <div className="d-flex align-items-center mb-3">
      {!isComplete ? (
        <div
          className={`rounded-circle p-2 d-flex align-items-center justify-content-center me-3 bg-black text-white`}
          style={{ width: 32, height: 32 }}
        >
          {stepNumber}
        </div>
      ) : (
        <div
          className={`rounded-circle p-2 d-flex align-items-center justify-content-center me-3 bg-gray-200 text-black`}
          style={{ width: 32, height: 32 }}
        >
          <Check />
        </div>
      )}
      <div className="fw-bolder text-lg text-black">{title}</div>
    </div>
  );
}

import { Stack } from "react-bootstrap";

interface ErrorMessageProps {
  message: string;
}
export const ErrorMessage = (props: ErrorMessageProps) => {
  return (
    <Stack>
      <h1 className="text-danger">Something went wrong</h1>
      <p>{props.message}</p>
    </Stack>
  );
};

export default ErrorMessage;

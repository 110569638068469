import { useCallback } from "react";
import Item from "src/components/lists/Item";
import { findContainer, getIndexForId } from "src/helpers/dnd";
import DraggableItem from "src/components/dragndrop/DraggableItem";
import { UniqueIdentifier } from "@dnd-kit/core";
import { ContainersItems } from "src/types/dnd";
import ItemContent from "src/components/lists/ItemContent";
import { ListItem } from "src/types/components";

type UseRenderSortableItemOverlayProps = {
  items: ContainersItems | null;
  renderItem?: any;
  wrapperStyle?(args: { index: number }): React.CSSProperties;
  getItemStyles?(args: {
    value: UniqueIdentifier;
    index: number;
    overIndex: number;
    isDragging: boolean;
    containerId: UniqueIdentifier;
    isSorting: boolean;
    isDragOverlay: boolean;
  }): React.CSSProperties;
};

export default function useRenderSortableItemOverlay(
  props: UseRenderSortableItemOverlayProps
) {
  const { items, renderItem, wrapperStyle } = props;

  const getItemContent = useCallback((item: ListItem) => {
    return <ItemContent item={item} />;
  }, []);

  const renderSortableItemDragOverlay = useCallback(
    (id: UniqueIdentifier) => {
      let containerId = findContainer(id, items);

      if (!containerId) {
        return;
      }

      let index = getIndexForId(id, items?.[containerId] ?? null);
      let element = items?.[containerId]?.[index];

      if (!element) {
        return;
      }

      return (
        <DraggableItem
          value={id}
          handle={false}
          wrapperStyle={wrapperStyle && wrapperStyle({ index: 0 })}
          renderItem={renderItem}
          customTag="li"
          dragOverlay
        >
          <Item
            item={element}
            className="depict--ItemsList__item depict--ItemsList__item--DragOverlay"
          >
            {getItemContent(element)}
          </Item>
        </DraggableItem>
      );
    },
    [getItemContent, items, renderItem, wrapperStyle]
  );

  return [renderSortableItemDragOverlay];
}

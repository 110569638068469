import { useQuery } from "@tanstack/react-query";
import { QueryId, getQueryKey } from "src/queries/queries";
import { GetCollectionDto } from "src/api/types";

export interface GetCollectionCallbackReturn {
  collection: GetCollectionDto | null;
}

export type GetCollectionCallback = (
  merchantId: string,
  collectionId: string
) => Promise<GetCollectionCallbackReturn>;

export interface GetCollectionConfig {
  callback: GetCollectionCallback;
  enabled: boolean;
  queryKeySalt: string;
}

export function useCategory(
  merchantId: string | null,
  collectionId: string | null,
  getCategoryConfig: GetCollectionConfig
) {
  const query = useQuery({
    queryKey: getQueryKey(
      QueryId.GetCategory,
      merchantId || "",
      collectionId || "",
      getCategoryConfig.queryKeySalt
    ),
    queryFn: () => {
      if (!merchantId || !collectionId) return;

      return getCategoryConfig.callback(merchantId, collectionId);
    },
    enabled: !!merchantId && !!collectionId && getCategoryConfig.enabled,
  });

  return {
    category: query.data?.collection || null,
    isLoading: query.isLoading,
    isError: query.isError,
    refetch: query.refetch,
  };
}

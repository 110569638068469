//https://stackoverflow.com/a/42234988
import React, { useRef, useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref: any, callback: any) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: { target: any }, callback: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };
    // Bind the event listener
    document.addEventListener("mousedown", (e) => {
      handleClickOutside(e, callback);
    });
    return () => {
      // Unbind the event listener on clean up
      //@ts-ignore
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

/**
 * Component that alerts if you click outside of it
 */
export default function OutsideClickDetector(props: {
  callback: Function;
  children: React.ReactNode;
}) {
  const { callback, children } = props;
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, callback);
  return <div ref={wrapperRef}>{children}</div>;
}

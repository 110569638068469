import React, { forwardRef, useMemo } from "react";
import classNames from "merge-class-names";

import styles from "src/assets/scss/dnd-kit/Container.module.scss";
import reactFastCompare from "react-fast-compare";
import { ContainersProps } from "src/types/dnd";

const Container = forwardRef<HTMLDivElement, ContainersProps>(
  (
    {
      children,
      columns = 1,
      handleProps,
      horizontal,
      hover,
      onClick,
      onRemove,
      label,
      placeholder,
      style,
      scrollable,
      shadow,
      unstyled,
      ...props
    }: ContainersProps,
    ref
  ) => {
    const Component = onClick ? "button" : "div";

    const computedStyle = useMemo(() => {
      return {
        ...style,
        "--columns": columns,
      };
    }, [style, columns]);

    return (
      <Component
        {...props}
        //@ts-ignore
        ref={ref}
        style={computedStyle}
        className={classNames(
          styles.Container,
          unstyled && styles.unstyled,
          horizontal && styles.horizontal,
          hover && styles.hover,
          scrollable && styles.scrollable,
          shadow && styles.shadow
        )}
        onClick={onClick}
        tabIndex={onClick ? 0 : undefined}
      >
        {placeholder ? children : <ul>{children}</ul>}
      </Component>
    );
  }
);

export default React.memo(Container, reactFastCompare);

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Chef = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M20 6.5c0-2.481-2.019-4.5-4.5-4.5-0.464 0-0.917 0.070-1.353 0.207-0.925-1.371-2.476-2.207-4.147-2.207s-3.222 0.836-4.147 2.207c-0.436-0.138-0.889-0.207-1.353-0.207-2.481 0-4.5 2.019-4.5 4.5 0 2.312 1.753 4.223 4 4.472v8.528c0 0.276 0.224 0.5 0.5 0.5h11c0.276 0 0.5-0.224 0.5-0.5v-8.528c2.247-0.25 4-2.16 4-4.472zM6.558 10.502c0.482-0.249 0.92-0.587 1.285-0.991 0.67 0.321 1.408 0.488 2.157 0.488s1.486-0.168 2.157-0.488c0.365 0.404 0.802 0.742 1.285 0.991 0.484 0.249 1.015 0.408 1.558 0.469v5.028h-10v-5.028c0.543-0.061 1.074-0.22 1.558-0.469zM5 19v-2h10v2h-10zM15.5 10c-1.121 0-2.147-0.517-2.815-1.42-0.152-0.206-0.435-0.263-0.656-0.133-0.613 0.362-1.314 0.553-2.029 0.553s-1.417-0.191-2.029-0.553c-0.221-0.13-0.504-0.073-0.656 0.133-0.668 0.902-1.694 1.42-2.815 1.42-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5c0.297 0 0.588 0.036 0.87 0.109-0.175 0.426-0.288 0.874-0.34 1.336-0.030 0.274 0.168 0.522 0.442 0.552s0.522-0.167 0.552-0.442c0.058-0.528 0.219-1.030 0.477-1.494 0-0 0-0 0-0 0.706-1.271 2.047-2.060 3.499-2.060s2.793 0.789 3.499 2.060c0 0 0 0 0 0 0.258 0.464 0.418 0.967 0.477 1.495 0.030 0.274 0.278 0.472 0.552 0.442s0.472-0.277 0.442-0.552c-0.051-0.463-0.165-0.91-0.34-1.336 0.282-0.072 0.573-0.109 0.87-0.109 1.93 0 3.5 1.57 3.5 3.5s-1.57 3.5-3.5 3.5z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Chef); 
import { observer as lib_Observer } from "src/helpers/ext/elementObserver";

//getbootstrap.com/docs/4.0/getting-started/webpack/
import Tooltip from "bootstrap/js/dist/tooltip";
import config from "src/config";

export default function bootstrapScripts() {
  lib_Observer?.onexists<HTMLElement>(
    '[data-bs-toggle="tooltip"]',
    ({ element }: { element: HTMLElement }) => {
      let placement = element.getAttribute("data-bs-placement") || "top";
      new Tooltip(element, {
        delay: config.const.bootstrapTooltipDelay,
        placement: placement as "auto" | "top" | "bottom" | "left" | "right",
      });
    }
  );
}

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Rain = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M2.5 9c0 0 0 0 0 0-0.668 0-1.296-0.26-1.768-0.732-0.975-0.975-0.975-2.561 0-3.536 0.554-0.554 1.757-1.051 3.151-1.626 0.81-0.334 1.647-0.68 2.393-1.053 0.193-0.096 0.425-0.059 0.577 0.094s0.19 0.385 0.094 0.577c-0.373 0.746-0.719 1.584-1.053 2.393-0.575 1.394-1.072 2.597-1.626 3.151-0.472 0.472-1.1 0.732-1.768 0.732zM5.479 3.521c-0.403 0.174-0.812 0.343-1.214 0.509-1.186 0.49-2.413 0.996-2.825 1.409-0.585 0.585-0.585 1.536 0 2.121 0.283 0.283 0.66 0.439 1.061 0.439s0.777-0.156 1.061-0.439c0.413-0.413 0.919-1.639 1.409-2.825 0.166-0.402 0.335-0.811 0.509-1.214z" fill={color}></path>
<path d="M15.5 18c-0 0 0 0 0 0-0.668 0-1.296-0.26-1.768-0.732-0.975-0.975-0.975-2.561 0-3.536 0.554-0.554 1.757-1.051 3.151-1.626 0.81-0.334 1.647-0.68 2.393-1.053 0.192-0.096 0.425-0.058 0.577 0.094s0.19 0.385 0.094 0.577c-0.373 0.746-0.719 1.584-1.053 2.393-0.575 1.394-1.072 2.597-1.626 3.151-0.472 0.472-1.1 0.732-1.768 0.732zM18.479 12.521c-0.403 0.174-0.812 0.343-1.214 0.509-1.186 0.49-2.413 0.996-2.825 1.409-0.585 0.585-0.585 1.536-0 2.121 0.283 0.283 0.66 0.439 1.061 0.439s0.777-0.156 1.061-0.439c0.413-0.413 0.919-1.639 1.409-2.825 0.166-0.402 0.335-0.811 0.509-1.214z" fill={color}></path>
<path d="M13.5 7c-0 0 0 0 0 0-0.668 0-1.296-0.26-1.768-0.732-0.975-0.975-0.975-2.561 0-3.536 0.554-0.554 1.757-1.051 3.151-1.626 0.81-0.334 1.647-0.68 2.393-1.053 0.192-0.096 0.425-0.059 0.577 0.094s0.19 0.385 0.094 0.577c-0.373 0.746-0.719 1.584-1.053 2.393-0.575 1.394-1.072 2.597-1.626 3.151-0.472 0.472-1.1 0.732-1.768 0.732zM16.479 1.521c-0.403 0.174-0.812 0.343-1.214 0.509-1.186 0.49-2.413 0.996-2.825 1.409-0.585 0.585-0.585 1.536-0 2.121 0.283 0.283 0.66 0.439 1.061 0.439s0.777-0.156 1.061-0.439c0.413-0.413 0.919-1.639 1.409-2.825 0.166-0.402 0.335-0.811 0.509-1.214z" fill={color}></path>
<path d="M4.5 20c0 0 0 0 0 0-0.668 0-1.296-0.26-1.768-0.732-0.975-0.975-0.975-2.561 0-3.536 0.554-0.554 1.757-1.051 3.151-1.626 0.81-0.334 1.647-0.68 2.393-1.053 0.193-0.096 0.425-0.058 0.577 0.094s0.19 0.385 0.094 0.577c-0.373 0.746-0.719 1.584-1.053 2.393-0.575 1.394-1.072 2.597-1.626 3.151-0.472 0.472-1.1 0.732-1.768 0.732zM7.479 14.521c-0.403 0.174-0.812 0.343-1.214 0.509-1.186 0.49-2.413 0.996-2.825 1.409-0.585 0.585-0.585 1.536-0 2.121 0.283 0.283 0.66 0.439 1.061 0.439s0.777-0.156 1.061-0.439c0.413-0.413 0.919-1.639 1.409-2.825 0.166-0.402 0.335-0.811 0.509-1.214z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Rain); 
import { css } from "@emotion/react";
import { FC, useRef } from "react";
import { theme } from "../designSystemVariables";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { IconButton } from "../IconButton/IconButton";

type SearchFieldProps = {
  searchQuery: string | undefined;
  onSetSearchQuery: (searchQuery: string) => void;
};

const inputWidth = 220;

export const SearchField: FC<SearchFieldProps> = ({
  searchQuery,
  onSetSearchQuery,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const hasValue = Boolean(inputRef.current?.value);

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <input
        ref={inputRef}
        css={css`
          background: transparent;
          background-color: ${hasValue
            ? theme.background.subtle.hover
            : "transparent"};
          outline: 0;
          border: 0;
          border-radius: 4px;
          padding: 0.25rem;
          padding-left: 1.75rem;
          width: ${hasValue ? inputWidth : 0}px;
          font-size: 14px;
          margin-right: ${hasValue ? "0.25rem" : 0};
          transition: width 0.2s 0.2s, background 0.4s 0.2s,
            margin-right 0.2s 0.2s;

          &:focus {
            background-color: ${theme.background.subtle.hover};
            width: ${inputWidth}px;
            margin-right: 0.25rem;
            transition: width 0.2s, background 0.4s;
          }

          &:focus + button {
            pointer-events: none;
          }

          &:focus + button:hover {
            background: transparent;
            cursor: default;
          }
        `}
        value={searchQuery}
        onChange={(e) => onSetSearchQuery(e.target.value)}
      />
      <IconButton
        icon={<MagnifyingGlass />}
        float
        onClick={() => inputRef.current?.focus()}
      />
    </div>
  );
};

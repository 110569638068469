import {
  ReactFilterBody,
  ReactSelectedFilters,
} from "@depict-ai/portal-components";
import { useState } from "react";
import { SearchFilter } from "@depict-ai/types/api/SearchResponse";
import { en } from "@depict-ai/react-ui/locales/latest";
import { OpenFilterButton } from "./OpenFilterButton";
import { theme } from "../designSystemVariables";

export type SelectedFilter = {
  field: string;
  op: SearchFilter["op"];
  data: any;
};

export const Filters = (props: {
  availableFilters: SearchFilter[] | undefined | null;
  onFilterChange: (filters: SelectedFilter[]) => void;
}) => {
  const [expandedFilters, setExpandedFilters] = useState<
    { section_: string; expanded_: boolean }[]
  >([]);
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([]);
  const [expandedHierarchicalFilters, setExpandedHierarchicalFilters] =
    useState<{ value_: string[]; expanded_: boolean }[]>([]);

  const onFilterChange = (filters: SelectedFilter[]) => {
    setSelectedFilters(filters);
    props.onFilterChange(filters);
  };

  return (
    <div
      className="depict--Filters d-flex flex-column p-3 rounded-3 h-100"
      style={{
        backgroundColor: theme.background.subtle.default,
        overflowY: "auto",
      }}
    >
      <div className="d-flex align-content-center align-items-center mb-3">
        <OpenFilterButton
          active={true}
          onClick={() => {
            setSelectedFilters([]);
            props.onFilterChange([]);
          }}
        />
        <h4 className="mb-0 ms-3">Filter by</h4>
      </div>
      <div className="mb-3">
        <ReactSelectedFilters
          availableFilters={props.availableFilters || undefined}
          selectedFilters={selectedFilters}
          setSelectedFilters={onFilterChange}
          setExpandedHierarchicalFilters={setExpandedHierarchicalFilters}
          expandedHierarchicalFilters={expandedHierarchicalFilters}
          i18n={en}
        />
      </div>
      <ReactFilterBody
        availableFilters={props.availableFilters || undefined}
        selectedFilters={selectedFilters}
        setSelectedFilters={onFilterChange}
        expandedFilters={expandedFilters}
        setExpandedFilters={setExpandedFilters}
        expandedHierarchicalFilters={expandedHierarchicalFilters}
        setExpandedHierarchicalFilters={setExpandedHierarchicalFilters}
        i18n={en}
      />
    </div>
  );
};

import { css } from "@emotion/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { DropTargetMonitor, useDrop } from "react-dnd";
import { OnAddListingItem } from "src/components/navs/types";
import { CollectionType } from "src/api/types";
import { ALLOWED_SCOLLECTION_TYPES_FOR_EDITING } from "../../../contexts/ListingTreeSelectorContext";
import ListingTreeItem, { ItemTypes } from "./ListingTreeItem";
import ListingTreeMenuHeader from "./ListingTreeMenuHeader";
import { ListingItem, ListingSorting } from "./types";
import { ImageResizeProvider } from "../ImageResizeProvider/ImageResizeProvider";
import useMerchant from "src/helpers/hooks/app/useMerchant";

function getNodeAndAncestorsWhere(
  where: (listingItem: ListingItem) => boolean,
  listingItems: ListingItem[]
) {
  const listingIds: Set<string> = new Set();
  const addListingIds = (
    listingItem: ListingItem,
    ancestorIds: string[] = []
  ) => {
    if (where(listingItem)) {
      listingIds.add(listingItem.id);
      ancestorIds.forEach((ancestorId) => listingIds.add(ancestorId));
    }
    if (listingItem.children) {
      listingItem.children.forEach((child) =>
        addListingIds(child, [...ancestorIds, listingItem.id])
      );
    }
  };
  listingItems.forEach((listingItem) => addListingIds(listingItem));
  return listingIds;
}

function getNodeAndChildrenWhere(
  where: (listingItem: ListingItem) => boolean,
  listingItems: ListingItem[]
) {
  const listingIds: Set<string> = new Set();
  const addListingIds = (listingItem: ListingItem) => {
    if (where(listingItem)) {
      listingIds.add(listingItem.id);
    }
    if (listingItem.children) {
      listingItem.children.forEach((child) => addListingIds(child));
    }
  };
  listingItems.forEach((listingItem) => addListingIds(listingItem));
  return listingIds;
}

export default function ListingTreeMenu({
  isLoading,
  listingItems,
  currentListingItemId,
  onAddListingItem,
  inSelectMode,
  selectedListingItemIds,
  onChangeSelectedListingItemIds,
  indeterminateListingItemIds,
  onSetParentId,
  editableListingTypes,
  onSetListingType,
}: {
  listingItems: ListingItem[];
  isLoading?: boolean;
  currentListingItemId?: string;
  onAddListingItem: OnAddListingItem;
  selectedListingItemIds?: Set<string>;
  onChangeSelectedListingItemIds?: (
    checked: boolean,
    listingItemIds: Set<string>
  ) => void;
  inSelectMode?: boolean;
  indeterminateListingItemIds?: Set<string>;
  onSetParentId: (listingItemId: string, parentId: string | null) => void;
  editableListingTypes: CollectionType[];
  onSetListingType: (
    collectionType: CollectionType,
    listingItemId: string
  ) => void;
}) {
  const { merchantId } = useMerchant();
  const [expandedListingItemIds, setExpandedListingItemIds] = useState<
    Set<string>
  >(new Set());

  const [filteredType, setFilteredType] = useState<CollectionType | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const sanitizedSearchQuery = searchQuery.trim().toLowerCase();

  const [sorting, setSorting] = useState<ListingSorting>({
    field: "title",
    direction: "asc",
  });

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (currentListingItemId && scrollContainerRef.current) {
      const elementToScrollTo = document.getElementById(currentListingItemId);
      if (elementToScrollTo) {
        const containerTop = scrollContainerRef.current.scrollTop;
        const containerBottom =
          containerTop + scrollContainerRef.current.clientHeight;

        const elementTop =
          elementToScrollTo.offsetTop - scrollContainerRef.current.offsetTop;
        const elementBottom = elementTop + elementToScrollTo.clientHeight;

        const isInView =
          elementBottom <= containerBottom && elementTop >= containerTop;

        if (!isInView) {
          scrollContainerRef.current.scrollTop = elementTop;
        }
      }
    }
  }, [currentListingItemId]);
  const viewableListingItemIds = useMemo(
    () =>
      getNodeAndAncestorsWhere((listingItem) => {
        if (
          inSelectMode &&
          !ALLOWED_SCOLLECTION_TYPES_FOR_EDITING.includes(listingItem.type)
        ) {
          return false;
        }
        if (filteredType && listingItem.type !== filteredType) {
          return false;
        }
        if (!searchQuery) {
          return true;
        }
        if (currentListingItemId === listingItem.id) {
          return true;
        }
        return listingItem.title.toLowerCase().includes(sanitizedSearchQuery);
      }, listingItems),
    [
      inSelectMode,
      searchQuery,
      listingItems,
      sanitizedSearchQuery,
      currentListingItemId,
      filteredType,
    ]
  );

  useEffect(() => {
    if (sanitizedSearchQuery) {
      setExpandedListingItemIds(viewableListingItemIds);
    } else if (currentListingItemId) {
      setExpandedListingItemIds(
        (expanded) =>
          new Set([
            ...expanded,
            ...getNodeAndAncestorsWhere(
              (listingItem) => listingItem.id === currentListingItemId,
              listingItems
            ),
          ])
      );
    }
  }, [
    currentListingItemId,
    listingItems,
    sanitizedSearchQuery,
    viewableListingItemIds,
  ]);

  const sortingFunction = (a: ListingItem, b: ListingItem) => {
    switch (sorting.field) {
      case "title":
        if (sorting.direction === "asc") {
          return a.title.localeCompare(b.title);
        }
        return b.title.localeCompare(a.title);
      case "nProducts":
        if (sorting.direction === "asc") {
          return (a.nProducts() || 0) - (b.nProducts() || 0);
        }
        return (b.nProducts() || 0) - (a.nProducts() || 0);
      case "updatedAt":
        if (sorting.direction === "asc") {
          return (a.updatedAt?.getTime() ?? 0) - (b.updatedAt?.getTime() ?? 0);
        }
        return (b.updatedAt?.getTime() ?? 0) - (a.updatedAt?.getTime() ?? 0);
      default:
        return 0;
    }
  };

  const onSelectChange = (checked: boolean, listingId: string) => {
    if (checked) {
      onChangeSelectedListingItemIds &&
        onChangeSelectedListingItemIds(
          checked,
          getNodeAndAncestorsWhere(
            (listingItem) => listingItem.id === listingId,
            listingItems
          )
        );
    } else {
      onChangeSelectedListingItemIds &&
        onChangeSelectedListingItemIds(
          checked,
          getNodeAndChildrenWhere(
            (listingItem) => listingItem.id === listingId,
            listingItems
          )
        );
    }
  };

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.LISTING_ITEM,
    drop: (item: { listingItem: ListingItem }, monitor) => {
      if (!monitor.didDrop()) {
        onSetParentId(item.listingItem.id, null);
      }
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver({
        shallow: true,
      }),
    }),
  }));

  return (
    <ImageResizeProvider merchantId={merchantId}>
      <div
        ref={drop}
        className="m-2 mt-0 p-2 rounded text-sm"
        css={css`
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          overflow-y: hidden;
          background: ${inSelectMode ? "white" : "transparent"};
          border: ${inSelectMode
            ? "1px solid transparent"
            : isOver
            ? "1px solid #3018C1"
            : "1px solid #e4e4e5"};
        `}
      >
        <div
          css={css`
            opacity: ${isLoading ? 0.25 : 1};
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            overflow-y: hidden;
          `}
        >
          <ListingTreeMenuHeader
            onSetType={(type, listingItemId) => {
              onSetListingType(type, listingItemId);
            }}
            isLoading={isLoading}
            availableFilterTypes={Array.from(
              Array.from(
                getNodeAndChildrenWhere(() => true, listingItems)
              ).reduce((acc, listingId) => {
                const listingItem = listingItems.find(
                  (listingItem) => listingItem.id === listingId
                );
                if (listingItem) {
                  acc.add(listingItem.type);
                }
                return acc;
              }, new Set() as Set<CollectionType>)
            )}
            searchQuery={searchQuery}
            onSetSearchQuery={setSearchQuery}
            onAddListingItem={onAddListingItem}
            sorting={sorting}
            onSetSorting={setSorting}
            filteredType={filteredType}
            onSetFilteredType={setFilteredType}
          />
          <div
            ref={scrollContainerRef}
            css={css`
              overflow-y: auto;
              flex-grow: 1;
            `}
          >
            {listingItems
              .filter((listingItem) =>
                viewableListingItemIds.has(listingItem.id)
              )
              .sort(sortingFunction)
              .map((listingItem) => (
                <ListingTreeItem
                  editableListingTypes={editableListingTypes}
                  onDrop={onSetParentId}
                  sortFunction={sortingFunction}
                  selectIsIndeterminate={(listingId) =>
                    indeterminateListingItemIds?.has(listingId) ?? false
                  }
                  key={listingItem.id}
                  currentListingItemId={currentListingItemId}
                  isVisible={(listingId) =>
                    viewableListingItemIds.has(listingId)
                  }
                  listingItem={listingItem}
                  isExpanded={(listingId) =>
                    expandedListingItemIds.has(listingId)
                  }
                  onSetExpand={(value, listingId) => {
                    const newExpandedListingItemIds = new Set(
                      expandedListingItemIds
                    );
                    if (value) {
                      newExpandedListingItemIds.add(listingId);
                    } else {
                      newExpandedListingItemIds.delete(listingId);
                    }
                    setExpandedListingItemIds(newExpandedListingItemIds);
                  }}
                  onSelectChange={onSelectChange}
                  isSelected={(listingId) =>
                    (selectedListingItemIds || new Set()).has(listingId)
                  }
                  inSelectMode={inSelectMode}
                />
              ))}
          </div>
        </div>
      </div>
    </ImageResizeProvider>
  );
}

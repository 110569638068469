import { Col, Row } from "react-bootstrap";
import SelectorButton from "../../SelectorButton/SelectorButton";
import { ParsingMethod } from "../../Integrations/ParsingMethodSelector/types";

export default function ParsingMethodSelector({
  selectedAttributeParsingMethod,
  onSetAttributeParsingMethod,
  mappingIsDisabled,
}: {
  selectedAttributeParsingMethod: ParsingMethod | undefined;
  onSetAttributeParsingMethod: (method: ParsingMethod) => void;
  mappingIsDisabled?: boolean;
}) {
  return (
    <Row className="my-3">
      <Col xs={6}>
        <SelectorButton
          checked={selectedAttributeParsingMethod === ParsingMethod.Map}
          onClick={() => onSetAttributeParsingMethod(ParsingMethod.Map)}
          disabled={mappingIsDisabled}
        >
          <span className="text-nowrap overflow-hidden">Map attribute</span>
        </SelectorButton>
      </Col>
      <Col xs={6}>
        <SelectorButton
          checked={selectedAttributeParsingMethod === ParsingMethod.Constant}
          onClick={() => onSetAttributeParsingMethod(ParsingMethod.Constant)}
        >
          <span className="text-nowrap overflow-hidden">
            Select one attribute for all products
          </span>
        </SelectorButton>
      </Col>
    </Row>
  );
}

export const CentraLogo = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#0F0F0F" />
      <g clip-path="url(#clip0_1322_43801)">
        <path
          d="M22.0539 21.6204C23.3539 21.6204 24.3524 20.7537 24.7104 19.887L26.406 20.9798C25.5394 22.5059 23.9944 23.5798 21.9408 23.5798C19.2089 23.5798 16.722 21.4131 16.722 18.2479C16.722 15.1015 19.2089 12.916 21.9408 12.916C24.0133 12.916 25.5394 14.0088 26.3872 15.5349L24.6915 16.6276C24.3524 15.7609 23.3727 14.8943 22.0539 14.8943C19.9814 14.8943 18.851 16.4204 18.851 18.2667C18.851 20.1131 19.9814 21.6204 22.0539 21.6204Z"
          fill="white"
        />
        <path
          d="M15.5727 26.99C16.6549 26.99 17.5321 26.1127 17.5321 25.0305C17.5321 23.9484 16.6549 23.0711 15.5727 23.0711C14.4905 23.0711 13.6133 23.9484 13.6133 25.0305C13.6133 26.1127 14.4905 26.99 15.5727 26.99Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1322_43801">
          <rect
            width="13"
            height="14.3188"
            fill="white"
            transform="translate(13.5 12.8398)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export const ShopifyLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="-18 0 292 292"
      preserveAspectRatio="xMidYMid"
    >
      <path
        d="M223.774 57.34c-.201-1.46-1.48-2.268-2.537-2.357-1.055-.088-23.383-1.743-23.383-1.743s-15.507-15.395-17.209-17.099c-1.703-1.703-5.029-1.185-6.32-.805-.19.056-3.388 1.043-8.678 2.68-5.18-14.906-14.322-28.604-30.405-28.604-.444 0-.901.018-1.358.044C129.31 3.407 123.644.779 118.75.779c-37.465 0-55.364 46.835-60.976 70.635-14.558 4.511-24.9 7.718-26.221 8.133-8.126 2.549-8.383 2.805-9.45 10.462C21.3 95.806.038 260.235.038 260.235l165.678 31.042 89.77-19.42S223.973 58.8 223.775 57.34zM156.49 40.848l-14.019 4.339c.005-.988.01-1.96.01-3.023 0-9.264-1.286-16.723-3.349-22.636 8.287 1.04 13.806 10.469 17.358 21.32zm-27.638-19.483c2.304 5.773 3.802 14.058 3.802 25.238 0 .572-.005 1.095-.01 1.624-9.117 2.824-19.024 5.89-28.953 8.966 5.575-21.516 16.025-31.908 25.161-35.828zm-11.131-10.537c1.617 0 3.246.549 4.805 1.622-12.007 5.65-24.877 19.88-30.312 48.297l-22.886 7.088C75.694 46.16 90.81 10.828 117.72 10.828z"
        fill="#95BF46"
      />
      <path
        d="M221.237 54.983c-1.055-.088-23.383-1.743-23.383-1.743s-15.507-15.395-17.209-17.099c-.637-.634-1.496-.959-2.394-1.099l-12.527 256.233 89.762-19.418S223.972 58.8 223.774 57.34c-.201-1.46-1.48-2.268-2.537-2.357"
        fill="#5E8E3E"
      />
      <path
        d="M135.242 104.585l-11.069 32.926s-9.698-5.176-21.586-5.176c-17.428 0-18.305 10.937-18.305 13.693 0 15.038 39.2 20.8 39.2 56.024 0 27.713-17.577 45.558-41.277 45.558-28.44 0-42.984-17.7-42.984-17.7l7.615-25.16s14.95 12.835 27.565 12.835c8.243 0 11.596-6.49 11.596-11.232 0-19.616-32.16-20.491-32.16-52.724 0-27.129 19.472-53.382 58.778-53.382 15.145 0 22.627 4.338 22.627 4.338"
        fill="#FFF"
      />
    </svg>
  );
};

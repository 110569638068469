import {
  isSameYear,
  subDays,
  endOfDay,
  subMinutes,
  addMinutes,
} from "date-fns";

import { DataPeriod, Period } from "src/types/components";
import config from "src/config";

const getDelta = (p: Period) => {
  let delta: number = p ? p.end - p.start : 1;
  return delta;
};

/**
 * We used Date.toLocaleDateString before, but that is not reliable in creating a specific date format because it depends on browser/user settings.
 * That's why we need to use a custom function.
 * Inspired by: https://stackoverflow.com/a/23593099
 */
export const formatDate = (timestamp: number): string => {
  //timestamp here ARE ALWAYS seconds!!!!!!
  let d = toUtc(new Date(timestamp * 1000)),
    month = "" + (d.getUTCMonth() + 1),
    day = "" + d.getUTCDate(),
    year = d.getUTCFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return config.const.dateFormat
    .replace("yyyy", year)
    .replace("MM", month)
    .replace("dd", day);
};

export const parseDate = (formatted: string): Date => {
  const parts = formatted.split(config.const.dateFormatSeparator);
  const formatParts = config.const.dateFormat.split(
    config.const.dateFormatSeparator
  );

  const iYear = formatParts.findIndex((part: string) => part === "yyyy");
  const iMonth = formatParts.findIndex((part: string) => part === "MM");
  const iDay = formatParts.findIndex((part: string) => part === "dd");

  let day = iDay !== -1 ? parts[iDay] : "";
  let month = iMonth !== -1 ? parts[iMonth] : "";
  let year = iYear !== -1 ? parts[iYear] : "";
  let date = toUtc(new Date());
  date.setUTCDate(parseInt(day));
  date.setUTCMonth(parseInt(month) - 1);
  date.setUTCFullYear(parseInt(year));
  return date;
};

export const toUtc = (date: Date) => {
  var now_utc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

  return new Date(now_utc); //the only one date that can be not wrapped in toUtc()
};

export const getDefaultEndDate = (): Date => {
  let date = toUtc(new Date());
  date.setUTCDate(date.getUTCDate() - 1); //set it to yesterday, we do not want today.
  date.setUTCHours(23, 59, 59); //set it to end of the day
  return date;
};

export const getDefaultStartDate = (): Date => {
  let d = toUtc(new Date());
  d.setUTCDate(d.getUTCDate() - 7); //one week
  d.setUTCHours(0, 0, 0, 0); // set it to beginning of the day
  return d;
};

export const getMaxDate = (period: Period): Date => {
  let delta: number = getDelta(period) * 1000;
  let endOfTodayTimestamp = +getDefaultEndDate();
  let lastPossibleStartDayTimestamp = endOfTodayTimestamp - delta;
  let maxDate: Date = toUtc(new Date(lastPossibleStartDayTimestamp));
  return maxDate;
};

export const getYesterdayRightBeforeUTCMidnight = (date: Date) => {
  return getExactSecondInUTCWithoutConversion(
    endOfDay(subDays(toUtc(date), 1))
  );
};

export const compensateUTC = (date: Date) => {
  let offset = date.getTimezoneOffset() * 60 * 1000;
  let newTimestamp = +date + offset;
  let newDate = new Date(newTimestamp);
  return newDate;
};

export const compensateDatepickerOutput = (date: Date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
};

export const getExactSecondInUTCWithoutConversion = (date: Date) => {
  return toUtc(subMinutes(date, date.getTimezoneOffset()));
};

export const getTwoDigitsDay = (timestamp: number): string => {
  const date = toUtc(new Date(timestamp * 1000));
  const day = date.getDate().toString().padStart(2, "0"); // "09"
  return day;
};

export const getTwoDigitsMonth = (timestamp: number): string => {
  const date = toUtc(new Date(timestamp * 1000));
  const month = date.toLocaleString("en-us", { month: "long" });
  return month;
};

export const getDataPeriodSpanText = (
  period: DataPeriod,
  alwaysPrintYear = false,
  locale = "en-us"
) => {
  const startLocal = new Date(period.start ? period.start * 1000 : NaN);
  const endLocal = new Date(period.end ? period.end * 1000 : NaN);
  if (isNaN(startLocal.getTime()) || isNaN(endLocal.getTime())) {
    return "NaN";
  }
  const start = toUtc(addMinutes(startLocal, startLocal.getTimezoneOffset()));
  const end = toUtc(addMinutes(endLocal, endLocal.getTimezoneOffset()));
  const startYear = start.toLocaleString(locale, { year: "numeric" });
  const startMonth = start.toLocaleString(locale, { month: "long" });
  const startDate = start.toLocaleString(locale, { day: "numeric" });
  const endYear = end.toLocaleString(locale, { year: "numeric" });
  const endMonth = end.toLocaleString(locale, { month: "long" });
  const endDate = end.toLocaleString(locale, { day: "numeric" });
  let startString = `${startDate} ${startMonth}`;
  let endString = `${endDate} ${endMonth}`;
  if (!isSameYear(start, end)) {
    startString += ` (${startYear})`;
    endString += ` (${endYear})`;
  } else if (alwaysPrintYear) {
    endString += ` (${endYear})`;
  }
  return `${startString} - ${endString}`;
};

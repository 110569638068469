import { Cross, FloppyDisk } from "src/components/linearicons";
import { ModalContent } from "src/types/modals";

const modal: ModalContent = {
  title: "Save changes to",
  subtitle: "{configuration_name}",
  content: (
    <p style={{ fontSize: "0.687rem", textAlign: "center" }}>
      Your configuration will be active on your site.
      <>
        <br />
        For a small configuration the effect should be visible on your site in a
        few minutes,
        <br /> while a large one can take a couple of hours.
      </>
    </p>
  ),
  buttons: [
    {
      id: "cancel",
      label: "Cancel",
      icon: <Cross size="12" className="me-2" />,
      className: "btn btn-sm btn-outline-depict-brown rounded-pill me-2",
    },
    {
      id: "save",
      label: "Save & Deploy",
      icon: <FloppyDisk size="12" className="me-2" />,
      className: "btn btn-sm btn-depict-blue rounded-pill me-2",
    },
  ],
};

export default modal;

import ProductListCardPlaceholder from "../ProductListCardPlaceholder/ProductListCardPlaceholder";

const NB_ITEMS_PLACEHOLDER = 15;

export const ProductListViewPlaceholder = () => {
  return (
    <>
      {Array.from({ length: NB_ITEMS_PLACEHOLDER }).map((_, i) => (
        <div key={i} className="w-100">
          <div className="w-100">
            <ProductListCardPlaceholder />
          </div>
        </div>
      ))}
    </>
  );
};

export default ProductListViewPlaceholder;

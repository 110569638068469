import {
  ModalContentParams,
  Interpolation,
  ModalContent,
} from "src/types/modals";

import save from "src/config/modals/save";
import becomingActive from "src/config/modals/becomingActive";
import deleteModal from "src/config/modals/delete";
import becomingScheduled from "src/config/modals/becomingScheduled";
import becomingInactive from "src/config/modals/becomingInactive";
import disableAi from "src/config/modals/disableAi";

const modalContents: {
  [id: string]: ModalContent;
} = {
  save: save,
  delete: deleteModal,
  "becoming-active": becomingActive,
  "becoming-scheduled": becomingScheduled,
  "becoming-inactive": becomingInactive,
  "disable-ai": disableAi,
};

const interpolateString = (
  text: string | JSX.Element | undefined,
  interpolations: Interpolation[]
): string | JSX.Element | undefined => {
  if (typeof text === "undefined") {
    return;
  }

  if (typeof text === "object") {
    return text;
  }

  let interpolatedText = text.toString(); // To avoid side effects to `text`

  interpolations.forEach((interpolation) => {
    interpolatedText = (interpolatedText as string)?.replace(
      "{" + interpolation.placeholder + "}",
      interpolation.value
    );
  });

  return interpolatedText;
};

export const getModalContent = (
  props: ModalContentParams
): ModalContent | null => {
  const { id, buttonCallbacks, stringInterpolations } = props;

  if (!modalContents[id]) {
    return null;
  }

  const content = modalContents[id];

  let buttonWithCallbacks = content.buttons?.map((button) => {
    if (buttonCallbacks && !buttonCallbacks[button.id]) {
      return button;
    }

    let callback = buttonCallbacks && buttonCallbacks[button.id];
    return { ...button, onClick: callback };
  });

  let enhancedContent = stringInterpolations
    ? interpolateString(content.content, stringInterpolations)
    : content.content;
  let enhancedTitle = stringInterpolations
    ? interpolateString(content.title, stringInterpolations)
    : content.title;
  let enhancedSubtitle = stringInterpolations
    ? interpolateString(content.subtitle, stringInterpolations)
    : content.subtitle;
  let contentElement = enhancedContent;
  let titleElement = enhancedTitle;
  let subTitleElement = enhancedSubtitle;

  if (typeof enhancedContent === "string") {
    contentElement = (
      <p style={{ fontSize: "0.687rem", textAlign: "center" }}>
        {enhancedContent}
      </p>
    );
  }

  if (typeof enhancedTitle === "string") {
    titleElement = (
      <h4
        style={{
          fontSize: "0.875rem",
          color: "#868689",
          fontWeight: "500",
          marginBottom: "0.2rem",
        }}
      >
        {enhancedTitle}
      </h4>
    );
  }

  if (typeof enhancedSubtitle === "string") {
    subTitleElement = (
      <h5
        style={{
          fontSize: "0.875rem",
          fontWeight: "500",
          marginBottom: 0,
        }}
      >
        {enhancedSubtitle}
      </h5>
    );
  }

  return {
    buttons: buttonWithCallbacks,
    content: contentElement,
    title: titleElement,
    subtitle: subTitleElement,
  };
};

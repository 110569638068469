import { RelationshipsApiResult } from "src/types/api";
import relationshipsApi from "src/api/relationships";
import { Configuration, Relationship } from "src/types/configuration";
import cache from "src/api/cache/configurations";
import { ConfigurationsCacheKey } from "src/types/cache";

const relationshipsMiddleware = (function () {
  return {
    edit: async (
      idToken: string,
      merchant_id: string,
      configuration_id: number,
      relationship_id: string,
      user_id: string | undefined,
      params: Relationship
    ): Promise<RelationshipsApiResult> => {
      const result = await relationshipsApi({
        idToken,
        merchant_id,
        configuration_id,
        relationship_id,
        body: params,
        method: "PUT",
      });

      if (result.success) {
        const cacheKey: ConfigurationsCacheKey = {
          merchant_id: merchant_id,
          user_id: user_id ?? "no-user",
        };
        if (cache.has(cacheKey)) {
          const cached = cache.get(cacheKey);
          const index = cached.findIndex((configuration: Configuration) => {
            return configuration.id === configuration_id;
          });
          if (index !== -1) {
            const configuration: Configuration = cached[index];
            const relationships = configuration.relationships ?? [];
            const relationshipIndex = relationships.findIndex(
              (relationship: Relationship) => {
                //@ts-ignore
                return relationship.id === (result.data as Relationship).id;
              }
            );
            if (relationshipIndex !== -1) {
              const updatedRelationships = [
                ...relationships.slice(0, relationshipIndex),
                result.data,
                ...relationships.slice(relationshipIndex + 1),
              ];
              const updatedConfiguration = Object.assign(configuration, {
                relationships: updatedRelationships,
              });
              const updated = [
                ...cached.slice(0, index),
                updatedConfiguration,
                ...cached.slice(index + 1),
              ];
              cache.delete(cacheKey);
              cache.set(cacheKey, updated);
            }
          }
        }
      }

      return result as RelationshipsApiResult;
    },
    create: async (
      idToken: string,
      merchant_id: string,
      configuration_id: number,
      user_id: string | undefined,
      params: Omit<Relationship, "id">
    ): Promise<RelationshipsApiResult> => {
      const result = await relationshipsApi({
        idToken,
        merchant_id,
        configuration_id,
        body: params,
        method: "POST",
      });

      if (result.success) {
        const cacheKey: ConfigurationsCacheKey = {
          merchant_id: merchant_id,
          user_id: user_id ?? "no-user",
        };
        if (cache.has(cacheKey)) {
          const cached = cache.get(cacheKey);
          const index = cached.findIndex((configuration: Configuration) => {
            return configuration.id === configuration_id;
          });
          if (index !== -1) {
            const configuration: Configuration = cached[index];
            const relationships = configuration.relationships ?? [];
            const updatedRelationships = relationships.concat([result.data]);
            const updatedConfiguration = Object.assign(configuration, {
              relationships: updatedRelationships,
            });
            const updated = [
              ...cached.slice(0, index),
              updatedConfiguration,
              ...cached.slice(index + 1),
            ];
            cache.delete(cacheKey);
            cache.set(cacheKey, updated);
          }
        }
      }

      return result as RelationshipsApiResult;
    },
    delete: async (
      idToken: string,
      merchant_id: string,
      configuration_id: number,
      relationship_id: string
    ): Promise<RelationshipsApiResult> => {
      const result = await relationshipsApi({
        idToken,
        merchant_id,
        configuration_id,
        relationship_id,
        method: "DELETE",
      });

      console.warn(
        "DELETE DOES not take care of the cache, needs to be implemented if this endpoint is used!!"
      );

      return result as RelationshipsApiResult;
    },
  };
})();

export default relationshipsMiddleware;

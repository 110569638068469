import {
  AutoScrollActivator,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useContext, useMemo, useRef, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { OpenDropdownProvider } from "../../Dropdown/WhichDropdownOpenContext";
import ProductListViewPlaceholder from "../../ProductListViewPlaceholder/ProductListViewPlaceholder";
import { Product } from "../../types";
import { sortableDndCollisionDetection, useDndSensors } from "../dndKitCommons";
import {
  generateDnDItems,
  sortableFindGridIndexes,
  useDraggedPairOffset,
} from "../ProductGridViewSortable/utils";
import {
  ProductsContext,
  ProductsDispatchContext,
} from "../ProductsProvider/ProductsProvider";
import { ActionType } from "../ProductsProvider/ProductsProvider.type";
import {
  SortableProduct,
  useSortablePairedProducts,
} from "../useSortablePairedProducts";
import {
  ProductListCardRef,
  ProductListSortableCard,
} from "./ProductListSortableCard";
import { depictCardClassName } from "../../common";

export type RenderCardCB = (
  item: SortableProduct,
  index: number,
  extraStyle: React.CSSProperties | undefined,
  isPair: boolean
) => React.ReactNode;

interface ListViewSortableProps {
  isLoading: boolean;
  isError: boolean;
  renderCard: RenderCardCB;
  onDragEnd?: (mainProductIds: string[], newIndex: number) => void;
  selectedIds: Set<string>;
  disableDrag: boolean;
}

const ListViewDraggable = ({
  isLoading,
  isError,
  renderCard,
  onDragEnd,
  selectedIds,
  disableDrag,
}: ListViewSortableProps) => {
  const productsDispatch = useContext(ProductsDispatchContext);
  const productsContext = useContext(ProductsContext);

  if (!productsContext) {
    throw new Error(
      "useSortablePairedProducts must be used within a ProductsProvider"
    );
  }

  const { products, productGroups, productGroupIds } = productsContext;
  const { sortableProducts: pairedProducts } = useSortablePairedProducts({
    products,
    productGroups,
    productGroupIds,
  });
  const indicatorOverIndex = useRef<number>(0);

  const [draggedId, setDraggedId] = useState<string | null>(null);

  const isInSelectMode = selectedIds.size > 0;

  const idsToConsider = useMemo(() => {
    return isInSelectMode
      ? selectedIds
      : draggedId
      ? new Set([draggedId])
      : new Set<string>();
  }, [draggedId, isInSelectMode, selectedIds]);

  function handleDragStart(e: DragStartEvent) {
    setDraggedId((e.active?.id as string) ?? null);
  }

  const showProductList = pairedProducts && !isError && !isLoading;

  const handleDragEnd = (e: DragEndEvent) => {
    setDraggedId(null);
    const draggedId = e.active.id;

    const payload = sortableFindGridIndexes(
      pairedProducts,
      generateDnDItems(pairedProducts, draggedId as string).dndPairsFlatIds,
      draggedId,
      indicatorOverIndex.current
    );

    const productsWithoutSelected = pairedProducts.flat().filter((p) => {
      return !idsToConsider.has(p.id);
    });
    const productsWithSelected = pairedProducts.flat().filter((p) => {
      return idsToConsider.has(p.id);
    });

    // Insert the selected products at the correct index
    const productsWithSelectedInserted = [...productsWithoutSelected];
    productsWithSelectedInserted.splice(
      indicatorOverIndex.current,
      0,
      ...productsWithSelected
    );

    productsDispatch({
      type: ActionType.UPDATE_PRODUCTS_AND_GROUPS,
      payload: {
        products: productsWithSelectedInserted.reduce((acc, product) => {
          acc[product.main_product_id] = product;
          return acc;
        }, {} as Record<string, Product>),
        groups: productsWithSelectedInserted.map((p) => [p.id]),
      },
    });

    onDragEnd?.([...idsToConsider], payload.newIndex);
  };

  const showPlaceholders = isLoading;

  const sensors = useDndSensors();

  const activeProduct = pairedProducts.find((p) => p[0].id === draggedId);

  const overlayRef = useRef<HTMLDivElement>(null);
  const getOffset = useDraggedPairOffset(activeProduct, overlayRef);

  const dragOverlayProducts = pairedProducts
    .filter(([p]) => {
      return idsToConsider.has(p.id);
    })
    .flat();

  return (
    <OpenDropdownProvider>
      <DndContext
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        sensors={sensors}
        autoScroll={{
          activator: AutoScrollActivator.DraggableRect,
        }}
        collisionDetection={(arg) => {
          return sortableDndCollisionDetection(
            arg,
            overlayRef,
            getOffset,
            !!draggedId
          );
        }}
      >
        <SortableContext
          strategy={(arg) => {
            indicatorOverIndex.current = arg.overIndex;
            return verticalListSortingStrategy(arg);
          }}
          items={pairedProducts.map((p) => p[0].id)}
        >
          <Container
            fluid
            className="g-2 h-100 d-flex flex-column"
            style={{
              width: "100%",
            }}
          >
            {showPlaceholders && <ProductListViewPlaceholder />}
            {showProductList &&
              pairedProducts.flat().map((product, i) => {
                const isSelected = idsToConsider.has(product.id);
                const isDragged = draggedId === product.id;

                if (draggedId && isSelected && !isDragged) {
                  return null;
                }

                if (draggedId === null && overlayRef.current) {
                  // This is a hack because dnd shifts around and expands slowly the overlayRef (the product dragged)
                  // This speeds it up, making it look less weird
                  overlayRef.current.style.width = "200%";
                }

                const _disableDrag =
                  (isInSelectMode && !isSelected) || disableDrag;

                const productsToRender = isDragged
                  ? pairedProducts
                      .filter(([p]) => idsToConsider.has(p.id))
                      .flat()
                  : [product];

                return (
                  <Row
                    className={`my-1 ${depictCardClassName}`}
                    key={product.main_product_id}
                  >
                    <ProductListSortableCard
                      id={product.id}
                      beingDragged={
                        activeProduct && activeProduct[0].id === product.id
                      }
                      disableDrag={_disableDrag}
                      isPair={productsToRender.length > 1}
                      pairsLength={productsToRender.length}
                      products={productsToRender}
                      renderCard={(product, index, style, isPair) => {
                        return renderCard(
                          product,
                          product.index,
                          style,
                          isPair
                        );
                      }}
                    />
                  </Row>
                );
              })}
          </Container>
        </SortableContext>
        <DragOverlay>
          {draggedId ? (
            <ProductListCardRef
              isOverlayItem={true}
              products={dragOverlayProducts}
              isPair={false}
              ref={overlayRef}
              renderCard={renderCard}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
    </OpenDropdownProvider>
  );
};

export default ListViewDraggable;

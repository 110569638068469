import { HTMLAttributes } from "react";
import { theme } from "../designSystemVariables";
import { SwitchButtonProps } from "./SwitchButton.types";

export type Colors =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "light"
  | "dark";

type InputStyle = HTMLAttributes<HTMLInputElement>["style"];

const blackStyle: InputStyle = {
  backgroundColor: "black",
  backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")`,
};

const whiteStyle: InputStyle = {
  backgroundColor: theme.textIcon.subtle.default,
  backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")`,
};

export const SwitchButton = (props: SwitchButtonProps) => (
  <div
    className="form-check form-switch"
    data-bs-toggle="tooltip"
    data-bs-title={props["data-bs-title"]}
  >
    <input
      style={props.checked ? blackStyle : whiteStyle}
      className="form-check-input"
      type="checkbox"
      id={props.id}
      checked={props.checked}
      onChange={(e) => props.onChange(e.target.checked)}
      disabled={props.disabled}
      name={props.name}
    />
  </div>
);

export default SwitchButton;

import { DetailedProduct } from "../../types";
import { ProductMetadata } from "../../../../helpers/catalog/ProductMetadataProvider";
import ProductBadge from "../../ProductBadge/ProductBadge";
import { useEffect } from "react";
import { useState } from "react";
import { css } from "@emotion/react";
import { Button, Spinner, Table } from "react-bootstrap";
import { CaretLeft, CaretRight, X } from "@phosphor-icons/react";
import { SurfaceRecommendations } from "../../../../api/types";
import { Link } from "react-router-dom";
import { useImageResize } from "../../ImageResizeProvider/ImageResizeProvider";
import { theme } from "../../designSystemVariables";

function snakeToTitleCase(str: string) {
  const newStr = str.replace(/_/g, " ");
  return newStr.charAt(0).toUpperCase() + newStr.slice(1);
}

const sizeValues = [
  "XXXXS",
  "XXS",
  "XS",
  "S",
  "M",
  "L",
  "XL",
  "XXL",
  "XXXL",
  "XXXXL",
  "XXXXXL",
];

function sizeSorter(a: string, b: string) {
  const numberA = parseInt(a);
  const numberB = parseInt(b);

  if (!isNaN(numberA) && !isNaN(numberB)) {
    return numberA - numberB;
  }

  if (!isNaN(numberA)) {
    return -1;
  }

  if (!isNaN(numberB)) {
    return 1;
  }

  const indexA = sizeValues.indexOf(a);
  const indexB = sizeValues.indexOf(b);

  if (indexA === -1) {
    return 1;
  }

  if (indexB === -1) {
    return -1;
  }

  return indexA - indexB;
}

export default function CatalogProductModal({
  open,
  onClose,
  product,
  variants,
  isLoading,
  productMetadata,
  onBrowseBack,
  onBrowseForward,
  recommendedProducts,
  getLinkToProduct,
  oosAutoHideState,
}: {
  open: boolean;
  product: DetailedProduct | null;
  variants: DetailedProduct[];
  productMetadata: ProductMetadata[];
  onClose: () => void;
  onBrowseBack?: () => void;
  onBrowseForward?: () => void;
  isLoading?: boolean;
  recommendedProducts?: SurfaceRecommendations[];
  getLinkToProduct: (mainProductId: string) => string;
  oosAutoHideState: boolean;
}) {
  const [selectedSurfaceType, setSelectedSurfaceType] = useState<string>();

  const selectedSurface = recommendedProducts?.find(
    (s) => s.type === selectedSurfaceType
  );

  useEffect(() => {
    if (!recommendedProducts || recommendedProducts.length === 0) return;
    setSelectedSurfaceType(recommendedProducts[0].type);
  }, [recommendedProducts]);

  const { getImageUrl } = useImageResize();

  const colors: Map<string, string | undefined> = new Map();
  if (product?.color_name) {
    colors.set(product.color_name, product.color_hex ?? undefined);
  }
  variants.forEach((v) => {
    if (v.color_name) {
      colors.set(v.color_name, v.color_hex ?? undefined);
    }
  });

  const sizeNames = product?.sizes.map((s) => s.size_name).sort(sizeSorter);

  // each size has a list of inventories with stock data
  // we want to have a list of inventories that contains that data for each size

  const inventories: Map<
    string,
    { sizeName: string; quantity: number | null; inStock: boolean }[]
  > = new Map();

  for (const size of product?.sizes || []) {
    for (const inventory of size.inventories) {
      if (inventories.has(inventory.inventory_id)) {
        inventories.set(inventory.inventory_id, [
          ...inventories.get(inventory.inventory_id)!,
          {
            sizeName: size.size_name,
            quantity: inventory.quantity,
            inStock: inventory.in_stock,
          },
        ]);
      } else {
        inventories.set(inventory.inventory_id, [
          {
            sizeName: size.size_name,
            quantity: inventory.quantity,
            inStock: inventory.in_stock,
          },
        ]);
      }
    }
  }

  return (
    <>
      <div
        css={css`
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
          z-index: 1000;
          transition: opacity 0.2s ease-in-out;
          opacity: ${open ? 1 : 0};
          visibility: ${open ? "visible" : "hidden"};
        `}
        onClick={onClose}
      />
      <div
        css={css`
          position: fixed;
          top: 0;
          right: 0;
          width: 33%;
          min-width: 420px;
          z-index: 1001;
          height: 100vh;
          padding: 8px;

          transition: transform 0.2s ease-in-out;
          transform: ${open ? "translateX(0)" : "translateX(100%)"};
        `}
      >
        <div
          css={css`
            border-radius: 16px;
            background: white;
            height: 100%;
            padding: 16px;
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 8px;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                margin-right: 8px;
              `}
            >
              <Button
                variant="outline-secondary"
                css={css`
                  border: 1px solid #e4e4e5;
                  padding: 8px;
                  margin-right: 4px;
                `}
                disabled={!onBrowseBack}
                onClick={onBrowseBack}
              >
                <CaretLeft size={16} />
              </Button>
              <Button
                variant="outline-secondary"
                css={css`
                  border: 1px solid #e4e4e5;
                  padding: 8px;
                `}
                disabled={!onBrowseForward}
                onClick={onBrowseForward}
              >
                <CaretRight size={16} />
              </Button>
            </div>
            <div
              css={css`
                min-width: 0;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                color: #0f0f0f;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-right: 8px;
              `}
            >
              {product?.title}
            </div>
            <Button
              variant="outline-secondary"
              css={css`
                border: 1px solid #e4e4e5;
                padding: 8px;
              `}
              onClick={onClose}
            >
              <X size={16} />
            </Button>
          </div>
          {isLoading && (
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
              `}
            >
              <Spinner />
            </div>
          )}
          {!isLoading && product && (
            <div
              css={css`
                font-size: 14px;
                color: #0f0f0f;
                font-style: normal;
                overflow: auto;
                height: calc(100% - 40px);
              `}
            >
              {product.image_url && (
                <div
                  css={css`
                    position: relative;
                    background: url(${product.image_url}) center;
                    background-size: cover;
                    border-radius: 16px;
                    border: 1px solid #e4e4e5;
                    height: 605px;
                    margin-bottom: 24px;
                  `}
                />
              )}
              <div
                css={css`
                  margin-bottom: 24px;
                `}
              >
                <h5
                  css={css`
                    font-weight: 700;
                    margin-bottom: 4px;
                  `}
                >
                  Product ID
                </h5>
                <p>{product.main_product_id}</p>
              </div>
              {colors.size > 0 && (
                <div
                  css={css`
                    margin-bottom: 24px;
                  `}
                >
                  <h5
                    css={css`
                      font-weight: 700;
                      margin-bottom: 8px;
                    `}
                  >
                    Colors
                  </h5>
                  <div
                    css={css`
                      display: flex;
                      gap: 4px;
                    `}
                  >
                    {Array.from(colors.entries()).map(([name, hex]) => (
                      <div
                        css={css`
                          display: flex;
                          align-items: center;
                          padding: 4px 8px;
                          border-radius: 100px;
                          background: #f3f3f5;
                        `}
                      >
                        {hex && (
                          <div
                            css={css`
                              background: ${hex ?? "#ffcc00"};
                              border-radius: 40px;
                              width: 16px;
                              height: 16px;
                              margin-right: 4px;
                            `}
                          />
                        )}
                        <div
                          css={css`
                            color: #0f0f0f;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                          `}
                        >
                          {name}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {variants.length > 0 && (
                <div
                  css={css`
                    margin-bottom: 24px;
                  `}
                >
                  <h5
                    css={css`
                      font-weight: 700;
                      margin-bottom: 8px;
                    `}
                  >
                    Variants
                  </h5>
                  <div
                    css={css`
                      display: flex;
                      gap: 4px;
                    `}
                  >
                    {variants.map((v) => (
                      <Link
                        to={getLinkToProduct(v.main_product_id)}
                        css={css`
                          display: flex;
                          align-items: center;
                          padding: 4px 8px 4px 4px;
                          border: 1px solid #e4e4e5;
                          border-radius: 12px;
                        `}
                      >
                        <div
                          css={css`
                            background: ${v.image_url
                              ? `url(${getImageUrl(v.image_url)}) center`
                              : "#ffcc00"};
                            background-size: cover;
                            border-radius: 8px;
                            width: 40px;
                            height: 40px;
                          `}
                        />
                        <div
                          css={css`
                            color: #0f0f0f;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            margin-left: 4px;
                          `}
                        >
                          {v.main_product_id}
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              )}
              {productMetadata.length > 0 && (
                <div
                  css={css`
                    margin-bottom: 24px;
                  `}
                >
                  <h5
                    css={css`
                      font-weight: 700;
                      margin-bottom: 8px;
                    `}
                  >
                    Status
                  </h5>

                  <div
                    css={css`
                      display: flex;
                      gap: 4px;
                    `}
                  >
                    {productMetadata.map((md, index) => (
                      <ProductBadge
                        metadata={md}
                        key={`${md.type}-${index}`}
                        oos_auto_hide={oosAutoHideState}
                      />
                    ))}
                  </div>
                </div>
              )}
              {product.description && (
                <div
                  css={css`
                    margin-bottom: 24px;
                  `}
                >
                  <h5
                    css={css`
                      font-weight: 700;
                      margin-bottom: 4px;
                    `}
                  >
                    Description
                  </h5>
                  <p>{product.description}</p>
                </div>
              )}
              <div
                css={css`
                  margin-bottom: 24px;
                `}
              >
                <h5
                  css={css`
                    font-weight: 700;
                    margin-bottom: 8px;
                  `}
                >
                  Inventory
                </h5>
                <div className="border rounded-3 overflow-hidden">
                  <Table className="m-0">
                    <thead
                      className="bg-body"
                      css={css`
                        color: #868689;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                      `}
                    >
                      <tr>
                        <th className="p-2 text-uppercase align-middle">
                          Inventory ID
                        </th>
                        {sizeNames?.map((sizeName) => (
                          <th className="p-2 text-uppercase align-middle">
                            {sizeName}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {Array.from(inventories.entries()).map(
                        ([inventoryId, sizes]) => (
                          <tr key={inventoryId} className="border-top">
                            <td className="p-2 align-middle">{inventoryId}</td>
                            {sizeNames?.map((sizeName) => {
                              const inventory = sizes.find(
                                (s) => s.sizeName === sizeName
                              );
                              return (
                                <td className="p-2 align-middle text-center">
                                  <div
                                    css={css`
                                      display: flex;
                                      align-items: center;
                                    `}
                                  >
                                    <div
                                      css={css`
                                        height: 8px;
                                        width: 8px;
                                        background: ${inventory?.inStock
                                          ? "#31C48D"
                                          : "#F3616D"};
                                        border-radius: 50%;
                                        margin-right: 6px;
                                      `}
                                    />
                                    {inventory?.quantity !== null && (
                                      <div>{inventory?.quantity}</div>
                                    )}
                                  </div>
                                </td>
                              );
                            })}
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
              <div
                css={css`
                  margin-bottom: 24px;
                `}
              >
                <h5
                  css={css`
                    font-weight: 700;
                    margin-bottom: 8px;
                  `}
                >
                  Price
                </h5>
                <div className="border rounded-3 overflow-hidden">
                  <Table className="m-0">
                    <thead
                      className="bg-body"
                      css={css`
                        color: #868689;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                      `}
                    >
                      <tr>
                        <th className="p-2 text-uppercase align-middle">
                          Pricelist
                        </th>
                        <th className="p-2 text-uppercase align-middle">
                          Currency
                        </th>
                        <th className="p-2 text-uppercase align-middle">
                          Sale Price
                        </th>
                        <th className="p-2 text-uppercase align-middle">
                          Original Price
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {product.pricelist_prices.map((price) => (
                        <tr key={price.pricelist_id} className="border-top">
                          <td className="p-2 align-middle">
                            {price.pricelist_id}
                          </td>
                          <td className="p-2 align-middle">{price.currency}</td>
                          <td className="p-2 align-middle">
                            {parseFloat(price.sale_price).toFixed(2)}
                          </td>
                          <td className="p-2 align-middle">
                            {parseFloat(price.original_price).toFixed(2)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
              {recommendedProducts?.every(
                (surface) => surface.products.length > 0
              ) && (
                <div
                  css={css`
                    margin-bottom: 24px;
                  `}
                >
                  <h5
                    css={css`
                      font-weight: 700;
                      margin-bottom: 8px;
                    `}
                  >
                    Recommendations
                  </h5>
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    {recommendedProducts.map((s) => (
                      <Button
                        variant="outline-secondary"
                        css={css`
                          text-decoration: none !important;
                          border: 1px solid
                            ${s.type === selectedSurfaceType
                              ? "#0b0b0b"
                              : "transparent"};
                          border-radius: 16px;
                          padding: 6px 8px;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 400;
                          display: flex;
                          align-items: center;
                          height: 32px;

                          background-color: ${s.type === selectedSurfaceType
                            ? "#ffffff"
                            : "#f3f3f5"};

                          margin-right: 4px;

                          &:hover {
                            background-color: ${theme.background.subtle.hover};
                          }
                        `}
                        onClick={() => setSelectedSurfaceType(s.type)}
                        key={s.type}
                      >
                        {snakeToTitleCase(s.type)}
                      </Button>
                    ))}
                  </div>
                  <div
                    css={css`
                      overflow: auto;
                    `}
                  >
                    <div
                      css={css`
                        width: fit-content;
                        display: flex;
                        gap: 16px;
                        margin-top: 8px;
                        background: #f3f3f5;
                        border-radius: 24px;
                        padding: 8px;
                      `}
                    >
                      {selectedSurface?.products.map((p) => (
                        <Link
                          to={getLinkToProduct(p.main_product_id)}
                          css={css`
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                            background: white;
                            padding: 8px;
                            border-radius: 16px;
                            border: 1px solid #e4e4e5;
                          `}
                        >
                          <div
                            css={css`
                              background: ${p.image_url
                                ? `url(${getImageUrl(p.image_url)}) center`
                                : "#ffcc00"};
                              background-size: cover;
                              border-radius: 16px;
                              width: 120px;
                              height: 180px;
                            `}
                          />
                          <div
                            css={css`
                              color: #0f0f0f;
                              font-size: 14px;
                              font-style: normal;
                              font-weight: 700;
                              margin-top: 8px;

                              max-width: 120px;

                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            `}
                          >
                            {p.title}
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

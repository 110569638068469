import { useEffect, useState } from "react";
import { Table } from "src/types/table";
import { DepictMetricsData } from "src/types/metrics";
import { getTableData, getTableRows, tableHeaders } from "src/helpers/table";
import { DataPeriod, EnhancedSurface } from "src/types/components";

type UseTableData = [tableData: Table | null];

export default function useTableData(
  metrics: {
    [metric: string]: DepictMetricsData | null;
  },
  merchant: string | null,
  market: string | null,
  splitBySurface: boolean,
  period: DataPeriod | undefined,
  currencySymbol: string | null,
  symbolLeading: boolean | null,
  enabledSurfaces: EnhancedSurface[] | null,
  suffix?: string
): UseTableData {
  const [tableData, setTableData] = useState<Table | null>(null);

  useEffect(() => {
    if (!metrics || !market) {
      setTableData(null);
      return;
    }

    let rows = getTableRows(metrics, enabledSurfaces);
    let data = getTableData(
      metrics,
      rows,
      tableHeaders,
      market,
      splitBySurface,
      period,
      suffix,
      currencySymbol,
      symbolLeading
    );

    if (!data.length) {
      setTableData(null);
      return;
    }

    setTableData(data);
  }, [
    metrics,
    splitBySurface,
    period,
    currencySymbol,
    symbolLeading,
    market,
    merchant,
    enabledSurfaces,
    suffix,
  ]);

  return [tableData];
}

import { css } from "@emotion/react";
import { useEffect, useRef } from "react";
import { Element } from "react-scroll";
import Button from "src/components/storybook/Button/Button";
import { useIntersectionObserver } from "usehooks-ts";
import { useComponentHeight } from "./useComponentHeight";
import { depictCardClassName } from "src/components/storybook/common";

export enum SectionKey {
  pinned = "pinned",
  relevance = "relevance",
  hidden = "hidden",
}

export type SectionWrapperProps = {
  children: React.ReactNode;
  keyName: SectionKey;
  onVisibilityChange: (visible: boolean, section: SectionKey) => void;
  headerHeight: number;
};

export const SectionSpacer = () => {
  return (
    <div
      css={css`
        margin-top: 1.25rem;
      `}
    />
  );
};

export const SectionWrapper = ({
  children,
  keyName,
  onVisibilityChange,
  headerHeight,
}: SectionWrapperProps) => {
  const cardSelector = `#${keyName} .${depictCardClassName}`;
  const cardHeight = useComponentHeight({
    selector: cardSelector,
  });
  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(ref, {
    rootMargin: `${-headerHeight - cardHeight}px 0px 0px 0px`,
  });
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    onVisibilityChange?.(isVisible, keyName);
  }, [isVisible, keyName, onVisibilityChange]);

  return (
    <Element name={keyName} id={keyName}>
      <div key={keyName} ref={ref}>
        <div
          css={css`
            padding: 1rem;
          `}
        >
          {children}
        </div>

        <SectionSpacer />
      </div>
    </Element>
  );
};

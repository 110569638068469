import { useParams } from "react-router-dom";

const useCategoryId = () => {
  const { categoryId } = useParams();

  const decodedCategoryId = categoryId ? decodeURIComponent(categoryId) : null;

  return {
    categoryId: decodedCategoryId,
  };
};

export default useCategoryId;

import { useCallback } from "react";
import { deepCopy } from "src/helpers";
import {
  createConfigurationCopy,
  createGroupsCopy,
  createBasicRelationships,
} from "src/helpers/configuration";

import { Configuration } from "src/types/configuration";

import { getAlertMessage, getErrorMessage } from "src/helpers/errors";
import { ControlLoadingQueueCB } from "src/types/components";
import { useAuth0 } from "@auth0/auth0-react";
import useAuthentication from "../app/useAuthentication";
import { useAlerts } from "../../../components/storybook/Alert/useAlerts";

export default function useDuplicateConfiguration(
  market: string | null,
  onViewConfiguration: Configuration | null,
  aiState: boolean,
  setCurrentConfigurationId: Function,
  refreshConfigurations: Function,
  controlLoadingQueueCB: ControlLoadingQueueCB
) {
  const { user } = useAuth0();
  const { addAlert } = useAlerts();
  const [getAccessToken] = useAuthentication();

  const finalizeDuplication = useCallback(
    (configuration: Configuration) => {
      refreshConfigurations();
      setCurrentConfigurationId(configuration.id);
      controlLoadingQueueCB("duplicate-configuration", "remove");
    },
    [controlLoadingQueueCB, refreshConfigurations, setCurrentConfigurationId]
  );

  const duplicateConfiguration = useCallback(async () => {
    if (!onViewConfiguration?.merchant_id || !market) {
      return;
    }

    controlLoadingQueueCB("duplicate-configuration", "push");

    const id_token = await getAccessToken();
    let request = await createConfigurationCopy(
      onViewConfiguration,
      id_token,
      user?.email
    );

    if (!request.success) {
      let message = await getAlertMessage(request.error);
      addAlert(message);
      console.log(`%c[DUPLICATE] Duplication failed`, "color: violet");
      return;
    }

    console.log(
      `%c[DUPLICATE] Duplicated configuration with success.`,
      "color: violet"
    );

    const duplicate = request.data;

    if (!onViewConfiguration.relationships.length) {
      console.log(
        `%c[DUPLICATE] Configuration does not have relationships. Exiting duplication.`,
        "color: violet"
      );
      finalizeDuplication(duplicate);
      return;
    }

    const response = await createGroupsCopy(
      onViewConfiguration,
      aiState,
      market,
      id_token,
      user?.email
    );

    if (response === null) {
      const message = getErrorMessage("duplicate-groups");
      if (message) {
        addAlert(message);
      }
      finalizeDuplication(duplicate);
      return;
    }

    if (!response.success) {
      const message = await getAlertMessage(response.error);
      addAlert(message);
      finalizeDuplication(duplicate);
      return;
    }

    console.log(
      `%c[DUPLICATE] Duplicated configuration groups.`,
      "color: violet"
    );

    const result = await createBasicRelationships(
      duplicate.merchant_id,
      duplicate.id,
      aiState,
      response.data,
      id_token,
      user?.email
    );

    if (!result.success) {
      let message = await getAlertMessage(result.error);
      addAlert(message);
      finalizeDuplication(duplicate);
      return;
    }

    console.log(
      `%c[DUPLICATE] Duplicated configuration relationships.`,
      "color: violet"
    );

    const relationships = result.data;

    const duplicateRelationships = [
      relationships.include,
      relationships.exclude,
    ];

    let completeDuplicate = deepCopy(duplicate);
    completeDuplicate.relationships = duplicateRelationships;
    finalizeDuplication(completeDuplicate);
  }, [
    onViewConfiguration,
    market,
    controlLoadingQueueCB,
    getAccessToken,
    user?.email,
    aiState,
    finalizeDuplication,
    addAlert,
  ]);

  return [duplicateConfiguration];
}

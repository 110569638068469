import { css } from "@emotion/react";
import { Check, Sparkle } from "@phosphor-icons/react";
import { FC, Fragment } from "react";
import { Dropdown } from "react-bootstrap";
import { theme } from "src/components/storybook/designSystemVariables";

export interface OptionModel {
  label: string;
  value: string;
}

export interface OptionGroupModel {
  label: string;
  options: OptionModel[];
}

interface Props {
  label: string;
  optionGroups: OptionGroupModel[];
  selectedValue: string;
  onSelect: (value: string) => void;
}

export const CategoryHeaderDropdown: FC<Props> = ({
  label,
  optionGroups,
  selectedValue,
  onSelect,
}) => (
  <Dropdown
    drop="down"
    css={css`
      padding: 0;
    `}
  >
    <Dropdown.Toggle
      variant={"link"}
      css={css`
        padding: 0.5rem;
        text-decoration: none !important;
        align-items: center;
        &:hover {
          background-color: ${theme.background.subtle.hover};
        }
      `}
    >
      <span
        css={css`
          display: block;
          color: ${theme.textIcon.subtle.default};
          margin-right: 0.25rem;
        `}
      >
        {label}:
      </span>
      <span
        css={css`
          color: ${theme.textIcon.base.default};
          display: flex;
          align-items: center;
          gap: 0.25rem;
        `}
      >
        <span
          css={css`
            line-height: 20px;
          `}
        >
          {optionGroups
            .flatMap((optionGroup) => optionGroup.options)
            .find((option) => option.value === selectedValue)?.label || "-"}
        </span>
      </span>
    </Dropdown.Toggle>
    <Dropdown.Menu
      css={css`
        padding: 6px 0;
        min-width: 233px;
      `}
    >
      {optionGroups.map((optionGroup, index) => (
        <Fragment key={optionGroup.label}>
          <div
            css={css`
              font-size: 12px;
              line-height: 20px;
              padding: 0.75rem 1rem;
              padding-bottom: 0;
              border-top: 1px solid ${theme.background.neutral.default};
              border-width: ${index > 0 ? 1 : 0};
            `}
          >
            {optionGroup.label}
          </div>
          {optionGroup.options.map((option) => (
            <Dropdown.Item
              css={css`
                display: flex;
                justify-content: space-between;
                padding: 0.75rem 1rem;
                font-size: 14px;
                line-height: 20px;
                color: ${selectedValue === option.value
                  ? theme.textIcon.accent.default
                  : theme.textIcon.base.default};
              `}
              key={option.value}
              onClick={() => onSelect(option.value)}
            >
              {option.label}
              <Check
                size={16}
                css={css`
                  color: ${theme.textIcon.accent.default};
                  opacity: ${selectedValue === option.value ? 1 : 0};
                `}
              />
            </Dropdown.Item>
          ))}
        </Fragment>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);

import { useQuery } from "@tanstack/react-query";
import React, { useMemo } from "react";
import { components } from "src/api/generated/openapi";
import { QueryId, getQueryKey } from "../../queries/queries";
import useAuthorizedApi from "../hooks/app/useAuthorizedApi";
import useMerchant from "../hooks/app/useMerchant";
import { getData } from "src/api/authorizedApi";

export interface ProductMetadataProviderProps {
  metadata?: { [key: string]: ProductMetadata[] };
}

export const ProductMetadataContext =
  React.createContext<ProductMetadataProviderProps | null>(null);

export type ProductMetadata =
  components["schemas"]["ProductWithMetadata"]["metadata"][number];

export const ProductMetadataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { api } = useAuthorizedApi();
  const { merchantId } = useMerchant();

  const metadataQuery = useQuery({
    queryKey: getQueryKey(QueryId.GetCatalogProductMetadata, merchantId || ""),
    queryFn: async () => {
      const response = await api?.GET(
        "/api/v0/catalog/products/{merchant_id}/metadata",
        {
          params: {
            path: {
              merchant_id: merchantId,
            },
          },
        }
      );

      if (!response) throw new Error("No response");

      const data = getData(response);

      if (!data.metadata) {
        throw new Error("No metadata found");
      }

      const metadata = response?.data?.metadata.reduce((acc, curr) => {
        acc[curr.main_product_id] = curr.metadata;
        return acc;
      }, {} as { [key: string]: ProductMetadata[] });

      return metadata;
    },

    enabled: !!merchantId && !!api,
    staleTime: 10 * 60 * 1000,
  });

  const metadata = useMemo(() => {
    if (!metadataQuery.data) {
      return {};
    }
    return metadataQuery.data;
  }, [metadataQuery.data]);

  return (
    <ProductMetadataContext.Provider value={{ metadata }}>
      {children}
    </ProductMetadataContext.Provider>
  );
};

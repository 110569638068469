import config from "src/config";

import { UserApiResult } from "src/types/api";

const userApi = async (params: {
  idToken: string;
  method?: "GET" | "POST" | "PUT";
}): Promise<UserApiResult> => {
  let { idToken } = params;
  const endpoint = `/users/me`;
  const url = config.api.baseUrl + "/" + config.api.version + endpoint;

  return new Promise((resolve) => {
    fetch(url, {
      headers: {
        accept: "application/json",
        Authorization: "Bearer " + idToken,
      },
    })
      .then((response) => {
        if (typeof response.ok === "undefined" || response.ok === false) {
          throw response;
        }

        return response.json();
      })
      .then((data) => {
        resolve({
          success: true,
          data: data,
        });
      })
      .catch((error: any | Response) => {
        resolve({
          success: false,
          error: error,
        });
      });
  });
};

export default userApi;

import useAuthorizedApi from "./useAuthorizedApi";
import { getQueryKey, QueryId } from "../../../queries/queries";
import { getData } from "../../../api/authorizedApi";
import { useQuery } from "@tanstack/react-query";

export function useMaintenance() {
  const { api } = useAuthorizedApi();

  const { data: maintenanceInfo, isLoading } = useQuery({
    queryKey: getQueryKey(QueryId.GetMaintenance),
    queryFn: async () => {
      if (!api) return null;
      const response = await api.GET("/api/v0/maintenance");

      return getData(response);
    },
    enabled: !!api,
    refetchInterval: 1000 * 60 * 5,
  });

  return {
    maintenanceInfo,
    isLoading,
  };
}

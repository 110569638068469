import { useCallback, useState } from "react";

export function useCachedState<T>(
  key: string,
  defaultValue: T
): [T, (value: T | ((prevValue: T) => T)) => void] {
  const [stateValue, setStateValue] = useState<T>(() => {
    const cachedValue = localStorage.getItem(key);
    if (cachedValue) {
      return JSON.parse(cachedValue);
    }
    return defaultValue;
  });

  const setCachedValue = useCallback(
    (value: T | ((prevValue: T) => T)) => {
      setStateValue((prev) => {
        // If value is a function, call it with the previous state
        const newValue = value instanceof Function ? value(prev) : value;
        localStorage.setItem(key, JSON.stringify(newValue));
        return newValue;
      });
    },
    [key]
  );

  return [stateValue, setCachedValue];
}

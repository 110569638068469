 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Mic2 = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M2.5 5c-0.132 0-0.261-0.053-0.353-0.147s-0.147-0.222-0.147-0.353 0.053-0.261 0.147-0.353c0.093-0.093 0.222-0.147 0.353-0.147s0.26 0.053 0.353 0.147c0.093 0.093 0.147 0.222 0.147 0.353s-0.053 0.261-0.147 0.353c-0.093 0.093-0.222 0.147-0.353 0.147z" fill={color}></path>
<path d="M4.5 3c-0.132 0-0.261-0.053-0.353-0.147s-0.147-0.222-0.147-0.353 0.053-0.261 0.147-0.353c0.093-0.093 0.222-0.147 0.353-0.147s0.261 0.053 0.353 0.147c0.093 0.093 0.147 0.222 0.147 0.353s-0.053 0.261-0.147 0.353c-0.093 0.093-0.222 0.147-0.353 0.147z" fill={color}></path>
<path d="M2.5 7c-0.132 0-0.26-0.053-0.353-0.147s-0.147-0.222-0.147-0.353 0.053-0.26 0.147-0.353 0.222-0.147 0.353-0.147 0.26 0.053 0.353 0.147c0.093 0.093 0.147 0.222 0.147 0.353s-0.053 0.26-0.147 0.353c-0.093 0.093-0.222 0.147-0.353 0.147z" fill={color}></path>
<path d="M4.5 5c-0.132 0-0.261-0.053-0.353-0.147s-0.147-0.222-0.147-0.353 0.053-0.26 0.147-0.353 0.222-0.147 0.353-0.147 0.261 0.053 0.353 0.147c0.093 0.093 0.147 0.222 0.147 0.353s-0.053 0.261-0.147 0.353c-0.093 0.093-0.222 0.147-0.353 0.147z" fill={color}></path>
<path d="M6.5 3c-0.132 0-0.26-0.053-0.353-0.147s-0.147-0.222-0.147-0.353 0.053-0.26 0.147-0.353 0.222-0.147 0.353-0.147 0.26 0.053 0.353 0.147c0.093 0.093 0.147 0.222 0.147 0.353s-0.053 0.26-0.147 0.353c-0.093 0.093-0.222 0.147-0.353 0.147z" fill={color}></path>
<path d="M4.5 9c-0.132 0-0.261-0.053-0.353-0.147s-0.147-0.222-0.147-0.353 0.053-0.261 0.147-0.353c0.093-0.093 0.222-0.147 0.353-0.147s0.26 0.053 0.353 0.147c0.093 0.093 0.147 0.222 0.147 0.353s-0.053 0.261-0.147 0.353c-0.093 0.093-0.222 0.147-0.353 0.147z" fill={color}></path>
<path d="M6.5 7c-0.132 0-0.26-0.053-0.353-0.147s-0.147-0.222-0.147-0.353 0.053-0.26 0.147-0.353 0.222-0.147 0.353-0.147 0.26 0.053 0.353 0.147c0.093 0.093 0.147 0.222 0.147 0.353s-0.053 0.26-0.147 0.353c-0.093 0.093-0.222 0.147-0.353 0.147z" fill={color}></path>
<path d="M8.5 5c-0.132 0-0.261-0.053-0.353-0.147s-0.147-0.222-0.147-0.353 0.053-0.26 0.147-0.353c0.093-0.093 0.222-0.147 0.353-0.147s0.26 0.053 0.353 0.147c0.093 0.093 0.147 0.222 0.147 0.353s-0.053 0.261-0.147 0.353c-0.093 0.093-0.222 0.147-0.353 0.147z" fill={color}></path>
<path d="M4.5 7c-0.132 0-0.26-0.053-0.353-0.147s-0.147-0.222-0.147-0.353 0.053-0.261 0.147-0.353c0.093-0.093 0.222-0.147 0.353-0.147s0.261 0.053 0.353 0.147c0.093 0.093 0.147 0.222 0.147 0.353s-0.053 0.261-0.147 0.353c-0.093 0.093-0.222 0.147-0.353 0.147z" fill={color}></path>
<path d="M6.5 5c-0.132 0-0.261-0.053-0.353-0.147s-0.147-0.222-0.147-0.353 0.053-0.261 0.147-0.353c0.093-0.093 0.222-0.147 0.353-0.147s0.261 0.053 0.353 0.147c0.093 0.093 0.147 0.222 0.147 0.353s-0.053 0.26-0.147 0.353c-0.093 0.093-0.222 0.147-0.353 0.147z" fill={color}></path>
<path d="M8.5 3c-0.132 0-0.26-0.053-0.353-0.147s-0.147-0.222-0.147-0.353 0.053-0.26 0.147-0.353c0.093-0.093 0.222-0.147 0.353-0.147s0.261 0.053 0.353 0.147c0.093 0.093 0.147 0.222 0.147 0.353s-0.053 0.261-0.147 0.353c-0.093 0.093-0.222 0.147-0.353 0.147z" fill={color}></path>
<path d="M2.5 9c-0.132 0-0.261-0.053-0.353-0.147s-0.147-0.222-0.147-0.353 0.053-0.261 0.147-0.353c0.093-0.093 0.222-0.147 0.353-0.147s0.26 0.053 0.353 0.147c0.093 0.093 0.147 0.222 0.147 0.353s-0.053 0.26-0.147 0.353c-0.093 0.093-0.222 0.147-0.353 0.147z" fill={color}></path>
<path d="M19.89 16.188l-7.899-9.873c0.005-0.104 0.008-0.209 0.008-0.314 0-3.308-2.692-6-6-6s-6 2.692-6 6c0 1.982 0.966 3.742 2.452 4.836 0.025 0.023 0.052 0.042 0.081 0.059 0.98 0.696 2.176 1.105 3.466 1.105 0.105 0 0.21-0.003 0.314-0.008l9.873 7.899c0.090 0.072 0.2 0.11 0.312 0.11 0.042 0 0.083-0.005 0.125-0.016 0.776-0.2 1.509-0.628 2.12-1.239s1.039-1.344 1.239-2.12c0.039-0.152 0.004-0.314-0.094-0.437zM1 6c0-2.757 2.243-5 5-5 1.602 0 3.030 0.757 3.946 1.933-0.427 3.677-3.339 6.587-7.013 7.013-1.176-0.916-1.933-2.344-1.933-3.946zM4.301 10.703c3.122-0.823 5.578-3.277 6.402-6.401 0.192 0.531 0.297 1.102 0.297 1.699 0 2.757-2.243 5-5 5-0.596 0-1.168-0.105-1.699-0.297zM16.608 18.946l-8.968-7.174c1.992-0.567 3.565-2.14 4.132-4.132l7.174 8.968c-0.361 1.061-1.277 1.977-2.338 2.338z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Mic2); 
import { LottieOptions, useLottie } from "lottie-react";
import skullAnimation from "./skullAnimation.json";

const style = {
  height: 240,
};

const BurySaveAnimation = ({
  onFinish,
  loop = false,
}: {
  onFinish: () => void;
  loop?: boolean;
}) => {
  const options: LottieOptions = {
    animationData: skullAnimation,
    loop: loop,
    autoplay: true,
    onComplete: () => {
      setTimeout(() => {
        onFinish();
      }, 500);
    },
  };

  const { View } = useLottie(options, style);

  return View;
};

export default BurySaveAnimation;

import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { SwitchButton } from "./SwitchButton";

interface FormSwitchButtonProps<T extends FieldValues> {
  name: FieldPath<T>;
  "data-bs-title"?: string;
  disabled?: boolean;
}
export function FormSwitchButton<T extends FieldValues>({
  name,
  "data-bs-title": dataBsTitle,
  disabled,
}: FormSwitchButtonProps<T>) {
  const { control } = useFormContext<T>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <SwitchButton
          checked={field.value}
          onChange={field.onChange}
          disabled={disabled}
          id={field.name}
          name={field.name}
          data-bs-title={dataBsTitle}
        />
      )}
    />
  );
}

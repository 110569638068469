import { useQuery } from "@tanstack/react-query";
import { getData } from "src/api/authorizedApi";
import useAuthorizedApi from "../../../helpers/hooks/app/useAuthorizedApi";
import { QueryId, getQueryKey } from "../../../queries/queries";
import ShopifyMetafieldsConfig from "../../storybook/Integrations/Shopify/ShopifyMetafieldsConfig";
import { ParsingConfig } from "../../storybook/Integrations/Shopify/types";
import { paths } from "src/api/generated/openapi";

type ShopifyAvailableAttributesResponse =
  paths["/api/v0/integrations/shopify/metafields-info"]["post"]["responses"]["200"]["content"]["application/json"];

export default function ShopifyMetafieldsConfigStep({
  adminApiUrl,
  adminApiKey,
  onSubmit,
  onBack,
  merchantId,
}: {
  adminApiUrl?: string;
  adminApiKey?: string;
  onSubmit: (
    genderParsingConfig: ParsingConfig,
    ageParsingConfig: ParsingConfig,
    optionalAttributeParsingConfigs: ParsingConfig[]
  ) => void;
  onBack: () => void;
  merchantId: string;
}) {
  const { api } = useAuthorizedApi();
  const { data, isLoading, error } = useQuery<
    ShopifyAvailableAttributesResponse | undefined,
    any & { message: string }
  >({
    queryKey: getQueryKey(
      QueryId.GetAvailableShopifyMetafieldDefinitions,
      merchantId,
      adminApiUrl || "",
      adminApiKey || ""
    ),
    queryFn: async () => {
      if (api && adminApiUrl && adminApiKey) {
        const response = await api.POST(
          "/api/v0/integrations/shopify/metafields-info",
          {
            body: {
              admin_api_url: adminApiUrl,
              admin_api_key: adminApiKey,
            },
          }
        );
        return getData(response);
      }
    },

    enabled: !!api && !!adminApiUrl && !!adminApiKey,
  });

  return (
    <ShopifyMetafieldsConfig
      availableOptionalAttributeIds={data?.optional_attributes || []}
      metafieldDefinitions={data?.metafield_definitions || []}
      isLoading={isLoading}
      errorMessage={error?.message}
      onSubmit={onSubmit}
      onBack={onBack}
    />
  );
}

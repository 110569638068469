import { useEffect, useState } from "react";
import { getAuthorizedApi, getData } from "../../../api/authorizedApi";
import useAuthentication from "../../../helpers/hooks/app/useAuthentication";
import { CentraStore } from "../../storybook/Integrations/Centra/types";

export const useCentraStores = (apiUrl: string, apiKey: string) => {
  const [availableStores, setAvailableStores] = useState<CentraStore[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error] = useState<string | null>(null);
  const [getAccessToken] = useAuthentication();

  useEffect(() => {
    const fetchMarkets = async () => {
      setIsLoading(true);

      const api = getAuthorizedApi(await getAccessToken());
      const response = await api.POST("/api/v0/integrations/centra/stores", {
        body: {
          api_key: apiKey,
          api_url: apiUrl,
        },
      });

      const data = getData(response);

      setIsLoading(false);
      setAvailableStores(data);
    };
    fetchMarkets();
  }, [apiKey, apiUrl, getAccessToken]);

  return { availableStores, isLoading, error };
};

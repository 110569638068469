import { Virtualizer, useVirtualizer } from "@tanstack/react-virtual";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Checkbox } from "../Checkbox/Checkbox";
import CollectionProductListCard from "../Collections/CollectionProductListCard/CollectionProductListCard";
import {
  DELETE_TEXT,
  MOVE_TO_BEGINNING_TEXT,
  MOVE_TO_END_TEXT,
} from "../SortableComponents/pairText";
import { theme } from "../designSystemVariables";
import { Product } from "../types";
interface ListViewProps {
  displayedProducts: Product[];
  // Products that has already be added to a collection
  addedProducts: string[];
  onAddCB: (productId: string, checked: boolean) => void;
  onScrollAtBottom?: () => void;
  // Products that you MIGHT add to the collection, because you checked them, but haven't clicked on "Add products"
  checkedProducts: string[];
}

// Taken by inspecting the DOM
const estimatedSize = 70;

const ListView = (props: ListViewProps) => {
  const { onScrollAtBottom, displayedProducts } = props;
  const parentRef = React.useRef<HTMLDivElement>(null);

  // Create a callback "onChange"
  const onChange = React.useCallback(
    (virtualizer: Virtualizer<HTMLDivElement, Element>) => {
      // check if bottom of list is reached
      const [lastItem] = [...virtualizer.getVirtualItems()].reverse();

      if (!lastItem) {
        return;
      }

      if (lastItem.index >= displayedProducts.length - 1) {
        onScrollAtBottom?.();
      }
    },
    [displayedProducts.length, onScrollAtBottom]
  );

  const rowVirtualizer = useVirtualizer({
    count: props.displayedProducts.length,
    overscan: 5,
    getScrollElement: () => parentRef.current,
    estimateSize: () => estimatedSize,
    onChange,
  });
  return (
    <div
      ref={parentRef}
      style={{
        overflowY: "scroll",
        overflowX: "hidden",
        padding: "0 10px",
        flexGrow: 1,
      }}
    >
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          position: "relative",
          width: "100%",
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualItem) => {
          const item = props.displayedProducts[virtualItem.index];

          const isChecked = props.checkedProducts.includes(
            item.main_product_id
          );
          const isAdded = props.addedProducts.includes(item.main_product_id);

          return (
            <div
              data-index={virtualItem.index}
              key={virtualItem.key}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: `${virtualItem.size}px`,
                transform: `translateY(${virtualItem.start}px)`,
              }}
            >
              <ListViewItemComponent
                item={{
                  ...item,
                  index: virtualItem.index,
                }}
                key={item.main_product_id}
                isAdded={isAdded}
                onAddCB={props.onAddCB}
                isChecked={isChecked}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface ListViewItemProps {
  item: Product & { index: number };
  onAddCB: ListViewProps["onAddCB"];
  isAdded: boolean;
  isChecked: boolean;
}

const ListViewItemComponent = (props: ListViewItemProps) => {
  const checked = props.isAdded || props.isChecked;

  return (
    <Row
      key={props.item.main_product_id}
      className="border-top border-bottom"
      style={{
        borderColor: theme.border.subtle.default,
        flexWrap: "nowrap",
        padding: "0.25rem 10px",
      }}
      onClick={() => {
        props.onAddCB(props.item.main_product_id, checked);
      }}
    >
      <Col md={"auto"} className="d-flex align-items-center px-0">
        <label
          style={{
            cursor: "pointer",
          }}
        >
          <Checkbox
            checked={checked}
            onChange={(checked) =>
              props.onAddCB(props.item.main_product_id, checked)
            }
            disabled={props.isAdded}
          />
        </label>
      </Col>
      <Col
        style={{
          paddingLeft: "8px",
        }}
      >
        <CollectionProductListCard
          moveToBeginningText={MOVE_TO_BEGINNING_TEXT}
          moveToEndText={MOVE_TO_END_TEXT}
          deleteText={DELETE_TEXT}
          {...props.item}
          disableDropdown={true}
          disableIndex={true}
          isInSelectMode={false}
        />
      </Col>
    </Row>
  );
};

export default React.memo(ListView);

import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import FilterCompare from "src/components/filters/FilterCompare";
/**
 * Components
 */
import FilterPeriod from "src/components/filters/FilterPeriod";
import MarketSelector from "src/components/filters/MarketSelector";
import SplitBySurface from "src/components/filters/SplitBySurface";
import { SectionFiltersProps } from "src/types/components";

const SectionFilters = (props: SectionFiltersProps) => {
  const {
    period,
    compare,
    splitBySurface,
    startTimestamp,
    setSplitBySurfaceCB,
    setPeriodCB,
    setCompareCB,
    setMarketCB,
    market,
    availableMarkets,
  } = props;

  return (
    <div className="row depict--filterBar">
      <div className="col">
        <form className="row mb-3 gy-2 gx-3 align-items-flex-start">
          <div className="col-sm-auto">
            <MarketSelector
              uppercase={true}
              availableMarkets={availableMarkets}
              setMarketCB={setMarketCB}
              currentMarket={market}
            />
          </div>
          <div className="col-sm-auto">
            <FilterPeriod
              period={period}
              startTimestamp={startTimestamp}
              setPeriodCB={setPeriodCB}
            />
          </div>

          <div className="col-sm-auto">
            <FilterCompare
              period={period}
              compare={compare}
              startTimestamp={startTimestamp}
              setCompareCB={setCompareCB}
            />
          </div>
          <div className="col-sm-auto">
            <SplitBySurface
              compare={compare}
              splitBySurface={splitBySurface}
              setSplitBySurfaceCB={setSplitBySurfaceCB}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default React.memo(SectionFilters);

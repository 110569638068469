import { css } from "@emotion/react";
import {
  Eye,
  EyeClosed,
  PushPin,
  PushPinSlash,
  Sparkle,
} from "@phosphor-icons/react";
import { FC, PropsWithChildren, useMemo } from "react";
import { theme } from "../../designSystemVariables";
import { HoverableIcon } from "../HoverableIcon";
import {
  CategoryProductListCardProps,
  HoverableContainer,
} from "./CategoryProductListCard";

const IconOuterContainer: FC<PropsWithChildren> = ({ children }) => (
  <div
    css={css`
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 8px;
      transition: gap 0.2s;

      ${HoverableContainer}:hover & {
        gap: 8px;
      }
    `}
  >
    {children}
  </div>
);

const CurationCornerIcon: FC<{ visible?: boolean } & PropsWithChildren> = ({
  visible,
  children,
}) => (
  <div
    css={css`
      ${!visible &&
      `
        width: 0;
        transition: width 0.2s;
        overflow: hidden;

        ${HoverableContainer}:hover & {
          width: 20px;
        }
      `}
    `}
  >
    {children}
  </div>
);

const FilledPinIcon = (
  <PushPin weight="fill" color={theme.textIcon.base.default} />
);
const buildFilledAiSortedIcon = (color: string) => (
  <Sparkle weight="fill" color={color} />
);

export function CurationCorner(props: CategoryProductListCardProps) {
  const pinned = props.pinningOptions?.pinned;
  const hidden = props.hidingOptions?.hidden;
  const aiSorted = !pinned && !hidden;

  const pinningIcon = useMemo(() => {
    const onClick = pinned
      ? props.actionCBs.onAiSortedClick
      : props.actionCBs.onPinToTop;

    return (
      <HoverableIcon
        iconWhenHovered={
          pinned ? (
            <PushPinSlash color={theme.textIcon.base.default} />
          ) : (
            FilledPinIcon
          )
        }
        iconWhenNotHovered={
          pinned ? (
            FilledPinIcon
          ) : (
            <PushPin color={theme.textIcon.base.default} />
          )
        }
        onClick={() => onClick?.(props.product.main_product_id)}
      />
    );
  }, [
    pinned,
    props.actionCBs.onAiSortedClick,
    props.actionCBs.onPinToTop,
    props.product.main_product_id,
  ]);

  const hidingIcon = useMemo(() => {
    const onClick = hidden
      ? props.actionCBs.onAiSortedClick
      : props.actionCBs.onHideClick;

    return (
      <HoverableIcon
        iconWhenHovered={
          hidden ? <Eye color={theme.textIcon.base.default} /> : <EyeClosed />
        }
        iconWhenNotHovered={
          hidden ? <EyeClosed /> : <Eye color={theme.textIcon.base.default} />
        }
        onClick={() => onClick?.(props.product.main_product_id)}
      />
    );
  }, [
    hidden,
    props.actionCBs.onAiSortedClick,
    props.actionCBs.onHideClick,
    props.product.main_product_id,
  ]);

  const aiSortedIcon = useMemo(() => {
    const onClick = aiSorted ? undefined : props.actionCBs.onAiSortedClick;

    return (
      <HoverableIcon
        iconWhenHovered={buildFilledAiSortedIcon(theme.textIcon.base.default)}
        iconWhenNotHovered={
          aiSorted ? (
            buildFilledAiSortedIcon(theme.textIcon.accent.default)
          ) : (
            <Sparkle color={theme.textIcon.base.default} />
          )
        }
        onClick={() => onClick?.(props.product.main_product_id)}
      />
    );
  }, [
    aiSorted,
    props.actionCBs.onAiSortedClick,
    props.product.main_product_id,
  ]);

  return (
    <div
      css={css`
        display: ${props.isInSelectMode ? "none" : "flex"};
        flex-direction: row;
        align-items: center;
        margin-left: 0.5rem;
      `}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <IconOuterContainer>
        <CurationCornerIcon visible={pinned}>{pinningIcon}</CurationCornerIcon>
        <CurationCornerIcon visible={!pinned && !hidden}>
          {aiSortedIcon}
        </CurationCornerIcon>
        <CurationCornerIcon visible={hidden}>{hidingIcon}</CurationCornerIcon>
      </IconOuterContainer>
    </div>
  );
}

import { useMemo } from "react";
import { Product } from "../types";
import {
  ProductGroups,
  ProductGroupsById,
  Products,
  ProductsById,
} from "./ProductsProvider/ProductsProvider.type";

export interface SortableProduct extends Product {
  id: string;
  index: number;
}

export function generatePairedProducts(
  groupIds: string[],
  groups: ProductGroupsById,
  products: ProductsById
): SortableProduct[][] {
  // Here, we map the groupIds into an array of arrays of SortableProduct.
  // For each groupId, we get the corresponding groups, map over their productIds, and turn them into SortableProducts.

  let counter = 0;
  return groupIds.map((groupId) => {
    const group = groups[groupId];
    return group.mainProductIds.map((mainProductId) => {
      const product = products[mainProductId];
      const sortableProduct = { ...product, id: mainProductId, index: counter };
      counter++;
      return sortableProduct;
    });
  });
}

interface UseSortablePairedProductsProps {
  products: Products;
  productGroups: ProductGroups;
  productGroupIds: string[];
}

export function useSortablePairedProducts(
  props: UseSortablePairedProductsProps
) {
  const { products, productGroups, productGroupIds } = props;

  const sortableProducts = useMemo<SortableProduct[][]>(() => {
    const pairs = generatePairedProducts(
      productGroupIds,
      productGroups.byId,
      products.byId
    );

    return pairs;
  }, [products, productGroups, productGroupIds]);

  return {
    sortableProducts,
  };
}

import { Trash } from "@phosphor-icons/react";
import React from "react";
import { theme } from "../../designSystemVariables";
import { css } from "@emotion/react";

export default function RuleCard({
  title,
  boostTypeLabel,
  unsaved,
  icon,
  onDelete,
}: {
  title: string;
  boostTypeLabel: string;
  unsaved?: boolean;
  icon?: React.JSX.Element;
  onDelete: () => void;
}) {
  return (
    <div
      className="py-1 px-2-5 rounded-3 border-1 dpt-color-border-subtle-disabled d-flex justify-content-between align-items-center"
      style={{ borderStyle: "solid" }}
    >
      <div className="d-flex flex-row align-items-center">
        {icon}
        <div className="d-flex flex-column align-items-start ms-2-5">
          <p className="fw-700 text-sm text-black mb-0">
            {title}{" "}
            <span
              className="fw-500"
              style={{
                color: theme.textIcon.subtle.default,
              }}
            >
              {`${unsaved ? " (save to apply)" : ""}`}
            </span>
          </p>
          <p className="text-xs mb-0">{boostTypeLabel}</p>
        </div>
      </div>
      <div
        className="pointer p-1"
        css={css`
          color: ${theme.textIcon.subtle.default};
          transition: color 0.2s ease-in-out;
          &:hover {
            color: ${theme.textIcon.danger.default};
          }
        `}
        onClick={onDelete}
      >
        <Trash />
      </div>
    </div>
  );
}

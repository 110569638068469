export default class Cache extends Map {
  constructor(array?: any) {
    super();
  }
  delete(key: any) {
    return super.delete(this.preProcess(key));
  }
  get(key: any) {
    return super.get(this.preProcess(key));
  }
  has(key: any) {
    return super.has(this.preProcess(key));
  }

  set(key: any, value: any) {
    return super.set(this.preProcess(key), value);
  }
  /*
   This is the method that will convert an object into a custom key.  It takes a parameter and returns it's processed key value.  If the type of parameter is primitive, it will return it unchanged.  Otherwise, the JSON.stringify will be called on the argument and the resulting value will be returned.  
  */

  preProcess(key: any) {
    if (typeof key === "object") {
      let sorted = Object.keys(key)
        .sort()
        .reduce(function (acc: any, k) {
          acc[k] = key[k];
          return acc;
        }, {});

      return JSON.stringify(sorted);
    } else {
      return key;
    }
  }
}

import { useState, useCallback } from "react";
import { monthOptions } from "src/helpers/filters/periodFilterOptions";
import { Period } from "src/types/components";

export default function useParam(): [
  parameter: Period,
  setCallback: (value: Period) => void
] {
  const [parameter, setParameter] = useState<Period>(
    monthOptions(new Date())[0].value
  );

  const setCallback = useCallback((value: Period) => {
    console.log(`[FILTERS] period is now ${JSON.stringify(value)}`);
    setParameter(value);
  }, []);

  return [parameter, setCallback];
}

import { css } from "@emotion/react";
import {
  ArrowCounterClockwise,
  ArrowElbowDownRight,
  Plus,
} from "@phosphor-icons/react";
import { FC } from "react";
import { Placeholder } from "react-bootstrap";
import { CollectionListDto, GetCollectionDto, Merchant } from "src/api/types";
import Button from "src/components/storybook/Button/Button";
import {
  AddProductsModal,
  OnSaveCB,
} from "../../../categories_v2_v3_common/AddProductsModal";
import { AddProductsButton } from "../../AddProductsButton/AddProductsButton";
import { HoverTitle } from "../../HoverTitle/HoverTitle";
import {
  CategoryBreadcrumb,
  ListingItemBase,
} from "../CategoryBreadcrumb/CategoryBreadcrumb";
import { CategoryHeaderButton } from "../CategoryHeaderButton/CategoryHeaderButton";
import { CategoryTitle } from "../CategoryTitle/CategoryTitle";

export type CategoryHeaderTopProps = {
  showPlaceholders: boolean;
  showTopPlaceholders: boolean;
  isSaving: boolean;
  canSave: boolean;
  canRevert: boolean;
  isAbleToAddProducts: boolean;
  canEditMetaData: boolean;
  showAddProductsModal: boolean;
  canAddSubCategory: boolean;
  rootCategories: ListingItemBase[];
  targetCategory: GetCollectionDto | null;
  merchant: Merchant | null;
  totalProductCount: number;
  allLocalProductIds: string[];
  subCategories: CollectionListDto[];
  rules: string[];
  title: string;
  createSubCategoryLoading: boolean;
  onBreadcrumbClick: (category: ListingItemBase) => void;
  onSubCategoryClick: (category: CollectionListDto | null) => void;
  onCreateSubCategory: (() => void) | undefined;
  onSave: () => Promise<void>;
  onRevert: () => void;
  onChangeTitle: (title: string, isValid: boolean) => void;
  onEdit: () => void;
  onOpenAddProductsModal: () => void;
  onCloseAddProductsModal: (addedProductsSinceOpen: string[]) => void;
  onSaveAddProductsModal: OnSaveCB;
  expandSubCategoriesOnHover: boolean;
};

export const CategoryHeaderTop: FC<CategoryHeaderTopProps> = ({
  showPlaceholders,
  showTopPlaceholders,
  isSaving,
  canSave,
  canRevert,
  isAbleToAddProducts,
  canEditMetaData,
  showAddProductsModal,
  canAddSubCategory,
  rootCategories,
  targetCategory,
  merchant,
  totalProductCount,
  allLocalProductIds,
  subCategories,
  rules,
  title,
  createSubCategoryLoading,
  onBreadcrumbClick,
  onSubCategoryClick,
  onCreateSubCategory,
  onSave,
  onRevert,
  onChangeTitle,
  onEdit,
  onOpenAddProductsModal,
  onCloseAddProductsModal,
  onSaveAddProductsModal,
  expandSubCategoriesOnHover,
}) => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
      `}
    >
      <div
        css={css`
          width: 100%;
          ${!showPlaceholders &&
          expandSubCategoriesOnHover &&
          `&:hover .header-buttons {
            opacity: 1;
            grid-template-rows: 1fr;
          }`}
        `}
      >
        {showTopPlaceholders && (
          <Placeholder animation="glow">
            <Placeholder
              css={css`
                display: block;
                width: 200px;
                height: 21px;
                margin-bottom: 4px;
              `}
            />
          </Placeholder>
        )}
        {!showTopPlaceholders && (
          <CategoryBreadcrumb
            rootCategories={rootCategories}
            targetCategory={targetCategory}
            onBreadcrumbClick={onBreadcrumbClick}
          />
        )}
        <CategoryTitle
          isLoading={showTopPlaceholders}
          canEditMetaData={canEditMetaData}
          title={title}
          onChangeTitle={onChangeTitle}
          onEdit={onEdit}
        />
        <div
          className="header-buttons"
          css={css`
            display: grid;
            opacity: 0;
            grid-template-rows: 0fr;
            transition: grid-template-rows 0.2s, opacity 0.2s;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
              gap: 0.25rem;
              margin-bottom: 0.5rem;
              overflow: hidden;
            `}
          >
            {subCategories.map((subCategory) => (
              <CategoryHeaderButton
                key={subCategory.collection_id}
                image={{
                  src: subCategory?.image_urls?.[0] || "",
                  alt: "image",
                }}
                label={subCategory.title}
                variant="dark"
                onClick={() => onSubCategoryClick(subCategory)}
              />
            ))}
            {!createSubCategoryLoading &&
              onCreateSubCategory &&
              canAddSubCategory && (
                <div
                  className="depict--HoverTitle-parent"
                  css={css`
                    position: relative;
                  `}
                >
                  <CategoryHeaderButton
                    icon={<Plus />}
                    onClick={onCreateSubCategory}
                  />
                  <div
                    css={css`
                      position: absolute;
                      top: 5px;
                      right: -128px;
                    `}
                  >
                    <HoverTitle
                      trianglePosition="left"
                      content={
                        <div
                          css={css`
                            display: flex;
                            align-items: center;
                            gap: 4px;
                          `}
                        >
                          <ArrowElbowDownRight size={14} />
                          <span
                            css={css`
                              font-size: 14px;
                            `}
                          >
                            Add a sublist
                          </span>
                        </div>
                      }
                    />
                  </div>
                </div>
              )}
          </div>
          {/* TODO: Enable when rules are working
          <div
            css={css`
              display: flex;
              gap: 0.5rem;
            `}
          >
            {rules.map((rule) => (
              <CategoryHeaderButton
                key={rule}
                label={rule}
                icon={<LockSimple />}
                variant="locked"
                rounded
              />
            ))}
            <CategoryHeaderButton icon={<Plus />} rounded disabled />
            </div>*/}
        </div>
      </div>
      <div>
        <div
          css={css`
            display: flex;
            gap: 0.5rem;
          `}
        >
          {isAbleToAddProducts && (
            <>
              <AddProductsButton
                onClick={onOpenAddProductsModal}
                buttonsDisabled={false}
              />
              <AddProductsModal
                show={showAddProductsModal}
                onClose={onCloseAddProductsModal}
                onSave={onSaveAddProductsModal}
                merchantId={merchant?.id!}
                maxNbProducts={Infinity}
                type="category"
                currentNbProducts={totalProductCount}
                currentProductIds={allLocalProductIds}
              />
            </>
          )}
          <Button
            text=""
            variant="outline-secondary"
            onClick={onRevert}
            disabled={!canRevert}
            icon={<ArrowCounterClockwise />}
          />
          <Button
            text={isSaving ? "Saving..." : "Save"}
            variant="outline-secondary"
            onClick={onSave}
            disabled={!canSave}
            icon={null}
          />
        </div>
      </div>
    </div>
  );
};

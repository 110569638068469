import "src/assets/scss/base.global.scss";
import "src/assets/scss/depict.global.scss";

import React from "react";
import { createRoot } from "react-dom/client";
import { IntercomProvider } from "react-use-intercom";
import App from "src/App";
import "./i18n";

//import reportWebVitals from "src/reportWebVitals";
import bootstrapScripts from "src/helpers/integrations/bootstrapScripts";

import { initializeMonitoring } from "./helpers/integrations/monitoring";
import { AnalyticsProvider } from "./helpers/integrations/segment";
import { Auth0Provider } from "@auth0/auth0-react";
import config from "./config";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { queryClient } from "src/queries/client";

initializeMonitoring();

const auth0Domain = process.env.AUTH0_DOMAIN || "depict.eu.auth0.com";
const auth0ClientId =
  process.env.AUTH0_CLIENT_ID || "v4rBBuOn19vgSroPGhrTI13AtIFUUhTb";
const segmentKey =
  process.env.SEGMENT_KEY || "TN2YttNTqKeUW1zGB3FvLdZDKbsHwZQ8";
const intercomAppID = process.env.INTERCOM_APP_ID || "sxdorqr2";

const container = document.getElementById("root");

const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      authorizationParams={{
        redirect_uri: window.location.origin + config.publicUrl,
      }}
    >
      <AnalyticsProvider writeKey={segmentKey}>
        <IntercomProvider appId={intercomAppID}>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <App />
          </QueryClientProvider>
        </IntercomProvider>
      </AnalyticsProvider>
    </Auth0Provider>
  </React.StrictMode>
);

bootstrapScripts();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log)),
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

import { BoostBuryEffect, DetailedPortalProductDto } from "src/api/types";

export interface Product {
  title: string;
  description?: string;
  image_url?: string | null;
  main_product_id: string;
  product_ids: Array<string>;
  deleted_at?: string | null;
  category_titles: Array<string>;
  color_name?: string | null;
  color_hex?: string | null;
  item_group_id?: string | null;
}

export interface DetailedProduct extends DetailedPortalProductDto {}

export type Collection = {
  collection_id: string;
  merchant_id: string;
  boost_bury_effect?: BoostBuryEffect;
  updated_at: Date | undefined | null;
  created_at: Date;
  deleted_at: Date | undefined | null;
  query_id: string | undefined | null;
  images: string[];
  title: string;
};

export type SortState<Column extends string> = {
  column: Column;
  direction: "asc" | "desc";
};

export const ChunkType = {
  Similar: "similar",
  Complementary: "complementary",
} as const;
export type ChunkType = (typeof ChunkType)[keyof typeof ChunkType];

export type FlattenedCategory = {
  name: string;
  displayName: string;
  imageUrls: string[];
  path: string[];
};

export enum SortStateSimple {
  ABC = "ABC",
  LATEST = "LATEST",
}

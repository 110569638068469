import config from "src/config";
import { useEffect } from "react";
import { hotjar } from "react-hotjar";
import { Merchant } from "src/api/types";

export default function useHotjar(
  authorizedMerchants: Merchant[] | null,
  isSuperUser: boolean,
  userName: string | undefined,
  userEmail: string | undefined
) {
  useEffect(() => {
    if (!authorizedMerchants) {
      return;
    }

    if (authorizedMerchants.length === 0) {
      return;
    }

    if (!isSuperUser && userEmail) {
      hotjar.initialize(config.hotjar.id, config.hotjar.version);
      hotjar.identify(userEmail, { userName });
    }
  }, [authorizedMerchants, isSuperUser, userEmail, userName]);
}

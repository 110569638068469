 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const PlaneCrossed = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M18.825 13.12l-6.593-5.651 4.654-5.651c0.176-0.213 0.145-0.528-0.068-0.704s-0.528-0.145-0.704 0.068l-4.135 5.020c-0.064-1.932-0.276-3.419-0.633-4.432-0.517-1.464-1.289-1.771-1.847-1.771s-1.33 0.307-1.847 1.771c-0.421 1.192-0.64 3.041-0.653 5.498l-6.826 5.851c-0.111 0.095-0.175 0.234-0.175 0.38v2c0 0.178 0.095 0.343 0.249 0.432s0.344 0.090 0.499 0.002l6.077-3.473-4.711 5.721c-0.176 0.213-0.145 0.528 0.068 0.704 0.093 0.077 0.206 0.114 0.318 0.114 0.144 0 0.287-0.062 0.386-0.182l4.319-5.244 0.216 2.164-2.182 1.336c-0.148 0.091-0.239 0.252-0.239 0.426v2c0 0.154 0.071 0.299 0.192 0.394s0.28 0.128 0.429 0.091l3.879-0.97 3.879 0.97c0.040 0.010 0.081 0.015 0.121 0.015 0.11 0 0.219-0.037 0.308-0.106 0.121-0.095 0.192-0.24 0.192-0.394v-2c0-0.174-0.091-0.336-0.239-0.426l-2.182-1.336 0.342-3.421 6.331 3.618c0.155 0.088 0.345 0.088 0.499-0.002s0.249-0.254 0.249-0.432v-2c0-0.146-0.064-0.285-0.175-0.38zM7.729 11.056c-0.151-0.078-0.33-0.074-0.477 0.010l-6.252 3.573v-0.908l6.825-5.85c0.111-0.095 0.175-0.234 0.175-0.38 0-4.798 0.808-6.5 1.5-6.5 0.687 0 1.488 1.677 1.5 6.392l-3.118 3.786c-0.042-0.050-0.094-0.092-0.153-0.122zM18 14.638l-6.252-3.572c-0.147-0.084-0.327-0.088-0.477-0.010s-0.252 0.226-0.268 0.395l-0.45 4.5c-0.019 0.191 0.073 0.376 0.236 0.476l2.211 1.354v1.079l-3.379-0.845c-0.040-0.010-0.081-0.015-0.121-0.015s-0.081 0.005-0.121 0.015l-3.379 0.845v-1.079l2.211-1.354c0.164-0.1 0.256-0.285 0.236-0.476l-0.347-3.465 3.495-4.244 6.404 5.489v0.908z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(PlaneCrossed); 
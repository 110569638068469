import { css } from "@emotion/react";
import { Sun } from "@phosphor-icons/react";

export function EmptyInbox() {
  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 64px;
      `}
    >
      <div
        css={css`
          display: flex;
          width: 260px;
          height: 284px;
          padding: 8px;
          flex-direction: column;
          align-items: center;
          gap: 12px;

          border-radius: 16px;
          border: 1px solid #e4e4e5;
          background: #fff;
          box-shadow: 0px 8px 32px 0px rgba(48, 24, 193, 0.16);
        `}
      >
        <div
          css={css`
            display: flex;
            padding: 14px 1px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex: 1 0 0;
            align-self: stretch;

            border-radius: 8px;
            background: linear-gradient(94deg, #e4e6fa 0%, #f1e7ff 100%);
          `}
        >
          <Sun width={96} height={96} color={"#000"} />
        </div>
        <div className="d-flex flex-column align-items-center">
          <h3
            css={css`
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 20px; /* 142.857% */
              margin: 0;
            `}
          >
            You rock!
          </h3>
          <p
            css={css`
              color: #868689;
              text-align: center;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px; /* 133.333% */
              margin: 0;
            `}
          >
            No new products right now
          </p>
        </div>
      </div>
    </div>
  );
}

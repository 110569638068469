import { useState, useEffect, useMemo, useCallback } from "react";
import { getRelationshipGroup } from "src/helpers/configuration";
import {
  ConfiguredProductGroup,
  Configuration,
  Relationship,
  RelationshipType,
} from "src/types/configuration";

type UseRelationshipGroup = [
  group: ConfiguredProductGroup | null,
  isEmpty: boolean | null,
  hasMultipleItems: boolean | null
];

export default function useRelationshipGroup(props: {
  configurationGroups: ConfiguredProductGroup[] | null;
  configuration: Configuration | null;
  relationship: RelationshipType;
  side: "from_group" | "to_group";
}): UseRelationshipGroup {
  const { configurationGroups, configuration, relationship, side } = props;
  const [group, setGroup] = useState<ConfiguredProductGroup | null>(null);

  const setGroupsInitialState = useCallback(
    (r: Relationship[], g: ConfiguredProductGroup[]) => {
      const result = getRelationshipGroup(relationship, side, r, g);

      if (!result) {
        return;
      }

      setGroup(result);
    },
    [relationship, side]
  );

  useEffect(() => {
    if (!configuration?.relationships || !configurationGroups) {
      return;
    }

    setGroupsInitialState(configuration.relationships, configurationGroups);
  }, [configuration, configurationGroups, setGroupsInitialState]);

  const isEmpty = useMemo(() => {
    if (!group) {
      return null;
    }

    if (!group.group_definition) {
      return null;
    }

    return group.group_definition.length > 0 ? false : true;
  }, [group]);

  const hasMultipleItems = useMemo(() => {
    if (!group) {
      return null;
    }

    if (!group.group_definition) {
      return null;
    }

    return group.group_definition.length > 1 ? true : false;
  }, [group]);

  return [group, isEmpty, hasMultipleItems];
}

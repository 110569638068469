import { useState, useEffect } from "react";

function useIsInputFocus() {
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    const handleFocus = (event: Event) => {
      if (!event.target) return;

      const target = event.target as HTMLElement;

      if (
        target.tagName === "INPUT" ||
        target.tagName === "TEXTAREA" ||
        target.isContentEditable
      ) {
        setIsFocused(true);
      }
    };

    const handleBlur = () => {
      setIsFocused(false);
    };

    document.addEventListener("focus", handleFocus, true);
    document.addEventListener("blur", handleBlur, true);

    return () => {
      document.removeEventListener("focus", handleFocus, true);
      document.removeEventListener("blur", handleBlur, true);
    };
  }, []);

  return isFocused;
}

export default useIsInputFocus;

import { useCallback } from "react";
import CategoryProductListCard from "src/components/storybook/Categories/CategoryProductListCard/CategoryProductListCard";
import { RenderCardCB } from "src/components/storybook/SortableComponents/ProductGridViewSortable/ProductGridViewSortable";
import ProductListViewSortable from "src/components/storybook/SortableComponents/ProductListViewSortable/ProductListViewSortable";
import { SortableGridViewProps } from "./SortableGridView";

export function SortableListView(
  props: Omit<SortableGridViewProps, "columns">
) {
  const { isLoading } = props;

  const isInSelectMode = props.selectedIds.size > 0;

  const renderCard = useCallback<RenderCardCB>(
    (product, index, extraStyle) => {
      return (
        <CategoryProductListCard
          key={product.id}
          pinningOptions={{
            pinningAllowed: true,
            pinned: true,
          }}
          hidingOptions={{
            hidingAllowed: false,
            hidden: false,
          }}
          product={product}
          index={index}
          disableIndex={false}
          actionCBs={{
            onPinToTop: props.onPinToBeginning,
            onPinClick: props.onPinClick,
            onHideClick: props.onHidingClick,
            onAiSortedClick: props.onAiSortedClick,
            onSelectClick: props.onSelectClick,
            onRemoveClick: props.onRemoveClick,
          }}
          showSelectCheckbox={true}
          selected={props.selectedIds.has(product.id)}
          extraStyles={extraStyle}
          highlight={false}
          isInSelectMode={isInSelectMode}
          aiSorted={props.aiSorted}
          isAutoHidden={false}
        />
      );
    },
    [
      props.onPinToBeginning,
      props.onPinClick,
      props.onHidingClick,
      props.onAiSortedClick,
      props.onSelectClick,
      props.onRemoveClick,
      props.selectedIds,
      props.aiSorted,
      isInSelectMode,
    ]
  );

  return (
    <ProductListViewSortable
      isLoading={isLoading}
      renderCard={renderCard}
      isError={false}
      onDragEnd={props.onDragEnd}
      selectedIds={props.selectedIds}
      disableDrag={props.disableDrag}
    />
  );
}

import {
  getGridContainerCss,
  getGridElementCss,
} from "src/components/storybook/ProductGridView/css";
import ProductGridCardPlaceholder from "../../../ProductGridCardPlaceholder/ProductGridCardPlaceholder";

const NB_ITEMS_PLACEHOLDER = 12;

interface ProductGridViewPlaceholderProps {
  nbItems?: number;
  columns: number;
}
export const ProductGridViewPlaceholder = (
  props: ProductGridViewPlaceholderProps
) => {
  const { nbItems = NB_ITEMS_PLACEHOLDER } = props;

  return (
    <>
      <div css={getGridContainerCss(props.columns)}>
        {Array.from({ length: nbItems }).map((_, i) => (
          <div key={i}>
            <div
              className="depict--SortableGridCard position-relative"
              css={getGridElementCss()}
            >
              <ProductGridCardPlaceholder />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ProductGridViewPlaceholder;

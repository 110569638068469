 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Hat = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M17.35 10.408l0.65-4.875v-0.033c0-0.816-0.438-1.951-2.524-2.719-1.387-0.511-3.28-0.781-5.476-0.781s-4.090 0.27-5.476 0.781c-2.086 0.768-2.524 1.903-2.524 2.719v0.033l0.65 4.875c-1.713 0.82-2.65 1.907-2.65 3.092 0 1.283 1.097 2.451 3.088 3.289 1.854 0.781 4.309 1.211 6.912 1.211s5.057-0.43 6.912-1.211c1.992-0.839 3.088-2.007 3.088-3.289 0-1.184-0.937-2.272-2.65-3.092zM16.396 9.994c-1.223 0.614-3.7 1.006-6.396 1.006s-5.173-0.392-6.396-1.006l-0.555-4.163c1.497 0.803 4.374 1.169 6.951 1.169 2.594 0 5.458-0.367 6.951-1.169l-0.555 4.163zM5.581 11.626l-1.239 1.567c-0.204-0.098-0.307-0.181-0.343-0.229l-0.243-1.82c0.515 0.189 1.132 0.351 1.824 0.482zM6.716 11.803c0.585 0.074 1.206 0.129 1.852 0.161l-1.31 1.893c-0.53-0.058-1.027-0.134-1.472-0.225-0.158-0.032-0.304-0.066-0.437-0.099l1.368-1.73zM9.76 11.999c0.080 0.001 0.16 0.001 0.24 0.001 0.524 0 1.038-0.014 1.537-0.041l-1.312 2.040c-0.075 0.001-0.15 0.001-0.225 0.001-0.543 0-1.078-0.016-1.593-0.046l1.353-1.955zM12.79 11.86c0.599-0.063 1.165-0.145 1.687-0.245l-1.285 2.188c-0.546 0.073-1.137 0.127-1.754 0.16l1.352-2.102zM15.831 11.283c0.143-0.044 0.281-0.091 0.412-0.139l-0.243 1.82c-0.078 0.103-0.465 0.366-1.514 0.609l1.345-2.29zM4.87 3.719c1.259-0.464 3.081-0.719 5.13-0.719s3.872 0.255 5.13 0.719c0.598 0.22 1.275 0.563 1.627 1.059-0.325 0.239-0.976 0.526-2.041 0.765-1.313 0.295-2.988 0.457-4.717 0.457-1.703 0-3.359-0.158-4.663-0.445-1.239-0.273-1.825-0.578-2.094-0.777 0.352-0.496 1.030-0.838 1.627-1.058zM16.523 15.868c-1.734 0.73-4.051 1.132-6.523 1.132s-4.789-0.402-6.523-1.132c-1.574-0.663-2.477-1.526-2.477-2.368 0-0.711 0.643-1.436 1.79-2.044l0.211 1.583c0.028 0.666 0.778 1.159 2.291 1.508 1.263 0.292 2.936 0.452 4.709 0.452s3.445-0.161 4.709-0.452c1.512-0.349 2.262-0.843 2.291-1.508l0.211-1.583c1.146 0.608 1.79 1.333 1.79 2.043 0 0.842-0.903 1.705-2.477 2.368z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Hat); 
import reactFastCompare from "react-fast-compare";
import React, { useEffect, useState } from "react";
import { MetricsSurfaceData } from "src/types/metrics";
import { SectionRevenueProps } from "src/types/components";
import { useTranslation } from "react-i18next";

/**
 * Helpers
 */
import filterTotalRevenue from "src/helpers/filters/totalRevenue";
import {
  relativizeMetricData,
  hasHoleInData,
  getMetricDataset,
} from "src/helpers/metrics";
import { formatGraphLeftAxis } from "src/helpers";

/**
 * Components
 */
import MetricPlot from "src/components/plots/Metric";
import AxisTooltips from "src/components/mix/AxisTooltips";
import { NotificationCircle } from "src/components/linearicons";

const SectionRevenue = (props: SectionRevenueProps) => {
  const { t } = useTranslation();
  const {
    metric,
    compareMetric,
    colors,
    market,
    compare,
    enabledSurfaces,
    splitBySurface,
  } = props;
  const [datasetForPercentage, setDatasetForPercentage] =
    useState<Array<MetricsSurfaceData> | null>(null);
  const [datasetForTotal, setDatasetForTotal] =
    useState<Array<MetricsSurfaceData> | null>(null);

  const [noRevenueTooltip, setNoRevenueTooltip] = useState<boolean>(false);
  const skipHoles = false;

  useEffect(() => {
    if (!!metric && Object.keys(metric).length === 0) {
      /**
       * Here we intercept early on the case where we get data back, but it is actually an empty object.
       */
      setDatasetForTotal([]);
      setDatasetForPercentage([]);
      return;
    }

    if (!metric || !market) {
      return;
    }

    const marketKey = market !== "EMPTY_KEY" ? market : "";

    if (!metric[marketKey]) {
      return;
    }

    const hasCompare =
      compareMetric && compareMetric[marketKey] && compare !== "none";

    const metricDataset = getMetricDataset(
      metric,
      compareMetric,
      marketKey,
      enabledSurfaces,
      splitBySurface,
      hasCompare
    );

    setDatasetForTotal(metricDataset);

    let relativizedMetric = relativizeMetricData(metric, skipHoles);
    let relativizedCompareMetric = relativizeMetricData(
      compareMetric ?? {},
      skipHoles
    );

    const percentaceMetricDataset = getMetricDataset(
      relativizedMetric,
      relativizedCompareMetric,
      marketKey,
      enabledSurfaces,
      splitBySurface,
      hasCompare
    );

    setDatasetForPercentage(percentaceMetricDataset);

    return () => {
      setDatasetForTotal(null);
      setDatasetForPercentage(null);
    };
  }, [
    metric,
    compareMetric,
    market,
    splitBySurface,
    compare,
    enabledSurfaces,
    skipHoles,
  ]);

  useEffect(() => {
    if (!datasetForPercentage) {
      // Used to have ( || skipHoles === true), add back if skipHoles becomes dynamic
      return;
    }

    for (var i = 0; i < datasetForPercentage.length; i++) {
      let dataset = datasetForPercentage[i];
      let surfaceName = Object.keys(dataset)[0];
      let hasHole = hasHoleInData(dataset[surfaceName]);
      if (hasHole) {
        setNoRevenueTooltip(true);
        return;
      }
    }

    setNoRevenueTooltip(false);

    return () => {
      setNoRevenueTooltip(false);
    };
  }, [datasetForPercentage, skipHoles]);

  return (
    <>
      <div className="row depict--sectionRevenue">
        <div className="col-lg-6 mb-2b mb-xxl-3">
          <div className="card h-100">
            <div className="card-body">
              <h3
                data-bs-toggle="tooltip"
                title={t("tooltips.graphs.revenue.title")}
              >
                Revenue through Depict
              </h3>
              <div className="chart-area">
                <AxisTooltips
                  i18nKeys={{
                    bottom: "tooltips.graphs.revenue.x",
                    left: "tooltips.graphs.revenue.y",
                  }}
                >
                  <MetricPlot
                    yTooltipLabel={props.currencyCode || ""}
                    dataset={datasetForTotal}
                    filters={[filterTotalRevenue]}
                    axisLeftFormat={formatGraphLeftAxis}
                    colors={colors}
                    options={{
                      axisLeft: {
                        tickSize: 5,
                        tickPadding: 5,
                        tickValues: 5,
                        format: (value: any) => {
                          return formatGraphLeftAxis(value);
                        },
                      },
                      axisTop: {
                        legend: props.currencyCode || "",
                        legendPosition: "end",
                        tickSize: 5,
                        tickPadding: 5,
                        tickValues: 0,
                        legendOffset: 0,
                      },
                    }}
                  />
                </AxisTooltips>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-2b mb-xxl-3">
          <div className="card h-100">
            <div className="card-body">
              {noRevenueTooltip && (
                <div
                  className="depict--NoRevenueTooltip"
                  data-bs-toggle="tooltip"
                  title={t("tooltips.graphs.revenuePercentage.noRevenue")}
                >
                  <NotificationCircle size="14" />
                </div>
              )}

              <h3
                data-bs-toggle="tooltip"
                title={t("tooltips.graphs.revenuePercentage.title")}
              >
                As % of total revenue
              </h3>
              <div className="chart-area">
                <AxisTooltips
                  i18nKeys={{
                    bottom: "tooltips.graphs.revenuePercentage.x",
                    left: "tooltips.graphs.revenuePercentage.y",
                  }}
                >
                  <MetricPlot
                    yTooltipLabel="Percentage"
                    dataset={datasetForPercentage}
                    filters={[filterTotalRevenue]}
                    colors={colors}
                    options={{
                      axisLeft: {
                        tickSize: 5,
                        tickPadding: 5,
                        tickValues: 5,
                        format: (value: any) => {
                          return `${Number(value)}%`;
                        },
                      },
                      axisTop: {
                        legend: "",
                        legendPosition: "start",
                        tickSize: 5,
                        tickPadding: 5,
                        tickValues: 0,
                        legendOffset: -5,
                      },
                      yFormat: ">-.2f",
                    }}
                  />
                </AxisTooltips>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(SectionRevenue, reactFastCompare);

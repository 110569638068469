import { Info } from "@phosphor-icons/react";
import { theme } from "../../designSystemVariables";
import Body from "../Body/Body";

interface InfoAlertProps {
  title?: string;
  message: string | JSX.Element;
}
const InfoAlert = (props: InfoAlertProps) => {
  return (
    <Body
      backgroundColor={theme.background.accent.default}
      titleColor={theme.textIcon.base.default}
      title={props.title || "Info"}
      message={props.message}
      messageColor={theme.textIcon.base.default}
      icon={<Info size={24} color={theme.textIcon.success.default} />}
    />
  );
};

export default InfoAlert;

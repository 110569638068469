import { css } from "@emotion/react";
import { PencilSimple } from "@phosphor-icons/react";
import { useCallback, useEffect, useRef } from "react";
import { theme } from "../../designSystemVariables";
import { categorySchema } from "../categories.schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { IconButton } from "../../IconButton/IconButton";
import { Placeholder } from "react-bootstrap";

type CategoryTitleProps = {
  title: string;
  canEditMetaData?: boolean;
  isLoading: boolean;
  onChangeTitle?: (title: string, valid: boolean) => void;
  onEdit?: () => void;
};

export const CategoryTitle = (props: CategoryTitleProps) => {
  const { title, onEdit, canEditMetaData, onChangeTitle } = props;
  const titleRef = useRef<HTMLHeadingElement | null>(null);

  const {
    register,
    formState: { errors },
    trigger,
    setValue,
    getValues,
  } = useForm({
    mode: "all",
    resolver: zodResolver(categorySchema),
    defaultValues: {
      title,
    },
  });

  const setTitle = useCallback(
    async (title: string) => {
      setValue("title", title, { shouldTouch: true });

      const valid = await trigger("title");

      if (onChangeTitle) {
        onChangeTitle(title, valid);
      }
    },
    [setValue, trigger, onChangeTitle]
  );

  useEffect(() => {
    if (title !== getValues().title) {
      setValue("title", title, { shouldValidate: true });

      if (titleRef.current) {
        titleRef.current.textContent = title;
      }
    }
  }, [title, getValues, setValue]);

  useEffect(() => {
    register("title");
  }, [register]);

  return (
    <div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 0.75rem;
          margin-bottom: 0.5rem;
        `}
      >
        {props.isLoading && (
          <Placeholder animation="glow">
            <Placeholder
              css={css`
                width: 250px;
                height: 40px;
              `}
            />
          </Placeholder>
        )}
        <h1
          css={css`
            font-size: 36px;
            line-height: 44px;
            font-weight: 700;
            color: ${theme.textIcon.base.default};
            margin: 0 !important;
            display: ${props.isLoading ? "none" : "block"};
          `}
          ref={titleRef}
          contentEditable={canEditMetaData}
          onInput={(e) => setTitle(e.currentTarget.textContent || "")}
        ></h1>
        {onEdit && <IconButton icon={<PencilSimple />} onClick={onEdit} />}
      </div>
      {errors.title && <p className="text-danger">{errors.title.message}</p>}
    </div>
  );
};

import { SortableProduct } from "./useSortablePairedProducts";
import { CARD_DECK_EFFECT_OFFSET } from "./ProductGridViewSortable/ProductGridSortableCard";
import React, { useMemo } from "react";
import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { css } from "@emotion/react";
import "./ProductCardDraggingStack.scss";

interface ProductCardDraggingStackProps {
  items: React.ReactNode[];
  amount: number;
  badgeRight?: number | string;
  containerRef?: React.ForwardedRef<HTMLDivElement>;
  containerStyle?: React.CSSProperties;
  containerAttributes?: {};
  containerListeners?: SyntheticListenerMap;
  isOverlayItem?: boolean;
}

export function useCreateCardDraggingExtraStyles(
  items: SortableProduct[],
  isOverlayItem: boolean,
  offsetX = CARD_DECK_EFFECT_OFFSET,
  offsetY = CARD_DECK_EFFECT_OFFSET
) {
  const extraStyles = useMemo<React.CSSProperties[]>(
    () =>
      items.map((_, i) => ({
        top: -i * offsetY,
        left: -i * offsetX,
        position: isOverlayItem ? "absolute" : "relative",
        zIndex: items.length >= 2 ? 100 - i : undefined,
        transform: isOverlayItem ? "scale(0.95)" : undefined,
      })),
    [isOverlayItem, items]
  );
  return extraStyles;
}

export function ProductCardDraggingStack({
  items,
  amount,
  badgeRight,
  containerRef,
  containerStyle,
  containerAttributes,
  containerListeners,
  isOverlayItem,
}: ProductCardDraggingStackProps) {
  const draggingAmountBadge = items.length > 1 && (
    <div
      className="depict--DraggingAmountBadge position-absolute top-0"
      style={{ right: badgeRight || 0 }}
    >
      <div className="inner d-flex align-items-center justify-content-center">
        {amount}
      </div>
    </div>
  );
  return (
    <div
      ref={containerRef}
      style={containerStyle || {}}
      {...containerAttributes}
      {...containerListeners}
      className={isOverlayItem ? "depict--OverlayContainer" : ""}
    >
      <div style={{ position: "relative" }}>
        {draggingAmountBadge}
        {items}
      </div>
    </div>
  );
}

interface DraggableCardProps {
  style?: React.CSSProperties;
  className?: string;
  attributes?: DraggableAttributes;
  listeners?: SyntheticListenerMap;
  pairsLength?: number;
  indicatorHeight?: number;
  indicatorNumberAlign?: "start" | "end" | "center";
  indicatorNumberFontSize?: string;
}

export const DragIndicatorElement = React.forwardRef<
  HTMLDivElement,
  DraggableCardProps
>(function DragIndicatorElement(props: DraggableCardProps, ref) {
  const attributes = props.attributes || {};
  const listeners = props.listeners || {};
  return (
    <div
      className={`depict--DragIndicatorElement position-relative h-100 d-flex justify-content-center align-items-center`}
      ref={ref}
      style={props.style || {}}
      {...attributes}
      {...listeners}
    >
      <div
        className="w-100 h-100 rounded d-flex align-items-center"
        style={{ minHeight: props.indicatorHeight }}
        css={css`
          height: 100%;
          background: rgba(171, 171, 171, 0.61);
          padding: 2rem;
          display: flex;
          align-items: center;
          justify-content: ${props.indicatorNumberAlign || "center"};
          font-size: ${props.indicatorNumberFontSize || "3.2em"};
        `}
      >
        {"+" + props.pairsLength}
      </div>
    </div>
  );
});

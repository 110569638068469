import { useQuery } from "@tanstack/react-query";
import { getData } from "src/api/authorizedApi";
import { BasePortalProductDto } from "src/api/types";
import useAuthorizedApi, {
  AuthorizedApi,
} from "src/helpers/hooks/app/useAuthorizedApi";
import { queryClient } from "src/queries/client";
import { QueryId, getQueryKey } from "src/queries/queries";

const fetchAllProducts = async (
  api: AuthorizedApi | null,
  merchantId: string
): Promise<BasePortalProductDto[] | undefined> => {
  const response = await api?.GET("/api/v0/catalog/products/", {
    params: {
      query: {
        merchant_id: merchantId,
      },
    },
  });

  if (!response) throw new Error("No response");

  const data = getData(response);

  return data;
};

const ONE_HOUR_MS = 1000 * 60 * 60;

const _getQueryKey = (merchantId: string | null) => {
  return getQueryKey(QueryId.GetMerchantProducts, merchantId || "");
};

export const getMerchantProducts = async (
  api: AuthorizedApi,
  merchantId: string | null
) => {
  const query = () => {
    if (merchantId) {
      return fetchAllProducts(api, merchantId);
    }

    return [];
  };

  const data = await queryClient.fetchQuery({
    queryKey: _getQueryKey(merchantId),
    queryFn: query,
    staleTime: ONE_HOUR_MS,
  });

  return data;
};

export default function useMerchantProducts(merchantId: string | null): {
  products: BasePortalProductDto[] | null;
  isLoading: boolean;
  isError: boolean;
} {
  const { api } = useAuthorizedApi();

  const query = useQuery({
    queryKey: _getQueryKey(merchantId),
    queryFn: () => {
      if (merchantId && api) {
        return fetchAllProducts(api, merchantId);
      }
    },
    enabled: !!merchantId && !!api,
    staleTime: ONE_HOUR_MS,
  });

  return {
    products: query.data || null,
    isLoading: query.isLoading,
    isError: query.isError,
  };
}

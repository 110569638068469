import { produce } from "immer";
import { Action, ActionType, State } from "./Category.reducer.types";

export const initialState: State = {
  isInitFromServer: false,
  products: {
    byId: {},
    allIds: [],
  },
  relevanceProductOrder: [],
  local: {
    title: null,
    rankingType: null,
    pinnedProducts: [],
    productsByRelevance: [],
    hiddenProducts: [],
  },
  server: {
    title: null,
    rankingType: null,
    pinnedProducts: [],
    productsByRelevance: [],
    hiddenProducts: [],
  },
  titleIsValid: true,
  categoryTotalProductCount: 0,
  categoryProductIds: [],
  localProductManagement: {
    addedProducts: [],
    removedProducts: [],
  },
  productSortMethod: undefined,
  isSaving: false,
};

function removeItemsFromArray(
  array: string[],
  itemsToRemove: string[]
): string[] {
  return array.filter((item) => !itemsToRemove.includes(item));
}

export const removeFromPinnedAndHidden = (
  state: State,
  productIds: string[]
): void => {
  state.local.pinnedProducts = removeItemsFromArray(
    state.local.pinnedProducts,
    productIds
  );
  state.local.hiddenProducts = removeItemsFromArray(
    state.local.hiddenProducts,
    productIds
  );
};

export const recomputeProductsByRelevance = (
  state: State,
  stateKey: "local" | "server"
) => {
  return state.relevanceProductOrder.filter(
    (id) =>
      !state[stateKey].pinnedProducts.includes(id) &&
      !state[stateKey].hiddenProducts.includes(id)
  );
};

export const reducer = produce((draft: State, action: Action) => {
  switch (action.type) {
    case ActionType.INIT_FROM_SERVER: {
      const {
        curatedProducts,
        pinnedProducts,
        hiddenProducts,
        totalProductCount,
        categoryProductIds,
      } = action.payload;

      draft.categoryTotalProductCount = totalProductCount;
      draft.categoryProductIds = categoryProductIds;

      // Add products to byId object
      curatedProducts.forEach((product) => {
        draft.products.byId[product.main_product_id] = product;
      });
      // Add product ids to allIds array
      draft.products.allIds = curatedProducts.map(
        (product) => product.main_product_id
      );

      draft.local.pinnedProducts = pinnedProducts;
      draft.local.hiddenProducts = hiddenProducts;

      // copy local state to server state
      draft.server = draft.local;

      draft.localProductManagement = {
        addedProducts: [],
        removedProducts: [],
      };

      draft.isInitFromServer = true;
      draft.isSaving = false;

      return;
    }

    case ActionType.SET_NOT_CURATED_PRODUCTS: {
      const { notCuratedProducts } = action.payload;

      // Add products to byId object
      notCuratedProducts.forEach((product) => {
        draft.products.byId[product.main_product_id] = product;
      });
      // Add product ids to allIds array
      draft.products.allIds = notCuratedProducts.map(
        (product) => product.main_product_id
      );
      draft.relevanceProductOrder = notCuratedProducts.map(
        (p) => p.main_product_id
      );

      draft.local.productsByRelevance = recomputeProductsByRelevance(
        draft,
        "local"
      );
      draft.server.productsByRelevance = recomputeProductsByRelevance(
        draft,
        "server"
      );

      return;
    }

    case ActionType.IS_SAVING: {
      draft.server = draft.local;
      draft.isSaving = action.payload.isSaving;
      return;
    }

    case ActionType.REVERT_CHANGES: {
      draft.local = draft.server;
      draft.localProductManagement = {
        addedProducts: [],
        removedProducts: [],
      };
      draft.titleIsValid = true;
      return;
    }

    case ActionType.PIN: {
      const { productIds } = action.payload;

      removeFromPinnedAndHidden(draft, productIds);

      draft.local.pinnedProducts.push(...productIds);

      draft.local.productsByRelevance = recomputeProductsByRelevance(
        draft,
        "local"
      );
      return;
    }

    case ActionType.HIDE_PRODUCTS: {
      const { productIds } = action.payload;

      removeFromPinnedAndHidden(draft, productIds);

      draft.local.hiddenProducts.push(...productIds);

      draft.local.productsByRelevance = recomputeProductsByRelevance(
        draft,
        "local"
      );
      return;
    }

    case ActionType.AI_SORTED: {
      const { productIds } = action.payload;

      removeFromPinnedAndHidden(draft, productIds);

      draft.local.productsByRelevance = recomputeProductsByRelevance(
        draft,
        "local"
      );
      return;
    }

    case ActionType.PIN_TO_BEGINNING:
      const { productIds } = action.payload;

      removeFromPinnedAndHidden(draft, productIds);

      draft.local.pinnedProducts.unshift(...productIds);

      draft.local.productsByRelevance = recomputeProductsByRelevance(
        draft,
        "local"
      );
      return;

    case ActionType.REORDER_PINNED_PRODUCTS: {
      const { productIds, newIndex } = action.payload;

      const pinnedProductsWithoutIds = draft.local.pinnedProducts.filter(
        (p) => !productIds.includes(p)
      );
      const pinnedProductsWithIdsInsertedAtIndex = pinnedProductsWithoutIds
        .slice(0, newIndex)
        .concat(productIds)
        .concat(pinnedProductsWithoutIds.slice(newIndex));
      draft.local.pinnedProducts = pinnedProductsWithIdsInsertedAtIndex;

      return;
    }

    case ActionType.ADD_PRODUCTS: {
      const { productIds } = action.payload;

      // Add products localProductManagement.addedProducts. Make sure to not add duplicates
      draft.localProductManagement.addedProducts = [
        ...new Set([
          ...draft.localProductManagement.addedProducts,
          ...productIds,
        ]),
      ];

      // remove products from localProductManagement.removedProducts
      draft.localProductManagement.removedProducts =
        draft.localProductManagement.removedProducts.filter(
          (id) => !productIds.includes(id)
        );

      return;
    }
    case ActionType.REMOVE_PRODUCTS: {
      const { productIds } = action.payload;

      const productsThatWereOnServer = productIds.filter(
        (id) => !draft.localProductManagement.addedProducts.includes(id)
      );

      draft.localProductManagement.addedProducts =
        draft.localProductManagement.addedProducts.filter(
          (id) => !productIds.includes(id)
        );

      draft.localProductManagement.removedProducts = [
        ...new Set([
          ...draft.localProductManagement.removedProducts,
          ...productsThatWereOnServer,
        ]),
      ];

      // remove from pinnedProducts, hiddenProducts, productsByRelevance
      draft.local.pinnedProducts = draft.local.pinnedProducts.filter(
        (id) => !productIds.includes(id)
      );
      draft.local.hiddenProducts = draft.local.hiddenProducts.filter(
        (id) => !productIds.includes(id)
      );
      draft.local.productsByRelevance = draft.local.productsByRelevance.filter(
        (id) => !productIds.includes(id)
      );
      return;
    }
    case ActionType.SET_TITLE: {
      // Only reset and update the local title if the new server title differs from our server title in store
      if (draft.server.title !== action.payload.title) {
        draft.local.title = action.payload.title;
      }

      draft.server.title = action.payload.title;

      return;
    }
    case ActionType.SET_LOCAL_TITLE: {
      draft.local.title = action.payload.title;
      draft.titleIsValid = action.payload.isValid;
      return;
    }
    case ActionType.ADD_PRODUCTS_TO_DICT: {
      const { products } = action.payload;

      // Add any new product to byId object
      products.forEach((product) => {
        if (!draft.products.byId[product.main_product_id]) {
          draft.products.byId[product.main_product_id] = product;
        }
      });
      // Add product ids to allIds array, but remove duplicates
      draft.products.allIds = [
        ...new Set([
          ...draft.products.allIds,
          ...products.map((product) => product.main_product_id),
        ]),
      ];

      return;
    }
    case ActionType.SET_PRODUCT_SORT_METHOD:
      draft.productSortMethod = action.payload.productSortMethod;
      return;
    case ActionType.SET_RANKING_TYPE:
      draft.server.rankingType = action.payload.rankingType;

      if (draft.local.rankingType === null) {
        draft.local.rankingType = action.payload.rankingType;
      }
      return;
    case ActionType.SET_LOCAL_RANKING_TYPE:
      draft.local.rankingType = action.payload.rankingType;
      return;

    default:
      return;
  }
});

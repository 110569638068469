import React, { useCallback } from "react";
import { ListItem } from "src/types/components";
import SortableItem from "src/components/dragndrop/SortableItem";
import reactFastCompare from "react-fast-compare";
import ConditionalWrapper from "../mix/ConditionalWrapper";

const Abstraction = (props: {
  id: string;
  index: number;
  item: ListItem;
  className?: string;
  isSortable?: boolean;
  imageLazyLoad?: boolean;
  getIndex: (id: string) => number;
  itemOnClickCB?: Function | undefined;
  itemRightIconOnClickCB?: Function | undefined;
  itemTile: JSX.Element;
}) => {
  const { id, item, index, getIndex, itemTile, isSortable } = props;

  const wrapperStyleFn = useCallback(() => {
    return {};
  }, []);

  const wrapper = useCallback(
    (children: React.ReactNode) => {
      return (
        <SortableItem
          id={item.id as string}
          index={index}
          handle={false}
          wrapperStyle={wrapperStyleFn}
          containerId={id}
          getIndex={getIndex}
        >
          {children}
        </SortableItem>
      );
    },
    [getIndex, id, index, item.id, wrapperStyleFn]
  );
  return (
    <ConditionalWrapper
      key={item.id}
      condition={isSortable ?? false}
      wrapper={wrapper}
    >
      {itemTile}
    </ConditionalWrapper>
  );
};

export default React.memo(Abstraction, reactFastCompare);

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Beaker = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M16.534 15.745l-4.534-8.371v-4.773c0.603-0.246 1-0.609 1-1.101 0-1.036-1.758-1.5-3.5-1.5s-3.5 0.464-3.5 1.5c0 0.492 0.397 0.855 1 1.101v4.773l-4.534 8.371c-0.594 1.097-0.651 2.154-0.16 2.978s1.448 1.277 2.695 1.277h9c1.247 0 2.204-0.454 2.695-1.277s0.434-1.881-0.16-2.978zM9.5 1c1.382 0 2.22 0.315 2.455 0.5-0.235 0.185-1.073 0.5-2.455 0.5s-2.22-0.315-2.455-0.5c0.235-0.185 1.073-0.5 2.455-0.5zM9.5 3c0.514 0 1.029-0.040 1.5-0.124v4.622c-0 0.018 0.001 0.035 0.003 0.053 0 0.003 0.001 0.005 0.001 0.008 0.002 0.013 0.003 0.027 0.006 0.040 0.002 0.008 0.004 0.016 0.006 0.024s0.004 0.015 0.006 0.023 0.009 0.027 0.015 0.041c0.001 0.002 0.001 0.004 0.002 0.006 0.007 0.016 0.015 0.032 0.023 0.048l3.39 6.259c-0.726-0.013-1.222-0.321-1.873-0.879-0.418-0.358-0.962-0.804-1.58-1.186-0.035-1.073-0.917-1.935-1.998-1.935-0.758 0-1.419 0.424-1.758 1.048-0.43 0.054-0.851 0.155-1.257 0.3l1.954-3.608c0.008-0.015 0.016-0.031 0.023-0.047 0.001-0.003 0.002-0.005 0.003-0.008 0.005-0.013 0.010-0.025 0.014-0.038 0.002-0.008 0.004-0.016 0.006-0.024s0.004-0.015 0.006-0.023 0.005-0.028 0.006-0.042c0-0.002 0.001-0.004 0.001-0.007 0.002-0.018 0.003-0.035 0.003-0.053v-4.622c0.471 0.083 0.986 0.124 1.5 0.124zM9 11c0.551 0 1 0.449 1 1s-0.449 1-1 1-1-0.449-1-1 0.449-1 1-1zM15.836 18.211c-0.303 0.509-0.955 0.789-1.836 0.789h-9c-0.881 0-1.533-0.28-1.836-0.789s-0.239-1.216 0.181-1.99l1.802-3.327c0.56-0.39 1.191-0.659 1.856-0.794 0.052 1.056 0.928 1.9 1.997 1.9 0.753 0 1.41-0.418 1.751-1.034 0.449 0.303 0.853 0.636 1.177 0.914 0.807 0.692 1.519 1.12 2.572 1.12 0.16 0 0.319-0.011 0.476-0.033l0.679 1.254c0.419 0.774 0.484 1.481 0.181 1.99z" fill={color}></path>
<path d="M6.5 15c-0.827 0-1.5 0.673-1.5 1.5s0.673 1.5 1.5 1.5 1.5-0.673 1.5-1.5-0.673-1.5-1.5-1.5zM6.5 17c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5 0.5 0.224 0.5 0.5-0.224 0.5-0.5 0.5z" fill={color}></path>
<path d="M9.5 15c-0.132 0-0.26 0.053-0.353 0.147s-0.147 0.222-0.147 0.353 0.053 0.261 0.147 0.353c0.093 0.093 0.222 0.147 0.353 0.147s0.261-0.053 0.353-0.147c0.093-0.093 0.147-0.222 0.147-0.353s-0.053-0.261-0.147-0.353c-0.093-0.093-0.222-0.147-0.353-0.147z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Beaker); 
import React, { useState } from "react";
import { Button as BootstrapButton } from "react-bootstrap";
import "./LoadingButton.scss";

export interface LoadingButtonProps {
  expectedDuration: number; //in seconds
  minimumDuration?: number; //in seconds
  text: string;
  textWhileLoading?: string;
  onClick: () => Promise<void>;
  icon?: React.ReactNode;
  loadingIcon?: React.ReactNode;
  disabled?: boolean;
}

//LoadingButton with a bottom progress bar
export const LoadingButton = (props: LoadingButtonProps) => {
  const minDuration = props.minimumDuration || 0;
  const [loading, setLoading] = useState(false);
  const [progressBarStyle, setProgressBarStyle] = useState({
    width: "0%",
    transition: `none`,
  });

  const onButtonClick = () => {
    setLoading(true);

    setProgressBarStyle({
      transition: `width ${props.expectedDuration}s ease-in-out`,
      width: "95%",
    });

    const minDurationPromise = new Promise((res) =>
      setTimeout(res, minDuration * 1000)
    );

    Promise.all([props.onClick(), minDurationPromise]).then(() => {
      setLoading(false);
      setProgressBarStyle({
        width: "100%",
        transition: `width ${props.expectedDuration}s ease-in-out`,
      });
      setTimeout(() => {
        setProgressBarStyle({ width: "0%", transition: `none` });
      }, 200);
    });
  };

  return (
    <div
      className={`loadingButton--root${
        loading ? " loadingButton--loading" : ""
      }`}
    >
      <BootstrapButton
        disabled={loading || props.disabled}
        onClick={onButtonClick}
      >
        {props.loadingIcon && loading ? props.loadingIcon : props.icon}
        {props.textWhileLoading && loading
          ? props.textWhileLoading
          : props.text}
      </BootstrapButton>
      <div className="loadingButton--progress-bar" style={progressBarStyle} />
    </div>
  );
};

export default LoadingButton;

import { Table } from "react-bootstrap";
import ContentItemRow from "./ContentItemRow";
import { MerchantContent } from "src/api/types";
import { css } from "@emotion/react";
import { theme } from "../designSystemVariables";

export type ContentTableProps = {
  items: MerchantContent[];
  localeId: string;
  updateContent: (content: MerchantContent) => void;
  deleteContent: (contentId: string) => void;
  isAddingContent: boolean;
  setIsAddingContent: (isAddingContent: boolean) => void;
};

function ContentTable(props: ContentTableProps) {
  return (
    <div className="me-2">
      <Table
        className="m-0"
        css={css`
          height: 1px;
        `}
      >
        <thead
          css={css`
            color: ${theme.textIcon.subtle.default};
            position: relative;
            ::before {
              background: ${theme.background.subtle.default};
              border-top: 1px solid ${theme.border.subtle.disabled};
              content: "";
              position: absolute;
              display: block;
              top: 0;
              left: -20px;
              right: -20px;
              bottom: 0;
              width: calc(100% + 40px);
            }
          `}
        >
          <tr>
            <th className="flex-column position-relative border-0">
              <div className="d-flex ps-0 align-items-center justify-content-between w-100">
                Title & URL
              </div>
            </th>
            <th className="border-0"></th>
            <th className="flex-column position-relative border-0"></th>
            <th className="flex-column position-relative border-0">
              <div className="d-flex ps-0 align-items-center justify-content-between w-100">
                Keywords
              </div>
            </th>
            <th className="flex-column position-relative border-0">
              <div className="d-flex ps-0 align-items-center justify-content-between w-100">
                Actions
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {props.items.length === 0 && !props.isAddingContent && (
            <tr>
              <td className="py-4 text-start w-100" colSpan={5}>
                You haven't added any content yet, go ahead and add some!
              </td>
            </tr>
          )}
          {props.isAddingContent && (
            <ContentItemRow
              item={{
                locale_id: props.localeId,
                title: "",
                uri: "",
                keywords: [],
              }}
              onUpdate={(content: MerchantContent) =>
                props.updateContent(content)
              }
              onDelete={(content: MerchantContent) => {
                if (!content.content_id) {
                  return;
                }
                props.deleteContent(content.content_id);
              }}
              isNewContent={true}
              setIsAddingContent={props.setIsAddingContent}
            />
          )}
          {props.items.map((item, index) => (
            <ContentItemRow
              key={`content-item-row-${props.localeId}-${index}`}
              item={item}
              onUpdate={(content: MerchantContent) =>
                props.updateContent(content)
              }
              onDelete={(content: MerchantContent) => {
                if (!content.content_id) {
                  return;
                }
                props.deleteContent(content.content_id);
              }}
              isNewContent={false}
              setIsAddingContent={props.setIsAddingContent}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default ContentTable;

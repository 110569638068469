import React from "react";
import { Cross } from "src/components/linearicons";

const SidebarOverlayRight = (props: {
  toggleRightSidebarOverlayCB: () => void;
  children: React.ReactNode;
}) => {
  const { toggleRightSidebarOverlayCB, children } = props;

  const closeRightNav = () => {
    toggleRightSidebarOverlayCB();
  };

  return (
    <div className="depict--SidebarOverlayRight">
      <button
        type="button"
        className="btn btn-link btn-sm text-white depict--SidebarOverlayRight__btnClose"
        onClick={closeRightNav}
      >
        <Cross />
      </button>

      {children}
    </div>
  );
};

export default React.memo(SidebarOverlayRight);

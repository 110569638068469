import { useCallback, useState } from "react";
import { BasePortalProductDto } from "src/api/types";
import useMerchantProducts, {
  getMerchantProducts,
} from "src/helpers/hooks/collections/useMerchantProducts";
import useProductCatalog, {
  DEFAULT_SORT,
} from "../../helpers/catalog/useProductCatalog";
import useAuthorizedApi from "../../helpers/hooks/app/useAuthorizedApi";
import useProductFilters from "../../helpers/hooks/collections/useProductFilters";
import { useTrackEvent } from "../../helpers/hooks/mix/useSegment";
import { AddProductsModal as AddProductsModalBase } from "../storybook/AddProductsModal/AddProductsModal";
import { SelectedFilter } from "../storybook/AddProductsModal/Filters";

export type OnSaveCB = (data: {
  addedItems: BasePortalProductDto[];
}) => Promise<void>;

interface AddProductsModalProps {
  merchantId: string;
  show: boolean;
  onClose: (addedProductsSinceOpen: string[]) => void;
  onSave: OnSaveCB;
  maxNbProducts: number;
  type: "collection" | "category";
  currentNbProducts: number;
  currentProductIds: string[];
}

export const AddProductsModal = (props: AddProductsModalProps) => {
  const { api } = useAuthorizedApi();
  const trackWantedToAddTooManyProducts = useTrackEvent(
    "wanted_to_add_too_many_products"
  );

  const filterQuery = useProductFilters();

  const getMerchantCatalogProducts = useCallback(async () => {
    if (!api) return [];

    const data = await getMerchantProducts(api, props.merchantId);

    return (
      data?.map((d, index) => ({
        ...d,
        index,
      })) || []
    );
  }, [api, props.merchantId]);

  // To warm up the cache
  useMerchantProducts(props.merchantId);

  const onSaveCB = async ({ addedIds }: { addedIds: string[] }) => {
    const products = await getMerchantCatalogProducts();
    const addedProducts = products.filter((p) =>
      addedIds.includes(p.main_product_id)
    );
    await props.onSave({
      addedItems: addedProducts,
    });
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([]);

  const { filteredProductsQuery } = useProductCatalog({
    merchantId: props.merchantId,
    currentView: "all",
    selectedSort: DEFAULT_SORT,
    searchTerm,
    selectedFilters,
  });

  const onNewSearchOrFilter = useCallback(
    ({
      searchText,
      selectedFilters,
    }: {
      searchText: string;
      selectedFilters: SelectedFilter[];
    }) => {
      setSearchTerm(searchText);
      setSelectedFilters(selectedFilters);
    },
    []
  );

  if (!api) return null;

  return (
    <AddProductsModalBase
      searchTerm={searchTerm}
      selectedFilters={selectedFilters}
      currentNbProducts={props.currentNbProducts}
      productsQuery={filteredProductsQuery}
      availableFiltersQuery={filterQuery}
      onSave={onSaveCB}
      onNewSearchOrFilter={onNewSearchOrFilter}
      show={props.show}
      onClose={props.onClose}
      maxNbProducts={props.maxNbProducts}
      currentProductIds={props.currentProductIds}
      onTooManyProductsSelected={({
        futureNbProducts,
        maxNbProducts,
        nbProductsTryingToAdd,
      }) => {
        trackWantedToAddTooManyProducts({
          futureNbProducts,
          maxNbProducts,
          nbProductsTryingToAdd,
        });
      }}
      type={props.type}
    />
  );
};

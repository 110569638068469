 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Bicycle = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M16.5 13c-0.625 0-1.212 0.165-1.72 0.453l-1.564-2.026 0.612-1.427h0.672c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-0.997c-0.002 0-0.004 0-0.006 0h-0.997c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h0.239l-0.429 1h-5.425l-0.243-0.315 0.476-1.068c0.171-0.383 0.528-0.617 0.666-0.617h0.715c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-0.715c-0.593 0-1.272 0.52-1.579 1.209l-0.598 1.34c-0.001 0.001-0.001 0.003-0.002 0.004l-1.151 2.579c-0.304-0.086-0.624-0.132-0.955-0.132-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5c0-1.244-0.652-2.338-1.632-2.959l0.812-1.82 3.057 3.96c-0.154 0.236-0.245 0.517-0.245 0.818 0 0.827 0.675 1.5 1.504 1.5 0.654 0 1.211-0.418 1.418-1h1.122c0.243 1.694 1.704 3 3.464 3 1.93 0 3.5-1.57 3.5-3.5s-1.57-3.5-3.5-3.5zM6 16.5c0 1.378-1.122 2.5-2.5 2.5s-2.5-1.122-2.5-2.5 1.122-2.5 2.5-2.5c0.186 0 0.367 0.021 0.541 0.060l-0.998 2.237c-0.113 0.252 0.001 0.548 0.253 0.66 0.066 0.030 0.135 0.044 0.203 0.044 0.191 0 0.374-0.11 0.457-0.296l0.997-2.235c0.633 0.454 1.046 1.195 1.046 2.032zM11.513 15.396l1.251-2.917 1.224 1.586c-0.502 0.517-0.845 1.188-0.953 1.935h-1.122c-0.083-0.233-0.222-0.439-0.4-0.604zM14.609 14.868l0.874 1.132h-1.432c0.087-0.427 0.284-0.814 0.558-1.132zM11.882 12l-1.288 3.003c-0.032-0.002-0.065-0.003-0.098-0.003-0.162 0-0.319 0.026-0.465 0.074l-2.373-3.074h4.224zM10.496 17c-0.278 0-0.504-0.224-0.504-0.5s0.226-0.5 0.504-0.5 0.504 0.224 0.504 0.5-0.226 0.5-0.504 0.5zM16.5 19c-1.207 0-2.217-0.86-2.45-2h2.45c0.191 0 0.365-0.108 0.449-0.279s0.063-0.375-0.053-0.526l-1.496-1.938c0.332-0.164 0.706-0.256 1.101-0.256 1.378 0 2.5 1.122 2.5 2.5s-1.122 2.5-2.5 2.5z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Bicycle); 
import { useEffect, useContext } from "react";
import { OpenDropdownContext } from "./WhichDropdownOpenContext";

export function useWhichDropdownOpen(id: string | number) {
  const { openDropdownId, setOpenDropdownId } = useContext(OpenDropdownContext);

  const registerOpenDropdown = (): void => {
    setOpenDropdownId(id);
  };

  const isOtherDropdownOpen = openDropdownId !== null && openDropdownId !== id;

  useEffect(() => {
    return () => {
      if (openDropdownId === id) {
        setOpenDropdownId(null);
      }
    };
  }, [openDropdownId, id, setOpenDropdownId]);

  return {
    registerOpenDropdown,
    isOtherDropdownOpen,
  };
}

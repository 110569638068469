import { createContext } from "react";
import { RouteConfig, ROUTES } from "src/router/Routes";

export interface PathContextValue {
  path: ROUTES;
  config: RouteConfig;
}

/*
  This context is used to retrieve the current path from any component.
  
  It's sometimes useful to know what the original "dynamic segment" was when declaring the route. 

  Here is an example where it's useful:
    If you have a route like this:
      "/catjam/collections/collection-1"
    You can use the context to retrieve the original path, which is:
      "/:merchantId/collections/:collectionId"
    This is useful when you want to find the where we should be redirected when the merchant changes (retrieved via routeConfigs.pathOnMerchantChange)
*/
export const PathContext = createContext<null | PathContextValue>(null);

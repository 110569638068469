import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useIntercom } from "react-use-intercom";
import MainContent from "./MainContent";
import SplashScreen from "./components/mix/SplashScreen";
import { TopNavProvider } from "./components/navs/TopNavProvider";
import AlertsWrapper from "./components/storybook/Alert/AlertsWrapper";
import useDepictUser from "./helpers/hooks/useDepictUser";
import { UserContext } from "./helpers/hooks/useUser";
import { monitoringEnabled } from "./helpers/integrations/monitoring";

const App = () => {
  const { user: auth0User } = useAuth0();
  const { boot } = useIntercom();
  const [user] = useDepictUser(auth0User);

  if (monitoringEnabled) {
    boot();
  }

  return (
    <UserContext.Provider value={{ user }}>
      <TopNavProvider>
        <MainContent />
        <AlertsWrapper />
      </TopNavProvider>
    </UserContext.Provider>
  );
};

export default withAuthenticationRequired(App, {
  onRedirecting: () => <SplashScreen />,
});

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useEffect, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { Link } from "react-router-dom";
import { CollectionType } from "src/api/types";
import { ListingItem } from "./types";

export const HoverableDiv = styled.div``;

export enum ItemTypes {
  LISTING_ITEM = "listingItem",
}

function allChildrenIds(listingItem: ListingItem): string[] {
  if (!listingItem.children) {
    return [];
  }
  const childrenIds: string[] = [];
  listingItem.children.forEach((child) => {
    childrenIds.push(child.id, ...allChildrenIds(child));
  });
  return childrenIds;
}

export default function ListingTreeItem({
  currentListingItemId,
  listingItem,
  isVisible,
  isExpanded,
  onSetExpand,
  sortFunction,
  inSelectMode,
  isSelected,
  onSelectChange,
  selectIsIndeterminate,
  onDrop,
  editableListingTypes,
}: {
  currentListingItemId?: string;
  listingItem: ListingItem;
  isVisible: (listingId: string) => boolean;
  isExpanded: (listingId: string) => boolean;
  onSetExpand: (value: boolean, listingId: string) => void;
  sortFunction: (a: ListingItem, b: ListingItem) => number;
  inSelectMode?: boolean;
  isSelected: (listingId: string) => boolean;
  onSelectChange: (checked: boolean, listingId: string) => void;
  selectIsIndeterminate?: (listingId: string) => boolean;
  onDrop: (listingItemId: string, parentId: string) => void;
  editableListingTypes: CollectionType[];
}) {
  const checkboxRef = useRef<HTMLInputElement>(null);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.LISTING_ITEM,
    canDrag: editableListingTypes.includes(listingItem.type),
    item: { listingItem },
    options: {
      dropEffect: "copy",
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [{ isOver, canDrop, somethingIsDragging }, drop] = useDrop(() => ({
    accept: ItemTypes.LISTING_ITEM,
    canDrop: (item: { listingItem: ListingItem }) => {
      return (
        item.listingItem.id !== listingItem.id &&
        item.listingItem.type === listingItem.type &&
        editableListingTypes.includes(listingItem.type) &&
        ![item.listingItem.id, ...allChildrenIds(item.listingItem)].includes(
          listingItem.id
        )
      );
    },
    drop: (item: { listingItem: ListingItem }, monitor) => {
      if (!monitor.didDrop()) {
        onDrop(item.listingItem.id, listingItem.id);
        onSetExpand(true, listingItem.id);
      }
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver({
        shallow: true,
      }),
      canDrop: monitor.canDrop(),
      somethingIsDragging: monitor.getItemType() === ItemTypes.LISTING_ITEM,
    }),
  }));

  useEffect(() => {
    if (inSelectMode && checkboxRef.current) {
      checkboxRef.current.indeterminate = selectIsIndeterminate
        ? selectIsIndeterminate(listingItem.id)
        : false;
    }
  }, [inSelectMode, listingItem.id, selectIsIndeterminate]);

  const isSelectedInTheTree = currentListingItemId === listingItem.id;

  return (
    <div
      id={listingItem.id}
      ref={drop}
      css={css`
        opacity: ${isDragging || (somethingIsDragging && !canDrop) ? 0.5 : 1};
        border-radius: 4px;
        border: 1px solid ${isOver && canDrop ? "#3018C1" : "transparent"};
      `}
    >
      <HoverableDiv
        className="p-2 text-gray-800 rounded mb-2"
        ref={drag}
        css={css`
          :hover {
            background-color: #e4e4e5;
          }
          background-color: ${isSelectedInTheTree ? "white" : "transparent"};
        `}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center line-height-normal flex-grow-1">
            {inSelectMode && (
              <Form.Check
                ref={checkboxRef}
                type="checkbox"
                className="p-1 me-1"
                checked={isSelected(listingItem.id)}
                onChange={(e) => {
                  onSelectChange(e.target.checked, listingItem.id);
                }}
              />
            )}
            <Link
              to={inSelectMode ? "#" : listingItem.portalNavigationPath}
              className="d-flex align-items-center text-decoration-none flex-grow-1 me-2"
              onClick={
                inSelectMode
                  ? (e) => {
                      e.preventDefault();
                      onSelectChange(
                        !isSelected(listingItem.id),
                        listingItem.id
                      );
                    }
                  : undefined
              }
            >
              {listingItem.plpLogo?.(isSelectedInTheTree)}
              <div>
                <div className="text-sm">{listingItem.title}</div>
                <div className="text-xs text-gray-600 d-flex align-items-center gap-1">
                  {listingItem.type.toString().charAt(0).toUpperCase() +
                    listingItem.type
                      .toString()
                      .slice(1)
                      .toLowerCase()
                      .replace(/_/g, " ")}{" "}
                  <div
                    style={{
                      padding: "1.7px",
                      borderRadius: "50%",
                      background: "#868689",
                      display: "inline-block",
                    }}
                  ></div>
                  {listingItem.nProducts() != null
                    ? `${listingItem.nProducts()} products`
                    : "Automated"}
                </div>
              </div>
            </Link>
          </div>
          {listingItem.children && listingItem.children.length > 0 && (
            <Button
              variant="link"
              className="p-2 rounded-circle"
              onClick={() =>
                onSetExpand(!isExpanded(listingItem.id), listingItem.id)
              }
              style={{ textDecoration: "none", fontSize: "8px" }}
            >
              {isExpanded(listingItem.id) ? (
                <span>&#9660;</span>
              ) : (
                <span>&#9654;</span>
              )}
            </Button>
          )}
        </div>
      </HoverableDiv>
      {isExpanded(listingItem.id) && listingItem.children && (
        <div className="ms-4">
          {listingItem.children
            .filter((child) => isVisible(child.id))
            .sort(sortFunction)
            .map((child) => (
              <ListingTreeItem
                editableListingTypes={editableListingTypes}
                selectIsIndeterminate={selectIsIndeterminate}
                onSelectChange={onSelectChange}
                isSelected={isSelected}
                inSelectMode={inSelectMode}
                currentListingItemId={currentListingItemId}
                sortFunction={sortFunction}
                isVisible={isVisible}
                key={child.id}
                listingItem={child}
                isExpanded={isExpanded}
                onSetExpand={onSetExpand}
                onDrop={onDrop}
              />
            ))}
        </div>
      )}
    </div>
  );
}

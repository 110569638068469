 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Barcode = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M0 3h1v14h-1v-14z" fill={color}></path>
<path d="M16 3h0.5v14h-0.5v-14z" fill={color}></path>
<path d="M3.5 3h0.5v14h-0.5v-14z" fill={color}></path>
<path d="M12 3h1v14h-1v-14z" fill={color}></path>
<path d="M2 3h1v14h-1v-14z" fill={color}></path>
<path d="M7.5 3h0.5v14h-0.5v-14z" fill={color}></path>
<path d="M6 3h1v14h-1v-14z" fill={color}></path>
<path d="M19.5 3h0.5v14h-0.5v-14z" fill={color}></path>
<path d="M17 3h1v14h-1v-14z" fill={color}></path>
<path d="M8.5 3h0.5v14h-0.5v-14z" fill={color}></path>
<path d="M10 3h1v14h-1v-14z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Barcode); 
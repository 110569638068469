import {
  DepictDataPoint,
  DepictMetricsData,
  EnrichedMetricsMarketData,
  MetricsMarketData,
  MetricsSurfaceData,
  NivoDataObject,
} from "src/types/metrics";
import { deepCopy, getTotalOf, refactorToArrayOfObjects } from "src/helpers";
import config from "src/config";
import { EnhancedSurface } from "src/types/components";
import { Surface } from "src/types/surfaces";
import {
  enhanceSurfaces,
  getDefaultCustomSurfaces,
  getEnhancedSurface,
} from "../surfaces";
import { PointTooltipProps } from "@nivo/line";
import TooltipPlot from "src/components/mix/TooltipPlot";
import { formatNumber } from "src/helpers";

export const enrichWithTotalFromRecommendations = (
  data: MetricsMarketData
): EnrichedMetricsMarketData => {
  /**
   * The total revenue (for recommendations) is computed adding up all revenues from all surfaces.
   */
  if ((data as EnrichedMetricsMarketData).totalFromRecommendations) {
    // already enriched - skip unnecessary computation
    return data as EnrichedMetricsMarketData;
  }

  let dataset: DepictDataPoint[] = [];
  let enrichedData = data as EnrichedMetricsMarketData;
  for (const totalDataPoint of enrichedData.total) {
    let newDataPoint = deepCopy(totalDataPoint);
    let newSum = 0;
    for (const surface in enrichedData.attributed) {
      const attributedPoint = enrichedData.attributed[surface].find(
        (p) => p.start === totalDataPoint.start
      );
      if (!attributedPoint?.value) {
        continue;
      }

      newSum += attributedPoint.value;
    }
    newDataPoint.value = newSum;
    dataset.push(newDataPoint);
  }

  enrichedData.totalFromRecommendations = dataset;
  return enrichedData;
};

/**
 * The following function computes the percentage values for "Revenue through Depict as % of total revenue"
 * and its corrispondent split-by-surface view.
 * For each point in time:
 * 1) Percentages for the total are calculated with: sumOfAllSurfaces / totalSum * 100
 * 2) Percentages for the split-by-surface are calculated with sumOfSurface / totalSum * 100
 */
export const relativizeMetricData = (
  data: DepictMetricsData,
  skipHoles: boolean
): DepictMetricsData => {
  let relativizedMetricData: DepictMetricsData = {};

  for (const market in data) {
    const { attributed, total } = data[market];

    const asPercentage: MetricsMarketData = {
      name: data[market].name,
      total: [],
      attributed: {},
    };

    for (var i = 0; i < total.length; i++) {
      const thisTotal: DepictDataPoint = total[i];
      const thisTotalSum: number = thisTotal.value ?? 0;
      let attributedSum: number = 0;

      for (const surface in attributed) {
        const attributedSurface =
          attributed[surface]?.find((p) => p.start === thisTotal.start) ?? null;
        if (!attributedSurface) {
          continue;
        }

        const surfaceSum = attributedSurface.value as number;
        attributedSum += surfaceSum;

        asPercentage.attributed[surface] ||= [];

        let surfacePercentage: number | null;
        if (thisTotalSum === 0) {
          if (skipHoles) {
            continue;
          }
          surfacePercentage = null;
        } else {
          surfacePercentage = (surfaceSum / thisTotalSum) * 100;
        }

        let temp = {
          start: attributedSurface.start,
          duration: attributedSurface.duration,
          value: surfacePercentage,
        } as DepictDataPoint;

        if (attributedSurface["extra"]) {
          temp["extra"] = attributedSurface["extra"];
        }

        asPercentage.attributed[surface].push(temp);
      }
      let percentage: number | null;

      if (thisTotalSum === 0) {
        if (skipHoles) {
          continue;
        }
        percentage = null;
      } else {
        percentage = (attributedSum / thisTotalSum) * 100;
      }

      let temp = {
        start: thisTotal.start,
        duration: thisTotal.duration,
        value: percentage,
      } as DepictDataPoint;

      if (thisTotal["extra"]) {
        temp.extra = thisTotal["extra"];
      }

      asPercentage.total.push(temp);
    }

    relativizedMetricData[market] = asPercentage;
  }

  return relativizedMetricData;
};

const getAggregateMetricValueBySurfaces = (
  marketData: MetricsMarketData,
  validSurfaces: Array<keyof MetricsSurfaceData> | null = null
) => {
  let sum = 0;

  if (validSurfaces?.includes("totalFromRecommendations")) {
    let dataPoints: DepictDataPoint[] =
      enrichWithTotalFromRecommendations(marketData).totalFromRecommendations;
    return getTotalOf(dataPoints, "value");
  } else {
    for (const key in marketData.attributed) {
      if (
        validSurfaces &&
        validSurfaces.length > 0 &&
        !validSurfaces.find((surface) => surface === key)
      ) {
        continue;
      }
      let dataPoints: DepictDataPoint[] = marketData.attributed[key];
      sum += getTotalOf(dataPoints, "value");
    }
  }
  return sum;
};

export const getAggregateMetricValue = (
  metric: MetricsMarketData,
  validSurfaces: Array<keyof MetricsSurfaceData> | null = null
) => {
  return getAggregateMetricValueBySurfaces(metric, validSurfaces);
};

export const getSurfacePercentageValue = (
  marketData: MetricsMarketData,
  validSurfaces: Array<keyof MetricsSurfaceData> | null = null
): number | null => {
  let total = getTotalOf(marketData.total, "value");

  if (total === 0) {
    return null;
  }

  let attributedSum = getAggregateMetricValueBySurfaces(
    marketData,
    validSurfaces
  );

  let percentage: number = (attributedSum / total) * 100;
  return percentage;
};

export const getMarketDataFromMetric = (
  metric: DepictMetricsData | null,
  market: string | null
): MetricsMarketData | null => {
  if (!metric || !market) {
    return null;
  }

  let marketKey: keyof DepictMetricsData = market !== "EMPTY_KEY" ? market : "";

  if (!metric[marketKey]) {
    return null;
  }

  return metric[marketKey];
};

export const getSurfaceConversionRate = (
  clicks: MetricsMarketData,
  purchases: MetricsMarketData,
  validSurfaces: Array<keyof MetricsSurfaceData> | null = null
): number | null => {
  let totalClicks = getAggregateMetricValueBySurfaces(clicks, validSurfaces);

  if (totalClicks === 0) {
    return null;
  }

  let totalPurchases = getAggregateMetricValueBySurfaces(
    purchases,
    validSurfaces
  );

  return (totalPurchases / totalClicks) * 100;
};

export const getSurfaceAggregatedValue = (
  marketData: MetricsMarketData,
  surface: keyof MetricsSurfaceData
): number => {
  let dataPoints: DepictDataPoint[] =
    surface === "totalFromRecommendations"
      ? enrichWithTotalFromRecommendations(marketData).totalFromRecommendations
      : marketData.attributed[surface];

  return getTotalOf(dataPoints, "value");
};

export const hasHoleInData = (dataset: DepictDataPoint[]): boolean => {
  if (!dataset) {
    return false;
  }

  for (var i = 0; i < dataset.length; i++) {
    if (dataset[i].value === null) {
      return true;
    }
  }

  return false;
};

export const filterSurfaces = (
  data: MetricsSurfaceData,
  enabledSurfaces: EnhancedSurface[] | null
): MetricsSurfaceData => {
  if (!enabledSurfaces) {
    return data;
  }

  let proxy: MetricsSurfaceData = {};

  for (var enabledSurface of enabledSurfaces) {
    if (!data[enabledSurface.id]) {
      continue;
    }
    proxy[enabledSurface.id] = data[enabledSurface.id];
  }

  return proxy;
};

export const filterData = (
  dataset: MetricsSurfaceData[],
  filters: Function[]
): NivoDataObject[] => {
  /**
   * Filters are functions that take either a DepictDataPoint[] or NivoDataPoint[] and return DepictDataPoint[] or NivoDataPoint[].
   * The last filter must always return a NivoDataPoint[].
   * The filters manipulate the dataset in order to prepare it for the <ResponsiveLine> component.
   */
  var nivoData: NivoDataObject[] = [];

  if (!dataset) {
    return nivoData;
  }

  for (var y = 0; y < dataset.length; y++) {
    let dataPoint: MetricsSurfaceData = dataset[y];
    let key = Object.keys(dataPoint)[0];
    let filtered = dataPoint[key];
    for (var i = 0; i < filters.length; i++) {
      filtered = filters[i](filtered);
    }
    nivoData.push({
      id: key,
      //@ts-ignore
      data: filtered as NivoDataPoint[],
    });
  }

  return nivoData;
};

export const filtersAreTheSame = (
  filters: Function[],
  previousFilters: Function[]
): boolean => {
  if (filters.length !== previousFilters.length) {
    return false;
  }

  for (var i = 0; i < filters.length; i++) {
    if (filters[i] !== previousFilters[i]) {
      return false;
    }
  }

  return true;
};

export const filterDataset = (
  metric: DepictMetricsData,
  market: string,
  splitBySurface: boolean,
  enabledSurfaces: EnhancedSurface[] | null
): MetricsSurfaceData[] => {
  if (!metric[market]) {
    return [];
  }

  const data = splitBySurface
    ? metric[market].attributed
    : {
        totalFromRecommendations: enrichWithTotalFromRecommendations(
          metric[market]
        ).totalFromRecommendations,
      };

  const filtered = enabledSurfaces
    ? filterSurfaces(data, enabledSurfaces)
    : data;

  const refactored = refactorToArrayOfObjects(filtered);

  return refactored as MetricsSurfaceData[];
};

export const getMetricDataset = (
  metric: DepictMetricsData,
  compareMetric: DepictMetricsData | null,
  market: string,
  enabledSurfaces: EnhancedSurface[] | null,
  splitBySurface: boolean,
  hasCompare: boolean | null
): MetricsSurfaceData[] => {
  let data = filterDataset(metric, market, splitBySurface, enabledSurfaces);
  if (hasCompare && compareMetric && compareMetric[market]) {
    let compareData = filterDataset(
      compareMetric,
      market,
      splitBySurface,
      enabledSurfaces
    );
    data = data.concat(
      compareData.map((el: MetricsSurfaceData) => {
        let key = Object.keys(el)[0];
        return { [key + config.const.compareSurfaceSuffix]: el[key] };
      })
    );
  }

  return data;
};

export const getEnhancedSurfaces = (
  merchantSurfaces: Surface[] | null,
  splitBySurface: boolean,
  hasCompare: boolean
): EnhancedSurface[] => {
  const customSurfaces = getDefaultCustomSurfaces();

  let tempSurfaces: EnhancedSurface[] = [];

  if (splitBySurface) {
    tempSurfaces = enhanceSurfaces(merchantSurfaces);
  }

  if (!splitBySurface) {
    tempSurfaces.push(customSurfaces["totalFromRecommendations"]);
  }

  if (hasCompare) {
    tempSurfaces = tempSurfaces.map((surface: EnhancedSurface) => {
      let surfaceCopy = deepCopy(surface);
      surfaceCopy.style = "dashed";
      surfaceCopy.id += config.const.compareSurfaceSuffix;
      surfaceCopy.display_name += " (Compare)";
      return Object.assign(surface, { children: [surfaceCopy] });
    });
  }

  return tempSurfaces;
};

export const getTooltipComponent = (
  value: PointTooltipProps,
  xLabel: string,
  yLabel: string | undefined,
  surfaces: EnhancedSurface[]
): JSX.Element => {
  const surface = getEnhancedSurface(
    value.point.serieId as string,
    surfaces ?? []
  );

  const title = surface?.display_name
    ? surface.display_name
    : (value.point.serieId as string);

  const xValue = (value.point.data as any)?.extra?.original_start_time
    ? (value.point.data as any).extra.original_start_time
    : value.point.data.xFormatted;

  return (
    <TooltipPlot
      value={value}
      title={title}
      xLabel={xLabel}
      xValue={xValue}
      yLabel={yLabel}
      yValue={
        yLabel === "Percentage"
          ? formatNumber({
              number: value.point.data.yFormatted as number,
              suffix: "%",
            })
          : formatNumber({ number: value.point.data.yFormatted as number })
      }
    />
  );
};

import { en } from "@depict-ai/react-ui/locales/latest";
import { css } from "@emotion/react";
import { Button, Collapse, Spinner } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { Product } from "../components/storybook/types";
import { CatalogProductGridCard } from "../components/storybook/CatalogV2/CatalogProductGridCard/CatalogProductGridCard";
import { useListingEditorContext } from "../contexts/ListingTreeSelectorContext";
import useMerchant from "../helpers/hooks/app/useMerchant";
import { ArrowsClockwise, Check } from "@phosphor-icons/react";
import {
  ProductMetadata,
  ProductMetadataProvider,
} from "../helpers/catalog/ProductMetadataProvider";
import { SelectedFilter } from "../components/storybook/AddProductsModal/Filters";
import { ReactFilterBody } from "@depict-ai/portal-components";
import useCatalogSyncStatus from "../helpers/hooks/catalog/useCatalogSyncStatus";
import useCatalogSyncTrigger from "../helpers/hooks/catalog/useCatalogSyncTrigger";
import CatalogProductListRow from "../components/storybook/CatalogV2/CatalogProductListRow/CatalogProductListRow";
import { Checkbox } from "../components/storybook/Checkbox/Checkbox";
import useOwnCategories from "../helpers/hooks/categories_v2_v3_common/useOwnCategories";
import useProductInbox from "../helpers/hooks/useProductInbox";
import { useSearchParams } from "react-router-dom";
import { DeepAny, PortalSearchFilter, PortalSortModel } from "../api/types";
import { useVisibilityState } from "../helpers/hooks/app/useVisibilityState";
import {
  decode_filters,
  decode_sorting,
  encode_filters,
  encode_sorting,
} from "@depict-ai/ui/latest";
import useProductCatalog, {
  DEFAULT_SORT,
} from "../helpers/catalog/useProductCatalog";
import { ImageResizeProvider } from "src/components/storybook/ImageResizeProvider/ImageResizeProvider";
import CatalogProductSpotlight from "../components/storybook/CatalogV2/CatalogProductSpotlight/CatalogProductSpotlight";
import { useCatalogProductSpotlights } from "../helpers/hooks/catalog/useCatalogProductSpotlights";
import { CatalogSearchFilterSortBar } from "../components/storybook/CatalogV2/CatalogSearchFilterSortBar/CatalogSearchFilterSortBar";
import { EmptyInbox } from "../components/storybook/CatalogV2/EmptyInbox/EmptyInbox";
import { TabButton } from "../components/storybook/CatalogV2/CatalogTabButton/CatalogTabButton";
import { useQuery } from "@tanstack/react-query";
import { getQueryKey, QueryId } from "../queries/queries";
import useAuthorizedApi, {
  AuthorizedApi,
} from "../helpers/hooks/app/useAuthorizedApi";
import { useCatalogMetadata } from "../helpers/hooks/catalog/useCatalogMetadata";
import CatalogProductModal from "../components/storybook/CatalogV2/CatalogProductModal/CatalogProductModal";
import { getData } from "src/api/authorizedApi";

const FILTER_PREFIX = "f_";
const SORT_PREFIX = "s_";

/**
 * Written by chatGPT, formats millisecond duration into a human readable string
 */
function formatDuration(milliseconds: number) {
  const totalMinutes = milliseconds / (1000 * 60);
  const roundedMinutes = Math.round(totalMinutes);
  const hours = Math.floor(roundedMinutes / 60);
  const minutes = roundedMinutes % 60;
  const days = Math.floor(hours / 24);
  const seconds = Math.floor((milliseconds / 1000) % 60);

  const parts = [];
  if (days) parts.push(days + " day" + (days > 1 ? "s" : ""));
  if (hours) parts.push((hours % 24) + " hour" + (hours % 24 > 1 ? "s" : ""));
  if (minutes) {
    parts.push(minutes + " minute" + (minutes > 1 ? "s" : ""));
  } else if (!minutes && !hours && !days) {
    parts.push(seconds + " second" + (seconds > 1 ? "s" : ""));
  }

  return parts.join(", ") + " ago";
}

const getAutoHideState = async (
  api: AuthorizedApi,
  merchantId: string,
  autoHideType: "all_sizes_out_of_stock"
) => {
  const response = await api.GET(
    "/api/v0/catalog/products/{merchant_id}/auto_hide/{auto_hide_type}",
    {
      params: {
        path: {
          merchant_id: merchantId,
          auto_hide_type: autoHideType,
        },
      },
    }
  );

  const data = getData(response);

  return data;
};

function SyncButton({ merchantId }: { merchantId: string }) {
  const [now, setNow] = useState(+new Date());

  const {
    lastSync: lastSyncString,
    syncInProgress,
    nQueued,
    isLoading: isLoadingSyncStatus,
    isError: isErrorSyncStatus,
  } = useCatalogSyncStatus(merchantId);
  const lastSync: Date | null | undefined =
    typeof lastSyncString === "string"
      ? new Date(lastSyncString)
      : lastSyncString;

  const { mutation: catalogSyncTriggerMutation } =
    useCatalogSyncTrigger(merchantId);
  const pageVisible = useVisibilityState();

  useEffect(() => {
    if (!pageVisible) return; // No need to update the time ago when page isn't focussed, otherwise periodically update it
    const interval = setInterval(() => {
      setNow(+new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, [pageVisible]);
  return (
    <div>
      <span title={lastSync?.toLocaleString()}>
        Last synced: {lastSync ? formatDuration(now - +lastSync) : "unknown"}
      </span>
      <Button
        variant="outline-secondary"
        css={css`
          padding: 12px 8px;
          border-radius: 8px;
          margin-left: 8px;
        `}
        disabled={
          isLoadingSyncStatus ||
          isErrorSyncStatus ||
          !!nQueued /* only allow queueing one sync for now */
        }
        onClick={() => catalogSyncTriggerMutation.mutate()}
      >
        {syncInProgress
          ? "Syncing…" + (nQueued ? ` (${nQueued} in queue)` : "")
          : "Sync Catalog"}
        <ArrowsClockwise size={16} className="ms-1" />
      </Button>
    </div>
  );
}

function CatalogV2() {
  const { merchantId } = useMerchant();

  const [expandedFilters, setExpandedFilters] = useState<
    { section_: string; expanded_: boolean }[]
  >([]);
  const [expandedHierarchicalFilters, setExpandedHierarchicalFilters] =
    useState<{ value_: string[]; expanded_: boolean }[]>([]);
  const [filtersIsOpen, setFiltersIsOpen] = useState(false);

  const ownsCategories = useOwnCategories();

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedFilters = decode_filters(searchParams, FILTER_PREFIX);
  const selectedSort =
    decode_sorting(searchParams, SORT_PREFIX) ?? DEFAULT_SORT;
  const searchTerm = searchParams.get("q") ?? "";
  const currentView: "inbox" | "all" | "out-of-stock" =
    (searchParams.get("view") as any) ?? "all";
  const viewedMainProductId = searchParams.get("mainProductId") ?? null;

  const {
    filteredProductsQuery,
    totalNumberOfOutOfStockProducts,
    totalNumberOfProducts,
  } = useProductCatalog({
    merchantId,
    currentView,
    searchTerm,
    selectedFilters,
    selectedSort,
  });

  const { inboxMainProductIds, dismissProducts } = useProductInbox(merchantId);

  const [currentMode, setCurrentMode] = useState<"grid" | "list">("grid");
  const [showSpotlight, setShowSpotlight] = useState(true);

  const { metrics } = useCatalogProductSpotlights(merchantId);

  const setCatalogParams = (args: {
    searchText: string;
    selectedFilters: SelectedFilter[];
    selectedSort: PortalSortModel;
    viewedMainProductId: string | null;
  }) => {
    const {
      searchText,
      selectedFilters: newSelectedFilters,
      selectedSort,
    } = args;

    const searchParams = new URLSearchParams();
    searchParams.set("q", searchText);
    searchParams.set("view", currentView);
    searchParams.set("mainProductId", args.viewedMainProductId ?? "");
    encode_filters(newSelectedFilters, searchParams, FILTER_PREFIX);
    encode_sorting(selectedSort, searchParams, SORT_PREFIX);
    setSearchParams(searchParams);
  };

  const onSetSearchQuery = (searchQuery: string) => {
    setCatalogParams({
      searchText: searchQuery,
      selectedFilters,
      selectedSort,
      viewedMainProductId,
    });
    setSelectedMainProductIds(new Set());
  };

  const onSetFilters = (filters: SelectedFilter[]) => {
    setCatalogParams({
      searchText: searchTerm,
      selectedFilters: filters,
      selectedSort,
      viewedMainProductId,
    });
    setSelectedMainProductIds(new Set());
  };

  const allProducts = filteredProductsQuery.data?.pages
    ?.map(
      (p) => p?.products.filter((p) => typeof p !== "undefined") as Product[]
    )
    .flat();

  const {
    setSelectedMainProductIds,
    selectedMainProductIds,
    mainProductIdsWithListings,
  } = useListingEditorContext(merchantId);

  const selectedAndHasListingsMainProductIds = Array.from(
    selectedMainProductIds
  ).filter((id) => mainProductIdsWithListings.has(id));

  const availableFilters: DeepAny<PortalSearchFilter>[] | undefined | null =
    filteredProductsQuery.data?.pages?.[0]?.filters;
  const availableSorts = filteredProductsQuery.data?.pages?.[0]?.sorts;

  const scrollRef = useRef<HTMLDivElement>(null);

  const handleScroll = async () => {
    if (!scrollRef.current) return;

    if (scrollRef.current.scrollTop > 50) {
      setShowSpotlight(false);
    } else if (scrollRef.current.scrollTop === 0) {
      setShowSpotlight(true);
    }

    if (
      scrollRef.current.scrollTop + scrollRef.current.clientHeight >=
      scrollRef.current.scrollHeight
    ) {
      await filteredProductsQuery.fetchNextPage();
    }
  };

  const handleSelectClick = (
    mainProductId: string,
    alreadySelected: boolean
  ) => {
    if (ownsCategories) {
      const newSelectedMainProductIds = new Set(selectedMainProductIds);
      if (alreadySelected) {
        newSelectedMainProductIds.delete(mainProductId);
      } else {
        newSelectedMainProductIds.add(mainProductId);
      }
      setSelectedMainProductIds(newSelectedMainProductIds);
    }
  };

  const handleSelectAll = () => {
    if (ownsCategories) {
      if (selectedMainProductIds.size > 0) {
        setSelectedMainProductIds(new Set());
      } else {
        setSelectedMainProductIds(
          new Set(allProducts?.map((p) => p.main_product_id) ?? [])
        );
      }
    }
  };

  const totalNrOfFilteredProducts =
    (filteredProductsQuery.data?.pages
      ? filteredProductsQuery.data?.pages[
          filteredProductsQuery.data?.pages?.length - 1
        ]?.hits
      : allProducts?.length) ?? 0;

  const { api } = useAuthorizedApi();

  const { data: variants, isLoading } = useQuery({
    queryKey: getQueryKey(
      QueryId.GetProductsByItemGroupId,
      merchantId || "",
      viewedMainProductId || ""
    ),
    queryFn: async () => {
      if (!api || !merchantId || !viewedMainProductId) return null;
      const response = await api.GET("/api/v0/catalog/products/detailed", {
        params: {
          query: {
            merchant_id: merchantId,
            main_product_id: viewedMainProductId,
          },
        },
      });

      return getData(response);
    },
    enabled: !!api && !!merchantId && !!viewedMainProductId,
  });

  const mainVariant = variants?.find(
    (v) => v.main_product_id === viewedMainProductId
  );

  const variantProductId = mainVariant?.sizes?.[0]?.product_id;

  const { data: recommendations } = useQuery({
    queryKey: getQueryKey(
      QueryId.GetProductRecommendations,
      merchantId || "",
      variantProductId || ""
    ),
    queryFn: async () => {
      if (!api || !merchantId || !variantProductId) return null;
      const response = await api.GET(
        "/api/v0/catalog/products/recommendations/{product_id}",
        {
          params: {
            query: {
              merchant_id: merchantId,
            },
            path: {
              product_id: variantProductId,
            },
          },
        }
      );

      return getData(response);
    },
    enabled: !!api && !!merchantId && !!variantProductId,
  });

  const { productMetadata: allProductMetadata } = useCatalogMetadata();
  const productMetadata: ProductMetadata[] = viewedMainProductId
    ? allProductMetadata?.[viewedMainProductId] ?? []
    : [];

  const getDetailsPageLink = (mainProductId: string | null) => {
    // get searchParams and set mainProductId
    const searchParams = new URLSearchParams();
    searchParams.set("q", searchTerm);
    searchParams.set("view", currentView);
    if (mainProductId) {
      searchParams.set("mainProductId", mainProductId);
    }
    encode_filters(selectedFilters, searchParams, FILTER_PREFIX);
    encode_sorting(selectedSort, searchParams, SORT_PREFIX);
    return `/${merchantId}/catalog?${searchParams.toString()}`;
  };

  const viewedMainProductIndex = viewedMainProductId
    ? allProducts?.findIndex((p) => p.main_product_id === viewedMainProductId)
    : undefined;

  const previousMainProductId =
    viewedMainProductIndex !== undefined && viewedMainProductIndex > 0
      ? allProducts?.[viewedMainProductIndex - 1]?.main_product_id
      : undefined;
  const nextMainProductId =
    viewedMainProductIndex !== undefined &&
    viewedMainProductIndex >= 0 &&
    allProducts &&
    viewedMainProductIndex < allProducts?.length - 1
      ? allProducts?.[viewedMainProductIndex + 1]?.main_product_id
      : undefined;

  const onBrowseForward = nextMainProductId
    ? () => {
        if (
          viewedMainProductIndex !== undefined &&
          allProducts &&
          viewedMainProductIndex >= allProducts?.length - 2
        ) {
          filteredProductsQuery.fetchNextPage();
        }
        setCatalogParams({
          searchText: searchTerm,
          selectedFilters,
          selectedSort,
          viewedMainProductId: nextMainProductId,
        });
      }
    : undefined;

  const onBrowseBack = previousMainProductId
    ? () => {
        setCatalogParams({
          searchText: searchTerm,
          selectedFilters,
          selectedSort,
          viewedMainProductId: previousMainProductId,
        });
      }
    : undefined;

  const query = useQuery({
    queryKey: getQueryKey(QueryId.GetAutoHide, merchantId),
    enabled: !!(merchantId && api),
    queryFn: async () => {
      if (!merchantId || !api) return;
      const data = await getAutoHideState(
        api,
        merchantId,
        "all_sizes_out_of_stock"
      );
      return data;
    },
  });

  const oosAutoHideState = query.data || false;

  return (
    <>
      <div
        css={css`
          background: white;
          margin: 8px 8px 8px 0;
          border-radius: 16px;
          display: flex;
          flex-direction: column;
          height: calc(100vh - 16px);
        `}
        onWheel={(e) => {
          if (e.deltaY > 0) {
            setShowSpotlight(false);
          }
        }}
      >
        <div
          css={css`
            padding: 16px 16px 0 16px;
            border-bottom: 1px solid #e5e5e5;
          `}
        >
          <div className="d-flex align-items-center justify-content-between">
            <h1
              css={css`
                margin-left: 8px;
                font-size: 36px;
                font-weight: 700;
              `}
            >
              Product catalog
            </h1>
            <div className="d-flex align-items-center">
              <SyncButton merchantId={merchantId} />
              {currentView === "inbox" &&
                selectedMainProductIds.size > 0 &&
                selectedAndHasListingsMainProductIds.length > 0 && (
                  <Button
                    variant="primary"
                    css={css`
                      padding: 12px 8px;
                      border-radius: 8px;
                      margin-left: 4px;
                    `}
                    onClick={() => {
                      dismissProducts(selectedAndHasListingsMainProductIds);
                      setSelectedMainProductIds(
                        new Set(
                          Array.from(selectedMainProductIds).filter(
                            (id) => !mainProductIdsWithListings.has(id)
                          )
                        )
                      );
                    }}
                  >
                    Mark assigned as done
                    <Check className="ms-1" />
                  </Button>
                )}
            </div>
          </div>

          {currentView === "all" &&
            metrics &&
            metrics.flatMap((m) => m.products).length > 0 && (
              <Collapse in={showSpotlight}>
                <div
                  className={!showSpotlight ? "inactive" : ""}
                  css={css`
                    margin: 16px;
                  `}
                >
                  <CatalogProductSpotlight metrics={metrics} />
                </div>
              </Collapse>
            )}

          <div className="ms-2 mb-4 d-flex">
            {ownsCategories && (
              <TabButton
                active={currentView === "inbox"}
                to={`/${merchantId}/catalog?view=inbox`}
              >
                Inbox
                {inboxMainProductIds && inboxMainProductIds.length > 0 && (
                  <div
                    className="d-flex p-1 align-items-center justify-content-center rounded-pill text-white"
                    css={css`
                      background-color: #3018c1;
                      height: 18px;
                      margin-left: 4px;
                      min-width: 18px;
                      font-weight: normal;
                      font-size: 10px;
                    `}
                  >
                    {inboxMainProductIds.length}
                  </div>
                )}
              </TabButton>
            )}
            <TabButton
              active={currentView === "all"}
              to={`/${merchantId}/catalog?view=all`}
              onClick={() => {
                setShowSpotlight(true);
              }}
            >
              All
              <div
                css={css`
                  margin-left: 4px;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 400;
                  color: #868689;
                `}
              >
                {totalNumberOfProducts}
              </div>
            </TabButton>
            {totalNumberOfOutOfStockProducts !== undefined && (
              <TabButton
                active={currentView === "out-of-stock"}
                to={`/${merchantId}/catalog?view=out-of-stock`}
              >
                Out of stock
                <div
                  css={css`
                    margin-left: 4px;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    color: #868689;
                  `}
                >
                  {totalNumberOfOutOfStockProducts}
                </div>
              </TabButton>
            )}
          </div>

          <CatalogSearchFilterSortBar
            searchTerm={searchTerm}
            availableFilters={availableFilters}
            selectedFilters={selectedFilters}
            onSetFilters={onSetFilters}
            availableSorts={availableSorts}
            selectedSort={selectedSort}
            onSetSearchQuery={onSetSearchQuery}
            onSetSort={(sort) => {
              setCatalogParams({
                searchText: searchTerm,
                selectedFilters,
                selectedSort: sort,
                viewedMainProductId,
              });
            }}
            currentMode={currentMode}
            setCurrentMode={setCurrentMode}
            expandedHierarchicalFilters={expandedHierarchicalFilters}
            setExpandedHierarchicalFilters={setExpandedHierarchicalFilters}
            filtersIsOpen={filtersIsOpen}
            setFiltersIsOpen={setFiltersIsOpen}
            useOosAutoHideToggle={currentView === "out-of-stock"}
            oosAutoHideState={oosAutoHideState}
          />
        </div>

        {filteredProductsQuery.isLoading && (
          <div
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 64px;
            `}
          >
            <Spinner />
          </div>
        )}

        {currentView === "inbox" && inboxMainProductIds?.length === 0 ? (
          <EmptyInbox />
        ) : (
          <div
            css={css`
              position: relative;
              display: flex;
              flex-direction: row;
              margin: 8px 16px 16px 16px;
              overflow: hidden;
            `}
          >
            <div
              css={css`
                width: 300px;
                position: absolute;
                left: ${filtersIsOpen ? "0" : "-300px"};
                overflow-y: auto;
                opacity: ${filtersIsOpen ? "1" : "0"};
                visibility: ${filtersIsOpen ? "visible" : "hidden"};
                height: 100%;
                transition: left 0.3s ease-out, opacity 0.3s ease-out,
                  visibility 0.3s ease-out;
                .filter-collapsible:first-of-type {
                  border-top: none !important;
                }
              `}
            >
              <ReactFilterBody
                availableFilters={availableFilters || undefined}
                selectedFilters={selectedFilters}
                setSelectedFilters={onSetFilters}
                expandedFilters={expandedFilters}
                setExpandedFilters={setExpandedFilters}
                expandedHierarchicalFilters={expandedHierarchicalFilters}
                setExpandedHierarchicalFilters={setExpandedHierarchicalFilters}
                i18n={en}
              />
            </div>
            {allProducts && allProducts.length > 0 && (
              <div
                ref={scrollRef}
                onScroll={handleScroll}
                css={css`
                  flex: 1;
                  margin-left: ${filtersIsOpen
                    ? "316px"
                    : 0}; // 300px + 16px margin-right of filter div
                  transition: margin-left 0.3s ease-out;
                  overflow: auto;
                `}
              >
                {currentMode === "grid" && (
                  <div
                    css={css`
                      display: grid;
                      grid-template-columns: repeat(
                        auto-fill,
                        minmax(300px, 1fr)
                      );
                      gap: 8px;
                      grid-auto-rows: min-content;
                    `}
                  >
                    {allProducts?.map((p, i) => (
                      <CatalogProductGridCard
                        product={p}
                        index={i}
                        highlight={false}
                        isSelected={selectedMainProductIds.has(
                          p.main_product_id
                        )}
                        isInSelectMode={ownsCategories}
                        showSelectCheckbox={ownsCategories}
                        onSelectClick={handleSelectClick}
                        linkToProductDetails={getDetailsPageLink(
                          p.main_product_id
                        )}
                        oosAutoHideState={oosAutoHideState}
                      />
                    ))}
                  </div>
                )}
                {currentMode === "list" && (
                  <div
                    css={css`
                      margin-left: 32px;
                    `}
                  >
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                        padding: 8px;
                        border: 1px solid transparent;
                        margin-bottom: 8px;
                      `}
                    >
                      {ownsCategories && (
                        <Checkbox
                          indeterminate={
                            selectedMainProductIds.size > 0 &&
                            selectedMainProductIds.size !== allProducts?.length
                          }
                          checked={
                            allProducts?.length === selectedMainProductIds.size
                          }
                          onChange={handleSelectAll}
                          style={{
                            width: 20,
                            height: 20,
                            marginRight: 8,
                          }}
                        />
                      )}
                      <span
                        onClick={handleSelectAll}
                        css={css`
                          cursor: pointer;
                          color: #0b0b0b;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 400;
                        `}
                      >
                        {totalNrOfFilteredProducts} products
                      </span>
                    </div>
                    <div>
                      {allProducts?.map((p, i) => (
                        <CatalogProductListRow
                          linkToProductDetails={getDetailsPageLink(
                            p.main_product_id
                          )}
                          product={p}
                          index={i}
                          disableIndex={false}
                          highlight={false}
                          isSelected={selectedMainProductIds.has(
                            p.main_product_id
                          )}
                          isInSelectMode={ownsCategories}
                          showSelectCheckbox={ownsCategories}
                          onSelectClick={handleSelectClick}
                          oosAutoHideState={oosAutoHideState}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <CatalogProductModal
        recommendedProducts={recommendations ?? undefined}
        open={!!viewedMainProductId}
        product={mainVariant ?? null}
        isLoading={isLoading}
        productMetadata={productMetadata}
        onBrowseBack={onBrowseBack}
        onBrowseForward={onBrowseForward}
        getLinkToProduct={(mainProductId) => getDetailsPageLink(mainProductId)}
        variants={
          variants?.filter((v) => v.main_product_id !== viewedMainProductId) ||
          []
        }
        onClose={() => {
          setCatalogParams({
            searchText: searchTerm,
            selectedFilters,
            selectedSort,
            viewedMainProductId: null,
          });
        }}
        oosAutoHideState={oosAutoHideState}
      />
    </>
  );
}

export default function CatalogV2View() {
  const { merchantId } = useMerchant();
  return (
    <ProductMetadataProvider>
      <ImageResizeProvider merchantId={merchantId}>
        <CatalogV2 />
      </ImageResizeProvider>
    </ProductMetadataProvider>
  );
}

import { useCallback, useEffect } from "react";
import { useAnalytics } from "../../integrations/segment";
import useUser from "../useUser";

export function useTrackPageView(pageName: string) {
  const analytics = useAnalytics();
  const { user } = useUser();
  useEffect(() => {
    if (user === null || user?.email.endsWith("@depict.ai")) {
      return;
    }
    analytics.page(pageName);
  }, [analytics, pageName, user]);
}

export function useTrackEvent(eventName: string) {
  const analytics = useAnalytics();
  const { user } = useUser();
  return useCallback(
    (properties: Parameters<typeof analytics["track"]>["1"]) => {
      if (user?.email.endsWith("@depict.ai")) {
        return;
      }
      analytics.track(eventName, properties);
    },
    [analytics, eventName, user?.email]
  );
}

import {
  CategoryRankingOrderOption,
  CategoryRankingOrderOptionType,
  RankingOption,
} from "./CategoryRankingOrderOption";

export type CategoryRankingOrderProps = {
  selected: CategoryRankingOrderOptionType;
  availableOptions: RankingOption[];
  onClick: (type: CategoryRankingOrderOptionType) => void;
};

export const CategoryRankingOrder = (props: CategoryRankingOrderProps) => {
  return (
    <div
      className="d-flex flex-row justify-content-center align-items-stretch"
      style={{ columnGap: "1rem" }}
    >
      {Object.values(CategoryRankingOrderOptionType).map((type) => {
        const option = props.availableOptions.find(
          (option) => option.type === type
        );

        if (!option) return null;

        const disabled = !option.available;

        return (
          <CategoryRankingOrderOption
            key={type}
            type={type}
            selected={type === props.selected}
            onClick={() => props.onClick(type)}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
};

export default CategoryRankingOrder;

import { useState, useCallback } from "react";

export default function useParam(): [
  parameter: boolean,
  setCallback: () => void
] {
  const [parameter, setParameter] = useState<boolean>(false);

  const setCallback = useCallback(() => {
    setParameter((value) => {
      return !value;
    });
  }, []);

  return [parameter, setCallback];
}

import { createContext } from "react";
import { Merchant } from "src/api/types";

interface AuthorizedMerchantsValue {
  authorizedMerchants: Merchant[] | null;
  loading: boolean;
  error: boolean;
}

/**
 * AuthorizedMerchantsContext is a React Context that provides the list of
 * merchants that the current user is authorized to access.
 * This is currently only relevant to admin users.
 */
export const AuthorizedMerchantsContext =
  createContext<AuthorizedMerchantsValue>({
    authorizedMerchants: null,
    loading: true,
    error: false,
  });

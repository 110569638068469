import { useContext } from "react";
import { AuthorizedMerchantsContext } from "src/contexts/AuthorizedMerchants.context";

/**
 * useAuthorizedMerchantsContext is a React hook that provides a convenient way
 * to access the AuthorizedMerchantsContext.
 */
export const useAuthorizedMerchantsContext = () => {
  const { authorizedMerchants, loading, error } = useContext(
    AuthorizedMerchantsContext
  );

  return { authorizedMerchants, loading, error };
};

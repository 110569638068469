import { Col, Placeholder, Row } from "react-bootstrap";
import { IMAGE_SIZE } from "../Collections/CollectionProductListCard/CollectionProductListCard";

export const ProductListCardPlaceholder = () => {
  return (
    <Placeholder
      as={Row}
      className="bg-white rounded pt-2 pb-2 px-3 align-items-center"
      animation="glow"
    >
      <Placeholder as={Col} sm={1} className=" gap-3 " style={{ flex: 1 }}>
        <Placeholder
          style={{ width: IMAGE_SIZE, height: "61px" }}
        ></Placeholder>
      </Placeholder>
    </Placeholder>
  );
};

export default ProductListCardPlaceholder;

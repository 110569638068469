 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Highlight = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M19.854 9.646c-0.195-0.195-0.512-0.195-0.707 0l-3.586 3.586c-0.585 0.585-1.537 0.585-2.121 0l-4.672-4.672c-0.282-0.282-0.437-0.658-0.437-1.061s0.155-0.779 0.437-1.061l3.586-3.586c0.195-0.195 0.195-0.512 0-0.707s-0.512-0.195-0.707 0l-3.586 3.586c-0.471 0.471-0.73 1.098-0.73 1.768 0 0.285 0.048 0.563 0.138 0.824l-7.322 7.322c-0.094 0.094-0.146 0.221-0.146 0.354v1.5c0 0.276 0.224 0.5 0.5 0.5h9.5c0.133 0 0.26-0.053 0.354-0.146l3.322-3.322c0.261 0.091 0.539 0.138 0.824 0.138 0.669 0 1.297-0.259 1.768-0.73l3.586-3.586c0.195-0.195 0.195-0.512 0-0.707zM9.793 17h-8.793v-0.793l7.002-7.002c0.020 0.021 0.039 0.042 0.059 0.062l4.672 4.672c0.020 0.020 0.041 0.040 0.062 0.059l-3.002 3.002z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Highlight); 
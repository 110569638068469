import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { getData } from "src/api/authorizedApi";
import useAuthorizedApi from "../../../helpers/hooks/app/useAuthorizedApi";
import { QueryId, getQueryKey } from "../../../queries/queries";
import {
  PartialShopifyConfig,
  ShopifyConfig,
} from "../../storybook/Integrations/Shopify/types";
import ShopifyApiConfigStep, {
  constructAdminApiUrl,
} from "./ShopifyApiConfigStep";
import ShopifyCompleteIntegrationStep from "./ShopifyCompleteIntegrationStep";
import ShopifyMarketConfigStep from "./ShopifyMarketConfigStep";
import ShopifyMetafieldsConfigStep from "./ShopifyMetafieldsConfigStep";

enum ShopifyStep {
  API_CREDENTIALS,
  MARKETS,
  ATTRIBUTES,
  SUCCESS,
}

export default function ShopifyIntegrationSteps({
  merchant,
  onClose,
  onSuccess,
}: {
  merchant: string;
  onClose: () => void;
  onSuccess: () => void;
}) {
  const [currentStep, setCurrentStep] = useState<ShopifyStep>(
    ShopifyStep.API_CREDENTIALS
  );
  const [newShopifyConfig, setNewShopifyConfig] =
    useState<PartialShopifyConfig>({});

  const { api } = useAuthorizedApi();

  const shopifyQuery = useQuery({
    queryKey: getQueryKey(QueryId.GetShopifyApiCredentials, merchant),
    queryFn: async () => {
      if (api) {
        const response = await api.GET(
          "/api/v0/integrations/shopify/{merchant_id}/api-credentials",
          {
            params: {
              path: {
                merchant_id: merchant,
              },
            },
          }
        );

        const data = getData(response);
        return data;
      }
    },
    enabled: !!api,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (shopifyQuery.isSuccess) {
      const data = shopifyQuery.data;
      if (data) {
        setNewShopifyConfig((prev) => ({
          ...prev,
          admin_api_url: constructAdminApiUrl(data.admin_api_url),
          admin_api_key: data.admin_api_key,
        }));
        setCurrentStep(ShopifyStep.MARKETS);
      }
    }
  }, [shopifyQuery.data, shopifyQuery.isSuccess]);

  switch (currentStep) {
    case ShopifyStep.API_CREDENTIALS:
      return (
        <ShopifyApiConfigStep
          adminApiUrl={newShopifyConfig.admin_api_url}
          adminApiKey={newShopifyConfig.admin_api_key}
          onSubmit={(adminApiUrl: string, adminApiKey: string) => {
            setNewShopifyConfig((prev) => ({
              ...prev,
              admin_api_url: adminApiUrl,
              admin_api_key: adminApiKey,
            }));
            setCurrentStep(ShopifyStep.MARKETS);
          }}
          onBack={() => onClose()}
        />
      );
    case ShopifyStep.MARKETS:
      return (
        <ShopifyMarketConfigStep
          adminApiUrl={newShopifyConfig.admin_api_url}
          adminApiKey={newShopifyConfig.admin_api_key}
          onBack={() => setCurrentStep(ShopifyStep.API_CREDENTIALS)}
          onSubmit={(markets) => {
            setCurrentStep(ShopifyStep.ATTRIBUTES);
            setNewShopifyConfig((prev) => ({
              ...prev,
              markets,
            }));
          }}
          merchantId={merchant}
        />
      );
    case ShopifyStep.ATTRIBUTES:
      return (
        <ShopifyMetafieldsConfigStep
          adminApiUrl={newShopifyConfig.admin_api_url}
          adminApiKey={newShopifyConfig.admin_api_key}
          onBack={() => setCurrentStep(ShopifyStep.API_CREDENTIALS)}
          onSubmit={(
            genderParsingConfig,
            ageParsingConfig,
            optionalAttributeParsingConfigs
          ) => {
            setCurrentStep(ShopifyStep.SUCCESS);
            setNewShopifyConfig((prev: PartialShopifyConfig) => ({
              ...prev,
              parsing_configs: [
                genderParsingConfig,
                ageParsingConfig,
                ...optionalAttributeParsingConfigs,
              ],
            }));
          }}
          merchantId={merchant}
        />
      );
    case ShopifyStep.SUCCESS:
      return (
        <ShopifyCompleteIntegrationStep
          merchant={merchant}
          config={newShopifyConfig as ShopifyConfig}
          onBack={() => setCurrentStep(ShopifyStep.ATTRIBUTES)}
          onComplete={onSuccess}
        />
      );
    default:
      return null;
  }
}

import React from "react";
import { RouterProvider } from "react-router-dom";
import { MyRouter } from "src/router/PortalRouter";
import config from "./config";

const MainContent: React.FC = () => {
  const baseName = config.publicUrl.endsWith("/")
    ? config.publicUrl.slice(0, -1)
    : config.publicUrl;

  const router = MyRouter(baseName);

  return <RouterProvider router={router} />;
};

export default MainContent;

import { useCallback, useEffect, useState } from "react";
import { Surface } from "src/types/surfaces";

import surfacesMiddleware from "src/api/surfacesMiddleware";
import { SurfacesApiResult } from "src/types/api";
import { getAlertMessage } from "src/helpers/errors";
import { ControlLoadingQueueCB } from "src/types/components";
import useAuthentication from "../app/useAuthentication";
import { useAlerts } from "../../../components/storybook/Alert/useAlerts";

type UseSurfaces = [surfaces: Surface[] | null];

export default function useSurfaces(
  merchant: string | null,
  controlLoadingQueueCB: ControlLoadingQueueCB
) {
  const { addAlert } = useAlerts();
  const [surfaces, setSurfaces] = useState<Surface[] | null>(null);
  const [getAccessToken] = useAuthentication();

  const loadMerchantData = useCallback(
    async (merchant: string) => {
      controlLoadingQueueCB("load-merchant-surfaces", "push");

      const id_token = await getAccessToken();
      const surfaces: SurfacesApiResult = (await surfacesMiddleware.get(
        id_token,
        merchant
      )) as SurfacesApiResult;

      if (!surfaces.success) {
        let message = await getAlertMessage(surfaces.error);
        addAlert(message);
        setSurfaces(null);
        controlLoadingQueueCB("load-merchant-surfaces", "remove");
        return;
      }

      setSurfaces(surfaces.data);
      controlLoadingQueueCB("load-merchant-surfaces", "remove");
    },
    [controlLoadingQueueCB, getAccessToken, addAlert]
  );

  useEffect(() => {
    if (!merchant) {
      setSurfaces(null);
      return;
    }

    loadMerchantData(merchant);

    return () => {
      setSurfaces(null);
    };
  }, [loadMerchantData, merchant]);

  return [surfaces] as UseSurfaces;
}

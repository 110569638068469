import {
  Active,
  ClientRect,
  closestCenter,
  DroppableContainer,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { RectMap } from "@dnd-kit/core/dist/store";
import { Coordinates } from "@dnd-kit/utilities";

// Increase this number to make it harder to accidentally start dragging
// Increasing it will also delay the drag start, so it's a tradeoff
const ACTIVATION_CONSTRAINT_DISTANCE = 5;

export function useDndSensors() {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: ACTIVATION_CONSTRAINT_DISTANCE,
      },
    })
  );
  return sensors;
}

export function sortableDndCollisionDetection(
  arg: {
    active: Active;
    collisionRect: ClientRect;
    droppableRects: RectMap;
    droppableContainers: DroppableContainer[];
    pointerCoordinates: Coordinates | null;
  },
  overlayRef: React.RefObject<HTMLDivElement>,
  getOffset: () => { x: number; y: number; changed: boolean },
  pause?: boolean
) {
  // Offsetting both the collision detection (where the indicator is placed),
  // and the actual drag overlay (the deck of cards that is being dragged),
  // is necessary because of this case:
  // - The user drags a card which is not the first in the pair
  //  - Result is that the other cards in the pair are removed from the grid
  //  - This causes the grid to reflow, and the indicator to be placed in the wrong place
  if (arg.pointerCoordinates) {
    const offset = getOffset();
    arg.collisionRect.left += offset.x;
    arg.collisionRect.top += offset.y;
    if (overlayRef.current && offset.changed) {
      overlayRef.current!.style.position = "relative";
      overlayRef.current!.style.left = `${offset.x}px`;
      overlayRef.current!.style.top = `${offset.y}px`;
    }
  }
  return closestCenter(arg);
}

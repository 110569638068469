import { Cross, Trash } from "src/components/linearicons";
import { ModalContent } from "src/types/modals";

const modal: ModalContent = {
  title: "Delete",
  subtitle: "{configuration_name}",
  content: "Are you sure you want to permanently delete your configuration?",
  buttons: [
    {
      id: "cancel",
      label: "Cancel",
      icon: <Cross size="12" className="me-2" />,
      className: "btn btn-sm btn-outline-depict-brown rounded-pill me-2",
    },
    {
      id: "delete",
      label: "Delete",
      icon: <Trash size="12" className="me-2" />,
      className: "btn btn-sm btn-depict-red rounded-pill me-2",
    },
  ],
};

export default modal;

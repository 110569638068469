import {
  ConfigurationsApiParams,
  ConfigurationsApiResult,
} from "src/types/api";
import config from "src/config";
import { mockConfigurations } from "src/helpers";

const configurationsApi = async (params: {
  idToken: string;
  merchant_id: string;
  configuration_id?: number;
  body?: ConfigurationsApiParams;
  method?: "GET" | "POST" | "PUT" | "DELETE";
}): Promise<ConfigurationsApiResult> => {
  let { merchant_id, configuration_id, body, method, idToken } = params;
  let endpoint = `/merchants/${merchant_id}/configurations`;

  if (method === "PUT" || method === "DELETE") {
    endpoint = `/merchants/${merchant_id}/configurations/${configuration_id}`;
  }

  let url = config.api.baseUrl + "/" + config.api.version + endpoint;
  return new Promise((resolve) => {
    if (!method) {
      method = "GET";
    }

    if (config.dataMock && method === "GET") {
      let loadMock = async () => {
        console.log(
          "%c[MOCK] Loaded mocking configurations for " + merchant_id,
          "color:coral"
        );
        const data: any = await mockConfigurations();
        resolve({
          success: true,
          data: data,
        });
      };
      loadMock();
      return;
    }

    let headers: any = {
      accept: "application/json",
      Authorization: "Bearer " + idToken,
    };

    if (method !== "GET") {
      headers["Content-Type"] = "application/json";
    }

    fetch(url, {
      headers,
      method,
      body: body ? JSON.stringify(body) : null,
    })
      .then((response) => {
        if (typeof response.ok === "undefined" || response.ok === false) {
          throw response;
        }

        return response.json();
      })
      .then((data) => {
        resolve({
          success: true,
          data: data,
        });
      })
      .catch((error) => {
        resolve({
          success: false,
          error: error,
        });
      });
  });
};

export default configurationsApi;

import { SerializedStyles, css } from "@emotion/react";
import { EyeClosed } from "@phosphor-icons/react";
import { theme } from "src/components/storybook/designSystemVariables";
import { SectionKey } from "../../AccordionSectionWrapper";
import { ItemTypes } from "../Menu";
import { BorderToIntegrateWithStyle, MenuItem } from "../MenuItem/MenuItem";

const iconSize = 16;

interface HiddenMenuItemProps {
  menuItemCss?: SerializedStyles;
  selected: boolean;
  borderToIntegrateWithStyle: BorderToIntegrateWithStyle;
  onClick: () => void;
  currentSection: SectionKey;
  availableSections: Record<SectionKey, boolean>;
  onMenuItemClick: (item: ItemTypes) => void;
  canInteractWithMenu: boolean;
}
export const HiddenMenuItem = (props: HiddenMenuItemProps) => {
  const hiddenAvailable = props.availableSections[SectionKey.hidden];

  const getEyeColor = () => {
    if (!hiddenAvailable) {
      return theme.textIcon.base.disabled;
    }
    if (props.currentSection === SectionKey.hidden) {
      return theme.textIcon.base.default;
    }
    return theme.textIcon.subtle.default;
  };

  const onMenuItemClick = () => {
    if (!hiddenAvailable) return;

    if (!props.canInteractWithMenu) return;

    props.onMenuItemClick(ItemTypes.Hidden);
    props.onClick();
  };

  return (
    <MenuItem
      menuItemCss={props.menuItemCss}
      selected={props.selected}
      borderToIntegrateWithStyle={props.borderToIntegrateWithStyle}
      onClick={onMenuItemClick}
    >
      <div
        css={[
          props.currentSection === SectionKey.hidden &&
            hiddenAvailable &&
            css`
              background: ${theme.background.subtle.default};
            `,
          css`
            height: 32px;
            width: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
            flex-direction: column;
            width: ${iconSize * 2}px;
            height: ${iconSize * 2}px;
            padding: ${iconSize / 2}px;
            cursor: ${hiddenAvailable ? "pointer" : "inherit"};
          `,
        ]}
      >
        <EyeClosed size={iconSize} color={getEyeColor()} weight="bold" />
      </div>
    </MenuItem>
  );
};

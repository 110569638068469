import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { CollectionType } from "src/api/types";
import { buildPathWithMerchantId, ROUTES } from "../router/Routes";
import useScollections from "../helpers/hooks/useScollections";
import { SidebarContext } from "./SidebarContext";

const ALLOWED_ROUTES_FOR_SELECT_MODE = [ROUTES.CATALOG];
export const ALLOWED_SCOLLECTION_TYPES_FOR_EDITING: CollectionType[] = [
  "category",
  "style",
];

function selectModeIsAllowed(pathname: string, merchantId?: string) {
  return ALLOWED_ROUTES_FOR_SELECT_MODE.some((route) =>
    pathname.includes(buildPathWithMerchantId(route, merchantId ?? ""))
  );
}

interface ListingEditorContextType {
  inSelectMode: boolean;

  selectedMainProductIds: Set<string>;
  setSelectedMainProductIds: (selectedMainProductIds: Set<string>) => void;

  selectedListingItemIds: Set<string>;
  indeterminateListingItemIds: Set<string>;

  mainProductIdsWithListings: Set<string>;
}

export const ListingEditorContext =
  createContext<ListingEditorContextType | null>(null);

export function ListingEditorProvider({
  merchantId,
  children,
}: {
  merchantId?: string;
  children: ReactNode;
}) {
  const [selectedMainProductIds, setSelectedMainProductIds] = useState<
    Set<string>
  >(new Set());

  const inSelectMode = selectedMainProductIds.size > 0;

  const { setIsCollapsed } = useContext(SidebarContext);

  useEffect(() => {
    if (inSelectMode) {
      setIsCollapsed(true);
    }
  }, [inSelectMode, setIsCollapsed]);

  const onExitSelectMode = () => {
    setSelectedMainProductIds(new Set());
  };

  const location = useLocation();
  useEffect(() => {
    if (inSelectMode && !selectModeIsAllowed(location.pathname, merchantId)) {
      onExitSelectMode();
    }
  }, [location.pathname, merchantId, inSelectMode]);

  useEffect(() => {
    onExitSelectMode();
  }, [merchantId]);

  const { scollections } = useScollections();

  const selectedListingItems = (scollections ?? []).filter((item) =>
    item.manual_main_product_ids.some((p) => selectedMainProductIds.has(p))
  );

  const indeterminateListingItemIds = new Set(
    selectedListingItems
      .filter(
        (item) =>
          !Array.from(selectedMainProductIds).every((p) =>
            item.manual_main_product_ids.includes(p)
          )
      )
      .map((item) => item.collection_id)
  );

  const mainProductIdsWithListings = new Set(
    (scollections ?? []).flatMap((item) => item.manual_main_product_ids)
  );

  return (
    <ListingEditorContext.Provider
      value={{
        inSelectMode,
        selectedMainProductIds,
        setSelectedMainProductIds,
        indeterminateListingItemIds,
        selectedListingItemIds: new Set(
          selectedListingItems.map((item) => item.collection_id)
        ),
        mainProductIdsWithListings,
      }}
    >
      {children}
    </ListingEditorContext.Provider>
  );
}

export function useListingEditorContext(merchantId?: string) {
  const context = useContext(ListingEditorContext);
  if (!context) {
    throw new Error(
      "useListingEditorContext must be used within a ListingEditorProvider"
    );
  }

  const {
    inSelectMode,
    selectedMainProductIds,
    setSelectedMainProductIds,
    indeterminateListingItemIds,
    selectedListingItemIds,
    mainProductIdsWithListings,
  } = context;

  return {
    inSelectMode,
    selectedMainProductIds,
    setSelectedMainProductIds,
    indeterminateListingItemIds,
    selectedListingItemIds,
    mainProductIdsWithListings,
  };
}

import { useContext, useMemo } from "react";
import { Market } from "src/api/types";
import { PathContext } from "src/contexts/PathContext";
import useMarkets from "./useMarkets";

interface UseFilteredMarketsReturn {
  markets: Market[];
  loading: boolean;
}

/**
 * useFilteredMarkets is a React hook that provides a convenient way to access
 * the list of markets for the current merchant, filtered by the
 * marketAvailableFilter (that's dependent on the Route).
 *
 * For example, you only want to display the "global" market when configuring categories.
 * @returns
 */
export function useFilteredMarkets(
  merchantId: string
): UseFilteredMarketsReturn {
  const { markets, loading } = useMarkets(merchantId);
  const pathContext = useContext(PathContext);

  if (!pathContext) throw new Error("PathContext is not defined");

  const { config } = pathContext;
  const { marketAvailableFilter } = config;

  const filteredMarkets = useMemo(() => {
    if (loading) return [];

    const filter = marketAvailableFilter;

    if (filter === null) return markets;

    return markets.filter(filter);
  }, [markets, marketAvailableFilter, loading]);

  return { markets: filteredMarkets, loading };
}

export default useFilteredMarkets;

import { css } from "@emotion/react";
import { theme } from "src/components/storybook/designSystemVariables";
import { SectionKey } from "../AccordionSectionWrapper";
import { HiddenMenuItem } from "./HiddenMenuItem/HiddenMenuItem";
import { BorderToIntegrateWithStyle } from "./MenuItem/MenuItem";
import { MerchandizableMenuItem } from "./MerchandizableMenuItem/MerchandizableMenuItem";
import { CategoryRankingOrderOptionType } from "src/components/storybook/Categories/CategoryRankingOrder/CategoryRankingOrderOption";

export enum ItemTypes {
  Merchandizable,
  Hidden,
}

interface MenuCBs {
  onClickOnPinned: () => void;
  onClickOnDepictSorted: () => void;
  onClickOnHidden: () => void;
  onMenuItemClick: (item: ItemTypes) => void;
}

interface MenuProps {
  borderToIntegrateWithStyle?: BorderToIntegrateWithStyle;
  menuCBs: MenuCBs;
  selectedItem: ItemTypes;
  chevronOpen: boolean;
  onChevronClick: () => void;
  currentSection: SectionKey;
  availableSections: Record<SectionKey, boolean>;
  rankingType: CategoryRankingOrderOptionType;
  canInteractWithMenu: boolean;
}
export const Menu = (props: MenuProps) => {
  const { menuCBs, selectedItem, availableSections } = props;
  const {
    onClickOnPinned,
    onClickOnDepictSorted,
    onClickOnHidden,
    onMenuItemClick,
  } = menuCBs;

  const borderToIntegrateWithStyle = props.borderToIntegrateWithStyle ?? {
    borderWeight: 1,
    borderColor: theme.border.subtle.disabled,
  };

  const menuItemStyle = css`
    background-color: white;
  `;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: start;
        position: relative;
        transform: translate(0px, ${borderToIntegrateWithStyle.borderWeight}px);
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
      `}
    >
      <MerchandizableMenuItem
        selected={selectedItem === ItemTypes.Merchandizable}
        menuItemCss={menuItemStyle}
        borderToIntegrateWithStyle={borderToIntegrateWithStyle}
        onClickOnDepictSorted={onClickOnDepictSorted}
        onClickOnPinned={onClickOnPinned}
        chevronOpen={props.chevronOpen}
        onChevronClick={props.onChevronClick}
        currentSection={props.currentSection}
        availableSections={availableSections}
        onMenuItemClick={onMenuItemClick}
        rankingType={props.rankingType}
        canInteractWithMenu={props.canInteractWithMenu}
      />
      <HiddenMenuItem
        selected={selectedItem === ItemTypes.Hidden}
        menuItemCss={menuItemStyle}
        borderToIntegrateWithStyle={borderToIntegrateWithStyle}
        onClick={onClickOnHidden}
        currentSection={props.currentSection}
        availableSections={availableSections}
        onMenuItemClick={onMenuItemClick}
        canInteractWithMenu={props.canInteractWithMenu}
      />
    </div>
  );
};

import { BoostRule } from "src/api/types";

export function getBoostRuleMetadata(rule: BoostRule) {
  switch (rule.type) {
    case "out_of_stock":
      return {
        title: "Out of Stock",
        boostTypeLabel: "Inventory",
      };
    case "new_products":
      return {
        title: "New In",
        boostTypeLabel: "Inventory", // Don't really know what to write here, TODO: revamp UI/naming?
      };
    case "new_products_proportional":
      return {
        title: "New In (Proportional)",
        boostTypeLabel: "Inventory", // Don't really know what to write here, TODO: revamp UI/naming?
      };
    case "best_selling":
      return {
        title: "Bestseller",
        boostTypeLabel: "Inventory", // Don't really know what to write here, TODO: revamp UI/naming?
      };
    case "on_sale":
      return {
        title: "On Sale",
        boostTypeLabel: "Pricelist", // Don't really know what to write here, TODO: revamp UI/naming?
      };
    case "few_sizes_available":
      return {
        title: "Few sizes available",
        boostTypeLabel: "Inventory", // Don't really know what to write here, TODO: revamp UI/naming?
      };
    case "scollection":
      return {
        title: rule.collection_title,
        boostTypeLabel: "Campaign",
      };
    default:
      return {
        title: "Unknown rule",
        boostTypeLabel: "",
      };
  }
}

import useMerchant from "../../helpers/hooks/app/useMerchant";
import React from "react";
import ProtectedRoute from "../../router/ProtectedRoute";
import { css } from "@emotion/react";
import ContentSearch from "../../components/storybook/ContentSearch/ContentSearch";
import "./Search.scss";
import { useSearchParams } from "react-router-dom";
import { TabButton } from "../../components/storybook/CatalogV2/CatalogTabButton/CatalogTabButton";
import Synonyms from "../../components/storybook/Synonyms/Synonyms";
import SearchOverview from "../../components/storybook/SearchOverview/SearchOverview";
import useUser from "src/helpers/hooks/useUser";

const Search = () => {
  const { merchant, merchantId } = useMerchant();
  const { user } = useUser();

  const [searchParams] = useSearchParams();
  const currentView: "overview" | "content" | "synonyms" =
    (searchParams.get("view") as any) ?? "overview";

  const hasModernSearch =
    merchant?.product_status?.search_state !== "inactive" &&
    merchant?.product_status?.search_state !== "legacy";

  return (
    <ProtectedRoute
      user={user}
      merchant={merchant}
      accessValidator={(u, m) =>
        !!u.is_superuser ||
        !!m.access_content_search ||
        merchantId === "artilleriet"
      }
    >
      <div
        css={css`
          background: white;
          margin: 8px 8px 8px 0;
          border-radius: 16px;
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            padding: 16px 16px 0 16px;
          `}
        >
          <div className="d-flex align-items-center justify-content-between">
            <h1
              css={css`
                margin-left: 8px;
                font-size: 36px;
                font-weight: 700;
              `}
            >
              Search
            </h1>
          </div>

          <div className="ms-2 mb-4 d-flex">
            <TabButton
              active={currentView === "overview"}
              to={`/${merchant?.id}/search?view=overview`}
            >
              Overview
            </TabButton>

            {hasModernSearch && (
              <>
                <TabButton
                  active={currentView === "content"}
                  to={`/${merchant?.id}/search?view=content`}
                >
                  Content
                </TabButton>
                <TabButton
                  active={currentView === "synonyms"}
                  to={`/${merchant?.id}/search?view=synonyms`}
                >
                  Synonyms
                </TabButton>
              </>
            )}
          </div>
          <div className="depict--MainContent depict--Search d-flex flex-column position-relative h-100">
            <div className="depict--Body d-flex flex-column h-100">
              <div className="rounded-3 d-flex flex-column">
                {currentView === "overview" && <SearchOverview />}
                {currentView === "content" && hasModernSearch && (
                  <ContentSearch />
                )}
                {currentView === "synonyms" && hasModernSearch && <Synonyms />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ProtectedRoute>
  );
};

export default React.memo(Search);

import { ArrowDown, ArrowUp, LinkBreak, Trash } from "@phosphor-icons/react";
import { useMemo } from "react";
import { Row } from "react-bootstrap";
import { MetadataInner } from "src/api/types";
import ProductEmptyImage from "src/assets/img/product-empty.svg";
import { ProductMetadata } from "../../../../helpers/catalog/ProductMetadataProvider";
import { useCatalogMetadata } from "../../../../helpers/hooks/catalog/useCatalogMetadata";
import Dropdown from "../../Dropdown/Dropdown";
import { useImageResize } from "../../ImageResizeProvider/ImageResizeProvider";
import ProductBadge from "../../ProductBadge/ProductBadge";
import {
  DELETE_TEXT,
  MOVE_TO_BEGINNING_TEXT,
  MOVE_TO_END_TEXT,
  PairTextProps,
} from "../../SortableComponents/pairText";
import { Checkbox } from "../../Checkbox/Checkbox";
import { OnSelect } from "../../BulkEditBar/BulkEditBar.types";
import useSelectionHandler from "src/helpers/hooks/useSelectionHandler";
import { noSelectCss } from "../../css.helper";

export const IMAGE_SIZE = "45px";

export interface ListCardProps extends PairTextProps {
  title: string;
  image_url?: string | null;
  onClick?: () => void;
  text?: string;
  maxWidth?: string;
  index: number;
  main_product_id: string;
  disableDropdown?: boolean;
  disableIndex?: boolean;
  onDelete?: (mainProductId: string) => void;
  onPushToTop?: (mainProductId: string) => void;
  onPushToBottom?: (mainProductId: string) => void;
  onUnpair?: (mainProductId: string) => void;
  showSelectCheckbox?: boolean;
  selected?: boolean;
  onSelectClick?: OnSelect;
  isInSelectMode: boolean;
}

type MetadataType = MetadataInner[number]["type"];

const collectionRelevantMetadataTypes: MetadataType[] = [
  "out_of_stock",
  "low_stock",
  "on_sale",
  "new_in",
  "bestseller",
  "trending",
  "slow_mover",
  "inactive",
];

export const CollectionProductListCard = (props: ListCardProps) => {
  const selectionHandler = useSelectionHandler(
    props.onSelectClick,
    Boolean(props.selected),
    props.main_product_id
  );
  const { getImageUrl } = useImageResize();
  const oneBasedIndex = props.index + 1;
  const index = oneBasedIndex.toString().padStart(2, "0");
  const { productMetadata: allProductMetadata } = useCatalogMetadata();
  const productMetadata: ProductMetadata[] = useMemo(() => {
    return allProductMetadata?.[props.main_product_id] ?? [];
  }, [allProductMetadata, props.main_product_id]);
  const collectionRelevantMetadata = productMetadata.filter((md) =>
    collectionRelevantMetadataTypes.includes(md.type)
  );

  const dropdownItems = useMemo(() => {
    const items = [
      {
        text: props.moveToBeginningText || MOVE_TO_BEGINNING_TEXT,
        onClick: () => props.onPushToTop?.(props.main_product_id),
        icon: <ArrowUp />,
      },
      {
        text: props.moveToEndText || MOVE_TO_END_TEXT,
        onClick: () => props.onPushToBottom?.(props.main_product_id),
        icon: <ArrowDown />,
      },
      {
        text: props.deleteText || DELETE_TEXT,
        onClick: () => props.onDelete?.(props.main_product_id),
        icon: <Trash />,
        color: "red",
      },
    ];
    if (typeof props.onUnpair !== "undefined") {
      items.push({
        text: "Unpair",
        onClick: () => props.onUnpair?.(props.main_product_id),
        icon: <LinkBreak />,
      });
    }
    return items;
  }, [props]);

  const dropdown = props.disableDropdown ? (
    <></>
  ) : (
    <Dropdown
      key_={props.main_product_id}
      closeWhenOtherDropdownOpens={true}
      items={dropdownItems}
      disabled={props.isInSelectMode}
    />
  );

  return (
    // Border just there so you can see the shape
    <div
      className="d-flex bg-white rounded pt-2 pb-2 px-0 align-items-stretch w-100 justify-content-space-between gap-3"
      onClick={selectionHandler}
      style={{
        outline: props.selected ? `2px solid black` : "none",
      }}
      css={noSelectCss}
    >
      <div className="d-flex align-items-center gap-3">
        {props.showSelectCheckbox && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Checkbox
              checked={Boolean(props.selected)}
              onChange={(alreadyChecked) => {
                props.onSelectClick?.(props.main_product_id, alreadyChecked);
              }}
              style={{ margin: 0, marginLeft: "1em" }}
            />
          </div>
        )}
        {!props?.disableIndex && (
          <strong className="d-flex justify-content-center text-primary">
            {index}
          </strong>
        )}
        <div style={{ width: IMAGE_SIZE, height: IMAGE_SIZE }}>
          <img
            alt={props.title}
            className="rounded"
            loading={"lazy"}
            src={getImageUrl(props.image_url ?? "")}
            style={{
              width: IMAGE_SIZE,
              height: IMAGE_SIZE,
              objectFit: "cover",
            }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = ProductEmptyImage;
            }}
          />
        </div>
      </div>
      <div
        style={{
          flexGrow: "1",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        <Row>
          <strong
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            className="text-bold text-primary"
          >
            {props.title}
          </strong>
        </Row>
        <Row>
          <small className="text-muted">{props.main_product_id}</small>
        </Row>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <div className="d-flex align-items-center gap-1 nowrap">
          {collectionRelevantMetadata?.map((md, index) => (
            <ProductBadge metadata={md} key={`${md.type}-${index}`} />
          ))}
        </div>
        <div data-no-dnd="true">{dropdown}</div>
      </div>
    </div>
  );
};

export default CollectionProductListCard;

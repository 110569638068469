 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const FingersCrossed = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M18.23 8.447c-0.42-0.328-0.944-0.472-1.474-0.404s-1 0.339-1.324 0.762l-1.222 1.598-1.085-3.837 1.397-2.628c0.251-0.472 0.303-1.013 0.147-1.524s-0.502-0.93-0.974-1.181c-0.774-0.412-1.7-0.261-2.307 0.308-0.494-0.449-1.199-0.647-1.886-0.463-0.514 0.138-0.945 0.47-1.211 0.934s-0.337 1.003-0.197 1.517l0.715 2.636-1.325 2.493c-0.366-0.404-0.895-0.658-1.482-0.658-0.871 0-1.614 0.56-1.887 1.339-0.318-0.214-0.701-0.339-1.113-0.339-1.103 0-2 0.897-2 2v2c0 1.404 0.559 3.115 1.459 4.465 1.090 1.635 2.525 2.535 4.041 2.535h4c1.083 0 2.204-0.662 3.426-2.025 0.967-1.077 1.811-2.388 2.428-3.345 0.216-0.336 0.403-0.625 0.556-0.845l1.746-2.505c0.622-0.893 0.431-2.164-0.426-2.834zM13.225 2.116c0.236 0.125 0.409 0.335 0.487 0.591s0.052 0.526-0.073 0.762l-0.908 1.707-0.762-2.697c-0.007-0.023-0.014-0.046-0.021-0.068 0.288-0.396 0.833-0.531 1.278-0.294zM5 10c0-0.551 0.449-1 1-1s1 0.449 1 1v3c0 0.551-0.449 1-1 1s-1-0.449-1-1v-3zM2 11c0-0.551 0.449-1 1-1s1 0.449 1 1v2c0 0.551-0.449 1-1 1s-1-0.449-1-1v-2zM17.836 10.709l-1.746 2.505c-0.163 0.234-0.355 0.531-0.576 0.875-1.106 1.716-3.164 4.911-5.014 4.911h-4c-1.58 0-2.689-1.309-3.209-2.090-0.408-0.611-0.74-1.32-0.966-2.027 0.211 0.076 0.438 0.117 0.675 0.117 0.597 0 1.133-0.263 1.5-0.679 0.367 0.416 0.903 0.679 1.5 0.679 1.103 0 2-0.897 2-2v-3c0-0.058-0.003-0.115-0.008-0.172l1.197-2.252 0.828 3.055c0.072 0.267 0.347 0.424 0.613 0.352s0.424-0.347 0.352-0.613l-1.925-7.101c-0.069-0.256-0.034-0.525 0.099-0.757s0.348-0.398 0.604-0.467c0.536-0.144 1.094 0.173 1.245 0.707l2.512 8.885c0.052 0.182 0.201 0.32 0.387 0.355s0.376-0.037 0.491-0.187l1.829-2.391c0.161-0.21 0.394-0.344 0.657-0.378s0.523 0.037 0.731 0.2c0.439 0.343 0.54 1.018 0.222 1.474z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(FingersCrossed); 
import { Button } from "react-bootstrap";
import { CollectionType } from "src/api/types";
import { ListingItem } from "./types";
import { ItemTypes } from "./ListingTreeItem";
import { DropTargetMonitor, useDrop } from "react-dnd";
import { css } from "@emotion/react";

export function getFolderName(type: CollectionType | null): string | null {
  switch (type) {
    case "smart_pick":
      return "Smart Picks";
    case "style":
      return "Styles";
    case "category":
      return "Categories";
    case "campaign":
      return "Campaigns";
    case "brand":
      return "Brands";
    case null:
      return null;
    default:
      return (
        type.toString().charAt(0).toUpperCase() +
        type.toString().slice(1).toLowerCase().replace(/_/g, " ")
      );
  }
}

const ASSIGNABLE_TYPES: CollectionType[] = ["category", "style"];

export default function ListingTreeTypeButton({
  type,
  filteredType,
  onSetFilteredType,
  onSetType,
}: {
  type: CollectionType;
  filteredType: CollectionType | null;
  onSetFilteredType: (value: CollectionType | null) => void;
  onSetType: (value: CollectionType, listingItemId: string) => void;
}) {
  const [{ isOver, canDrop, isDragging }, drop] = useDrop(() => ({
    accept: ItemTypes.LISTING_ITEM,
    canDrop: (item: { listingItem: ListingItem }) => {
      return item.listingItem.type !== type && ASSIGNABLE_TYPES.includes(type);
    },
    drop: (item: { listingItem: ListingItem }, monitor) => {
      if (!monitor.didDrop()) {
        onSetType(type, item.listingItem.id);
      }
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver({
        shallow: true,
      }),
      canDrop: monitor.canDrop(),
      isDragging: monitor.getItemType() === ItemTypes.LISTING_ITEM,
    }),
  }));

  return (
    <Button
      ref={drop}
      variant="secondary"
      className={`p-1 m-0 rounded-pill me-1`}
      css={css`
        opacity: ${isDragging && !canDrop ? 0.25 : 1};
        ${canDrop && isOver && "border: 1px solid #3018C1;"}
      `}
      style={{ fontSize: "10px", height: "24px" }}
      onClick={() => {
        onSetFilteredType(type);
      }}
    >
      {getFolderName(type)}
    </Button>
  );
}

//eslint-disable-next-line
export default {
  tooltips: {
    period: {
      thisYear: "From 1 Jan to yesterday.",
      oneYear: "Last 365 days.",
    },
    compare: {
      "same-last-year":
        "Year-over-Year, same period the previous calendar year.",
      custom: "Define your own custom period to compare to.",
      filterDisabled: "This combination is not possible.",
      comingSoon: "Coming soon",
    },
    splitBySurface: {
      active:
        "Show data for each recommendation surface, i.e. related & cross-sell.",
      disabled:
        "Split by surface is not currently available when comparing. Coming soon.",
    },
    highlights: {
      revenue:
        "Revenue attributed to recommendations, where a user has clicked on a product recommendation and later purchased that item.",
      revenuePercentage:
        "Revenue attributed to recommendations / Total revenue.",
      conversionRate:
        "Unique purchases attributed to recommendations / clicks on recommendations.",
    },
    graphs: {
      revenue: {
        title: "Revenue through Depict.",
        x: "Only full days. Today excluded.",
        y: "Y-axis: Revenue through Depict.",
      },
      revenuePercentage: {
        title: "Revenue through Depict as a % of total revenue.",
        noRevenue:
          "The line is non-continuous as total revenue is unavailable for some data points.",
        x: "Only full days. Today excluded.",
        y: "Y-axis: Revenue through Depict as a % of total revenue.",
      },
      clicks: {
        title: "How many product recommendations users have clicked.",
      },
      addsToCart: {
        title:
          "How many products users have added to cart after clicking that recommendation.",
      },
      checkout: {
        title:
          "How many products users taken to checkout after clicking that recommendation.",
      },
      uniquePurchases: {
        title:
          "How many items have been purchased after the user clicked that recommendation.",
      },
    },
    table: {
      headers: {
        purchases:
          "Where a user has clicked on a product recommendation and later purchased that item.",
        conversionRate:
          "Unique purchases attributed to recommendations / clicks on recommendations.",
        revenue:
          "Revenue attributed to recommendations, where a user has clicked on a product recommendation and later purchased that item.",
        uniquePurchases:
          "How many items have been purchased after the user clicked that recommendation.",
        checkout:
          "How many products users taken to checkout after clicking that recommendation.",
        clicks: "How many product recommendations users have clicked.",
        addsToCart:
          "How many products users have added to cart after clicking that recommendation.",
      },
    },
    configuration: {
      aicontrol:
        "If disabled AI will not add any recommendations, only products in ‘Always show’ will be recommended.",
      schedule: {
        inactive:
          "When saved as ‘Inactive’, the configuration will not affect your site",
        indefinite:
          "When saved as  ‘Indefinite’, the configuration will be active until you change it",
      },
      panels: {
        from: "Controls which sub-set of surfaces are affected. It has no effect on some surfaces (i.e. Front Page).",
      },
      toolbar: {
        restore: "Reset changes",
        delete: "Delete configuration",
        save: {
          inactive: "Save configuration as inactive / draft.",
          indefinite: "Save and deploy configuration.",
          scheduled: "Save and schedule configuration for deployment.",
        },
      },
    },
  },
  filters: {
    compare: {
      buttons: {
        previous: { regular: "Previous" },
        average: { regular: "Average" },
        "same-last-year": { regular: "Year-over-year", compressed: "YoY" },
        custom: { regular: "Custom" },
        none: { regular: "None" },
      },
    },
    period: {
      buttons: {
        "this-year": { regular: "This year" },
        "30-days": { regular: "30 days" },
        "90-days": { regular: "90 days" },
        "one-year": { regular: "Year" },
        "since-start": { regular: "Since start" },
        custom: { regular: "Custom" },
        month: { regular: "Month" },
        quarter: { regular: "Quarter" },
      },
    },
  },
  configuration: {
    state: {
      label: {
        indefinite: "Active",
        scheduled: "Scheduled",
        inactive: "Inactive",
      },
    },
  },
  general: {},
};

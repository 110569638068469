import { useCallback, useEffect, useState } from "react";
import productMiddleware from "src/api/productMiddleware";

import { getData } from "src/api/authorizedApi";
import { getAlertMessage } from "src/helpers/errors";
import { ProductsApiResult } from "src/types/api";
import { ControlLoadingQueueCB } from "src/types/components";
import { MainProduct, Product } from "src/types/products";
import { Merchant } from "src/api/types";
import { useAlerts } from "../../../components/storybook/Alert/useAlerts";
import useAuthentication from "../app/useAuthentication";
import useAuthorizedApi from "../app/useAuthorizedApi";

type UseProducts = [products: Array<MainProduct | Product> | null];

export default function useProducts(
  merchant: Merchant | null,
  market: string | null,
  controlLoadingQueueCB: ControlLoadingQueueCB
) {
  const { addAlert } = useAlerts();
  const [products, setProducts] = useState<Array<MainProduct | Product> | null>(
    null
  );
  const [getAccessToken] = useAuthentication();
  const { api } = useAuthorizedApi();

  const loadMerchantMarketData = useCallback(
    async (merchant: string, market: string) => {
      controlLoadingQueueCB(
        `load-merchant-market-products-${merchant}-${market}`,
        "push"
      );
      const id_token = await getAccessToken();
      const products = (await productMiddleware.get(
        id_token,
        merchant,
        market
      )) as ProductsApiResult;

      if (!products.success) {
        let message = await getAlertMessage(products.error);
        addAlert(message);
        controlLoadingQueueCB(
          `load-merchant-market-products-${merchant}-${market}`,
          "remove"
        );
        return;
      }

      controlLoadingQueueCB(
        `load-merchant-market-products-${merchant}-${market}`,
        "remove"
      );
      return products.data;
    },
    [controlLoadingQueueCB, getAccessToken, addAlert]
  );

  // When all is migrated, we should use useQuery here instead
  const loadProductsForNoCodeMerchants: (
    merchantId: string
  ) => Promise<MainProduct[] | undefined> = useCallback(
    async (merchantId: string) => {
      if (!api) return Promise.resolve(undefined);

      try {
        const response = await api.GET("/api/v0/catalog/products/", {
          params: {
            query: {
              merchant_id: merchantId,
              include_deleted: true,
            },
          },
        });

        const data = getData(response);

        return data?.map(
          (product) =>
            ({
              // @ts-ignore
              main_product_id: product.main_product_id ?? product.variant_id,
              title: product.title,
              image_urls: product.image_url ? [product.image_url] : [],
              in_stock: true, // TODO: Return stock status from API
              deleted: !!product.deleted_at,
            } as MainProduct)
        );
      } catch (error) {
        console.error(
          `%c[useCategoryProducts] Failed to load products for merchant ${merchantId}`,
          "color: darkred"
        );
        let message = await getAlertMessage(error as Response);
        addAlert(message);
        return [];
      }
    },
    [addAlert, api]
  );

  useEffect(() => {
    let canceledRef = { canceled: false };
    if (!merchant || !market || !api) {
      setProducts(null);
      return;
    }

    if (merchant.no_code_config) {
      loadProductsForNoCodeMerchants(merchant.id).then((products) => {
        if (!canceledRef.canceled) {
          setProducts(products ?? null);
          console.log(
            `%c[useCategoryProducts] Product update complete for merchant ${merchant} - ${market}`,
            "color: lightgreen"
          );
        } else {
          console.log(
            `%c[useCategoryProducts] Product update canceled for merchant ${merchant} - ${market}`,
            "color: lightcoral"
          );
        }
      });
    } else {
      loadMerchantMarketData(merchant.id, market).then((products) => {
        if (!canceledRef.canceled) {
          setProducts(products ?? null);
          console.log(
            `%c[useCategoryProducts] Product update complete for merchant ${merchant} - ${market}`,
            "color: lightgreen"
          );
        } else {
          console.log(
            `%c[useCategoryProducts] Product update canceled for merchant ${merchant} - ${market}`,
            "color: lightcoral"
          );
        }
      });
    }

    return () => {
      canceledRef.canceled = true;
      controlLoadingQueueCB(
        `load-merchant-market-products-${merchant}-${market}`,
        "remove"
      );
    };
  }, [
    addAlert,
    controlLoadingQueueCB,
    loadMerchantMarketData,
    loadProductsForNoCodeMerchants,
    market,
    merchant,
    api,
  ]);

  return [products] as UseProducts;
}

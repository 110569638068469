import { css } from "@emotion/react";
import { Sparkle } from "@phosphor-icons/react";
import { Transition, motion } from "framer-motion";
import { theme } from "src/components/storybook/designSystemVariables";

const duration = 0.5;
const repeat = Infinity;
const repeatType = "reverse";
const transition: Transition = {
  duration,
  repeat,
  repeatType,
} as const;

const divAnimation = {
  initial: { opacity: 1 },
  animate: {
    opacity: 0.5,
    transition,
  },
} as const;

const iconAnimation = {
  initial: { scale: 1 },
  animate: {
    scale: 24 / 32, // Scaling down from 32px to 24px
    transition,
  },
} as const;

export const LoadingIndicator = () => {
  return (
    <motion.div
      css={css`
        height: 100%;
        width: 100%;
        display: grid;
        place-items: center;
        border-radius: 16px;
        border: 1px solid #879be5;
        background: linear-gradient(
            219deg,
            rgba(135, 155, 229, 0.11) 0%,
            rgba(175, 149, 232, 0) 100%
          ),
          #fff;
      `}
      initial={divAnimation.initial}
      animate={divAnimation.animate}
    >
      <motion.div
        initial={iconAnimation.initial}
        animate={iconAnimation.animate}
      >
        <Sparkle
          size={32}
          color={theme.textIcon.accent.default}
          weight="fill"
        />
      </motion.div>
    </motion.div>
  );
};

import { SerializedStyles, css } from "@emotion/react";
import { CaretDown, CaretUp, PushPin, Sparkle } from "@phosphor-icons/react";
import { theme } from "src/components/storybook/designSystemVariables";
import { SectionKey } from "../../AccordionSectionWrapper";
import { ItemTypes } from "../Menu";
import { BorderToIntegrateWithStyle, MenuItem } from "../MenuItem/MenuItem";
import {
  CategoryRankingOrderOptionType,
  typeToTitle,
} from "src/components/storybook/Categories/CategoryRankingOrder/CategoryRankingOrderOption";

interface MerchandizableMenuItemProps {
  menuItemCss?: SerializedStyles;
  selected: boolean;
  borderToIntegrateWithStyle: BorderToIntegrateWithStyle;
  onClickOnPinned: () => void;
  onClickOnDepictSorted: () => void;
  chevronOpen: boolean;
  onChevronClick: () => void;
  currentSection: SectionKey;
  availableSections: Record<SectionKey, boolean>;
  onMenuItemClick: (item: ItemTypes) => void;
  rankingType: CategoryRankingOrderOptionType;
  canInteractWithMenu: boolean;
}
export const MerchandizableMenuItem = (props: MerchandizableMenuItemProps) => {
  const pinnedAvailable = props.availableSections[SectionKey.pinned];
  const relevanceAvailable = props.availableSections[SectionKey.relevance];

  const getPinColor = () => {
    if (!pinnedAvailable) {
      return theme.textIcon.base.disabled;
    }
    if (props.currentSection === SectionKey.pinned) {
      return theme.textIcon.base.default;
    }

    return theme.textIcon.subtle.default;
  };

  const getRankedIconColor = () => {
    if (props.currentSection === SectionKey.relevance) {
      return theme.textIcon.accent.default;
    }
    if (!relevanceAvailable) {
      return theme.textIcon.base.disabled;
    }
    return theme.textIcon.subtle.default;
  };

  const getRankedBackground = () => {
    if (props.chevronOpen) {
      return theme.background.accent.default;
    }
    if (!relevanceAvailable) {
      return undefined;
    }
    if (props.currentSection === SectionKey.relevance) {
      return theme.background.subtle.default;
    }

    return undefined;
  };

  const getRankedTextColor = () => {
    if (props.currentSection === SectionKey.relevance) {
      return theme.textIcon.base.default;
    }
    if (!relevanceAvailable) {
      return theme.textIcon.base.disabled;
    }
    return theme.textIcon.base.default;
  };

  const onMenuItemClick = () => {
    if (!props.canInteractWithMenu) {
      return;
    }

    props.onMenuItemClick(ItemTypes.Merchandizable);
  };

  const onClickOnPinned = () => {
    if (!props.canInteractWithMenu) {
      return;
    }

    if (pinnedAvailable) {
      props.onClickOnPinned();
    }
  };

  const onChevronClick = () => {
    if (!props.canInteractWithMenu) {
      return;
    }

    if (relevanceAvailable) {
      props.onChevronClick();
    }
  };

  const onClickOnDepictSorted = () => {
    if (!props.canInteractWithMenu) {
      return;
    }

    if (relevanceAvailable) {
      props.onClickOnDepictSorted();
    }
  };

  return (
    <MenuItem
      menuItemCss={props.menuItemCss}
      selected={props.selected}
      borderToIntegrateWithStyle={props.borderToIntegrateWithStyle}
      onClick={onMenuItemClick}
    >
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <div
          css={[
            props.currentSection === SectionKey.pinned &&
              pinnedAvailable &&
              css`
                background: ${theme.background.subtle.default};
              `,
            css`
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 8px;
              cursor: pointer;
            `,
          ]}
          onClick={onClickOnPinned}
        >
          <PushPin weight="fill" color={getPinColor()} />
        </div>
        <div
          css={[
            css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-end;
              padding: 8px;
              border-radius: 4px;
              background: ${getRankedBackground()};
              cursor: ${relevanceAvailable ? "pointer" : "inherit"};
            `,
          ]}
        >
          <div
            css={css`
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              gap: 8px;
            `}
            onClick={onClickOnDepictSorted}
          >
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
              `}
            >
              <Sparkle weight="fill" color={getRankedIconColor()} />
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 2px;
              `}
            >
              <div
                css={css`
                  overflow: hidden;
                  color: var(--text-icon-base-default, #0f0f0f);
                  font-feature-settings: "clig" off, "liga" off;
                  text-overflow: ellipsis;

                  /* paragraph/300/Light */
                  font-family: Inter;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 16px; /* 133.333% */
                  color: ${getRankedTextColor()};
                `}
              >
                {typeToTitle[props.rankingType]}
              </div>
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 16px;
                  height: 16px;
                `}
                onClick={onChevronClick}
              >
                {props.chevronOpen ? (
                  <CaretUp size={12} weight="bold" />
                ) : (
                  <CaretDown size={12} weight="bold" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </MenuItem>
  );
};

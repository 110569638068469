 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Vault = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M18.361 2.391l-0.891-1.336c-0.401-0.601-1.248-1.055-1.971-1.055h-12c-0.723 0-1.57 0.453-1.971 1.055l-0.891 1.336c-0.358 0.537-0.639 1.464-0.639 2.109v14c0 0.827 0.673 1.5 1.5 1.5h16c0.827 0 1.5-0.673 1.5-1.5v-14c0-0.646-0.281-1.572-0.639-2.109zM2.361 1.609c0.213-0.319 0.755-0.609 1.139-0.609h12c0.383 0 0.926 0.29 1.139 0.609l0.891 1.336c0.012 0.018 0.024 0.037 0.036 0.056-0.022-0.001-0.043-0.002-0.065-0.002h-16c-0.022 0-0.043 0.001-0.065 0.002 0.012-0.019 0.024-0.038 0.036-0.056l0.891-1.336zM18 18.5c0 0.276-0.224 0.5-0.5 0.5h-16c-0.276 0-0.5-0.224-0.5-0.5v-14c0-0.276 0.224-0.5 0.5-0.5h16c0.276 0 0.5 0.224 0.5 0.5v14z" fill={color}></path>
<path d="M16.5 11c0.276 0 0.5-0.224 0.5-0.5v-3c0-0.276-0.224-0.5-0.5-0.5h-0.5v-0.5c0-0.827-0.673-1.5-1.5-1.5h-11c-0.827 0-1.5 0.673-1.5 1.5v10c0 0.827 0.673 1.5 1.5 1.5h11c0.827 0 1.5-0.673 1.5-1.5v-0.5h0.5c0.276 0 0.5-0.224 0.5-0.5v-3c0-0.276-0.224-0.5-0.5-0.5h-0.5v-1h0.5zM16 10h-1v-2h1v2zM16 15h-1v-2h1v2zM15 12h-0.5c-0.276 0-0.5 0.224-0.5 0.5v3c0 0.276 0.224 0.5 0.5 0.5h0.5v0.5c0 0.276-0.224 0.5-0.5 0.5h-11c-0.276 0-0.5-0.224-0.5-0.5v-10c0-0.276 0.224-0.5 0.5-0.5h11c0.276 0 0.5 0.224 0.5 0.5v0.5h-0.5c-0.276 0-0.5 0.224-0.5 0.5v3c0 0.276 0.224 0.5 0.5 0.5h0.5v1z" fill={color}></path>
<path d="M8.5 13c-0.827 0-1.5-0.673-1.5-1.5 0-0.206 0.059-0.581 0.567-1.458 0.257-0.443 0.51-0.811 0.521-0.826 0.093-0.135 0.247-0.216 0.411-0.216s0.318 0.081 0.411 0.216c0.011 0.015 0.264 0.383 0.521 0.826 0.509 0.877 0.567 1.252 0.567 1.458 0 0.827-0.673 1.5-1.5 1.5zM8.5 10.427c-0.288 0.485-0.496 0.921-0.5 1.074 0 0.275 0.224 0.499 0.5 0.499s0.5-0.224 0.5-0.5c-0.004-0.153-0.212-0.588-0.5-1.073z" fill={color}></path>
<path d="M8.5 7c-2.481 0-4.5 2.019-4.5 4.5s2.019 4.5 4.5 4.5c2.481 0 4.5-2.019 4.5-4.5s-2.019-4.5-4.5-4.5zM11.302 13.595l-0.449-0.449c-0.195-0.195-0.512-0.195-0.707 0s-0.195 0.512 0 0.707l0.449 0.449c-0.458 0.343-1.003 0.577-1.595 0.662v-0.464c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v0.464c-0.592-0.085-1.137-0.319-1.595-0.662l0.449-0.449c0.195-0.195 0.195-0.512 0-0.707s-0.512-0.195-0.707 0l-0.449 0.449c-0.343-0.458-0.577-1.003-0.662-1.595h0.464c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-0.464c0.085-0.592 0.319-1.137 0.662-1.595l0.449 0.449c0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146c0.195-0.195 0.195-0.512 0-0.707l-0.449-0.449c0.458-0.343 1.003-0.577 1.595-0.662v0.464c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v-0.464c0.592 0.085 1.137 0.319 1.595 0.662l-0.449 0.449c-0.195 0.195-0.195 0.512 0 0.707 0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146l0.449-0.449c0.343 0.458 0.577 1.003 0.662 1.595h-0.464c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h0.464c-0.085 0.592-0.319 1.137-0.662 1.595z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Vault); 
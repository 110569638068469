 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Hourglass = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M16 3.5v-0.5c0-0.392-0.241-0.733-0.717-1.014-0.344-0.203-0.821-0.382-1.419-0.531-1.172-0.293-2.722-0.454-4.364-0.454s-3.192 0.161-4.364 0.454c-0.598 0.149-1.075 0.328-1.419 0.531-0.476 0.281-0.717 0.622-0.717 1.014v0.5c0 2.631 1.593 4.999 4 6.002v1.997c-2.407 1.003-4 3.371-4 6.002v0.5c0 0.392 0.241 0.733 0.717 1.014 0.344 0.203 0.821 0.382 1.419 0.531 1.172 0.293 2.722 0.454 4.364 0.454s3.192-0.161 4.364-0.454c0.598-0.149 1.075-0.328 1.419-0.531 0.476-0.281 0.717-0.623 0.717-1.014v-0.5c0-2.631-1.593-4.999-4-6.002v-1.997c2.407-1.003 4-3.371 4-6.002zM5.612 2.369c1.066-0.238 2.447-0.369 3.888-0.369s2.823 0.131 3.888 0.369c1.15 0.257 1.498 0.534 1.589 0.631-0.091 0.097-0.439 0.374-1.589 0.631-1.066 0.238-2.447 0.369-3.888 0.369s-2.823-0.131-3.888-0.369c-1.15-0.257-1.498-0.534-1.589-0.631 0.091-0.097 0.439-0.374 1.589-0.631zM11.333 12.313c2.193 0.775 3.667 2.86 3.667 5.187v0.473c-0.042 0.057-0.324 0.37-1.612 0.657-1.066 0.238-2.447 0.369-3.888 0.369s-2.823-0.131-3.888-0.369c-1.287-0.288-1.57-0.6-1.612-0.657v-0.473c0-2.327 1.473-4.412 3.667-5.187 0.2-0.071 0.333-0.259 0.333-0.471v-2.683c0-0.212-0.134-0.401-0.333-0.471-1.976-0.699-3.368-2.461-3.624-4.506 0.3 0.135 0.666 0.257 1.093 0.364 1.172 0.293 2.722 0.454 4.364 0.454s3.192-0.161 4.364-0.454c0.428-0.107 0.794-0.229 1.093-0.364-0.256 2.045-1.647 3.807-3.624 4.506-0.2 0.071-0.333 0.26-0.333 0.471v2.683c0 0.212 0.134 0.401 0.333 0.471z" fill={color}></path>
<path d="M13.667 16.529c-2.262-0.799-3.667-2.726-3.667-5.029v-3.025c0.6-0.059 1.178-0.225 1.723-0.495 0.247-0.123 0.348-0.423 0.225-0.67s-0.423-0.348-0.67-0.225c-0.555 0.276-1.153 0.416-1.778 0.416s-1.223-0.14-1.778-0.416c-0.247-0.123-0.547-0.022-0.67 0.225s-0.022 0.547 0.225 0.67c0.545 0.271 1.122 0.436 1.723 0.495v3.025c0 2.302-1.405 4.229-3.667 5.029-0.21 0.074-0.346 0.279-0.332 0.501s0.172 0.409 0.39 0.458c0.982 0.22 2.477 0.512 4.109 0.512s3.127-0.292 4.109-0.512c0.218-0.049 0.377-0.236 0.39-0.458s-0.122-0.427-0.332-0.501zM9.5 17c-0.877 0-1.701-0.087-2.437-0.205 0.665-0.421 1.244-0.952 1.711-1.575 0.293-0.391 0.536-0.812 0.726-1.254 0.19 0.442 0.433 0.863 0.726 1.254 0.466 0.622 1.045 1.153 1.711 1.575-0.736 0.117-1.56 0.205-2.437 0.205z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Hourglass); 
import { theme } from "../storybook/designSystemVariables";
import Button from "../storybook/Button/Button";
import TopBar from "../storybook/TopBar/TopBar";
import { Col, Container, Row } from "react-bootstrap";
import { ArrowCounterClockwise, Fire } from "@phosphor-icons/react";
import { css } from "@emotion/react";
import LoadingButton from "../storybook/LoadingButton/LoadingButton";
import DepictLogo from "../DepictLogo";

export type BoostAndBuryTopBarProps = {
  hasLocalChanges: boolean;
  onRevert: () => void;
  onSave: () => Promise<void>;
  saveIsLoading: boolean;
};

const BoostAndBuryTopBar = ({
  hasLocalChanges,
  onRevert,
  onSave,
  saveIsLoading,
}: BoostAndBuryTopBarProps) => {
  return (
    <TopBar showBorder={false}>
      <Container
        fluid
        css={css`
          padding: 0;
          align-self: center;
          justify-content: center;
        `}
      >
        <Row
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <Col
            css={css`
              display: flex;
              align-items: center;
              flex-direction: row;
            `}
          >
            <Fire className="mx-3" weight="bold" size={20} />
            <p
              css={css`
                margin-bottom: 0;
                color: ${theme.textIcon.base.default};
              `}
            >
              Boost & Bury
            </p>
          </Col>
          <Col
            css={css`
              display: flex;
              justify-content: end;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                align-self: stretch;
                gap: 1rem;
              `}
            >
              <Button
                text="Revert"
                variant="outline-secondary"
                onClick={onRevert}
                disabled={!hasLocalChanges}
                icon={<ArrowCounterClockwise />}
              />
              <LoadingButton
                expectedDuration={4}
                minimumDuration={3}
                text="Save"
                textWhileLoading="Applying changes..."
                disabled={!hasLocalChanges}
                onClick={onSave}
                loadingIcon={
                  <DepictLogo className="me-2" width={16} height={16} />
                }
              />
            </div>
          </Col>
        </Row>
      </Container>
    </TopBar>
  );
};

export default BoostAndBuryTopBar;

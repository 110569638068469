import { useContext, useMemo } from "react";
import { Market } from "src/api/types";
import { AuthorizedMerchantsContext } from "src/contexts/AuthorizedMerchants.context";

interface UseMarketsReturn {
  markets: Market[];
  loading: boolean;
}
/**
 * useMarkets is a React hook that provides a convenient way to access the comprehensive list
 * of markets for the current merchant.
 */
export default function useMarkets(merchantId: string): UseMarketsReturn {
  const { authorizedMerchants, loading } = useContext(
    AuthorizedMerchantsContext
  );

  const markets = useMemo<Market[]>(() => {
    const currentMerchant = authorizedMerchants?.find(
      (x) => x.id === merchantId
    );

    if (!currentMerchant) return [];

    if (!currentMerchant.markets) return [];

    return currentMerchant.markets;
  }, [authorizedMerchants, merchantId]);

  return {
    markets,
    loading,
  };
}

import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { useEffect, useMemo } from "react";
import { SortableProduct } from "../useSortablePairedProducts";
import { getGridSnappedX } from "./math";
import "./productgridview.scss";
import {
  useCreateCardDraggingExtraStyles,
  DragIndicatorElement,
  ProductCardDraggingStack,
} from "../ProductCardDraggingStack";

export const CARD_DECK_EFFECT_OFFSET = 10;

interface DraggableCardProps {
  style?: React.CSSProperties;
  className?: string;
  attributes?: DraggableAttributes;
  listeners?: SyntheticListenerMap;
  pairsLength?: number;
  indicatorHeight?: number;
}

interface ProductGridCardRefProps extends DraggableCardProps {
  products: SortableProduct[];
  isOverlayItem?: boolean;
  isPair: boolean;
  groupId?: string;
  disableDrag: boolean;
  renderCard: (
    product: SortableProduct,
    index: number,
    extraStyle: React.CSSProperties,
    isPair: boolean
  ) => React.ReactNode;
}

// We use this presentation component to allow the parent to
// render an overlay item identical to the one in the list.
export const ProductGridCardRef = React.forwardRef<
  HTMLDivElement,
  ProductGridCardRefProps
>(function ProductGridCardRef(props: ProductGridCardRefProps, ref) {
  const { products: items } = props;

  const isOverlayItem = props.isOverlayItem || false;
  const style = props.style || {};
  const attributes = props.attributes || {};
  const listeners = props.listeners || {};

  const extraStyles = useCreateCardDraggingExtraStyles(
    items,
    props.isOverlayItem || false
  );
  const renderedItems = items.slice(0, 3).map((p, i) => {
    return props.renderCard(p, i, extraStyles[i], props.isPair);
  });

  return (
    <ProductCardDraggingStack
      containerAttributes={attributes}
      containerRef={ref}
      containerListeners={listeners}
      containerStyle={style}
      items={renderedItems}
      amount={items.length}
      isOverlayItem={isOverlayItem}
    />
  );
});

export function ProductGridSortableCard(props: {
  id: string;
  products: SortableProduct[];
  beingDragged?: boolean;
  className?: string;
  shouldBeIndicated?: boolean;
  pairsLength?: number;
  disableDrag: boolean;
  gridWidth: number;
  isPair: boolean;
  groupId?: string;
  renderCard: (
    product: SortableProduct,
    index: number,
    extraStyle: React.CSSProperties,
    isPair: boolean
  ) => React.ReactNode;
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    index,
    activeIndex,
  } = useSortable({ id: props.id, disabled: props.disableDrag });
  const [productCardHeight, setProductCardHeight] = React.useState<number>(0);
  const [el, setEl] = React.useState<HTMLDivElement | null>(null);

  const setRef = (el: HTMLDivElement) => {
    setNodeRef(el);
    setEl(el);
  };

  if (transform !== null) {
    // dnd-kit is not smart enough to calculate the correct x position for our epic complicated grid,
    // so we have to do it ourselves.
    transform.x = getGridSnappedX(
      transform.x,
      props.gridWidth,
      el?.offsetWidth || 1
    );
  }

  const style = useMemo(
    () => ({
      transform: CSS.Transform.toString(transform),
      transition,
      zIndex: index === activeIndex ? 100 : undefined,
    }),
    [transform, transition, index, activeIndex]
  );

  useEffect(() => {
    if (el) {
      // Keeping track of this is important for the indicator.
      // If a pair is the only pair on a row, its height will collapse to 0 when it is being dragged.
      const observer = new ResizeObserver((entries) => {
        setProductCardHeight(
          (entries[0].target as HTMLDivElement).offsetHeight
        );
      });
      observer.observe(el);
      return () => observer.disconnect();
    }
  }, [el]);

  // If we are being dragged, the OverlayItem above us with render the ProductListCard
  // We should render an indicator instead if that is the case.
  return props.shouldBeIndicated ? (
    <DragIndicatorElement
      ref={setRef}
      style={style}
      pairsLength={props.products.length}
      attributes={attributes}
      listeners={listeners}
      indicatorHeight={productCardHeight}
    />
  ) : (
    <ProductGridCardRef
      ref={setRef}
      style={style}
      products={props.products}
      attributes={attributes}
      listeners={listeners}
      className={props.className}
      isPair={props.isPair}
      groupId={props.groupId}
      renderCard={props.renderCard}
      disableDrag={props.disableDrag}
    />
  );
}

import { BasePortalProductDto } from "src/api/types";
import { Product } from "src/components/storybook/types";

/*
 * This function is used to sort the products in the category page.
 * It takes the searched products and sorts them in the following order:
 * 1. Pinned products
 * 2. Non-pinned products
 * 3. Hidden products
 */
export const getSearchedGridViewProducts = (
  searchedProducts: BasePortalProductDto[] | null,
  pinnedProducts: Product[],
  hiddenProducts: Product[]
) => {
  if (!searchedProducts) return [];

  const addPinnedAndHiddenFlag = (p: Product) => {
    return {
      ...p,
      pinned: pinnedProducts.some(
        (pp) => pp.main_product_id === p.main_product_id
      ),
      hidden: hiddenProducts.some(
        (hp) => hp.main_product_id === p.main_product_id
      ),
    };
  };

  const productIsInSearchResults = (p: Product) =>
    searchedProducts.some((sp) => sp.main_product_id === p.main_product_id);

  const pinnedProductsWithFlag = pinnedProducts
    .map(addPinnedAndHiddenFlag)
    .filter((p) => p.pinned === true)
    .filter(productIsInSearchResults);

  const productsByRelevanceWithFlag = searchedProducts
    .map(addPinnedAndHiddenFlag)
    .filter((p) => p.pinned === false && p.hidden === false);

  const hiddenProductsWithFlag = hiddenProducts
    .map(addPinnedAndHiddenFlag)
    .filter((p) => p.hidden === true)
    .filter(productIsInSearchResults);

  return [
    ...pinnedProductsWithFlag,
    ...productsByRelevanceWithFlag,
    ...hiddenProductsWithFlag,
  ];
};

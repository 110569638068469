import React from "react";
import reactFastCompare from "react-fast-compare";

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean;
  wrapper: Function;
  children: React.ReactNode;
}) => (condition ? wrapper(children) : children);

export default React.memo(ConditionalWrapper, reactFastCompare);

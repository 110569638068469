import { VirtualElement } from "@popperjs/core";
import mergeClassNames from "merge-class-names";
import { useCallback, useEffect, useState } from "react";
import { usePopper } from "react-popper";
import { PopoverProps } from "src/types/components";
import OutsideClickDetector from "src/components/mix/OutsideClickDetector";

const Popover = (props: PopoverProps) => {
  const [referenceElement, setReferenceElement] = useState<
    Element | VirtualElement | null | undefined
  >(null);
  const [popperElement, setPopperElement] = useState(null);
  const {
    triggerRef,
    isVisible,
    placement,
    title,
    className,
    children,
    closeOnClickCB,
  } = props;

  const modifiers = [
    {
      name: "offset",
      options: {
        offset: [0, 8],
      },
    },
    {
      name: "arrow",
      options: {
        padding: 10, // 5px from the edges of the popper
      },
    },
  ];

  const classMap = {
    right: "bs-popover-end",
    left: "bs-popover-start",
    top: "bs-popover-top",
    bottom: "bs-popover-bottom",
  };

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement ?? "right",
    modifiers,
  });

  useEffect(() => {
    setReferenceElement(triggerRef);
  }, [triggerRef]);

  const callback = useCallback(() => {
    if (!closeOnClickCB) {
      return;
    }
    //@ts-ignore
    closeOnClickCB();
  }, [closeOnClickCB]);

  return (
    <>
      {isVisible && (
        <OutsideClickDetector callback={callback}>
          <div
            className={mergeClassNames(
              className,
              "popover depict--Popover",
              classMap[placement] ?? classMap["right"]
            )}
            role="tooltip"
            //@ts-ignore
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <div
              className="popover-arrow"
              data-popper-arrow
              style={styles.arrow}
              {...attributes.arrow}
            ></div>
            {closeOnClickCB && (
              <button
                className="btn-close"
                type="button"
                aria-label="Close"
                onClick={closeOnClickCB}
              ></button>
            )}
            <h3 className="popover-header">{title}</h3>
            <div className="popover-body">{children}</div>
          </div>
        </OutsideClickDetector>
      )}
    </>
  );
};

export default Popover;

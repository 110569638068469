import { theme } from "../designSystemVariables";
import { ArrowsLeftRight, Check, Plus, Trash, X } from "@phosphor-icons/react";
import { Button } from "react-bootstrap";

import React, { useState, useEffect, useRef } from "react";
import { MerchantSynonym } from "src/api/types";

import { css } from "@emotion/react";

type SynonymItemRowProps = {
  item: MerchantSynonym;
  onUpdate: (item: MerchantSynonym) => void;
  onDelete: (item: MerchantSynonym) => void;
  isNewSynonym: boolean | null;
  setIsAddingSynonym: (isAddingSynonym: boolean) => void;
};

function SynonymItemRow(props: SynonymItemRowProps) {
  const { item, onUpdate, onDelete, setIsAddingSynonym } = props;

  const isNewSynonym = props.isNewSynonym || false;
  const [synonymWords, setSynonymWords] = useState<string[]>(
    isNewSynonym ? [] : item.synonyms
  );

  const [isAddingSynonymWord, setIsAddingSynonymWord] =
    useState<boolean>(isNewSynonym);
  const [newSynonymWord, setNewSynonymWord] = useState<string>("");
  const newSynonymWordInputRef = useRef<HTMLInputElement>(null);

  const deleteSynonym = () => {
    if (isNewSynonym) {
      // If it's a new synonym, we just remove it from the list.
      setIsAddingSynonym(false);
    } else {
      // If it's an existing synonym, we want to actually delete it.
      onDelete(item);
    }
  };

  const deleteSynonymWord = (synonym: string) => {
    const filteredSynonyms = synonymWords.filter((k) => k !== synonym);
    setSynonymWords(filteredSynonyms);

    if (filteredSynonyms.length === 0) {
      // Deleting the last word in the synonym should delete the entire synonym.
      deleteSynonym();
    } else if (!isNewSynonym) {
      onUpdate({
        ...item,
        synonyms: filteredSynonyms,
      });
    }
  };

  const addSynonymWord = (synonym: string) => {
    if (!synonym) {
      return;
    }

    const newSynonymWords = [...synonymWords, synonym];

    setSynonymWords(newSynonymWords);

    if (isNewSynonym && newSynonymWords.length < 2) {
      // If it's a new synonym, and we have less than 2 synonyms, move automatically
      // to the next synonym word without saving.
      setIsAddingSynonymWord(true);
    } else {
      onUpdate({
        ...item,
        synonyms: newSynonymWords,
      });
      setIsAddingSynonymWord(false);
      setIsAddingSynonym(false);
    }

    setNewSynonymWord("");
  };

  useEffect(() => {
    if (isAddingSynonymWord && newSynonymWordInputRef.current) {
      newSynonymWordInputRef.current.focus();
    }
  }, [isAddingSynonymWord]);

  return (
    <tr
      className="border-gray-300 display-block"
      css={css`
        :last-child td {
          border-bottom: none !important;
        }
      `}
    >
      <td
        className="text-start w-100"
        style={{
          minWidth: "500px",
          overflowWrap: "break-word",
          whiteSpace: "normal",
          flexWrap: "wrap",
          gap: "0.5em",
          alignItems: "center",
        }}
      >
        <div className="d-flex align-items-center flex-wrap">
          {synonymWords.length === 0 && !isAddingSynonymWord && (
            <span
              style={{ color: theme.textIcon.inverse.disabled }}
              className="me-2 mb-2 d-inline-block"
            >
              No synonyms added yet
            </span>
          )}
          {synonymWords.length > 0 &&
            synonymWords.map((synonym, index) => (
              <div className="d-flex align-items-center">
                <span
                  key={`${isNewSynonym ? "new" : item.id}-${synonym}`}
                  className="badge me-2 mb-2 d-flex align-items-center"
                  style={{ fontSize: "1em" }}
                  css={css`
                    background: ${theme.background.base.hover};
                    border: 1px solid ${theme.border.subtle.disabled};
                  `}
                >
                  {synonym}
                  <X
                    size={12}
                    className="ms-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => deleteSynonymWord(synonym)}
                  />
                </span>

                {index < synonymWords.length - 1 && (
                  <span className="me-2 mb-2 d-flex">
                    <ArrowsLeftRight />
                  </span>
                )}
              </div>
            ))}

          {isAddingSynonymWord && (
            <div className="d-flex align-items-center">
              {synonymWords.length > 0 && (
                <span className="me-2 mb-2 d-flex">
                  <ArrowsLeftRight />
                </span>
              )}
              <input
                type="text"
                className="new-keyword-input d-flex mb-2"
                value={newSynonymWord}
                ref={newSynonymWordInputRef}
                onChange={(event) => setNewSynonymWord(event.target.value)}
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    addSynonymWord(newSynonymWord);
                  }
                }}
              />
              <Button
                variant="outline-secondary"
                style={{ padding: "2px" }}
                className="badge d-flex mb-2"
                onClick={() => {
                  addSynonymWord(newSynonymWord);
                }}
              >
                <Check size={12} />
              </Button>
              <Button
                variant="outline-secondary"
                style={{
                  padding: "2px",
                  marginLeft: "0.3em",
                }}
                className="badge d-flex mb-2"
                onClick={() => {
                  setIsAddingSynonymWord(false);
                  setNewSynonymWord("");
                }}
              >
                <X size={12} />
              </Button>
            </div>
          )}
          {!isAddingSynonymWord && (
            <Button
              variant="outline-secondary"
              style={{ padding: "2px" }}
              className="badge mb-2 d-flex"
              onClick={() => setIsAddingSynonymWord(true)}
            >
              <Plus size={12} />
            </Button>
          )}
        </div>
      </td>
      <td className="justify-content-end d-flex" style={{ height: "100%" }}>
        <Button
          variant="link"
          style={{
            padding: "6px",
            color: theme.textIcon.base.pressed,
          }}
          onClick={deleteSynonym}
        >
          <Trash size={16} />
        </Button>
      </td>
    </tr>
  );
}

export default SynonymItemRow;

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Spotlights = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M19.581 15.066l-6.131-7.189 2.522-7.212c0.079-0.225-0.012-0.474-0.217-0.595s-0.467-0.082-0.626 0.095l-4.246 4.703-4.002-4.692c-0.158-0.185-0.427-0.23-0.636-0.105s-0.298 0.382-0.211 0.609l2.577 6.705-8.152 9.029c-0.3 0.329-0.458 0.697-0.458 1.087 0 0.744 0.574 1.408 1.615 1.871 0.912 0.406 2.115 0.629 3.385 0.629s2.473-0.223 3.385-0.629c0.836-0.371 1.37-0.873 1.548-1.441l1.3-3.718 0.819 2.132c0.11 0.358 0.429 0.814 1.275 1.176 0.722 0.309 1.671 0.48 2.672 0.48s1.95-0.17 2.672-0.48c1.156-0.495 1.328-1.165 1.328-1.52 0-0.226-0.070-0.579-0.419-0.934zM14.209 2.675l-1.511 4.321-1.161-1.361 2.672-2.96zM12.316 8.089l-1.126 3.22-1.426-3.711 1.097-1.215 1.455 1.706zM8.014 3.045l2.193 2.571-0.845 0.936-1.348-3.507zM7.979 18.457c-0.787 0.35-1.845 0.543-2.979 0.543s-2.192-0.193-2.979-0.543c-0.63-0.28-1.021-0.647-1.021-0.957s0.391-0.677 1.021-0.957c0.787-0.35 1.845-0.543 2.979-0.543s2.192 0.193 2.979 0.543c0.63 0.28 1.021 0.647 1.021 0.957s-0.391 0.677-1.021 0.957zM9.441 16.309c-0.264-0.256-0.619-0.486-1.056-0.68-0.912-0.406-2.115-0.629-3.385-0.629-0.745 0-1.467 0.077-2.12 0.223l6.132-6.792 1.667 4.338-1.238 3.54zM13.067 8.97l4.4 5.159c-0.46-0.084-0.957-0.129-1.468-0.129-1.001 0-1.95 0.17-2.672 0.48-0.32 0.137-0.564 0.288-0.751 0.441l-0.833-2.168 1.323-3.783zM16 17c-1.861 0-3-0.647-3-1s1.139-1 3-1 3 0.647 3 1-1.139 1-3 1z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Spotlights); 
import React from "react";
import "./CurvyCorner.scss";
import { css } from "@emotion/react";

const defaultTriangleSize = "1em";

interface CurvyCornerProps {
  corner?: "top-right" | "bottom-right" | "bottom-left" | "top-left";
  children?: React.ReactNode | string;
  triangleSize?: string;
  borderRadius?: string;
  extraStyles?: React.CSSProperties;
  contentContainerStyles?: React.CSSProperties;
  triangleStyles?: React.CSSProperties;
  backgroundColor?: string;
}

export function CurvyCorner(props: CurvyCornerProps) {
  const cornerStyles =
    !props.corner || props.corner === "bottom-right"
      ? { bottom: 0, right: 0 }
      : props.corner === "top-right"
      ? { top: 0, right: 0 }
      : props.corner === "bottom-left"
      ? { bottom: 0, left: 0, alignItems: "flex-start" }
      : { top: 0, left: 0, alignItems: "flex-start" };

  const triangleSize = props.triangleSize || defaultTriangleSize;

  const cssVariables = {
    "--curvy-corner-triangle-size": triangleSize,
    "--curvy-corner-border-radius": props.borderRadius,
  } as React.CSSProperties;

  const triangleStyle = css`
    overflow: hidden;
    position: absolute;
    width: calc(${triangleSize} * 2);
    height: calc(${triangleSize} * 2);
    &::before {
      content: "";
      display: block;
      height: 100%;
      box-shadow: 0 0 0 ${triangleSize} ${props.backgroundColor || "white"};
      border-radius: 0 0 35% 0;
    }
  `;

  return (
    <div
      className={`depict--CurvyCorner ${props.corner || "bottom-right"}`}
      style={{
        ...cssVariables,
        ...cornerStyles,
        ...props.extraStyles,
      }}
    >
      <div
        className="content-container"
        style={{
          backgroundColor: props.backgroundColor || "white",
          ...props.contentContainerStyles,
        }}
      >
        <div css={triangleStyle} className="smooth-triangle triangle-1" />
        {props.children}
        <div css={triangleStyle} className="smooth-triangle triangle-2" />
      </div>
    </div>
  );
}

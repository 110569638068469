import config from "src/config";
import { DepictDataPoint } from "src/types/metrics";

const lessDataPoints = (dataset: DepictDataPoint[]): DepictDataPoint[] => {
  let nOfPoints = config.pointsInMiniGraph;
  let granularity = Math.ceil(dataset.length / nOfPoints);
  let filtered: DepictDataPoint[] = [];

  let i = 0;
  while (i < dataset.length) {
    let groupedValue = 0;
    let max = i + granularity - 1;
    if (max > dataset.length - 1) {
      max = dataset.length - 1;
    }
    for (var j = i; j <= max; j++) {
      groupedValue += dataset[j].value || 0;
    }
    let toBePushed = JSON.parse(JSON.stringify(dataset[i]));
    toBePushed.value = groupedValue;
    filtered.push(toBePushed);
    i = i + granularity;
  }

  return filtered;
};

export default lessDataPoints;

import { css } from "@emotion/react";
import { FC } from "react";
import { theme } from "../designSystemVariables";

type IconButtonProps = {
  icon: JSX.Element;
  color?: "regular" | "black";
  float?: boolean;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const IconButton: FC<IconButtonProps> = ({
  icon,
  color,
  float,
  ...props
}) => (
  <button
    {...props}
    css={css`
      border: 0;
      background: ${color === "black"
        ? theme.background.inverse.default
        : "none"};
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 2.25rem;

      ${float &&
      `
      position: absolute;
      left: -1px;
      top: -1px;
      `}

      &:hover {
        background: ${color === "black"
          ? theme.background.inverse.hover
          : theme.background.subtle.hover};
      }

      & svg {
        color: ${color === "black"
          ? theme.textIcon.inverse.default
          : theme.textIcon.base.default};
        font-size: 1rem;
      }
    `}
  >
    {icon}
  </button>
);

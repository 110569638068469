import { useState, useRef, useEffect } from "react";
import usePrevious from "src/helpers/hooks/usePrevious";

export default function useLoadingStatus(loadingQueue: string[]) {
  const [minimumDurationPassed, setMinimumDurationPassed] =
    useState<boolean>(true);

  const previousLength = usePrevious(loadingQueue?.length);
  const timer = useRef<ReturnType<typeof setTimeout>>();
  const minimumLoadingTime = 750;

  useEffect(() => {
    if (previousLength !== 0 && typeof previousLength !== "undefined") {
      return;
    }

    if (loadingQueue && loadingQueue?.length > 0) {
      clearTimeout(timer.current as ReturnType<typeof setTimeout>);
      setMinimumDurationPassed(false);
      timer.current = setTimeout(() => {
        setMinimumDurationPassed(true);
      }, minimumLoadingTime);
    }
  }, [loadingQueue, previousLength]);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current as ReturnType<typeof setTimeout>);
    };
  }, []);

  return [(loadingQueue && loadingQueue.length > 0) || !minimumDurationPassed];
}

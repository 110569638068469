export default function DottedProgressBar({
  currentStep,
  numberOfSteps,
}: {
  currentStep: number;
  numberOfSteps: number;
}) {
  return (
    <div className="d-flex">
      {Array.from(Array(numberOfSteps).keys()).map((step) => (
        <div
          key={step}
          className={`d-flex align-items-center justify-content-center rounded-circle mx-2 ${
            step === currentStep - 1 ? "bg-primary text-white" : "bg-gray-200"
          }`}
          style={{ width: 8, height: 8 }}
        />
      ))}
    </div>
  );
}

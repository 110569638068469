import { EyeClosed, Icon } from "@phosphor-icons/react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ProductMetadata } from "../../../helpers/catalog/ProductMetadataProvider";
import {
  getDetailFromProductMetadata,
  getLabelFromProductMetadata,
} from "../../../helpers/catalog/productMetadataToTextTransformers";
import { Badge } from "../Badge/Badge";
import { metadataStyles } from "./MetadataStyles";
import { css } from "@emotion/react";

type NonNullableProductMetadataType = NonNullable<ProductMetadata["type"]>;

export interface ProductBadgeProps {
  metadata: ProductMetadata;
  oos_auto_hide?: boolean;
}

const icons: Record<NonNullableProductMetadataType, Icon | undefined> = {
  on_sale: undefined,
  low_stock: undefined,
  out_of_stock: undefined,
  new_in: undefined,
  bestseller: undefined,
  trending: undefined,
  slow_mover: undefined,
  inactive: undefined,
};

const myStyle = css`
  display: block;
  margin-left: 0.3rem;
`;

export const ProductBadge = (props: ProductBadgeProps) => {
  const { metadata } = props;

  if (!metadata.type) {
    return null;
  }

  const style = metadataStyles[metadata.type];
  const icon = icons[metadata.type];
  const badge = (
    <Badge
      style={{ ...style, fontSize: "12px" }}
      text={getLabelFromProductMetadata(metadata)}
      variant="custom"
      icon={icon}
      rightIcon={
        props.oos_auto_hide && metadata.type === "out_of_stock" ? (
          <div css={myStyle}>
            <EyeClosed />
          </div>
        ) : undefined
      }
    />
  );
  if (getDetailFromProductMetadata(metadata) === null) {
    return <span style={{ display: "flex" }}>{badge}</span>;
  }
  const tooltip = (
    <Tooltip id="tooltip">
      {getDetailFromProductMetadata(metadata, props.oos_auto_hide)}
    </Tooltip>
  );
  return (
    <OverlayTrigger placement="top" overlay={tooltip}>
      {/* Extra span needed to trigger tooltip on hover */}
      <span style={{ cursor: "default", display: "flex" }}>{badge}</span>
    </OverlayTrigger>
  );
};

export default ProductBadge;

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const CarWash4 = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M16.492 3.009c-0.83 0-1.504-0.675-1.504-1.504s0.675-1.504 1.504-1.504 1.504 0.675 1.504 1.504-0.675 1.504-1.504 1.504zM16.492 1c-0.278 0-0.504 0.226-0.504 0.504s0.226 0.504 0.504 0.504c0.278 0 0.504-0.226 0.504-0.504s-0.226-0.504-0.504-0.504z" fill={color}></path>
<path d="M13.498 2c-0.132 0-0.261-0.053-0.354-0.147s-0.146-0.222-0.146-0.353 0.053-0.261 0.146-0.353c0.093-0.093 0.222-0.147 0.354-0.147s0.26 0.053 0.353 0.147c0.093 0.093 0.147 0.222 0.147 0.353s-0.053 0.26-0.147 0.353c-0.093 0.093-0.222 0.147-0.353 0.147z" fill={color}></path>
<path d="M1.5 5.009c-0.132 0-0.261-0.053-0.353-0.146s-0.147-0.222-0.147-0.354c0-0.132 0.053-0.261 0.147-0.353s0.222-0.147 0.353-0.147 0.261 0.053 0.353 0.147c0.093 0.093 0.147 0.222 0.147 0.353s-0.053 0.261-0.147 0.354c-0.093 0.093-0.222 0.146-0.353 0.146z" fill={color}></path>
<path d="M2.5 3c-0.827 0-1.5-0.673-1.5-1.5s0.673-1.5 1.5-1.5 1.5 0.673 1.5 1.5-0.673 1.5-1.5 1.5zM2.5 1c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5 0.5-0.224 0.5-0.5-0.224-0.5-0.5-0.5z" fill={color}></path>
<path d="M5.5 16c-0.827 0-1.5-0.673-1.5-1.5s0.673-1.5 1.5-1.5 1.5 0.673 1.5 1.5-0.673 1.5-1.5 1.5zM5.5 14c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5 0.5-0.224 0.5-0.5-0.224-0.5-0.5-0.5z" fill={color}></path>
<path d="M14.5 16c-0.827 0-1.5-0.673-1.5-1.5s0.673-1.5 1.5-1.5 1.5 0.673 1.5 1.5-0.673 1.5-1.5 1.5zM14.5 14c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5 0.5-0.224 0.5-0.5-0.224-0.5-0.5-0.5z" fill={color}></path>
<path d="M0.5 17c-0.132 0-0.261-0.053-0.353-0.147s-0.147-0.222-0.147-0.353 0.053-0.261 0.147-0.353c0.093-0.093 0.222-0.147 0.353-0.147s0.261 0.053 0.353 0.147c0.093 0.093 0.147 0.222 0.147 0.353s-0.053 0.261-0.147 0.353c-0.093 0.093-0.222 0.147-0.353 0.147z" fill={color}></path>
<path d="M19.5 16c-0.132 0-0.261-0.053-0.353-0.147s-0.147-0.222-0.147-0.353 0.053-0.261 0.147-0.353c0.093-0.093 0.222-0.147 0.353-0.147s0.261 0.053 0.353 0.147c0.093 0.093 0.147 0.222 0.147 0.353s-0.053 0.26-0.147 0.353c-0.093 0.093-0.222 0.147-0.353 0.147z" fill={color}></path>
<path d="M19.089 9.845c0.577-0.636 0.911-1.474 0.911-2.353 0-1.93-1.57-3.5-3.5-3.5-0.316 0-0.627 0.042-0.927 0.124-0.456-0.69-1.231-1.116-2.080-1.116-0.895 0-1.725 0.483-2.168 1.261-0.137 0.24-0.053 0.545 0.187 0.682s0.545 0.053 0.682-0.187c0.266-0.466 0.763-0.756 1.299-0.756 0.448 0 0.863 0.199 1.142 0.53-0.029 0.018-0.057 0.036-0.085 0.055-0.229 0.154-0.29 0.465-0.136 0.694s0.465 0.29 0.694 0.136c0.412-0.277 0.893-0.423 1.392-0.423 1.378 0 2.5 1.122 2.5 2.5 0 0.89-0.479 1.72-1.25 2.166-0.239 0.138-0.321 0.444-0.182 0.683 0.093 0.16 0.261 0.25 0.433 0.25 0.085 0 0.171-0.022 0.25-0.067 0.020-0.012 0.039-0.024 0.059-0.036 0.437 0.377 0.69 0.923 0.69 1.512 0 0.635-0.292 1.218-0.8 1.6-0.221 0.166-0.265 0.479-0.099 0.7 0.098 0.131 0.248 0.2 0.4 0.2 0.105 0 0.21-0.033 0.3-0.1 0.762-0.573 1.2-1.447 1.2-2.4 0-0.827-0.333-1.596-0.911-2.155z" fill={color}></path>
<path d="M8.939 4.396c-0.284-1.388-1.52-2.396-2.939-2.396-1.654 0-3 1.346-3 3 0 0.042 0.001 0.084 0.003 0.126-1.742 0.447-3.003 2.033-3.003 3.874 0 0.868 0.286 1.712 0.801 2.4-0.492 0.37-0.801 0.958-0.801 1.6 0 0.846 0.536 1.604 1.333 1.886 0.055 0.019 0.111 0.029 0.167 0.029 0.206 0 0.399-0.128 0.471-0.333 0.092-0.26-0.044-0.546-0.305-0.638-0.399-0.141-0.667-0.52-0.667-0.943 0-0.45 0.303-0.847 0.737-0.965 0.266-0.072 0.424-0.347 0.351-0.614-0.025-0.093-0.075-0.172-0.14-0.233l0-0c-0.611-0.573-0.948-1.351-0.948-2.188 0-1.367 0.925-2.546 2.21-2.895 0.013 0.032 0.026 0.064 0.039 0.095 0.082 0.188 0.266 0.3 0.458 0.3 0.067 0 0.135-0.013 0.2-0.042 0.253-0.111 0.369-0.405 0.258-0.658s-0.166-0.522-0.166-0.8c0-1.103 0.897-2 2-2 0.946 0 1.77 0.671 1.959 1.596 0.055 0.271 0.32 0.445 0.59 0.39s0.445-0.32 0.39-0.59z" fill={color}></path>
<path d="M16.958 11.907c-0.13-0.792-0.553-3.214-1.011-4.131-0.305-0.61-1.088-1.077-2.326-1.386-1.006-0.251-2.292-0.39-3.621-0.39s-2.615 0.138-3.621 0.39c-1.238 0.31-2.021 0.776-2.326 1.386-0.458 0.916-0.881 3.339-1.011 4.131-0.699 0.479-1.042 1.172-1.042 2.093v2.5c0 0.652 0.418 1.208 1 1.414v1.086c0 0.551 0.449 1 1 1h1c0.551 0 1-0.449 1-1v-1h8v1c0 0.551 0.449 1 1 1h1c0.551 0 1-0.449 1-1v-1.086c0.582-0.206 1-0.762 1-1.414v-2.5c0-0.921-0.343-1.614-1.042-2.093zM4.947 8.224c0.297-0.593 2.168-1.224 5.053-1.224s4.756 0.63 5.053 1.224c0.287 0.575 0.606 2.096 0.804 3.183-0.098-0.029-0.199-0.056-0.303-0.080-0.267-0.064-0.555-0.114-0.863-0.156l-2.899-2.077c-0.225-0.161-0.537-0.109-0.698 0.115s-0.109 0.537 0.115 0.698l1.566 1.122c-0.85-0.028-1.782-0.028-2.775-0.028-0.117 0-0.233 0-0.349 0l-2.874-1.916c-0.23-0.153-0.54-0.091-0.693 0.139s-0.091 0.54 0.139 0.693l1.644 1.096c-1.341 0.024-2.507 0.097-3.42 0.314-0.104 0.025-0.205 0.052-0.303 0.081 0.198-1.087 0.516-2.608 0.804-3.183zM5 19h-1v-1h1v1zM16 19h-1v-1h1v1zM17 16.5c0 0.276-0.224 0.5-0.5 0.5h-13c-0.276 0-0.5-0.224-0.5-0.5v-2.5c0-0.924 0.486-1.417 1.678-1.701 1.255-0.299 3.14-0.299 5.322-0.299s4.066 0 5.322 0.299c1.192 0.284 1.678 0.777 1.678 1.701v2.5z" fill={color}></path>
<path d="M17.496 7c-0.132 0-0.261-0.053-0.354-0.147s-0.146-0.222-0.146-0.353 0.053-0.261 0.146-0.353c0.093-0.093 0.222-0.147 0.354-0.147s0.26 0.053 0.353 0.147c0.093 0.093 0.147 0.222 0.147 0.353s-0.053 0.26-0.147 0.353c-0.093 0.093-0.222 0.147-0.353 0.147z" fill={color}></path>
<path d="M6.5 4.992c-0.132 0-0.261-0.053-0.353-0.147s-0.147-0.222-0.147-0.353c0-0.132 0.053-0.261 0.147-0.354s0.222-0.146 0.353-0.146 0.261 0.053 0.353 0.146c0.093 0.093 0.147 0.222 0.147 0.354s-0.053 0.26-0.147 0.353c-0.093 0.093-0.222 0.147-0.353 0.147z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(CarWash4); 
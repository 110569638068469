import { createMachine, assign } from "xstate";

// any object
export type Element = Record<string, any>;

type InitializeEventPayload<T> = {
  nbRows: number;
  chunkSize: number;
  nbColumns: number;
  initialProducts: T[];
};

type GridContext<T> = {
  nbRows: number;
  chunkSize: number;
  nbColumns: number;
  currentRow: number;
  endProducts: T[] | null;
  currentProducts: T[];
  initialProducts: T[];
  rowDeletionDelay: number;
  chunkReappearingDelay: number;
};

type SetEndProductsEventPayload<T> = {
  endProducts: T[];
};

type GridEvents<T> =
  | { type: "initialize"; payload: InitializeEventPayload<T> }
  | { type: "disappearRow" }
  | { type: "reappearChunk" }
  | { type: "allChunksAppeared" }
  | { type: "allRowsDisappeared" }
  | { type: "setEndProducts"; payload: SetEndProductsEventPayload<T> };

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

async function rowDeletionDelay<T extends Element>(context: GridContext<T>) {
  await delay(context.rowDeletionDelay);
}

async function beforeReappearingDelay<T extends Element>(
  context: GridContext<T>
) {
  await delay(1000);
}

async function chunkReappearingDelay<T extends Element>(
  context: GridContext<T>
) {
  await delay(context.chunkReappearingDelay);
}

/**
 * XState Machine: Grid Transition Animator
 *
 * This state machine manages the animated transition of a grid of products.
 * It operates in several distinct states to handle the appearance and disappearance
 * of grid items during transitions.
 *
 * States:
 * - Idle: The initial state where the grid is waiting to start the transition.
 *         Transitions to 'Disappearing' when initialized with grid parameters.
 *
 * - Disappearing: In this state, grid products disappear row by row, starting from the bottom.
 *                 After all rows have disappeared, the machine transitions to 'IdleWaitingForEndProducts'.
 *
 * - IdleWaitingForEndProducts: The machine waits in this state for the end products to be provided.
 *                               Once provided, it transitions to 'PreparingToReappear'.
 *
 * - PreparingToReappear: A brief waiting period before the reappearing of the products begins.
 *                        This leads to the 'Reappearing' state.
 *
 * - Reappearing: Products reappear in chunks, starting from the top of the grid.
 *                Once all chunks have reappeared, the machine returns to the 'Idle' state.
 *
 * The machine manages these transitions based on external events and internal conditions,
 * ensuring a smooth animated transition between the initial and final states of the product grid.
 *
 * Events:
 * - 'initialize': Triggers the transition from 'Idle' to 'Disappearing', starting the animation sequence.
 * - 'disappearRow': Handles the disappearance of a single row of products.
 * - 'reappearChunk': Manages the reappearing of a chunk of products.
 * - 'allChunksAppeared': Indicates all chunks have reappeared, triggering the transition to 'Idle'.
 * - 'allRowsDisappeared': Signals that all rows have disappeared, leading to 'IdleWaitingForEndProducts'.
 * - 'setEndProducts': Sets the final products for the grid, used in the 'Reappearing' state.
 *
 * Context:
 * The machine's context holds the state of the grid, including the number of rows and columns,
 * the current and end products, and various delays for animations.
 */
export function _createMachine<T extends Element>() {
  return createMachine(
    {
      /** @xstate-layout N4IgpgJg5mDOIC5RQE4EsIDoCSEA2YAxGgHZoAuaAhnmgF5gDaADALqKgAOA9rBWtxIcQAD0QBWAMwAOTAE45AJgCMUgCyTmcyXPEAaEAE9Ek5QHZMZ5uY3NT45gDZxZgL6uDqDJgAiaWFScnGBU6CRQhBCCYJikAG7cANYxKNwA7j5gBJSCmXhUhizsSCA8fDlCJWIIZlKWaspq4oqOGmpqzgbGCIqKapgdzIo64uKObh4gXlh+AUEhYRE0eABK6bCzgcGhkEXCZfyCwtXK5uKYKo7M17WOcmbKXSYymNftd61yzpKOku6e6CwuAIAHUqPxwgAxbgoACiJAgAAVUhAAK4AY3IsEIsDA5HhSJRGKxexKBwqx0Qykc0kUmBpCkU0lUigUaieCEkLzeHS+ai+Ul+-ymgMwyLAnFCpCgABVuCsQvNQpForESAlkphpmKUBKpeE5QqtgsEPFuOiqBUiqSuLxDpVQNVFMxpP1xPcpGY+lZlIoOT86b17jJlJpnX1HMLtUalYsVSQYmbNeiABaokiJGPbRZ5Ao20p2ilVRC9V2WNk6L3KaRWdlGEsteRXJkOM6ScRNKOirMLaWEZYAYTTGdgAEFY7s2PtCwIHaISy63R7JF61D6-fWEMzMJIg40VIoxq65O5JiRuBA4MJptPyrPKQgALSODnPrveYFgW-2h9qDfdJk5CbJx3UcYZD1pd8Zn8Y19Sgb8i0dRAzF3TBmRXGRpDkWl-RUSwuUcVpMPEWk1CgnB8DAMEISgaE4QRZEL2JeAyRnI5iwQUM5GYNCVA6TDNGcf9nlkFDhjMBQHggsjJm1cVJUWQ1FWzBD7w46sUPpCSxlOV0SPaf0VwGWlNA7XQHFaCYAW8Hs4NU9ikJ6ex5F6KQVwUXdJFw-phh+U52y0Bo7FPVwgA */
      tsTypes: {} as import("./TransitionGridAnimator.state.typegen").Typegen0,
      context: {
        nbRows: 0,
        chunkSize: 0,
        nbColumns: 0,
        currentRow: 0,
        endProducts: null,
        currentProducts: [],
        initialProducts: [],
        rowDeletionDelay: 500,
        chunkReappearingDelay: 250,
      },
      id: "grid",
      initial: "Idle",
      states: {
        Idle: {
          description:
            "The grid is idle, waiting to start the transition from initialProducts to endProducts.",
          on: {
            initialize: {
              target: "Disappearing",
              actions: "assignInitialContext",
            },
          },
        },
        Disappearing: {
          description:
            "Products are disappearing one row at a time, from bottom to top.",

          invoke: {
            id: "rowDeletionDelay",
            src: rowDeletionDelay,
            onDone: {
              target: "Disappearing",
              cond: "hasMoreRowsToDisappear",
              actions: "disappearRow",
            },
          },

          on: {
            allRowsDisappeared: {
              target: "IdleWaitingForEndProducts",
              actions: "prepareForReappearing",
            },
          },
        },
        IdleWaitingForEndProducts: {
          description: "Waiting for end products to be provided.",
          on: {
            setEndProducts: {
              target: "PreparingToReappear",
              actions: "assignEndProducts",
            },
          },
        },
        PreparingToReappear: {
          invoke: {
            src: beforeReappearingDelay,
            onDone: "Reappearing",
          },
        },
        Reappearing: {
          description:
            "Products are reappearing in chunks, from top to bottom.",

          invoke: {
            id: "chunkReappearingDelay",
            src: chunkReappearingDelay,
            onDone: {
              target: "Reappearing",
              cond: "hasMoreChunksToReappear",
              actions: "reappearChunk",
            },
          },

          on: {
            allChunksAppeared: {
              target: "Idle",
              actions: "finalizeReappearing",
            },
          },
        },
      },
      schema: {
        events: {} as GridEvents<T>,
        context: {} as GridContext<T>,
      },
      predictableActionArguments: true,
      preserveActionOrder: true,
    },
    {
      actions: {
        assignInitialContext: assign((context, event) => {
          const payload = event.payload;

          const delayUnit = context.rowDeletionDelay / payload.nbColumns;

          const chunkReappearingDelay = delayUnit * payload.chunkSize;

          return {
            ...context,
            nbRows: payload.nbRows,
            chunkSize: payload.chunkSize,
            nbColumns: payload.nbColumns,
            currentRow: payload.nbRows - 1,
            currentProducts: [...payload.initialProducts],
            initialProducts: [...payload.initialProducts],
            chunkReappearingDelay,
          };
        }),
        disappearRow: assign((context) => {
          // delete last row. Sometimes, the row isn't full.
          const startIndex = context.currentRow * context.nbColumns;
          // Calculate the actual end index, considering the total number of products
          const endIndex = Math.min(
            startIndex + context.nbColumns,
            context.currentProducts.length
          );

          const newProducts = context.currentProducts
            .slice(0, startIndex)
            .concat(context.currentProducts.slice(endIndex));
          return {
            ...context,
            currentProducts: newProducts,
            currentRow: context.currentRow - 1,
          };
        }),
        prepareForReappearing: assign((context) => {
          return {
            ...context,
            currentProducts: [],
            currentRow: 0, // Reset row counter for reappearing
          };
        }),
        reappearChunk: assign((context) => {
          if (!context.endProducts) throw new Error("endProducts is undefined");

          const startIndex = context.currentRow * context.chunkSize;
          const chunk = context.endProducts.slice(
            startIndex,
            startIndex + context.chunkSize
          );
          return {
            ...context,
            currentProducts: [...context.currentProducts, ...chunk],
            currentRow: context.currentRow + 1,
          };
        }),
        finalizeReappearing: assign((context) => {
          return {
            ...context,
            currentRow: 0, // Reset for next transition
          };
        }),
        assignEndProducts: assign((context, event) => {
          return {
            ...context,
            endProducts: event.payload.endProducts,
          };
        }),
      },
      services: {},
      guards: {
        hasMoreRowsToDisappear: (context) => {
          return context.currentRow >= 0;
        },
        hasMoreChunksToReappear: (context) => {
          if (!context.endProducts) throw new Error("endProducts is undefined");

          const totalChunks = Math.ceil(
            context.endProducts.length / context.chunkSize
          );
          return context.currentRow < totalChunks;
        },
      },
      delays: {},
    }
  );
}

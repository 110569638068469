import { createSelector } from "reselect";
import { State } from "./BoostBury.reducer.types";

const getLocalState = (state: State) => state.local;
const getServerState = (state: State) => state.server;

const getLocalBoostBuryManagement = (state: State) =>
  state.localBoostBuryManagement;

export const getHasModifiedRules = createSelector(
  [getLocalBoostBuryManagement],
  (localProductManagement) =>
    localProductManagement.addedRules.length > 0 ||
    localProductManagement.removedRules.length > 0 ||
    localProductManagement.updatedRules.length > 0
);

export const isLocalStateDifferentThanServer = createSelector(
  [getLocalState, getServerState, getHasModifiedRules],
  (local, server, hasModifiedCategoryProducts) => {
    return (
      JSON.stringify(local) !== JSON.stringify(server) ||
      hasModifiedCategoryProducts
    );
  }
);

export const getLocalRules = createSelector([getLocalState], (state) => {
  return {
    allRules: state.boostRules.concat(state.buryRules),
    boostRules: state.boostRules,
    buryRules: state.buryRules,
  };
});

export const getChangedRules = createSelector(
  [getLocalBoostBuryManagement],
  (localProductManagement) => {
    return {
      addedRules: localProductManagement.addedRules,
      removedRules: localProductManagement.removedRules,
      updatedRules: localProductManagement.updatedRules,
    };
  }
);

import { Placeholder } from "react-bootstrap";
import BootstrapCard from "react-bootstrap/Card";
import { common } from "../common";
import { IMG_ASPECT_RATIO } from "../CollectionProductGridCard/CollectionProductGridCard";

export const ProductGridCardPlaceholder = () => {
  return (
    <Placeholder
      as={BootstrapCard}
      className="depict--ProductGridCard"
      style={{
        borderRadius: common.cardBorderRadius,
        overflow: "hidden",
      }}
      animation="glow"
    >
      <div className="pt-3 px-3">
        <div
          style={{
            position: "relative",
            width: "100%",
            paddingTop: `calc(100% / ${IMG_ASPECT_RATIO})`,
            borderRadius: common.cardBorderRadius,
            overflow: "hidden",
          }}
        >
          <Placeholder
            as={BootstrapCard.Img}
            variant="top"
            draggable={false}
            style={{
              objectFit: "cover",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              borderRadius: common.cardBorderRadius,
            }}
          />
        </div>
      </div>
      <BootstrapCard.Body style={{ maxHeight: "calc(20rem - 160px)" }}>
        <Placeholder
          className="w-100 rounded"
          style={{ height: "2.25rem" }}
        ></Placeholder>
      </BootstrapCard.Body>
    </Placeholder>
  );
};

export default ProductGridCardPlaceholder;

import { SearchFilter } from "@depict-ai/types/api/SearchResponse";
import { QueryKey } from "@tanstack/react-query";
import { PortalSortModel } from "src/api/types";
import { TimePeriod } from "../components/storybook/ListingMetricBanner/ListingMetricBanner";

// Query IDs are used to identify queries in the cache (when using react-query)
export enum QueryId {
  GetPlpMetrics = "GetPlpMetrics",
  GetNumberOfProducts = "GetNumberOfProducts",
  GetNumberOfSalesProducts = "GetNumberOfSalesProducts",
  GetSCollections = "GetSCollections",
  GetSCollection = "GetSCollection",
  GetInboxMainProductIds = "GetInboxMainProductIds",
  GetAuthorizedMerchants = "GetAuthorizedMerchants",
  GetCollections = "GetCollections",
  GetLongTailCollections = "GetLongTailCollections",
  GetLongTailCollectionCount = "GetLongTailCollectionCount",
  GetCampaigns = "GetCampaigns",
  GetCollection = "GetCollection",
  GetSearchStats = "GetSearchStats",
  GetContentSearch = "GetContentSearch",
  GetCentraConfiguredMarkets = "GetCentraConfiguredMarkets",
  GetCentraStores = "GetCentraStores",
  GetCategoryProducts = "GetCategoryProducts",
  GetCategoryProductsOrder = "GetCategoryProductsOrder",
  GetGetCategoryPreviewProducts = "GetGetCategoryPreviewProducts",
  GetCategoryProductIds = "GetCategoryProductIds",
  GetCategory = "GetCategory",
  GetCollectionProducts = "GetCollectionProducts",
  GetCategories = "GetCategories",
  GetMerchantProducts = "GetMerchantProducts",
  GetCatalogProducts = "GetCatalogProducts",
  GetCatalogSyncStatus = "GetCatalogSyncStatus",
  GetPairs = "GetPairs",
  GetBoostAndBuryRules = "GetBoostAndBuryRules",
  GetAvailableDimensionKeys = "GetAvailableDimensionKeys",
  GetAvailableShopifyMarkets = "GetAvailableShopifyMarkets",
  GetAvailableShopifyMetafieldDefinitions = "GetAvailableShopifyMetafieldDefinitions",
  GetAvailableCentraAttributes = "GetAvailableCentraAttributes",
  ProductCatalogSearchAndFilterProvider = "ProductCatalogSearchAndFilterProvider",
  GetCatalogProductFilters = "GetCatalogProductFilters",
  GetCatalogProductMetadata = "GetCatalogProductMetadata",
  GetRuleBasedCollections = "GetRuleBasedCollections",
  GetRuleBasedCollection = "GetRuleBasedCollection",
  GetCollectionByQueryId = "GetCollectionByQueryId",
  GetShopifyApiCredentials = "GetShopifyApiCredentials",
  GetSynonyms = "GetSynonyms",
  GetCategorySearchResults = "GetCategorySearchResults",
  GetAvailableSorts = "GetAvailableSorts",
  GetIntegrations = "GetIntegrations",
  GetProductSpotlightMetrics = "GetProductSpotlightMetrics",
  GetProductsByItemGroupId = "GetProductsByItemGroupId",
  GetProductRecommendations = "GetProductRecommendations",
  GetAutoHide = "GetAutoHide",
  GetMaintenance = "GetMaintenance",
}

export const categoryQueries: QueryId[] = [
  QueryId.GetCategory,
  QueryId.GetCategoryProducts,
  QueryId.GetCategoryProductsOrder,
  QueryId.GetGetCategoryPreviewProducts,
  QueryId.GetCategoryProductIds,
  QueryId.GetCategorySearchResults,
];

export const queryKeyBuilders = {
  [QueryId.GetPlpMetrics]: (
    merchantId: string,
    listingId: string,
    timePeriod?: TimePeriod
  ) => [QueryId.GetPlpMetrics, merchantId, listingId, timePeriod],
  [QueryId.GetProductSpotlightMetrics]: (merchantId: string) => [
    QueryId.GetProductSpotlightMetrics,
    merchantId,
  ],
  [QueryId.GetAutoHide]: (merchantId: string) => [
    QueryId.GetAutoHide,
    merchantId,
  ],
  [QueryId.GetProductRecommendations]: (
    merchantId: string,
    productId: string
  ) => [QueryId.GetProductsByItemGroupId, merchantId, productId],
  [QueryId.GetProductsByItemGroupId]: (
    merchantId: string,
    itemGroupId: string
  ) => [QueryId.GetProductsByItemGroupId, merchantId, itemGroupId],
  [QueryId.GetNumberOfProducts]: (merchantId: string) => [
    QueryId.GetNumberOfProducts,
    merchantId,
  ],
  [QueryId.GetNumberOfSalesProducts]: (merchantId: string) => [
    QueryId.GetNumberOfSalesProducts,
    merchantId,
  ],
  [QueryId.GetSCollections]: (
    merchantId: string,
    accessCategoryV3: boolean
  ) => [QueryId.GetSCollections, merchantId, accessCategoryV3],
  [QueryId.GetSCollection]: (merchantId: string, collectionId: string) => [
    QueryId.GetSCollection,
    merchantId,
    collectionId,
  ],
  [QueryId.GetInboxMainProductIds]: (merchantId: string) => [
    QueryId.GetInboxMainProductIds,
    merchantId,
  ],
  [QueryId.GetAuthorizedMerchants]: () => [QueryId.GetAuthorizedMerchants],
  [QueryId.GetCollections]: (merchantId: string) => [
    QueryId.GetCollections,
    merchantId,
  ],
  [QueryId.GetLongTailCollections]: (
    marketId: string,
    merchantId: string,
    offset: number,
    limit: number,
    sortBy: string,
    sortDirection: string
  ) => [
    QueryId.GetLongTailCollections,
    marketId,
    merchantId,
    offset,
    limit,
    sortBy,
    sortDirection,
  ],
  [QueryId.GetLongTailCollectionCount]: (
    marketId: string,
    merchantId: string
  ) => [QueryId.GetLongTailCollectionCount, marketId, merchantId],
  [QueryId.GetCampaigns]: (merchantId: string) => [
    QueryId.GetCampaigns,
    merchantId,
  ],
  [QueryId.GetCollection]: (merchantId: string, collectionId: string) => [
    QueryId.GetCollection,
    merchantId,
    collectionId,
  ],

  [QueryId.GetSearchStats]: (
    merchantId: string,
    period: {
      start: number;
      end: number;
    }
  ) => [QueryId.GetSearchStats, merchantId, period],
  [QueryId.GetContentSearch]: (merchantId: string, localeId: string) => [
    QueryId.GetContentSearch,
    merchantId,
    localeId,
  ],
  [QueryId.GetSynonyms]: (merchantId: string, localeId: string) => [
    QueryId.GetSynonyms,
    merchantId,
    localeId,
  ],
  [QueryId.GetCentraConfiguredMarkets]: (merchantId: string) => [
    QueryId.GetCentraConfiguredMarkets,
    merchantId,
  ],
  [QueryId.GetCentraStores]: (merchantId: string) => [
    QueryId.GetCentraStores,
    merchantId,
  ],
  [QueryId.GetCategoryProducts]: (
    merchantId: string,
    categoryId: string,
    querySalt: string
  ) => [QueryId.GetCategoryProducts, merchantId, categoryId, querySalt],
  [QueryId.GetCategoryProductsOrder]: (
    merchantId: string,
    categoryId: string,
    querySalt: string,
    sortBy: { field: string; order: string } | undefined,
    rankingType: string | undefined
  ) => [
    QueryId.GetCategoryProductsOrder,
    merchantId,
    categoryId,
    querySalt,
    sortBy?.field,
    sortBy?.order,
    rankingType,
  ],
  [QueryId.GetGetCategoryPreviewProducts]: (
    merchantId: string,
    categoryId: string,
    querySalt: string,
    sortBy?: { field: string; order: string }
  ) => [
    QueryId.GetGetCategoryPreviewProducts,
    merchantId,
    categoryId,
    querySalt,
    sortBy?.field,
    sortBy?.order,
  ],
  [QueryId.GetCategoryProductIds]: (
    merchantId: string,
    categoryId: string,
    querySalt: string
  ) => [QueryId.GetCategoryProductIds, merchantId, categoryId, querySalt],
  [QueryId.GetCategory]: (
    merchantId: string,
    categoryId: string,
    querySalt: string
  ) => [QueryId.GetCategory, merchantId, categoryId, querySalt],
  [QueryId.GetCollectionProducts]: (
    merchantId: string,
    collectionId: string
  ) => [QueryId.GetCollectionProducts, merchantId, collectionId],
  [QueryId.GetCategories]: (merchantId: string, querySalt: string) => [
    QueryId.GetCategories,
    merchantId,
    querySalt,
  ],
  [QueryId.GetMerchantProducts]: (merchantId: string) => [
    QueryId.GetMerchantProducts,
    merchantId,
  ],
  [QueryId.GetCatalogProducts]: (merchantId: string, localeId: string) => [
    QueryId.GetCatalogProducts,
    merchantId,
    localeId,
  ],
  [QueryId.GetCatalogSyncStatus]: (merchantId: string) => [
    QueryId.GetCatalogSyncStatus,
    merchantId,
  ],
  [QueryId.GetPairs]: (
    merchantId: string,
    collectionId: string,
    chunkType: string
  ) => [QueryId.GetPairs, merchantId, collectionId, chunkType],
  [QueryId.GetBoostAndBuryRules]: (merchantId: string) => [
    QueryId.GetBoostAndBuryRules,
    merchantId,
  ],
  [QueryId.GetAvailableDimensionKeys]: (merchantId: string) => [
    QueryId.GetAvailableDimensionKeys,
    merchantId,
  ],
  [QueryId.GetAvailableShopifyMarkets]: (
    merchantId: string,
    adminApiUrl: string,
    adminApiKey: string
  ) => [
    QueryId.GetAvailableShopifyMarkets,
    merchantId,
    adminApiUrl,
    adminApiKey,
  ],
  [QueryId.GetAvailableShopifyMetafieldDefinitions]: (
    merchantId: string,
    adminApiUrl: string,
    adminApiKey: string
  ) => [
    QueryId.GetAvailableShopifyMetafieldDefinitions,
    merchantId,
    adminApiUrl,
    adminApiKey,
  ],
  [QueryId.GetAvailableCentraAttributes]: (
    merchantId: string,
    adminApiUrl: string,
    adminApiKey: string
  ) => [
    QueryId.GetAvailableCentraAttributes,
    merchantId,
    adminApiUrl,
    adminApiKey,
  ],
  [QueryId.ProductCatalogSearchAndFilterProvider]: (
    merchantId: string,
    searchTerm: string,
    selectedFilters: SearchFilter[],
    allowedMainProductIds: string[],
    selectedSort: PortalSortModel,
    currentView: string
  ) => [
    QueryId.ProductCatalogSearchAndFilterProvider,
    merchantId,
    searchTerm,
    selectedFilters,
    allowedMainProductIds,
    selectedSort,
    currentView,
  ],
  [QueryId.GetCatalogProductFilters]: (merchantId: string) => [
    QueryId.GetCatalogProductFilters,
    merchantId,
  ],
  [QueryId.GetCatalogProductMetadata]: (merchantId: string) => [
    QueryId.GetCatalogProductMetadata,
    merchantId,
  ],
  [QueryId.GetRuleBasedCollections]: (merchantId: string, locale: string) => [
    QueryId.GetRuleBasedCollections,
    merchantId,
    locale,
  ],
  [QueryId.GetRuleBasedCollection]: (
    merchantId: string,
    collectionId: string,
    locale: string
  ) => [QueryId.GetRuleBasedCollection, merchantId, collectionId, locale],
  [QueryId.GetCollectionByQueryId]: (merchantId: string, queryId: string) => [
    QueryId.GetCollectionByQueryId,
    merchantId,
    queryId,
  ],
  [QueryId.GetShopifyApiCredentials]: (merchantId: string) => [
    QueryId.GetShopifyApiCredentials,
    merchantId,
  ],
  [QueryId.GetCategorySearchResults]: (
    merchantId: string,
    categoryId: string
  ) => [QueryId.GetCategorySearchResults, merchantId, categoryId],
  [QueryId.GetAvailableSorts]: (merchantId: string, querySalt: string) => [
    QueryId.GetAvailableSorts,
    merchantId,
    querySalt,
  ],
  [QueryId.GetIntegrations]: (merchantId: string) => [
    QueryId.GetIntegrations,
    merchantId,
  ],
  [QueryId.GetMaintenance]: () => [QueryId.GetMaintenance],
} as const;

type QueryKeyArgs = {
  [K in QueryId]: Parameters<(typeof queryKeyBuilders)[K]>;
};

export function getQueryKey<K extends QueryId>(
  queryId: K,
  ...args: QueryKeyArgs[K]
): QueryKey {
  // @ts-expect-error
  return queryKeyBuilders[queryId](...args);
}

import { css } from "@emotion/react";
import { EyeClosed, PushPin, Sparkle, Trash } from "@phosphor-icons/react";
import { useMemo } from "react";
import { Row } from "react-bootstrap";
import { MetadataInner } from "src/api/types";
import ProductEmptyImage from "src/assets/img/product-empty.svg";
import { ProductMetadata } from "../../../../helpers/catalog/ProductMetadataProvider";
import { useCatalogMetadata } from "../../../../helpers/hooks/catalog/useCatalogMetadata";
import Dropdown, { Item } from "../../Dropdown/Dropdown";
import { useImageResize } from "../../ImageResizeProvider/ImageResizeProvider";
import ProductBadge from "../../ProductBadge/ProductBadge";
import { Checkbox } from "../../Checkbox/Checkbox";
import { theme } from "../../designSystemVariables";
import { OnSelect } from "../../BulkEditBar/BulkEditBar.types";
import useSelectionHandler from "src/helpers/hooks/useSelectionHandler";
import { noSelectCss, noWrapCss } from "../../css.helper";
import styled from "@emotion/styled";
import { CurationCorner } from "./CurationCorner";
import { common } from "../../common";

export const IMAGE_SIZE = "45px";

type ActionCB = (mainProductId: string) => Promise<void> | void;

export const HoverableContainer = styled.div`
  position: relative;
`;

export interface PinningOptions {
  pinningAllowed: boolean;
  pinned: boolean;
}

export interface HidingOptions {
  hidingAllowed: boolean;
  hidden: boolean;
}

export interface CategoryProductListCardProps {
  product: {
    title: string;
    image_url?: string | null;
    main_product_id: string;
  };
  index: number;
  disableDropdown?: boolean;
  disableIndex?: boolean;
  pinningOptions: PinningOptions;
  hidingOptions: HidingOptions;
  actionCBs: {
    onPinToTop: ActionCB | undefined;
    onPinClick: ActionCB | undefined;
    onHideClick: ActionCB | undefined;
    onAiSortedClick: ActionCB | undefined;
    onSelectClick?: OnSelect;
    onRemoveClick: ActionCB | undefined;
  };
  showSelectCheckbox: boolean;
  selected?: boolean;
  extraStyles?: React.CSSProperties;
  highlight: boolean;
  isInSelectMode: boolean;
  aiSorted: boolean;
  isAutoHidden?: boolean;
}

type MetadataType = MetadataInner[number]["type"];

const categoryRelevantMetadataTypes: MetadataType[] = [
  "out_of_stock",
  "low_stock",
  "on_sale",
  "new_in",
  "bestseller",
  "trending",
  "slow_mover",
];

export const CategoryProductListCard = (
  props: CategoryProductListCardProps
) => {
  const isAutoHidden = props.isAutoHidden || false;

  const selectionHandler = useSelectionHandler(
    props.actionCBs.onSelectClick,
    Boolean(props.selected),
    props.product.main_product_id,
    isAutoHidden
  );
  const { getImageUrl } = useImageResize();
  const oneBasedIndex = props.index + 1;
  const index = oneBasedIndex.toString().padStart(2, "0");
  const { productMetadata: allProductMetadata } = useCatalogMetadata();
  const productMetadata: ProductMetadata[] = useMemo(() => {
    return allProductMetadata?.[props.product.main_product_id] ?? [];
  }, [allProductMetadata, props.product.main_product_id]);
  const collectionRelevantMetadata = productMetadata.filter((md) =>
    categoryRelevantMetadataTypes.includes(md.type)
  );
  const { pinned } = props.pinningOptions;

  const displayCurationCorner =
    props.actionCBs.onPinToTop &&
    props.actionCBs.onHideClick &&
    props.actionCBs.onAiSortedClick;

  const aiSorted = props.aiSorted;

  const dropdownItems = useMemo(() => {
    const items: Item[] = [];

    const firstItem = props.index === 0;

    const { hidden } = props.hidingOptions;

    const canPinToTop = !pinned;

    const canMoveToTop = pinned && !firstItem;

    if (canMoveToTop) {
      items.push({
        text: "Move to top",
        onClick: () =>
          props.actionCBs.onPinToTop?.(props.product.main_product_id),
        icon: <PushPin />,
      });
    }

    if (canPinToTop) {
      items.push({
        text: "Pin to top",
        onClick: () =>
          props.actionCBs.onPinToTop?.(props.product.main_product_id),
        icon: <PushPin weight="fill" />,
      });
    }

    if (!pinned) {
      items.push({
        text: "Pin",
        onClick: () =>
          props.actionCBs.onPinClick?.(props.product.main_product_id),
        icon: <PushPin />,
      });
    }

    if (pinned || hidden) {
      items.push({
        text: "AI sorted",
        onClick: () =>
          props.actionCBs.onAiSortedClick?.(props.product.main_product_id),
        icon: <Sparkle />,
      });
    }

    if (!hidden) {
      items.push({
        text: "Hide",
        onClick: () =>
          props.actionCBs.onHideClick?.(props.product.main_product_id),
        icon: <EyeClosed />,
      });
    }

    if (props.actionCBs.onRemoveClick) {
      items.push({
        text: "Remove",
        onClick: () =>
          props.actionCBs.onRemoveClick?.(props.product.main_product_id),
        icon: <Trash />,
      });
    }

    return items;
  }, [
    pinned,
    props.actionCBs,
    props.hidingOptions,
    props.index,
    props.product.main_product_id,
  ]);

  const dropdown = props.disableDropdown ? (
    <></>
  ) : (
    <Dropdown
      key_={props.product.main_product_id}
      closeWhenOtherDropdownOpens={true}
      items={dropdownItems}
      disabled={props.isInSelectMode}
    />
  );

  const getOutline = () => {
    if (props.selected) {
      return `2px solid ${theme.border.base.default}`;
    }
    if (props.highlight) {
      return `2px solid ${theme.textIcon.accent.default}`;
    }
    return "none";
  };

  const getBorder = () => {
    if (aiSorted) {
      return `1px solid #879BE5`;
    } else {
      return `1px solid ${theme.border.subtle.default}`;
    }
  };

  const getBackgroundColor = () => {
    if (aiSorted) {
      return "linear-gradient(219deg, rgba(135, 155, 229, 0.22) 0%, rgba(175, 149, 232, 0.00) 100%), #FFF";
    }
    return theme.background.base.default;
  };

  return (
    <div
      onClick={selectionHandler}
      css={[
        noSelectCss,
        css`
          outline: ${getOutline()};
          border-radius: ${common.cardBorderRadius};
          border: ${getBorder()};
          background: ${getBackgroundColor()};
        `,
        { ...props.extraStyles },
      ]}
    >
      <HoverableContainer>
        <div
          css={css`
            border-radius: ${common.cardBorderRadius};
            display: flex;
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 16px;
            padding-right: 16px;
            align-items: stretch;
            width: 100%;
            justify-content: space-between;
            gap: 16px;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 0.5rem;
            `}
          >
            {props.showSelectCheckbox && !isAutoHidden && (
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <Checkbox
                  checked={Boolean(props.selected)}
                  onChange={(alreadyChecked) => {
                    props.actionCBs.onSelectClick?.(
                      props.product.main_product_id,
                      alreadyChecked
                    );
                  }}
                  style={{ margin: 0 }}
                />
              </div>
            )}
            {!props?.disableIndex && (
              <strong
                css={css`
                  display: flex;
                  justify-content: center;
                  color: #000;
                `}
              >
                {index}
              </strong>
            )}
            <div
              css={css`
                width: ${IMAGE_SIZE};
                height: ${IMAGE_SIZE};
              `}
            >
              <img
                alt="Product"
                className="rounded"
                loading={"lazy"}
                src={getImageUrl(props.product.image_url ?? "")}
                css={css`
                  width: ${IMAGE_SIZE};
                  height: ${IMAGE_SIZE};
                  object-fit: cover;
                `}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = ProductEmptyImage;
                }}
              />
            </div>
          </div>
          <div
            css={css`
              flex-grow: 1;
              white-space: nowrap;
              overflow: hidden;
            `}
          >
            <Row>
              <strong
                css={[
                  css`
                    color: #000;
                  `,
                  noWrapCss,
                ]}
              >
                {props.product.title}
              </strong>
            </Row>
            <Row>
              <small
                css={css`
                  color: ${theme.textIcon.subtle.default};
                `}
              >
                {props.product.main_product_id}
              </small>
            </Row>
          </div>
          <div
            css={css`
              display: flex;
              justify-content: end;
              align-items: center;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 0.25rem;
              `}
            >
              {collectionRelevantMetadata?.map((md, index) => (
                <ProductBadge
                  metadata={md}
                  key={`${md.type}-${index}`}
                  oos_auto_hide={isAutoHidden}
                />
              ))}
              {isAutoHidden && <EyeClosed />}
            </div>
            {displayCurationCorner && !isAutoHidden && (
              <CurationCorner {...props} />
            )}
            {!isAutoHidden && <div data-no-dnd="true">{dropdown}</div>}
          </div>
        </div>
      </HoverableContainer>
    </div>
  );
};

export default CategoryProductListCard;

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Scissors = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M8.5 12c-0.132 0-0.26-0.053-0.353-0.147s-0.147-0.222-0.147-0.353 0.053-0.261 0.147-0.353c0.093-0.093 0.222-0.147 0.353-0.147s0.261 0.053 0.353 0.147c0.093 0.093 0.147 0.222 0.147 0.353s-0.053 0.261-0.147 0.353c-0.093 0.093-0.222 0.147-0.353 0.147z" fill={color}></path>
<path d="M19.313 14.915c-0.748-1.183-1.946-1.919-3.126-1.919-0.488 0-0.955 0.132-1.351 0.382-0.632 0.399-1.043 1.058-1.174 1.869l-1.308-2.016c-0.003-0.004-0.006-0.009-0.008-0.013l-8.427-12.99c-0.092-0.142-0.25-0.228-0.419-0.228-0 0-0.001 0-0.001 0-0.17 0.001-0.328 0.087-0.42 0.23l-0.742 1.158c-0.393 0.614-0.452 1.572-0.136 2.23l4.416 9.197c0.319 0.665 1.146 1.185 1.884 1.185l3.159-0.003 2.578 3.975c0.022 0.037 0.044 0.074 0.067 0.11 0.748 1.183 1.946 1.919 3.126 1.919 0 0 0 0 0 0 0.488 0 0.955-0.132 1.35-0.382 0.69-0.436 1.117-1.181 1.203-2.097 0.081-0.867-0.157-1.793-0.671-2.607zM8.5 13c-0.359 0-0.827-0.295-0.982-0.618l-4.416-9.197c-0.163-0.34-0.127-0.94 0.077-1.257l0.323-0.505 7.508 11.574-2.511 0.003zM18.989 17.428c-0.056 0.6-0.32 1.078-0.742 1.345-0.238 0.151-0.513 0.227-0.816 0.227-0 0-0 0-0 0-0.773-0-1.605-0.498-2.166-1.283l-0.175-0.27c-0.355-0.602-0.518-1.264-0.46-1.879 0.056-0.6 0.32-1.078 0.742-1.345 0.238-0.151 0.513-0.227 0.816-0.227 0.828 0 1.723 0.57 2.281 1.453 0.397 0.628 0.582 1.33 0.521 1.979z" fill={color}></path>
<path d="M13.5 11.917c-0.073 0-0.146-0.016-0.216-0.049-0.249-0.12-0.354-0.418-0.234-0.667l3.848-8.016c0.163-0.34 0.127-0.94-0.077-1.257l-0.323-0.505-4.578 7.057c-0.15 0.232-0.46 0.298-0.692 0.147s-0.298-0.46-0.147-0.692l5-7.708c0.092-0.142 0.25-0.228 0.419-0.228 0 0 0.001 0 0.001 0 0.17 0.001 0.328 0.087 0.42 0.23l0.742 1.158c0.393 0.614 0.452 1.572 0.136 2.23l-3.848 8.016c-0.086 0.179-0.265 0.284-0.451 0.284z" fill={color}></path>
<path d="M7.042 15.081c-0.232-0.15-0.541-0.084-0.692 0.147l-0.013 0.020c-0.131-0.812-0.542-1.47-1.174-1.869-0.395-0.25-0.862-0.382-1.351-0.382-1.18 0-2.378 0.735-3.126 1.919-0.514 0.813-0.753 1.739-0.671 2.607 0.086 0.916 0.513 1.66 1.203 2.097 0.395 0.25 0.862 0.382 1.35 0.382 1.18 0 2.378-0.735 3.126-1.919 0.023-0.036 0.045-0.073 0.067-0.11l1.427-2.2c0.15-0.232 0.084-0.541-0.147-0.692zM2.569 19c-0.303 0-0.578-0.076-0.816-0.227-0.422-0.267-0.686-0.744-0.742-1.345-0.061-0.649 0.124-1.351 0.521-1.979 0.558-0.883 1.453-1.453 2.281-1.453 0.303 0 0.578 0.076 0.816 0.227 0.422 0.267 0.686 0.744 0.742 1.345 0.058 0.614-0.106 1.277-0.46 1.878l-0.175 0.27c-0.561 0.785-1.393 1.283-2.166 1.283z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Scissors); 
export function getColorLuminanceFromHex(hex: string) {
  // Convert hex to RGB first
  let r;
  let g;
  let b;

  if (hex.length === 4) {
    // 3 digits
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    // 6 digits
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  } else {
    return -1; // Invalid format
  }

  // Calculate the perceived luminance
  return (0.299 * r + 0.587 * g + 0.114 * b) / 255;
}

import logo from "src/assets/img/logo-symbol.svg";
import "./LoadingBadge.scss";

export default function LoadingBadge({ label }: { label: string }) {
  return (
    <div className="p-3 rounded d-flex align-items-center bg-gray-100">
      <div className="rounded-circle bg-light p-1 me-3 d-inline-flex justify-content-center align-items-center depict-gradient">
        <img src={logo} className="rotate" width={20} height={20} />
      </div>
      <div>{label}</div>
    </div>
  );
}

import { css } from "@emotion/react";
import { FC } from "react";
import { theme } from "../../designSystemVariables";
import {
  CategoryHeaderTop,
  CategoryHeaderTopProps,
} from "../CategoryHeaderTop/CategoryHeaderTop";
import {
  CategoryHeaderBottom,
  CategoryHeaderBottomProps,
} from "../CategoryHeaderBottom/CategoryHeaderBottom";
import ListingMetricBanner, {
  PlpMetric,
  TimePeriod,
} from "../../ListingMetricBanner/ListingMetricBanner";

type CategoryHeaderProps = CategoryHeaderTopProps &
  CategoryHeaderBottomProps & {
    metricsAreLoading: boolean;
    metrics: PlpMetric[];
    metricTimePeriod: TimePeriod;
    onSetMetricTimePeriod: (timePeriod: TimePeriod) => void;
  };

export const headerId = "category-header";

export const CategoryHeader: FC<CategoryHeaderProps> = ({
  showPlaceholders,
  showTopPlaceholders,
  metrics,
  metricTimePeriod,
  onSetMetricTimePeriod,
  isSaving,
  canSave,
  canRevert,
  isAbleToAddProducts,
  canEditMetaData,
  showAddProductsModal,
  canAddSubCategory,
  rootCategories,
  targetCategory,
  merchant,
  totalProductCount,
  allLocalProductIds,
  subCategories,
  rules,
  metricsAreLoading,
  title,
  availableFilters,
  availableSorts,
  rankingType,
  selectedFilter,
  selectedSort,
  selectedView,
  createSubCategoryLoading,
  onBreadcrumbClick,
  onSubCategoryClick,
  onCreateSubCategory,
  onSave,
  onRevert,
  onEdit,
  onChangeTitle,
  onOpenAddProductsModal,
  onCloseAddProductsModal,
  onSaveAddProductsModal,
  onSelectFilter,
  onSelectSort,
  onSetSearchQuery,
  onToggleView,
  onToggleFiltersOpen,
  menuItemComponent,
  expandSubCategoriesOnHover,
  nbColumnSwitcher
}) => (
  <div
    id={headerId}
    css={css`
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      background: ${theme.background.base.default};
      padding: 1rem;
      padding-bottom: 0;
      border-bottom: 1px solid ${theme.border.subtle.disabled};
      border-radius: 24px 24px 0 0;
    `}
  >
    <CategoryHeaderTop
      showPlaceholders={showPlaceholders}
      showTopPlaceholders={showTopPlaceholders}
      isSaving={isSaving}
      canSave={canSave}
      canRevert={canRevert}
      isAbleToAddProducts={isAbleToAddProducts}
      canEditMetaData={canEditMetaData}
      showAddProductsModal={showAddProductsModal}
      canAddSubCategory={canAddSubCategory}
      rootCategories={rootCategories}
      targetCategory={targetCategory}
      merchant={merchant}
      totalProductCount={totalProductCount}
      allLocalProductIds={allLocalProductIds}
      title={title}
      subCategories={subCategories}
      rules={rules}
      createSubCategoryLoading={createSubCategoryLoading}
      onBreadcrumbClick={onBreadcrumbClick}
      onSubCategoryClick={onSubCategoryClick}
      onCreateSubCategory={onCreateSubCategory}
      onSave={onSave}
      onRevert={onRevert}
      onEdit={onEdit}
      onChangeTitle={onChangeTitle}
      onOpenAddProductsModal={onOpenAddProductsModal}
      onCloseAddProductsModal={onCloseAddProductsModal}
      onSaveAddProductsModal={onSaveAddProductsModal}
      expandSubCategoriesOnHover={expandSubCategoriesOnHover}
    />
    {!merchant?.id.includes("demo") &&
      !(!metricsAreLoading && metrics.length === 0) && (
        <ListingMetricBanner
          isLoading={metricsAreLoading}
          timePeriod={metricTimePeriod}
          onSetTimePeriod={onSetMetricTimePeriod}
          metrics={metrics}
        />
      )}
    <CategoryHeaderBottom
      showPlaceholders={showPlaceholders}
      availableFilters={availableFilters}
      availableSorts={availableSorts}
      rankingType={rankingType}
      selectedFilter={selectedFilter}
      selectedSort={selectedSort}
      selectedView={selectedView}
      totalProductCount={totalProductCount}
      onSelectFilter={onSelectFilter}
      onSelectSort={onSelectSort}
      onSetSearchQuery={onSetSearchQuery}
      onToggleView={onToggleView}
      onToggleFiltersOpen={onToggleFiltersOpen}
      menuItemComponent={menuItemComponent}
      nbColumnSwitcher={nbColumnSwitcher}
    />
  </div>
);

import { css } from "@emotion/react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  BasePortalProductDto,
  CollectionListDto,
  CollectionType,
  SortModel,
} from "src/api/types";
import {
  CategoryHeader,
  headerId,
} from "src/components/storybook/Categories/CategoryHeader/CategoryHeader";
import { ImageResizeProvider } from "src/components/storybook/ImageResizeProvider/ImageResizeProvider";
import {
  ProductsDispatchContext,
  ProductsProvider,
} from "src/components/storybook/SortableComponents/ProductsProvider/ProductsProvider";

import { Plus } from "@phosphor-icons/react";
import { PiTShirtThin } from "react-icons/pi";
import { scroller } from "react-scroll";
import { EmptyProductCard } from "src/components/cards/EmptyProductCard";
import {
  GetCategorySearchResult,
  useCategorySearch,
} from "src/components/categories_v2_v3_common/Category/useCategorySearch";
import {
  CategoryConfigModalContext,
  CategoryConfigModalProvider,
  SaveConfigCallback,
} from "src/components/categories_v2_v3_common/CategoryConfigModal/CategoryConfigModalProvider";
import { useAlerts } from "src/components/storybook/Alert/useAlerts";
import BulkEditBarProvider, {
  BulkEditBarContext,
} from "src/components/storybook/BulkEditBar/BulkEditBar.provider";
import ConfirmationDialog from "src/components/storybook/ConfirmationDialog/ConfirmationDialog";
import { IconButton } from "src/components/storybook/IconButton/IconButton";
import ProductListViewPlaceholder from "src/components/storybook/ProductListViewPlaceholder/ProductListViewPlaceholder";
import ProductGridViewPlaceholder from "src/components/storybook/SortableComponents/ProductGridViewSortable/ProductGridViewPlaceholder/ProductGridViewPlaceholder";
import { ActionType as ProductsActionType } from "src/components/storybook/SortableComponents/ProductsProvider/ProductsProvider";
import { depictCardClassName } from "src/components/storybook/common";
import { theme } from "src/components/storybook/designSystemVariables";
import { ProductMetadataProvider } from "src/helpers/catalog/ProductMetadataProvider";
import { useDepictBlocker } from "src/helpers/hooks/app/useDepictBlocker";
import useMerchant from "src/helpers/hooks/app/useMerchant";
import {
  GetAvailableSortsConfig,
  useAvailableSorts,
} from "src/helpers/hooks/categories_v2_v3_common/useAvailableSort/useAvailableSort";
import { sortCategories } from "src/helpers/hooks/categories_v2_v3_common/useCategories/useCategories";
import {
  GetCollectionConfig,
  useCategory,
} from "src/helpers/hooks/categories_v2_v3_common/useCategory/useCategory";
import useShowIntercom from "src/helpers/hooks/intercom/useShowIntercom";
import { useCachedState } from "src/helpers/hooks/useCachedState";
import useListingItems from "src/helpers/hooks/useListingItems";
import { useOnSelectCB } from "src/helpers/hooks/useOnSelectCB";
import useOnValueChange from "src/helpers/hooks/useOnValueChange";
import useUser from "src/helpers/hooks/useUser";
import countElementsInView from "src/helpers/misc/countElementsInView";
import { mainId } from "src/layouts/SidenavLayout";
import queryClient from "src/queries/client";
import { QueryId, getQueryKey } from "src/queries/queries";
import ProtectedRoute, { AccessValidator } from "src/router/ProtectedRoute";
import { v4 as uuidv4 } from "uuid";
import {
  CategoryRankingOrderOptionType,
  RankingOption,
} from "../../storybook/Categories/CategoryRankingOrder/CategoryRankingOrderOption";
import { TimePeriod } from "../../storybook/ListingMetricBanner/ListingMetricBanner";
import { SortStateSimple } from "../../storybook/types";
import { OnSaveCB } from "../AddProductsModal";
import {
  CategoriesNavigationFunction,
  CategoryNavigationFunction,
} from "../types";
import {
  SectionKey,
  SectionSpacer,
  SectionWrapper,
} from "./AccordionSectionWrapper";
import { getSearchedGridViewProducts } from "./Category.helper";
import {
  ActionType as CategoryActionType,
  CategoryDispatchContext,
  CategoryProvider,
  CategorySelectorsContext,
} from "./Category.provider";
import CategoryBulkEditBar, {
  CategoryBulkEditBarProps,
} from "./CategoryBulkEditBar";
import {
  CategoryTransitionGridAnimator,
  RankingAnimationStateConfig,
} from "./CategoryTransitionGridAnimator";
import { ItemTypes, Menu } from "./Menu/Menu";
import ProductDisplayer from "./ProductDisplayer";
import RankingOptions from "./RankingOptions";
import { SortableView } from "./SortableView";
import "./category.scss";
import {
  GetCuratedProductsConfig,
  useCategoryCuratedProducts,
} from "./useCategoryCuratedProducts";
import useCategoryId from "./useCategoryId";
import {
  GetNotCuratedOrderProductsConfig,
  ProductOrderData,
  getProductOrder,
  useCategoryNotCuratedProducts,
} from "./useCategoryNotCuratedProducts";
import { useComponentHeight } from "./useComponentHeight";
import { DeleteCategoryMutationFunc } from "./useDeleteCategory";
import {
  GetCategoryProductIdsConfig,
  useCategoryProductIds,
} from "./useGetCategoryProductIds";
import { usePreviewProducts } from "./usePreviewProducts";
import useRevertableChanges from "./useRevertableChanges";
import { SaveCategoryMutationFunc, useSaveCategory } from "./useSaveCategory";
import NbColumnsSwitcher from "./NbColumnsSwitcher/NbColumnsSwitcher";

export interface NavigationPaths {
  getCategoryPath: CategoryNavigationFunction;
  getCategoriesPath: CategoriesNavigationFunction;
}

export interface CategoryPageConfigProps {
  getCategory: GetCollectionConfig;

  navigationPaths: NavigationPaths;
  saveCategory: SaveCategoryMutationFunc;
  deleteCategory: DeleteCategoryMutationFunc | undefined;
  getNotCuratedProducts: GetNotCuratedOrderProductsConfig;
  getCuratedProducts: GetCuratedProductsConfig;

  saveConfig: SaveConfigCallback;

  getCategorySearchResults: GetCategorySearchResult;

  isAbleToAddProducts: boolean;
  canEditMetaData: boolean;
  isAbleToDelete: boolean;
  isAbleToRemoveProducts: boolean;

  accessValidator: AccessValidator;

  getCategoryProductIds: GetCategoryProductIdsConfig | undefined;
  getAvailableSorts: GetAvailableSortsConfig | undefined;

  availableRankingOptions: RankingOption[];
  rankingType: CategoryRankingOrderOptionType;
  collectionType: CollectionType | undefined;
  onCreateSubCategory:
    | {
        callback: () => Promise<void>;
        isLoading: boolean;
      }
    | undefined;

  baseCollection: {
    isLoading: boolean;
    data: CollectionListDto | undefined;
  };

  querySalt: string;
}

export interface CategoryProps {
  config: CategoryPageConfigProps;
}

export const relevanceSortingField = "_relevance";

const MemoizedCategoryTransitionGridAnimator = React.memo(
  CategoryTransitionGridAnimator
);

function InternalCategory(props: CategoryProps) {
  const [columnSize, setColumnSize] = React.useState(4);
  const { categoryId } = useCategoryId();
  const { merchant } = useMerchant();
  const { user } = useUser();
  const navigate = useNavigate();
  const [selectedIds, setSelectedIds] = useState(new Set<string>());
  const { addAlert } = useAlerts();
  const {
    refetch: refetchListingItems,
    listingItems,
    isLoading: listingItemsLoading,
  } = useListingItems();

  const { elementToHookToRef, setShortcutsDisabled } =
    useContext(BulkEditBarContext);

  const [view, setView] = useCachedState<"list" | "grid">(
    "user_view_pref_categories",
    "grid"
  );

  const headerHeight = useComponentHeight({ selector: `#${headerId}` });

  const modalCtx = React.useContext(CategoryConfigModalContext);

  const [rankingAnimationConfig, setRankingAnimationConfig] =
    useState<RankingAnimationStateConfig>({
      initialProducts: [],
      endProducts: [],
      nbColumns: 0,
      chunkSize: 0,
      startAnimation: false,
      animationId: null,
    });

  const [showRankingOrderOptions, setShowRankingOrderOptions] = useState(false);

  const [openAddProducts, setOpenAddProducts] = React.useState(false);
  const [
    addedProductsDuringLastModalOpen,
    setAddedProductsDuringLastModalOpen,
  ] = React.useState<string[]>([]);

  const { category: serverCategory, refetch: refetchCategory } = useCategory(
    merchant?.id || null,
    categoryId,
    props.config.getCategory
  );

  const {
    isLoading,
    isError,
    curatedProductsDto,
    refetch: refetchCurated,
  } = useCategoryCuratedProducts(
    merchant?.id || null,
    categoryId,
    props.config.getCuratedProducts
  );

  const { data: categoryProductIds } = useCategoryProductIds(
    merchant?.id || null,
    categoryId,
    props.config.getCategoryProductIds
  );

  const { data: availableSorts } = useAvailableSorts(
    merchant?.id || null,
    props.config.getAvailableSorts
  );

  const {
    totalProductCount,
    productsByRelevance,
    pinnedProducts,
    hiddenProducts,
    isLocalStateDifferentThanServer,
    addedProducts,
    removedProducts,
    allLocalProductIds,
    title,
    curatedProductIds,
    hasHiddenChanged,
    hasPinnedChanged,
    titleIsValid,
    productSortMethod,
    rankingType,
    isSaving: isSavingCategory,
    autoHiddenProducts,
    manuallyHiddenProducts,
  } = useContext(CategorySelectorsContext);

  const duplicateProducts = useMemo(() => {
    return allLocalProductIds.filter((id) => {
      return (
        allLocalProductIds.indexOf(id) !== allLocalProductIds.lastIndexOf(id)
      );
    });
  }, [allLocalProductIds]);

  if (duplicateProducts.length > 0) {
    console.log("duplicateProducts", duplicateProducts);
  }

  const dispatch = useContext(CategoryDispatchContext);

  const unselectAll = React.useCallback(() => {
    setSelectedIds(new Set());
  }, []);

  const productsToExcludeFromNotCurated = useMemo<string[] | undefined>(() => {
    if (!curatedProductsDto || curatedProductIds === null) {
      return undefined;
    }

    const allIds = new Set<string>([...curatedProductIds, ...removedProducts]);

    return Array.from(allIds);
  }, [curatedProductIds, curatedProductsDto, removedProducts]);

  const productsToExcludeFromSearch = useMemo<string[] | undefined>(() => {
    if (!curatedProductsDto) {
      return undefined;
    }

    const allIds = new Set<string>([...removedProducts]);

    return Array.from(allIds);
  }, [curatedProductsDto, removedProducts]);

  const productsToIncludeForNotCurated = useMemo<string[]>(() => {
    return addedProducts;
  }, [addedProducts]);

  const [mustRefetchNotCuratedProducts, setMustRefetchNotCuratedProducts] =
    React.useState<{
      value: boolean;
      origin:
        | "added_products"
        | "put_product_in_ai_sorted"
        | "new_ranking_type"
        | null;
      onBeforeFetch: (() => void) | null;
      onFetched: ((notCuratedProducts: BasePortalProductDto[]) => void) | null;
    }>({
      value: false,
      origin: null,
      onFetched: null,
      onBeforeFetch: null,
    });

  const isPreviewingProducts = Boolean(
    props.config.getAvailableSorts &&
      productSortMethod &&
      productSortMethod.field !== relevanceSortingField
  );

  const {
    notCuratedProducts,
    hasMoreNotCuratedProducts,
    getMoreNotCuratedProducts,
    refetch: refetchNotCuratedProducts,
    isError: isNotCuratedError,
    isFetchingNextPage: isFetchingNextNotCuratedPage,
  } = useCategoryNotCuratedProducts(
    merchant?.id || null,
    categoryId,
    productsToExcludeFromNotCurated,
    productsToIncludeForNotCurated,
    props.config.getNotCuratedProducts,
    productSortMethod,
    isPreviewingProducts,
    rankingType
  );

  const {
    previewProducts,
    hasMorePreviewProducts,
    getMorePreviewProducts,
    isLoading: isPreviewProductsLoading,
    isError: isPreviewError,
    isFetchingMore: isFetchingMorePreviewProducts,
  } = usePreviewProducts(
    merchant?.id || null,
    categoryId,
    props.config.getNotCuratedProducts,
    productSortMethod,
    isPreviewingProducts
  );

  const dispatchForCategory = useContext(CategoryDispatchContext);
  const dispatchForPinnedProducts = useContext(ProductsDispatchContext);

  const {
    setSearchQuery,
    loadMoreSearchResults,
    resetSearchQuery,
    hasMoreSearchResults,
    searchedCategoryProducts: searchedProducts,
    isLoading: isSearchLoading,
    isFetchingMore: isFetchingMoreSearchResults,
  } = useCategorySearch(
    merchant?.id || null,
    categoryId,
    props.config.getCategorySearchResults,
    productsToExcludeFromSearch,
    productsToIncludeForNotCurated,
    productSortMethod
  );

  const {
    showRevertConfirmation,
    onCloseRevertConfirmation,
    onRevertConfirmation,
    onRevert,
  } = useRevertableChanges({
    dispatch,
    unselectAll,
  });

  useEffect(() => {
    setMustRefetchNotCuratedProducts({
      value: true,
      origin: "added_products",
      onFetched: null,
      onBeforeFetch: null,
    });
  }, [addedProducts]);

  const cancelChunkingAnimation = useCallback(() => {
    setRankingAnimationConfig((prev) => {
      return {
        ...prev,
        startAnimation: false,
      };
    });
  }, [setRankingAnimationConfig]);

  useOnValueChange(isNotCuratedError, (isNotCuratedError) => {
    if (isNotCuratedError) {
      cancelChunkingAnimation();
    }
  });

  useOnValueChange(rankingType, () => {
    unselectAll();
    setView("grid");
  });

  useEffect(() => {
    if (!productSortMethod && availableSorts?.length) {
      dispatch({
        type: CategoryActionType.SET_PRODUCT_SORT_METHOD,
        payload: {
          productSortMethod: availableSorts.find(
            (sort) => sort.field === relevanceSortingField
          )!,
        },
      });
    }
  }, [availableSorts, productSortMethod, dispatch]);

  const onSearch = (query: string) => {
    if (query === "") {
      resetSearchQuery();
      return;
    }
    cancelChunkingAnimation();
    setSearchQuery(query);
  };

  const { saveCategory } = useSaveCategory(
    merchant?.id || null,
    categoryId,
    props.config.saveCategory
  );

  const pin = React.useCallback(
    (productIds: string[]) => {
      dispatchForCategory({
        type: CategoryActionType.PIN,
        payload: {
          productIds: productIds,
        },
      });
    },
    [dispatchForCategory]
  );

  const hide = React.useCallback(
    (productIds: string[]) => {
      dispatchForCategory({
        type: CategoryActionType.HIDE_PRODUCTS,
        payload: {
          productIds: productIds,
        },
      });
    },
    [dispatchForCategory]
  );

  const aiSortClick = React.useCallback(
    async (productIds: string[]) => {
      dispatchForCategory({
        type: CategoryActionType.AI_SORTED,
        payload: {
          productIds,
        },
      });

      // Delete all pages

      queryClient.setQueryData<ProductOrderData>(
        getQueryKey(
          QueryId.GetCategoryProductsOrder,
          merchant?.id || "",
          categoryId || "",
          props.config.querySalt,
          productSortMethod,
          rankingType || undefined
        ),
        (data) => {
          // Don't keep any page
          const keepNoPage = {
            pages: [],
            pageParams: [],
          };

          return keepNoPage;
        }
      );

      setMustRefetchNotCuratedProducts({
        value: true,
        origin: "put_product_in_ai_sorted",
        onFetched: null,
        onBeforeFetch: null,
      });
    },
    [
      categoryId,
      dispatchForCategory,
      merchant?.id,
      productSortMethod,
      props.config.querySalt,
      rankingType,
    ]
  );

  const pinToBeginning = React.useCallback(
    (productIds: string[]) => {
      dispatchForCategory({
        type: CategoryActionType.PIN_TO_BEGINNING,
        payload: {
          productIds,
        },
      });
    },
    [dispatchForCategory]
  );

  const onDragEnd = React.useCallback(
    (productIds: string[], newIndex: number) => {
      dispatchForCategory({
        type: CategoryActionType.REORDER_PINNED_PRODUCTS,
        payload: {
          productIds,
          newIndex,
        },
      });
      unselectAll();
    },
    [dispatchForCategory, unselectAll]
  );

  const onSelectSort = React.useCallback(
    (sort: SortModel) => {
      cancelChunkingAnimation();

      dispatchForCategory({
        type: CategoryActionType.SET_PRODUCT_SORT_METHOD,
        payload: {
          productSortMethod: sort,
        },
      });
    },
    [cancelChunkingAnimation, dispatchForCategory]
  );

  const select = useOnSelectCB(
    setSelectedIds,
    allLocalProductIds,
    autoHiddenProducts.map((p) => p.main_product_id)
  );

  const { baseCollection } = props.config;

  useOnValueChange(
    mustRefetchNotCuratedProducts,
    (mustRefetchNotCuratedProducts) => {
      if (mustRefetchNotCuratedProducts.value === false) return;

      const _refetchNotCuratedProducts = async () => {
        if (mustRefetchNotCuratedProducts.onBeforeFetch) {
          mustRefetchNotCuratedProducts.onBeforeFetch();
        }
        const results = await refetchNotCuratedProducts();

        if (mustRefetchNotCuratedProducts.onFetched) {
          const productOrder = getProductOrder(results.data);
          mustRefetchNotCuratedProducts.onFetched(productOrder);
        }

        setMustRefetchNotCuratedProducts(() => {
          return {
            value: false,
            origin: null,
            onFetched: null,
            onBeforeFetch: null,
          };
        });
      };

      _refetchNotCuratedProducts();
    }
  );

  useEffect(() => {
    if (baseCollection.data) {
      dispatchForCategory({
        type: CategoryActionType.SET_TITLE,
        payload: {
          title: baseCollection.data.title,
        },
      });
    }
  }, [baseCollection, dispatchForCategory]);

  useEffect(() => {
    dispatchForCategory({
      type: CategoryActionType.SET_RANKING_TYPE,
      payload: {
        rankingType: props.config.rankingType,
      },
    });
  }, [dispatchForCategory, props.config.rankingType]);

  const setCategoryTitle = React.useCallback(
    (title: string, valid: boolean) => {
      dispatchForCategory({
        type: CategoryActionType.SET_LOCAL_TITLE,
        payload: {
          title,
          isValid: valid,
        },
      });
    },
    [dispatchForCategory]
  );

  const onRemove = React.useCallback(
    (productIds: string[]) => {
      dispatchForCategory({
        type: CategoryActionType.REMOVE_PRODUCTS,
        payload: {
          productIds: productIds,
        },
      });
    },
    [dispatchForCategory]
  );

  const onCloseAddProductsModal = React.useCallback(
    async (addedProductsSinceOpen: string[]) => {
      setOpenAddProducts(false);
      setAddedProductsDuringLastModalOpen(addedProductsSinceOpen);
      setShortcutsDisabled(false);
    },
    [setShortcutsDisabled]
  );

  const onSaveAddProductsModal = React.useCallback<OnSaveCB>(
    async ({ addedItems }) => {
      dispatchForCategory({
        type: CategoryActionType.ADD_PRODUCTS,
        payload: {
          productIds: addedItems.map((p) => p.main_product_id),
        },
      });

      addAlert({
        type: "success",
        message: `Successfully added ${addedItems.length} products`,
        id: "add_products_success",
        autohide: true,
        exclusive: true,
      });
    },
    [addAlert, dispatchForCategory]
  );

  const onRemoveClick = props.config.isAbleToRemoveProducts
    ? onRemove
    : undefined;

  const navigateWithCheckForChanges = React.useCallback(
    (to: string) => {
      navigate(to);
    },
    [navigate]
  );

  const saveLocalChanges = React.useCallback(async () => {
    const pinnedProductsMainProductIds = pinnedProducts.map(
      (p) => p.main_product_id
    );
    const hiddenProductsMainProductIds = manuallyHiddenProducts.map(
      (p) => p.main_product_id
    );

    dispatchForCategory({
      type: CategoryActionType.IS_SAVING,
      payload: {
        isSaving: true,
      },
    });

    const defaultLocale = merchant?.default_locale!;

    await saveCategory.mutateAsync({
      pinnedProducts: pinnedProductsMainProductIds,
      hiddenProducts: hiddenProductsMainProductIds,
      addedProducts,
      removedProducts,
      hasPinnedChanged: hasPinnedChanged,
      hasHiddenChanged: hasHiddenChanged,
      titles: { [defaultLocale]: title || "" },
      rankingType,
    });

    await Promise.all([
      refetchNotCuratedProducts(),
      refetchCurated(),
      refetchCategory(),
      refetchListingItems(),
    ]);

    dispatchForCategory({
      type: CategoryActionType.IS_SAVING,
      payload: {
        isSaving: false,
      },
    });

    unselectAll();
  }, [
    pinnedProducts,
    dispatchForCategory,
    merchant?.default_locale,
    saveCategory,
    manuallyHiddenProducts,
    addedProducts,
    removedProducts,
    hasPinnedChanged,
    hasHiddenChanged,
    title,
    rankingType,
    refetchNotCuratedProducts,
    refetchCurated,
    refetchCategory,
    refetchListingItems,
    unselectAll,
  ]);
  useEffect(() => {
    if (!curatedProductsDto) return;

    // V2 doesn't have an endpoint giving all product IDs in a category
    const hasCategoryProductIds = !!props.config.getCategoryProductIds;

    if (hasCategoryProductIds && !categoryProductIds) return;
    dispatchForCategory({
      type: CategoryActionType.INIT_FROM_SERVER,
      payload: {
        curatedProducts: Object.values(curatedProductsDto.curatedProducts),
        pinnedProducts: curatedProductsDto.pinned,
        hiddenProducts: curatedProductsDto.hidden,
        totalProductCount: curatedProductsDto.totalProductCount,
        categoryProductIds: categoryProductIds || [],
      },
    });
  }, [
    dispatchForCategory,
    curatedProductsDto,
    props.config.getCategoryProductIds,
    categoryProductIds,
  ]);

  const scrollToSection = useCallback(
    (section: SectionKey) => {
      scroller.scrollTo(section.toString(), {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        containerId: mainId,
        offset: -headerHeight,
      });
    },
    [headerHeight]
  );

  useEffect(() => {
    if (!notCuratedProducts) return;
    dispatchForCategory({
      type: CategoryActionType.SET_NOT_CURATED_PRODUCTS,
      payload: {
        notCuratedProducts: notCuratedProducts,
      },
    });
  }, [dispatchForCategory, notCuratedProducts]);

  useEffect(() => {
    dispatchForPinnedProducts({
      type: ProductsActionType.UPDATE_PRODUCTS_AND_GROUPS,
      payload: {
        products: pinnedProducts.reduce(
          (acc, p) => ({ ...acc, [p.main_product_id]: p }),
          {}
        ),
        groups: pinnedProducts.map((p) => [p.main_product_id]),
      },
    });
  }, [dispatchForPinnedProducts, pinnedProducts]);

  const subCategories = React.useMemo(() => {
    if (!serverCategory || !serverCategory.children) return [];
    return sortCategories(SortStateSimple.ABC, serverCategory.children);
  }, [serverCategory]);

  const productsOrderedByDepict = React.useMemo(() => {
    return productsByRelevance.map((p) => {
      return {
        ...p,
        pinned: false,
        hidden: false,
      };
    });
  }, [productsByRelevance]);

  const hiddenProductsGridView = React.useMemo(() => {
    return hiddenProducts.map((p) => {
      return {
        ...p,
        pinned: false,
        hidden: true,
      };
    });
  }, [hiddenProducts]);

  const setRankingType = React.useCallback(
    (type: CategoryRankingOrderOptionType) => {
      if (rankingType === type) return;

      cancelChunkingAnimation();

      dispatchForCategory({
        type: CategoryActionType.SET_LOCAL_RANKING_TYPE,
        payload: {
          rankingType: type,
        },
      });

      setMustRefetchNotCuratedProducts({
        value: true,
        origin: "new_ranking_type",
        onFetched: (products) => {
          setRankingAnimationConfig((prev) => {
            return {
              ...prev,
              endProducts: products,
            };
          });
        },
        onBeforeFetch: () => {
          queryClient.setQueryData<ProductOrderData>(
            getQueryKey(
              QueryId.GetCategoryProductsOrder,
              merchant?.id || "",
              categoryId || "",
              props.config.querySalt,
              productSortMethod,
              rankingType || undefined
            ),
            (data) => {
              // We only want to keep the first page of data, otherwise the animation will be too long
              const onlyKeepFirstPage = {
                pages: data?.pages.slice(0, 1) || [],
                pageParams: data?.pageParams.slice(0, 1) || [],
              };

              return onlyKeepFirstPage;
            }
          );
        },
      });

      setShowRankingOrderOptions(false);

      const rankingToChunkSize: Record<CategoryRankingOrderOptionType, number> =
        {
          [CategoryRankingOrderOptionType.Top]: 1,
          [CategoryRankingOrderOptionType.Similar]: 2,
          [CategoryRankingOrderOptionType.Complementary]: 2,
        };

      const nbProductsInViewport = countElementsInView(
        `#${SectionKey.relevance} .${depictCardClassName}`
      );

      setRankingAnimationConfig({
        initialProducts: productsOrderedByDepict.slice(0, nbProductsInViewport),
        endProducts: null,
        nbColumns: columnSize,
        chunkSize: rankingToChunkSize[type],
        startAnimation: true,
        animationId: uuidv4(),
      });
    },
    [
      cancelChunkingAnimation,
      categoryId,
      dispatchForCategory,
      merchant?.id,
      productSortMethod,
      productsOrderedByDepict,
      columnSize,
      props.config.querySalt,
      rankingType,
    ]
  );

  useEffect(() => {
    if (searchedProducts) {
      dispatchForCategory({
        type: CategoryActionType.ADD_PRODUCTS_TO_DICT,
        payload: {
          products: searchedProducts,
        },
      });
    }
  }, [searchedProducts, dispatchForCategory]);

  const searchedGridViewProducts = React.useMemo(() => {
    return getSearchedGridViewProducts(
      searchedProducts || null,
      pinnedProducts,
      hiddenProducts
    );
  }, [hiddenProducts, pinnedProducts, searchedProducts]);

  const mustDisplaySearchedProducts = searchedProducts !== null && !isLoading;
  const mustDisplayPreviewProducts =
    isPreviewingProducts && searchedProducts === null && !isLoading;

  const mustDisplayPlaceholders = isLoading;

  const productIdToArray =
    <T extends (productIds: string[]) => void>(cb: T) =>
    (productId: string): void => {
      return cb([productId]);
    };

  const bulkEditActions = useMemo<
    Required<CategoryBulkEditBarProps["actions"]>
  >(
    () => ({
      onDeleteAction: (selectedIds: string[]) => {
        onRemove(selectedIds);
        unselectAll();
      },
      onHideAction: (selectedIds: string[]) => {
        hide(selectedIds);
        unselectAll();
      },
      onPinToTopAction: (selectedIds: string[]) => {
        pinToBeginning(selectedIds);
        unselectAll();
      },
      aiSortedAction: (selectedIds: string[]) => {
        aiSortClick(selectedIds);
        unselectAll();
      },
    }),
    [hide, onRemove, pinToBeginning, aiSortClick, unselectAll]
  );

  const bulkEditBarOpen = selectedIds.size > 0;

  useShowIntercom({ show: !bulkEditBarOpen });

  const openAddProductsModal = React.useCallback(() => {
    setOpenAddProducts(true);
    setShortcutsDisabled(true);
  }, [setShortcutsDisabled]);

  // When editing the title we need to make sure the breadcrumbs display get the local title
  const serverCategoryWithLocalTitle = serverCategory && {
    ...serverCategory,
    title: title || "",
  };

  const [displayMode, setDisplayMode] = React.useState<ItemTypes>(
    ItemTypes.Merchandizable
  );

  const onChevronClick = React.useCallback(() => {
    setShowRankingOrderOptions((prev) => !prev);
  }, [setShowRankingOrderOptions]);

  const [visibilityMap, setVisibilityMap] = React.useState<
    Record<SectionKey, boolean>
  >({
    [SectionKey.pinned]: false,
    [SectionKey.relevance]: false,
    [SectionKey.hidden]: false,
  });

  const visibleSection = React.useMemo<SectionKey>(() => {
    // take the first visible section, if none are visible, return the first one
    const visible = Object.keys(visibilityMap).find(
      (key) => visibilityMap[key as SectionKey]
    ) as SectionKey | undefined;

    if (!visible) return Object.keys(visibilityMap)[0] as SectionKey;

    return visible;
  }, [visibilityMap]);

  const onVisibilityChange = React.useCallback(
    (visible: boolean, section: SectionKey) => {
      setVisibilityMap((prev) => {
        return {
          ...prev,
          [section]: visible,
        };
      });
    },
    []
  );

  const onToggleView = React.useCallback(() => {
    setView((prev) => {
      return prev === "grid" ? "list" : "grid";
    });
    cancelChunkingAnimation();
  }, [cancelChunkingAnimation, setView]);

  const chunkingAnimationIsRunning = rankingAnimationConfig.startAnimation;

  const mustDisableActions = chunkingAnimationIsRunning || isSavingCategory;

  const menuItem = React.useMemo(() => {
    const pinnedAvailable = pinnedProducts.length > 0;
    const relevanceAvailable = productsByRelevance.length > 0;
    const hiddenAvailable = hiddenProducts.length > 0;

    const availableSections = {
      [SectionKey.pinned]: pinnedAvailable,
      [SectionKey.relevance]: relevanceAvailable,
      [SectionKey.hidden]: hiddenAvailable,
    };

    return (
      <Menu
        menuCBs={{
          onClickOnDepictSorted: () => {
            setDisplayMode(ItemTypes.Merchandizable);
            scrollToSection(SectionKey.relevance);
            setVisibilityMap((prev) => {
              const comingFromHidden = prev.hidden;

              const newValue = {
                [SectionKey.pinned]: comingFromHidden
                  ? pinnedAvailable
                  : prev.pinned,
                [SectionKey.relevance]: comingFromHidden
                  ? !pinnedAvailable
                  : true,
                [SectionKey.hidden]: false,
              };

              return newValue;
            });
          },
          onClickOnHidden: () => {
            cancelChunkingAnimation();
            setDisplayMode(ItemTypes.Hidden);
            scrollToSection(SectionKey.hidden);
            setVisibilityMap((prev) => {
              return {
                [SectionKey.pinned]: false,
                [SectionKey.relevance]: false,
                [SectionKey.hidden]: true,
              };
            });
          },
          onClickOnPinned: () => {
            setDisplayMode(ItemTypes.Merchandizable);
            scrollToSection(SectionKey.pinned);
            setVisibilityMap((prev) => {
              return {
                [SectionKey.pinned]: true,
                [SectionKey.relevance]: prev.relevance,
                [SectionKey.hidden]: false,
              };
            });
          },
          onMenuItemClick: (item) => {
            setDisplayMode(item);
          },
        }}
        selectedItem={displayMode}
        onChevronClick={onChevronClick}
        chevronOpen={showRankingOrderOptions}
        currentSection={visibleSection}
        availableSections={availableSections}
        rankingType={rankingType ?? props.config.rankingType}
        canInteractWithMenu={true}
      />
    );
  }, [
    pinnedProducts.length,
    productsByRelevance.length,
    hiddenProducts.length,
    displayMode,
    onChevronClick,
    showRankingOrderOptions,
    visibleSection,
    rankingType,
    props.config.rankingType,
    scrollToSection,
    cancelChunkingAnimation,
  ]);

  const mustDisplayPinnedProducts =
    !isPreviewingProducts &&
    searchedProducts === null &&
    pinnedProducts.length > 0 &&
    !isLoading &&
    displayMode === ItemTypes.Merchandizable;

  const mustDisplayOrderByDepictProducts =
    !isPreviewingProducts &&
    searchedProducts === null &&
    !isLoading &&
    displayMode === ItemTypes.Merchandizable;

  const mustDisplayHiddenProducts =
    !isPreviewingProducts &&
    searchedProducts === null &&
    hiddenProducts.length > 0 &&
    !isLoading &&
    displayMode === ItemTypes.Hidden;

  const mustDisplayMerchandizableProducts =
    !mustDisplaySearchedProducts &&
    !isLoading &&
    !mustDisplayPlaceholders &&
    (mustDisplayPinnedProducts ||
      mustDisplayOrderByDepictProducts ||
      mustDisplayHiddenProducts);

  const isRankedByDepictLoading = mustRefetchNotCuratedProducts.value;

  const mustShowMenuItem = !(
    mustDisplayPreviewProducts || mustDisplaySearchedProducts
  );

  const mustDisplayEmptyProductCard =
    !isRankedByDepictLoading &&
    !isLoading &&
    !pinnedProducts.length &&
    !hiddenProducts.length &&
    !productsByRelevance.length;

  const [metricsTimePeriod, setMetricsTimePeriod] = React.useState<TimePeriod>(
    TimePeriod.LAST_30_DAYS
  );

  useDepictBlocker(isLocalStateDifferentThanServer);

  return (
    <ProtectedRoute
      user={user}
      merchant={merchant}
      accessValidator={props.config.accessValidator}
    >
      <ImageResizeProvider merchantId={merchant?.id || undefined}>
        <div
          css={css`
            flex: 1;
            display: flex;
            flex-direction: column;
            margin-bottom: ${bulkEditBarOpen ? "4rem" : "0"};
            background: ${theme.background.base.default};
            margin-right: 0.5rem;
            margin-bottom: 0.5rem;
            border-radius: 24px;
          `}
        >
          <div
            css={css`
              position: sticky;
              top: 0px;
              z-index: 1000;
              padding-top: 0.5rem;
              background: ${theme.background.subtle.default};
            `}
          >
            <CategoryHeader
              metrics={[]}
              metricsAreLoading={false}
              metricTimePeriod={metricsTimePeriod}
              onSetMetricTimePeriod={setMetricsTimePeriod}
              showPlaceholders={mustDisplayPlaceholders}
              showTopPlaceholders={
                baseCollection.isLoading || listingItemsLoading
              }
              isSaving={isSavingCategory}
              canSave={
                titleIsValid &&
                !isLoading &&
                !isError &&
                !isSavingCategory &&
                isLocalStateDifferentThanServer &&
                !mustDisableActions
              }
              canRevert={
                !isLoading &&
                isLocalStateDifferentThanServer &&
                !isSavingCategory &&
                !mustDisableActions
              }
              isAbleToAddProducts={props.config.isAbleToAddProducts}
              canAddSubCategory={props.config.isAbleToAddProducts}
              showAddProductsModal={openAddProducts}
              rootCategories={listingItems}
              targetCategory={serverCategoryWithLocalTitle}
              merchant={merchant}
              totalProductCount={totalProductCount}
              allLocalProductIds={allLocalProductIds}
              subCategories={subCategories}
              canEditMetaData={props.config.canEditMetaData}
              title={title || ""}
              onSubCategoryClick={(c) => {
                if (!c || !merchant?.id) return;

                const path = props.config.navigationPaths.getCategoryPath(
                  merchant.id,
                  c.collection_id
                );
                navigateWithCheckForChanges(path);
              }}
              onCreateSubCategory={props.config.onCreateSubCategory?.callback}
              createSubCategoryLoading={
                props.config.onCreateSubCategory?.isLoading || false
              }
              rules={[]}
              availableFilters={[]}
              availableSorts={availableSorts || []}
              rankingType={rankingType ?? props.config.rankingType}
              selectedFilter={""}
              selectedSort={productSortMethod}
              selectedView={view}
              onSelectFilter={() => undefined}
              onSelectSort={onSelectSort}
              onBreadcrumbClick={(c) => {
                if (!merchant?.id) return;

                if (!c) {
                  const path = props.config.navigationPaths.getCategoriesPath(
                    merchant.id
                  );
                  navigate(path);
                  return;
                }

                const path = props.config.navigationPaths.getCategoryPath(
                  merchant.id,
                  c.id
                );

                navigate(path);
              }}
              onSave={saveLocalChanges}
              onRevert={onRevert}
              onEdit={modalCtx?.openModal || (() => {})}
              onChangeTitle={setCategoryTitle}
              onOpenAddProductsModal={openAddProductsModal}
              onCloseAddProductsModal={onCloseAddProductsModal}
              onSaveAddProductsModal={onSaveAddProductsModal}
              onSetSearchQuery={onSearch}
              onToggleView={onToggleView}
              // TODO: Create this function when filters are ready
              onToggleFiltersOpen={() => undefined}
              menuItemComponent={mustShowMenuItem ? menuItem : undefined}
              expandSubCategoriesOnHover={!showRankingOrderOptions}
              nbColumnSwitcher={
                <>
                  {view === "grid" && (
                    <NbColumnsSwitcher
                      columns={columnSize}
                      setColumns={setColumnSize}
                    />
                  )}
                </>
              }
            />
          </div>
          {mustDisplayEmptyProductCard && (
            <div
              css={css`
                display: flex;
                justify-content: center;
                margin-top: 1rem;
              `}
            >
              <EmptyProductCard
                icon={
                  <PiTShirtThin
                    css={css`
                      transform: scale(6);
                    `}
                    color={theme.background.neutral.pressed}
                  />
                }
                color="gray"
              >
                <div
                  css={css`
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                  `}
                >
                  <span
                    css={css`
                      font-size: 14px;
                      line-height: 20px;
                      font-weight: 700;
                      color: ${theme.textIcon.base.default};
                    `}
                  >
                    Add products
                  </span>
                  <IconButton
                    icon={<Plus />}
                    color="black"
                    onClick={openAddProductsModal}
                  />
                </div>
              </EmptyProductCard>
            </div>
          )}
          <div
            id="depict--MainContent"
            className="depict--MainContent container d-flex flex-column position-relative"
            css={css`
              flex: 1;
              padding-top: 1rem;
              scroll-margin-top: 600px;
              padding: 0;
            `}
            ref={elementToHookToRef}
          >
            <div
              css={css`
                display: flex;
                flex-direction: row;
                height: 100%;
              `}
            >
              <div
                css={css`
                  width: 100%;
                `}
              >
                {mustDisplayPlaceholders && (
                  <>
                    {view === "grid" && (
                      <ProductGridViewPlaceholder columns={4} />
                    )}
                    {view === "list" && <ProductListViewPlaceholder />}
                  </>
                )}
                {mustDisplayMerchandizableProducts && (
                  <>
                    {mustDisplayPinnedProducts && (
                      <SectionWrapper
                        keyName={SectionKey.pinned}
                        onVisibilityChange={onVisibilityChange}
                        headerHeight={headerHeight}
                      >
                        <SortableView
                          isLoading={isLoading}
                          view={view}
                          onPinClick={productIdToArray(pin)}
                          onHidingClick={productIdToArray(hide)}
                          onAiSortedClick={productIdToArray(aiSortClick)}
                          onPinToBeginning={productIdToArray(pinToBeginning)}
                          onDragEnd={onDragEnd}
                          onSelectClick={select}
                          selectedIds={selectedIds}
                          onRemoveClick={
                            onRemoveClick
                              ? productIdToArray(onRemoveClick)
                              : undefined
                          }
                          columns={columnSize}
                          aiSorted={false}
                        />
                      </SectionWrapper>
                    )}
                    {mustDisplayOrderByDepictProducts && (
                      <SectionWrapper
                        keyName={SectionKey.relevance}
                        onVisibilityChange={onVisibilityChange}
                        headerHeight={headerHeight}
                      >
                        {chunkingAnimationIsRunning === false && (
                          <ProductDisplayer
                            products={productsOrderedByDepict}
                            pinningOptions={{
                              pinningAllowed: true,
                              onPinClick: productIdToArray(pin),
                              onAiSortedClick: productIdToArray(aiSortClick),
                              onPinToTop: productIdToArray(pinToBeginning),
                            }}
                            hidingOptions={{
                              hidingAllowed: true,
                              onHideClick: productIdToArray(hide),
                              onAiSortedClick: productIdToArray(aiSortClick),
                            }}
                            hiddenProducts={hiddenProducts}
                            pinnedProducts={pinnedProducts}
                            view={view}
                            loading={isRankedByDepictLoading}
                            onRemoveClick={
                              onRemoveClick
                                ? productIdToArray(onRemoveClick)
                                : undefined
                            }
                            selectedIds={selectedIds}
                            onSelectClick={select}
                            productsToHighlight={
                              addedProductsDuringLastModalOpen
                            }
                            error={isNotCuratedError}
                            columns={columnSize}
                            aiSorted={true}
                            onScrollAtBottom={() => {
                              if (hasMoreNotCuratedProducts) {
                                getMoreNotCuratedProducts();
                              }
                            }}
                            isLoadingMore={isFetchingNextNotCuratedPage}
                          />
                        )}
                        {chunkingAnimationIsRunning && (
                          <MemoizedCategoryTransitionGridAnimator
                            key={rankingAnimationConfig.animationId}
                            startAnimation={chunkingAnimationIsRunning}
                            animationId={rankingAnimationConfig.animationId}
                            rankingConfig={rankingAnimationConfig}
                            setRankingAnimationConfig={
                              setRankingAnimationConfig
                            }
                          />
                        )}
                      </SectionWrapper>
                    )}
                    {mustDisplayHiddenProducts && (
                      <SectionWrapper
                        keyName={SectionKey.hidden}
                        onVisibilityChange={onVisibilityChange}
                        headerHeight={headerHeight}
                      >
                        <ProductDisplayer
                          products={hiddenProductsGridView}
                          hidingOptions={{
                            hidingAllowed: true,
                            onHideClick: productIdToArray(hide),
                            onAiSortedClick: productIdToArray(aiSortClick),
                          }}
                          pinningOptions={{
                            pinningAllowed: false,
                            onPinClick: productIdToArray(pin),
                            onAiSortedClick: productIdToArray(aiSortClick),
                            onPinToTop: productIdToArray(pinToBeginning),
                          }}
                          hiddenProducts={hiddenProducts}
                          pinnedProducts={pinnedProducts}
                          view={view}
                          loading={false}
                          onRemoveClick={
                            onRemoveClick
                              ? productIdToArray(onRemoveClick)
                              : undefined
                          }
                          selectedIds={selectedIds}
                          onSelectClick={select}
                          productsToHighlight={[]}
                          error={false}
                          columns={columnSize}
                          aiSorted={false}
                          onScrollAtBottom={undefined}
                          isLoadingMore={false}
                        />
                      </SectionWrapper>
                    )}
                  </>
                )}
                {mustDisplaySearchedProducts && (
                  <div className="pb-3">
                    <SectionSpacer />
                    <ProductDisplayer
                      products={searchedGridViewProducts}
                      pinningOptions={{
                        pinningAllowed: true,
                        onPinClick: productIdToArray(pin),
                        onAiSortedClick: productIdToArray(aiSortClick),
                        onPinToTop: productIdToArray(pinToBeginning),
                      }}
                      hidingOptions={{
                        hidingAllowed: true,
                        onHideClick: productIdToArray(hide),
                        onAiSortedClick: productIdToArray(aiSortClick),
                      }}
                      hiddenProducts={hiddenProducts}
                      pinnedProducts={pinnedProducts}
                      view={view}
                      loading={isSearchLoading}
                      onRemoveClick={
                        onRemoveClick
                          ? productIdToArray(onRemoveClick)
                          : undefined
                      }
                      selectedIds={selectedIds}
                      onSelectClick={select}
                      productsToHighlight={[]}
                      error={false}
                      columns={columnSize}
                      aiSorted={false}
                      onScrollAtBottom={() => {
                        if (hasMoreSearchResults) {
                          loadMoreSearchResults();
                        }
                      }}
                      isLoadingMore={isFetchingMoreSearchResults}
                    />
                  </div>
                )}
                {mustDisplayPreviewProducts && (
                  <div className="pb-3">
                    <SectionSpacer />
                    <ProductDisplayer
                      products={previewProducts.map((product) => ({
                        ...product,
                        pinned: false,
                        hidden: false,
                      }))}
                      pinningOptions={{
                        pinningAllowed: false,
                      }}
                      hidingOptions={{
                        hidingAllowed: false,
                      }}
                      hiddenProducts={[]}
                      pinnedProducts={[]}
                      view={view}
                      loading={isPreviewProductsLoading}
                      selectedIds={selectedIds}
                      disableDropdown
                      productsToHighlight={[]}
                      error={isPreviewError}
                      columns={columnSize}
                      aiSorted={false}
                      onScrollAtBottom={() => {
                        if (hasMorePreviewProducts) {
                          getMorePreviewProducts();
                        }
                      }}
                      isLoadingMore={isFetchingMorePreviewProducts}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {showRankingOrderOptions && (
          <RankingOptions
            elementToHookToRef={elementToHookToRef}
            availableRankingOptions={props.config.availableRankingOptions}
            showRankingOrderOptions={showRankingOrderOptions}
            rankingType={rankingType ?? props.config.rankingType}
            onSetRankingType={setRankingType}
            headerHeight={headerHeight}
            setShowRankingOrderOptions={setShowRankingOrderOptions}
          />
        )}
        <CategoryBulkEditBar
          open={bulkEditBarOpen}
          selectedIds={Array.from(selectedIds)}
          actions={bulkEditActions}
          unselectAll={unselectAll}
        />
        <ConfirmationDialog
          show={showRevertConfirmation}
          onConfirmClick={async () => {
            onRevertConfirmation();
          }}
          onClose={async () => {
            onCloseRevertConfirmation();
          }}
          title={`Are you sure you want to revert to your last save?`}
          message={`You latest changes will be lost and you will go back to your previous saved session ${
            serverCategory?.updated_at
              ? `on ${serverCategory?.updated_at?.toLocaleString()}`
              : ""
          }`}
          confirmButtonText="Revert"
          cancelButtonText="Not now"
          cancelButtonVariant="link"
          primaryButtonVariant="outline-secondary"
        />
      </ImageResizeProvider>
    </ProtectedRoute>
  );
}

const Category = (props: CategoryProps) => {
  return (
    <CategoryProvider>
      <ProductsProvider>
        <ProductMetadataProvider>
          <CategoryConfigModalProvider
            saveConfigCB={props.config.saveConfig}
            deleteCategoryCb={props.config.deleteCategory}
            canEditMetaData={props.config.canEditMetaData}
            isAbleToDelete={props.config.isAbleToDelete}
            getCategory={props.config.getCategory}
            collectionType={props.config.collectionType}
          >
            <BulkEditBarProvider>
              <InternalCategory {...props} />
            </BulkEditBarProvider>
          </CategoryConfigModalProvider>
        </ProductMetadataProvider>
      </ProductsProvider>
    </CategoryProvider>
  );
};

export const WrappedCategory = (props: CategoryProps) => {
  const { categoryId } = useParams();

  return <Category {...props} key={categoryId} />;
};

export default WrappedCategory;

import { useQuery } from "@tanstack/react-query";
import { getData } from "src/api/authorizedApi";
import { QueryId, getQueryKey } from "../../../queries/queries";
import useAuthorizedApi from "../app/useAuthorizedApi";

function _getQueryKey(merchantId: string | null) {
  return getQueryKey(QueryId.GetAvailableDimensionKeys, merchantId || "");
}

export default function useAvailableDimensionKeys(merchantId: string | null) {
  const { api } = useAuthorizedApi();

  const query = useQuery({
    queryKey: _getQueryKey(merchantId),
    queryFn: async () => {
      if (merchantId && api) {
        const response = await api.GET("/api/v0/catalog/products/dimensions", {
          params: {
            query: {
              merchant_id: merchantId,
            },
          },
        });

        return getData(response);
      }
    },
    enabled: !!merchantId && !!api,
  });

  return {
    localeIds: query.data?.locale_ids ?? [],
    pricelistIds: query.data?.pricelist_ids ?? [],
    inventoryIds: query.data?.inventory_ids ?? [],
    defaults: query.data?.default_dimensions,
  };
}

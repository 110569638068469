import { Button } from "react-bootstrap";
import Card from "src/components/storybook/Card/Card";

export default function OnboardingSetUpSite({
  onComplete,
}: {
  onComplete: () => void;
}) {
  return (
    <Card>
      <Card.Body>
        <h1 className="fw-bolder">Set up site</h1>
        <Card.Text>
          You have successfully connected to Depict! Now you can start
          configuring your site to use Depict. Please refer to our{" "}
          <a href="https://docs.depict.ai" target="_blank" rel="noreferrer">
            documentation
          </a>{" "}
          for more information on how to get started.
        </Card.Text>
        <Button className="w-100" onClick={onComplete}>
          Complete
        </Button>
      </Card.Body>
    </Card>
  );
}

import { useState, useCallback } from "react";
import { ControlLoadingQueueCB } from "src/types/components";

export default function useParam(): [
  parameter: string[],
  setCallback: ControlLoadingQueueCB
] {
  const [parameter, setParameter] = useState<string[]>([]);

  const setCallback = useCallback((id: string, action: "push" | "remove") => {
    setParameter((prevState) => {
      if (action === "remove") {
        const newState = prevState.filter(function (item) {
          return item !== id;
        });
        return newState;
      }
      const newState = [...prevState, ...[id]];
      return newState;
    });
  }, []);

  return [parameter, setCallback];
}

import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getData, throwErrorIfAny } from "src/api/authorizedApi";
import { BasePortalProductDto, GetCollectionDto } from "src/api/types";
import Category, {
  CategoryPageConfigProps,
} from "src/components/categories_v2_v3_common/Category/Category";
import {
  GetCuratedProductsCallback,
  GetCuratedProductsConfig,
} from "src/components/categories_v2_v3_common/Category/useCategoryCuratedProducts";
import {
  GetNotCuratedOrderProductsConfig,
  GetNotCuratedProductsCallback,
} from "src/components/categories_v2_v3_common/Category/useCategoryNotCuratedProducts";
import { GetCategorySearchResult } from "src/components/categories_v2_v3_common/Category/useCategorySearch";
import { SaveCategoryMutationFunc } from "src/components/categories_v2_v3_common/Category/useSaveCategory";
import useAuthorizedApi from "src/helpers/hooks/app/useAuthorizedApi";
import useMerchant from "src/helpers/hooks/app/useMerchant";
import useOwnCategories from "src/helpers/hooks/categories_v2_v3_common/useOwnCategories";
import {
  accessValidator,
  getSCollection,
  querySalt,
  useCreateSCollectionMutation,
  useDeleteCategoryCB,
  useGetAvailableSorts,
  useGetCategoryCB,
  useGetCategoryConfig,
  useGetCategoryProductIds,
  useNavigationPaths,
  useSaveConfigCB,
} from "../../helpers/categories/categoryV3.helper";
import { getNextListingName } from "../../helpers/categories/getNextListingName";
import { usePLPRanking } from "../../helpers/hooks/plp/usePLPRanking";
import useScollections from "../../helpers/hooks/useScollections";

const CategoryV3 = () => {
  const { categoryId } = useParams();
  const { api } = useAuthorizedApi();
  const { merchant } = useMerchant();
  const navigate = useNavigate();

  const [sCollection, setSCollection] = useState<GetCollectionDto | null>(null);

  const {
    scollections,
    isLoading: sCollectionsLoading,
    refetch: refetchSCollections,
  } = useScollections();

  const baseCollection = useMemo(
    () =>
      scollections?.find(
        (scollection) => scollection.collection_id === categoryId
      ),
    [categoryId, scollections]
  );

  const {
    availableRankingOptions,
    currentRankingOption: serverRankingOption,
    changeRankingType,
    rankingOptionToChunkType,
  } = usePLPRanking(api, sCollection);

  useEffect(() => {
    const fetchSCollection = async () => {
      if (!api || !merchant?.id || !categoryId || sCollection) return;

      const { sCollection: _sCollection } = await getSCollection(
        api,
        merchant?.id,
        categoryId
      );

      setSCollection(_sCollection);
    };

    fetchSCollection();
  }, [api, categoryId, merchant, merchant?.id, sCollection]);

  const getCategoryConfig = useGetCategoryConfig(api);
  const getAllCategoryProductIds = useGetCategoryProductIds(api);
  const getAvailableSorts = useGetAvailableSorts(api);

  const getAllCategoryProductConfig = useMemo(() => {
    return {
      enabled: !!api,
      querySalt: querySalt,
      callback: getAllCategoryProductIds,
    };
  }, [api, getAllCategoryProductIds]);

  const getAvailableSortsConfig = useMemo(() => {
    return {
      enabled: !!api,
      querySalt,
      callback: getAvailableSorts,
    };
  }, [api, getAvailableSorts]);

  const saveCategory = useCallback<SaveCategoryMutationFunc>(
    async (merchantId, categoryId, categoryData) => {
      if (!api) throw new Error("api is not defined");

      const response = await api.PUT(
        "/api/v0/listing/{merchant_id}/scollections/category/{collection_id}",
        {
          params: {
            path: {
              merchant_id: merchantId,
              collection_id: categoryId,
            },
          },
          body: {
            added_ids: categoryData.addedProducts,
            removed_ids: categoryData.removedProducts,
            pinned_ids: categoryData.pinnedProducts,
            hidden_ids: categoryData.hiddenProducts,
            localized_titles: categoryData.titles,
          },
        }
      );

      throwErrorIfAny(response);

      if (
        categoryData.rankingType &&
        categoryData.rankingType !== serverRankingOption
      ) {
        await changeRankingType(categoryData.rankingType);
      }
    },
    [api, changeRankingType, serverRankingOption]
  );

  const createScollectionMutation = useCreateSCollectionMutation(
    api,
    merchant?.id ?? ""
  );

  const onCreateSubCategory = useCallback(async () => {
    const collectionType = sCollection?.collection_type;
    if (collectionType !== null && collectionType !== undefined) {
      const returned = await createScollectionMutation.mutateAsync({
        title: getNextListingName(sCollection?.children || [], collectionType),
        type: collectionType,
        parentId: sCollection?.collection_id,
      });

      if (returned) {
        const categoryId = returned?.collection_id;

        if (!categoryId) {
          throw new Error("Category ID is null, should never happen");
        }

        navigate(`/${merchant?.id}/categories-v3/${categoryId}`);
        await refetchSCollections();
      }
    }
  }, [
    sCollection?.collection_type,
    sCollection?.children,
    sCollection?.collection_id,
    createScollectionMutation,
    navigate,
    merchant?.id,
    refetchSCollections,
  ]);

  const navigationPaths = useNavigationPaths();

  const enabled = !!api;

  const getNotCuratedProductsCB = useCallback<GetNotCuratedProductsCallback>(
    async (
      merchantId,
      categoryId,
      productsToExclude,
      productsToInclude,
      cursor,
      sortBy,
      chunkType
    ) => {
      if (!api) throw new Error("api is not defined");

      const chunkTypeOverride =
        chunkType && rankingOptionToChunkType(chunkType);

      const response = await api.POST(
        "/api/v0/listing/{merchant_id}/scollections/{collection_id}/products/not-curated",
        {
          params: {
            path: {
              merchant_id: merchantId,
              collection_id: categoryId,
            },
            query: {
              cursor: cursor || undefined,
              limit: cursor ? 100 : 20,
              chunk_type_override: chunkTypeOverride,
            },
          },
          body: {
            excluded_main_product_ids: productsToExclude,
            included_main_product_ids: productsToInclude,
            sort_by: sortBy,
          },
        }
      );

      const data = getData(response);

      return {
        notCuratedOrder: data.products.map((p) => p.main_product_id),
        notCuratedProducts: data.products.reduce<{
          [key: string]: BasePortalProductDto;
        }>((acc, p) => {
          return {
            ...acc,
            [p.main_product_id]: p,
          };
        }, {}),
        cursor: data.cursor || null,
      };
    },
    [api, rankingOptionToChunkType]
  );

  const getNotCuratedProducts =
    useMemo<GetNotCuratedOrderProductsConfig>(() => {
      return {
        enabled,
        querySalt: querySalt,
        callback: getNotCuratedProductsCB,
      };
    }, [enabled, getNotCuratedProductsCB]);

  const getCategoryCB = useGetCategoryCB(api);

  const getCuratedProductsCB = useCallback<GetCuratedProductsCallback>(
    async (merchantId, categoryId) => {
      if (!api) throw new Error("api is not defined");

      const response = await api.GET(
        "/api/v0/listing/{merchant_id}/scollections/{collection_id}/products/curated",
        {
          params: {
            path: {
              merchant_id: merchantId,
              collection_id: categoryId,
            },
          },
        }
      );

      const data = getData(response);

      const hiddenIds = data.hidden.map((p) => p.main_product_id);
      const pinnedIds = data.pinned.map((p) => p.main_product_id);

      const curatedProducts = [...data.pinned, ...data.hidden].reduce<{
        [key: string]: BasePortalProductDto;
      }>((acc, p) => {
        return {
          ...acc,
          [p.main_product_id]: p,
        };
      }, {});

      const category = await getCategoryCB(merchantId, categoryId);

      const totalProductCount = category.collection?.n_main_products || 0;

      return {
        hidden: hiddenIds,
        pinned: pinnedIds,
        curatedProducts,
        totalProductCount,
      };
    },
    [api, getCategoryCB]
  );

  const getCuratedProducts = useMemo<GetCuratedProductsConfig>(() => {
    return {
      enabled: !!api,
      querySalt: querySalt,
      callback: getCuratedProductsCB,
    };
  }, [api, getCuratedProductsCB]);

  const saveConfigCB = useSaveConfigCB(api);
  const deleteCategoryCB = useDeleteCategoryCB(api);

  const getCategorySearchResults = useCallback<GetCategorySearchResult>(
    async ({
      merchantId,
      categoryId,
      query,
      cursor,
      productsToExclude,
      productsToInclude,
      sortBy,
    }) => {
      if (!api) throw new Error("api is not defined");

      const response = await api.POST(
        "/api/v0/listing/{merchant_id}/scollections/{collection_id}/products/search",
        {
          params: {
            path: {
              merchant_id: merchantId,
              collection_id: categoryId,
            },
            query: {
              cursor: cursor || undefined,
              limit: 20,
              query,
            },
          },
          body: {
            excluded_main_product_ids: productsToExclude,
            included_main_product_ids: productsToInclude,
            sort_by: sortBy,
          },
        }
      );

      return getData(response);
    },
    [api]
  );

  const canUpdateProducts = useMemo(() => {
    if (!sCollection) return false;

    if (sCollection.externally_owned) return false;

    return (
      sCollection.collection_type === "category" ||
      sCollection.collection_type === "style" ||
      sCollection.collection_type === "campaign"
    );
  }, [sCollection]);

  const ownCategories = useOwnCategories();
  const canEdit =
    (sCollection?.collection_type !== "smart_pick" && ownCategories) ||
    sCollection?.collection_type === "campaign";

  const config = useMemo<CategoryPageConfigProps>(() => {
    return {
      getCategory: getCategoryConfig,
      navigationPaths,
      saveCategory,
      getNotCuratedProducts: getNotCuratedProducts,
      getCuratedProducts,
      saveConfig: saveConfigCB,
      deleteCategory: deleteCategoryCB,
      getCategorySearchResults,
      accessValidator,
      isAbleToAddProducts: canUpdateProducts,
      isAbleToRemoveProducts: canUpdateProducts,
      getCategoryProductIds: canUpdateProducts
        ? getAllCategoryProductConfig
        : undefined,
      canEditMetaData: canEdit,
      isAbleToDelete: canEdit,
      getAvailableSorts: getAvailableSortsConfig,
      availableRankingOptions,
      rankingType: serverRankingOption,
      collectionType: sCollection?.collection_type,
      baseCollection: {
        isLoading: sCollectionsLoading,
        data: baseCollection,
      },
      onCreateSubCategory: {
        callback: onCreateSubCategory,
        isLoading: createScollectionMutation.isPending,
      },
      querySalt,
    };
  }, [
    getCategoryConfig,
    navigationPaths,
    saveCategory,
    getNotCuratedProducts,
    getCuratedProducts,
    saveConfigCB,
    deleteCategoryCB,
    getCategorySearchResults,
    canUpdateProducts,
    getAllCategoryProductConfig,
    getAvailableSortsConfig,
    availableRankingOptions,
    serverRankingOption,
    canEdit,
    sCollection?.collection_type,
    onCreateSubCategory,
    createScollectionMutation.isPending,
    baseCollection,
    sCollectionsLoading,
  ]);

  if (!enabled || sCollection === null) return null;

  return <Category config={config} />;
};

const WrappedCategoryV3 = () => {
  const { categoryId } = useParams();

  return <CategoryV3 key={categoryId} />;
};

export default WrappedCategoryV3;

import { css } from "@emotion/react";
import { PencilSimple, Translate } from "@phosphor-icons/react";
import React from "react";
import { FieldErrors, useFormContext } from "react-hook-form";
import { CollectionMetadataSchema } from "src/components/storybook/Categories/categories.schemas";
import { theme } from "src/components/storybook/designSystemVariables";

type Locale = string;

type Title = string;

const borderRadius = 8;

// Table styles
const tableCSS = css`
  width: 100%;
  border-collapse: collapse;
  border-style: hidden;
`;

// Header styles
const thCSS = [
  theme.typography.label[400].default,
  css`
    padding: 12px;
    // Add other styles like text color, font, etc.
  `,
];

const firstThCSS = [
  ...thCSS,
  css`
    border-top-left-radius: ${borderRadius}px;
  `,
];

const lastThCSS = [
  ...thCSS,
  css`
    border-top-right-radius: ${borderRadius}px;
  `,
];

// Row and cell styles
const trCSS = css`
  &:hover {
    background-color: #f1f1f1; // Or any other hover state color
  }
`;

const tdCSS = css`
  padding: 12px;
  border-bottom: 1px dashed #e0e0e0;
  text-align: left;
`;

interface TableProps {
  children: React.ReactNode;
}

interface CellProps {
  children: React.ReactNode;
  isLastRow: boolean;
  isLeftCell?: boolean;
  isRightCell?: boolean;
}

const Table: React.FC<TableProps> = ({ children }) => (
  <table css={tableCSS}>{children}</table>
);
const TableRow: React.FC<TableProps> = ({ children }) => (
  <tr css={[trCSS, theme.typography.paragraph[200].light]}>{children}</tr>
);
const TableCell: React.FC<CellProps> = ({
  children,
  isLastRow,
  isLeftCell,
  isRightCell,
}) => (
  <td
    css={[
      tdCSS,
      isLastRow &&
        isLeftCell &&
        css`
          border-bottom-left-radius: ${borderRadius}px;
        `,
      isLastRow &&
        isRightCell &&
        css`
          border-bottom-right-radius: ${borderRadius}px;
        `,
    ]}
  >
    {children}
  </td>
);

export type Titles = Record<Locale, Title>;

export interface TitleEditorProps {
  defaultLocale: string;
  getErrorMessage: (
    errors: FieldErrors<CollectionMetadataSchema>,
    locale: string
  ) => string | undefined;
}
export const TitleEditor = (props: TitleEditorProps) => {
  const form = useFormContext<CollectionMetadataSchema>();

  const titles = form.getValues().titles;

  const titleArray = Object.entries(titles).filter(
    ([locale]) => locale !== props.defaultLocale
  );

  return (
    <div
      css={css`
        max-height: 200px; // Adjust this value as needed
        overflow-y: auto;
      `}
    >
      <Table>
        <thead
          css={css`
            position: sticky;
          `}
        >
          <tr
            css={css`
              border-radius: 8px 8px 0px 0px;
              border: 1px solid ${theme.border.subtle.default};
              background: ${theme.background.subtle.default};
            `}
          >
            <th css={firstThCSS}>
              <Translate weight="bold" /> Locale
            </th>
            <th css={lastThCSS}>
              <PencilSimple weight="bold" /> Title
            </th>
          </tr>
        </thead>

        <tbody>
          {titles && titleArray.length > 0
            ? titleArray.map(([locale], index) => {
                const errorMessage = props.getErrorMessage(
                  form.formState.errors,
                  locale
                );

                return (
                  <TableRow key={locale}>
                    <TableCell
                      isLastRow={index === titleArray.length - 1}
                      isLeftCell={true}
                    >
                      {locale}
                    </TableCell>
                    <TableCell
                      isLastRow={index === titleArray.length - 1}
                      isRightCell={true}
                    >
                      <input
                        css={[
                          theme.typography.paragraph[200].light,
                          css`
                            :focus-within {
                              border-color: ${theme.border.neutral.default};
                            }
                          `,
                        ]}
                        type="text"
                        {...form.register(`titles.${locale}` as const, {
                          required: "This field is required",
                        })}
                      />
                      {errorMessage && (
                        <div
                          css={css`
                            color: red;
                            margin-top: 4px;
                            font-size: 0.8rem; // Adjust as needed
                          `}
                        >
                          {errorMessage}
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
            : null}
        </tbody>
      </Table>
    </div>
  );
};

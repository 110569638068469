import { useState, useEffect } from "react";
import { UserData } from "../../types/user";
import userApi from "../../api/users";
import useAuthentication from "./app/useAuthentication";
import { User } from "@auth0/auth0-react";

export default function useDepictUser(
  auth0User: User | undefined
): [depictUser: UserData | null] {
  const [depictUser, setDepictUser] = useState<UserData | null>(null);
  const [getAccessToken] = useAuthentication();

  useEffect(() => {
    if (!auth0User) {
      return;
    }

    getAccessToken()
      .then((idToken) => {
        return userApi({ idToken });
      })
      .then((response) => {
        if (response.success) {
          const data = response.data;
          setDepictUser(data);
        }
      });

    return () => {
      setDepictUser(null);
    };
  }, [auth0User, getAccessToken]);

  return [depictUser];
}

import { deepEqual } from "src/helpers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { deepCopy } from "src/helpers";
import { getNullAiState } from "src/helpers/configuration";
import { AiState } from "src/types/components";
import { AiControlModifyCB } from "src/types/components";

type UseLocalAiState = [
  local: AiState,
  modify: AiControlModifyCB,
  restore: Function,
  hasChanges: boolean
];

export default function useLocalAiState(aiState: AiState) {
  const [local, set] = useState<AiState>(getNullAiState());
  const [backup, setBackup] = useState<AiState | null>(null);

  useEffect(() => {
    console.log(
      `%c[CONFIGURATION] Setting Ai control state for on-view configuration.`,
      "color: purple"
    );

    set(deepCopy(aiState));
    setBackup(deepCopy(aiState));

    return () => {
      set(getNullAiState());
      setBackup(null);
    };
  }, [aiState]);

  const restore = useCallback(() => {
    if (!backup) {
      return;
    }

    set(deepCopy(backup));
  }, [backup]);

  const modify = useCallback((data: boolean) => {
    console.log(
      `%c[CONFIGURATION] Updating AiControl status`,
      "color: crimson"
    );
    set((prevValue: AiState) => {
      let updated = deepCopy(prevValue);
      updated.view = data;
      return updated;
    });
  }, []);

  const hasChanges = useMemo(() => !deepEqual(local, backup), [local, backup]);

  return [local, modify, restore, hasChanges] as UseLocalAiState;
}

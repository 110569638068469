import React, { useMemo } from "react";
import { CSVLink } from "react-csv";
import reactFastCompare from "react-fast-compare";
import { CsvDownloadProps } from "src/types/components";
import useCsvData from "src/helpers/hooks/csv/useCsvData";
import { getPeriodFromUi } from "src/helpers/compare";
import { getCsvHeaders } from "src/helpers/csv";

const CsvDownload = (props: CsvDownloadProps) => {
  const {
    metrics,
    metricsCompare,
    splitBySurface,
    market,
    merchant,
    period,
    compare,
    currencySymbol,
    enabledSurfaces,
  } = props;

  const comparePeriod = useMemo(
    () => getPeriodFromUi(period, compare, "compare"),
    [period, compare]
  );

  const [partialCsvData] = useCsvData(
    metrics,
    merchant,
    market,
    splitBySurface,
    period,
    enabledSurfaces
  );

  const [partialCompareCsvData] = useCsvData(
    metricsCompare,
    merchant,
    market,
    splitBySurface,
    comparePeriod,
    enabledSurfaces
  );

  const headers = useMemo(
    () => [getCsvHeaders(currencySymbol || "")],
    [currencySymbol]
  );

  const csvData = useMemo(() => {
    const data = partialCsvData ? partialCsvData : [];
    const compare = partialCompareCsvData ? partialCompareCsvData : [];
    const merged = [...headers, ...data, ...compare];
    return merged;
  }, [partialCsvData, partialCompareCsvData, headers]);

  const fileName = useMemo(() => {
    const today = new Date();
    const isoFormat = today.toISOString();
    return `depict_portal_export_${isoFormat}.csv`;
  }, []);

  return (
    <>
      <CSVLink
        data={csvData || []}
        filename={fileName}
        className="dropdown-item"
        target="_blank"
      >
        Download as CSV
      </CSVLink>
    </>
  );
};

export default React.memo(CsvDownload, reactFastCompare);

import { Cross, FloppyDisk } from "src/components/linearicons";
import { ModalContent } from "src/types/modals";

const modal: ModalContent = {
  title: "Save changes to",
  subtitle: "{configuration_name}",
  content: "Are you sure you want to save changes to your configuration?",
  buttons: [
    {
      id: "cancel",
      label: "Cancel",
      icon: <Cross size="12" className="me-2" />,
      className: "btn btn-sm btn-outline-depict-brown rounded-pill me-2",
    },
    {
      id: "save",
      label: "Save",
      icon: <FloppyDisk size="12" className="me-2" />,
      className: "btn btn-sm btn-depict-blue rounded-pill me-2",
    },
  ],
};

export default modal;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QueryId, getQueryKey } from "../../../queries/queries";
import useAuthorizedApi from "../app/useAuthorizedApi";
import { throwErrorIfAny } from "src/api/authorizedApi";

export default function useCatalogSyncTrigger(merchantId: string | null) {
  const { api } = useAuthorizedApi();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async () => {
      if (!api || !merchantId) return;
      const response = await api.POST(
        "/api/v0/integrations/workflow/{merchant_id}",
        {
          params: {
            path: {
              merchant_id: merchantId,
            },
          },
        }
      );

      throwErrorIfAny(response);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getQueryKey(QueryId.GetCatalogSyncStatus, merchantId || ""),
      });
    },
  });

  return {
    mutation,
  };
}

import reactFastCompare from "react-fast-compare";
import CardCollapsible from "src/components/cards/CardCollapsible";
import ConfigurationSettings from "src/components/configuration/Settings";
import SectionConfigurationToolbar from "src/components/sections/SectionConfigurationToolbar";
import SectionConfigurationRelationships from "./SectionConfigurationRelationships";

import React, { useCallback, useMemo, useState } from "react";
import { SectionConfigurationProps } from "src/types/components";
import CreateRelationships from "../configuration/CreateRelationships";

import { css } from "@emotion/react";
import { haveFromPanelDisabled } from "src/helpers/configuration";
import useClearGroup from "src/helpers/hooks/configuration/useClearGroup";
import useLocalAiState from "src/helpers/hooks/configuration/useLocalAiState";
import useLocalConfiguration from "src/helpers/hooks/configuration/useLocalConfiguration";
import useLocalConfigurationGroups from "src/helpers/hooks/configuration/useLocalConfigurationGroups";

const SectionConfiguration = (props: SectionConfigurationProps) => {
  const [fromHasMultipleItems, setFromHasMultipleItems] = useState<
    boolean | null
  >(null);
  const [isFromPanelDanger, setIsFromPanelDanger] = useState<boolean>(false);

  const {
    showAiControls,
    onViewConfiguration,
    onViewAiState,
    onViewGroups,
    products,
    groups,
    surfaces,
    saveConfigurationCB,
    deleteConfigurationCB,
    createNewRelationshipsCB,
    toggleRightSidebarOverlayCB,
    toggleHelpModalCB,
    hasLoadingProcesses,
  } = props;

  const [
    localConfiguration,
    modifyConfiguration,
    restoreConfiguration,
    configurationHasChanges,
  ] = useLocalConfiguration(onViewConfiguration);

  const [localAiState, modifyAiState, restoreAiState, aiHasChanges] =
    useLocalAiState(onViewAiState);

  const [localGroups, modifyGroup, restoreGroups, groupsHasChanges] =
    useLocalConfigurationGroups(onViewGroups);

  const restoreConfigurationCB = useCallback(() => {
    restoreConfiguration();
    restoreAiState();
    restoreGroups();
  }, [restoreConfiguration, restoreAiState, restoreGroups]);

  const unsavedChanges: boolean = useMemo(() => {
    return configurationHasChanges || aiHasChanges || groupsHasChanges;
  }, [configurationHasChanges, aiHasChanges, groupsHasChanges]);

  const shouldFromPanelBeDisabled: boolean = useMemo(
    () => haveFromPanelDisabled(surfaces, localConfiguration?.surfaces),
    [surfaces, localConfiguration?.surfaces]
  );

  const getFromGroupStatusUpdate = useCallback(
    (hasMultipleItems: boolean | null) => {
      setFromHasMultipleItems(hasMultipleItems);
    },
    []
  );

  const [fromGroupClear] = useClearGroup(
    localAiState.currentConf ? "only_include" : "must_include",
    "from_group",
    localConfiguration?.relationships || [],
    localGroups || [],
    modifyGroup
  );

  const listenToWarningOnScreen = useCallback((status: boolean) => {
    setIsFromPanelDanger(status);
  }, []);

  const saveTrigger = useCallback(() => {
    saveConfigurationCB(localConfiguration, localGroups, localAiState);
  }, [localAiState, localConfiguration, localGroups, saveConfigurationCB]);

  const deleteTrigger = useCallback(() => {
    deleteConfigurationCB(localConfiguration ? localConfiguration.id : null);
  }, [deleteConfigurationCB, localConfiguration]);

  const continueTrigger = useCallback(() => {
    createNewRelationshipsCB(localConfiguration);
  }, [createNewRelationshipsCB, localConfiguration]);

  return (
    <>
      {localConfiguration && (
        <div
          className="depict--ConfigureConfiguration"
          css={css`
            display: flex;
            flex-direction: column;
            height: 100%;
          `}
        >
          <SectionConfigurationToolbar
            displayName={localConfiguration?.display_name ?? ""}
            hasUnsavedChanges={unsavedChanges}
            hasLoadingProcesses={hasLoadingProcesses}
            showSaveButton={
              localConfiguration.relationships &&
              localConfiguration.relationships.length > 0
            }
            startTimestamp={localConfiguration.validity_start_timestamp || null}
            endTimestamp={localConfiguration.validity_end_timestamp || null}
            savedAsState={onViewConfiguration?.state || "inactive"}
            savedAsEmpty={
              typeof onViewConfiguration?.is_empty !== "undefined"
                ? onViewConfiguration.is_empty
                : true
            }
            state={localConfiguration.state || "inactive"}
            configurationModifyCB={modifyConfiguration}
            saveTriggerCB={saveTrigger}
            configurationDeleteCB={deleteTrigger}
            configurationRestoreCB={restoreConfigurationCB}
          />
          <div className="depict--ConfigureConfiguration__settings mb-2b mb-xxl-3">
            <CardCollapsible title="Settings" id="collapseCardSettings">
              <ConfigurationSettings
                displayName={localConfiguration?.display_name || ""}
                enabledSurfaces={localConfiguration?.surfaces}
                surfaces={surfaces}
                relationships={localConfiguration?.relationships}
                startTimestamp={
                  localConfiguration?.validity_start_timestamp
                    ? localConfiguration?.validity_start_timestamp
                    : null
                }
                endTimestamp={
                  localConfiguration?.validity_end_timestamp
                    ? localConfiguration?.validity_end_timestamp
                    : null
                }
                state={localConfiguration.state}
                aiControl={localAiState}
                showAiControls={showAiControls}
                fromHasMultipleItems={fromHasMultipleItems}
                groupClearCB={fromGroupClear}
                configurationModifyCB={modifyConfiguration}
                aiControlModifyCB={modifyAiState}
                notifyOfWarningPopupOnScreenCB={listenToWarningOnScreen}
                toggleHelpModalCB={toggleHelpModalCB}
              />
            </CardCollapsible>
          </div>
          {localConfiguration.relationships &&
          localConfiguration.relationships.length > 0 ? (
            <SectionConfigurationRelationships
              configuration={localConfiguration}
              configurationGroups={localGroups}
              aiControl={localAiState}
              products={products}
              groups={groups}
              groupModifyCB={modifyGroup}
              isFromDisabled={shouldFromPanelBeDisabled}
              fromHasMultipleItemsCB={getFromGroupStatusUpdate}
              isFromPanelDanger={isFromPanelDanger}
              toggleRightSidebarOverlayCB={toggleRightSidebarOverlayCB}
              toggleHelpModalCB={toggleHelpModalCB}
            />
          ) : (
            <CreateRelationships
              disabled={false}
              continueTriggerCB={continueTrigger}
            />
          )}
        </div>
      )}
    </>
  );
};

//15 no deep. //4 times deep.
export default React.memo(SectionConfiguration, reactFastCompare);

import { Card as BootstrapCard } from "react-bootstrap";

const Card = ({ children }: { children: React.ReactNode }) => {
  return (
    <BootstrapCard
      className="bg-white"
      style={{
        borderRadius: "16px",
        border: "none",
        boxShadow: "0px 2px 4px 0px rgba(15, 15, 15, 0.10)",
      }}
    >
      {children}
    </BootstrapCard>
  );
};

const CardBody = ({ children }: { children: React.ReactNode }) => {
  return <div style={{ padding: "32px" }}>{children}</div>;
};

const CardTitle = ({ children }: { children: React.ReactNode }) => {
  return (
    <BootstrapCard.Title
      className="fw-bolder"
      style={{ fontSize: "32px", marginBottom: "12px" }}
    >
      {children}
    </BootstrapCard.Title>
  );
};

const CardFooter = ({ children }: { children: React.ReactNode }) => {
  return (
    <BootstrapCard.Footer
      className="bg-white"
      style={{ padding: "32px", borderRadius: "0 0 16px 16px" }}
    >
      {children}
    </BootstrapCard.Footer>
  );
};

export default Object.assign(Card, {
  Body: CardBody,
  Text: BootstrapCard.Text,
  Title: CardTitle,
  Footer: CardFooter,
});

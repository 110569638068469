 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Planet = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M19.675 4.414c-0.397-0.687-1.157-1.141-2.199-1.311-0.883-0.145-1.951-0.094-3.176 0.149-1.273-0.815-2.753-1.251-4.301-1.251-2.137 0-4.146 0.832-5.657 2.343s-2.343 3.52-2.343 5.657c0 0.116 0.003 0.232 0.008 0.347-0.824 0.94-1.402 1.84-1.719 2.678-0.373 0.988-0.361 1.873 0.036 2.561 0.528 0.914 1.664 1.391 3.262 1.391 0.448 0 0.932-0.037 1.449-0.113 0.218-0.032 0.439-0.070 0.664-0.115 1.273 0.815 2.753 1.251 4.3 1.251 2.137 0 4.146-0.832 5.657-2.343s2.343-3.52 2.343-5.657c0-0.116-0.003-0.232-0.008-0.347 0.152-0.173 0.297-0.347 0.434-0.52 1.481-1.873 1.925-3.549 1.249-4.719zM10 3c3.623 0 6.613 2.767 6.965 6.3-1.29 1.391-3.063 2.765-5.096 3.938-2.031 1.173-4.106 2.021-5.955 2.443-1.764-1.272-2.915-3.344-2.915-5.681 0-3.86 3.14-7 7-7zM1.191 15.086c-0.431-0.746-0.059-1.969 0.993-3.372 0.322 1.482 1.061 2.844 2.16 3.942 0.087 0.087 0.176 0.172 0.267 0.255-1.753 0.211-2.992-0.085-3.419-0.825zM10 17c-1.016 0-1.982-0.218-2.854-0.609 1.673-0.491 3.468-1.274 5.224-2.287s3.331-2.177 4.593-3.381c-0.363 3.521-3.347 6.277-6.963 6.277zM15.657 4.343c-0.087-0.087-0.176-0.172-0.267-0.255 1.742-0.211 2.989 0.079 3.42 0.826 0.427 0.74 0.065 1.96-0.993 3.371-0.322-1.482-1.061-2.844-2.16-3.942z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Planet); 
import config from "src/config";
import { SurfacesApiResult } from "src/types/api";
import { mockSurfaces } from "src/helpers";

const surfacesApi = (params: {
  idToken: string;
  merchant_id: string;
  method?: "GET";
}): Promise<SurfacesApiResult> => {
  let { merchant_id, idToken } = params;

  const endpoint = `/merchants/${merchant_id}/surfaces`;
  const url = config.api.baseUrl + "/" + config.api.version + endpoint;
  return new Promise((resolve) => {
    if (config.dataMock) {
      let loadMock = async () => {
        console.log(
          "%c[MOCK] Loaded mocking surfaces for merchant " + merchant_id,
          "color:coral"
        );
        const data: any = await mockSurfaces();
        resolve({
          success: true,
          data: data,
        });
      };
      loadMock();
      return;
    }

    fetch(url, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + idToken,
      },
    })
      .then((response) => {
        if (typeof response.ok === "undefined" || response.ok === false) {
          throw response;
        }

        return response.json();
      })
      .then((data) => {
        resolve({
          success: true,
          data: data,
        });
      })
      .catch((error: any | Response) => {
        resolve({
          success: false,
          error: error,
        });
      });
  });
};

export default surfacesApi;

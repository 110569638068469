import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";

export default function useAuthentication(): [
  getAccessToken: () => Promise<string>
] {
  const { getAccessTokenSilently } = useAuth0();

  const getAccessToken = useCallback(async () => {
    if (process.env.NODE_ENV === "development") {
      //Check if local storage has a token and use that instead
      const token = localStorage.getItem("prod_token");
      if (token) {
        return token;
      }
    }
    const { id_token } = await getAccessTokenSilently({
      detailedResponse: true,
    });
    return id_token;
  }, [getAccessTokenSilently]);

  return [getAccessToken];
}

import { createContext, useEffect, useMemo, useReducer } from "react";
import { Product } from "../../types";
import { initialState, reducer } from "./ProductsProvider.reducer"; // Assuming the reducer and its related types are exported from a 'reducer' file
import { Action, ActionType, State } from "./ProductsProvider.type";
export { ActionType };

export interface ProductContextProps extends State {}

export const ProductsContext = createContext<ProductContextProps | null>(null);
export const ProductsDispatchContext = createContext<React.Dispatch<Action>>(
  () => {}
);

interface ProductsSelectors {
  products: Product[] | null;
  groups: string[][] | null;
}
export const ProductsSelectorsContext = createContext<ProductsSelectors>({
  products: null,
  groups: null,
});

interface UseProductsContextProps {
  products?: Product[];
  groups?: string[][];
  children: React.ReactNode;
}

export const ProductsProvider = ({
  children,
  products,
  groups,
}: UseProductsContextProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  /*
    This effect is only run once, when the component is mounted.
    It will update the context with the products and groups passed as props.

    You don't always have the products and groups available when the component is mounted, so you can't always pass them as props.
  */
  useEffect(() => {
    if (!products) return;
    if (!groups) return;

    const productsById = products.reduce((acc, product) => {
      acc[product.main_product_id] = product;
      return acc;
    }, {} as Record<string, Product>);

    dispatch({
      type: ActionType.UPDATE_PRODUCTS_AND_GROUPS,
      payload: {
        products: productsById,
        groups,
      },
    });
    // Only run this effect once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* 
    This is a selector that will be used by the components that consume the context.
    It will return the products in the order defined by the groups.
  */
  const selectors: ProductsSelectors = useMemo(() => {
    if (state.loading) return { products: null, groups: null };

    // return the products in the order defined by the groups
    return {
      products: state.productGroupIds.flatMap((groupId) =>
        state.productGroups.byId[groupId].mainProductIds.map(
          (mainProductIds) => state.products.byId[mainProductIds]
        )
      ),
      groups: state.productGroupIds.map(
        (groupId) => state.productGroups.byId[groupId].mainProductIds
      ),
    };
  }, [
    state.loading,
    state.productGroupIds,
    state.productGroups.byId,
    state.products.byId,
  ]);

  return (
    <ProductsContext.Provider value={state}>
      <ProductsDispatchContext.Provider value={dispatch}>
        <ProductsSelectorsContext.Provider value={selectors}>
          {children}
        </ProductsSelectorsContext.Provider>
      </ProductsDispatchContext.Provider>
    </ProductsContext.Provider>
  );
};

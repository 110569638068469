import { BoostBuryEffect } from "src/api/types";
import { theme } from "../../designSystemVariables";
import BoostAndBuryPreviewCard from "./BoostAndBuryPreviewCard";
import "./BoostAndBuryPreview.scss";
import { BoostRuleType } from "src/helpers/boostbury/availableOptions";

type NonNullableBoostRuleType = NonNullable<BoostRuleType>;

const campaignPreviewTitle = "Campaign";

const TYPE_TO_PREVIEW_TITLE: Record<NonNullableBoostRuleType, string> = {
  best_selling: "Bestseller",
  few_sizes_available: "Few sizes",
  new_products: "New in",
  on_sale: "On sale",
  out_of_stock: "Out of stock",
  new_products_proportional: "New in",
  scollection: campaignPreviewTitle,
};

const campaignPreviewDescription =
  "All products in the campaign will be boosted.\nIf you add or remove products from this campaign, we will update the boosting automatically.";
const TYPE_TO_PREVIEW_DESCRIPTION: Record<NonNullableBoostRuleType, string> = {
  best_selling:
    "Bestseller products are always changing based on your shoppers’ purchases. We will automatically update which product gets boosted based on their bestseller score.",
  few_sizes_available:
    "Few sizes products are always changing based on your catalog.\nWe will automatically update which product gets tagged as “Few sizes” based on their stock level.",
  new_products:
    "New in products are always changing based on your catalog.\nWe will automatically update which product gets boosted based on their catalog arrival date.",
  on_sale:
    'On Sale products are based on your catalog.\n We will automatically update which product gets tagged as "on sale" based on your catalog data.',
  out_of_stock:
    "Out of stock products are always changing based on your catalog.\nWe will automatically update which product gets tagged as “out of stock” based on their stock level.",
  scollection: campaignPreviewDescription,
  new_products_proportional:
    "New in products are always changing based on your catalog.\nWe will automatically update which product gets boosted based on their catalog arrival date.",
};

export interface BoostAndBuryPreviewProps {
  showVariant: BoostBuryEffect | false;
  option: BoostRuleType | null;
  images: string[];
  collectionTitle?: string;
  collectionBoostRuleType: "collection" | "scollection";
}

export const BoostAndBuryPreview = (props: BoostAndBuryPreviewProps) => {
  const {
    showVariant,
    option,
    images,
    collectionTitle,
    collectionBoostRuleType,
  } = props;

  const shouldShowPreview =
    showVariant &&
    option &&
    (option !== collectionBoostRuleType || collectionTitle);

  const imagesToUse =
    images.length > 0 ? images.slice(0, 5) : ["", "", "", "", ""];

  return (
    <div
      className={`d-flex flex-column justify-content-${
        shouldShowPreview ? "start" : "center"
      } align-items-center h-100 relative depict--BoostAndBuryPreview`}
    >
      {shouldShowPreview && (
        <div
          className={`depict--BoostAndBuryPreview__cards${
            imagesToUse.length < 5 ? " hide-fade" : ""
          }`}
        >
          {imagesToUse.map((image, index) => (
            <BoostAndBuryPreviewCard
              key={index}
              image={image}
              effect={showVariant}
              options={[option]}
            />
          ))}
        </div>
      )}
      <div
        className="text-center p4-5"
        style={{ color: theme.textIcon.subtle.default }}
      >
        {!shouldShowPreview && (
          <p className="fw-700 mb-1">
            {`Select a group of product to ${showVariant}`}
          </p>
        )}
        {shouldShowPreview && (
          <p className="fw-700 mb-1">
            {`${showVariant === "boost" ? "Boosting" : "Burying"}`}
            <strong className="text-black">
              {` ${collectionTitle ?? TYPE_TO_PREVIEW_TITLE[option]} `}
            </strong>
            products
          </p>
        )}
        <p>
          {shouldShowPreview
            ? TYPE_TO_PREVIEW_DESCRIPTION[option]
            : `You will be able to preview the effect of your ${showVariant} here`}
        </p>
      </div>
    </div>
  );
};

export default BoostAndBuryPreview;

import { useMutation } from "@tanstack/react-query";
import { useAlerts } from "src/components/storybook/Alert/useAlerts";
import { CategoryRankingOrderOptionType } from "src/components/storybook/Categories/CategoryRankingOrder/CategoryRankingOrderOption";

export interface SaveCategoryMutationFuncProps {
  pinnedProducts: string[];
  hiddenProducts: string[];
  addedProducts: string[];
  removedProducts: string[];
  hasPinnedChanged: boolean;
  hasHiddenChanged: boolean;
  titles: Record<string, string>;
  rankingType: CategoryRankingOrderOptionType | null;
}

export type SaveCategoryMutationFunc = (
  merchantId: string,
  categoryId: string,
  categoryData: SaveCategoryMutationFuncProps
) => Promise<void>;

export function useSaveCategory(
  merchantId: string | null,
  categoryId: string | null,
  saveCategoryCB: SaveCategoryMutationFunc
) {
  const { addAlert } = useAlerts();

  const saveCategoryMutation = useMutation<
    void,
    unknown,
    SaveCategoryMutationFuncProps
  >({
    mutationFn: async (args) => {
      if (!merchantId || !categoryId) return;
      await saveCategoryCB(merchantId, categoryId, args);
    },
    onError: (error) => {
      console.error("Saving category failed", error);
      addAlert({
        id: Math.random() + "",
        message: "Error saving category",
        detail:
          "Please try again later, or contact Depict if the problem persists.",
        type: "danger",
        autohide: true,
      });
    },
  });

  return {
    isError: saveCategoryMutation.isError,
    isSaving: saveCategoryMutation.isPending,
    saveCategory: saveCategoryMutation,
  };
}

import React from "react";

import { useContext } from "react";
import { UserData } from "src/types/user";

interface UserContextData {
  user: UserData | null;
}

const userContextInitialValue: UserContextData = {
  user: null,
};

// Create a context with a default null value
export const UserContext = React.createContext(userContextInitialValue);

const useUser = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }

  return context;
};

export default useUser;

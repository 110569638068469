import { GroupsApiParams, GroupsApiResult } from "src/types/api";
import config from "src/config";
import { mockGroups } from "src/helpers";

const groupsApi = async (params: {
  idToken: string;
  merchant_id: string;
  market_id: string;
  group_id?: string;
  body?: GroupsApiParams;
  method?: "GET" | "POST" | "PUT";
}): Promise<GroupsApiResult> => {
  let { merchant_id, market_id, group_id, body, method, idToken } = params;

  if (!method) {
    method = "GET";
  }

  let endpoint = `/merchants/${merchant_id}/markets/${market_id}/groups`;

  if (method === "PUT") {
    endpoint = `/merchants/${merchant_id}/markets/${market_id}/groups/${group_id}`;
  }

  if (method === "GET" && group_id) {
    endpoint = `/merchants/${merchant_id}/markets/${market_id}/groups/${group_id}`;
  }

  const url = config.api.baseUrl + "/" + config.api.version + endpoint;
  return new Promise((resolve) => {
    if (config.dataMock && method === "GET") {
      let loadMock = async () => {
        console.log(
          "%c[MOCK] Loaded mocking groups for " + merchant_id,
          "color:coral"
        );
        const data: any = await mockGroups();
        resolve({
          success: true,
          data: data,
        });
      };
      loadMock();
      return;
    }

    let headers: any = {
      accept: "application/json",
      Authorization: "Bearer " + idToken,
    };

    if (method !== "GET") {
      headers["Content-Type"] = "application/json";
    }

    fetch(url, {
      headers,
      method: method,
      body: body ? JSON.stringify(body) : null,
    })
      .then((response) => {
        if (typeof response.ok === "undefined" || response.ok === false) {
          throw response;
        }

        return response.json();
      })
      .then((data) => {
        resolve({
          success: true,
          data: data,
        });
      })
      .catch((error) => {
        resolve({
          success: false,
          error: error,
        });
      });
  });
};

export default groupsApi;

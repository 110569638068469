import React from "react";
import { ConfigurationSettingsProps } from "src/types/components";

import AiControl from "src/components/configuration/AiControl";
import Schedule from "./Schedule";
import SurfacesSelector from "./SurfacesSelector";
import reactFastCompare from "react-fast-compare";

const Settings = (props: ConfigurationSettingsProps) => {
  const {
    enabledSurfaces,
    startTimestamp,
    endTimestamp,
    relationships,
    displayName,
    state,
    surfaces,
    aiControl,
    showAiControls,
    fromHasMultipleItems,
    configurationModifyCB,
    aiControlModifyCB,
    groupClearCB,
    notifyOfWarningPopupOnScreenCB,
    toggleHelpModalCB,
  } = props;

  return (
    <div className="row depict--ConfigurationSettings">
      <div
        className={`${
          showAiControls ? "col-xs-12 col-sm-4" : "col-xs-12 col-sm-6"
        }`}
      >
        <div className="pe-sm-2b">
          <SurfacesSelector
            enabledSurfaces={enabledSurfaces}
            surfaces={surfaces}
            fromHasMultipleItems={fromHasMultipleItems}
            groupClearCB={groupClearCB}
            configurationModifyCB={configurationModifyCB}
            notifyOfWarningPopupOnScreenCB={notifyOfWarningPopupOnScreenCB}
            toggleHelpModalCB={toggleHelpModalCB}
          />
        </div>
      </div>
      <div
        className={`${
          showAiControls ? "col-xs-12 col-sm-4" : "col-xs-12 col-sm-6"
        }`}
      >
        <div className="pe-sm-2b ps-sm-2b">
          <Schedule
            startTimestamp={startTimestamp}
            endTimestamp={endTimestamp}
            state={state}
            configurationModifyCB={configurationModifyCB}
          />
        </div>
      </div>
      {showAiControls && relationships && relationships.length > 0 && (
        <div className="col-xs-12 col-sm-4">
          <div className="ps-sm-2b">
            <AiControl
              displayName={displayName}
              disabled={!relationships || relationships.length === 0}
              aiControl={aiControl}
              aiControlModifyCB={aiControlModifyCB}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(Settings, reactFastCompare);

import { RelationshipsApiParams, RelationshipsApiResult } from "src/types/api";
import config from "src/config";

const relationshipsApi = async (params: {
  idToken: string;
  merchant_id: string;
  configuration_id: number;
  relationship_id?: string;
  body?: RelationshipsApiParams;
  method?: "POST" | "PUT" | "DELETE";
}): Promise<RelationshipsApiResult> => {
  let {
    merchant_id,
    configuration_id,
    relationship_id,
    body,
    method,
    idToken,
  } = params;
  let endpoint = `/merchants/${merchant_id}/configurations/${configuration_id}/relationships/${relationship_id}`;

  if (method === "POST") {
    endpoint = `/merchants/${merchant_id}/configurations/${configuration_id}/relationships`;
  }

  let url = config.api.baseUrl + "/" + config.api.version + endpoint;
  return new Promise((resolve) => {
    if (!method) {
      method = "POST";
    }

    let headers: any = {
      accept: "application/json",
      Authorization: "Bearer " + idToken,
      "Content-Type": "application/json",
    };

    fetch(url, {
      headers,
      method: method,
      body: body ? JSON.stringify(body) : null,
    })
      .then((response) => {
        if (typeof response.ok === "undefined" || response.ok === false) {
          throw response;
        }

        return response.json();
      })
      .then((data) => {
        resolve({
          success: true,
          data: data,
        });
      })
      .catch((error) => {
        resolve({
          success: false,
          error: error,
        });
      });
  });
};

export default relationshipsApi;

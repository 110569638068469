.Container {
  &.scrollable {
    ul {
      overflow-y: auto;
    }
  }

  &.placeholder {
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.5);
    background-color: transparent;
    border-style: dashed;
    border-color: rgba(0, 0, 0, 0.08);

    &:hover {
      border-color: rgba(0, 0, 0, 0.15);
    }
  }

  &.hover {
    background-color: rgb(235, 235, 235, 1);
  }

  &.unstyled {
    overflow: visible;
    background-color: transparent !important;
    border: none !important;
  }

  &.horizontal {
    width: 100%;

    ul {
      grid-auto-flow: column;
    }
  }

  &.shadow {
    box-shadow: 0 1px 10px 0 rgba(34, 33, 81, 0.1);
  }

  &:focus-visible {
    border-color: transparent;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0), 0 0px 0px 2px #4c9ffe;
  }
}

[data-no-dnd="true"] {
  cursor: default;
}

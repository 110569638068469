import { getDaysInMonth, subYears, subQuarters, subMonths } from "date-fns";
import config from "src/config";
import { formatDate, toUtc } from "src/helpers/dates";
import { Compare, DataPeriod, Period } from "src/types/components";
import {
  DepictDataPoint,
  DepictMetricsData,
  MetricsMarketData,
  MetricsSurfaceData,
} from "src/types/metrics";

import {
  fullYearSpan,
  monthSpan,
  quarterSpan,
} from "../filters/periodFilterFunctions";

type BreakpointsMap = Array<{
  value: number;
  aggregation: number;
}>;

/**
 * This function is ugly to rely upon
 * it would be better to let getPeriodData get to know what period option is active
 * and then use that to determine the comparison period
 * @param period
 * @returns
 */
const calculateReasonablePreviousDelta = (
  period: Period | undefined
): DataPeriod | undefined => {
  if (typeof period === "undefined") {
    return;
  }
  const startDate = toUtc(new Date(period.start * 1000));
  const endDate = toUtc(new Date(period.end * 1000));
  const isStartOfMonth = startDate.getUTCDate() === 1;
  const isEndOfMonth =
    endDate.getUTCDate() ===
    getDaysInMonth(new Date(endDate.getUTCFullYear(), endDate.getUTCMonth()));
  const isSameMonth = startDate.getUTCMonth() === endDate.getUTCMonth();
  const isStartOfQuarter = startDate.getUTCMonth() % 3 === 0;
  const isEndOfQuarter = endDate.getUTCMonth() % 3 === 2;
  const isYearSpan =
    startDate.getUTCMonth() === 0 && endDate.getUTCMonth() === 11;

  if (isSameMonth && isStartOfMonth && isEndOfMonth) {
    // We are most likely looking at a month span.
    // We want to show the previous month.
    return monthSpan({
      start: Math.floor(+subMonths(startDate, 1) / 1000),
      end: -1,
    });
  }
  if (!isSameMonth && isStartOfQuarter && isEndOfQuarter) {
    // We are most likely looking at a quarter span.
    // We want to show the previous quarter.
    return quarterSpan({
      start: Math.floor(+subQuarters(startDate, 1) / 1000),
      end: -1,
    });
  }
  if (isYearSpan && isStartOfMonth && isEndOfMonth) {
    // We are most likely looking at a year span.
    // We want to show the previous year.
    return fullYearSpan({
      start: Math.floor(+subYears(startDate, 1) / 1000),
      end: -1,
    });
  }

  const delta = period.end - period.start + 24 * 60 * 60;
  return {
    start: period.start - delta,
    end: period.end - delta,
  };
};

export const getPeriodFromUi = (
  period: Period | undefined,
  compare: Compare | undefined,
  type: "data" | "compare"
): DataPeriod | undefined => {
  if (period && type === "data") {
    let periodData: DataPeriod;
    periodData = period;

    return periodData;
  }

  if (isCompareOptionDisabled(compare, period, 1)) {
    /**
     * The problem here is that when you switch period, not immediately "compare to" switches.
     * So you end up with cases like "since-start" – "previous" which should be disabled.
     * Better if we double check here.
     */
    return;
  }

  if (compare && compare !== "none" && type === "compare") {
    if (typeof compare === "string") {
      /**
       * Here is the case where period is custom and compares to a string-defined period.
       * In this case the dates needs to be computed from scratch.
       * The two possible values for period here are "previous" or "same-last-year".
       * There is also the possibility for "custom" dates, but it is taken care of in code below.
       * "Average" is also a possiblity, but not implemented yet.
       */
      let delta: number;
      switch (compare) {
        case "previous":
          return calculateReasonablePreviousDelta(period);
        case "same-last-year":
          delta = config.const.secondsInYear;
          break;
        default:
          delta = 0;
      }

      if (period?.start && period?.end && delta !== 0) {
        return {
          start: period?.start - delta,
          end: period?.end - delta,
        };
      }
    } else {
      return compare as DataPeriod;
    }
  }
};

export const getAggregationValue = (period: Period | undefined): number => {
  let aggregation = 86400;

  if (!period) {
    return aggregation;
  }

  let delta: number = period?.end - period?.start;
  /**
   * Breakpoints must be in increasing order of .value for this to work.
   */
  let breakpointsMap: BreakpointsMap = [
    {
      value: config.const.secondsInDay * 70,
      aggregation: config.const.secondsInDay * 7,
    },
    {
      value: config.const.secondsInDay * 300,
      aggregation: config.const.secondsInMonth,
    },
  ];

  for (let breakpoint of breakpointsMap) {
    if (delta > breakpoint.value) {
      aggregation = breakpoint.aggregation;
    }
  }

  return aggregation;
};

export const isCompareOptionDisabled = (
  option: Compare | undefined,
  period: Period | undefined,
  minimumStartSecond: number
): boolean => {
  /**
   * This controls which options of "compare" are available for a given "period".
   */
  switch (option) {
    case "same-last-year":
      if (period) {
        /**
         * if period is passed we check if endDate is in current year and startDate is within 365 days.
         * In that case, "same-last-year" should be active, otherwise disabled.
         */
        let today = toUtc(new Date());
        today.setUTCHours(0, 0, 0, 0);
        let startDate = toUtc(new Date(period.start * 1000));
        let endDate = toUtc(new Date(period.end * 1000));
        let currentYear = today.getUTCFullYear();
        let endYear = endDate.getUTCFullYear();
        let oneYearAgoTimestamp = Math.floor(+subYears(today, 1) / 1000);
        let startDateTimestamp = Math.floor(+startDate / 1000);
        if (
          currentYear !== endYear ||
          startDateTimestamp <= oneYearAgoTimestamp
        ) {
          return true;
        }
      }
      return false;
    case "previous":
      /**
       * Need to check if period start is within bounds.
       */
      if (period) {
        if (period?.start <= minimumStartSecond) {
          return true;
        }
      }
      return false;
    case "average":
      return true;
    default:
      /**
       * Case of custom period, option is not a string
       */
      return false;
  }
};

export const isPeriodOptionDisabled = (
  period: Period,
  minTimestamp: number | null
): boolean => {
  let endTimestamp = period.end;

  if (minTimestamp && endTimestamp < minTimestamp) {
  }

  //SINCE THE END DATE IS ALWAYS TODAY, then the option is never disabled. Might change in the future though.
  return false;
};

export const performDateShift = (
  data: DepictDataPoint[],
  delta: number
): DepictDataPoint[] => {
  let shifted: DepictDataPoint[] = [];
  data.reduce((shifted, point: DepictDataPoint, index: number) => {
    let relatedCompare = data[index];
    if (relatedCompare) {
      shifted.push({
        value: relatedCompare.value || 0,
        duration: relatedCompare.duration,
        start: point.start + delta,
        extra: {
          original_start_time: formatDate(point.start),
        },
      });
    }
    return shifted;
  }, shifted);

  return shifted;
};

const getShiftedDataPoints = (
  dataPoints: DepictDataPoint[],
  compareDataPoints: DepictDataPoint[]
) => {
  const firstTimestamp = dataPoints[0].start;
  const compareFirstTimestamp = compareDataPoints[0].start;
  return performDateShift(
    compareDataPoints,
    firstTimestamp - compareFirstTimestamp
  );
};

export const getCompareDataset = (
  data: DepictMetricsData | null,
  compareData: DepictMetricsData | null
) => {
  if (!data || !compareData) {
    return null;
  }

  const metricData: DepictMetricsData = data;
  const compareTo: DepictMetricsData = compareData;
  const newCompareTo: DepictMetricsData = {};

  for (const market in metricData) {
    if (!compareTo[market]) {
      continue;
    }
    const thisMarket = metricData[market];
    const compareMarket = compareTo[market];
    newCompareTo[market] = {
      name: compareMarket.name,
    } as MetricsMarketData;

    const thisTotal = thisMarket.total;
    const compareTotal = compareMarket.total;

    if (!thisTotal.length || !compareTotal.length) {
      continue;
    }

    let newAttributed: MetricsSurfaceData = {};
    for (const attribute in thisMarket.attributed) {
      if (
        !thisMarket.attributed[attribute] ||
        !compareMarket.attributed[attribute]
      ) {
        continue;
      }
      const attributed = thisMarket.attributed[attribute];
      const compareAttributed = compareMarket.attributed[attribute];
      newAttributed[attribute] = getShiftedDataPoints(
        attributed,
        compareAttributed
      );
    }

    newCompareTo[market].attributed = newAttributed;
    newCompareTo[market].total = getShiftedDataPoints(thisTotal, compareTotal);
  }

  return newCompareTo;
};

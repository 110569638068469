import { css } from "@emotion/react";
import { FC } from "react";
import { theme } from "../../designSystemVariables";
import { Placeholder, XCircle } from "@phosphor-icons/react";

type Variant = "regular" | "dark" | "locked";

type CategoryHeaderButtonProps = {
  image?: { src: string; alt: string };
  label?: string;
  icon?: JSX.Element;
  variant?: Variant;
  rounded?: boolean;
  disabled?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const getBackgroundColor = (variant: Variant): string => {
  switch (variant) {
    case "regular":
    case "locked":
      return "transparent";
    case "dark":
      return theme.background.inverse.default;
  }
};

const getBackgroundHoverColor = (
  variant: Variant,
  disabled: boolean | undefined
): string => {
  if (disabled) {
    return getBackgroundColor(variant);
  }

  switch (variant) {
    case "regular":
    case "locked":
      return theme.background.base.hover;
    case "dark":
      return theme.background.inverse.hover;
  }
};

const getColor = (variant: Variant, disabled: boolean | undefined): string => {
  switch (variant) {
    case "regular":
      return disabled
        ? theme.textIcon.base.disabled
        : theme.textIcon.base.default;
    case "dark":
      return disabled
        ? theme.textIcon.inverse.disabled
        : theme.textIcon.inverse.default;
    case "locked":
      return disabled
        ? theme.textIcon.subtle.disabled
        : theme.textIcon.subtle.default;
  }
};

const getHoverColor = (
  variant: Variant,
  disabled: boolean | undefined
): string => {
  if (disabled) {
    return getColor(variant, disabled);
  }

  switch (variant) {
    case "dark":
      return theme.textIcon.inverse.hover;
  }

  return "";
};

const getBorderColor = (
  variant: Variant,
  disabled: boolean | undefined
): string => {
  switch (variant) {
    case "regular":
      return theme.textIcon.subtle.disabled;
    case "locked":
      return disabled
        ? theme.textIcon.subtle.disabled
        : theme.textIcon.subtle.default;
    case "dark":
      return theme.textIcon.base.default;
  }
};

const getBorderHoverColor = (
  variant: Variant,
  disabled: boolean | undefined
): string => {
  switch (variant) {
    case "regular":
    case "locked":
      return disabled
        ? theme.textIcon.subtle.disabled
        : theme.textIcon.base.hover;

    case "dark":
      return theme.textIcon.base.hover;
  }
};

export const CategoryHeaderButton: FC<CategoryHeaderButtonProps> = ({
  image,
  label,
  icon,
  variant = "regular",
  rounded,
  disabled,
  ...props
}) => (
  <button
    {...props}
    disabled={disabled}
    css={css`
      border: 0;
      outline: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      padding: 0 0.5rem;
      margin: 0;
      border-radius: ${rounded ? 24 : 4}px;
      background-color: ${getBackgroundColor(variant)};
      color: ${getColor(variant, disabled)};
      border: 1px solid ${getBorderColor(variant, disabled)};
      font-size: 0.875rem;
      min-width: 36px;
      height: 36px;

      transition: all 0.2s;

      &:hover {
        color: ${getHoverColor(variant, disabled)};
        background-color: ${getBackgroundHoverColor(variant, disabled)};
        border-color: ${getBorderHoverColor(variant, disabled)};
      }
    `}
  >
    {image?.src && (
      <img
        css={css`
          width: 20px;
          height: 20px;
          border-radius: 2px;
        `}
        alt={image.alt}
        src={image.src}
      />
    )}
    {image && !image.src && (
      <div
        css={css`
          width: 24px;
          height: 24px;
          background-color: ${theme.background.subtle.hover};
          border-radius: 4px;
          margin-left: -0.25rem;
        `}
      >
        <Placeholder weight="bold" color={theme.textIcon.subtle.default} />
      </div>
    )}
    {label}
    {icon}
  </button>
);

import { OnSelect } from "src/components/storybook/BulkEditBar/BulkEditBar.types";

const useSelectionHandler = (
  selectCB: OnSelect | undefined,
  isSelected: boolean,
  mainProductId: string,
  autoHidden: boolean = false
) => {
  const selectionHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (!selectCB || autoHidden) {
      return;
    }

    selectCB(mainProductId, isSelected, "meta");

    if (e.shiftKey) {
      selectCB(mainProductId, isSelected, "shift");
    }
  };
  return selectionHandler;
};

export default useSelectionHandler;

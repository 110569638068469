import { css } from "@emotion/react";
import { useMachine } from "@xstate/react";
import { AnimatePresence, Variants, motion } from "framer-motion";
import { useEffect, useMemo } from "react";
import { getGridContainerCss, getGridElementCss } from "../css";
import { Element, _createMachine } from "./TransitionGridAnimator.state";
import { Typegen0 } from "./TransitionGridAnimator.state.typegen";

type State = Typegen0["matchesStates"];

export type RenderCardCB<T> = (
  item: T,
  index: number,
  extraStyles?: React.CSSProperties
) => React.ReactNode;

export interface Grid<T> {
  initialProducts: T[];
  endProducts: T[] | null;
  nbColumns: number;
  chunkSize: number;
}

interface TransitionGridAnimatorProps<T> {
  grid: Grid<T>;
  productKey: keyof T;
  enableAnimation: boolean;
  renderCard: RenderCardCB<T>;
  onAnimateEnd?: () => void;
  onAnimateStart?: () => void;
  loadingIndicator?: React.ReactNode;
}

/**
 * Component: TransitionGridAnimator
 *
 * This React component is designed to animate transitions in a grid of products. It leverages the XState library to manage complex state transitions and Framer Motion for smooth animations.
 *
 * Props:
 * - grid: An object representing the grid's initial and final states (initialProducts and endProducts), number of columns (nbColumns), and chunk size for animations (chunkSize).
 * - productKey: A key to uniquely identify each product in the grid.
 * - enableAnimation: A boolean to toggle animations on and off.
 * - renderCard: A callback function to render each product card.
 * - onAnimateEnd: An optional callback invoked when the animation ends.
 * - onAnimateStart: An optional callback invoked when the animation starts.
 * - loadingIndicator: An optional React node to display as a loading indicator during transitions.
 *
 * Behavior:
 * - The component initializes an XState machine to handle the animation states of the grid.
 * - Based on the current state of the machine, it renders the product grid, applying animations to product cards for appearing and disappearing effects.
 * - The component also handles dynamic loading indicators and ensures the smooth transition between initial and final product states.
 *
 * State Management:
 * - The state machine handles different states like 'Idle', 'Disappearing', 'Reappearing', etc., to control the flow of the animation sequence.
 *
 * Animation:
 * - Uses Framer Motion's 'AnimatePresence' and 'motion' components to apply animations to each product card.
 * - Defines animation variants for 'visible' and 'hidden' states to control the appearance and disappearance of grid items.
 *
 * Use Case:
 * - This component is particularly useful for applications where the order or number of products in a grid needs to be animated smoothly, such as in online stores, galleries, or dynamic dashboards.
 */
export function TransitionGridAnimator<T extends Element>(
  props: TransitionGridAnimatorProps<T>
) {
  const { onAnimateEnd, onAnimateStart, enableAnimation } = props;

  const machine = useMemo(() => {
    return _createMachine<T>();
  }, []);

  const [state, send] = useMachine(machine, { devTools: true });

  useEffect(() => {
    if (enableAnimation) {
      send({
        type: "initialize",
        payload: {
          ...props.grid,
          nbRows: Math.ceil(
            props.grid.initialProducts.length / props.grid.nbColumns
          ),
        },
      });
      onAnimateStart?.();
    }
  }, [enableAnimation, onAnimateStart, props.grid, send]);

  // Trigger the next state transition after all rows have disappeared
  useEffect(() => {
    if (
      state.matches("Disappearing") &&
      state.context.currentProducts.length === 0
    ) {
      send("allRowsDisappeared");
    }
  }, [state, send]);

  useEffect(() => {
    if (props.grid.endProducts === null) return;

    if (state.matches("IdleWaitingForEndProducts")) {
      send({
        type: "setEndProducts",
        payload: {
          endProducts: props.grid.endProducts,
        },
      });
    }
  }, [props.grid.endProducts, send, state]);

  useEffect(() => {
    // Check if the state is 'Idle' and the previous state was 'Reappearing'
    if (
      state.matches("Reappearing") &&
      state.context.currentProducts.length === props.grid.endProducts?.length
    ) {
      onAnimateEnd?.();
    }
  }, [state, onAnimateEnd, props]);

  // We don't want jumpy scrollbars
  const unshownProducts = useMemo(() => {
    if (state.matches("Idle")) return props.grid.initialProducts;
    if (state.matches("Disappearing")) {
      // products that are in the initial list but not in the current list
      return props.grid.initialProducts.filter((product) => {
        return !state.context.currentProducts.includes(product);
      });
    }
    if (
      state.matches("IdleWaitingForEndProducts") ||
      state.matches("PreparingToReappear")
    ) {
      return props.grid.initialProducts;
    }
    if (state.matches("Reappearing")) {
      // products that are in the end list but not in the current list
      return (
        props.grid.endProducts?.filter((product) => {
          return !state.context.currentProducts.includes(product);
        }) ?? []
      );
    }

    return [];
  }, [props.grid.endProducts, props.grid.initialProducts, state]);

  // Animation variants for Framer Motion
  const variants: Variants = {
    visible: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 0.5 },
  };

  const statesWhereLoadingIndicatorIsShown: State[] = [
    "IdleWaitingForEndProducts",
    "Reappearing",
    "PreparingToReappear",
  ];

  return (
    <div css={getGridContainerCss(props.grid.nbColumns)}>
      <AnimatePresence>
        {state.context.currentProducts.map((product) => (
          <motion.div
            css={getGridElementCss()}
            key={product[props.productKey]}
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={variants}
          >
            {props.renderCard(product, 0)}
          </motion.div>
        ))}
        {unshownProducts.map((product, index) => {
          const showLoading =
            props.loadingIndicator &&
            index === 0 &&
            statesWhereLoadingIndicatorIsShown.includes(state.value as State) &&
            enableAnimation;

          return (
            <div
              key={product[props.productKey]}
              css={[
                getGridElementCss(),
                css`
                  visibility: ${showLoading ? "visible" : "hidden"};
                  position: relative;
                  box-sizing: border-box;
                `,
              ]}
            >
              {!showLoading &&
                props.renderCard(product, index, {
                  visibility: "hidden",
                })}
              {showLoading && (
                <div
                  css={[
                    css`
                      position: absolute;
                      height: 100%;
                      width: 100%;
                    `,
                  ]}
                >
                  {props.loadingIndicator}
                </div>
              )}
            </div>
          );
        })}
      </AnimatePresence>
    </div>
  );
}

 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Store = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M18 9.5v-1c0-0.078-0.018-0.154-0.053-0.224l-2-4c-0.085-0.169-0.258-0.276-0.447-0.276h-12c-0.189 0-0.363 0.107-0.447 0.276l-2 4c-0.035 0.069-0.053 0.146-0.053 0.224v1c0 0.816 0.393 1.542 1 1.999v6.501h-0.5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h16c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-0.5v-6.501c0.607-0.457 1-1.182 1-1.999zM11.5 11c-0.827 0-1.5-0.673-1.5-1.5v-0.5h3v0.5c0 0.827-0.673 1.5-1.5 1.5zM2 9.5v-0.5h3v0.5c0 0.827-0.673 1.5-1.5 1.5s-1.5-0.673-1.5-1.5zM9 5v3h-2.86l0.75-3h2.11zM12.11 5l0.75 3h-2.86v-3h2.11zM6 9h3v0.5c0 0.827-0.673 1.5-1.5 1.5s-1.5-0.673-1.5-1.5v-0.5zM14 9h3v0.5c0 0.827-0.673 1.5-1.5 1.5s-1.5-0.673-1.5-1.5v-0.5zM16.691 8h-2.801l-0.75-3h2.051l1.5 3zM3.809 5h2.051l-0.75 3h-2.801l1.5-3zM3 11.95c0.162 0.033 0.329 0.050 0.5 0.050 0.817 0 1.544-0.394 2-1.002 0.456 0.608 1.183 1.002 2 1.002s1.544-0.394 2-1.002c0.361 0.48 0.89 0.827 1.5 0.951v6.050h-8v-6.050zM16 18h-4v-6.050c0.61-0.124 1.139-0.471 1.5-0.951 0.456 0.608 1.183 1.002 2 1.002 0.171 0 0.338-0.017 0.5-0.050v6.050z" fill={color}></path>
<path d="M14 14.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5z" fill={color}></path>
<path d="M4.5 15c-0.128 0-0.256-0.049-0.354-0.146-0.195-0.195-0.195-0.512 0-0.707l1-1c0.195-0.195 0.512-0.195 0.707 0s0.195 0.512 0 0.707l-1 1c-0.098 0.098-0.226 0.146-0.354 0.146z" fill={color}></path>
<path d="M5.5 17c-0.128 0-0.256-0.049-0.354-0.146-0.195-0.195-0.195-0.512 0-0.707l3-3c0.195-0.195 0.512-0.195 0.707 0s0.195 0.512 0 0.707l-3 3c-0.098 0.098-0.226 0.146-0.354 0.146z" fill={color}></path>
<path d="M8.5 17c-0.128 0-0.256-0.049-0.354-0.146-0.195-0.195-0.195-0.512 0-0.707l1-1c0.195-0.195 0.512-0.195 0.707 0s0.195 0.512 0 0.707l-1 1c-0.098 0.098-0.226 0.146-0.354 0.146z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Store); 
import React from "react";
import { Badge, Button, Card, Col } from "react-bootstrap";
import { Platform } from "../Onboarding/SelectPlatform/SelectPlatform";

function IntegrationCard({
  platform,
  isIntegrated,
  name,
  description,
  logo,
  onViewIntegration,
}: {
  platform: Platform;
  isIntegrated: boolean;
  name: string;
  description: string;
  logo: React.ReactNode;
  onViewIntegration: (platform: Platform) => void;
}) {
  return (
    <Col xs={12} md={6} lg={4} className="mb-4">
      <Card className="mb-4">
        <Card.Body>
          <div className="mb-4 d-flex justify-content-between align-items-center">
            <div className="p-2">{logo}</div>
            <div className="p-2">
              {isIntegrated && (
                <Badge bg="success">
                  <span role="img" aria-label="checkmark">
                    ✓
                  </span>{" "}
                  Integrated
                </Badge>
              )}
            </div>
          </div>
          <Card.Title>{name}</Card.Title>
          <Card.Text>{description}</Card.Text>
          <Button
            variant="primary"
            className="w-100"
            disabled={!isIntegrated}
            onClick={() => onViewIntegration(platform)}
          >
            View
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default React.memo(IntegrationCard);

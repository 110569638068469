import { SortableProduct } from "../useSortablePairedProducts";
import { UniqueIdentifier } from "@dnd-kit/core";
import { useCallback, useEffect, useRef } from "react";

/** Certain overIndexes aren't "allowed" because they are in the middle of a pair  */
export function ensureAllowedOverIndex({
  overIndex,
  draggedIndex,
  pairedProducts,
  items,
}: {
  overIndex: number;
  draggedIndex: number;
  pairedProducts: SortableProduct[][];
  items: string[];
}) {
  if (overIndex === draggedIndex) {
    return overIndex;
  }

  const draggingBackwards = overIndex < draggedIndex;

  const overId = items[overIndex];
  const overPair = pairedProducts.find((p) => p.some((p) => p.id === overId))!;
  // Banning in this case means preventing the user from being able to drag to the current overIndex they are hovering over
  const shouldBan =
    overPair.length <= 1
      ? false
      : overPair.at(draggingBackwards ? 0 : -1)!.main_product_id !== overId;
  if (shouldBan) {
    return draggedIndex; // We don't allow the current overIndex and default to the activeIndex
  }

  return overIndex; // We allow the current overIndex
}

/** This creates an array that you can index by overIndex.
 * You then get another overIndex which is guaranteed to be allowed as specified in ensureAllowedOverIndex  */
export function getOverIndexesLookupTable({
  draggedIndex,
  pairedProducts,
  dndItemsIds,
}: {
  draggedIndex: number;
  pairedProducts: SortableProduct[][];
  dndItemsIds: string[];
}) {
  const lookupTable: number[] = Array.from({ length: dndItemsIds.length });
  for (let overIndex = 0; overIndex < dndItemsIds.length; overIndex++) {
    const validIndex = ensureAllowedOverIndex({
      overIndex,
      draggedIndex,
      pairedProducts,
      items: dndItemsIds,
    });
    lookupTable[overIndex] = validIndex;
  }
  return lookupTable;
}

/* This function needs to output the two indexes that useSortablePairedProducts uses to reorder the pairs.
 * It's important that the dragged pair is inserted *exactly* where the indicator is currently shown
 * (this was not always the case in the first version of grid pairs)  */
export const sortableFindGridIndexes = (
  productsNested: SortableProduct[][],
  dndPairsFlatIds: string[],
  draggedId: UniqueIdentifier | undefined,
  indicatorOverIndexFlat: number
) => {
  // This code is tricky because some of it is based on a flat product list and some is not.
  // This is because we prefer a nested structure while dnd-kit prefers a flat structure.
  // To keep track of this, the variables follow a naming convention:
  //   - If it's flat based, it's suffixed with "Flat"
  //   - If it's nested based, it's suffixed with "Nested"
  const draggedProductIndexNested = productsNested.findIndex((p) =>
    p.some((p) => p.id === draggedId)
  );
  const idOverIndexFlat = dndPairsFlatIds[indicatorOverIndexFlat];
  const productIndicatorIsOverIndexNested = productsNested.findIndex((p) =>
    p.some((p) => p.id === idOverIndexFlat)
  );
  return {
    oldIndex: draggedProductIndexNested,
    newIndex: productIndicatorIsOverIndexNested,
  };
};

/** This returns the items that we give to dnd-kit.
 * We have to give it different items because,
 * when a user drags a pair, in dnd-kits world,
 * they are only dragging one product (the specific product picked up from the pair)
 * We can't have the other products from the pair still being draggable elements
 * Example: When picking up pair2-product2:
 * We go from [pair1-product1, pair1-product2, pair2-product1, pair2-product2, pair2-product3, pair3-product1]
 * To         [pair1-product1, pair1-product2, pair2-product2, pair3-product1]
 * Removed pair2-product1 and pair2-product3 */
export function generateDnDItems(
  pairedProducts: SortableProduct[][],
  draggedId: string | null
) {
  let draggedPairIndex = -1;
  let draggedProductInPairIndex = -1;
  let draggedProductFlatIndex = -1;
  const dndPairsFlat: SortableProduct[] = [];
  for (let pairIndex = 0; pairIndex < pairedProducts.length; pairIndex++) {
    const pair = pairedProducts[pairIndex];
    const indexInsidePair = pair.findIndex((p) => p.id === draggedId);
    if (indexInsidePair !== -1) {
      dndPairsFlat.push(pair[indexInsidePair]);
      draggedPairIndex = pairIndex;
      draggedProductInPairIndex = indexInsidePair;
      draggedProductFlatIndex = dndPairsFlat.length - 1;
    } else {
      dndPairsFlat.push(...pair);
    }
  }
  return {
    draggedPairIndex,
    draggedProductInPairIndex,
    draggedProductFlatIndex,
    dndPairsFlat,
    dndPairsFlatIds: dndPairsFlat.map((p) => p.id),
  };
}

/**
 * This function is used to generate the items that we give to dnd-kit when the user is selecting multiple items.
 * It works with the assumption that draggedId is part of selectedIds.
 *
 * It takes out the products that are selected, but not the dragged selected product.
 */
export function generateDnDItemsForSelection(
  pairedProducts: SortableProduct[][],
  draggedId: string | null,
  selectedIds: Set<string>
) {
  let draggedPairIndex = -1;
  let draggedProductInPairIndex = -1;
  let draggedProductFlatIndex = -1;
  const dndPairsFlat: SortableProduct[] = [];

  for (let pairIndex = 0; pairIndex < pairedProducts.length; pairIndex++) {
    const pair = pairedProducts[pairIndex];

    // Check if draggedId is in the current pair
    const indexInsidePair = pair.findIndex((p) => p.id === draggedId);

    // If the current product is the dragged item, add only it and remember its position
    if (indexInsidePair !== -1) {
      dndPairsFlat.push(pair[indexInsidePair]);
      draggedPairIndex = pairIndex;
      draggedProductInPairIndex = indexInsidePair;
      draggedProductFlatIndex = dndPairsFlat.length - 1;
    } else {
      // Add only the products that are not in the selected set
      for (const product of pair) {
        if (!selectedIds.has(product.id)) {
          dndPairsFlat.push(product);
        }
      }
    }
  }

  return {
    draggedPairIndex,
    draggedProductInPairIndex,
    draggedProductFlatIndex,
    dndPairsFlat,
    dndPairsFlatIds: dndPairsFlat.map((p) => p.id),
  };
}

export function useDraggedPairOffset(
  activePair: SortableProduct[] | undefined,
  overlayRef: React.RefObject<HTMLDivElement>
) {
  const mouseCoordsRef = useRef<{ clientX: number; clientY: number } | null>(
    null
  );
  const offsets = useRef<null | { x: number; y: number; changed: boolean }>(
    null
  );

  // The old offset becomes invalid when a new pair is picked up
  useEffect(() => {
    if (activePair) {
      offsets.current = null;
    }
  }, [activePair]);

  // Capture mouse position
  useEffect(() => {
    const listener = (e: MouseEvent) => {
      mouseCoordsRef.current = { clientX: e.clientX, clientY: e.clientY };
    };
    window.addEventListener("mousemove", listener);
    return () => window.removeEventListener("mousemove", listener);
  }, []);

  const getMouseOverlayOffsets = useCallback(() => {
    if (!overlayRef.current || !mouseCoordsRef.current) {
      return { x: 0, y: 0 };
    }
    const { clientX: mouseClientX, clientY: mouseClientY } =
      mouseCoordsRef.current!;
    const overlayRect = overlayRef.current!.getBoundingClientRect();
    const offsetX = mouseClientX - overlayRect.left;
    const offsetY = mouseClientY - overlayRect.top;
    return { x: offsetX, y: offsetY };
  }, [overlayRef]);

  return useCallback(() => {
    if (!offsets.current) {
      offsets.current = { ...getMouseOverlayOffsets(), changed: true };
    } else if (offsets.current?.changed === true) {
      offsets.current.changed = false;
    }
    return offsets.current; // Purposefully not returning a new object, because this is called many times per second
  }, [getMouseOverlayOffsets]);
}

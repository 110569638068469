import { useState, useCallback } from "react";
import { EnhancedSurface } from "src/types/components";

export default function useParam(): [
  parameter: Array<EnhancedSurface> | null,
  setCallback: (
    value: EnhancedSurface[] | EnhancedSurface | null,
    action: "enable" | "disable" | "set"
  ) => void
] {
  const [parameter, setParameter] = useState<Array<EnhancedSurface> | null>(
    null
  );

  const setCallback = useCallback(
    (
      value: EnhancedSurface[] | EnhancedSurface | null,
      action: "enable" | "disable" | "set"
    ) => {
      setParameter((prevValue: EnhancedSurface[] | null) => {
        if (action === "enable") {
          if (!prevValue) {
            return [value as EnhancedSurface];
          }

          const newValue = [...prevValue, ...[value as EnhancedSurface]];
          return newValue;
        }

        if (action === "disable") {
          if (!prevValue) {
            return prevValue;
          }

          return prevValue.filter(function (item: EnhancedSurface) {
            return item.id !== (value as EnhancedSurface).id;
          });
        }

        if (action === "set") {
          return value as EnhancedSurface[] | null;
        }

        return prevValue;
      });
    },
    []
  );

  return [parameter, setCallback];
}

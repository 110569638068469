import {
  useSensors,
  useSensor,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
  DndContext,
  MeasuringStrategy,
  DragOverlay,
  UniqueIdentifier,
  defaultDropAnimation,
  DropAnimation,
  DragStartEvent,
} from "@dnd-kit/core";
import {
  verticalListSortingStrategy,
  sortableKeyboardCoordinates,
  SortableContext,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  MouseEvent,
  TouchEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { MultipleContainersProps } from "src/types/dnd";
import useRenderSortableItemOverlay from "src/helpers/hooks/dnd/useRenderSortableItemOverlay";
import useOnDragOver from "src/helpers/hooks/dnd/useOnDragOver";
import useOnDragEnd from "src/helpers/hooks/dnd/useOnDragEnd";
import React from "react";
import reactFastCompare from "react-fast-compare";
import { usePrevious } from "@dnd-kit/utilities";
import { deepEqual } from "src/helpers";

class CustomMouseSensor extends MouseSensor {
  static activators = [
    {
      eventName: "onMouseDown" as any,
      handler: ({ nativeEvent: event }: MouseEvent) => {
        const element = event.target as Element;
        return element.getAttribute("data-no-dnd") !== "true";
      },
    },
  ];
}

class CustomTouchSensor extends TouchSensor {
  static activators = [
    {
      eventName: "onTouchStart" as any,
      handler: ({ nativeEvent: event }: TouchEvent) => {
        const element = event.target as Element;
        return element.getAttribute("data-no-dnd") !== "true";
      },
    },
  ];
}

const MultipleContainers = ({
  adjustScale = false,
  columns,
  items,
  getItemStyles = undefined,
  wrapperStyle = undefined,
  modifiers,
  renderItem,
  vertical = false,
  children,
  onDragOverCB,
  onDragEndCB,
  onActiveIdChange,
}: MultipleContainersProps) => {
  const [containers, setContainers] = useState<string[]>([]);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const previousItems = usePrevious(items);

  useEffect(() => {
    if (!items) {
      return;
    }

    if (deepEqual(items, previousItems)) {
      return;
    }

    setContainers(Object.keys(items));
  }, [items, previousItems]);

  const sensors = useSensors(
    useSensor(CustomMouseSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    }),
    useSensor(CustomTouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [renderSortableItemDragOverlay] = useRenderSortableItemOverlay({
    items,
    renderItem,
    wrapperStyle,
    getItemStyles,
  });

  const [onDragOver, collisionDetectionStrategy] = useOnDragOver({
    items,
    onDragOverCB,
    activeId,
  });

  const [onDragEnd] = useOnDragEnd({
    items,
    onDragEndCB,
    setActiveId,
    setContainers,
  });

  const dropAnimation: DropAnimation = defaultDropAnimation;

  useEffect(() => {
    if (onActiveIdChange) {
      onActiveIdChange(activeId);
    }
  }, [activeId, items, onActiveIdChange]);

  const measuring = useMemo(() => {
    return {
      droppable: {
        strategy: MeasuringStrategy.Always,
      },
    };
  }, []);

  const onDragStart = useCallback(
    ({ active }: { active: DragStartEvent["active"] }) => {
      setActiveId(active.id);
    },
    []
  );

  return (
    <DndContext
      sensors={sensors}
      // @ts-ignore
      collisionDetection={collisionDetectionStrategy}
      measuring={measuring}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDragEnd={onDragEnd}
      modifiers={modifiers}
    >
      <SortableContext
        items={containers}
        strategy={
          vertical ? verticalListSortingStrategy : horizontalListSortingStrategy
        }
      >
        {children}
      </SortableContext>
      {createPortal(
        <DragOverlay
          adjustScale={adjustScale}
          dropAnimation={dropAnimation}
          className="depict--DragOverlay"
        >
          {activeId ? renderSortableItemDragOverlay(activeId) : null}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );
};

export default React.memo(MultipleContainers, reactFastCompare);

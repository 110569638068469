 import React, { forwardRef } from "react";
import { LinearIconsProps } from "src/types/components";

const Hammer2 = forwardRef<SVGSVGElement, LinearIconsProps>((props, ref) => {
  const { size = 16, color = "currentColor", ...rest } = props;
  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        {...rest}
      > 



<path d="M18.073 17.498c-0.011-0.399-0.183-0.767-0.485-1.037l-8.361-7.481 1.804-1.804c0.143 0.076 0.303 0.116 0.469 0.116 0.268 0 0.519-0.104 0.707-0.292l0.793-0.793c0.188-0.188 0.292-0.44 0.292-0.707s-0.104-0.519-0.292-0.707l-3.293-3.293c-0.188-0.188-0.44-0.292-0.707-0.292s-0.519 0.104-0.707 0.292l-0.793 0.793c-0.188 0.188-0.292 0.44-0.292 0.707 0 0.166 0.040 0.327 0.116 0.469l-4.855 4.855c-0.143-0.076-0.303-0.116-0.469-0.116-0.268 0-0.519 0.104-0.707 0.292l-0.793 0.793c-0.39 0.39-0.39 1.024 0 1.414l3.293 3.293c0.188 0.188 0.44 0.292 0.707 0.292s0.519-0.104 0.707-0.292l0.793-0.793c0.318-0.318 0.376-0.798 0.176-1.176l1.804-1.804 7.481 8.361c0.27 0.302 0.638 0.474 1.037 0.485 0.014 0 0.027 0.001 0.041 0.001 0.384 0 0.745-0.151 1.022-0.427l0.086-0.086c0.286-0.286 0.438-0.664 0.427-1.063zM9 2.207l3.293 3.293-0.792 0.792c-0 0-0.001 0-0.001 0v0.001l-3.293-3.293 0.793-0.793zM8 4.207l2.293 2.293-4.793 4.793-2.293-2.293 4.793-4.793zM4.501 13.292c-0 0-0.001 0-0.001 0v0.001l-3.293-3.293 0.793-0.793 3.293 3.293-0.792 0.792zM16.939 17.854l-0.086 0.086c-0.090 0.090-0.206 0.137-0.328 0.134s-0.235-0.057-0.32-0.152l-6.082-6.798 6.798 6.082c0.095 0.085 0.149 0.198 0.152 0.32s-0.044 0.238-0.134 0.328z" fill={color}></path>

 </svg>
    </>
  );
});

export default React.memo(Hammer2); 
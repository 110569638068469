import React, { PropsWithChildren } from "react";
import { Merchant } from "src/api/types";
import NoAccess from "src/views/NoAccess";
import { UserData } from "../types/user";

export type AccessValidator = (user: UserData, merchant: Merchant) => boolean;

interface ProtectedRouteProps {
  user: UserData | null;
  merchant: Merchant | null;
  accessValidator: AccessValidator;
}

const ProtectedRoute = (props: PropsWithChildren<ProtectedRouteProps>) => {
  if (
    props.user == null ||
    props.merchant == null ||
    !props.accessValidator(props.user, props.merchant)
  ) {
    return <NoAccess />;
  }
  return <>{props.children}</>;
};

export default ProtectedRoute;

import React from "react";
import { BasePortalProductDto } from "src/api/types";
import { CategoryProductGridCard } from "src/components/storybook/Categories/CategoryProductGridCard/CategoryProductGridCard";
import {
  TransitionGridAnimator,
  Grid,
} from "src/components/storybook/ProductGridView/TransitionGridAnimator/TransitionGridAnimator";
import { LoadingIndicator } from "src/components/storybook/ProductGridView/TransitionGridAnimator/LoadingIndicator/LoadingIndicator";

export type RankingAnimationConfig = Grid<BasePortalProductDto>;
export type RankingAnimationStateConfig = RankingAnimationConfig & {
  startAnimation: boolean;
  animationId: string | null;
};

interface CategoryTransitionGridAnimatorProps {
  startAnimation: boolean;
  animationId: string | null;
  rankingConfig: RankingAnimationConfig;
  setRankingAnimationConfig: React.Dispatch<
    React.SetStateAction<RankingAnimationStateConfig>
  >;
}
export function CategoryTransitionGridAnimator(
  props: CategoryTransitionGridAnimatorProps
) {
  return (
    <TransitionGridAnimator
      key={props.animationId}
      enableAnimation={props.startAnimation}
      grid={props.rankingConfig}
      productKey={"main_product_id"}
      renderCard={function (
        item,
        index: number,
        extraStyles?: React.CSSProperties | undefined
      ): React.ReactNode {
        return (
          <CategoryProductGridCard
            id={item.main_product_id}
            product={item}
            pinned={false}
            pinningAllowed={false}
            hidden={false}
            hidingAllowed={false}
            index={index}
            disableIndex={false}
            disableDropdown={true}
            actionCBs={{
              onPinToTop: undefined,
              onPinClick: undefined,
              onHideClick: undefined,
              onAiSortedClick: undefined,
              onRemoveClick: undefined,
              onSelectClick: undefined,
            }}
            extraStyles={extraStyles}
            showSelectCheckbox={false}
            isSelected={false}
            highlight={false}
            isInSelectMode={false}
            aiSorted={true}
            isAutoHidden={false}
          />
        );
      }}
      onAnimateEnd={() => {
        props.setRankingAnimationConfig((prev) => {
          return { ...prev, startAnimation: false };
        });
      }}
      loadingIndicator={<LoadingIndicator />}
    />
  );
}

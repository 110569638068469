import { useEffect, useRef } from "react";

/**
 * A hook that runs a callback function when the given value changes.
 *
 * @param value - The value to monitor for changes.
 * @param callback - The function to call when the value changes.
 */
function useOnValueChange<T>(
  value: T,
  callback: (value: T) => void | Promise<void>
): void {
  const isFirstRender = useRef(true);
  const prevValue = useRef(value);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (prevValue.current !== value) {
      callback(value);
    }

    prevValue.current = value;
  }, [value, callback]); // Dependency array includes both value and callback
}

export default useOnValueChange;

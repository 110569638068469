import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { EyeClosed, PushPin, Sparkle, Trash } from "@phosphor-icons/react";
import classnames from "classnames";
import { useCallback, useMemo, useState } from "react";
import BootstrapCard from "react-bootstrap/Card";
import { MetadataInner } from "src/api/types";
import ProductEmptyImage from "src/assets/img/product-empty.svg";
import { ProductMetadata } from "src/helpers/catalog/ProductMetadataProvider";
import useSelectionHandler from "src/helpers/hooks/useSelectionHandler";
import {
  getDetailFromProductMetadata,
  metadataTypeBadgePositionMap,
} from "../../../../helpers/catalog/productMetadataToTextTransformers";
import { useCatalogMetadata } from "../../../../helpers/hooks/catalog/useCatalogMetadata";
import { OnSelect } from "../../BulkEditBar/BulkEditBar.types";
import { Checkbox } from "../../Checkbox/Checkbox";
import { ColorDot } from "../../ColorDot/ColorDot";
import { CurvyCorner } from "../../CurvyCorner/CurvyCorner";
import Dropdown, { Item } from "../../Dropdown/Dropdown";
import { useImageResize } from "../../ImageResizeProvider/ImageResizeProvider";
import ProductBadges from "../../ProductBadges/ProductBadges";
import { common } from "../../common";
import { noSelectCss, noWrapCss } from "../../css.helper";
import { theme } from "../../designSystemVariables";
import { Product } from "../../types";
import { CurationCorner } from "./CurationCorner";
import { HidingOptions } from "../CategoryProductListCard/CategoryProductListCard";

export const IMG_ASPECT_RATIO = 238 / 220;

export const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

type ActionCB = (mainProductId: string) => Promise<void> | void;

export interface CategoryProductGridCardProps {
  id: string;
  index: number | undefined;
  disableIndex: boolean;
  disableDropdown?: boolean;
  pinningAllowed: boolean;
  pinned: boolean;
  hidingAllowed: boolean;
  hidden: boolean;
  product: Product;
  aiSorted: boolean;
  actionCBs: {
    onPinToTop: ActionCB | undefined;
    onPinClick: ActionCB | undefined;
    onHideClick: ActionCB | undefined;
    onAiSortedClick: ActionCB | undefined;
    onSelectClick?: OnSelect | undefined;
    onRemoveClick: ActionCB | undefined;
  };
  showSelectCheckbox?: boolean;
  isSelected?: boolean;
  extraStyles?: React.CSSProperties;
  highlight: boolean;
  isInSelectMode: boolean;
  isAutoHidden?: boolean;
}

type MetadataType = MetadataInner[number]["type"];

const categoryRelevantMetadataTypes: MetadataType[] = [
  "out_of_stock",
  "low_stock",
  "on_sale",
  "new_in",
  "bestseller",
  "trending",
  "slow_mover",
  "inactive",
];

export function CategoryProductGridCard(props: CategoryProductGridCardProps) {
  const selectionHandler = useSelectionHandler(
    props.actionCBs.onSelectClick,
    Boolean(props.isSelected),
    props.product.main_product_id,
    props.isAutoHidden
  );
  const { productMetadata: allProductMetadata } = useCatalogMetadata();
  const productMetadata: ProductMetadata[] = useMemo(() => {
    return allProductMetadata?.[props.product.main_product_id] ?? [];
  }, [allProductMetadata, props.product.main_product_id]);

  const categoryRelevantMetadata = productMetadata.filter((md) =>
    categoryRelevantMetadataTypes.includes(md.type)
  );
  const topRowBadgeMetadata = categoryRelevantMetadata.filter((md) => {
    if (md.type === undefined) return false;
    return metadataTypeBadgePositionMap[md.type] === "top";
  });
  const bottomRowBadgeMetadata = categoryRelevantMetadata.filter((md) => {
    if (md.type === undefined) return false;
    return metadataTypeBadgePositionMap[md.type] === "bottom";
  });

  const aiSorted = props.aiSorted;
  const isPinned = props.pinned;

  const isAutoHidden = props.isAutoHidden || false;

  const dropdownItems = useMemo(() => {
    const items: Item[] = [];

    const firstItem = props.index === 0;

    const canPinToTop = !props.pinned;

    const canMoveToTop = props.pinned && !firstItem;

    if (canMoveToTop) {
      items.push({
        text: "Move to top",
        onClick: () =>
          props.actionCBs.onPinToTop?.(props.product.main_product_id),
        icon: <PushPin />,
      });
    }

    if (canPinToTop) {
      items.push({
        text: "Pin to top",
        onClick: () =>
          props.actionCBs.onPinToTop?.(props.product.main_product_id),
        icon: <PushPin weight="fill" />,
      });
    }

    if (!props.pinned) {
      items.push({
        text: "Pin",
        onClick: () =>
          props.actionCBs.onPinClick?.(props.product.main_product_id),
        icon: <PushPin />,
      });
    }

    if (props.pinned || props.hidden) {
      items.push({
        text: "AI Sorted",
        onClick: () =>
          props.actionCBs.onAiSortedClick?.(props.product.main_product_id),
        icon: <Sparkle />,
      });
    }

    if (!props.hidden) {
      items.push({
        text: "Hide",
        onClick: () =>
          props.actionCBs.onHideClick?.(props.product.main_product_id),
        icon: <EyeClosed />,
      });
    }

    if (props.actionCBs.onRemoveClick) {
      items.push({
        text: "Remove",
        onClick: () =>
          props.actionCBs.onRemoveClick?.(props.product.main_product_id),
        icon: <Trash />,
      });
    }

    return items;
  }, [
    props.actionCBs,
    props.hidden,
    props.index,
    props.pinned,
    props.product.main_product_id,
  ]);

  const badgeContainerBaseStyle: React.CSSProperties = {
    position: "absolute",
    display: "flex",
    zIndex: 2,
    gap: "0.5rem",
    margin: "0.5rem",
    flexWrap: "wrap",
  };

  const image = Image({ imageUrl: props.product.image_url });

  const displayCurationCorner =
    props.actionCBs.onPinToTop &&
    props.actionCBs.onHideClick &&
    props.actionCBs.onAiSortedClick;

  const getOutline = useCallback(() => {
    if (props.isSelected) {
      return `2px solid ${theme.border.base.default}`;
    } else if (props.hidden) {
      return `2px solid ${theme.border.subtle.default}`;
    } else if (props.highlight) {
      return `2px solid ${theme.border.accent.default}`;
    } else {
      return ``;
    }
  }, [props.hidden, props.highlight, props.isSelected]);

  const getBackground = useCallback(() => {
    if (props.hidden) {
      return theme.background.neutral.default;
    } else if (aiSorted) {
      return "linear-gradient(219deg, rgba(135, 155, 229, 0.22) 0%, rgba(175, 149, 232, 0.00) 100%), #FFF";
    } else {
      return theme.background.base.default;
    }
  }, [aiSorted, props.hidden]);

  const getBorder = useCallback(() => {
    if (aiSorted) {
      return `1px solid #879BE5`;
    } else {
      return `1px solid ${theme.border.subtle.default}`;
    }
  }, [aiSorted]);

  const getTopRightCornersBackgroundColor = useCallback(() => {
    if (props.hidden) {
      return theme.background.neutral.default;
    } else if (props.isSelected && aiSorted) {
      return "F3F5FC";
    } else if (aiSorted) {
      return "#E6EAFA";
    } else {
      return theme.background.base.default;
    }
  }, [aiSorted, props.hidden, props.isSelected]);

  const getBottomRightCornersBackgroundColor = useCallback(() => {
    if (props.hidden) {
      return theme.background.neutral.default;
    } else if (props.isSelected && aiSorted) {
      return "F3F5FC";
    } else if (aiSorted) {
      return "#F1F4FC";
    } else {
      return theme.background.base.default;
    }
  }, [aiSorted, props.hidden, props.isSelected]);

  const getTopLeftCornersBackgroundColor = useCallback(() => {
    if (props.hidden) {
      return theme.background.neutral.default;
    } else if (props.isSelected && aiSorted) {
      return "F9F9FE";
    } else if (aiSorted) {
      return "#F2F4FC";
    } else {
      return theme.background.base.default;
    }
  }, [aiSorted, props.hidden, props.isSelected]);

  const getBottomLeftCornersBackgroundColor = useCallback(() => {
    if (props.hidden) {
      return theme.background.neutral.default;
    } else if (props.isSelected && aiSorted) {
      return theme.background.base.default;
    } else if (aiSorted) {
      return "#FCFBFE";
    } else {
      return theme.background.base.default;
    }
  }, [aiSorted, props.hidden, props.isSelected]);

  return (
    <div
      css={[
        noSelectCss,
        `
          user-select: none;
          display: flex;
          flex-direction: column;
          padding: 0.5rem;
          gap: 12px;
          position: relative;
          border: ${getBorder()}; 
          border-radius: ${common.cardBorderRadius};
          background: ${getBackground()};
          outline: ${getOutline()};
          box-shadow: ${
            isPinned ? "0px 8px 32px 0px rgba(15, 15, 15, 0.16);" : ""
          }          
        `,
        { ...props.extraStyles },
      ]}
      onClick={selectionHandler}
    >
      <ImageContainer>
        {image}
        {props.showSelectCheckbox && !isAutoHidden && (
          <SelectionCorner
            {...props}
            backgroundColor={getTopLeftCornersBackgroundColor()}
          />
        )}
        {props.product.color_name && props.product.color_hex && (
          <div
            css={css`
              transform: translate(24px, 24px);
              opacity: 0;
              transition: all 0.2s;

              ${ImageContainer}:hover & {
                transform: none;
                opacity: 1;
              }
            `}
          >
            <ColorDot
              hexCode={props.product.color_hex}
              name={props.product.color_name}
              backgroundColor={getBottomRightCornersBackgroundColor()}
            />
          </div>
        )}
        <div
          css={css`
            display: ${props.isInSelectMode ? "none" : "block"};
          `}
        >
          {displayCurationCorner && (
            <CurationCorner
              {...props}
              backgroundColor={getTopRightCornersBackgroundColor()}
            />
          )}
        </div>
        <div
          style={{
            ...badgeContainerBaseStyle,
            bottom: 0,
            left: 0,
          }}
        ></div>
        {!(
          bottomRowBadgeMetadata.length || topRowBadgeMetadata.length
        ) ? null : (
          <CurvyCorner
            corner="bottom-left"
            backgroundColor={getBottomLeftCornersBackgroundColor()}
          >
            <div
              style={{
                marginTop: "0.32em",
                marginRight: "0.32em",
                display: "flex",
                flexDirection: "column",
                gap: "0.32em",
              }}
            >
              <ProductBadges
                metadata={[...bottomRowBadgeMetadata, ...topRowBadgeMetadata]}
                oos_auto_hide={isAutoHidden}
              />
            </div>
          </CurvyCorner>
        )}
      </ImageContainer>
      <div
        css={[
          css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
          `,
          css`
            flex-grow: 1;
            white-space: nowrap;
          `,
        ]}
      >
        <div css={noWrapCss}>
          <div
            title={props.product.title}
            css={css(
              theme.typography.paragraph[200].heavy,
              `
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: ${theme.textIcon.base.default}
              `
            )}
          >
            {props.product.title}
          </div>
          <div
            title={props.product.main_product_id}
            css={css(
              theme.typography.paragraph[300].light,
              css`
                color: ${theme.textIcon.subtle.default};
              `
            )}
          >
            {props.product.main_product_id}
          </div>
        </div>
        <div>
          {!props.disableDropdown &&
            dropdownItems.length > 0 &&
            !isAutoHidden && (
              <Dropdown
                disabled={props.isInSelectMode}
                closeWhenOtherDropdownOpens={true}
                key_={props.product.main_product_id}
                items={dropdownItems}
                dotSize={24}
                toggleStyleOverride={{
                  color: theme.textIcon.base.default,
                }}
              />
            )}
        </div>
      </div>
    </div>
  );
}

// CategoryProductGridCard.whyDidYouRender = true;

export function Image({ imageUrl }: { imageUrl?: string | null }) {
  const { getImageUrl } = useImageResize();

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        paddingTop: `calc(100% / ${IMG_ASPECT_RATIO})`,
        borderRadius: common.cardContentBorderRadius,
        overflow: "hidden",
      }}
    >
      <BootstrapCard.Img
        variant="top"
        draggable={false}
        src={imageUrl ? getImageUrl(imageUrl, 500) : ProductEmptyImage}
        style={{
          objectFit: "cover",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = ProductEmptyImage;
        }}
        loading="lazy"
      />
    </div>
  );
}

export function SelectionCorner(props: {
  isSelected?: boolean;
  isInSelectMode: boolean;
  product: Product;
  actionCBs: {
    onSelectClick?: OnSelect | undefined;
  };
  hidingOptions?: HidingOptions;
  backgroundColor?: string;
}) {
  return (
    <div
      className={classnames("selection-corner", {
        show: props.isInSelectMode,
      })}
      css={css`
        & .content-container {
          ${!props.isSelected &&
          !props.isInSelectMode &&
          `
            transform: translate(-32px, -32px);
            opacity: 0;
          `}
          transition: transform 0.2s, opacity 0.2s;

          ${ImageContainer}:hover & {
            transform: translate(0px, 0px);
            opacity: 1;
          }
        }
      `}
    >
      <CurvyCorner
        corner="top-left"
        borderRadius="0.75em"
        backgroundColor={props.backgroundColor}
      >
        <div
          css={css`
            display: flex;
            padding: 0px 8px 8px 0px;
            flex-direction: column;
            align-items: flex-end;
            gap: 8px;
          `}
        >
          <Checkbox
            checked={Boolean(props?.isSelected)}
            onChange={(alreadyChecked) => {
              props.actionCBs.onSelectClick?.(
                props.product.main_product_id,
                alreadyChecked
              );
            }}
            style={{
              width: 16,
              height: 16,
            }}
          />
        </div>
      </CurvyCorner>
    </div>
  );
}

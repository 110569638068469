import { Table } from "react-bootstrap";
import SynonymItemRow from "./SynonymItemRow";
import { MerchantSynonym } from "src/api/types";
import { css } from "@emotion/react";
import { theme } from "../designSystemVariables";

export type SynonymTableProps = {
  items: MerchantSynonym[];
  localeId: string;
  updateSynonym: (synonym: MerchantSynonym) => void;
  deleteSynonym: (synonymId: number) => void;
  isAddingSynonym: boolean;
  setIsAddingSynonym: (isAddingSynonym: boolean) => void;
};

function SynonymTable(props: SynonymTableProps) {
  return (
    <div className="me-2">
      <Table
        className="m-0"
        css={css`
          height: 1px;
        `}
      >
        <thead
          css={css`
            color: ${theme.textIcon.subtle.default};
            position: relative;
            ::before {
              background: ${theme.background.subtle.default};
              border-top: 1px solid ${theme.border.subtle.disabled};
              content: "";
              position: absolute;
              display: block;
              top: 0;
              left: -20px;
              right: -20px;
              bottom: 0;
              width: calc(100% + 40px);
            }
          `}
        >
          <tr>
            <th className="flex-column position-relative border-0">
              <div className="d-flex ps-0 align-items-center w-100">
                Synonyms
              </div>
            </th>
            <th className="flex-column position-relative border-0">
              <div className="d-flex ps-0 align-items-center w-100">
                Actions
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {props.items.length === 0 && !props.isAddingSynonym && (
            <tr>
              <td
                className="pb-4 text-start w-100 border-0 text-gray-800"
                colSpan={5}
              >
                You haven't added any synonyms yet, go ahead and add some!
              </td>
            </tr>
          )}
          {props.isAddingSynonym && (
            <SynonymItemRow
              item={{
                locale_id: props.localeId,
                synonyms: [],
              }}
              onUpdate={(synonym: MerchantSynonym) =>
                props.updateSynonym(synonym)
              }
              onDelete={(synonym: MerchantSynonym) => {
                if (!synonym.id) {
                  return;
                }
                props.deleteSynonym(synonym.id);
              }}
              isNewSynonym={true}
              setIsAddingSynonym={props.setIsAddingSynonym}
            />
          )}
          {props.items.map((item, index) => (
            <SynonymItemRow
              key={`synonym-item-row-${props.localeId}-${item.id}`}
              item={item}
              onUpdate={(synonym: MerchantSynonym) =>
                props.updateSynonym(synonym)
              }
              onDelete={(synonym: MerchantSynonym) => {
                if (!synonym.id) {
                  return;
                }
                props.deleteSynonym(synonym.id);
                console.log("delete synonym", synonym.id);
              }}
              isNewSynonym={false}
              setIsAddingSynonym={props.setIsAddingSynonym}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default SynonymTable;

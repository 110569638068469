import { useEffect, useState } from "react";

import { toUtc } from "src/helpers/dates";

type UseMinDate = [minDate: Date, minTimestamp: number | null];

export default function useMinDate(startTimestamp: number | null): UseMinDate {
  const [minDate, setMinDate] = useState<Date>(toUtc(new Date(0)));
  const [minTimestamp, setMinTimestamp] = useState<number | null>(null);

  useEffect(() => {
    if (!startTimestamp) {
      setMinDate(toUtc(new Date(0)));
      setMinTimestamp(null);
      return;
    }

    if (startTimestamp * 1000 === +minDate) {
      return;
    }

    const startDate = toUtc(new Date(startTimestamp * 1000));
    setMinDate(startDate);
    setMinTimestamp(startTimestamp);

    return () => {
      setMinDate(toUtc(new Date(0)));
      setMinTimestamp(null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTimestamp]);

  return [minDate, minTimestamp];
}
